/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
import React, { Dispatch, FC, useEffect, useState } from 'react';

import happy from '../icons/happy.svg';
import neutral from '../icons/neutral.svg';
import unhappy from '../icons/unhappy.svg';
import very_happy from '../icons/very_happy.svg';
import very_unhappy from '../icons/very_unhappy.svg';
import styles from './styles.module.scss';

interface Validator {
  id: number;
  form_question_id: number;
  type: string;
  operator: string;
  value: string;
  custom_error_message: string;
  created_at: string;
  updated_at: string | null;
}

interface QuestionAndMultiselectProps {
  element: {
    id: number;
    form_id: number;
    form_step_id: number;
    title: string;
    is_required: boolean;
    is_conditional: boolean;
    type: string;
    options: string[] | [] | null;
    validators: Validator[] | [];
    created_at: string;
    updated_at: string | null;
  };
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
  isEmoji?: boolean;
  medicalHistory?: boolean;
  isMulti?: boolean;
}

const QuestionAndMultiselect: FC<QuestionAndMultiselectProps> = React.forwardRef(
  ({ element, form, setForm, isEmoji, isMulti }, ref) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
      if (element.id) {
        setSelectedOptions(
          Object.keys(form[`question_${element.id}`]).length !== 0 ? form[`question_${element.id}`] : [],
        );
      }
    }, []);

    useEffect(() => {
      if (initialized) {
        if (selectedOptions.length > 0) {
          setForm((prev: any) => ({
            ...prev,
            [`question_${element.id}`]: selectedOptions,
          }));
        } else {
          setForm((prev: any) => ({
            ...prev,
            [`question_${element.id}`]: [],
          }));
        }
      } else {
        setInitialized(true);
      }
    }, [selectedOptions, initialized]);

    const getComputedEmoji = (option: string): string => {
      switch (option) {
        case 'Very Unhappy':
          return `url(${very_unhappy})`;
        case 'Somewhat Unhappy':
          return `url(${unhappy})`;
        case 'Neutral':
          return `url(${neutral})`;
        case 'Somewhat Happy':
          return `url(${happy})`;
        case 'Very Happy':
          return `url(${very_happy})`;
        default:
          return '';
      }
    };

    useEffect(() => {
      if (element.id) {
        setSelectedOptions(
          form[`question_${element.id}`] !== 'no' && Object.keys(form[`question_${element.id}`]).length !== 0
            ? form[`question_${element.id}`]
            : [],
        );
      }
    }, []);

    const handleOptionChange = (option: string): void => {
      if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    };

    return (
      <div
        className={`${styles.row} row ${styles.medicalFormRow} ${
          isEmoji && selectedOptions && selectedOptions.length
            ? styles.selectedClass
            : !isEmoji
            ? styles.selectedClass
            : ''
        }`}
      >
        <div
          className={styles.questionAndButtonContainer}
          onClick={() => {
            if (isEmoji) {
              setSelectedOptions([]);
            } else {
              setSelectedOptions([]);
            }
          }}
        >
          <div className={`${styles.question} col-12 col-sm-8 px-0`}>
            <div className={`${styles.label} mb-2 mb-md-0`}>
              {element.title}
              {element.is_required ? <span className={styles.redText}> *</span> : ''}
            </div>
          </div>
        </div>
        {isEmoji && (
          <div className={styles.selectSelf} onClick={(e) => e.stopPropagation()}>
            <div className={styles.radio}>
              {element.options &&
                element.options.map((option: string) => (
                  <div key={option}>
                    <label
                      className={`${styles.label} ${
                        selectedOptions.length > 0 && selectedOptions?.includes(option) ? styles.selectedOption : ''
                      }`}
                      htmlFor={`question_${element.id}-${option}`}
                    >
                      <div
                        className={styles.emoji}
                        style={{
                          filter: selectedOptions.includes(option)
                            ? 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(29deg) brightness(105%) contrast(102%)'
                            : '',
                          backgroundImage: getComputedEmoji(option),
                        }}
                      />
                      {isEmoji ? option.replace(/\p{Emoji}+/gu, '').trim() : option}
                    </label>
                    <input
                      id={`question_${element.id}-${option}`}
                      value={option}
                      type={isMulti ? 'checkbox' : 'radio'}
                      onClick={() => handleOptionChange(option)}
                      checked={selectedOptions.includes(option)}
                      ref={ref as any}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  },
);

QuestionAndMultiselect.defaultProps = {
  isEmoji: false,
  medicalHistory: false,
  isMulti: false,
};

export default QuestionAndMultiselect;
