import cn from 'classnames';
import { E164Number } from 'libphonenumber-js';
import React, { forwardRef, useState } from 'react';
import Input from 'react-phone-number-input/input';

import styles from './styles.module.scss';

interface InputProps {
  wrapperClassName?: string;
  inputClass?: string;
  focusClassName?: string;
  isUnderlined?: boolean;
  wrapperStyle?: React.CSSProperties;
  value: E164Number | undefined;
  onChange: (value?: E164Number | undefined) => void;
  errors?: { [key: string]: any };
  tabIndex?: number;
  id?: string;
  disabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
}

const PhoneInput = forwardRef(
  (
    {
      wrapperClassName,
      inputClass,
      focusClassName,
      wrapperStyle,
      isUnderlined,
      value,
      onChange,
      errors,
      tabIndex,
      id,
      disabled,
      onBlur,
    }: InputProps,
    ref,
  ): JSX.Element => {
    const [maxLength, setMaxLength] = useState<number>(14);

    const handleOnChange = (newValue: string): void => {
      onChange(newValue);
      const trimmedValue = newValue && newValue.startsWith('+1') ? newValue.slice(2) : newValue;
      const numberOfDigits = trimmedValue?.replace(/\D/g, '').length; // Count only digits
      setMaxLength(numberOfDigits >= 10 ? newValue?.length : 16);
    };

    return (
      <div className={cn(styles.inputWrapper, wrapperClassName)} style={wrapperStyle}>
        <Input
          defaultCountry="US"
          value={value}
          onChange={handleOnChange}
          className={cn(styles.input, inputClass)}
          maxLength={maxLength}
          tabIndex={tabIndex}
          ref={ref}
          disabled={disabled}
          onBlur={onBlur}
        />
        {isUnderlined && <span className={`${styles.inputFocus} ${focusClassName}`} />}
        {errors?.phone_number?.message && (
          <div className={`${styles.invalidFeedback} invalid-feedback`} style={{ display: 'block' }}>
            {errors.phone_number.message}
          </div>
        )}
        {errors?.iPhoneNumber?.message && id === 'iPhoneNumber' && (
          <div className={`${styles.invalidFeedback} invalid-feedback`} style={{ display: 'block' }}>
            {errors.iPhoneNumber.message}
          </div>
        )}
        {errors?.secondary_iPhoneNumber?.message && id === 'secondary_iPhoneNumber' && (
          <div className={`${styles.invalidFeedback} invalid-feedback`} style={{ display: 'block' }}>
            {errors.secondary_iPhoneNumber.message}
          </div>
        )}
      </div>
    );
  },
);

PhoneInput.defaultProps = {
  wrapperClassName: '',
  inputClass: '',
  focusClassName: '',
  isUnderlined: true,
  wrapperStyle: {},
  errors: undefined,
  tabIndex: 0,
  id: '',
  disabled: false,
  onBlur: undefined,
};

export default PhoneInput;
