import { isValidFullName, isValidZipCode } from '@brands/Utils/validation';
import * as Yup from 'yup';

export const validationSchema = (isSameAddress: boolean, verifyResult: string): any => {
  return Yup.object().shape({
    creditCardFullName:
      verifyResult === 'Failed'
        ? Yup.string().test('creditCardFullName', 'Full Name on card is required', function (value) {
            if (!value) {
              return false;
            }
            if (!isValidFullName(value)) {
              return this.createError({
                message: 'Please enter a valid Full Name',
              });
            }
            return true;
          })
        : Yup.string(),
    creditCardAddress1:
      isSameAddress && verifyResult === 'Failed' ? Yup.string().required('Address is required') : Yup.string(),
    creditCardCity:
      isSameAddress && verifyResult === 'Failed' ? Yup.string().required('City is required') : Yup.string(),
    creditCardState:
      isSameAddress && verifyResult === 'Failed' ? Yup.string().required('State is required') : Yup.string(),
    creditCardZipCode:
      verifyResult === 'Failed'
        ? Yup.string()
            .test('validZipCode', 'Not valid zipcode', (value) => {
              if (value && !isValidZipCode(value)) {
                return false;
              }
              return true;
            })
            .when('creditCardAddress1', {
              is: (val: string) => isSameAddress && val,
              then: Yup.string().required('Zip Code is required'),
              otherwise: Yup.string(),
            })
        : Yup.string(),
  });
};
