import styles from '@brands/Dashboard/Dashboard/Provider/Sections/Schedule/styles.module.scss';
import { selectAuth } from '@brands/store/selectors/auth';
import { formatTimeForDisplay } from '@brands/Utils/formatTime';
import { getParsedTimezoneName } from '@brands/Utils/getParsedTimezoneName';
import React, { useEffect, useState } from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { BsClockHistory, BsListTask } from 'react-icons/bs';

import { Loading } from '../../../../../Components/LoadingSpinner/Loading';
import { useWindowSize } from '../../../../../hooks';
import { useAppSelector } from '../../../../../hooks/useReduxHook';
import {
  ListProviderAvailabilitiesResponse,
  listUserAvailabilities,
} from '../../../../../services/availability/listUserAvailabilities';
import { ProviderAvailability } from '../../../../../services/availability/types/ProviderAvailability';
import { ICase } from '../../../../../services/cases/types/ICase';
import AlertModal from './Components/AlertModal/AlertModal';
import DashboardCalendar from './Components/DashboardCalendar/DashboardCalendar';
import DateOverrides from './Components/DateOverrides/DateOverrides';
import SelectDate, { OverrideHour } from './Components/SelectDateModal/SelectDate';
import WeeklyHours from './Components/WeeklyHours/WeeklyHours';

export const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const Schedule = (): JSX.Element => {
  const screenSize = useWindowSize();
  const { userInfo } = useAppSelector(selectAuth);
  const currentTimezone = getParsedTimezoneName(userInfo?.address?.timezone ?? 'EST');
  const [view, setView] = useState('list');
  const [addDateModal, setAddDateModal] = useState(false);
  const [alertModal, setAlertModal] = useState<{
    cases: ICase[];
    kind: 'override' | 'weekly';
    type: 'delete' | 'update' | 'add';
    selectedDates?: Date[];
    isUnavailable?: boolean;
    slots: ProviderAvailability[];
    overrideSlots?: OverrideHour[];
  } | null>(null);
  const [selectedDates, setSelectedDates] = useState<Date[] | null>(null);
  const [weeklyAvailabilities, setWeeklyAvailabilities] = useState<ProviderAvailability[]>([]);
  const [dateOverrides, setDateOverrides] = useState<ProviderAvailability[]>([]);
  const [loading, setLoading] = useState(false);
  const [requestSending, setRequestSending] = React.useState(false);
  const [hoursTypeSelection, setHoursTypeSelection] = useState<'weekly' | 'date'>('weekly');

  const getAvailabilityList = async (type: 'weekday' | 'date'): Promise<ListProviderAvailabilitiesResponse> => {
    const params = {
      user_id: userInfo.id,
      type,
    };
    const availabilityList = await listUserAvailabilities(userInfo.id, params);
    return availabilityList;
  };

  const convertTimeFormat = (time: string): string => formatTimeForDisplay(time);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      const processNodes = (nodes: ProviderAvailability[]): ProviderAvailability[] =>
        nodes.map((node) => ({
          ...node,
          start_time: convertTimeFormat(node.start_time),
          end_time: convertTimeFormat(node.end_time),
        }));

      const { nodes } = await getAvailabilityList('weekday');
      setWeeklyAvailabilities(processNodes(nodes));

      const { nodes: dateNodes } = await getAvailabilityList('date');
      setDateOverrides(processNodes(dateNodes));

      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (screenSize.width < 1024) {
      setView('list');
    }
  }, [screenSize.width]);

  if (loading) return <Loading dotted />;

  const weeklyHoursComponent = (
    <div className={styles.dateSelectionColumn}>
      <WeeklyHours
        weeklyHours={weeklyAvailabilities}
        setWeeklyHours={setWeeklyAvailabilities}
        setAlertModal={setAlertModal}
      />
    </div>
  );

  const dateOverridesComponent = (
    <div className={styles.dateSelectionColumn}>
      <DateOverrides
        dateOverrides={dateOverrides}
        setDateOverrides={setDateOverrides}
        setAddDateModal={setAddDateModal}
        setAlertModal={setAlertModal}
        requestSending={requestSending}
      />
    </div>
  );

  const dateSelectionColumns = (
    <div className={styles.dateSelectionColumns}>
      {screenSize.width < 1024 && (
        <>
          {hoursTypeSelection === 'weekly' && weeklyHoursComponent}
          {hoursTypeSelection === 'date' && dateOverridesComponent}
        </>
      )}
      {screenSize.width > 1024 && (
        <>
          {weeklyHoursComponent}
          {dateOverridesComponent}
        </>
      )}
    </div>
  );

  return (
    <>
      <div className={styles.scheduleSectionContainer}>
        {screenSize.width > 1024 && (
          <div className={styles.viewSelection}>
            <div className={styles.viewSelectionButtonsContainer}>
              <button
                className={`${styles.viewSelectionButton} ${view === 'list' ? styles.viewSelectionButtonActive : ''}`}
                onClick={() => setView('list')}
                type="button"
              >
                <BsListTask size="1.25rem" />
                List View
              </button>
              <button
                className={`${styles.viewSelectionButton} ${
                  view === 'calendar' ? styles.viewSelectionButtonActive : ''
                }`}
                onClick={() => setView('calendar')}
                type="button"
              >
                <AiOutlineCalendar size="1.25rem" />
                Calendar View
              </button>
            </div>
          </div>
        )}
        {screenSize.width < 1024 && (
          <div className={styles.mobileHoursTypeSelectionContainer}>
            <button
              className={`${styles.viewSelectionButton} ${
                hoursTypeSelection === 'weekly' ? styles.viewSelectionButtonActive : ''
              }`}
              onClick={() => setHoursTypeSelection('weekly')}
              type="button"
            >
              <BsClockHistory size="1.25rem" />
              Set Weekly Hours
            </button>
            <button
              className={`${styles.viewSelectionButton} ${
                hoursTypeSelection === 'date' ? styles.viewSelectionButtonActive : ''
              }`}
              onClick={() => setHoursTypeSelection('date')}
              type="button"
            >
              <AiOutlineCalendar size="1.25rem" />
              Date Overrides
            </button>
          </div>
        )}
        {view === 'list' ? (
          <>
            <div className={styles.timeZoneIndicatorText}>Time Zone: {currentTimezone}</div>
            {dateSelectionColumns}
          </>
        ) : (
          <>
            <div className={styles.timeZoneIndicatorTextCalendar}>Time Zone: {currentTimezone}</div>
            <div className={`${styles.dateSelectionColumns} mt-0 pb-5`}>
              <div className={styles.calendarContainer}>
                <DashboardCalendar
                  weeklyHours={weeklyAvailabilities}
                  dateOverrides={dateOverrides}
                  setDateOverrides={setDateOverrides}
                  setView={setView}
                  setAddDateModal={setAddDateModal}
                  setSelectedDates={setSelectedDates}
                  setAlertModal={setAlertModal}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {addDateModal && (
        <SelectDate
          addDateModal={addDateModal}
          setAddDateModal={setAddDateModal}
          dateOverrides={dateOverrides}
          setDateOverrides={setDateOverrides}
          weeklyAvailabilities={weeklyAvailabilities}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          timezone={currentTimezone}
          setAlertModal={setAlertModal}
          setRequestSending={setRequestSending}
        />
      )}
      {alertModal && (
        <AlertModal
          isOpen={alertModal !== null}
          toggleModal={() => setAlertModal(null)}
          overlaps={alertModal}
          setValues={alertModal.kind === 'weekly' ? setWeeklyAvailabilities : setDateOverrides}
        />
      )}
    </>
  );
};

export default Schedule;
