/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-array-index-key */
import React, { memo, useEffect } from 'react';
import { RemoteAudioTrack, RemoteParticipant } from 'twilio-video';

import { useParticipants, useTracks, useVideoContext } from '../../hooks';
import AudioTrack from '../AudioTrack/AudioTrack';

const Participant = ({ participant }: { participant: RemoteParticipant }) => {
  const { tracks, displayMessage } = useTracks(participant);
  const { setDisplaySSNotification } = useVideoContext();
  useEffect(() => {
    if (displayMessage) {
      setDisplaySSNotification(displayMessage);
      setTimeout(() => {
        setDisplaySSNotification(false);
      }, 2000);
    }
  }, [displayMessage]);
  const audioTrack = tracks.find((track) => track.kind === 'audio') as RemoteAudioTrack | undefined;

  if (audioTrack?.kind === 'audio') return <AudioTrack track={audioTrack} />;

  return null;
};

const ParticipantAudioTracks = () => {
  const { room } = useVideoContext();
  const participants = useParticipants(room);

  return (
    <>
      {participants.map((participant, index) => (
        <Participant key={index} participant={participant} />
      ))}
    </>
  );
};

export default memo(ParticipantAudioTracks);
