import { client } from '../client';

export interface Payload {
  email: string;
  password: string;
}

export interface VerifyPasswordResponse {
  verified: boolean;
}
export const verifyPassword = async (payload: Payload): Promise<VerifyPasswordResponse> => {
  const { data } = await client.post<VerifyPasswordResponse>(`/auth/verify_password`, payload);

  return data;
};
