import { client } from '../client';

export interface HashedUser {
  email: string;
  phone_number: string;
  organizations: Organization[];
}

export interface Organization {
  id: string;
  name: string;
  is_active: boolean;
  credit_card_required: boolean;
}

export const getUserByHash = async (hash: string): Promise<HashedUser> => {
  const { data } = await client.get<HashedUser>(`/auth/${hash}`);

  return data;
};
