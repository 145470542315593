import Container from '@brands/Components/Container/Container';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import useWindowSize from '@brands/hooks/useWindowSize';
import React, { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './smileScanReport.module.scss';

const SmileScanDisclaimer: FC = () => {
  const screenSize = useWindowSize();
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.smileScanDisclaimerSidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.smileScanDisclaimerSidenavToggleText}>Help</div>
          <div className={styles.smileScanDisclaimerSidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <Container
        isBGEclipse={false}
        patientDashboard
        childClassName={styles.dashboard}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
          overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
        }}
      >
        <div className={styles.smileScanDisclaimerBody}>
          <div id="content" className={styles.smileScanDisclaimerContent}>
            <main id="primary" className="site-main">
              <article id="post-889" className="post-889 page type-page status-publish hentry">
                <div className="entry-content">
                  <h2 style={{ marginBottom: '20px' }}>
                    <strong>AI SmileScan Disclaimer</strong>
                  </h2>

                  <p>
                    The information available on the Dentistry.One website and any associated web applications
                    (collectively, the “Online Services”) does not create a patient-provider or similar relationship
                    between you and Mouthwatch, LLC (“We” or “Us”). Such relationships require your mutual agreement
                    with qualified, licensed dental or other healthcare professional (“Provider”).
                  </p>

                  <p>
                    We provide the Online Services as a tool for pre-diagnostic information. They are not medical or
                    diagnostic tools, and we do not claim that they diagnose any specific condition. They do not provide
                    any medical advice and are not evaluated by a Provider. Due to the many variables involved in their
                    use and potential health conditions, we cannot guarantee the accuracy or reliability of the Online
                    Services. We do not warrant that the information provided by the Online Services is complete or
                    exhaustive.
                  </p>

                  <p>
                    You understand and acknowledge that reports provided by the Online Services are based on limited
                    information gathered from the photos and/or scans that you upload, and those may be generated by
                    technologies such as artificial intelligence. These reports are subject to review and verification
                    by a Provider. We strongly encourage you to discuss the report provided by the Online Services with
                    your dentist to ensure an appropriate course of treatment where warranted.
                  </p>

                  <p>
                    The report generated by the Online Services is not a substitute for medical consultation with a
                    Provider. We strongly advise you to consult with a Provider about any aspect of a report, medical
                    advice, or treatment.
                  </p>

                  <p>
                    We provide the Online Services and information they convey on an “as is” and “as available” basis.
                    We use our best efforts to provide accurate information but disclaim any express or implied
                    warranties, including but not limited to implied warranties of merchantability, fitness for a
                    particular purpose, or non-infringement of intellectual property, to the fullest extent permitted by
                    law.
                  </p>

                  <p>
                    Where the Online Services link to other websites or content from third parties, we make no warranty
                    that it has investigated, monitored, or checked those sites or that content for adequacy, accuracy,
                    validity, reliability, availability, or completeness.
                  </p>

                  <p>
                    Use of the Online Services is at your sole risk and in no event shall our liability under any legal
                    or equitable theory exceed the fees actually paid for the Online Services or fifty dollars ($50),
                    whichever is lower. We shall not be liable for any incidental, consequential, or indirect damages
                    relating to or arising from your use of the Online Services, even if you advise us in advance that
                    there is a possibility of same. You agree to defend, indemnify, and hold us harmless from any
                    demands, claims, damages, suits, losses, or other expenses of any kind that arise from or relate to
                    your use of the Online Services, including any claims brought by you or third parties.
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SmileScanDisclaimer;
