/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { TbMicrophone, TbMicrophoneOff } from 'react-icons/tb';

import { useLocalAudioToggle } from '../../../hooks';
import styles from './styles.module.scss';

interface ToggleAudioButtonProps {
  disabled?: boolean;
  className?: string;
}

const ToggleAudioButton: FC<ToggleAudioButtonProps> = ({ disabled, className }): JSX.Element => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  return (
    <button type="button" className={className} disabled={disabled} onClick={toggleAudioEnabled} data-cy-audio-toggle>
      <span className={styles.vcVideoIcon}>
        {isAudioEnabled ? <TbMicrophone size={20} /> : <TbMicrophoneOff size={20} />}
      </span>
    </button>
  );
};

ToggleAudioButton.defaultProps = {
  disabled: false,
  className: '',
};

export default ToggleAudioButton;
