import { client } from '../client';

export interface Payload {
  email: string;
  password: string;
  organization_id?: string;
  division_id?: string;
  practice_id?: string;
  client?: string;
}

export interface LoginResponse {
  access_token?: string;
  refresh_token?: string;
  mfa_method?: string;
  session_id?: string;
}
export const login = async (payload: Payload): Promise<LoginResponse> => {
  const { data } = await client.post<LoginResponse>(`/auth/login`, payload);

  return data;
};
