import qs from 'qs';

import { client } from '../client';
import { Meta } from '../communication/types/Meta';
import { UserProfile } from './types/UserProfile';

export interface IListCA {
  nodes: UserProfile[];
  meta: Meta;
}

export interface IListCAParams {
  limit?: number;
  sort_by?: 'created_at' | 'updated_at';
  sort_direction?: 'asc' | 'desc';
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
}

export const listAllCareAdvisors = async (params?: IListCAParams): Promise<IListCA> => {
  const { data } = await client.get<IListCA>(`/care_advisors?${qs.stringify(params, { skipNulls: true })}`);

  return data;
};
