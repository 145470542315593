/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes, FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (() => void) | any;
  className?: string;
  style?: React.CSSProperties;
  auth?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<Props> = ({ children, type, onClick, className, style, auth, ...rest }): JSX.Element => {
  return (
    <button
      type={type}
      {...(type === 'button' && { onClick })}
      className={`${styles.generalButton} ${className} ${auth && styles.authBtn}`}
      style={style}
      id={rest.id || undefined}
      {...rest}
    >
      {children}
    </button>
  );
};
Button.defaultProps = {
  type: 'button',
  onClick: () => {},
  className: '',
  style: {},
  auth: false,
};
export default Button;
