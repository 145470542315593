import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FormValues = {
  form_question_id: number;
  value: {
    [key: string]: string | string[];
  };
};

type Form = {
  form_id: number;
  form_answer_id: number | null;
  values: FormValues[];
};

export type FormAnswersSliceState = {
  forms: Form[];
  isDentalFormCompleted: boolean;
};

const initialState: FormAnswersSliceState = {
  forms: [],
  isDentalFormCompleted: false,
};

export const formAnswersSlice = createSlice({
  name: 'formAnswers',
  initialState,
  reducers: {
    setFormAnswer(state, action: PayloadAction<Form>) {
      const index = state.forms.findIndex(
        (item) => item.form_id === action.payload.form_id && item.form_answer_id === action.payload.form_answer_id,
      );
      if (index !== -1) {
        Object.assign(state.forms[index], action.payload);
      } else {
        state.forms.push(action.payload);
      }
    },
    setDentalFormCompleted(state, action: PayloadAction<boolean>) {
      Object.assign(state, { isDentalFormCompleted: action.payload });
    },
    deleteFormAnswer(state, action: PayloadAction<Form>) {
      const index = state.forms.findIndex(
        (item) => item.form_id === action.payload.form_id && item.form_answer_id === action.payload.form_answer_id,
      );
      if (index !== -1) {
        state.forms.splice(index, 1);
      }
    },
  },
});

export const { setFormAnswer, setDentalFormCompleted, deleteFormAnswer } = formAnswersSlice.actions;

export default formAnswersSlice.reducer;
