import moment from 'moment';

import { client } from '../client';
import { License } from './types/UserProfile';

export const updateData = (data: License): License => {
  const updatedData = { ...data };
  updatedData.expiry_date = moment(data.expiry_date).format('YYYY-MM-DD');
  return updatedData;
};

export const createProviderLicense = async (payload: License, user_id: number): Promise<License> => {
  const updatedProviderLicenseExpiryDate = updateData(payload);
  const { data } = await client.post<License>(
    `/users/${user_id}/provider_profile/licenses`,
    updatedProviderLicenseExpiryDate,
  );

  return data;
};
