import React, { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import { ICase } from '../../../../services/cases/types/ICase';
import { unassignProvider } from '../../../../services/cases/unassignProvider';
import { UnassignProviderModal } from '../../../../services/forms/types/ImageNote';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from './styles.module.scss';

interface Modal {
  setOpenModal: Dispatch<SetStateAction<UnassignProviderModal>>;
  thisCaseId: number;
  providerName: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  consultationForm: OralHealthReportSubmit;
  saveCaseNotes: (internalCasesNotes: string) => Promise<void>;
}

const RemoveProviderModal = ({
  setOpenModal,
  thisCaseId,
  providerName,
  setLoading,
  thisCase,
  setCase,
  consultationForm,
  saveCaseNotes,
}: Modal): JSX.Element => {
  const removeThisProvider = async (caseId: number): Promise<void> => {
    setLoading(true);
    if (consultationForm.caseNotes !== '') await saveCaseNotes(consultationForm.caseNotes);
    localStorage.removeItem('notes');
    if (thisCase) {
      setLoading(true);
      const updatedCase = await unassignProvider(caseId);
      if (updatedCase) {
        setCase({
          ...thisCase,
          status: updatedCase.status,
          provider: updatedCase.provider,
          provider_id: updatedCase.provider_id,
          updated_at: updatedCase.updated_at,
        });
      }

      setLoading(false);
    }
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    removeThisProvider(thisCaseId);
    setOpenModal({ isOpen: false, caseId: 0, providerName: '' });
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.modalCloseIcon}>
          <button
            type="button"
            onClick={() => setOpenModal({ isOpen: false, caseId: 0, providerName: '' })}
            aria-label="Close Modal"
          >
            <AiFillCloseCircle />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <span>{providerName}</span>
              <span>Are you sure that you want to remove this provider without assigning a new provider?</span>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button type="button" onClick={() => setOpenModal({ isOpen: false, caseId: 0, providerName: '' })}>
              No
            </button>
            <button type="submit">Yes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RemoveProviderModal;
