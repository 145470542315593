import { CaseType, Status } from './ICase';

export const defaultICaseValues = {
  id: 0,
  patient_id: 0,
  care_advisor_id: 0,
  provider_id: 0,
  one_report_id: 0,
  type: CaseType.unknown,
  status: Status.New,
  video_conference_date: null,
  oral_health_report_date: null,
  smile_scan_report: null,
  patient_care_advisor_conversation_sid: '',
  patient_provider_conversation_sid: '',
  provider_care_advisor_conversation_sid: '',
  patient_care_advisor_conversation_stats: null,
  patient_provider_conversation_stats: null,
  provider_care_advisor_conversation_stats: null,
  created_at: '',
  started_at: '',
  updated_at: null,
  patient_vc_stats: null,
  completed_at: '',
  organization: {
    id: '',
    name: '',
    credit_card_required: true,
    professional_corporations: [],
    divisions: [],
    practices: [],
    payment_options: [],
  },
  one_report: {
    id: '',
    patient_id: '',
    care_advisor_id: '',
    provider_id: '',
    case_id: '',
    assessments: '',
    chief_complaint_problem: '',
    pain_scale: 0,
    medical_history: '',
    disposition: '',
    prescription_in_icore: '',
    pharmacy: '',
    need_for_oral_health_coaching: '',
    is_media_utilized: false,
    is_intra_oral_utilized: false,
    is_extra_oral_utilized: false,
    internal_notes: [],
    status: '',
    media: [],
    treatments: [],
    educations: [],
    created_at: '',
    updated_at: '',
    completed_at: '',
    cdts: [
      {
        code: '',
        nomenclature: '',
      },
    ],
    prescriptions: [],
    followUp: {
      care_coordination: false,
      oral_health_coaching: false,

      care_coordination_type: '',
      care_coordination_urgency: '',
      care_coordination_reason: '',

      oral_health_coaching_type: '',
      oral_health_coaching_urgency: '',
      oral_health_coaching_reason: '',
    },
    pharmacies: {
      pharmacy_name: '',
      pharmacy_street_address: '',
      pharmacy_city: '',
      pharmacy_state: '',
      pharmacy_zip_code: '',
      pharmacy_phone_number: '',
    },
    follow_ups: {
      care_coordination: false,
      oral_health_coaching: false,

      care_coordination_type: '',
      care_coordination_other_type: '',
      care_coordination_urgency: '',
      care_coordination_reason: '',

      oral_health_coaching_type: '',
      oral_health_coaching_other_type: '',
      oral_health_coaching_urgency: '',
      oral_health_coaching_reason: '',
    },
  },
  patient: {
    id: 0,
    external_id: '',
    username: '',
    email: '',
    status: '',
    full_name: '',
    phone_number: '',
    picture_media_id: '',
    role: {
      name: '',
    },
    organizations: [],
    basic_info: {
      first_name: '',
      middle_name: '',
      last_name: '',
      gender: '',
      date_of_birth: '',
      pronouns: '',
      languages: [''],
    },
    patient_profile: {
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
      },
      insurances: [
        {
          insured_by: '',
          insurance_membership: {
            member_id: '',
            insurance_carrier: {
              id: 0,
              name: '',
            },
            insurance_carrier_id: 0,
            phone_number: '',
            group_number: '',
            address: {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip_code: '',
              country: '',
            },
          },
          insurance_through: {
            first_name: '',
            date_of_birth: '',
            last_name: '',
            address: {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip_code: '',
              country: '',
            },
          },
        },
      ],
      payment_profile: {
        full_name: '',
        expiration_month: 0,
        expiration_year: 0,
        cc_last_four: 0,
        billing_address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
        },
        is_active: true,
      },
      divisions: [],
      practices: [],
    },
    provider_profile: {
      licenses: [],
      dea_number: '',
      national_provider_id: '',
      education: [],
      primary_specialty: [],
      secondary_specialty: [],
      biography: '',
      plan: [''],
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
      },
      availability: [],
      npi: '',
    },
    care_advisor_profile: {
      biography: '',
      address: {
        state: '',
      },
    },
    created_at: '',
    updated_at: '',
  },
  provider: {
    id: 0,
    external_id: '',
    username: '',
    email: '',
    status: '',
    full_name: '',
    phone_number: '',
    picture_media_id: '',
    role: {
      name: '',
    },
    organizations: [],
    basic_info: {
      first_name: '',
      middle_name: '',
      last_name: '',
      gender: '',
      date_of_birth: '',
      pronouns: '',
      languages: [''],
    },
    patient_profile: {
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
      },
      insurances: [
        {
          insured_by: '',
          insurance_membership: {
            member_id: '',
            insurance_carrier: {
              id: 0,
              name: '',
            },
            insurance_carrier_id: 0,
            phone_number: '',
            group_number: '',
            address: {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip_code: '',
              country: '',
            },
          },
          insurance_through: {
            first_name: '',
            date_of_birth: '',
            last_name: '',
            address: {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip_code: '',
              country: '',
            },
          },
        },
      ],
      payment_profile: {
        full_name: '',
        expiration_month: 0,
        expiration_year: 0,
        cc_last_four: 0,
        billing_address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
        },
        is_active: true,
      },
      divisions: [],
      practices: [],
    },
    provider_profile: {
      licenses: [],
      dea_number: '',
      national_provider_id: '',
      education: [],
      primary_specialty: [],
      secondary_specialty: [],
      biography: '',
      plan: [''],
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
      },
      availability: [],
      npi: '',
    },
    care_advisor_profile: {
      biography: '',
      address: {
        state: '',
      },
    },
    created_at: '',
    updated_at: '',
  },
  care_advisor: {
    id: 0,
    external_id: '',
    username: '',
    email: '',
    status: '',
    full_name: '',
    phone_number: '',
    picture_media_id: '',
    role: {
      name: '',
    },
    organizations: [],
    basic_info: {
      first_name: '',
      middle_name: '',
      last_name: '',
      gender: '',
      date_of_birth: '',
      pronouns: '',
      languages: [''],
    },
    patient_profile: {
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
      },
      insurances: [
        {
          insured_by: '',
          insurance_membership: {
            member_id: '',
            insurance_carrier: {
              id: 0,
              name: '',
            },
            insurance_carrier_id: 0,
            phone_number: '',
            group_number: '',
            address: {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip_code: '',
              country: '',
            },
          },
          insurance_through: {
            first_name: '',
            date_of_birth: '',
            last_name: '',
            address: {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip_code: '',
              country: '',
            },
          },
        },
      ],
      payment_profile: {
        full_name: '',
        expiration_month: 0,
        expiration_year: 0,
        cc_last_four: 0,
        billing_address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
        },
        is_active: true,
      },
      divisions: [],
      practices: [],
    },
    provider_profile: {
      licenses: [],
      dea_number: '',
      national_provider_id: '',
      education: [],
      primary_specialty: [],
      secondary_specialty: [],
      biography: '',
      plan: [''],
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
      },
      availability: [],
      npi: '',
    },
    care_advisor_profile: {
      biography: '',
      address: {
        state: '',
      },
    },
    created_at: '',
    updated_at: '',
  },
  payment_profile: {
    id: 0,
    type: '',
    is_default: false,
    is_active: false,
  },
};
