/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState } from 'react';
import { RemoteParticipant, RemoteTrack } from 'twilio-video';

export default function useTracks(participant: RemoteParticipant) {
  const [tracks, setTracks] = useState<RemoteTrack[]>([]);
  const [displayMessage, setDisplayMessage] = useState(false);

  useEffect(() => {
    const subscribedTracks = Array.from(participant.tracks.values())
      .filter((trackPublication) => trackPublication.track !== null)
      .map((trackPublication) => trackPublication.track!);

    setTracks(subscribedTracks);

    const handleTrackSubscribed = (track: RemoteTrack) => {
      if (track.kind === 'data') {
        track.on('message', (data: string) => {
          const eventPayload = JSON.parse(data);
          if (eventPayload.event === 'screenshot-taken') {
            setDisplayMessage(true);
            setTimeout(() => {
              setDisplayMessage(false);
            }, 2000);
          }
        });

        return;
      }
      setTracks((prevTracks) => [...prevTracks, track]);
    };
    const handleTrackUnsubscribed = (track: RemoteTrack) => {
      setTracks((prevTracks) => prevTracks.filter((t) => t !== track));
    };

    participant.on('trackSubscribed', handleTrackSubscribed);
    participant.on('trackUnsubscribed', handleTrackUnsubscribed);
    return () => {
      participant.off('trackSubscribed', handleTrackSubscribed);
      participant.off('trackUnsubscribed', handleTrackUnsubscribed);
    };
  }, [participant]);

  return { tracks, displayMessage };
}
