import { selectAuth } from '@brands/store/selectors/auth';
import { setShowVideoThumbnail } from '@brands/store/slices/showVideoThumbnailSlice';
import { setIsVisible } from '@brands/store/slices/videoCallCredentialsSlice';
import React, { FC, useEffect } from 'react';
import { TbPhone } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useVideoContext from '../../../hooks/video-hooks/useVideoContext';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import styles from './styles.module.scss';

type EndCallButtonProps = {
  isWaitingRoom?: boolean;
  endCurrentCall?: boolean;
};

const EndCallButton: FC<EndCallButtonProps> = ({ isWaitingRoom, endCurrentCall }) => {
  const { room } = useVideoContext();
  const { userInfo } = useAppSelector(selectAuth);
  let navigate: any;
  const dispatch = useAppDispatch();
  const { setBackgroundSettings } = useVideoContext();

  if (userInfo.role.name === UserRoleName.Patient) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navigate = useNavigate();
  }

  const dispatchSuccessEvent = (event: string, video_room_id: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      userId: userInfo.id,
      video_room_id,
    });
  };

  const endCall = (): void => {
    dispatch(setIsVisible(false));
    dispatchSuccessEvent('video-room-finished', room?.sid || '');
    setBackgroundSettings({ type: 'none', index: 0 });
    dispatch(setShowVideoThumbnail(false));
    if (!isWaitingRoom) {
      room?.disconnect();
      if (userInfo.role.name === UserRoleName.Patient) navigate('/dashboard');
    } else if (userInfo.role.name === UserRoleName.Patient) navigate('/dashboard');
  };

  useEffect(() => {
    (async () => {
      if (endCurrentCall) {
        await endCall();
      }
    })();
  }, [endCurrentCall]);

  return (
    <button type="button" onClick={() => endCall()} aria-label="End Call">
      <span className={`${styles.vcVideoIcon} ${styles.rotate135} ${styles.endCallButton}`}>
        <TbPhone />
      </span>
    </button>
  );
};

EndCallButton.defaultProps = {
  isWaitingRoom: false,
  endCurrentCall: false,
};

export default EndCallButton;
