/* eslint-disable no-param-reassign */
export const trimStringFields = (obj: any): any => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map((item: any) => {
        if (typeof item === 'string') {
          return item.trim();
        }
        if (typeof item === 'object') {
          return trimStringFields(item);
        }
        return item;
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = trimStringFields(obj[key]);
    }
  });
  return obj;
};
