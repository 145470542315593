import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { Organization } from '../../../../services/identity/types/UserProfile';
import {
  checkFutureDate,
  checkValidDate,
  isValidAddress,
  isValidDate,
  isValidEmail,
  isValidName,
  isValidZipCode,
} from '../../../../Utils/validation';

export const profileValidationSchema = (
  reviewProfile: boolean | undefined,
  isAdmin: boolean | undefined,
  isFromSelectPatient: boolean | undefined,
): any => {
  return Yup.object().shape({
    profile_img: Yup.string(),
    first_name: Yup.string()
      .required('First name is required')
      .test('test-name', 'Enter Valid First Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    middle_name: Yup.string().test('test-name', 'Enter Valid Middle Name', (value) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    last_name: Yup.string()
      .required('Last name is required')
      .test('test-name', 'Enter Valid Last Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    gender: Yup.string()
      .required('Gender is required')
      .test('test-name', 'Gender is required', (value) => {
        if (value === '') {
          return false;
        }
        return true;
      }),
    gender_identity:
      !isAdmin || isFromSelectPatient
        ? Yup.string()
            .required('Gender identity is required')
            .test('test-name', 'Gender identity is required', (value) => {
              if (value === '') {
                return false;
              }
              return true;
            })
        : Yup.string(),
    date_of_birth: Yup.string()
      .required('Date of Birth is required')
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const values = value?.split('/');
          if (!checkValidDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value) => {
        if (value) {
          const values = value?.split('/');
          if (!checkFutureDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      }),
    language: Yup.array().min(1, 'Language is required').required('Language is required'),
    address1: Yup.string()
      .required('Address is required')
      .test('test-name', 'Enter Valid Address', (value) => {
        if (value && !isValidAddress(value)) {
          return false;
        }
        return true;
      }),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string()
      .required('Zip Code is required')
      .test('test-name', 'Enter Valid Zip Code', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    email: Yup.string()
      .required('Email is required')
      .test('test-name', 'Enter Valid Email', (value) => {
        if (value && !isValidEmail(value)) {
          return false;
        }
        return true;
      }),
    organization: reviewProfile ? Yup.array().min(1, 'Organization is required') : Yup.array(),
    organizations: reviewProfile
      ? Yup.array()
          .min(1, 'Organization is required')
          .test(
            'has-practice-or-division',
            'Each organization must have at least one practice or division',
            (value) => {
              if (!value) {
                return true;
              }

              const organizations = value as Organization[];

              const hasPracticeOrDivision = organizations.every(
                (org) => org.divisions?.length > 0 || org.practices?.length > 0,
              );
              return hasPracticeOrDivision;
            },
          )
      : Yup.array(),
    phone_number: Yup.string()
      .required('Phone number is required')
      .test('test-name', 'Enter Valid Phone Number', (value) => {
        if (value && !isValidPhoneNumber(value)) {
          return false;
        }
        return true;
      }),
  });
};

export const dependentAddValidationSchema = (
  addSecondaryInsurance: boolean | undefined,
  isAdminRole: boolean | undefined,
): any => {
  return Yup.object().shape({
    profile_img: Yup.string(),
    first_name: Yup.string()
      .required('First name is required')
      .test('test-name', 'Enter Valid First Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    middle_name: Yup.string().test('test-name', 'Enter Valid Middle Name', (value) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    dependent_email: Yup.string().test('test-name', 'Enter Valid Email', (value) => {
      if (value?.trim() && !isValidEmail(value)) {
        return false;
      }
      return true;
    }),
    last_name: Yup.string()
      .required('Last name is required')
      .test('test-name', 'Enter Valid Last Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    gender: Yup.string()
      .required('Gender is required')
      .test('test-name', 'Gender is required', (value) => {
        if (value === '') {
          return false;
        }
        return true;
      }),
    gender_identity: !isAdminRole
      ? Yup.string()
          .required('Gender identity is required')
          .test('test-name', 'Gender identity is required', (value) => {
            if (value === '') {
              return false;
            }
            return true;
          })
      : Yup.string(),
    date_of_birth: Yup.string()
      .required('Date of Birth is required')
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const values = value?.split('/');
          if (!checkValidDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value) => {
        if (value) {
          const values = value?.split('/');
          if (!checkFutureDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      }),
    language: Yup.array().min(1, 'Language is required').required('Language is required'),
    address1: Yup.string()
      .required('Address is required')
      .test('test-name', 'Enter Valid Address', (value) => {
        if (value && !isValidAddress(value)) {
          return false;
        }
        return true;
      }),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string()
      .required('Zip Code is required')
      .test('test-name', 'Enter Valid Zip Code', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    guardian_relationship: Yup.string().required('Relationship is required'),
    insurance_through: Yup.string(),
    insurance_carrier_id: Yup.number().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none' && val !== undefined,
      then: Yup.number().required('Insurance carrier is required'),
      otherwise: Yup.number(),
    }),
    iPhoneNumber: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none' && val !== undefined,
      then: Yup.string().test('test-name', 'Enter Valid Phone Number', (value: any) => {
        if (!value || !isValidPhoneNumber(value)) {
          return false;
        }
        return true;
      }),
      otherwise: Yup.string(),
    }),
    member_id: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Member ID is required')
        .test('no-empty-space', 'Member ID cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    group_number: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Group Number is required')
        .test('no-empty-space', 'Group number cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_address: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Insurance Address is required')
        .test('no-empty-space', 'Insurance Address cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_city: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Insurance City is required')
        .test('no-empty-space', 'Insurance City cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_state: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Insurance State is required')
        .test('no-empty-space', 'Insurance State cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_zip_code: Yup.string()
      .when(['insurance_through'], {
        is: (val: string) => val !== 'none' && val !== undefined,
        then: Yup.string().required('Insurance ZipCode is required'),
      })
      .test('test-name', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    iFirstName: Yup.string(),
    iMiddleName: Yup.string(),
    iLastName: Yup.string(),
    iDateOfBirth: Yup.string()
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value: any) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const values = value?.split('/');
          if (!checkValidDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value: any) => {
        if (value) {
          const values = value?.split('/');
          if (!checkFutureDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      }),
    iAddress1: Yup.string(),
    iCity: Yup.string(),
    iState: Yup.string(),
    iZipCode: Yup.string().test('test-name', 'Not valid zipcode', (value: any) => {
      if (value && !isValidZipCode(value)) {
        return false;
      }
      return true;
    }),
    secondary_insurance_through: Yup.string().test(
      'secondary_insurance_through',
      'Secondary Insurance is required',
      (value) => {
        return addSecondaryInsurance ? value !== undefined && value?.trim() !== '' : true;
      },
    ),
    secondary_insurance_carrier_id: Yup.number().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.number().required('Secondary Insurance carrier ID is required'),
      otherwise: Yup.number(),
    }),
    secondary_member_id: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string().required('Secondary Member ID is required'),
      otherwise: Yup.string(),
    }),
    secondary_group_number: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string().required('Secondary Group Number is required'),
      otherwise: Yup.string(),
    }),
    secondary_iPhoneNumber: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance phone number is required')
        .test('test-name', 'Enter Valid Phone Number', (value) => {
          if (value && !isValidPhoneNumber(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),

    secondary_insurance_address: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Secondary Insurance Address is required')
        .test('no-empty-space', 'Secondary Insurance Address cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_insurance_city: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance City is required'),
      otherwise: Yup.string(),
    }),
    secondary_insurance_state: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance State is required'),
      otherwise: Yup.string(),
    }),
    secondary_insurance_zip_code: Yup.string()
      .when(['secondary_insurance_through'], {
        is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
        then: Yup.string().required('Secondary Insurance ZipCode is required'),
      })
      .test('test-name', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    secondary_iMiddleName: Yup.string(),
    secondary_iFirstName: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder First Name is required')
        .test('test-name', 'Enter Valid First Name', (value) => {
          if (value && !isValidName(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iLastName: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder Last Name is required')
        .test('test-name', 'Enter Valid Last Name', (value) => {
          if (value && !isValidName(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iDateOfBirth: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder Date of birth is required')
        .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value) => {
          if (value && !isValidDate(value)) {
            return false;
          }
          if (value) {
            const datevalues = value?.split('/');
            if (!checkValidDate(datevalues[0], datevalues[1], datevalues[2])) {
              return false;
            }
          }
          return true;
        })
        .test('test-future', 'The Secondary Date of Birth can not be in the future.', (value) => {
          if (value) {
            const datevalues = value?.split('/');
            if (!checkFutureDate(datevalues[0], datevalues[1], datevalues[2])) {
              return false;
            }
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iAddress1: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder Address is required'),
      otherwise: Yup.string(),
    }),
    secondary_iCity: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder city Required'),
      otherwise: Yup.string(),
    }),
    secondary_iState: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder state Required'),
      otherwise: Yup.string(),
    }),
    secondary_iZipCode: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder ZipCode is required')
        .test('test-name', 'Not valid zipcode', (value) => {
          if (value && !isValidZipCode(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
  });
};

export const dependentUpdateValidationSchema = (
  addSecondaryInsurance: boolean | undefined,
  isAdminRole: boolean | undefined,
  isFromSelectPatient: boolean | undefined,
): any => {
  return Yup.object().shape({
    profile_img: Yup.string(),
    first_name: Yup.string()
      .required('First name is required')
      .test('test-name', 'Enter Valid First Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    middle_name: Yup.string().test('test-name', 'Enter Valid Middle Name', (value) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    dependent_email: Yup.string().test('test-name', 'Enter Valid Email', (value) => {
      if (value?.trim() && !isValidEmail(value)) {
        return false;
      }
      return true;
    }),
    last_name: Yup.string()
      .required('Last name is required')
      .test('test-name', 'Enter Valid Last Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    gender: Yup.string()
      .required('Gender is required')
      .test('test-name', 'Gender is required', (value) => {
        if (value === '') {
          return false;
        }
        return true;
      }),
    gender_identity:
      !isAdminRole || isFromSelectPatient
        ? Yup.string()
            .required('Gender identity is required')
            .test('test-name', 'Gender identity is required', (value) => {
              if (value === '') {
                return false;
              }
              return true;
            })
        : Yup.string(),
    date_of_birth: Yup.string()
      .required('Date of Birth is required')
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const values = value?.split('/');
          if (!checkValidDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value) => {
        if (value) {
          const values = value?.split('/');
          if (!checkFutureDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      }),
    language: Yup.array().min(1, 'Language is required').required('Language is required'),
    address1: Yup.string()
      .required('Address is required')
      .test('test-name', 'Enter Valid Address', (value) => {
        if (value && !isValidAddress(value)) {
          return false;
        }
        return true;
      }),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string()
      .required('Zip Code is required')
      .test('test-name', 'Enter Valid Zip Code', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    guardian_relationship: Yup.string().required('Relationship is required'),
  });
};

export const pharmacyValidationSchema = (): any => {
  return Yup.object().shape({
    pharmacy_zip_code: Yup.string().test('test-name', 'Not valid zipcode', (value) => {
      if (value && !isValidZipCode(value)) {
        return false;
      }
      return true;
    }),
    pharmacy_phone_number: Yup.string().test('test-name', 'Enter Valid Phone Number', (value) => {
      if (value && !isValidPhoneNumber(value)) {
        return false;
      }
      return true;
    }),
  });
};

export const insuranceValidationSchema = (addSecondaryInsurance: boolean): any => {
  return Yup.object().shape({
    insurance_through: Yup.string().required('This field is required'),
    insurance_carrier_id: Yup.number().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.number()
        .transform((value, originalValue) => (originalValue === null ? undefined : value))
        .required('Insurance carrier is required'),
      otherwise: Yup.number(),
    }),
    iPhoneNumber: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Insurance phone number is required')
        .test('test-name', 'Enter Valid Phone Number', (value) => {
          if (value && !isValidPhoneNumber(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    member_id: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Member ID is required')
        .test('no-empty-space', 'Member ID cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    group_number: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Group Number is required')
        .test('no-empty-space', 'Group number cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_address: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Insurance Address is required')
        .test('no-empty-space', 'Insurance Address cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_city: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Insurance City is required')
        .test('no-empty-space', 'Insurance City cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_state: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Insurance State is required')
        .test('no-empty-space', 'Insurance State cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_zip_code: Yup.string()
      .when(['insurance_through'], {
        is: (val: string) => val !== 'none' && val !== undefined,
        then: Yup.string().required('Insurance ZipCode is required'),
      })
      .test('test-name', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    iMiddleName: Yup.string(),
    iFirstName: Yup.string().when(['insurance_through'], {
      is: (val: string) => {
        return val !== 'none' && val !== undefined && val !== 'myself';
      },
      then: Yup.string().required('Insurance Holder First Name is required'),
    }),
    iLastName: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself',
      then: Yup.string().required('Insurance Holder Last Name is required'),
    }),
    iDateOfBirth: Yup.string()
      .when(['insurance_through'], {
        is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself',
        then: Yup.string().required('Insurance Holder Date of birth is required'),
      })
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const values = value?.split('/');
          if (!checkValidDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value) => {
        if (value) {
          const values = value?.split('/');
          if (!checkFutureDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      }),
    iAddress1: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself',
      then: Yup.string().required('Insurance Holder Address is required'),
    }),
    iCity: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself',
      then: Yup.string().required('Insurance Holder city Required'),
    }),
    iState: Yup.string().when(['insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself',
      then: Yup.string().required('Insurance Holder state Required'),
    }),
    iZipCode: Yup.string()
      .when(['insurance_through'], {
        is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself',
        then: Yup.string().required('Insurance Holder ZipCode is required'),
      })
      .test('test-name', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    secondary_insurance_through: Yup.string().test(
      'secondary_insurance_through',
      'Secondary Insurance is required',
      (value) => {
        return addSecondaryInsurance ? value?.trim() !== '' : true;
      },
    ),
    secondary_insurance_carrier_id: Yup.number().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.number().required('Secondary Insurance carrier ID is required'),
      otherwise: Yup.number(),
    }),
    secondary_member_id: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string().required('Secondary Member ID is required'),
      otherwise: Yup.string(),
    }),
    secondary_group_number: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string().required('Secondary Group Number is required'),
      otherwise: Yup.string(),
    }),
    secondary_iPhoneNumber: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance phone number is required')
        .test('test-name', 'Enter Valid Phone Number', (value) => {
          if (value && !isValidPhoneNumber(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_insurance_address: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined,
      then: Yup.string()
        .required('Secondary Insurance Address is required')
        .test('no-empty-space', 'Secondary Insurance Address cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_insurance_city: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance City is required'),
      otherwise: Yup.string(),
    }),
    secondary_insurance_state: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance State is required'),
      otherwise: Yup.string(),
    }),
    secondary_insurance_zip_code: Yup.string()
      .when(['secondary_insurance_through'], {
        is: (val: string) => val !== 'none' && val !== undefined && addSecondaryInsurance,
        then: Yup.string().required('Secondary Insurance ZipCode is required'),
      })
      .test('test-name', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    secondary_iMiddleName: Yup.string(),
    secondary_iFirstName: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder First Name is required')
        .test('test-name', 'Enter Valid First Name', (value) => {
          if (value && !isValidName(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iLastName: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder Last Name is required')
        .test('test-name', 'Enter Valid Last Name', (value) => {
          if (value && !isValidName(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iDateOfBirth: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder Date of birth is required')
        .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value) => {
          if (value && !isValidDate(value)) {
            return false;
          }
          if (value) {
            const datevalues = value?.split('/');
            if (!checkValidDate(datevalues[0], datevalues[1], datevalues[2])) {
              return false;
            }
          }
          return true;
        })
        .test('test-future', 'The Secondary Date of Birth can not be in the future.', (value) => {
          if (value) {
            const datevalues = value?.split('/');
            if (!checkFutureDate(datevalues[0], datevalues[1], datevalues[2])) {
              return false;
            }
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iAddress1: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder Address is required'),
      otherwise: Yup.string(),
    }),
    secondary_iCity: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder city Required'),
      otherwise: Yup.string(),
    }),
    secondary_iState: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder state Required'),
      otherwise: Yup.string(),
    }),
    secondary_iZipCode: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== undefined && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder ZipCode is required')
        .test('test-name', 'Not valid zipcode', (value) => {
          if (value && !isValidZipCode(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
  });
};
