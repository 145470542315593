import { Pricings } from '@brands/services/identity/getPricings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  currentOrganizationPrices: Pricings[] | null;
}

const initialState: AuthState = {
  currentOrganizationPrices: null,
};

export const organizationPricingSlice = createSlice({
  name: 'organizationPrices',
  initialState,
  reducers: {
    setOrganizationPrices: (state, action: PayloadAction<{ currentOrganizationPrices: Pricings[] | null }>) => {
      return { currentOrganizationPrices: action.payload.currentOrganizationPrices };
    },
  },
});

export const { setOrganizationPrices } = organizationPricingSlice.actions;

export default organizationPricingSlice.reducer;
