import { client } from '../client';

export interface Payload {
  email: string;
}

export interface ResendConfirmationResponse {
  success: boolean;
}
export const resendConfirmationCode = async (payload: Payload): Promise<ResendConfirmationResponse> => {
  const { data } = await client.post<ResendConfirmationResponse>(`/auth/resend_confirmation_code`, payload);

  return data;
};
