import { client } from '../client';
import { IFormAnswer } from './types/IFormAnswer';

type FormValues = {
  form_question_id: number;
  value: {
    [key: string]: string | number | string[] | File[];
  };
};

type FormAnswerPayload = {
  form_id: number;
  user_id: number;
  values: FormValues[];
  dependent_id?: number;
};

export const createFormAnswer = async (payload: FormAnswerPayload): Promise<IFormAnswer> => {
  const { data } = await client.post<IFormAnswer>('/form_answers', payload);

  return data;
};
