/* eslint-disable @typescript-eslint/naming-convention */
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { isValidAddress, isValidEmail, isValidName, isValidZipCode } from '../../../../../Utils/validation';

export const validationSchema = Yup.object().shape({
  profile_img: Yup.string(),
  first_name: Yup.string()
    .required('First name is required')
    .test('test-name', 'Enter Valid First Name', (value: any) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
  last_name: Yup.string()
    .required('Last name is required')
    .test('test-name', 'Enter Valid Last Name', (value: any) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
  email: Yup.string()
    .required('Email is required')
    .test('test-name', 'Enter Valid Email', (value: any) => {
      if (value && !isValidEmail(value)) {
        return false;
      }
      return true;
    }),
  address1: Yup.string()
    .required('Address is required')
    .test('test-adress1', 'Valid Address is required', (value: any) => {
      return isValidAddress(value);
    }),
  address2: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip_code: Yup.string()
    .required('Zip Code is required')
    .test('test-zipcode', 'Valid Zip Code is required', (value: any) => {
      return isValidZipCode(value);
    }),
  phone_number: Yup.string().test('test-name', 'Enter Valid Phone Number', (value) => {
    if (value && !isValidPhoneNumber(value)) {
      return false;
    }
    return true;
  }),
});
