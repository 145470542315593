/* eslint-disable react/no-array-index-key */
import { getFriendlyNameInsuredBy } from '@brands/Utils/getFriendlyName';
import { genderIdentity } from '@brands/Utils/selectButtonOptions';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React from 'react';
import { FiEdit } from 'react-icons/fi';

import { Insurance } from '../../../../../services/identity/types/UserProfile';
import styles from '../../../styles.module.scss';

type ReadOnlyProfileProps = {
  onEditInsurance: () => void;
  patientInsurance: Insurance[];
};

const ReadOnlyInsurance = ({ onEditInsurance, patientInsurance }: ReadOnlyProfileProps): JSX.Element => {
  const getLabelForGenderIdentity = (value: string): string => {
    const gender = genderIdentity.find((item) => item.value === value);
    return gender ? gender.label : '';
  };
  return (
    <div className={styles.readOnlyProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Insurance Information</span>
        <button className={styles.editBtn} type="button" onClick={onEditInsurance}>
          Edit
          <FiEdit className={styles.editIcon} />
        </button>
      </div>
      {patientInsurance.map((insurance, index) => (
        <div key={index}>
          <div className={styles.detailsContainer}>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Insured by</div>
              <div className={styles.value}>{getFriendlyNameInsuredBy(insurance.insured_by)}</div>
            </div>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Carrier</div>
              <div className={styles.value}>{insurance.insurance_membership?.insurance_carrier?.name || ' '}</div>
            </div>
            {insurance.insured_by !== 'none' && (
              <>
                <div className={styles.readOnlyItem}>
                  <div className={styles.caption}>Member ID</div>
                  <div className={styles.value}>{insurance.insurance_membership?.member_id || ' '}</div>
                </div>
                <div className={styles.readOnlyItem}>
                  <div className={styles.caption}>Group Number</div>
                  <div className={styles.value}>{insurance.insurance_membership?.group_number || ' '}</div>
                </div>
                <div className={styles.readOnlyItem}>
                  <div className={styles.caption}>Phone Number</div>
                  <div className={styles.value}>
                    {insurance.insurance_membership?.phone_number
                      ? parsePhoneNumber(insurance.insurance_membership.phone_number, 'US').formatNational()
                      : ''}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.detailsContainer}>
            {insurance.insured_by && insurance.insured_by !== 'myself' && insurance.insured_by !== 'none' && (
              <div className={styles.moreDetails}>
                <div>
                  <div className={styles.readOnlyItem}>
                    <div className={styles.caption}>Insurance Holder’s First Name</div>
                    <div className={styles.value}>{insurance.insurance_through?.first_name || ' '}</div>
                  </div>
                  {insurance.insurance_through?.middle_name && (
                    <div className={styles.readOnlyItem}>
                      <div className={styles.caption}>Insurance Holder’s Middle Name</div>
                      <div className={styles.value}>{insurance.insurance_through?.middle_name || ' '}</div>
                    </div>
                  )}
                  <div className={styles.readOnlyItem}>
                    <div className={styles.caption}>Insurance Holder’s Last Name</div>
                    <div className={styles.value}>{insurance.insurance_through?.last_name || ' '}</div>
                  </div>
                </div>
                <div>
                  <div className={`${styles.readOnlyItem} ${styles.iDOB}`}>
                    <div className={styles.caption}>Insurance Holder’s Date of Birth</div>
                    <div className={styles.value}>
                      {insurance.insurance_through?.date_of_birth
                        ? moment(insurance.insurance_through.date_of_birth).format('MM/DD/YYYY')
                        : ''}
                    </div>
                  </div>
                  <div className={`${styles.readOnlyItem} ${styles.iDOB}`}>
                    <div className={styles.caption}>Insurance Holder’s Gender Identity</div>
                    <div className={styles.value}>
                      {insurance.insurance_through?.gender_identity
                        ? getLabelForGenderIdentity(insurance.insurance_through?.gender_identity)
                        : ''}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.readOnlyItem}>
                    <div className={styles.caption}>Insurance Holder’s Street Address</div>
                    <div className={styles.value}>{insurance.insurance_through?.address?.address1 || ' '}</div>
                  </div>
                  {insurance.insurance_through?.address?.address2 && (
                    <div className={styles.readOnlyItem}>
                      <div className={styles.caption}>Apt, suite, building</div>
                      <div className={styles.value}>{insurance.insurance_through?.address?.address2 || ' '}</div>
                    </div>
                  )}
                </div>
                <div>
                  <div className={styles.readOnlyItem}>
                    <div className={styles.caption}>City</div>
                    <div className={styles.value}>{insurance.insurance_through?.address?.city || ' '}</div>
                  </div>
                  <div className={styles.readOnlyItem}>
                    <div className={styles.caption}>State</div>
                    <div className={styles.value}>{insurance.insurance_through?.address?.state || ' '}</div>
                  </div>
                  <div className={styles.readOnlyItem}>
                    <div className={styles.caption}>Zip Code</div>
                    <div className={styles.value}>{insurance.insurance_through?.address?.zip_code || ' '}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReadOnlyInsurance;
