/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import CustomCheckbox from '@brands/Components/Inputs/CustomCheckbox/CustomCheckbox';
import { updateOneReport } from '@brands/services/oneReport/updateOneReport';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineLeft } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Collapsible from '../../Components/Collapsible/Collapsible';
import Container from '../../Components/Container/Container';
import { Loading } from '../../Components/LoadingSpinner/Loading';
import { useWindowSize } from '../../hooks';
import useCases from '../../hooks/useCases';
import { useAppSelector } from '../../hooks/useReduxHook';
import useSubscribe, { Subscriber } from '../../hooks/useSubscribe';
import { completeCase } from '../../services/cases/completeCase';
import { CasePayload } from '../../services/cases/createNewCase';
import { getOneCase } from '../../services/cases/getCaseById';
import { defaultICaseValues } from '../../services/cases/types/defaultICaseValues';
import { CaseType, ICase, Status } from '../../services/cases/types/ICase';
import {
  AttachmentNoteState,
  EnforceCAAssignModal,
  ImageNoteState,
  UnassignProviderModal,
} from '../../services/forms/types/ImageNote';
import { ImageWithNotes } from '../../services/forms/types/ImageWithNotes';
import { UserProfile, UserRoleName } from '../../services/identity/types/UserProfile';
import { addOneReportInternalNote } from '../../services/oneReport/addOneReportInternalNote';
import { IOneReport } from '../../services/oneReport/types/IOneReport';
import { getTagsByUserId } from '../../services/tags/getTagsByUserId';
import { displayErrorDetails } from '../../Utils/displayError';
import { checkCollapsibleHeaderColor } from '../../Utils/getCollapsibleHeader';
import { getSecondaryColor } from '../../Utils/getPrimaryColor';
import { getTextForStatus } from '../../Utils/getTextForStatus';
import CancelCaseModal from '../Dashboard/CareAdvisor/Modals/CancelCaseModal/CancelCaseModal';
import CareAdvisorList from '../Dashboard/CareAdvisor/Modals/CCList/CareAdvisorList';
import ConsultTime from '../Dashboard/CareAdvisor/Modals/ConsultTime/ConsultTime';
import ConsultType from '../Dashboard/CareAdvisor/Modals/ConsultType/ConsultType';
import CareAdvisorProfileCardModal from '../ProfileCards/CareAdvisor/ProfileCards';
import ProviderProfileCardModal from '../ProfileCards/Dentist/ProfileCards';
import PatientProfileCardModal from '../ProfileCards/Patient/ProfileCards';
import {
  consultationFormDefaultValues,
  editImageNoteDefaultValues,
  removeAttachmentDefaultValues,
  removeImageDefaultValues,
  unassignProviderModalDefaultValues,
} from './assets/defaultValues';
import { Option, OralHealthReportSubmit } from './assets/types';
import { validationSchema } from './assets/validationSchema';
import AssessmentSection from './CollapsibleSections/AssessmentSection/AssessmentSection';
import EducationSection from './CollapsibleSections/EducationSection/EducationSection';
import ImagesSection from './CollapsibleSections/ImagesSection/ImagesSection';
import InternalCasesNotesSection from './CollapsibleSections/InternalCasesNotesSection/InternalCasesNotesSection';
import ObjectiveSection from './CollapsibleSections/ObjectiveSection/ObjectiveSection';
import PlanSection from './CollapsibleSections/PlanSection/PlanSection';
import ServicesProvidedSection from './CollapsibleSections/ServicesProvided/ServicesProvidedSection';
import SubjectiveSection from './CollapsibleSections/SubjectiveSection/SubjectiveSection';
import ConsultationAdditionalPeople from './Components/ConsultationAdditionalPeople/ConsultationAdditionalPeople';
import ConsultationButton from './Components/ConsultationButton/ConsultationButton';
import ConsulationHeader from './Components/ConsultationHeader/ConsultationHeader';
import ConsultationPatientInfo from './Components/ConsultationPatientInfo/ConsultationPatientInfo';
import EnforceAssignCAModal from './Modals/EnforceAssignCAModal/EnforceAssignCAModal';
import ImageNotesModal from './Modals/ImageNotesModal/ImageNotesModal';
import MedicalInformationModal from './Modals/MedicalInformationModal/MedicalInformationModal';
import ProviderListTable from './Modals/ProviderListModal/ProviderListTable';
import RemoveOneReportAttachment from './Modals/RemoveOneReportAttachment/RemoveOneReportAttachment';
import RemoveOneReportImage from './Modals/RemoveOneReportImage/RemoveOneReportImage';
import RemoveProviderModal from './Modals/RemoveProviderModal/RemoveProviderModal';
import styles from './styles.module.scss';

const ConsultationPage = (): JSX.Element => {
  const navigate = useNavigate();
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const [loading, setLoading] = useState(false);
  const routeCaseId = useParams().caseId as string;
  const [thisCase, setCase] = useState<ICase>(defaultICaseValues);
  const [addRequiredField, setAddRequiredField] = useState<boolean>(
    thisCase.one_report.follow_ups !== null ? true : false,
  );
  const [medicalInformationModal, setMedicalInformationModal] = useState<boolean>(false);
  const { is_visible: isVisible } = useAppSelector(selectVideoCallCredentials);
  const icoreTimeout = useRef<NodeJS.Timeout | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);
  const { userInfo } = useAppSelector(selectAuth);
  const [selectProvidersModal, setSelectProvidersModal] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [profileCardModal, setProfileCardModal] = useState(false);
  const [caProfileCardModal, setCAProfileCardModal] = useState(false);
  const [caProfileCardIdentity, setCAProfileCardIdentity] = useState<UserProfile | null>(null);
  const [providerProfileCardModal, setProviderProfileCardModal] = useState(false);
  const [selectCTModal, setSelectCTModal] = useState(false);
  const [updateCaseStatusModal, setUpdateCaseStatusModal] = useState({
    isopen: false,
    type: '',
  });
  const [selectCTimeModal, setSelectCTimeModal] = useState(false);
  const [selectCCModal, setSelectCCModal] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [profileCardTags, setProfileCardTags] = React.useState<Option[]>([]);
  const [isAdditionalPeople, setIsAdditionalPeople] = useState(false);
  const [consultationForm, setConsultationForm] = useState<OralHealthReportSubmit>(consultationFormDefaultValues);
  const [images, setImages] = useState<ImageWithNotes[]>([]);
  const [editImageNote, setEditImageNote] = useState<ImageNoteState>(editImageNoteDefaultValues);
  const [removeImage, setRemoveImage] = useState<ImageNoteState>(removeImageDefaultValues);
  const [removeAttachment, setRemoveAttachment] = useState<AttachmentNoteState>(removeAttachmentDefaultValues);
  const [unassignProviderModal, setUnassignProviderModal] = useState<UnassignProviderModal>(
    unassignProviderModalDefaultValues,
  );
  const [enforceCaAssignmentModal, setEnforceCaAssignmentModal] = useState<EnforceCAAssignModal>({
    isOpen: false,
    caId: 0,
  });

  const allowedRolesForCommentsToProvider = [
    UserRoleName.Provider,
    UserRoleName.CareAdvisor,
    UserRoleName.Admin,
    UserRoleName.SuperAdmin,
    UserRoleName.OrganizationAdmin,
  ];

  useEffect(() => {
    if (thisCase.one_report.follow_ups !== null) {
      setAddRequiredField(true);
    }
    else {
      setAddRequiredField(false);
    }
  }, [thisCase]);

  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
    }),
    [],
  );
  const { assignToProvider, assignToCareAdvisor, editCase, editCaseTypeFn } = useCases(params, true);

  const refreshCurrentCase = async (): Promise<void> => {
    if (isStatusUpdating) return;

    setIsStatusUpdating(true);
    setLoading(true);

    try {
      const res = await getOneCase(Number(routeCaseId), {
        include_patient_care_advisor_conversation: true,
        include_patient_provider_conversation: true,
        include_provider_care_advisor_conversation: true,
      });

      if (res) {
        let newUpdatedCase = null;
        if (
          userInfo.role.name === UserRoleName.Provider &&
          res.status === 'accepted_provider' &&
          Number(userInfo.id) === Number(res.provider_id)
        ) {
          newUpdatedCase = await editCase(res.id, { status: 'in_progress_provider' } as CasePayload);
        }

        setCase({
          ...res,
          status: newUpdatedCase ? (newUpdatedCase?.status as Status) : res.status,
        });

        fetchUserTags(res.patient_id);
        setConsultationForm({
          ...consultationForm,
          assessment: res?.one_report ? res?.one_report.assessments || '' : '',
          cdt:
            res?.one_report?.cdts && res?.one_report?.cdts?.length > 0
              ? res?.one_report?.cdts?.map((cdt) => cdt.code)
              : [],
          caseNotes:
            localStorage.getItem('notes') !== null && res.id in JSON.parse(localStorage.getItem('notes')!)
              ? JSON.parse(localStorage.getItem('notes')!)[res.id]
              : '',
          subjective: {
            chief_complaint_problem: res.one_report?.chief_complaint_problem || '',
            medical_history: res.one_report?.medical_history || '',
            pain_scale: res.one_report?.pain_scale?.toString(),
          },
          objective: {
            is_media_utilized:
              res.one_report && res.one_report.is_media_utilized === true
                ? 'Utilized'
                : res.one_report && res.one_report.is_media_utilized === false
                ? 'Not Utilized'
                : '',
            is_intra_oral_utilized:
              res.one_report && res.one_report.is_intra_oral_utilized === true
                ? 'Visualized'
                : res.one_report && res.one_report.is_intra_oral_utilized === false
                ? 'Not visualized'
                : '',
            is_extra_oral_utilized:
              res.one_report && res.one_report.is_extra_oral_utilized === true
                ? 'Visualized'
                : res.one_report && res.one_report.is_extra_oral_utilized === false
                ? 'Not visualized'
                : '',
          },
          plan: {
            disposition: res.one_report?.disposition || '',
            prescription_in_icore: res.one_report?.prescription_in_icore,
            pharmacy: res.one_report?.pharmacy,
            need_for_oral_health_coaching: res.one_report?.need_for_oral_health_coaching,
          },
          educations:
            res?.one_report.educations.length > 0
              ? res?.one_report?.educations
                  ?.map((education) => ({
                    url: education.url,
                    title: education.title,
                    id: education.id,
                  }))
                  .sort((a, b) => Number(a.id) - Number(b.id))
              : [],
          treatments:
            res?.one_report.treatments.length > 0
              ? res?.one_report?.treatments?.map((treatment) => ({
                  category: { label: treatment.category ?? '', value: treatment.category ?? '' },
                  tooth: { label: treatment.tooth_number ?? '', value: treatment.tooth_number ?? '' },
                  toothArea: { label: treatment.tooth_area ?? '', value: treatment.tooth_area ?? '' },
                  urgency: { label: treatment.urgency ?? '', value: treatment.urgency ?? '' },
                  description: treatment.description ?? '',
                  id: treatment.id,
                }))
              : [],
          prescriptions:
            res?.one_report?.prescriptions.length > 0
              ? res?.one_report?.prescriptions?.map((prescription) => ({
                  drug: prescription.drug || '',
                  sig: prescription.sig || '',
                  prescription_date: prescription.prescription_date || '',
                  dispense: prescription.dispense || '',
                  refills: prescription.refills || '',
                  id: prescription.id,
                }))
              : [],
          followUp: res.one_report.follow_ups,
          pharmacies: res.one_report.pharmacies,
          additionalPeople:
            res.video_conference_other_participants !== null && res.video_conference_other_participants !== undefined
              ? res.video_conference_other_participants
              : '',
          isAdditionalPeople:
            res.video_conference_other_participants !== null && res.video_conference_other_participants !== undefined
              ? !!res.video_conference_other_participants
              : false,
          checkboxes: res.one_report?.is_terms_accepted
            ? consultationForm.checkboxes.map((item) => ({
                ...item,
                isSelected: true,
              }))
            : consultationForm.checkboxes.map((item) => ({
                ...item,
                isSelected: false,
              })),
        });
        setIsAdditionalPeople(!!res.video_conference_other_participants);
      }
    } catch (error) {
      displayErrorDetails(error);
    } finally {
      setLoading(false);
      setIsStatusUpdating(false);
    }
  };

  const changeCaseUnreadIndicator = async (): Promise<void> => {
    try {
      const updatedCase = await getOneCase(thisCase.id, {
        include_patient_care_advisor_conversation: true,
        include_patient_provider_conversation: true,
        include_provider_care_advisor_conversation: userInfo.role.name !== UserRoleName.Patient,
      });
      if (updatedCase) {
        setCase({
          ...thisCase,
          patient_care_advisor_conversation_stats: updatedCase.patient_care_advisor_conversation_stats,
          patient_provider_conversation_stats: updatedCase.patient_provider_conversation_stats,
          provider_care_advisor_conversation_stats: updatedCase.provider_care_advisor_conversation_stats,
        });
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const updateCaseForAdditionalInfoAndImages = async (): Promise<void> => {
    try {
      const updatedCase = await getOneCase(thisCase.id, {
        include_patient_care_advisor_conversation: false,
        include_patient_provider_conversation: false,
        include_provider_care_advisor_conversation: false,
      });
      if (updatedCase) {
        setCase({
          ...thisCase,
          additional_info: updatedCase.additional_info,
          one_report: {
            ...thisCase.one_report,
            media: updatedCase.one_report?.media,
          },
        });
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const onMessage = useCallback(
    (message: Record<string, any>) => {
      if (Number(message.case_id) === Number(thisCase.id)) {
        if (['case_new_message_created'].includes(message.command)) {
          if (thisCase && !isEqual(thisCase, defaultICaseValues)) {
            changeCaseUnreadIndicator();
          }
        } else if (['case_add_additional_info'].includes(message.command)) {
          if (thisCase) {
            updateCaseForAdditionalInfoAndImages();
          }
        } else if (['case_updated', 'case_cancelled'].includes(message.command)) {
          refreshCurrentCase();
        }
      }
    },
    [thisCase],
  );

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage,
      commands: ['case_new_message_created', 'case_add_additional_info', 'case_updated', 'case_cancelled'],
    }),
    [onMessage],
  );

  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  const assignCareAdvisor = async (selectedCareAdvisor: number): Promise<void> => {
    try {
      setLoading(true);
      if (thisCase.care_advisor_id === null) {
        const updatedCase = await assignToCareAdvisor(thisCase?.id, { care_advisor_id: selectedCareAdvisor }, true);
        if (updatedCase) {
          setCase({
            ...thisCase,
            status: updatedCase.status,
            care_advisor: updatedCase.care_advisor,
            care_advisor_id: updatedCase.care_advisor_id,
            updated_at: updatedCase.updated_at,
          });
        } else {
          setEnforceCaAssignmentModal({
            isOpen: true,
            caId: selectedCareAdvisor,
          });
        }
      } else {
        setEnforceCaAssignmentModal({
          isOpen: true,
          caId: selectedCareAdvisor,
        });
      }
    } catch (error: any) {
      displayErrorDetails(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMessageFromIcore = (event: MessageEvent): void => {
    if (event.data.result?.status === 'Complete') {
      if (icoreTimeout.current) {
        clearTimeout(icoreTimeout.current);
        icoreTimeout.current = null;
      }
      enqueueSnackbar('Subscriptions added successfully.', {
        variant: 'success',
        autoHideDuration: 3000,
        disableWindowBlurListener: true,
      });
    }
  };

  const handleScroll = (event: any): void => {
    if (window.pageYOffset >= event.currentTarget.scrollTop) {
      setScroll(false);
    } else {
      setScroll(true);
    }
  };

  const completedByProvider = async (): Promise<void> => {
    const payload: CasePayload = {
      status: 'completed_by_provider',
    };

    if (typeof thisCase?.id === 'number') {
      if (thisCase) {
        try {
          setLoading(true);
          const updatedCase = await editCase(thisCase.id, payload);
          if (updatedCase) {
            setCase({
              ...thisCase,
              status: updatedCase.status,
              updated_at: updatedCase.updated_at,
              one_report: updatedCase.one_report,
            });
            // setConsultationForm({
            //   ...consultationForm,
            //   educations: updatedCase.one_report.educations,
            // });
          }
        } catch (error: unknown) {
          displayErrorDetails(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const sendConsultationPageToCA = async (): Promise<void> => {
    const payload: CasePayload = {
      status: 'in_progress_care_advisor',
    };

    if (typeof thisCase?.id === 'number') {
      if (thisCase) {
        try {
          setLoading(true);
          const updatedCase = await editCase(thisCase.id, payload);
          if (updatedCase) {
            setCase({
              ...thisCase,
              status: updatedCase.status,
              care_advisor: updatedCase.care_advisor,
              care_advisor_id: updatedCase.care_advisor_id,
              updated_at: updatedCase.updated_at,
              one_report: updatedCase.one_report,
            });
          }
        } catch (error: unknown) {
          displayErrorDetails(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };
  const submitToPatient = async (): Promise<void> => {
    if (typeof thisCase?.id === 'number') {
      if (thisCase) {
        try {
          setLoading(true);
          const updatedCase = await completeCase(thisCase.id);
          setCase({
            ...thisCase,
            status: updatedCase?.status || '',
            updated_at: updatedCase?.updated_at || '',
            one_report: updatedCase.one_report,
            completed_at: updatedCase.completed_at,
          });
        } catch (error: unknown) {
          displayErrorDetails(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const saveCaseNotes = async (internalCasesNotes: string): Promise<void> => {
    await addOneReportInternalNote(thisCase?.one_report.id || '', {
      note: internalCasesNotes,
    });
    const currentCase = await getOneCase(Number(routeCaseId));
    setCase({
      ...thisCase,
      one_report: currentCase.one_report,
      updated_at: currentCase.updated_at,
    });
    setConsultationForm({ ...consultationForm, caseNotes: '' });
    setValue('caseNotes', '');
  };

  const onSubmit = async (data: OralHealthReportSubmit): Promise<void> => {
    setLoading(true);

    if (consultationForm.caseNotes.trim() !== '') {
      toast.error('Please save your notes before submitting');
      setLoading(false);
      return;
    }

    localStorage.removeItem('notes');

    setConsultationForm(data);

    // Ensure the case is up-to-date before continuing
    const updatedCase = await getOneCase(Number(routeCaseId), {
      include_patient_care_advisor_conversation: false,
      include_patient_provider_conversation: false,
      include_provider_care_advisor_conversation: false,
    });

    switch (updatedCase?.status) {
      case 'in_progress_provider':
      case 'accepted_provider': {
        if (updatedCase.care_advisor_id) {
          sendConsultationPageToCA();
        } else {
          completedByProvider();
        }
        break;
      }
      case 'in_progress_care_advisor': {
        submitToPatient();
        break;
      }
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<OralHealthReportSubmit>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema(userInfo, thisCase, addRequiredField)),
  });

  const goToDashboard = useCallback(
    () =>
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
        ? navigate('/admin/dashboard')
        : navigate('/dashboard'),
    [navigate],
  );

  const onInvalid = (errorsList: any): void => {
    // Check if errorsList is an array and display the first error message if available
    if (Array.isArray(errorsList) && errorsList.length > 0) {
      const firstError = errorsList[0];
      toast.error(firstError.message || 'An error occurred');
      return;
    }

    // Check if errorsList is an object
    if (typeof errorsList === 'object' && errorsList !== null) {
      const firstKey = Object.keys(errorsList)[0];
      const firstError = errorsList[firstKey];

      // Handle if firstError is an array (like prescriptions array)
      if (Array.isArray(firstError) && firstError.length > 0) {
        const firstArrayError = firstError[0];
        const nestedFieldKey = Object.keys(firstArrayError)[0];
        const nestedFieldError = firstArrayError[nestedFieldKey];

        // Display the message of the nested error if available
        if (nestedFieldError && typeof nestedFieldError === 'object' && nestedFieldError.message) {
          toast.error(nestedFieldError.message);
          return;
        }
      }

      // Check if firstError is an object with further nested errors
      if (firstError && typeof firstError === 'object') {
        const nestedKey = Object.keys(firstError)[0];
        const nestedError = firstError[nestedKey];

        // Display nested error message if available
        if (nestedError && nestedError.message) {
          toast.error(nestedError.message);
          return;
        }
      }

      // Display top-level error message if firstError has a message property
      if (firstError && firstError.message) {
        toast.error(firstError.message);
      }
    }
  };

  const updateSelectedImages = (updatedReport: IOneReport): void => {
    if (!errors?.selectedImages) {
      const selectedImages = updatedReport?.media?.filter((image) => image.is_selected);
      setValue('selectedImages', selectedImages);
    } else {
      const selectedImages = updatedReport?.media?.filter((image) => image.is_selected);
      setValue('selectedImages', selectedImages, { shouldValidate: true });
    }

    setConsultationForm({
      ...consultationForm,
      selectedImages: updatedReport?.media?.filter((image) => image.is_selected),
    });
  };

  const fetchUserTags = async (patientId: number): Promise<void> => {
    if (patientId) {
      const response = await getTagsByUserId(patientId);
      const options: Option[] = response
        .filter((tag: any) => tag.answer)
        .map((item: any) => ({
          value: item.tag.description,
          label: item.note === 'yes' ? item.tag.name : item.note,
        }));
      setProfileCardTags(options);
    }
  };

  useEffect(() => {
    setLoading(true);
    window.addEventListener('message', handleMessageFromIcore);
    refreshCurrentCase();
    setLoading(false);
    return () => {
      window.removeEventListener('message', handleMessageFromIcore);
    };
  }, [routeCaseId]);

  useEffect(() => {
    (async () => {
      if (
        thisCase?.status !== 'completed' &&
        thisCase?.status !== 'canceled' &&
        userInfo.role.name === UserRoleName.Provider
      ) {
        const allCheckboxes = consultationForm.checkboxes;
        const isAllChecked = allCheckboxes.every((item) => item.isSelected);
        if (thisCase?.one_report?.id) {
          await updateOneReport(thisCase.one_report.id, {
            is_terms_accepted: isAllChecked,
          });
        }
      }
    })();
  }, [consultationForm.checkboxes]);

  const prevConsultationFormRef = useRef<OralHealthReportSubmit>(consultationForm);

  useEffect(() => {
    if (consultationForm) {
      const prevConsultationForm = prevConsultationFormRef.current;

      const changedFields = (Object.keys(consultationForm) as (keyof OralHealthReportSubmit)[]).filter((key) => {
        return consultationForm[key] !== prevConsultationForm[key];
      });

      if (changedFields.length > 0) {
        changedFields.forEach((key) => {
          setValue(key, consultationForm[key]);
        });

        const currentSelectedImages = thisCase.one_report?.media?.filter((image) => image.is_selected);
        if (currentSelectedImages.length !== (prevConsultationForm.selectedImages?.length || 0)) {
          setValue('selectedImages', currentSelectedImages);
        }

        prevConsultationFormRef.current = consultationForm;
      }
    }
  }, [consultationForm, thisCase.one_report?.media]);

  useEffect(() => {
    if (consultationForm) {
      Object.keys(consultationForm).forEach((key: unknown) => {
        setValue(key as keyof OralHealthReportSubmit, consultationForm[key as keyof OralHealthReportSubmit]);
      });
      setValue(
        'selectedImages',
        thisCase.one_report?.media?.filter((image) => image.is_selected),
      );
    }
  }, [routeCaseId]);

  if (
    loading ||
    !thisCase ||
    !userInfo ||
    getTextForStatus(userInfo.role.name, thisCase?.type as string, thisCase?.status) === ''
  ) {
    return <Loading fullScreen />;
  }

  return (
    <div className={styles.scrollableDiv} onScroll={handleScroll}>
      <Container
        isBGEclipse={false}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
        }}
        childClassName={styles.consultationPage}
        isSidenavVisible
        isProvider={userInfo.role.name === UserRoleName.Provider}
      >
        <div id="navbar" className={styles.navbar}>
          <div className={`${styles.consultationStatus}`}>
            <span className={styles.caseNumberSpan}>Case Id: {thisCase.id}</span>
            <div className={styles.flexHeaderContainer}>
              <div className={styles.backBtnContainer}>
                <button
                  type="button"
                  className={scroll ? styles.backBtnSticky : styles.backBtn}
                  onClick={() => (isVisible && isMobile ? goToDashboard() : navigate(-1))}
                >
                  <AiOutlineLeft /> Back
                </button>
              </div>
              {(errors.selectedImages || errors.assessment || errors.treatments || errors.plan || errors.objective) &&
              thisCase?.status !== 'completed' &&
              thisCase?.status !== 'canceled' &&
              !(
                (userInfo.role.name === UserRoleName.CareAdvisor ||
                  userInfo.role.name === UserRoleName.Admin ||
                  userInfo.role.name === UserRoleName.SuperAdmin ||
                  userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                (thisCase?.status === 'in_progress_provider' || thisCase?.status === 'accepted_provider')
              ) ? (
                <div className={styles.errorNotification}>
                  <span>Please fill in all required fields before submitting</span>
                </div>
              ) : null}
            </div>
            <ConsulationHeader
              thisCase={thisCase}
              setUpdateCaseStatusModal={setUpdateCaseStatusModal}
              setSelectCTModal={setSelectCTModal}
              setSelectCTimeModal={setSelectCTimeModal}
              assignCareAdvisor={assignCareAdvisor}
              setProviderProfileCardModal={setProviderProfileCardModal}
              setSelectProvidersModal={setSelectProvidersModal}
              setUnassignProviderModal={setUnassignProviderModal}
              setCAProfileCardModal={setCAProfileCardModal}
              setSelectCCModal={setSelectCCModal}
            />
          </div>
        </div>
        <div className={styles.container} ref={containerRef} id="menu">
          <div className={styles.consultationDetails}>
            <div className={styles.patientInfo}>
              <ConsultationPatientInfo
                thisCase={thisCase}
                profileCardTags={profileCardTags}
                setProfileCardModal={setProfileCardModal}
                setMedicalInformationModal={setMedicalInformationModal}
              />
            </div>
            <form className={styles.oralHealthReport} onSubmit={handleSubmit(onSubmit, onInvalid)}>
              <div className="row">
                <div className={styles.ohrTitle}>Oral Health Report</div>
              </div>
              <hr className={styles.ohrHr} />
              <div className="row">
                {allowedRolesForCommentsToProvider.includes(userInfo.role.name) &&
                  consultationForm.checkboxes.map((item) => {
                    return checkCollapsibleHeaderColor(thisCase || '', userInfo, 'APPDC') === 'edit' ? (
                      <CustomCheckbox
                        label={item.label}
                        checked={(thisCase.one_report?.is_terms_accepted ?? false) || item.isSelected}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const updatedCheckboxes = consultationForm.checkboxes.map((checkbox) => {
                            if (checkbox.label === item.label) {
                              return {
                                ...checkbox,
                                isSelected: isChecked,
                              };
                            }
                            return checkbox;
                          });
                          setConsultationForm((prevState) => ({
                            ...prevState,
                            checkboxes: updatedCheckboxes,
                          }));
                        }}
                        key={item.value}
                        containerClassName="mb-3"
                        size="16px"
                        textStyles={{ fontSize: '12px', marginLeft: '0.25rem', fontWeight: 'unset' }}
                        errors={errors}
                        disabled={userInfo.role.name !== UserRoleName.Provider}
                      />
                    ) : (
                      <CustomCheckbox
                        label={item.label}
                        checked={item.isSelected}
                        onChange={() => {}}
                        key={item.value}
                        containerClassName="mb-3"
                        size="16px"
                        textStyles={{ fontSize: '12px', marginLeft: '0.25rem', fontWeight: 'unset' }}
                        disabled
                      />
                    );
                  })}

                {(thisCase.type === CaseType.video_call_scheduled ||
                  thisCase.type === CaseType.phone_call_scheduled) && (
                  <ConsultationAdditionalPeople
                    thisCase={thisCase}
                    setCase={setCase}
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    isAdditionalPeople={isAdditionalPeople}
                    setIsAdditionalPeople={setIsAdditionalPeople}
                    setValue={setValue}
                    register={register}
                    errors={errors}
                    editCase={editCase}
                  />
                )}
              </div>
              <div className="row">
                <Collapsible
                  header={
                    <div className={styles.collapsibleHeader}>
                      {thisCase.type === 'second_opinion' ? 'Attachments' : 'Images'}
                    </div>
                  }
                  error={!!errors.selectedImages}
                  thisCaseStatus={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Images')}
                  userRole={userInfo.role.name}
                  currentStatus={thisCase?.status || ''}
                >
                  <ImagesSection
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    thisCase={thisCase}
                    setCase={setCase}
                    userInfo={userInfo}
                    setValue={setValue}
                    errors={errors}
                    images={images}
                    setImages={setImages}
                    setEditImageNote={setEditImageNote}
                    setRemoveAttachment={setRemoveAttachment}
                    setRemoveImage={setRemoveImage}
                    updateSelectedImages={updateSelectedImages}
                  />
                </Collapsible>
              </div>
              <div className="row mt-4">
                <Collapsible
                  header={
                    <div className={styles.collapsibleHeader}>
                      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'readOnly'
                        ? 'Subjective'
                        : 'Subjective'}
                    </div>
                  }
                  thisCaseStatus={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment')}
                  userRole={userInfo.role.name}
                  currentStatus={thisCase?.status || ''}
                >
                  <SubjectiveSection
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    thisCase={thisCase}
                    setCase={setCase}
                    userInfo={userInfo}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    errors={errors}
                  />
                </Collapsible>
              </div>
              <div className="row mt-4">
                <Collapsible
                  header={
                    <div className={styles.collapsibleHeader}>
                      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'readOnly'
                        ? 'Objective'
                        : 'Objective *'}
                    </div>
                  }
                  error={!!errors.objective}
                  thisCaseStatus={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment')}
                  userRole={userInfo.role.name}
                  currentStatus={thisCase?.status || ''}
                >
                  <ObjectiveSection
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    thisCase={thisCase}
                    setCase={setCase}
                    userInfo={userInfo}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    errors={errors}
                  />
                </Collapsible>
              </div>
              <div className="row mt-4">
                <Collapsible
                  header={
                    <div className={styles.collapsibleHeader}>
                      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'readOnly'
                        ? 'Assessment'
                        : 'Assessment *'}
                    </div>
                  }
                  error={!!errors.assessment}
                  thisCaseStatus={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment')}
                  userRole={userInfo.role.name}
                  currentStatus={thisCase?.status || ''}
                >
                  <AssessmentSection
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    thisCase={thisCase}
                    setCase={setCase}
                    userInfo={userInfo}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    errors={errors}
                  />
                </Collapsible>
              </div>
              <div className="row mt-4">
                <Collapsible
                  header={
                    <div className={styles.collapsibleHeader}>
                      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'readOnly'
                        ? 'Services Provided During This Visit'
                        : 'Services Provided During This Visit *'}
                    </div>
                  }
                  error={!!errors.cdt}
                  thisCaseStatus={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment')}
                  userRole={userInfo.role.name}
                  currentStatus={thisCase?.status || ''}
                >
                  <ServicesProvidedSection
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    thisCase={thisCase}
                    setCase={setCase}
                    userInfo={userInfo}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    errors={errors}
                  />
                </Collapsible>
              </div>
              <div className="row mt-4">
                <Collapsible
                  header={
                    <div className={styles.collapsibleHeader}>
                      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'readOnly'
                        ? 'Plan'
                        : 'Plan *'}
                    </div>
                  }
                  error={
                    !!errors.plan ||
                    !!errors.prescriptions ||
                    !!errors.pharmacies ||
                    !!errors.followUp ||
                    !!errors.treatments
                  }
                  thisCaseStatus={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment')}
                  userRole={userInfo.role.name}
                  currentStatus={thisCase?.status || ''}
                >
                  <PlanSection
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    thisCase={thisCase}
                    setCase={setCase}
                    userInfo={userInfo}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    errors={errors}
                    addRequiredField={addRequiredField}
                    setAddRequiredField={setAddRequiredField}
                  />
                </Collapsible>
              </div>
              <div className="row mt-4">
                <Collapsible
                  header={<div className={styles.collapsibleHeader}>Education</div>}
                  thisCaseStatus={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Education')}
                  userRole={userInfo.role.name}
                  currentStatus={thisCase?.status || ''}
                  error={!!errors.educations}
                >
                  <EducationSection
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    thisCase={thisCase}
                    setCase={setCase}
                    userInfo={userInfo}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    errors={errors}
                  />
                </Collapsible>
              </div>
              <div className="row mt-4">
                <Collapsible
                  header={<div className={styles.collapsibleHeader}>Internal Cases Notes</div>}
                  headerStyle={{ backgroundColor: getSecondaryColor() }}
                  thisCaseStatus={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'InternalNotes')}
                  userRole={userInfo.role.name}
                  currentStatus={thisCase?.status || ''}
                >
                  <InternalCasesNotesSection
                    consultationForm={consultationForm}
                    setConsultationForm={setConsultationForm}
                    thisCase={thisCase}
                    userInfo={userInfo}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    errors={errors}
                    saveCaseNotes={saveCaseNotes}
                  />
                </Collapsible>
              </div>
              <ConsultationButton
                thisCase={thisCase}
                consultationForm={consultationForm}
                setConsultationForm={setConsultationForm}
                setCase={setCase}
                loading={loading}
                setLoading={setLoading}
                setSelectProvidersModal={setSelectProvidersModal}
                handleSubmit={handleSubmit}
                trigger={trigger}
                onInvalid={onInvalid}
              />
            </form>
          </div>
        </div>
        {editImageNote.isOpen && (
          <ImageNotesModal
            setOpenModal={setEditImageNote}
            thisCase={thisCase}
            setCase={setCase}
            images={images}
            media_id={editImageNote.media_id}
            media_src={editImageNote.media_src}
            oneReportId={editImageNote.oneReportId ? editImageNote.oneReportId : ''}
            updateSelectedImages={updateSelectedImages}
            readOnly={editImageNote.readOnly || false}
          />
        )}
        {removeImage.isOpen && (
          <RemoveOneReportImage
            setOpenModal={setRemoveImage}
            thisCase={thisCase}
            setCase={setCase}
            images={images}
            media_id={removeImage.media_id}
            media_src={removeImage.media_src}
            oneReportId={removeImage.oneReportId ? removeImage.oneReportId : ''}
            updateSelectedImages={updateSelectedImages}
          />
        )}
        {removeAttachment.isOpen && (
          <RemoveOneReportAttachment
            setOpenModal={setRemoveAttachment}
            thisCase={thisCase}
            setCase={setCase}
            media_id={removeAttachment.media_id}
            media_name={removeAttachment.media_name}
            oneReportId={removeAttachment.oneReportId ? removeAttachment.oneReportId : ''}
          />
        )}
        {unassignProviderModal.isOpen && (
          <RemoveProviderModal
            setOpenModal={setUnassignProviderModal}
            thisCaseId={unassignProviderModal.caseId}
            providerName={unassignProviderModal.providerName}
            setLoading={setLoading}
            thisCase={thisCase}
            setCase={setCase}
            consultationForm={consultationForm}
            saveCaseNotes={saveCaseNotes}
          />
        )}
        {enforceCaAssignmentModal.isOpen && (
          <EnforceAssignCAModal
            setOpenModal={setEnforceCaAssignmentModal}
            caseId={thisCase.id}
            caId={enforceCaAssignmentModal.caId}
            setLoading={setLoading}
            thisCase={thisCase}
            setCase={setCase}
            assignToCareAdvisor={assignToCareAdvisor}
            existCAName={thisCase.care_advisor.full_name}
          />
        )}
        {selectProvidersModal && (
          <ProviderListTable
            openModal={setSelectProvidersModal}
            thisCase={thisCase}
            setCase={setCase}
            consultationForm={consultationForm}
            setLoading={setLoading}
            saveCaseNotes={saveCaseNotes}
            assignToProvider={assignToProvider}
          />
        )}
        {profileCardModal && (
          <PatientProfileCardModal
            isOpen={profileCardModal}
            toggleModal={setProfileCardModal}
            patientIdentity={thisCase?.patient ? thisCase?.patient : null}
            insuranceDetails={thisCase?.insurance}
          />
        )}
        {caProfileCardModal && (
          <CareAdvisorProfileCardModal
            isOpen={caProfileCardModal}
            toggleModal={setCAProfileCardModal}
            caIdentity={caProfileCardIdentity || (thisCase?.care_advisor ? thisCase?.care_advisor : null)}
          />
        )}
        {providerProfileCardModal && (
          <ProviderProfileCardModal
            isOpen={providerProfileCardModal}
            toggleModal={setProviderProfileCardModal}
            providerIdentity={thisCase?.provider ? thisCase?.provider : null}
            patientCurrentStateValue={thisCase.patient_current_state}
            caseId={thisCase.id}
          />
        )}
        {selectCTModal && (
          <ConsultType
            openModal={setSelectCTModal}
            setLoading={setLoading}
            thisCase={thisCase}
            setCase={setCase}
            editCaseTypeFn={editCaseTypeFn}
          />
        )}
        {updateCaseStatusModal.isopen && (
          <CancelCaseModal
            openModal={setUpdateCaseStatusModal}
            thisCase={thisCase}
            setLoading={setLoading}
            setCase={setCase}
            type={updateCaseStatusModal.type}
            consultationForm={consultationForm}
            saveCaseNotes={saveCaseNotes}
          />
        )}
        {selectCTimeModal && (
          <ConsultTime
            openModal={setSelectCTimeModal}
            thisCase={thisCase}
            setCase={setCase}
            setLoading={setLoading}
            editCase={editCase}
          />
        )}
        {selectCCModal && (
          <CareAdvisorList
            openModal={setSelectCCModal}
            assignCareAdvisor={assignCareAdvisor}
            setCAProfileCardIdentity={setCAProfileCardIdentity}
            setCAProfileCardModal={setCAProfileCardModal}
          />
        )}
        {medicalInformationModal && (
          <MedicalInformationModal
            isOpen={medicalInformationModal}
            toggleModal={setMedicalInformationModal}
            patientId={thisCase?.patient_id}
          />
        )}
        <form
          className={styles.form}
          id="icoreForm"
          target="_blank"
          action={`${process.env.REACT_APP_ICORE_DOMAIN}/api/v1/practice.php`}
          method="POST"
        >
          <input type="text" id="icoreFormData" name="data" readOnly />
          <input type="submit" />
        </form>
      </Container>
    </div>
  );
};
export default ConsultationPage;
