import { client } from '../client';

export interface InsuranceCarrier {
  id: number;
  name: string;
}

export const listInsuranceCarriers = async (): Promise<InsuranceCarrier[]> => {
  const { data } = await client.get('/insurance_carriers');

  return data;
};
