import { Conversation } from '@twilio/conversations';

import { IGMConversation } from '../services/communication/types/IGMConversation';
import { IGMConversationParticipant } from '../services/communication/types/IGMConversationParticipant';
import { mapToGMParticipant } from './mapToGMParticipant';

export const mapToGMConversation = async (
  conversation: Conversation,
  includeParticipants?: boolean,
): Promise<IGMConversation> => {
  let participants: IGMConversationParticipant[] | null = null;

  if (includeParticipants) {
    const twParticipants = await conversation.getParticipants();
    participants = twParticipants.map((p) => mapToGMParticipant(p));
  }
  return {
    sid: conversation.sid,
    friendly_name: conversation.friendlyName,
    state: conversation.state!.current,
    attributes: conversation.attributes as { [x: string]: any },
    created_at: conversation.dateCreated!.toISOString(),
    updated_at: conversation.dateUpdated!.toISOString(),
    participants,
  };
};
