import React, { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import styles from './styles.module.scss';

type Props = {
  errors: any;
  setValue: UseFormSetValue<any>;
  form: any;
  value: string;
  options: { id: number; value: string; label: string }[];
};

const SelectButtons: React.FC<Props> = ({ errors, setValue, form, value, options }) => {
  const [isSelected, setIsSelected] = React.useState<number>();
  const addSelectedClass = (e: React.MouseEvent<HTMLButtonElement>, bNumber: number): void => {
    setValue(value, e.currentTarget.value, { shouldValidate: true });
    setIsSelected(bNumber);
  };
  useEffect(() => {
    let selectedIndex = 0;
    selectedIndex = options.findIndex((option) => form[value] === option.value);
    setIsSelected(selectedIndex + 1);
  }, [form, options, value]);
  return (
    <div className={styles.container}>
      <div className={styles.errorMessage} style={{ display: errors[value]?.message ? 'block' : 'none' }}>
        {errors[value]?.message}
      </div>
      <div className={styles.flexWrap}>
        {options.map((selection) => (
          <button
            className={
              isSelected === selection.id
                ? `${styles.multiSelector} ${styles.buttonSelected}`
                : `${styles.multiSelector}`
            }
            value={selection.value}
            type="button"
            onClick={(e) => addSelectedClass(e, selection.id)}
            key={selection.id}
          >
            <span>{selection.label}</span>
            <div className={styles.checkInput} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectButtons;
