import { selectAuth } from '@brands/store/selectors/auth';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { useAppSelector } from '../../../hooks/useReduxHook';
import { isValidEmail } from '../../../Utils/validation';
import Button from '../../Button/Button';
import Modal from '../Modal';
import styles from './styles.module.scss';

interface UpdateEmailAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (email: string) => void;
}

const UpdateEmailAddressModal: FC<UpdateEmailAddressModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const { userInfo } = useAppSelector(selectAuth);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setEmail('');
    setError('');
  }, [isOpen]);

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    setError('');
    setEmail(event.target.value);
  };

  const onUpdate = (): void => {
    setError('');
    if (!email) {
      setError('Email address is required.');
      return;
    }

    if (!isValidEmail(email)) {
      setError('Email address is not valid.');
      return;
    }

    if (email === userInfo.email) {
      setError("You can't use the same email address.");
      return;
    }

    onSubmit(email);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.content}>
        <p className={styles.title}>Update email address</p>
        <p className={styles.label}>
          Email address <span>*</span>
        </p>
        <input className={styles.input} type="text" value={email} onChange={onChangeEmail} />
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.actionBtnContainer}>
          <Button type="button" className={styles.cancel} onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" disabled={!email} className={styles.submit} onClick={onUpdate}>
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateEmailAddressModal;
