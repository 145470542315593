import { defaultFormValues } from '@brands/Dashboard/MyProfile/OrganizationAdmin/utils/defaultFromValues';
import { OrganizationAdminMyProfileSubmit } from '@brands/Dashboard/MyProfile/OrganizationAdmin/utils/types';
import { UserProfile } from '@brands/services/identity/types/UserProfile';

export const mapProfileToMyProfileSubmit = (
  organizationAdminProfile: UserProfile,
): OrganizationAdminMyProfileSubmit => {
  const mappedProfile: OrganizationAdminMyProfileSubmit = {
    ...defaultFormValues,
    first_name: organizationAdminProfile.basic_info?.first_name || '',
    last_name: organizationAdminProfile.basic_info?.last_name || '',
    email: organizationAdminProfile.email || '',
    organizations:
      organizationAdminProfile.organizations?.map((org) => {
        const matchingDivision = organizationAdminProfile.organization_admin_profile?.divisions?.find(
          (division) => division?.organization_id === org.id,
        );

        const matchingPractice = organizationAdminProfile.organization_admin_profile?.practices?.find(
          (practice) => practice?.organization_id === org.id,
        );

        return {
          ...org,
          divisions: matchingDivision ? [matchingDivision] : [],
          practices: matchingPractice ? [matchingPractice] : [],
        };
      })[0] || {},
    organization: {} as any,
    address1: organizationAdminProfile?.address?.address1 || '',
    address2: organizationAdminProfile?.address?.address2 || '',
    zip_code: organizationAdminProfile?.address?.zip_code || '',
    state: organizationAdminProfile?.address?.state || '',
    city: organizationAdminProfile?.address?.city || '',
    phone_number: organizationAdminProfile?.phone_number || '',
  };
  return mappedProfile;
};
