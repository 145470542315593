import qs from 'qs';

import { client } from '../client';
import { Meta } from '../communication/types/Meta';
import { INotification } from './types/INotification';

export interface IListNotifications {
  nodes: INotification[];
  meta: Meta;
  unread_count: number;
}

export interface IListNotificationsParams {
  limit?: number;
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
}

export const listNotifications = async (params?: IListNotificationsParams): Promise<IListNotifications> => {
  const { data } = await client.get<IListNotifications>(
    `/notifications${params ? `?${qs.stringify(params, { skipNulls: true })}` : ''}`,
  );

  return data;
};
