export const base64ListToFileList = (base64OrList: string | string[]): File | File[] | null => {
  if (!base64OrList) {
    return null;
  }
  if (Array.isArray(base64OrList)) {
    const base64List = base64OrList;
    const files = base64List.map((base64) => {
      const binaryData = atob(base64.replace(/^data:.*,/, ''));
      const binaryArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        binaryArray[i] = binaryData.charCodeAt(i);
      }
      return new File([binaryArray.buffer], 'patient-intake', { type: 'application/octet-stream' });
    });
    return files;
  }
  const base64 = base64OrList;
  const binaryData = atob(base64.replace(/^data:.*,/, ''));
  const binaryArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    binaryArray[i] = binaryData.charCodeAt(i);
  }
  return new File([binaryArray.buffer], 'patient-intake', { type: 'application/octet-stream' });
};
