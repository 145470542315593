import { defaultFormValues } from '@brands/Dashboard/MyProfile/CareAdvisor/utils/defaultFromValues';
import { CAMyProfileSubmit } from '@brands/Dashboard/MyProfile/CareAdvisor/utils/types';
import { UserProfile } from '@brands/services/identity/types/UserProfile';

export const mapProfileToMyProfileSubmit = (careAdvisorProfile: UserProfile): CAMyProfileSubmit => {
  const mappedProfile: CAMyProfileSubmit = {
    ...defaultFormValues,
    first_name: careAdvisorProfile.basic_info?.first_name || '',
    middle_name: careAdvisorProfile.basic_info?.middle_name || '',
    last_name: careAdvisorProfile.basic_info?.last_name || '',
    gender: careAdvisorProfile.basic_info?.gender || '',
    pronouns: careAdvisorProfile.basic_info?.pronouns || '',
    gender_identity: careAdvisorProfile.basic_info?.gender_identity || '',
    biography: careAdvisorProfile.care_advisor_profile?.biography || '',
    languages: careAdvisorProfile.basic_info?.languages || [''],
    state: careAdvisorProfile?.address?.state || '',
    language: [],
    phone_number: careAdvisorProfile?.phone_number || '',
    email: careAdvisorProfile?.email || '',
    address1: careAdvisorProfile?.address?.address1 || '',
    address2: careAdvisorProfile?.address?.address2 || '',
    zip_code: careAdvisorProfile?.address?.zip_code || '',
    city: careAdvisorProfile?.address?.city || '',
  };
  return mappedProfile;
};
