export const defaultFormValues = {
  profile_img: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  pronouns: '',
  gender: '',
  gender_identity: '',
  state: '',
  address1: '',
  address2: '',
  zip_code: '',
  city: '',
  language: [],
  languages: [],
  biography: '',
  avatar: '',
  phone_number: '',
};
