import { client } from '../client';
import { IOneReport } from './types/IOneReport';
import { ObjectType } from './updateOneReportObject';

export const deleteOneReportObject = async (
  one_report_id: string,
  object_id: string,
  object_type: ObjectType,
): Promise<IOneReport> => {
  const { data } = await client.delete<IOneReport>(`/one_reports/${one_report_id}/${object_type}/${object_id}`);

  return data;
};
