import { client } from '../client';
import { IOneReport } from './types/IOneReport';

interface IUpdateOneReportNote {
  note: string;
}

export const addOneReportInternalNote = async (
  one_report_id: string,
  payload: IUpdateOneReportNote,
): Promise<IOneReport> => {
  const { data } = await client.post<IOneReport>(`/one_reports/${one_report_id}/internal_notes`, payload);

  return data;
};
