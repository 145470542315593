import { config } from '@brands/config/config';
import NotFoundPage from '@brands/Dashboard/NotFoundPage/NotFoundPage';
import SmileScanWeb from '@brands/Dashboard/SmileScan/Patient/SmileScanWeb';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';
import { isMobile } from 'react-device-detect';

const ImageCaptureValidation: React.FC = () => {
  const user: AuthState = useAppSelector(selectAuth);
  const getComponent = (): JSX.Element => {
    if (user.userInfo?.role.name === UserRoleName.Patient && config.organization.name === 'default' && !isMobile) {
      return <SmileScanWeb />;
    }
    return <NotFoundPage />;
  };

  return <div>{getComponent()}</div>;
};

export default ImageCaptureValidation;
