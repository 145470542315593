import { ICase } from '../cases/types/ICase';
import { client } from '../client';

export interface UpdatePaymentProfilePayload {
  payment_profile_id: number;
}

export const updatePaymentProfile = async (case_id: number, payload: UpdatePaymentProfilePayload): Promise<ICase> => {
  const { data } = await client.patch(`/cases/${case_id}/payment_profile`, payload);

  return data;
};
