import { client } from '../client';
import { Dependent } from './types/Dependent';

type UpdateDependentPayload = {
  guardian_relationship: string;
  email: string | null;
};

export const updateDependent = async (
  user_id: number,
  dependent_id: number,
  payload: UpdateDependentPayload,
): Promise<Dependent> => {
  const { data } = await client.patch(`/users/${user_id}/dependents/${dependent_id}`, payload);

  return data;
};
