import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useState } from 'react';

import { Meta } from '../services/communication/types/Meta';
import { acknowledgeNotificationById } from '../services/notification/acknowledgeNotification';
import { listNotifications } from '../services/notification/listNotifications';
import { INotification } from '../services/notification/types/INotification';

interface IUseNotifications {
  notifications: INotification[];
  unreadCount: number;
  acknowledgeNotification: (id: number) => Promise<void>;
  reloadAll: () => Promise<void>;
  loadNextPage: () => Promise<void>;
  loadPreviousPage: () => Promise<void>;
  dataLoading: boolean;
}

export default function useNotifications(shouldLoadAllNotifications = false): IUseNotifications {
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [firstPageMeta, setFirstPageMeta] = useState<Meta | null>(null);
  const [lastPageMeta, setLastPageMeta] = useState<Meta | null>(null);

  const acknowledgeNotification = useCallback(
    async (id: number): Promise<void> => {
      const curNotification = notifications.find((n) => n.id === id);
      if (curNotification?.acknowledged_at) return;
      const notification = await acknowledgeNotificationById(id);
      setNotifications((list) => list.map((n) => (n.id === id ? notification : n)));
      setUnreadCount((n) => n - 1);
    },
    [notifications],
  );

  const reloadAll = useCallback(async (): Promise<void> => {
    setDataLoading(true);
    const notificationsList = await listNotifications();
    setNotifications(notificationsList.nodes);
    setFirstPageMeta(notificationsList.meta);
    setLastPageMeta(notificationsList.meta);
    setUnreadCount(notificationsList.unread_count);
    setDataLoading(false);
  }, []);

  const loadNextPage = useCallback(async (): Promise<void> => {
    if (!lastPageMeta?.has_next_page) {
      return;
    }
    const {
      unread_count: count,
      meta,
      nodes,
    } = await listNotifications({
      cursor: lastPageMeta.end_cursor,
      cursor_direction: 'next',
    });
    setLastPageMeta(meta);
    if (!firstPageMeta) {
      setFirstPageMeta(meta);
    }
    setNotifications((prevNotifications) => uniqBy([...prevNotifications, ...nodes], 'id'));
    setUnreadCount(count);
  }, [firstPageMeta, lastPageMeta]);

  const loadPreviousPage = useCallback(async (): Promise<void> => {
    if (!firstPageMeta?.has_previous_page) {
      return;
    }
    const {
      unread_count: count,
      meta,
      nodes,
    } = await listNotifications({
      cursor: firstPageMeta.start_cursor,
      cursor_direction: 'previous',
    });
    setFirstPageMeta(meta);
    if (!lastPageMeta) {
      setLastPageMeta(meta);
    }
    setNotifications((prevNotifications) => uniqBy([...nodes, ...prevNotifications], 'id'));
    setUnreadCount(count);
  }, [firstPageMeta, lastPageMeta]);

  useEffect(() => {
    if (shouldLoadAllNotifications) {
      reloadAll();
    }
  }, [shouldLoadAllNotifications, reloadAll]);

  return {
    notifications,
    unreadCount,
    acknowledgeNotification,
    reloadAll,
    loadNextPage,
    loadPreviousPage,
    dataLoading,
  };
}
