/* eslint-disable consistent-return */
import { selectAuth } from '@brands/store/selectors/auth';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { BsCamera } from 'react-icons/bs';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { RemoteVideoTrack } from 'twilio-video';

import { useScreenShareParticipant, useSpeakerViewParticipants, useVideoContext, useWindowSize } from '../../hooks';
import { useAppSelector } from '../../hooks/useReduxHook';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import { DataTrackManager } from '../../Utils/dataTrack';
import { capture, getBlobFromMediaStream } from '../../Utils/takeSnapshot';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import VideoOptions from '../Buttons/VideoOptions/VideoOptions';
import Participant from '../Participant/Participant';
import VideoRoomSidenav from '../VideoRoomSidenav/VideoRoomSidenav';
import styles from './styles.module.scss';

interface IMenuBarProps {
  participantsOnScreen: boolean;
  setParticipantsOnScreen: Dispatch<SetStateAction<boolean>>;
  setChatOnScreen: (value: boolean) => void;
  chatOnScreen: boolean;
  endCurrentCall?: boolean;
  uploadFile?: (file: File) => Promise<void>;
}

const MenuBar = ({
  participantsOnScreen,
  setParticipantsOnScreen,
  setChatOnScreen,
  chatOnScreen,
  endCurrentCall,
  uploadFile,
}: IMenuBarProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const { room, isSharing, toggleScreenShare, displaySSNotification, participantsDetails } = useVideoContext();
  const { width } = useWindowSize();
  const [participantsOpen, setParticipantsOpen] = useState(true);
  const [isListDisplayed, setIsListDisplayed] = React.useState(false);
  const localParticipant = room?.localParticipant;
  const speakerViewParticipants = useSpeakerViewParticipants();
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  const [src, setSrc] = useState<File>();
  const [displayUpladFileAlert, setDisplayUploadFileAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;

  const getRemoteVideoTrack = async (): Promise<RemoteVideoTrack> => {
    const remoteParticipant = await room?.participants.values().next().value;
    const remoteVideoTrackInstance: RemoteVideoTrack = (await remoteParticipant?.videoTracks.values().next().value
      ?.track) as RemoteVideoTrack;
    return remoteVideoTrackInstance;
  };

  const isPatientIncall = useMemo(() => {
    const patientId = participantsDetails.find(
      (participant) => participant.user.role.name === UserRoleName.Patient,
    )?.identity;
    return speakerViewParticipants.find((participant) => Number(participant.identity) === Number(patientId));
  }, [speakerViewParticipants, participantsDetails]);

  const uploadScreenshot = async (): Promise<void> => {
    if (uploadFile && src) {
      try {
        await uploadFile(src);
        DataTrackManager.sendMessage(JSON.stringify({ event: 'screenshot-taken' }));
        setDisplayUploadFileAlert(true);
        setTimeout(() => {
          setDisplayUploadFileAlert(false);
        }, 2000);
      } catch (error) {
        toast.error('Error uploading screenshot');
      }
    }
  };

  useEffect(() => {
    if (src) {
      uploadScreenshot();
    }
  }, [src]);

  useEffect(() => {
    if (displaySSNotification || displayUpladFileAlert) {
      setDisabled(false);
    }
  }, [displaySSNotification, displayUpladFileAlert]);

  return (
    <>
      {isSharing && (
        <div className={styles.screenShareAlertContainer}>
          <div className={styles.screenShareAlert}>
            Dentistry.One is sharing your screen. <span onClick={toggleScreenShare}>Stop Sharing</span>
          </div>
        </div>
      )}
      {(displayUpladFileAlert || displaySSNotification) && (
        <div className={styles.screenShareAlertContainer}>
          <div className={styles.screenShareAlert}>Photo Saved to Consultation</div>
        </div>
      )}
      <footer className={styles.videoFooter}>
        {isRemoteParticipantScreenSharing && (
          <div
            className={styles.collapsibleSection}
            style={{ height: participantsOpen ? '' : '20px', top: !participantsOpen ? '-20px' : '' }}
          >
            <div className={styles.collapsibleSectionButton}>
              {participantsOpen ? (
                <RiArrowDownSLine className={styles.arrowIcon} onClick={() => setParticipantsOpen(!participantsOpen)} />
              ) : (
                <RiArrowUpSLine className={styles.arrowIcon} onClick={() => setParticipantsOpen(!participantsOpen)} />
              )}
            </div>
            <div className={styles.collapsibleSectionParticipants} style={{ display: !participantsOpen ? 'none' : '' }}>
              {localParticipant && (
                <div className={styles.videoParticipant}>
                  <div className={styles.videoParticipantImage} style={{ height: '100%' }}>
                    <Participant participant={localParticipant} />
                  </div>
                </div>
              )}
              {speakerViewParticipants.map((participant) => (
                <div className={styles.videoParticipant}>
                  <div className={styles.videoParticipantImage}>
                    <Participant key={participant.sid} participant={participant} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          className={styles.videoFooterMenu}
          style={{ height: (isListDisplayed || participantsOnScreen) && width <= 768 ? 'unset' : '' }}
        >
          <div className={`flex flex-row ${styles.vcIconsContainer} w-100`}>
            {(isListDisplayed || participantsOnScreen) && width <= 768 ? null : (
              <>
                <EndCallButton endCurrentCall={endCurrentCall} />
                <ToggleAudioButton />
                <ToggleVideoButton />
              </>
            )}
            {(userInfo.role.name === UserRoleName.Provider ||
              userInfo.role.name === UserRoleName.CareAdvisor ||
              userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin) &&
            isPatientIncall &&
            !((isListDisplayed || participantsOnScreen) && width <= 768) ? (
              <button
                type="button"
                onClick={async () => {
                  setDisabled(true);
                  try {
                    const remoteVideoTrack = await getRemoteVideoTrack();
                    if ('mediaDevices' in navigator && 'ImageCapture' in window) {
                      const res = await capture(remoteVideoTrack);
                      setSrc(res);
                    } else {
                      getBlobFromMediaStream(remoteVideoTrack)
                        .then((blob: any) => {
                          if (blob.size === 0) {
                            setDisabled(false);
                            return;
                          }
                          const file = new File([blob], 'image.png', { type: 'image/png' });
                          setSrc(file);
                        })
                        .catch((err) => {
                          toast.error(err);
                        });
                    }
                  } catch (error) {
                    toast.error('Error capturing screenshot');
                  } finally {
                    setDisabled(false);
                  }
                }}
                data-cy-share-screen
                disabled={disabled}
              >
                <span className={styles.vcVideoIcon} style={{ backgroundColor: disabled ? 'grey' : '' }}>
                  {!disabled ? <BsCamera /> : <div className="spinner-grow text-secondary" role="status" />}
                </span>
              </button>
            ) : null}
            <VideoOptions
              toggleScreenShare={toggleScreenShare}
              participantsOnScreen={participantsOnScreen}
              setParticipantsOnScreen={setParticipantsOnScreen}
              setChatOnScreen={setChatOnScreen}
              chatOnScreen={chatOnScreen}
              isListDisplayed={isListDisplayed}
              setIsListDisplayed={setIsListDisplayed}
            />
          </div>
        </div>
      </footer>
      {isMobile && (
        <div
          className={styles.videoParticipantsDetail}
          style={{
            width: participantsOnScreen ? (width > 768 ? '25%' : '100%') : '0%',
            display: !participantsOnScreen ? 'none' : '',
          }}
        >
          <VideoRoomSidenav
            participantsOnScreen={participantsOnScreen}
            setParticipantsOnScreen={setParticipantsOnScreen}
          />
        </div>
      )}
    </>
  );
};

MenuBar.defaultProps = {
  endCurrentCall: false,
  uploadFile: undefined,
};

export default MenuBar;
