import { ICase } from '../services/cases/types/ICase';
import { UserProfile, UserRoleName } from '../services/identity/types/UserProfile';

export const checkCollapsibleHeaderColor = (currentCase: ICase, userInfo: UserProfile, tabName: string): string => {
  if (
    (currentCase.care_advisor_id === userInfo.id && userInfo.role.name === UserRoleName.CareAdvisor) ||
    userInfo.role.name === UserRoleName.Admin ||
    userInfo.role.name === UserRoleName.SuperAdmin ||
    userInfo.role.name === UserRoleName.OrganizationAdmin
  ) {
    if (tabName === 'Images') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'editImages';
        case 'accepted_provider':
          return 'editImages';
        case 'in_progress_provider':
          return 'editImages';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'APPDC') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'edit';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'edit';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'EditImages') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'editImages';
        case 'accepted_provider':
          return 'editImages';
        case 'in_progress_provider':
          return 'editImages';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'InternalNotes' && userInfo.role.name === UserRoleName.CareAdvisor) {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'edit';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'edit';
        case 'completed':
          return 'edit';
        default:
          return 'edit';
      }
    } else if (
      tabName === 'InternalNotes' &&
      (userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin)
    ) {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'readOnly';
        case 'accepted_provider':
          return 'readOnly';
        case 'in_progress_provider':
          return 'readOnly';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'JoinWaitingRoom') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'edit';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'edit';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'Education') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'readOnly';
        case 'accepted_provider':
          return 'readOnly';
        case 'in_progress_provider':
          return 'readOnly';
        case 'in_progress_care_advisor':
          return 'edit';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'RemoveMedia') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'editImages';
        case 'accepted_provider':
          return 'editImages';
        case 'in_progress_provider':
          return 'editImages';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'ViewNotes') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'readOnly';
        case 'accepted_provider':
          return 'readOnly';
        case 'in_progress_provider':
          return 'editImages';
        case 'in_progress_care_advisor':
          return 'editImages';
        case 'completed':
          return 'editImages';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'Assessment') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'readOnly';
        case 'accepted_provider':
          return 'readOnly';
        case 'in_progress_provider':
          return 'readOnly';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'readOnly';
        case 'accepted_provider':
          return 'readOnly';
        case 'in_progress_provider':
          return 'readOnly';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    }
  } else if (currentCase.provider_id === userInfo.id && userInfo.role.name === UserRoleName.Provider) {
    if (tabName === 'JoinWaitingRoom') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'edit';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'APPDC') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'readOnly';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'EditImages') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'editImages';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'editImages';
        case 'completed':
          return 'editImages';
        default:
          return 'editImages';
      }
    } else if (tabName === 'RemoveMedia') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'editImages';
        case 'accepted_provider':
          return 'editImages';
        case 'in_progress_provider':
          return 'editImages';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'ViewNotes') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'readOnly';
        case 'accepted_provider':
          return 'readOnly';
        case 'in_progress_provider':
          return 'readOnly';
        case 'in_progress_care_advisor':
          return 'editImages';
        case 'completed':
          return 'editImages';
        default:
          return 'readOnly';
      }
    } else if (tabName === 'InternalNotes') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'edit';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'edit';
        case 'completed':
          return 'edit';
        default:
          return 'edit';
      }
    } else if (tabName === 'Assessment') {
      if (currentCase.type === 'second_opinion') {
        switch (currentCase.status) {
          case 'accepted_care_advisor':
            return 'readOnly';
          case 'accepted_provider':
            return 'editNotRequired';
          case 'in_progress_provider':
            return 'editNotRequired';
          case 'in_progress_care_advisor':
            return 'readOnly';
          case 'completed':
            return 'readOnly';
          default:
            return 'readOnly';
        }
      } else {
        switch (currentCase.status) {
          case 'accepted_care_advisor':
            return 'readOnly';
          case 'accepted_provider':
            return 'edit';
          case 'in_progress_provider':
            return 'edit';
          case 'in_progress_care_advisor':
            return 'readOnly';
          case 'completed':
            return 'readOnly';
          default:
            return 'readOnly';
        }
      }
    } else if (tabName === 'E-Prescribe') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'edit';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'edit';
        case 'completed':
          return 'edit';
        default:
          return 'edit';
      }
    }  else if (tabName === 'editPrescriptions') {
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'edit';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'edit';
        case 'completed':
          return 'edit';
        default:
          return 'edit';
      }
    } else
      switch (currentCase.status) {
        case 'accepted_care_advisor':
          return 'readOnly';
        case 'accepted_provider':
          return 'edit';
        case 'in_progress_provider':
          return 'edit';
        case 'in_progress_care_advisor':
          return 'readOnly';
        case 'completed':
          return 'readOnly';
        default:
          return 'readOnly';
      }
  } else return 'readOnly';
};

export const checkEditValid = (currentCase: ICase, userInfo: UserProfile): boolean => {
  if (
    (currentCase.care_advisor_id === userInfo.id && userInfo.role.name === UserRoleName.CareAdvisor) ||
    userInfo.role.name === UserRoleName.Admin ||
    userInfo.role.name === UserRoleName.SuperAdmin ||
    userInfo.role.name === UserRoleName.OrganizationAdmin
  ) {
    switch (currentCase?.status) {
      case 'new':
        return true;
      case 'accepted_care_advisor':
        return true;
      case 'accepted_provider':
        return true;
      case 'in_progress_provider':
        return true;
      case 'in_progress_care_advisor':
        return false;
      default:
        return true;
    }
  } else if (currentCase.provider_id === userInfo.id && userInfo.role.name === UserRoleName.Provider) {
    switch (currentCase?.status) {
      case 'new':
        return false;
      case 'accepted_care_advisor':
        return false;
      case 'accepted_provider':
        return false;
      case 'in_progress_provider':
        return false;
      case 'in_progress_care_advisor':
        return false;
      default:
        return false;
    }
  }
  return false;
};

export const checkAllowCAToCancelCaseOnly = (currentCase: ICase, userRole: string): boolean => {
  if (userRole === UserRoleName.CareAdvisor) {
    switch (currentCase?.status) {
      case 'new':
        return false;
      case 'accepted_care_advisor':
        return true;
      case 'accepted_provider':
        return true;
      case 'in_progress_provider':
        return true;
      case 'completed_by_provider':
        return true;
      case 'in_progress_care_advisor':
        return true;
      case 'completed':
        return false;
      case 'canceled':
        return false;
      default:
        return false;
    }
  }
  return false;
};

export const checkAllowCAToCancelCase = (currentCase: ICase, userInfo: UserProfile): boolean => {
  if (currentCase.care_advisor_id === userInfo.id && userInfo.role.name === UserRoleName.CareAdvisor) {
    switch (currentCase?.status) {
      case 'new':
        return false;
      case 'accepted_care_advisor':
        return true;
      case 'accepted_provider':
        return true;
      case 'in_progress_provider':
        return true;
      case 'in_progress_care_advisor':
        return true;
      case 'completed':
        return false;
      case 'canceled':
        return false;
      default:
        return false;
    }
  } else if (
    userInfo.role.name === UserRoleName.Admin ||
    userInfo.role.name === UserRoleName.SuperAdmin ||
    userInfo.role.name === UserRoleName.OrganizationAdmin
  ) {
    switch (currentCase?.status) {
      case 'new':
        return true;
      case 'accepted_care_advisor':
        return true;
      case 'accepted_provider':
        return true;
      case 'in_progress_provider':
        return true;
      case 'completed_by_provider':
        return true;
      case 'in_progress_care_advisor':
        return true;
      case 'completed':
        return false;
      case 'canceled':
        return false;
      default:
        return false;
    }
  } else if (currentCase.provider_id === userInfo.id && userInfo.role.name === UserRoleName.Provider) {
    switch (currentCase?.status) {
      case 'new':
        return false;
      case 'accepted_care_advisor':
        return false;
      case 'accepted_provider':
        return false;
      case 'in_progress_provider':
        return false;
      case 'in_progress_care_advisor':
        return false;
      case 'completed':
        return false;
      case 'canceled':
        return false;
      default:
        return false;
    }
  }
  return false;
};

export const checkEditValidForCA = (currentCase: ICase, userInfo: UserProfile): boolean => {
  if (
    userInfo.role.name === UserRoleName.CareAdvisor ||
    userInfo.role.name === UserRoleName.Admin ||
    userInfo.role.name === UserRoleName.SuperAdmin ||
    userInfo.role.name === UserRoleName.OrganizationAdmin
  ) {
    switch (currentCase?.status) {
      case 'new':
        return true;
      case 'accepted_care_advisor':
        return true;
      case 'accepted_provider':
        return true;
      case 'in_progress_provider':
        return true;
      case 'in_progress_care_advisor':
        return true;
      case 'completed':
        return true;
      case 'canceled':
        return true;
      default:
        return false;
    }
  }
  return false;
};

export const checkSelectionAvailability = (userInfo: UserProfile, thisCase: ICase | undefined): boolean => {
  if (thisCase)
    if (
      thisCase.care_advisor_id === userInfo.id &&
      (userInfo.role.name === UserRoleName.CareAdvisor ||
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin)
    ) {
      switch (thisCase?.status) {
        case 'new':
          return false;
        case 'accepted_care_advisor':
          return false;
        case 'accepted_provider':
          return false;
        case 'in_progress_provider':
          return false;
        default:
          return false;
      }
    } else if (thisCase.provider_id === userInfo.id && userInfo.role.name === UserRoleName.Provider) {
      switch (thisCase?.status) {
        case 'new':
          return false;
        case 'accepted_care_advisor':
          return false;
        case 'accepted_provider':
          return true;
        case 'in_progress_provider':
          return true;
        case 'in_progress_care_advisor':
          return false;
        default:
          return false;
      }
    }
  return false;
};
