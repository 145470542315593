import React, { FC } from 'react';
import Modal from 'react-modal';

import modalClose from '../../../../assets/icons/close.svg';
import { useWindowSize } from '../../../../hooks';
import { customStylesDashboard } from '../../../../Utils/customStyles';
import MedicalInformationSummary from '../../../MedicalHistory/MedicalInformationSummary/MedicalInformationSummary';
import styles from '../../styles.module.scss';

interface MedicalInformationModalProps {
  isOpen: boolean;
  toggleModal: (bool: boolean) => void;
  patientId: number;
}

const mobileCustomStylesDashboard = {
  ...customStylesDashboard,
  overlay: {
    ...customStylesDashboard.overlay,
    overflowY: 'scroll',
  },
  content: {
    ...customStylesDashboard.content,
    transform: `translate(-50%, -40%)`,
    width: '90%',
  },
};

const MedicalInformationModal: FC<MedicalInformationModalProps> = ({ isOpen, toggleModal, patientId }) => {
  const isMobile = useWindowSize().width < 992;

  const closeModal = (): void => {
    toggleModal(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={isMobile ? mobileCustomStylesDashboard : customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <button
        type="button"
        className={styles.modalCloseBtn}
        style={{ backgroundImage: `url(${modalClose})` }}
        aria-label="Close modal"
        onClick={closeModal}
      />
      <div className={styles.profileCard}>
        <MedicalInformationSummary isModal createdById={patientId} />
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default MedicalInformationModal;
