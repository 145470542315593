import { client } from '../client';
import { ITags } from './listAllTags';

export interface IaddTag {
  answer: true;
  note: 'string';
  tag: ITags;
}
export interface AddTagPayload {
  answer: boolean;
  note: string | string[];
}

export const setTagsToMe = async (tag_id: number, payload: AddTagPayload, dependent_id?: number): Promise<IaddTag> => {
  const { data } = await client.put(
    `/tags/me/${tag_id}${dependent_id ? `?dependent_id=${dependent_id}` : ''}`,
    payload,
  );

  return data;
};
