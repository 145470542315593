import cn from 'classnames';
import React, { ChangeEventHandler, FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  id: string;
  name: string;
  value: string;
  className?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  text: string;
};

const Radio: FC<Props> = ({ className, name, id, value, onChange, checked, text }): JSX.Element => {
  return (
    <label htmlFor={id} className={cn(styles.radioLabel, className)}>
      <input
        className={styles.radioInput}
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className={styles.customRadio} />
      <span className={styles.text}>{text}</span>
    </label>
  );
};

Radio.defaultProps = {
  className: '',
};

export default Radio;
