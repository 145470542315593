import { client } from '../client';
import { UserProfile } from './types/UserProfile';

type UpdateOrganizationPayload = {
  organization_ids: string[];
};

export const updateUserOrganization = async (
  user_id: number,
  payload: UpdateOrganizationPayload,
): Promise<UserProfile> => {
  const { data } = await client.patch(`/admin/users/${user_id}/organizations`, payload);

  return data;
};
