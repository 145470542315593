export const questionToAnswer = (
  question: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  isAdditionaAnswer = '',
  questionType = 'none',
): string => {
  setLoading(true);
  let mainQuestion = question
    .split('?')[0]
    .replaceAll('any', 'the following')
    .replaceAll('your', 'my')
    .replaceAll('you', 'I')
    .replaceAll(/\bever\b/gi, '');
  if (mainQuestion.includes('the following')) {
    mainQuestion = mainQuestion.replace(/the following.*/i, 'the following');
  }
  if (questionType === 'select') {
    if (mainQuestion.startsWith('Do I')) {
      const answer = mainQuestion.replace(/^do\s+I\s+/i, 'I ');
      return answer.endsWith('?') ? answer.replace('?', '.') : `${answer},`;
    }
    if (mainQuestion.startsWith('How do I')) {
      const answer = mainQuestion.replace(/^how\s+do\s+I\s+feel\s+/i, `I'm ${isAdditionaAnswer} `);
      return answer.endsWith('?') ? answer.replace('?', '.') : `${answer}.`;
    }
  }
  if (questionType === 'checkbox') {
    if (mainQuestion.startsWith('If yes')) {
      const answer = 'Am I interested in stopping?';
      return answer.endsWith('?') ? answer.replace('?', '?') : `${answer},`;
    }
  }
  if (mainQuestion.startsWith('Have I')) {
    const answer = mainQuestion.replace(/^have\s+I\s+/i, 'I have ');
    return answer.endsWith('?')
      ? answer.replace('?', '.')
      : `${answer}${isAdditionaAnswer !== 'yes' && isAdditionaAnswer !== 'no' ? ':' : '.'}`;
  }
  if (mainQuestion.startsWith('Are I')) {
    let answer = mainQuestion.replace(/^are\s+I\s+/i, "I'm ");
    const havePattern = /\b(have\s+(you|I))\b/i;
    if (mainQuestion.includes('are I')) {
      answer = answer.replace(/are\s+I\s+/i, "I'm ");
    }
    if (havePattern.test(answer)) {
      answer = answer.replace(havePattern, 'I have');
      return answer.endsWith('?')
        ? answer.replace('?', ':')
        : `${answer}${isAdditionaAnswer !== 'yes' && isAdditionaAnswer !== 'no' ? ':' : '.'}`;
    }
    return answer.endsWith('?')
      ? answer.replace('?', '.')
      : `${answer}${isAdditionaAnswer !== 'yes' && isAdditionaAnswer !== 'no' ? ':' : '.'}`;
  }
  if (mainQuestion.startsWith('Do I')) {
    const answer = mainQuestion.replace(/^do\s+I\s+/i, 'I ');
    return answer.endsWith('?')
      ? answer.replace('?', '.')
      : `${answer}${isAdditionaAnswer !== 'yes' && isAdditionaAnswer !== 'no' ? ':' : '.'}`;
  }
  if (mainQuestion.startsWith('Do my')) {
    const answer = mainQuestion.replace(/^do\s+my\s+/i, 'My ');
    return answer.endsWith('?')
      ? answer.replace('?', '.')
      : `${answer}${isAdditionaAnswer !== 'yes' && isAdditionaAnswer !== 'no' ? ':' : '.'}`;
  }
  if (mainQuestion.startsWith('Does my')) {
    const answer = mainQuestion.replace(/^does\s+my\s+/i, 'My ');
    return answer.endsWith('?')
      ? answer.replace('?', '.')
      : `${answer}${isAdditionaAnswer !== 'yes' && isAdditionaAnswer !== 'no' ? ':' : '.'}`;
  }
  if (mainQuestion.startsWith('Are my teeth')) {
    const answer = mainQuestion.replace(/^are\s+my\s+teeth\s+/i, 'My teeth are ');
    return answer.endsWith('?')
      ? answer.replace('?', '.')
      : `${answer}${isAdditionaAnswer !== 'yes' && isAdditionaAnswer !== 'no' ? ':' : '.'}`;
  }
  if (mainQuestion.startsWith('Are my')) {
    const answer = mainQuestion.replace(/^are\s+my\s+/i, 'My ');
    return answer.endsWith('?')
      ? answer.replace('?', '.')
      : `${answer}${isAdditionaAnswer !== 'yes' && isAdditionaAnswer !== 'no' ? ':' : '.'}`;
  }
  setLoading(false);
  return question;
};
