import React from 'react';

import { useWindowSize } from '../../../../hooks';
import { ICase } from '../../../../services/cases/types/ICase';
import { getPainLevelColor, getPainLevelColor2, getPainTypeText } from '../../../../Utils/getFriendlyName';
import { mapPainLevelLabel } from '../../../../Utils/mapPainLevelLabel';
import styles from '../../styles.module.scss';
import { FaMeh, FaSmile } from 'react-icons/fa';
import { PiSmileySadFill } from 'react-icons/pi';
import { RiEmotionSadFill } from 'react-icons/ri';
import { TbMoodSadFilled } from 'react-icons/tb';

type ConsultationOHRHeaderProps = {
  thisCase: ICase;
};

const ConsultationOHRHeader = ({ thisCase }: ConsultationOHRHeaderProps): JSX.Element => {
  const screenSize = useWindowSize();

  const getPainLevelIcon = (painLevel: string, color: string): JSX.Element | null => {
    switch (painLevel) {
      case 'nopain':
        return <FaSmile color={color} />;
      case 'mildPain':
        return <FaSmile color={color} />;
      case 'moderatePain':
        return <FaMeh color={color} />;
      case 'severePain':
        return <TbMoodSadFilled color={color} />;
      case 'verySeverePain':
        return <PiSmileySadFill color={color} />;
      case 'worstPainPossible':
        return <RiEmotionSadFill color={color} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.detailCards} style={{ paddingLeft: '0px' }}>
      <div
        className={`${styles.detailCard} ${screenSize.width >= 992 && styles.detailCardRightMargin}`}
        style={{ alignItems: 'left' }}
      >
        <div className={styles.detailCardTitle}>Pain Level</div>
        <div className={`${styles.detailCardContent} d-flex`}>
          {getPainLevelIcon(thisCase?.pain_level as string, getPainLevelColor2(thisCase?.pain_level || ''))}
          {mapPainLevelLabel(thisCase?.pain_level || '')}
        </div>
      </div>
      <div
        className={`${styles.detailCard} ${screenSize.width >= 992 && styles.detailCardRightMargin}`}
        style={{ borderLeft: '1px solid #d0d0d0', textAlign: 'left', alignItems: 'center' }}
      >
        <div className={styles.detailCardTitle}>Pain Type</div>
        <div className={styles.detailCardContent}>
          {thisCase?.pain_description?.map((pain) => getPainTypeText(pain)).join(', ') || '-'}
        </div>
      </div>
      <div
        className={`${styles.detailCard} ${screenSize.width >= 992 && styles.detailCardRightMargin}`}
        style={{ borderLeft: '1px solid #d0d0d0', textAlign: 'left', alignItems: 'right' }}
      >
        <div className={styles.detailCardTitle}>Time in Pain</div>
        <div className={styles.detailCardContent}>{thisCase?.pain_time || '-'}</div>
      </div>
    </div>
  );
};
export default ConsultationOHRHeader;
