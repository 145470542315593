import { selectCurrentOrganization } from '@brands/store/selectors/organization';
import { useMemo } from 'react';

import { Organization } from '../services/identity/types/UserProfile';
import { useAppSelector } from './useReduxHook';

interface OrganizationFeature {
  videoCaseEnabled: boolean;
  asyncCaseEnabled: boolean;
  secondOpinionCaseEnabled: boolean;
  phoneCaseEnabled: boolean;
}

export default function useOrganizationFlags(state: string): OrganizationFeature {
  const currentOrganization = useAppSelector<Organization | null>(selectCurrentOrganization);

  const { videoCaseEnabled, asyncCaseEnabled, secondOpinionCaseEnabled, phoneCaseEnabled } = useMemo(() => {
    if (currentOrganization) {
      const organizationState = currentOrganization.professional_corporations?.find(
        (corporation) => corporation.state === state,
      );

      if (organizationState) {
        return {
          videoCaseEnabled: !!organizationState.video_case_enabled,
          asyncCaseEnabled: !!organizationState.async_case_enabled,
          secondOpinionCaseEnabled: !!organizationState.second_opinion_case_enabled,
          phoneCaseEnabled: !!organizationState.phone_case_enabled,
        };
      }
    }
    return {
      videoCaseEnabled: false,
      asyncCaseEnabled: false,
      secondOpinionCaseEnabled: false,
      phoneCaseEnabled: false,
    };
  }, [currentOrganization, state]);

  return {
    videoCaseEnabled,
    asyncCaseEnabled,
    secondOpinionCaseEnabled,
    phoneCaseEnabled,
  };
}
