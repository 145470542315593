/* eslint-disable no-nested-ternary */
import React, { Fragment, SetStateAction } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

const isMediaUtilizedOptions = ['Utilized', 'Not Utilized'];
const objectiveOralOptions = ['Visualized', 'Not visualized'];

type ObjectiveSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const ObjectiveSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: ObjectiveSectionProps): JSX.Element => {
  const saveOneReportObjective = async (attributeName: string, attributeValue: any): Promise<void> => {
    if (attributeValue) {
      const updateData = {
        [attributeName]: !!(attributeValue === 'Utilized' || attributeValue === 'Visualized'),
      };

      const response = await updateOneReport(thisCase?.one_report.id || '', updateData);

      setCase({
        ...thisCase,
        one_report: {
          ...thisCase.one_report,
          ...response,
        },
      });
    }
  };

  return (
    <div className={styles.subjectiveDiv}>
      <div className={styles.ObjectiveSectionDiv}>
        <span className={styles.subjectiveSpan}>Photos, Video, Radiographs*</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          isMediaUtilizedOptions &&
          isMediaUtilizedOptions.map((element) => (
            <Fragment key={element}>
              <div>
                <label
                  className={`${styles.btn} ${
                    consultationForm?.objective?.is_media_utilized === element ? styles.selectedClass : ''
                  }`}
                  htmlFor={element}
                >
                  {element}
                  <div
                    className={`${styles.bntIcon} ${
                      consultationForm?.objective?.is_media_utilized === element ? styles.primaryColor : ''
                    }`}
                  />
                </label>
                <input
                  {...register('objective.is_media_utilized')}
                  type="radio"
                  className={`form-check-input ${styles.eduFormCheckInput}`}
                  value={element}
                  id={element}
                  onChange={(e) => {
                    setConsultationForm({
                      ...consultationForm,
                      objective: {
                        ...consultationForm.objective,
                        is_media_utilized: e.target.value,
                      },
                    });

                    setValue('objective.is_media_utilized', e.target.value);
                    saveOneReportObjective('is_media_utilized', e.target.value);
                    if (errors?.objective?.is_media_utilized) {
                      trigger('objective.is_media_utilized');
                    }
                  }}
                />
              </div>
            </Fragment>
          ))
        ) : (
          <span className={styles.subjectiveSpan}>
            {thisCase.one_report && thisCase.one_report.is_media_utilized === true
              ? 'Utilized'
              : thisCase.one_report && thisCase.one_report.is_media_utilized === false
              ? 'Not Utilized'
              : ''}
          </span>
        )}
      </div>
      <div className={styles.ObjectiveSectionDiv}>
        <span className={styles.subjectiveSpan}>Intra-Oral*</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          objectiveOralOptions &&
          objectiveOralOptions.map((element) => (
            <Fragment key={element}>
              <div>
                <label
                  className={`${styles.btn} ${
                    consultationForm?.objective?.is_intra_oral_utilized === element ? styles.selectedClass : ''
                  }`}
                  htmlFor={`intraOral_${element}`} // Use a unique identifier for Intra-Oral labels
                >
                  {element}
                  <div
                    className={`${styles.bntIcon} ${
                      consultationForm?.objective?.is_intra_oral_utilized === element ? styles.primaryColor : ''
                    }`}
                  />
                </label>
                <input
                  {...register('objective.is_intra_oral_utilized')}
                  type="radio"
                  className={`form-check-input ${styles.eduFormCheckInput}`}
                  value={element}
                  id={`intraOral_${element}`} // Use the same unique identifier for corresponding Intra-Oral input
                  onChange={(e) => {
                    setConsultationForm({
                      ...consultationForm,
                      objective: {
                        ...consultationForm.objective,
                        is_intra_oral_utilized: e.target.value,
                      },
                    });

                    setValue('objective.is_intra_oral_utilized', e.target.value);
                    saveOneReportObjective('is_intra_oral_utilized', e.target.value);
                    if (errors?.objective?.is_intra_oral_utilized) {
                      trigger('objective.is_intra_oral_utilized');
                    }
                  }}
                />
              </div>
            </Fragment>
          ))
        ) : (
          <span className={styles.subjectiveSpan}>
            {thisCase.one_report && thisCase.one_report.is_intra_oral_utilized === true
              ? 'Visualized'
              : thisCase.one_report && thisCase.one_report.is_intra_oral_utilized === false
              ? 'Not visualized'
              : ''}
          </span>
        )}
      </div>

      <div className={styles.ObjectiveSectionDiv}>
        <span className={styles.subjectiveSpan}>Extra-Oral*</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          objectiveOralOptions &&
          objectiveOralOptions.map((element) => (
            <Fragment key={element}>
              <div>
                <label
                  className={`${styles.btn} ${
                    consultationForm?.objective?.is_extra_oral_utilized === element ? styles.selectedClass : ''
                  }`}
                  htmlFor={`extraOral_${element}`} // Use a unique identifier for Extra-Oral labels
                >
                  {element}
                  <div
                    className={`${styles.bntIcon} ${
                      consultationForm?.objective?.is_extra_oral_utilized === element ? styles.primaryColor : ''
                    }`}
                  />
                </label>
                <input
                  {...register('objective.is_extra_oral_utilized')}
                  type="radio"
                  className={`form-check-input ${styles.eduFormCheckInput}`}
                  value={element}
                  id={`extraOral_${element}`} // Use the same unique identifier for corresponding Extra-Oral input
                  onChange={(e) => {
                    setConsultationForm({
                      ...consultationForm,
                      objective: {
                        ...consultationForm.objective,
                        is_extra_oral_utilized: e.target.value,
                      },
                    });

                    setValue('objective.is_extra_oral_utilized', e.target.value);
                    saveOneReportObjective('is_extra_oral_utilized', e.target.value);
                    if (errors?.objective?.is_extra_oral_utilized) {
                      trigger('objective.is_extra_oral_utilized');
                    }
                  }}
                />
              </div>
            </Fragment>
          ))
        ) : (
          <span className={styles.subjectiveSpan}>
            {thisCase.one_report && thisCase.one_report.is_extra_oral_utilized === true
              ? 'Visualized'
              : thisCase.one_report && thisCase.one_report.is_extra_oral_utilized === false
              ? 'Not visualized'
              : ''}
          </span>
        )}
      </div>
    </div>
  );
};

export default React.memo(ObjectiveSection);
