/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import styles from './styles.module.scss';

interface PaginationControllerProps {
  totalRecords: number;
  limit: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}

const PaginationController: React.FC<PaginationControllerProps> = ({
  totalRecords,
  limit,
  currentPage,
  onPageChange,
}): JSX.Element => {
  const totalPages = Math.ceil(totalRecords / limit);
  const [pagesToShow, setPagesToShow] = useState<number[]>([]);

  const handlePageChange = (pageNumber: number): void => {
    onPageChange(pageNumber);
  };

  useEffect(() => {
    const range = 5;
    const startPage = Math.max(1, currentPage - Math.floor(range / 2));
    const endPage = Math.min(totalPages, startPage + range - 1);

    const pages: number[] = [];

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push(-1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(-1);
      }
      pages.push(totalPages);
    }

    setPagesToShow(pages);
  }, [currentPage, totalPages]);

  return (
    <div className={styles.parentDiv}>
      <div className={styles.childDiv}>
        <button
          className={`${styles.controllers} ${styles.borderRight}`}
          type="button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <MdKeyboardArrowLeft /> Previous
        </button>
        {pagesToShow.map((pageNumber, index) =>
          pageNumber === -1 ? (
            <span className={styles.dots} key={`dots-${index}`}>
              ...
            </span>
          ) : (
            <button
              className={styles.numbers}
              type="button"
              key={`page-${pageNumber}`}
              onClick={() => handlePageChange(pageNumber)}
              disabled={pageNumber === currentPage}
            >
              {pageNumber}
            </button>
          ),
        )}
        <button
          className={`${styles.controllers} ${styles.borderLeft}`}
          type="button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next <MdKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

export default PaginationController;
