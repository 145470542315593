import qs from 'qs';

import { config } from '../../config/config';
import { KMXOrganization } from '../../Utils/getCurrentXOrganization';
import { jwtToken } from '../../Utils/getToken';

export const getUserAvatar = (id: number): string => {
  const params = {
    organization_id: KMXOrganization() ? 'kare-mobile' : config.organization_id,
    token: `Bearer ${jwtToken()}`,
    practice_id: config.practice_id,
    division_id: config.division_id,
  };
  return `${config.api.baseUrl}/card/${id}/avatar?${qs.stringify(params)}`;
};
