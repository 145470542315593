import React from 'react';

import modalClose from '../../../assets/icons/close.svg';
import styles from './dashboardModal.module.scss';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
}

const EmergencyModal = ({ setOpenModal }: IModal): JSX.Element => (
  <div className={`${styles.modalBackground}`} onClick={() => setOpenModal(false)}>
    <div className={`${styles.modalContainer} ${styles.emergencyModalContainer}`} onClick={(e) => e.stopPropagation()}>
      <button
        type="button"
        className={`${styles.modalClose}`}
        style={{ backgroundImage: `url(${modalClose})` }}
        aria-label="Close modal"
        onClick={() => setOpenModal(false)}
      />

      <div className={`${styles.body}`}>
        <p>Emergency Situations</p>
        <span>
          Although dental emergencies are rare, there are a few life-threatening situations that require immediate
          supervision. If you experience any of the below, please call 911 or go to your nearest emergency department:
        </span>
        <div className={`${styles.emergencyList} ${styles.tAL}`}>
          <ul>
            <li>
              <div className={styles.dot} /> Difficulty breathing
            </li>
            <li>
              <div className={styles.dot} /> Intense pain in your left arm that is spreading to your neck and/or jaw
            </li>
            <li>
              <div className={styles.dot} /> Intense pain behind the eyes and/or double vision
            </li>
            <li>
              <div className={styles.dot} /> Swelling/pressure that is preventing you from swallowing your spit
            </li>
            <li>
              <div className={styles.dot} /> Swelling/pressure behind the tongue moving it out of place
            </li>
            <li>
              <div className={styles.dot} /> Swelling/pressure from your mouth preventing you from opening your mouth
            </li>
            <li>
              <div className={styles.dot} /> Swelling from your mouth to your upper eyelid
            </li>
            <li>
              <div className={styles.dot} /> Fever or vomiting possibly caused by a dental infection
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);
export default EmergencyModal;
