import React, { useEffect, useState } from 'react';

import Container from '../../../Components/Container/Container';
import { useWindowSize } from '../../../hooks';
import AllAppointments from './AllAppointments';
import styles from './appointments.module.scss';
import MyAppointments from './MyAppointments';

type AppointmentsProps = {
  isProfileCard?: boolean;
};

const Appointments = ({ isProfileCard }: AppointmentsProps): JSX.Element => {
  const screenSize = useWindowSize();

  // Determine initial tab from state or URL hash
  const [activeTab, setActiveTab] = useState(() => {
    const hash = window.location.hash.replace('#', '');
    if (hash === 'myAppointments' || hash === 'allAppointments') {
      return hash;
    }
    return 'myAppointments';
  });

  // Update URL hash when the tab is changed
  const setTab = (targetTab: string): void => {
    setActiveTab(targetTab);
    window.location.hash = targetTab;
  };

  // Handle URL hash change directly (if the user navigates back/forward)
  useEffect(() => {
    const handleHashChange = (): void => {
      const hash = window.location.hash.replace('#', '');
      if (hash === 'myAppointments' || hash === 'allAppointments') {
        setActiveTab(hash);
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <Container
      childClassName={styles.appointments}
      isBGEclipse={false}
      containerStyle={{
        justifyContent: 'start',
        alignItems: isProfileCard ? 'start' : '',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
    >
      <div
        className={styles.container}
        style={{
          marginTop: isProfileCard ? '0px' : '',
          paddingLeft: isProfileCard && screenSize.width >= 992 ? '6rem' : '',
        }}
      >
        <div className={`${styles.innerContainer} mb-1`}>
          <div className={styles.appointmentHeader}>
            <div
              className={`${styles.appointmentTab} ${
                activeTab === 'myAppointments' ? styles.activeTab : styles.inActiveTab
              }`}
              onClick={() => setTab('myAppointments')}
            >
              <span>My Previous Cases</span>
            </div>
            <div
              className={`${styles.appointmentTab} ${
                activeTab === 'allAppointments' ? styles.activeTab : styles.inActiveTab
              }`}
              onClick={() => setTab('allAppointments')}
            >
              <span>All Previous Cases</span>
            </div>
          </div>
          {activeTab === 'myAppointments' && <MyAppointments />}
          {activeTab === 'allAppointments' && <AllAppointments />}
        </div>
      </div>
    </Container>
  );
};

Appointments.defaultProps = {
  isProfileCard: false,
};

export default Appointments;
