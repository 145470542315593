import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { leaveConversation } from '../../services/communication/leaveConversation';
import styles from './styles.module.scss';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
  conversationSid: string | undefined;
}

const LeaveModal = ({ setOpenModal, conversationSid }: IModal): JSX.Element => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const leaveChat = async (): Promise<void> => {
    setLoading(true);
    await leaveConversation(conversationSid);
    setOpenModal(false);
    navigate(-1);
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.body}>
          <p>Do you want to leave this chat?</p>
          <div className={styles.modalButtons}>
            <button
              disabled={loading}
              type="button"
              onClick={() => {
                leaveChat();
              }}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenModal(false);
              }}
              id="cancelBtn"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaveModal;
