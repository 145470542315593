import { useMemo, useState } from 'react';

import { getMedia } from '../../services/communication/getMedia';

export interface IConversationMedia {
  mediaType: string;
  url: string;
  mimeType: string;
}

export default function useConversationMedia(media_ids: string[], conversationSid: string): IConversationMedia[] {
  const [media, setMedia] = useState<IConversationMedia[]>([]);
  async function getMediaSrc(url: string): Promise<void> {
    const mediaSrc = await getMedia(url, conversationSid);
    const setUploadedMedia: IConversationMedia = {
      mediaType: mediaSrc.type,
      url: mediaSrc.url,
      mimeType: mediaSrc.mimetype,
    };
    setMedia((prevMedia) => [...prevMedia, setUploadedMedia]);
  }

  useMemo(() => {
    if (media_ids) {
      media_ids.map((value) => {
        getMediaSrc(value);
      });
    } else {
      setMedia([]);
    }
  }, []);

  return media;
}
