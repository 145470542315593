import Container from '@brands/Components/Container/Container';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import useWindowSize from '@brands/hooks/useWindowSize';
import React, { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

const NoticeOfPrivacyPractices: FC = () => {
  const screenSize = useWindowSize();
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <Container
        isBGEclipse={false}
        patientDashboard
        childClassName={styles.dashboard}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
          overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
        }}
      >
        <div className={styles.tOSBody}>
          <div id="content" className={styles.tOSBodySiteContent}>
            <main id="primary" className="site-main">
              <article id="post-889" className="post-889 page type-page status-publish hentry">
                <div className={styles.entryContent}>
                  <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <strong>DENTAL GROUP NOTICE OF PRIVACY PRACTICES</strong>
                  </h2>
                  <p style={{ textAlign: 'center' }}>
                    THIS NOTICE SETS FORTH IMPORTANT DETAILS AS TO HOW MEDICAL AND DENTAL INFORMATION ABOUT YOU MAY BE
                    USED AND DISCLOSED AND HOW YOU CAN ACCESS THIS INFORMATION. PLEASE CAREFULLY REVIEW THIS NOTICE.
                  </p>

                  <p>
                    Dentistry One LLC (“D1”) is committed to protecting the confidentiality of Users’ information. This
                    Notice is provided to you pursuant to the Health Insurance Portability and Accountability Act of
                    1996 and its implementing regulations (“HIPAA”). D1 is committed to obtaining, maintaining, using,
                    and disclosing patient protected health information in a manner that protects patient privacy. We
                    urge you to read this Notice of Privacy Practices (“Notice”) carefully in order to understand both
                    our commitment to the privacy of your protected health information (“PHI”) and your rights, as well
                    as the ways we may use and disclose your PHI and your rights and our obligations regarding such use
                    and disclosure of your PHI. “We” refers to, and this Notice applies D1, as well as all affiliated
                    PCs of Dentistry One LLC, including, in each such practice’s case, their providers and employees
                    (collectively such practices, the “Dental Groups”).
                  </p>

                  <p>
                    HOW WE MAY USE AND DISCLOSE YOUR PROTECTED HEALTH INFORMATION (PHI) (i.e., YOUR MEDICAL INFORMATION)
                  </p>

                  <p>
                    The following categories describe the different ways we typically use and disclose PHI, the purposes
                    for such uses and disclosures, and the reasons for such uses and disclosures. Your PHI may be used
                    and disclosed for treatment, payment, healthcare operations, and other purposes permitted or
                    required by law. If we wish to use or disclose your PHI for other purposes, we would have to obtain
                    your authorization. Per the below, we may use different methods of communication that you may
                    approve or select in order to communicate and contact you, such as via text message, email, or
                    through your D1 account. In most instances, your initial communication with the applicable Dental
                    Group will be through an interaction with the Dental Group through the D1 website or app. Protected
                    Health Information or “PHI” includes your demographic information such as name, address, telephone
                    number, social security number, birth date, and gender. PHI also includes information regarding your
                    dental health, illnesses and injuries, and information about the dental services provided to you. We
                    maintain the privacy of your PHI and notify affected individuals following a breach of unsecured
                    PHI, in each case to the extent required by state and federal law.
                  </p>

                  <ul className={styles.dotList}>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Communicating with You.</span>{' '}
                      We may use or disclose your health information to communicate with you about a number of important
                      topics, such as your upcoming appointments, care, or treatment alternatives or other
                      health-related services that may be of interest to you. We may contact you at the email address,
                      phone number (including via text message), or home address that you provide to us. If your contact
                      information changes, it is important you let us know. If you choose to communicate with us via
                      unsecure electronic communications, such as regular email or text message, we may respond to you
                      in the same manner in which the communication was received and to the same email address or phone
                      number from which you sent your communication. Before using or agreeing to the use of any unsecure
                      electronic communication to communicate with us, note that there are certain risks associated with
                      such use, including interception of the message by others, misdirected messages, or storage of
                      your information on devices that are not secure. By choosing to communicate with us via unsecure
                      electronic communication, you are acknowledging and agreeing to accept these risks. Email and text
                      communications are not a substitute for professional dental advice, diagnosis, or treatment and
                      should never be used in a medical or dental emergency.
                    </li>
                    <li>
                      ● <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>For Treatment.</span> We may
                      use and disclose PHI about you to provide you with dental care treatment and related services, as
                      well as coordinating and managing your dental care and treatment. Accordingly, we may disclose PHI
                      about you to dentists, dental technicians, other dental care providers and persons who are
                      providing or involved in providing dental care to you (both within and outside of the applicable
                      Dental Group(s)). For example, should your care require referral to a dental specialist for the
                      provision of certain dental care, we may provide that dental specialist with your PHI in order to
                      aid the dental specialist in his or her treatment of you.
                    </li>
                    <li>
                      ● <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>For Payment.</span> Our
                      billing department will use and disclose PHI to certain insurance companies, hospitals, physicians
                      and health plans for payment purposes, or to our third-party billing partners to assist us in
                      creating bills and claim forms, and getting paid for our services. For example, we may send a
                      User’s name, date of service, test or procedures performed, diagnosis or procedure code and other
                      information to a health plan so that the plan will pay us for the services we provided. In some
                      cases, we may have to contact the patient to obtain billing information or for other billing
                      purposes. When required, we may use an outside collection agency to obtain payment.{' '}
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        For Health Care Operations.
                      </span>{' '}
                      We may use or disclose your PHI in order to carry out our general business activities or certain
                      business activities. These activities include, but are not limited to, training and education;
                      quality assessment/improvement activities; risk management; claims management; legal consultation;
                      licensing; and other business planning activities. For example, we may use your PHI to evaluate
                      the quality of care we are providing.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Health Information Exchanges:
                      </span>{' '}
                      We may use and disclose PHI to participate in health information exchanges (HIEs) so that we can
                      share, request, and receive electronic health information from other healthcare organizations for
                      treatment, payment, and healthcare operations purposes as described herein HIEs involve
                      coordinated information sharing among HIE participants for purposes of treatment, payment, and
                      health care operations. This means we may share your health information with non-D1, non-Dental
                      Group entities involved in your care (such as hospitals, doctors offices, pharmacies, or insurance
                      companies) who participate in the HIE, or we may receive information they create or maintain about
                      you so each of us can provide better treatment and coordination of your health care services.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Quality Assurance and Utilization Review.
                      </span>{' '}
                      We may need to use or disclose your PHI for our internal processes to assess and facilitate the
                      provision of quality care to our patients. We may need to use or disclose your PHI to perform a
                      review of the services we provide in order to evaluate whether that the appropriate level of
                      services is received, depending on condition and diagnosis.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Credentialing and Peer Review.
                      </span>{' '}
                      We may need to use or disclose your PHI in order for us to review the credentials, qualifications
                      and actions of our health care providers.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Treatment Alternatives.</span>{' '}
                      We may use and disclose your health information to manage and coordinate your healthcare and
                      inform you of treatment alternatives and other health related benefits that may be of interest to
                      you. This may include telling you about treatments, services, products and/or other healthcare
                      providers.
                    </li>
                    <li>
                      ● <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Appointment Reminders.</span>{' '}
                      We may use and disclose PHI in order to provide you with a reminder that you have an appointment
                      for testing or consultation.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Health Related Benefits and Services.
                      </span>{' '}
                      We may use and disclose PHI to tell you about health-related benefits or services that may be of
                      interest to you.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Vendors and Business Associates.
                      </span>{' '}
                      here are some services (such as billing, legal, or technology services) that may be provided to or
                      on behalf of the Dental Groups through contracts with various vendors and business associates.
                      When these services are contracted, D1 or the Dental Groups may disclose your PHI to the business
                      associate so that they can perform the job the we have asked them to do. To protect your PHI,
                      however, where we deem it appropriate, we may require the business associate to appropriately
                      safeguard your information..
                    </li>
                    <li>
                      ● <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>As Required by Law.</span> We
                      may use or disclose your PHI to the extent the use or disclosure is required by law. Any such use
                      or disclosure will be made in compliance with the law and will be limited to what is required by
                      the law.
                    </li>
                    <li>
                      ● <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Other.</span> Subject to
                      applicable legal requirements, and where appropriate for your dental care or required by law, we
                      may also use your PHI (i) to avert an immediate threat of injury to your health or safety, (ii)
                      for research, (iii) to appropriate military authorities if you are in the armed forces, (iv) for
                      workers’ compensation programs, (v) for public health activities, (vi) for health oversight
                      activities, (vii) for other legal matters, (viii) for law enforcement purposes, (ix) to coroners
                      and medical examiners, or (x) for marketing or fundraising purposes.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Electronic Disclosures of Dental Medical Information.
                      </span>{' '}
                      Under certain state the law, we may be required to provide notice to you if your PHI is subject to
                      electronic disclosure. This Notice provides you overall notice that we may disclose your PHI
                      electronically for treatment, payment, or health care operations or as otherwise permitted or
                      mandated by state or federal law.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        De-Identified Information.
                      </span>{' '}
                      We may use your PHI to create “de-identified” information, which means that information that can
                      be used to identify you will be removed. There are specific rules under the law about what type of
                      information needs to be removed before information is considered de-identified. Once information
                      has been de-identified as required by law, it is no longer subject to this Notice, and we may use
                      it for any purpose without any further notice or compensation to you.
                    </li>
                  </ul>

                  <p>OTHER USES OF PHI</p>

                  <ul className={styles.dotList}>
                    <li>
                      ● <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Authorizations.</span> There
                      are times we may need or want to use or disclose your PHI for reasons other than those listed
                      above, but to do so we will need your prior authorization. Other than expressly provided herein,
                      any other uses or disclosures of your PHI will require your specific written authorization.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Marketing and Sale of Medical Information.
                      </span>{' '}
                      Most uses and disclosures of PHI for marketing purposes, and disclosures that constitute a “sale
                      of PHI” under applicable state and federal law require your authorization. The Dental Groups do
                      not anticipate that they will sell PHI or use or disclose any notes created by a Provider in the
                      course of providing you dental care except by your Provider to provide you with ongoing dental
                      care.
                    </li>
                  </ul>

                  <h5>YOUR HEALTH INFORMATION RIGHTS</h5>

                  <p>
                    Certain laws and regulations provide you with certain rights regarding the PHI we have about you.
                    The following is a summary of those rights.
                  </p>

                  <h4>You have the right to:</h4>

                  <ul className={styles.dotList}>
                    <li>
                      ● <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Inspect and Copy.</span>{' '}
                      Generally, you will have the right to inspect and/or copy your PHI that we maintain, which
                      generally includes medical and billing records within a designated record set,. In order to
                      inspect/copy PHI, you must submit a written request to the applicable Dental Group at{' '}
                      <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>. Under certain
                      circumstances, we may charge certain fees to cover costs of mailing, copying, and related costs to
                      accommodate your request, as permitted by state law. To the extent such requested PHI is
                      maintained in electronically or in electronic form (e.g., as part of records maintained by the
                      applicable Dental Group that is used to provide dental care to you, such as an electronic medical
                      record, electronic billing record, etc. ) and you request an electronic copy of this information,
                      then in such case the requested PHI will be provided to you in the electronic form and format
                      requested (provided that it is readily producible in that particular form and format). If it is
                      not readily producible in the requested electronic form and format, we will endeavor to provide
                      access in an electronic form and format as may be mutually agreed to by you and the applicable
                      Dental Group. In certain cases as permitted by applicable law, we may deny your request to review
                      or copy your PHI. If you your request is denied, we will provide written notification of such
                      denial .
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Request a restriction on certain uses and disclosures of PHI.
                      </span>{' '}
                      You have the right to request additional restrictions on our use or disclosure of your PHI for
                      treatment, payment or health care operations activities, or to individuals involved in your care,
                      like a family member or friend, by sending a written request to the by emailing{' '}
                      <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>. When making such
                      request you are required specifically inform us as to what information you specifically want to
                      limit in terms of use, disclosure, or both, and to which parties you want the limits to apply. We
                      are not required to agree to those restrictions unless the disclosure is not required by law and
                      you paid for the service in full out of pocket. There are certain situations where we won’t be
                      able to agree to your request, such as when we are required by law to use or disclose your PHI.
                      These situations include emergency treatment, disclosures to the Secretary of the Department of
                      Health and Human Services, and uses and disclosures that do not require your authorization. Please
                      be aware that such restrictions may adversely impact your care particularly if other providers
                      need to know that information. Furthermore, it will be your obligation to notify any such other
                      providers of this restriction (including insurance companies paying for related care).
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Request an amendment of PHI.
                      </span>{' '}
                      If you feel that PHI we maintain about you is incomplete or incorrect, you may request that we
                      amend it. To request an amendment, your request must be in writing and submitted to{' '}
                      <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>. In your request, you
                      must provide a reason as to why you want this amendment. In certain cases, we may deny your
                      request for amendment. For example, in circumstances under which the patient would be denied
                      access to his/her PHI, we may deny a request for amendment if it is not in writing or does not
                      include a reason to support the request. In addition, we may deny your request if you ask us to
                      amend information that (i) was not created by D1 or a Dental Group (unless you provide a
                      reasonable basis for asserting that the person or organization that created the information is no
                      longer available to act on the requested amendment), (ii) is not part of the information kept by
                      the applicable Dental Group, (iii) is not part of the information which you would be permitted to
                      inspect and copy, or (iv) is accurate and complete. If you your request is denied, we will provide
                      written notification of such denial.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Receive an accounting of disclosures of PHI.
                      </span>{' '}
                      You have the right to receive an accounting of the disclosures we have made of your PHI. The right
                      to receive an accounting is subject to certain exceptions, restrictions, and limitations. To
                      request an accounting, your request must be in writing and submitted to{' '}
                      <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>. Your request must
                      specify the time period for which you would like an accounting, but this time period may not be
                      longer than six years, and a shorter period may apply for some disclosures.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Right to Revoke Authorization.
                      </span>{' '}
                      If you provide us with written authorization to use or disclose your PHI for such other purposes,
                      you may revoke that authorization in writing at any time. If you revoke your authorization, we
                      will no longer use or disclose your PHI for the reasons covered by your written authorization. You
                      understand that we are unable to take back any uses or disclosures we have already made in
                      reliance upon your authorization, and that we are required to retain our records of the care that
                      we provided to you.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Request communications of PHI by alternative means or at alternative locations.
                      </span>{' '}
                      You have a right to request to receive communications of PHI by alternate means or at alternate
                      locations. For instance, you may request that we contact you about medical matters only in writing
                      or at a different residence or post office box. To request confidential communication of your PHI,
                      send a written request by email to{' '}
                      <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>. Your request must state
                      how or where you would like to be contacted. We will accommodate all reasonable requests.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Request a copy of your PHI.
                      </span>{' '}
                      You have the right to request a copy of certain PHI we may have about you. To request a copy of
                      your PHI, please send a written request by email to{' '}
                      <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>. If we maintain your PHI
                      electronically, you will have the right to request that we send a copy of your PHI in an
                      electronic format to you and may have the right to request that we send a copy to a third party
                      that you identify. We may charge a fee for the costs of copying, mailing or other permitted
                      supplies associated with your request. In some cases, you may receive a summary of this PHI. This
                      may include a reasonable fee for creating and sending the summary. We may deny access to certain
                      information in limited circumstances.
                    </li>
                    <li>
                      ●{' '}
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                        Request Confidential Communications.
                      </span>{' '}
                      You have the right to request confidential communications, i.e., how and where we contact you,
                      about your health information. For example, you may request that we contact you at your personal
                      email address as opposed to your work email address or phone number. Your request must specify how
                      and where you wish to be contacted and must be made in writing to{' '}
                      <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>. Generally, we will not
                      inquire as to the reason for your request, and we will accommodate reasonable requests, but there
                      might be some requests with which we will not be able accommodate.{' '}
                    </li>
                    <li>
                      ● <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Breach Notification.</span>{' '}
                      In certain cases, we may be required to notify you (and in some cases, other parties) if we become
                      aware that your PHI has been improperly disclosed or has inadvertently been made available due to
                      a “breach” as required by applicable law.
                    </li>
                  </ul>

                  <h5>STATE LAW</h5>

                  <p>
                    We will not use or share your information if state law prohibits it. Some states have laws that are
                    stricter than the federal privacy regulations, such as laws protecting HIV/AIDS information or
                    mental health information. If a state law applies to us and is stricter or places limits on the ways
                    we can use or share your health information, we will follow the state law.
                  </p>

                  <h5>CHANGES TO THIS NOTICE</h5>

                  <p>
                    We reserve the right to change this Notice at any time, along with our privacy policies and
                    practices. We reserve the right to make the revised or changed Notice effective for PHI we already
                    have about you as well, as any information we receive in the future. We will post a copy of the
                    current notice, along with an announcement that changes have been made, as applicable, on our
                    website and in any physical office in which the Dental Groups practice medicine. When changes have
                    been made to the Notice, you may obtain a revised copy by writing to{' '}
                    <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>.
                  </p>

                  <h5>QUESTIONS, CONCERNS OR COMPLAINTS</h5>

                  <p>
                    If you have any questions or want more information about this Notice or how to exercise your PHI
                    rights, please contact <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>.
                  </p>

                  <p>
                    If you believe that your privacy rights as described in this Notice have been violated, you may file
                    a complaint with the applicable Dental Group at{' '}
                    <a href="https://dentistry.one/privacy-policy/">privacy@dentistry.one</a>. No Dental Groups will
                    retaliate against any individual who files a complaint.
                  </p>

                  <p>
                    If you believe your privacy rights have been violated, you may also file a complaint with the Office
                    for Civil Rights: Centralized Case Management Operations, U.S. Department of Health and Human
                    Services, 200 Independence Avenue, S.W., Room 509F HHH Bldg., Washington, D.C. 20201 or
                    OCRComplaint@hhs.gov.
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </Container>
    </>
  );
};

export default NoticeOfPrivacyPractices;
