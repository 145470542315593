/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
export const isValidPhoneNumber = (phone: string): boolean => {
  return /^\(\d{3}\)\s\d{3}-\d{4}$/.test(phone);
};

export const isValidEmail = (email: string): boolean => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const isValidFullName = (name: string): boolean => {
  const regex = /^[a-zA-Z\.\'\-]{1,16}(?: [a-zA-Z\.\'\-]{1,16})+$/;
  return regex.test(name);
};

export const isValidNickname = (name: string): boolean => {
  const regex = /^[A-Za-z0-9]+([A-Za-z0-9]*|[._-]?[A-Za-z0-9]+)*$/;
  return regex.test(name);
};

export const isValidName = (name: string): boolean => {
  const regex = /^[A-Za-z]+([A-Za-z0-9]*|[ ]|[-]|[']|[`]|[.]|[+]|[A-Za-z0-9]+)*$/;
  return regex.test(name);
};

export const isValidDateFormat = (text: string): boolean => {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  return datePattern.test(text);
};

export const isValidCreditCardNumber = (number: string): boolean => {
  const regex =
    /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}| 222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/;
  return regex.test(number);
};

export const isValidRoutingNumber = (number: string): boolean => {
  const regex = /^[:]*\s*(\d{9})(?:[^\d]|$)$/;
  return regex.test(number);
};

export const isValidExpiryDate = (date: string): boolean => {
  const regex = /^(0[1-9]|1[0-2])\/?(2[0-9]|3[0-9])$/;
  return regex.test(date);
};

export const isValidZipCode = (code: string): boolean => {
  const regex = /^\d{5}([-]|\s*)?(\d{4})?$/;
  return regex.test(code);
};

export const isValidCvvNumber = (number: string): boolean => {
  const regex = /^[0-9]{3,4}$/;
  return regex.test(number);
};

export const isValidPassword = (value: string): boolean => {
  const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[><?@_+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-])(?=.{8,})/;
  return regex.test(value);
};

export const isValidDate = (value: string): boolean => {
  const regex = /^(0[1-9]|1[012])[ / ](0[1-9]|[12][0-9]|3[01])[ / ](19|20)\d\d$/;
  return regex.test(value);
};

export const hasCapsLetter = (value: string): boolean => {
  const regex = /^(?=.*[A-Z]).+$/;
  return regex.test(value);
};

export const hasNumber = (value: string): boolean => {
  const regex = /^(?=.*[0-9]).+$/;
  return regex.test(value);
};

export const hasSpecialChar = (value: string): boolean => {
  const regex = /^(?=.*[><?@_+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-]).*$/;
  return regex.test(value);
};

export const isValidAddress = (value: string): boolean => {
  const regex = /^[A-Za-z0-9'\.\-\s\,].*$/;
  return regex.test(value);
};

export const checkValue = (str: string, max: number): string => {
  let resultDate = str;
  if (str.charAt(0) !== '0' || str == '00') {
    let num = Number(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    if (num > Number(max.toString().charAt(0)) && num.toString().length == 1) {
      resultDate = `0${num}`;
    } else {
      resultDate = num.toString();
    }
  }
  return resultDate;
};

export const checkFutureDate = (month: string, day: string, year: string): boolean => {
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  const today = new Date();
  return date <= today;
};

export const checkExpiredDate = (month: string, day: string, year: string): boolean => {
  const mm = +month;
  const dd = +day;
  const yy = +year;
  const currentDate = new Date();
  const expirationDate = new Date(yy, mm - 1, dd);

  if (expirationDate < currentDate) {
    return true; // Date is expired
  }
  return false; // Date is not expired
};

export const checkExpiryDate = (month: string, year: string): boolean => {
  const mm = +month;
  const yy = +year;

  const date = new Date();
  const todayYear = +date.getFullYear().toString().substr(-2);
  const todayMonth = date.getMonth() + 1;

  if ((mm > todayMonth && yy === todayYear) || yy > todayYear) {
    return true;
  }
  return false;
};

export const checkValidDate = (month: string, day: string, year: string): boolean => {
  const mm = +month;
  const dd = +day;
  const yy = +year;
  const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Check for valid month and day range
  if (mm < 1 || mm > 12 || dd < 1 || dd > 31) return false;

  // Check February for leap year or standard year day limits
  if (mm === 2) {
    const isLeapYear = (!(yy % 4) && yy % 100) || !(yy % 400);
    if ((isLeapYear && dd > 29) || (!isLeapYear && dd > 28)) return false;
  } else if (dd > ListofDays[mm - 1]) {
    return false;
  }

  return true;
};

export const checkFilesNumber = (files: [File], min = 2, max = 6): boolean => {
  if (files.length <= max && files.length >= min) {
    return true;
  }
  return false;
};

export const checkFilesSize = (files?: [File]): boolean => {
  let valid = true;
  if (files && files.length > 0) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkFilesType = (files?: [File]): boolean => {
  let valid = true;
  if (files && files.length > 0) {
    files.map((file) => {
      if (!['image/png', 'image/jpg', 'image/jpeg', 'image/pjpeg', 'image/pjp', 'image/jfif'].includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
};
