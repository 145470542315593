import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

const Toast: FC = (): JSX.Element => {
  return (
    <ToastContainer
      position="bottom-left"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{ fontSize: '14px' }}
    />
  );
};

export default Toast;
