import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientMyProfile } from '@brands/store/selectors/patientMyProfile';
import { setPatientMyProfile } from '@brands/store/slices/patientMyProfileSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiArrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import modalClose from '../../assets/icons/close.svg';
import Button from '../../Components/Button/Button';
import Container from '../../Components/Container/Container';
import SelectInput from '../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../Components/Inputs/TextField/TextField';
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHook';
import { checkAndSetDateWithoutDays } from '../../Utils/checkAndSetDate';
import { selectCustomStyles } from '../../Utils/customStyles';
import { countryOptions, stateOptions } from '../../Utils/selectOptions';
import SubmitConfirmationModal from '../Dashboard/DashboardModals/SubmitConfirmationModal';
import { MyProfileSubmit } from '../MyProfile/Patient/utils/types';
import PaymentModal from './PaymentModal/PaymentModal';
import styles from './styles.module.scss';
import { validationSchema } from './utils/validationSchema';

type PaymentPageSubmit = {
  creditCardFullName: string;
  creditCardAddress1: string;
  creditCardAddress2: string;
  creditCardNumber: string;
  expiryDate: string;
  creditCardZipCode: string;
  cvvNumber: string;
  creditCardState: string;
  country: string;
} & MyProfileSubmit;

const PaymentPage = (): JSX.Element => {
  const values = useAppSelector(selectPatientMyProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(selectAuth);
  const [openModal, setOpenModal] = useState({ isOpen: false, type: '' });

  const paymentForm = useMemo(() => {
    if (values.myProfile.patientId === userInfo.id) {
      return values.myProfile as PaymentPageSubmit;
    }
    return {
      creditCardFullName: '',
      creditCardAddress1: '',
      creditCardAddress2: '',
      creditCardNumber: '',
      expiryDate: '',
      creditCardZipCode: '',
      cvvNumber: '',
      creditCardState: '',
      country: '',
    } as PaymentPageSubmit;
  }, [values.myProfile, userInfo.id]);

  const onSubmit = (data: PaymentPageSubmit): void => {
    dispatch(
      setPatientMyProfile({
        ...(data as PaymentPageSubmit),
        patientId: userInfo?.id,
      }),
    );
  };

  const onError = (): void => {
    setOpenModal({ isOpen: true, type: 'imperfect' });
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<PaymentPageSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (paymentForm) {
      Object.keys(paymentForm).forEach((key: unknown) => {
        setValue(key as keyof PaymentPageSubmit, paymentForm[key as keyof PaymentPageSubmit]);
      });
    }
  }, []);
  return (
    <Container containerStyle={{ overflow: 'hidden' }}>
      <div className={styles.paymentContainer}>
        <div className={styles.paymentWrap}>
          <button
            type="button"
            className={styles.modalCloseBtn}
            style={{ backgroundImage: `url(${modalClose})` }}
            aria-label="Close modal"
            onClick={() => navigate('/dashboard')}
          />
          <div className={styles.backArrow} onClick={() => navigate(-1)}>
            <HiArrowLeft /> Insurance Page
          </div>
          <form className="login100-form p-form" onSubmit={handleSubmit(onSubmit, onError)}>
            <span className="login100-form-title p-b-26">
              <span className="login100-form-title-welcome">Total $30</span>
            </span>
            <div className={`${styles.row} row`}>
              <div className="col-md-6">
                <div className="col-md-12">
                  <TextField
                    {...register('creditCardFullName')}
                    errors={errors}
                    name="creditCardFullName"
                    placeholder="Name on Card"
                    value={getValues('creditCardFullName')}
                    onChange={(e) => {
                      setValue('creditCardFullName', e.target.value, { shouldValidate: true });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <TextField
                      {...register('creditCardNumber')}
                      errors={errors}
                      name="creditCardNumber"
                      placeholder="Credit Card Number"
                      value={getValues('creditCardNumber')}
                      onChange={(e) => {
                        setValue('creditCardNumber', e.target.value, { shouldValidate: true });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.row} row`}>
              <div className="col-md-6">
                <div className="col-md-12">
                  <TextField
                    {...register('creditCardAddress1')}
                    errors={errors}
                    name="creditCardAddress1"
                    placeholder="Billing Address"
                    value={getValues('creditCardAddress1')}
                    onChange={(e) => {
                      setValue('creditCardAddress1', e.target.value, { shouldValidate: true });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12">
                  <TextField
                    {...register('expiryDate')}
                    errors={errors}
                    name="expiryDate"
                    placeholder="Expiry Date (MM/YY)"
                    value={getValues('expiryDate')}
                    onChange={(e) => {
                      setValue('expiryDate', checkAndSetDateWithoutDays(e.target.value), { shouldValidate: true });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.row} row`}>
              <div className="col-md-6">
                <div className="col-md-12">
                  <TextField
                    {...register('creditCardAddress2')}
                    errors={errors}
                    name="creditCardAddress2"
                    placeholder="Suite, Unit, Floor, etc."
                    value={getValues('creditCardAddress2')}
                    onChange={(e) => {
                      setValue('creditCardAddress2', e.target.value, { shouldValidate: true });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12">
                  <TextField
                    {...register('cvvNumber')}
                    errors={errors}
                    name="cvvNumber"
                    placeholder="CVV"
                    value={getValues('cvvNumber')}
                    onChange={(e) => {
                      setValue('cvvNumber', e.target.value, { shouldValidate: true });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.row} row`}>
              <div className="col-md-6">
                <div className="col-md-12">
                  <TextField
                    {...register('creditCardZipCode')}
                    errors={errors}
                    name="creditCardZipCode"
                    placeholder="Zip Code"
                    value={getValues('creditCardZipCode')}
                    onChange={(e) => {
                      setValue('creditCardZipCode', e.target.value, { shouldValidate: true });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.row} row`}>
              <div className="col-md-6">
                <div className="row m-0 d-flex justify-content-between">
                  <div className="col-md-5 pl-0 pr-0">
                    <SelectInput
                      {...register('creditCardState')}
                      name="creditCardState"
                      options={stateOptions}
                      style={selectCustomStyles}
                      placeholder="State"
                      errors={errors}
                      selectedValue={getValues('creditCardState')}
                      onChange={(value) => {
                        if (value) {
                          setValue('creditCardState', value.value, { shouldValidate: true });
                        }
                      }}
                      isDisabled={getValues('country') !== 'US'}
                    />
                  </div>
                  <div className="col-md-6 pl-0 pr-0">
                    <SelectInput
                      {...register('country')}
                      name="country"
                      options={countryOptions}
                      style={selectCustomStyles}
                      placeholder="Country"
                      errors={errors}
                      selectedValue={getValues('country')}
                      onChange={(value) => {
                        if (value) {
                          setValue('country', value.value, { shouldValidate: true });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.btnContainer}>
              <Button type="submit" className={styles.btn}>
                Pay for my consult
              </Button>
            </div>
          </form>
          <div className={styles.paymentInsideEclipseLeft} style={{ zIndex: '-1' }} />
          <div className={styles.paymentInsideEclipseRight} />
        </div>
      </div>
      {false && <SubmitConfirmationModal />}
      {openModal.isOpen && <PaymentModal openModal={openModal} setOpenModal={setOpenModal} />}
    </Container>
  );
};
export default PaymentPage;
