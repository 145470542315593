import { useEffect, useState } from 'react';
import { decodeToken } from 'react-jwt';

export const useJwtExpired = (onExpired: () => void, token: string | null): void => {
  const [expireHandle, setExpireHandle] = useState<NodeJS.Timeout | null>(null);

  // Handle Token expiration
  useEffect(() => {
    if (!token) {
      return;
    }
    const decoded = decodeToken<{ exp: number; iat: number }>(token);
    if (!decoded || typeof decoded === 'string') {
      return;
    }

    if (!decoded.exp) {
      return;
    }
    if (expireHandle) {
      clearTimeout(expireHandle);
    }
    // Triggers expired handler 5 mins ahead before the actual token expires
    const newExpireHandle = setTimeout(() => {
      setExpireHandle(null);
      onExpired();
    }, Math.max((decoded.exp - 5 * 60) * 1000 - new Date().getTime(), 0));
    setExpireHandle(newExpireHandle);
  }, [token]);

  useEffect(() => {
    return () => {
      if (expireHandle) {
        clearTimeout(expireHandle);
        setExpireHandle(null);
      }
    };
  }, []);
};
