import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import Modal, { Styles } from 'react-modal';
import { toast } from 'react-toastify';

import closeModalIcon from '../../../../../assets/icons/close.svg';
import SuccessAlert from '../../../../../Components/Alert/SuccessAlert';
import Button from '../../../../../Components/Button/Button';
import CustomCheckbox from '../../../../../Components/Inputs/CustomCheckbox/CustomCheckbox';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import ProfileImage from '../../../../../Components/ProfileImage/ProfileImage';
import { RegisterMediaPayload } from '../../../../../services/forms/registerFormMedia';
import { confirmAvatarMedia } from '../../../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../../../services/identity/createBasicInformation';
import { createNewUser } from '../../../../../services/identity/createNewUser';
import { deleteAvatar } from '../../../../../services/identity/deleteAvatar';
import { registerAvatarMedia } from '../../../../../services/identity/registerAvatarMedia';
import { Address, BasicInfo, UserProfile } from '../../../../../services/identity/types/UserProfile';
import { customStylesDashboard } from '../../../../../Utils/customStyles';
import { defaultFormValues } from '../../../../MyProfile/Admin/utils/defaultFromValues';
import { AdminMyProfileSubmit } from '../../../../MyProfile/Admin/utils/types';
import { updateData } from '../../../../MyProfile/Patient/utils/utilsFunctions';
import AddressSection from '../../Components/AddressSection/AddressSection';
import styles from '../styles.module.scss';
import { validationSchema } from './validationSchema';

interface CustomStylesDashboard {
  overlay: Styles['overlay'];
  content: Styles['content'];
}

interface ProfileCardModalProps {
  isOpen: boolean;
  toggleModal: (bool: boolean) => void;
  role: string;
}

const AddAdminUserModal: FC<ProfileCardModalProps> = ({ isOpen, toggleModal, role }) => {
  const [myProfileForm, setMyProfileForm] = useState<AdminMyProfileSubmit>(defaultFormValues as AdminMyProfileSubmit);
  const [currentAvatarURL, setCurrentAvatarURL] = useState<string>('');
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isInfoSaved, setIsInfoSaved] = useState(false);
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
  const ref = React.useRef<HTMLInputElement>(null);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [image, setImage] = React.useState<string>();
  const [validationResponseId, setValidationResponseId] = useState('');
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });
  const userRoleCustomStylesDashboard: CustomStylesDashboard = {
    ...customStylesDashboard,
    overlay: {
      ...customStylesDashboard.overlay,
      backgroundColor: '#1f2b38f0',
      overflowY: 'scroll',
    },
    content: {
      ...customStylesDashboard.content,
      width: '1166px',
      height: 'auto',
      backgroundColor: '#F0F0F0',
      borderRadius: '11px',
      opacity: '1',
      padding: '40px 100px',
      transform: 'translate(-50%, -36%)',
    },
  };
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && !isOpen) {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);
  const closeModal = (): void => {
    toggleModal(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<AdminMyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const triggerProfileAvatar = React.useCallback((avatarUrl: string) => {
    setValue('profile_img', avatarUrl, { shouldValidate: true });
    setMyProfileForm({
      ...getValues(),
      profile_img: avatarUrl,
    });
  }, []);

  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof AdminMyProfileSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 0) {
      Object.keys(myProfileForm).forEach((key: unknown) => {
        setValue(key as keyof AdminMyProfileSubmit, myProfileForm[key as keyof AdminMyProfileSubmit]);
      });
    }
  }, [myProfileForm, setValue]);

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const onCancelEditProfile = (): void => {
    toggleModal(false);
    setImage(currentAvatarURL);
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    let newUser: UserProfile | undefined;
    setIsSavingProfile(true);
    try {
      newUser = await createNewUser({
        email: profileData.email?.toLowerCase(),
        ...(profileData.address1 !== '' ||
        profileData.address2 !== '' ||
        profileData.city !== '' ||
        profileData.state !== '' ||
        profileData.zip_code !== ''
          ? {
              address: {
                ...(profileData.address1 !== '' && { address1: profileData.address1 }),
                ...(profileData.address2 !== '' && { address2: profileData.address2 }),
                ...(profileData.city !== '' && { city: profileData.city }),
                ...(profileData.state !== '' && { state: profileData.state }),
                ...(profileData.zip_code !== '' && { zip_code: profileData.zip_code }),
              } as Address,
            }
          : {}),
        type: role,
        send_invitation: isSendingInvitation,
        full_name: isSendingInvitation ? `${profileData.first_name} ${profileData.last_name}` : undefined,
        ...((profileData as UnifiedProfileSubmit).phone_number && {
          phone_number: parsePhoneNumber((profileData as UnifiedProfileSubmit).phone_number, 'US')?.format(
            'E.164',
          ) as string,
        }),
      });
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    if (newUser) {
      try {
        if (avatarPayload && avatarFile && avatarAction === 'Upload') {
          const res = await registerAvatarMedia(avatarPayload);
          const mediaId = res.files[0].upload_id;
          await fetch(res.files[0].upload_url, {
            method: 'PUT',
            body: avatarFile,
            headers: { 'content-type': avatarFile.type },
          });
          await confirmAvatarMedia(mediaId, newUser.id);
          triggerProfileAvatar(res.files[0].upload_url);
        } else if (avatarAction === 'Remove') {
          await deleteAvatar(newUser.id);
          triggerProfileAvatar('');
        }
        setIsInfoSaved(true);

        const dataWithUpdatedDOB = updateData(profileData);

        newUser = await createBasicInformation(
          {
            ...(dataWithUpdatedDOB as BasicInfo),
          },
          newUser.id,
        );

        // setIsEditProfile(false);
      } catch (error: any) {
        const { status, data } = error.response;
        if (status === 400 || status === 422) {
          toast.error(data.message);
        }
      }
    }
    setIsSavingProfile(false);
    toggleModal(false);
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const onSubmitProfile = async (profileData: AdminMyProfileSubmit): Promise<void> => {
    if (
      profileData.address1 !== '' ||
      profileData.address2 !== '' ||
      profileData.city !== '' ||
      profileData.state !== '' ||
      profileData.zip_code !== ''
    ) {
      await validateAddress(
        profileData,
        getValues as UseFormGetValues<UnifiedProfileSubmit>,
        validationResponseId,
        callSaveProfile,
        setValidationResponseId,
        setShowValidationAddressModal,
        onCloseConfirmationModal,
        updateCurrentAddress,
      );
    } else callSaveProfile(profileData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={userRoleCustomStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <button
        type="button"
        className={styles.modalClose}
        aria-label="Close modal"
        onClick={closeModal}
        style={{ backgroundImage: `url(${closeModalIcon})` }}
      />
      <div className={styles.profileContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.contentContainerInner}>
            <div className={styles.editProfileContainer}>
              <div className={styles.titleContainer}>
                <span className={styles.title}>Create {role === 'SuperAdmin' && 'Super'} Admin Profile</span>
              </div>
              <div className={styles.editContainer}>
                <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitProfile, onInvalid)}>
                  <div className={styles.headerActionDiv}>
                    <ProfileImage
                      reviewProfile
                      setCurrentAvatarURL={setCurrentAvatarURL}
                      setAvatarFile={setAvatarFile}
                      setAvatarPayload={setAvatarPayload}
                      setAvatarAction={setAvatarAction}
                      triggerProfileAvatar={triggerProfileAvatar}
                      setImage={setImage}
                      image={image}
                      errors={errors}
                      size="80px"
                      isNewDependent
                    />
                    {isInfoSaved && <SuccessAlert />}
                  </div>
                  <p className={styles.requiredIndicator}>
                    Required <span>*</span>
                  </p>

                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.rowItemHalf)}>
                      <div className={styles.caption}>
                        First Name <span>*</span>
                      </div>
                      <TextField
                        {...register('first_name')}
                        errors={errors}
                        name="first_name"
                        value={getValues('first_name')}
                        onChange={(e) => setTextValue(e, 'first_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.rowItemHalf)}>
                      <div className={styles.caption}>
                        Last Name <span>*</span>
                      </div>
                      <TextField
                        {...register('last_name')}
                        errors={errors}
                        name="last_name"
                        value={getValues('last_name')}
                        onChange={(e) => setTextValue(e, 'last_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.row}>
                    <AddressSection
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      setTextValue={setTextValue}
                      setValue={setValue}
                      showValidationAddressModal={showValidationAddressModal}
                      required
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <div className={styles.caption}>Phone Number</div>
                      <div className={styles.inputWrapper}>
                        <PhoneInput
                          {...register('phone_number')}
                          value={getValues('phone_number')}
                          id="phone_number"
                          onChange={(val) => {
                            setValue('phone_number', val as string, {
                              shouldValidate: true,
                            });
                          }}
                          inputClass={styles.input}
                          errors={errors}
                          wrapperClassName={styles.inputWrapper}
                          ref={phoneInputRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <div className={styles.caption}>
                        Email Address <span>*</span>
                      </div>
                      <TextField
                        {...register('email')}
                        errors={errors}
                        name="email"
                        value={getValues('email')}
                        onChange={(e) => setTextValue(e, 'email')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                  {false && <SuccessAlert />}
                  <div className={styles.actionBtnContainer} style={{ marginTop: '20px' }}>
                    <CustomCheckbox
                      label="Invite user"
                      checked={isSendingInvitation}
                      onChange={(e) => {
                        setIsSendingInvitation(e.target.checked);
                      }}
                      containerClassName={styles.checkboxContainer}
                    />
                    <div className={styles.btnContainer}>
                      <Button type="button" className={styles.cancel} onClick={onCancelEditProfile}>
                        Cancel
                      </Button>
                      <Button type="submit" className={styles.submit} disabled={isSavingProfile}>
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default AddAdminUserModal;
