import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LocationState {
  showStateModal: boolean;
}

const initialState: LocationState = {
  showStateModal: false,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setShowStateModal: (state, action: PayloadAction<{ showStateModal: boolean }>) => {
      return {
        ...state,
        showStateModal: action.payload.showStateModal,
      };
    },
  },
});

export const { setShowStateModal } = locationSlice.actions;

export default locationSlice.reducer;
