/* eslint-disable react/no-array-index-key */
import React from 'react';

import { riskTexts } from './utils/riskTexts';

export type RiskLevel = 'low' | 'moderate' | 'high';
export type Category =
  | 'cavity_score'
  | 'plaque_buildup_score'
  | 'swollen_gums_score'
  | 'root_recession_score'
  | 'gaps_detected';

interface RiskTextDisplayProps {
  category: Category;
  riskLevel: RiskLevel;
  isDownloadMode: boolean;
}

const RiskTextDisplay: React.FC<RiskTextDisplayProps> = ({ category, riskLevel, isDownloadMode }) => {
  const mainText = riskTexts[category].main;
  const mainTextArray = mainText.split('\n');
  const riskLevelText = riskTexts[category].risk_level[riskLevel];
  const textArray = riskLevelText.split('\n');

  return (
    <span
      style={{
        display: textArray.length > 1 ? 'flex' : undefined,
        flexDirection: textArray.length > 1 ? 'column' : undefined,
        gap: textArray.length > 1 ? '20px' : 0,
      }}
    >
      {!isDownloadMode &&
        mainTextArray.map((line, index) => (
          <div
            key={index}
            style={{ paddingBottom: index === mainTextArray.length - 1 && mainTextArray.length > 1 ? 0 : 20 }}
          >
            {line}
            {mainTextArray.length - 1 !== index && <br />}
          </div>
        ))}
      {riskLevelText !== '' ? (
        textArray.map((line, index) => (
          <span key={index}>
            {line}
            {textArray.length - 1 !== index && <br />}
          </span>
        ))
      ) : riskLevelText === '' && isDownloadMode ? (
        <div style={{ paddingBottom: 0 }}>{mainTextArray[1]}</div>
      ) : null}
    </span>
  );
};

export default RiskTextDisplay;
