import React from 'react';
import { DefaultPlayer as Video } from 'react-html5video';

import modalClose from '../../assets/icons/close.svg';
import styles from './styles.module.scss';

interface IModal {
  setOpenModal: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      videoSrc: string;
    }>
  >;
  videoReviewModal: {
    isOpen: boolean;
    videoSrc: string;
  };
}

const VideoPreviewModal = ({ setOpenModal, videoReviewModal }: IModal): JSX.Element => {
  return (
    <div className={`${styles.modalBackground} ${styles.videoPreviewModal}`}>
      <div className={`${styles.modalContainer} ${styles.createConversation}`}>
        <div className={styles.body}>
          <button
            type="button"
            className={styles.modalClose}
            style={{ backgroundImage: `url(${modalClose})` }}
            aria-label="Close modal"
            onClick={() =>
              setOpenModal({
                isOpen: false,
                videoSrc: '',
              })
            }
          />
          <Video loop muted controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
            <source src={videoReviewModal.videoSrc} type="video/mp4" />
            <source src={videoReviewModal.videoSrc} type="video/webm" />
            <track label="English" kind="subtitles" srcLang="en" default />
          </Video>
        </div>
      </div>
    </div>
  );
};
export default VideoPreviewModal;
