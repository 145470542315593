import React, { useRef } from 'react';

import Resizer from './Components/Resizer';
import styles from './styles.module.scss';

interface PanelProps {
  children: React.ReactNode;
}

enum Direction {
  TopLeft = 'topLeft',
  Top = 'top',
  TopRight = 'topRight',
  Right = 'right',
  BottomRight = 'bottomRight',
  Bottom = 'bottom',
  BottomLeft = 'bottomLeft',
  Left = 'left',
}

const Panel: React.FC<PanelProps> = ({ children }) => {
  const panelRef = useRef<HTMLDivElement | null>(null);

  const handleResize = (direction: string, movementX: number, movementY: number): void => {
    const panel = panelRef.current;
    if (!panel) return;

    const { width, height, x, y } = panel.getBoundingClientRect();

    const resizeTop = (): void => {
      panel.style.height = `${height - movementY}px`;
      panel.style.top = `${y + movementY}px`;
    };

    const resizeRight = (): void => {
      panel.style.width = `${width + movementX}px`;
    };

    const resizeBottom = (): void => {
      panel.style.height = `${height + movementY}px`;
    };

    const resizeLeft = (): void => {
      panel.style.width = `${width - movementX}px`;
      panel.style.left = `${x + movementX}px`;
    };

    switch (direction) {
      case Direction.TopLeft:
        resizeTop();
        resizeLeft();
        break;

      case Direction.Top:
        resizeTop();
        break;

      case Direction.TopRight:
        resizeTop();
        resizeRight();
        break;

      case Direction.Right:
        resizeRight();
        break;

      case Direction.BottomRight:
        resizeBottom();
        resizeRight();
        break;

      case Direction.Bottom:
        resizeBottom();
        break;

      case Direction.BottomLeft:
        resizeBottom();
        resizeLeft();
        break;

      case Direction.Left:
        resizeLeft();
        break;

      default:
        break;
    }
  };

  return (
    <div className={styles.panel} ref={panelRef}>
      <div className={styles.panelContainer}>
        <Resizer onResize={handleResize} />

        <div className={styles.panelContent}>{children}</div>
      </div>
    </div>
  );
};

export default Panel;
