/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import TextField from '@brands/Components/Inputs/TextField/TextField';
import ValidateAddressModal from '@brands/Dashboard/MyProfile/Patient/Sections/ValidateAddressModal/ValidateAddressModal';
import { selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { stateOptions } from '@brands/Utils/selectOptions';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { ValidationModalInterface } from '@brands/Utils/validateAddress';
import cn from 'classnames';
import React from 'react';
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import styles from './styles.module.scss';

interface AddressSectionProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<UnifiedProfileSubmit>;
  getValues: UseFormGetValues<any>;
  setTextValue: (e: React.ChangeEvent<HTMLInputElement>, name: string) => void;
  setValue: UseFormSetValue<any>;
  showValidationAddressModal: ValidationModalInterface;
  required: boolean;
}

const AddressSection: React.FC<AddressSectionProps> = ({
  register,
  errors,
  getValues,
  setTextValue,
  setValue,
  required,
  showValidationAddressModal,
}): JSX.Element => {
  return (
    <>
      <div className={styles.rowItem}>
        <div className={styles.caption}>Street Address{required && <span> *</span>}</div>
        <TextField
          {...register('address1')}
          errors={errors}
          name="address1"
          value={getValues('address1')}
          onChange={(e) => setTextValue(e, 'address1')}
          wrapperClassName={styles.inputWrapper}
          inputClass={styles.input}
        />
      </div>
      <div className={styles.rowItem}>
        <div className={styles.caption}>Apt, suite, building (optional)</div>
        <TextField
          {...register('address2')}
          errors={errors}
          name="address2"
          value={getValues('address2')}
          onChange={(e) => setTextValue(e, 'address2')}
          wrapperClassName={styles.inputWrapper}
          inputClass={styles.input}
        />
      </div>
      <div className={cn(styles.sameRow)}>
        <div className={cn(styles.rowItem, styles.details)}>
          <div className={styles.caption}>City{required && <span> *</span>}</div>
          <TextField
            {...register('city')}
            errors={errors}
            name="city"
            value={getValues('city')}
            onChange={(e) => setTextValue(e, 'city')}
            wrapperClassName={styles.inputWrapper}
            inputClass={styles.input}
          />
        </div>
        <div className={cn(styles.rowItem, styles.details)}>
          <div className={styles.caption}>State{required && <span> *</span>}</div>
          <SelectInput
            {...register('state')}
            name="state"
            options={stateOptions}
            containerClass={styles.inputWrapper}
            selectorClass={styles.selector}
            style={selectCommonCustomStylesLarge}
            errors={errors}
            selectedValue={getValues('state')}
            onChange={(value) => {
              if (value) {
                setValue('state', value.value, { shouldValidate: true });
              }
            }}
          />
        </div>
        <div className={cn(styles.rowItem, styles.details)}>
          <div className={styles.caption}>Zip Code{required && <span> *</span>}</div>
          <TextField
            {...register('zip_code')}
            errors={errors}
            name="zip_code"
            value={getValues('zip_code')}
            onChange={(e) => setTextValue(e, 'zip_code')}
            wrapperClassName={styles.inputWrapper}
            inputClass={styles.input}
          />
        </div>
      </div>
      <ValidateAddressModal
        title={showValidationAddressModal.title}
        isOpen={showValidationAddressModal.isOpen}
        recommended={showValidationAddressModal.recommended}
        recommendedAddress={showValidationAddressModal.recommendedAddress}
        whatYouEntered={showValidationAddressModal.whatYouEntered}
        onClose={showValidationAddressModal.onClose}
        onKeepCurrentAddress={showValidationAddressModal.onKeepCurrentAddress}
        onConfirmChange={showValidationAddressModal.onConfirmChange}
      />
    </>
  );
};

export default AddressSection;
