import { LocalDataTrack } from 'twilio-video';

export class DataTrackManager {
  static dataTrack: LocalDataTrack;

  static dataTrackPublished: {
    promise?: Promise<void>;
    resolve?: (value: void | PromiseLike<void>) => void;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    reject?: (reason?: any) => void;
  } = {};

  static sendMessage = (message: string): void => {
    this.dataTrackPublished.promise?.then(() => this.dataTrack.send(message));
  };

  static reset = (): void => {
    this.dataTrack = new LocalDataTrack({
      maxPacketLifeTime: 1000,
    });
    this.dataTrackPublished.promise = new Promise((resolve, reject) => {
      this.dataTrackPublished.resolve = resolve;
      this.dataTrackPublished.reject = reject;
    });
  };
}
