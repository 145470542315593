import { useAppSelector } from '@brands/hooks/useReduxHook';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import modalClose from '../../../assets/icons/close.svg';
import styles from './dashboardModal.module.scss';

const InstantConsultationConfirmationModal = (): JSX.Element => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userInfo } = useAppSelector(selectAuth);
  const goToDashboard = useCallback(() => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      navigate(`/admin/review-profile/Patient/${state.patientId}`);
    } else {
      navigate('/dashboard');
    }
  }, [userInfo.role.name, navigate]);

  return (
    <div className={styles.modalBackground} onClick={() => goToDashboard()}>
      <div
        className={`${styles.modalContainer} ${styles.scheduleLiveConsultation}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          type="button"
          className={styles.modalClose}
          style={{ backgroundImage: `url(${modalClose})` }}
          aria-label="Close modal"
          onClick={() => goToDashboard()}
        />
        <div className={styles.body}>
          <p>Thanks! Your appointment has been successfully created.</p>
        </div>
      </div>
    </div>
  );
};
export default InstantConsultationConfirmationModal;
