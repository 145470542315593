/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import modalClose from '../../../../../assets/icons/close.svg';
import styles from './styles.module.scss';

type CameraProps = {
  onClose: () => void;
  onPhotoTaken: (uri: string) => void;
};

const Camera: React.FC<CameraProps> = ({ onClose, onPhotoTaken }) => {
  const cameraRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const startCamera = async (): Promise<void> => {
    try {
      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: 'user', // use the User camera by default Selfie
        },
        audio: false,
      };
      if (navigator.mediaDevices) {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        cameraRef.current!.srcObject = stream;
        cameraRef.current!.play();
      } else {
        toast.warn('getUserMedia not supported on this browser');
      }
    } catch (err) {
      toast.error('Please allow camera access to take a photo');
      onClose();
    }
  };

  useEffect(() => {
    if (cameraRef.current) {
      startCamera();
    }
  }, [cameraRef]);

  const stopCamera = (): void => {
    const stream = cameraRef.current!.srcObject as MediaStream;

    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      cameraRef.current!.srcObject = null;
    }
  };

  const dataURLToBlob = (dataURL: string): Blob => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const takePicture = (): void => {
    const context = canvasRef.current!.getContext('2d');
    context?.drawImage(cameraRef.current!, 0, 0, 640, 480);
    const dataUrl = canvasRef.current!.toDataURL('image/png');
    const blob = dataURLToBlob(dataUrl);
    const file = new File([blob], 'Capture.png', {
      type: 'image/png',
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageDataUrl = reader.result as string;
      // dispatch(addImage(imageDataUrl));
      onPhotoTaken(imageDataUrl);
    };
    reader.readAsDataURL(file);
    stopCamera();
    onClose();
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.closeBtn}>
          <button
            type="button"
            className={styles.modalClose}
            style={{ backgroundImage: `url(${modalClose})` }}
            aria-label="Close modal"
            onClick={() => {
              stopCamera();
              onClose();
            }}
          />
        </div>
        <div className={styles.videoDiv}>
          <video
            playsInline
            muted
            autoPlay
            onClick={() => {
              if (cameraRef.current && cameraRef.current.paused) {
                cameraRef.current.play();
              }
            }}
            ref={cameraRef}
          />
          <canvas ref={canvasRef} width="640" height="480" />
        </div>
        <div className={styles.takePicture}>
          <button className={styles.capture} onClick={takePicture} type="button">
            Take Picture
          </button>
        </div>
        {/*         <div className={styles.body}>
          <button
            type="button"
            className={styles.modalClose}
            style={{ backgroundImage: `url(${modalClose})` }}
            aria-label="Close modal"
            onClick={() => {
              stopCamera();
              onClose();
            }}
          />
          <div className={styles.cameraDiv}>
            <video
              playsInline
              muted
              autoPlay
              onClick={() => {
                if (cameraRef.current && cameraRef.current.paused) {
                  cameraRef.current.play();
                }
              }}
              ref={cameraRef}
              width="640"
              height="480"
            />
            <canvas ref={canvasRef} width="640" height="480" />
          </div>
          <button className={styles.capture} onClick={takePicture} type="button">
            Take Picture
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Camera;
