import { client } from '../client';
import { updateData } from './createProviderLicense';
import { License } from './types/UserProfile';

export const updateProviderLicense = async (
  payload: License,
  provider_license_id: number,
  user_id: number,
): Promise<License> => {
  const updatedProviderLicenseExpiryDate = updateData(payload);
  const { data } = await client.patch<License>(
    `/users/${user_id}/provider_profile/licenses/${provider_license_id}`,
    updatedProviderLicenseExpiryDate,
  );

  return data;
};
