import { client } from '../client';
import { Dependent } from './types/Dependent';

type CreateDependentPayload = {
  guardian_relationship: string;
  email?: string | null;
};

export const createDependent = async (user_id: number, payload: CreateDependentPayload): Promise<Dependent> => {
  const { data } = await client.post<Dependent>(`/users/${user_id}/dependents`, payload);

  return data;
};
