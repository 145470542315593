import { client } from '../client';
import { UserProfile } from './types/UserProfile';

export const verifyInsuranceByAdmin = async ({
  user_id,
  payer_id,
  member_id,
  insured_by,
  group_number,
}: {
  user_id: string;
  payer_id: string;
  member_id: string;
  insured_by: string;
  group_number: string;
}): Promise<UserProfile> => {
  const { data } = await client.post(`/admin/users/${user_id}/verify_insurance`, {
    payer_id,
    member_id,
    insured_by,
    group_number,
  });
  return data;
};
