import { CaseType, Status } from '@brands/services/cases/types/ICase';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';

export type Option = {
  // NOTE: This should templated type like Option<T>
  // for now, just make it to any and we will update it later
  // eslint-disable-next-line
  value: any;
  label: string;
  status?: string;
  id?: string | number;
  organization_id?: string;
  isSelected?: boolean;
  disabled?: boolean;
  image?: string;
  cachedImage?: string;
  hasImageError?: boolean;
};

export type DivisionOption = {
  value: number;
  label: string;
};

export type PracticeOption = {
  value: number;
  label: string;
};

export type OptionLanguage = {
  value: string;
  label: string;
  id: string;
};

export type OptionPainLevel = {
  value: string;
  label: string;
  id: string;
};

export const painOptions: OptionPainLevel[] = [
  { value: 'sharp', label: 'Sharp', id: 'sharp' },
  { value: 'dull', label: 'Dull', id: 'dull' },
  { value: 'throbbing', label: 'Throbbing', id: 'throbbing' },
  { value: 'sensitive_to_hot', label: 'Sensitive to hot', id: 'sensitive_to_hot' },
  { value: 'sensitive_to_cold', label: 'Sensitive to cold', id: 'sensitive_to_cold' },
  { value: 'sensitive_to_sweet', label: 'Sensitive to sweet', id: 'sensitive_to_sweet' },
  { value: 'off_and_on', label: 'On and off', id: 'off_and_on' },
  { value: 'constant', label: 'Constant', id: 'constant' },
  { value: 'burning', label: 'Burning', id: 'burning' },
];

export type OptionInsurance = {
  value: number;
  label: string;
  id: number;
};

export type OptionCDT = {
  code: string;
  nomenclature: string;
};

export type OptionUniversity = {
  value: string;
  label: string;
};

export const licenseOptions: Option[] = [
  {
    value: 'DDS',
    label: 'DDS',
  },
  {
    value: 'DMD',
    label: 'DMD',
  },
];

export const caseStatusOptions: Option[] = [
  { value: Status.New, label: 'New' },
  { value: Status.AcceptedCareAdvisor, label: 'Accepted' },
  { value: Status.AcceptedProvider, label: 'Provider Assigned' },
  { value: Status.InProgressProvider, label: 'With Provider' },
  { value: Status.CompletedByProvider, label: 'Completed By Provider' },
  { value: Status.InProgressCareAdvisor, label: 'In Progress' },
  { value: Status.Completed, label: 'Completed' },
  { value: Status.Canceled, label: 'Canceled' },
];

export const caseTypeOptions: Option[] = [
  { value: CaseType.video_call_instant, label: 'Instant Video Call' },
  { value: CaseType.video_call_scheduled, label: 'Scheduled Video Call' },
  { value: CaseType.one_report, label: 'One Report' },
  { value: CaseType.second_opinion, label: 'Second Opinion' },
  { value: CaseType.smile_scan, label: 'Smile Scan' },
  { value: CaseType.phone_call_scheduled, label: 'Scheduled Phone Call' },
];

export const patientTypeOptions: Option[] = [
  { value: UserRoleName.Patient, label: 'Patient' },
  { value: UserRoleName.Dependent, label: 'Dependent' },
];

export const paymentMethodOptions: Option[] = [
  { value: 'insurance', label: 'Insurance' },
  { value: 'self_pay', label: 'Self Pay' },
  { value: 'free', label: 'Free Case' },
];

export const careCoordinationTypeOptions: Option[] = [
  {
    value: 'general_dentist',
    label: 'General Dentist',
  },
  {
    value: 'dental_specialist',
    label: 'Dental Specialist',
  },
  {
    value: 'physician',
    label: 'Physician',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const careCoordinationUrgencyOptions: Option[] = [
  {
    value: 'low',
    label: 'Low',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'high',
    label: 'High',
  },
  {
    value: 'critical',
    label: 'Critical',
  },
];

export const oralHealthCoachingTypeOptions: Option[] = [
  {
    value: 'diabetes',
    label: 'Diabetes',
  },
  {
    value: 'heart_disease',
    label: 'Heart Disease',
  },
  {
    value: 'dry_mouth',
    label: 'Dry Mouth',
  },
  {
    value: 'excessive_decay',
    label: 'Excessive Decay',
  },
  {
    value: 'oral_health_literacy',
    label: 'Oral Health Literacy',
  },
  {
    value: 'dementia',
    label: 'Dementia',
  },
  {
    value: 'pregnancy',
    label: 'Pregnancy',
  },
  {
    value: 'obesity',
    label: 'Obesity',
  },
  {
    value: 'respiratory_disease',
    label: 'Respiratory Disease',
  },
  {
    value: 'cancer',
    label: 'Cancer',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const stateOptions: Option[] = [
  {
    value: 'AL',
    label: 'AL - Alabama',
  },
  {
    value: 'AK',
    label: 'AK - Alaska',
  },
  {
    value: 'AZ',
    label: 'AZ - Arizona',
  },
  {
    value: 'AR',
    label: 'AR - Arkansas',
  },
  {
    value: 'CA',
    label: 'CA - California',
  },
  {
    value: 'CO',
    label: 'CO - Colorado',
  },
  {
    value: 'CT',
    label: 'CT - Connecticut',
  },
  {
    value: 'DE',
    label: 'DE - Delaware',
  },
  {
    value: 'DC',
    label: 'DC - District Of Columbia',
  },
  {
    value: 'FL',
    label: 'FL - Florida',
  },
  {
    value: 'GA',
    label: 'GA - Georgia',
  },
  {
    value: 'HI',
    label: 'HI - Hawaii',
  },
  {
    value: 'ID',
    label: 'ID - Idaho',
  },
  {
    value: 'IL',
    label: 'IL - Illinois',
  },
  {
    value: 'IN',
    label: 'IN - Indiana',
  },
  {
    value: 'IA',
    label: 'IA - Iowa',
  },
  {
    value: 'KS',
    label: 'KS - Kansas',
  },
  {
    value: 'KY',
    label: 'KY - Kentucky',
  },
  {
    value: 'LA',
    label: 'LA - Louisiana',
  },
  {
    value: 'ME',
    label: 'ME - Maine',
  },
  {
    value: 'MD',
    label: 'MD - Maryland',
  },
  {
    value: 'MA',
    label: 'MA - Massachusetts',
  },
  {
    value: 'MI',
    label: 'MI - Michigan',
  },
  {
    value: 'MN',
    label: 'MN - Minnesota',
  },
  {
    value: 'MS',
    label: 'MS - Mississippi',
  },
  {
    value: 'MO',
    label: 'MO - Missouri',
  },
  {
    value: 'MT',
    label: 'MT - Montana',
  },
  {
    value: 'NE',
    label: 'NE - Nebraska',
  },
  {
    value: 'NV',
    label: 'NV - Nevada',
  },
  {
    value: 'NH',
    label: 'NH - New Hampshire',
  },
  {
    value: 'NJ',
    label: 'NJ - New Jersey',
  },
  {
    value: 'NM',
    label: 'NM - New Mexico',
  },
  {
    value: 'NY',
    label: 'NY - New York',
  },
  {
    value: 'NC',
    label: 'NC - North Carolina',
  },
  {
    value: 'ND',
    label: 'ND - North Dakota',
  },
  {
    value: 'OH',
    label: 'OH - Ohio',
  },
  {
    value: 'OK',
    label: 'OK - Oklahoma',
  },
  {
    value: 'OR',
    label: 'OR - Oregon',
  },
  {
    value: 'PA',
    label: 'PA - Pennsylvania',
  },
  {
    value: 'RI',
    label: 'RI - Rhode Island',
  },
  {
    value: 'SC',
    label: 'SC - South Carolina',
  },
  {
    value: 'SD',
    label: 'SD - South Dakota',
  },
  {
    value: 'TN',
    label: 'TN - Tennessee',
  },
  {
    value: 'TX',
    label: 'TX - Texas',
  },
  {
    value: 'UT',
    label: 'UT - Utah',
  },
  {
    value: 'VT',
    label: 'VT - Vermont',
  },
  {
    value: 'VA',
    label: 'VA - Virginia',
  },
  {
    value: 'WA',
    label: 'WA - Washington',
  },
  {
    value: 'WV',
    label: 'WV - West Virginia',
  },
  {
    value: 'WI',
    label: 'WI - Wisconsin',
  },
  {
    value: 'WY',
    label: 'WY - Wyoming',
  },
];

export const paymentOptions: Option[] = [
  { value: 'D', label: 'Daily' },
  { value: 'W', label: 'Weekly' },
  { value: 'M', label: 'Monthly' },
];

export const insuranceThroughOptions: Option[] = [
  { value: 'none', label: 'No insurance' },
  { value: 'myself', label: 'Myself' },
  { value: 'spouse', label: 'Spouse' },
  { value: 'parent', label: 'Parent' },
  { value: 'foster_parent', label: 'Foster parent' },
  { value: 'step_parent', label: 'Stepparent' },
  { value: 'grand_parent', label: 'Grandparent' },
  { value: 'legal_guardian', label: 'Legal guardian' },
];

export const sortByOptions: Option[] = [
  { value: 'full_name', label: 'Name' },
  { value: 'license_states', label: 'License State' },
  { value: 'specialty', label: 'Specialty' },
  { value: 'plan', label: 'Insurance' },
  { value: 'availability', label: 'Availability' },
  { value: 'languages', label: 'Language' },
];

export const specialtyOptions: Option[] = [
  { value: 'endodontics', label: 'Endodontics' },
  { value: 'general', label: 'General' },
  { value: 'pediatric', label: 'Pediatric' },
  { value: 'periodontics', label: 'Periodontics' },
  { value: 'prosthodontics', label: 'Prosthodontics' },
  { value: 'orthodontics', label: 'Orthodontics' },
  { value: 'oral surgery', label: 'Oral Surgery' },
  { value: 'dental public health', label: 'Dental Public Health' },
  { value: 'dental hygiene', label: 'Dental Hygiene' },
  { value: 'dental laboratory technology', label: 'Dental Laboratory Technology' },
  { value: 'dental therapy', label: 'Dental Therapy' },
];

export const countryOptions = [
  {
    label: 'United States of America',
    value: 'US',
  },
  {
    label: 'Afghanistan',
    value: 'AF',
  },
  {
    label: 'Albania',
    value: 'AL',
  },
  {
    label: 'Algeria',
    value: 'DZ',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Andorra',
    value: 'AD',
  },
  {
    label: 'Angola',
    value: 'AO',
  },
  {
    label: 'Anguilla',
    value: 'AI',
  },
  {
    label: 'Antarctica',
    value: 'AQ',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG',
  },
  {
    label: 'Argentina',
    value: 'AR',
  },
  {
    label: 'Armenia',
    value: 'AM',
  },
  {
    label: 'Aruba',
    value: 'AW',
  },
  {
    label: 'Australia',
    value: 'AU',
  },
  {
    label: 'Austria',
    value: 'AT',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
  },
  {
    label: 'Bahamas (the)',
    value: 'BS',
  },
  {
    label: 'Bahrain',
    value: 'BH',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
  },
  {
    label: 'Barbados',
    value: 'BB',
  },
  {
    label: 'Belarus',
    value: 'BY',
  },
  {
    label: 'Belgium',
    value: 'BE',
  },
  {
    label: 'Belize',
    value: 'BZ',
  },
  {
    label: 'Benin',
    value: 'BJ',
  },
  {
    label: 'Bermuda',
    value: 'BM',
  },
  {
    label: 'Bhutan',
    value: 'BT',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'BO',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    label: 'Botswana',
    value: 'BW',
  },
  {
    label: 'Bouvet Island',
    value: 'BV',
  },
  {
    label: 'Brazil',
    value: 'BR',
  },
  {
    label: 'British Indian Ocean Territory (the)',
    value: 'IO',
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
  },
  {
    label: 'Burundi',
    value: 'BI',
  },
  {
    label: 'Cabo Verde',
    value: 'CV',
  },
  {
    label: 'Cambodia',
    value: 'KH',
  },
  {
    label: 'Cameroon',
    value: 'CM',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Cayman Islands (the)',
    value: 'KY',
  },
  {
    label: 'Central African Republic (the)',
    value: 'CF',
  },
  {
    label: 'Chad',
    value: 'TD',
  },
  {
    label: 'Chile',
    value: 'CL',
  },
  {
    label: 'China',
    value: 'CN',
  },
  {
    label: 'Christmas Island',
    value: 'CX',
  },
  {
    label: 'Cocos (Keeling) Islands (the)',
    value: 'CC',
  },
  {
    label: 'Colombia',
    value: 'CO',
  },
  {
    label: 'Comoros (the)',
    value: 'KM',
  },
  {
    label: 'Congo (the Democratic Republic of the)',
    value: 'CD',
  },
  {
    label: 'Congo (the)',
    value: 'CG',
  },
  {
    label: 'Cook Islands (the)',
    value: 'CK',
  },
  {
    label: 'Costa Rica',
    value: 'CR',
  },
  {
    label: 'Croatia',
    value: 'HR',
  },
  {
    label: 'Cuba',
    value: 'CU',
  },
  {
    label: 'Curaçao',
    value: 'CW',
  },
  {
    label: 'Cyprus',
    value: 'CY',
  },
  {
    label: 'Czechia',
    value: 'CZ',
  },
  {
    label: "Côte d'Ivoire",
    value: 'CI',
  },
  {
    label: 'Denmark',
    value: 'DK',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
  },
  {
    label: 'Dominica',
    value: 'DM',
  },
  {
    label: 'Dominican Republic (the)',
    value: 'DO',
  },
  {
    label: 'Ecuador',
    value: 'EC',
  },
  {
    label: 'Egypt',
    value: 'EG',
  },
  {
    label: 'El Salvador',
    value: 'SV',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
  },
  {
    label: 'Eritrea',
    value: 'ER',
  },
  {
    label: 'Estonia',
    value: 'EE',
  },
  {
    label: 'Eswatini',
    value: 'SZ',
  },
  {
    label: 'Ethiopia',
    value: 'ET',
  },
  {
    label: 'Falkland Islands (the) [Malvinas]',
    value: 'FK',
  },
  {
    label: 'Faroe Islands (the)',
    value: 'FO',
  },
  {
    label: 'Fiji',
    value: 'FJ',
  },
  {
    label: 'Finland',
    value: 'FI',
  },
  {
    label: 'France',
    value: 'FR',
  },
  {
    label: 'French Guiana',
    value: 'GF',
  },
  {
    label: 'French Polynesia',
    value: 'PF',
  },
  {
    label: 'French Southern Territories (the)',
    value: 'TF',
  },
  {
    label: 'Gabon',
    value: 'GA',
  },
  {
    label: 'Gambia (the)',
    value: 'GM',
  },
  {
    label: 'Georgia',
    value: 'GE',
  },
  {
    label: 'Germany',
    value: 'DE',
  },
  {
    label: 'Ghana',
    value: 'GH',
  },
  {
    label: 'Gibraltar',
    value: 'GI',
  },
  {
    label: 'Greece',
    value: 'GR',
  },
  {
    label: 'Greenland',
    value: 'GL',
  },
  {
    label: 'Grenada',
    value: 'GD',
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Guatemala',
    value: 'GT',
  },
  {
    label: 'Guernsey',
    value: 'GG',
  },
  {
    label: 'Guinea',
    value: 'GN',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    label: 'Guyana',
    value: 'GY',
  },
  {
    label: 'Haiti',
    value: 'HT',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'HM',
  },
  {
    label: 'Holy See (the)',
    value: 'VA',
  },
  {
    label: 'Honduras',
    value: 'HN',
  },
  {
    label: 'Hong Kong',
    value: 'HK',
  },
  {
    label: 'Hungary',
    value: 'HU',
  },
  {
    label: 'Iceland',
    value: 'IS',
  },
  {
    label: 'India',
    value: 'IN',
  },
  {
    label: 'Indonesia',
    value: 'ID',
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'IR',
  },
  {
    label: 'Iraq',
    value: 'IQ',
  },
  {
    label: 'Ireland',
    value: 'IE',
  },
  {
    label: 'Isle of Man',
    value: 'IM',
  },
  {
    label: 'Israel',
    value: 'IL',
  },
  {
    label: 'Italy',
    value: 'IT',
  },
  {
    label: 'Jamaica',
    value: 'JM',
  },
  {
    label: 'Japan',
    value: 'JP',
  },
  {
    label: 'Jersey',
    value: 'JE',
  },
  {
    label: 'Jordan',
    value: 'JO',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
  },
  {
    label: 'Kenya',
    value: 'KE',
  },
  {
    label: 'Kiribati',
    value: 'KI',
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    value: 'KP',
  },
  {
    label: 'Korea (the Republic of)',
    value: 'KR',
  },
  {
    label: 'Kuwait',
    value: 'KW',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
  },
  {
    label: "Lao People's Democratic Republic (the)",
    value: 'LA',
  },
  {
    label: 'Latvia',
    value: 'LV',
  },
  {
    label: 'Lebanon',
    value: 'LB',
  },
  {
    label: 'Lesotho',
    value: 'LS',
  },
  {
    label: 'Liberia',
    value: 'LR',
  },
  {
    label: 'Libya',
    value: 'LY',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
  },
  {
    label: 'Lithuania',
    value: 'LT',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
  },
  {
    label: 'Macao',
    value: 'MO',
  },
  {
    label: 'Madagascar',
    value: 'MG',
  },
  {
    label: 'Malawi',
    value: 'MW',
  },
  {
    label: 'Malaysia',
    value: 'MY',
  },
  {
    label: 'Maldives',
    value: 'MV',
  },
  {
    label: 'Mali',
    value: 'ML',
  },
  {
    label: 'Malta',
    value: 'MT',
  },
  {
    label: 'Marshall Islands (the)',
    value: 'MH',
  },
  {
    label: 'Martinique',
    value: 'MQ',
  },
  {
    label: 'Mauritania',
    value: 'MR',
  },
  {
    label: 'Mauritius',
    value: 'MU',
  },
  {
    label: 'Mayotte',
    value: 'YT',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
  {
    label: 'Micronesia (Federated States of)',
    value: 'FM',
  },
  {
    label: 'Moldova (the Republic of)',
    value: 'MD',
  },
  {
    label: 'Monaco',
    value: 'MC',
  },
  {
    label: 'Mongolia',
    value: 'MN',
  },
  {
    label: 'Montenegro',
    value: 'ME',
  },
  {
    label: 'Montserrat',
    value: 'MS',
  },
  {
    label: 'Morocco',
    value: 'MA',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
  },
  {
    label: 'Myanmar',
    value: 'MM',
  },
  {
    label: 'Namibia',
    value: 'NA',
  },
  {
    label: 'Nauru',
    value: 'NR',
  },
  {
    label: 'Nepal',
    value: 'NP',
  },
  {
    label: 'Netherlands (the)',
    value: 'NL',
  },
  {
    label: 'New Caledonia',
    value: 'NC',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
  },
  {
    label: 'Niger (the)',
    value: 'NE',
  },
  {
    label: 'Nigeria',
    value: 'NG',
  },
  {
    label: 'Niue',
    value: 'NU',
  },
  {
    label: 'Norfolk Island',
    value: 'NF',
  },
  {
    label: 'Northern Mariana Islands (the)',
    value: 'MP',
  },
  {
    label: 'Norway',
    value: 'NO',
  },
  {
    label: 'Oman',
    value: 'OM',
  },
  {
    label: 'Pakistan',
    value: 'PK',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Palestine, State of',
    value: 'PS',
  },
  {
    label: 'Panama',
    value: 'PA',
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
  },
  {
    label: 'Paraguay',
    value: 'PY',
  },
  {
    label: 'Peru',
    value: 'PE',
  },
  {
    label: 'Philippines (the)',
    value: 'PH',
  },
  {
    label: 'Pitcairn',
    value: 'PN',
  },
  {
    label: 'Poland',
    value: 'PL',
  },
  {
    label: 'Portugal',
    value: 'PT',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Qatar',
    value: 'QA',
  },
  {
    label: 'Republic of North Macedonia',
    value: 'MK',
  },
  {
    label: 'Romania',
    value: 'RO',
  },
  {
    label: 'Russian Federation (the)',
    value: 'RU',
  },
  {
    label: 'Rwanda',
    value: 'RW',
  },
  {
    label: 'Réunion',
    value: 'RE',
  },
  {
    label: 'Saint Barthélemy',
    value: 'BL',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN',
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'MF',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
  },
  {
    label: 'Samoa',
    value: 'WS',
  },
  {
    label: 'San Marino',
    value: 'SM',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
  },
  {
    label: 'Senegal',
    value: 'SN',
  },
  {
    label: 'Serbia',
    value: 'RS',
  },
  {
    label: 'Seychelles',
    value: 'SC',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
  },
  {
    label: 'Singapore',
    value: 'SG',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'SX',
  },
  {
    label: 'Slovakia',
    value: 'SK',
  },
  {
    label: 'Slovenia',
    value: 'SI',
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
  },
  {
    label: 'Somalia',
    value: 'SO',
  },
  {
    label: 'South Africa',
    value: 'ZA',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
  },
  {
    label: 'South Sudan',
    value: 'SS',
  },
  {
    label: 'Spain',
    value: 'ES',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
  },
  {
    label: 'Sudan (the)',
    value: 'SD',
  },
  {
    label: 'Suriname',
    value: 'SR',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
  },
  {
    label: 'Sweden',
    value: 'SE',
  },
  {
    label: 'Switzerland',
    value: 'CH',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY',
  },
  {
    label: 'Taiwan',
    value: 'TW',
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'TZ',
  },
  {
    label: 'Thailand',
    value: 'TH',
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
  },
  {
    label: 'Togo',
    value: 'TG',
  },
  {
    label: 'Tokelau',
    value: 'TK',
  },
  {
    label: 'Tonga',
    value: 'TO',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
  },
  {
    label: 'Tunisia',
    value: 'TN',
  },
  {
    label: 'Turkey',
    value: 'TR',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
  },
  {
    label: 'Turks and Caicos Islands (the)',
    value: 'TC',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
  },
  {
    label: 'Uganda',
    value: 'UG',
  },
  {
    label: 'Ukraine',
    value: 'UA',
  },
  {
    label: 'United Arab Emirates (the)',
    value: 'AE',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland (the)',
    value: 'GB',
  },
  {
    label: 'United States Minor Outlying Islands (the)',
    value: 'UM',
  },
  {
    label: 'Uruguay',
    value: 'UY',
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 'VE',
  },
  {
    label: 'Viet Nam',
    value: 'VN',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'VG',
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'VI',
  },
  {
    label: 'Wallis and Futuna',
    value: 'WF',
  },
  {
    label: 'Western Sahara',
    value: 'EH',
  },
  {
    label: 'Yemen',
    value: 'YE',
  },
  {
    label: 'Zambia',
    value: 'ZM',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
  },
  {
    label: 'Åland Islands',
    value: 'AX',
  },
];

export const oneReportTreatmentsCategory = [
  { value: 'diagnostic', label: 'Diagnostic' },
  { value: 'maxillofacial_prosthetics', label: 'Maxillofacial Prosthetics' },
  { value: 'preventive', label: 'Preventive' },
  { value: 'restorative', label: 'Restorative' },
  { value: 'endodontics', label: 'Endodontics' },
  { value: 'periodontics', label: 'Periodontics' },
  { value: 'prosthodontics', label: 'Prosthodontics' },
  { value: 'prosthodontics_removable', label: 'Prosthodontics Removable' },
  { value: 'prosthodontics_fixed', label: 'Prosthodontics Fixed' },
  { value: 'orthodontics', label: 'Orthodontics' },
  { value: 'implant_services', label: 'Implant Services' },
  { value: 'oral_and_maxillofacial_surgery', label: 'Oral and Maxillofacial Surgery' },
  { value: 'adjunctive_general_services', label: 'Adjunctive General Services' },
];

export const oneReportTreatmentsToothNumber = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
  { value: '32', label: '32' },
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'c', label: 'C' },
  { value: 'd', label: 'D' },
  { value: 'e', label: 'E' },
  { value: 'f', label: 'F' },
  { value: 'g', label: 'G' },
  { value: 'h', label: 'H' },
  { value: 'i', label: 'I' },
  { value: 'j', label: 'J' },
  { value: 'k', label: 'K' },
  { value: 'l', label: 'L' },
  { value: 'm', label: 'M' },
  { value: 'n', label: 'N' },
  { value: 'o', label: 'O' },
  { value: 'p', label: 'P' },
  { value: 'q', label: 'Q' },
  { value: 'r', label: 'R' },
  { value: 's', label: 'S' },
  { value: 't', label: 'T' },
  { value: 'ur', label: 'UR' },
  { value: 'lr', label: 'LR' },
  { value: 'ul', label: 'UL' },
  { value: 'll', label: 'LL' },
  { value: 'ua', label: 'UA' },
  { value: 'la', label: 'LA' },
  { value: 'fm', label: 'FM' },
  { value: 'other', label: 'Other' },
];

export const oneReportTreatmentsUrgency = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
  { value: 'critical', label: 'Critical' },
];

export const dayOptions = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
];
export const monthOptions = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];
export const yearOptions = [
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
];
export const hourOptions = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];
export const minuteOptions = [
  { value: '00', label: '00' },
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
  { value: '32', label: '32' },
  { value: '33', label: '33' },
  { value: '34', label: '34' },
  { value: '35', label: '35' },
  { value: '36', label: '36' },
  { value: '37', label: '37' },
  { value: '38', label: '38' },
  { value: '39', label: '39' },
  { value: '40', label: '40' },
  { value: '41', label: '41' },
  { value: '42', label: '42' },
  { value: '43', label: '43' },
  { value: '44', label: '44' },
  { value: '45', label: '45' },
  { value: '46', label: '46' },
  { value: '47', label: '47' },
  { value: '48', label: '48' },
  { value: '49', label: '49' },
  { value: '50', label: '50' },
  { value: '51', label: '51' },
  { value: '52', label: '52' },
  { value: '53', label: '53' },
  { value: '54', label: '54' },
  { value: '55', label: '55' },
  { value: '56', label: '56' },
  { value: '57', label: '57' },
  { value: '58', label: '58' },
  { value: '59', label: '59' },
];
export const dayTimeOptions = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' },
];
