import { displayErrorDetails } from '@brands/Utils/displayError';
import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { config } from '../config/config';
import { getEventSubscribeUrl } from '../services/iot/getEventSubscribeUrl';
import { iotClient } from '../services/iot/IotClient';
import useIdentityContext from './useIdentityContext';

export interface Subscriber {
  /**
   * Callback when event happens.
   *
   * @param message message from server
   */
  onMessage: (message: Record<string, any>) => void | Promise<void>;

  /**
   * List of commands to subscribe to.
   * Use an empty array to apply no filter.
   * i.e. ['case_created', 'case_updated']
   */
  commands: string[];
}

export default function useSubscribe(): {
  subscribe: (subscriber: Subscriber) => string;
  unsubscribe: (id: string) => void;
} {
  const subId = useMemo(() => uuidv4(), []);

  const { userInfo } = useIdentityContext();
  const userId = userInfo?.id;

  const subscribe = useCallback(
    (subscriber: Subscriber): string => {
      return iotClient.onMessage(subId, (topic, message) => {
        if ('command' in message) {
          if (subscriber.commands.length === 0 || subscriber.commands.includes(message.command)) {
            subscriber.onMessage(message);
          }
        }
      });
    },
    [subId],
  );

  const unsubscribe = useCallback((): void => {
    iotClient.offMessage(subId);
  }, [subId]);

  const refreshConnection = useCallback(async (): Promise<void> => {
    if (iotClient.connected || iotClient.connecting) {
      return;
    }

    iotClient.connecting = true;

    try {
      const { url } = await getEventSubscribeUrl();
      if (iotClient.hasIoT) {
        iotClient.disconnect();
      }
      iotClient.endpointURL = url;
      iotClient.connect(`${config.iot.prefix}-${userId}-${Date.now()}`);
    } catch (e) {
      displayErrorDetails(e);
    }

    iotClient.connecting = false;
  }, [userId]);

  useEffect(() => {
    if (userId) {
      iotClient.onConnect = () => {
        iotClient.subscribe(`${config.iot.prefix}/${userId}`);
      };

      iotClient.onError = (error: Error) => {
        console.error('IoT connection error', error);
        console.error('Reconnecting to IoT...', error);
        setTimeout(refreshConnection, config.iot.reconnectPeriod);
      };

      iotClient.onClose = () => {
        console.error('IoT closed');
        refreshConnection();
      };

      refreshConnection();
    } else {
      iotClient.reset();
      iotClient.disconnect();
    }
  }, [userId, refreshConnection]);

  return {
    subscribe,
    unsubscribe,
  };
}
