import { client } from '../client';
import { IGMRegisteredMedia } from './types/IGMRegisteredMedia';

export interface RegisterMediaPayload {
  files: Array<{
    filename: string;
    mimetype: string;
    attributes?: { [x: string]: string };
  }>;
}

export const registersConversationMedia = async (
  conversationSid: string,
  payload: RegisterMediaPayload,
): Promise<IGMRegisteredMedia> => {
  const { data } = await client.post<IGMRegisteredMedia>(`/conversations/${conversationSid}/media/register`, payload);

  return data;
};
