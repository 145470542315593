import moment from 'moment';

export const calculateDueDate = (dueDate: string): string | null => {
  const dueDateObjectNew = moment(dueDate).format('lll');

  const utcTime = moment().utc().format('lll');

  const dueDateObject = new Date(dueDateObjectNew);
  const currentTime = new Date(utcTime);
  const remainingTime = dueDateObject.getTime() - currentTime.getTime();
  if (remainingTime < 0) return null;

  const remainingSeconds = Math.ceil(remainingTime / 1000);
  if (remainingTime <= 10 * 60 * 1000) {
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }
  return null;
};

export const calculateDueDateAppointmentsPage = (dueDate: string): string | null => {
  const dueDateObjectNew = moment(dueDate).format('lll');

  const utcTime = moment().utc().format('lll');

  const dueDateObject = new Date(dueDateObjectNew);
  const currentTime = new Date(utcTime);
  const remainingTime = dueDateObject.getTime() - currentTime.getTime();
  if (remainingTime < 0) return 'True';

  const remainingSeconds = Math.ceil(remainingTime / 1000);
  if (remainingTime <= 10 * 60 * 1000) {
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }
  return null;
};
