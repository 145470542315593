import { client } from '../client';
import { IGMMessage } from './types/IGMMessage';

export interface EditMessagePayload {
  author_identity?: string;
  body?: string | null;
}

export const editConversationMessage = async (
  conversationSid: string,
  messageSid: string,
  payload: EditMessagePayload,
): Promise<IGMMessage> => {
  const { data } = await client.patch(`/conversations/${conversationSid}/messages/${messageSid}`, payload);

  return data;
};
