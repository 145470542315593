import { useEffect, useState } from 'react';
import { RemoteParticipant, Room } from 'twilio-video';

export default function useDominantSpeaker(room: Room | null, includeNull = false): RemoteParticipant | null {
  const [dominantSpeaker, setDominantSpeaker] = useState(room?.dominantSpeaker ?? null);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const handleDominantSpeakerChanged = (newDominantSpeaker: RemoteParticipant): void => {
        if (includeNull || newDominantSpeaker !== null) {
          setDominantSpeaker(newDominantSpeaker);
        }
      };
      const handleParticipantDisconnected = (participant: RemoteParticipant): void => {
        setDominantSpeaker((prevDominantSpeaker) => (prevDominantSpeaker === participant ? null : prevDominantSpeaker));
      };

      room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);
      room.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
        room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [room, includeNull]);

  return dominantSpeaker;
}
