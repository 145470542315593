import { client } from '../client';
import { ICase } from './types/ICase';

export interface IAssignCACase {
  care_advisor_id: number;
  enforce_assignment?: boolean;
}

export const assignCareAdvisor = async (case_id: number, payload: IAssignCACase): Promise<ICase> => {
  const { data } = await client.patch(`/cases/${case_id}/assign_ca`, payload);

  return data;
};
