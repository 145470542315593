import { ICase } from '../services/cases/types/ICase';

export const hasUnreadMessageInPatientCA = (currentCase: ICase, id: number): boolean => {
  if (
    currentCase.patient_care_advisor_conversation_stats &&
    currentCase.patient_care_advisor_conversation_stats.length > 0
  ) {
    return currentCase.patient_care_advisor_conversation_stats.some(
      (conversation) => conversation.message_created_by === +id,
    );
  }
  return false;
};

export const hasUnreadMessageInPatientProvider = (currentCase: ICase, id: number): boolean => {
  if (currentCase.patient_provider_conversation_stats && currentCase.patient_provider_conversation_stats.length > 0) {
    return currentCase.patient_provider_conversation_stats.some(
      (conversation) => conversation.message_created_by === +id,
    );
  }
  return false;
};

export const hasUnreadMessageInPrivate = (currentCase: ICase, id: number): boolean => {
  if (
    currentCase.provider_care_advisor_conversation_stats &&
    currentCase.provider_care_advisor_conversation_stats.length > 0
  ) {
    return currentCase.provider_care_advisor_conversation_stats.some(
      (conversation) => conversation.message_created_by === +id,
    );
  }
  return false;
};
