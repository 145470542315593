import Container from '@brands/Components/Container/Container';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import useWindowSize from '@brands/hooks/useWindowSize';
import React, { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

const ConsentPolicy: FC = () => {
  const screenSize = useWindowSize();
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <Container
        isBGEclipse={false}
        patientDashboard
        childClassName={styles.dashboard}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
          overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
        }}
      >
        <div className={styles.tOSBody}>
          <div id="content" className={styles.tOSBodySiteContent}>
            <main id="primary" className="site-main">
              <article id="post-929" className="post-929 page type-page status-publish hentry">
                <div className="entry-content">
                  <h2 className="wp-block-heading" data-block-type="core">
                    Consent to Care for a Teledentistry Visit
                  </h2>

                  <p>
                    To assist you in understanding the services provided by Dentistry.One, please take a moment to read
                    the
                    <a href="./terms-of-service">terms of service</a> before using offered services. The purpose of this
                    form is to obtain your consent for a teledentistry visit with a dental provider at Dentistry One LLC
                    and affiliated P.C.<strong> </strong>using Dentistry.One, a virtual first oral health platform.
                    &nbsp;
                  </p>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>What is teledentistry?</strong>
                  </h3>

                  <p>
                    Teledentistry is a way to visit with oral healthcare providers, such as dentists. You can talk to a
                    dentist from any place, including your home. You don’t go to a dental office.&nbsp;
                  </p>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>How do I use teledentistry?</strong>
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">
                      You talk to a dentist by smartphone, computer, or tablet. Using video so you and your provider can
                      see each other.&nbsp;
                    </li>

                    <li data-block-type="core">
                      You can also have questions answered through messaging without having a video visit.
                    </li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>How does teledentistry help me?</strong>
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">You don’t have to visit a dental office to see a dentist.</li>

                    <li data-block-type="core">
                      You won’t risk getting sick from other people.<strong>&nbsp;</strong>
                    </li>

                    <li data-block-type="core">You can talk to a dentist to see if you need in-person care.</li>

                    <li data-block-type="core">
                      You can work with a care coordinator to get help with in-person care.
                    </li>

                    <li data-block-type="core">You can learn how you can improve your oral health.</li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>Can teledentistry be bad for me?</strong>&nbsp;
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">
                      You and the dentist won’t be in the same room, so it may feel different than an office visit.
                    </li>

                    <li data-block-type="core">
                      The dentist won’t be able to take X-rays during a teledentistry visit, and they may be unable to
                      diagnose every issue. (We don’t know if this is more common with teledentistry visits.)
                    </li>

                    <li data-block-type="core">The dentist may decide you still need an office visit.</li>

                    <li data-block-type="core">
                      Technical problems may interrupt or stop your visit before you are done.
                    </li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>Will my teledentistry visit be private?</strong>
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">
                      We will not record visits with the dentist, though, during the visit, your provider may conduct a
                      remote oral examination during which video, audio, and/or photo recordings may be taken and become
                      part of your health record.&nbsp;
                    </li>

                    <li data-block-type="core">
                      If people are close to you, they may hear something you did not want them to know. You should be
                      in a private place so other people cannot hear you.&nbsp;&nbsp;
                    </li>

                    <li data-block-type="core">&nbsp;The dentist will tell you if someone can hear or see you.</li>

                    <li data-block-type="core">
                      Dentistry.One’s telehealth technology is designed to protect your privacy.
                    </li>

                    <li data-block-type="core">
                      &nbsp;Using the Internet for teledentistry, use a private and secure network.&nbsp;&nbsp;
                    </li>

                    <li data-block-type="core">
                      There is a very small chance that someone could use technology to hear or see your teledentistry
                      visit.&nbsp;
                    </li>

                    <li data-block-type="core">
                      To assist in coordinating care or referral from a healthcare provider, Dentistry.One may disclose
                      PHI for such purposes.
                    </li>

                    <li data-block-type="core">
                      In connection with your teledentistry visit, details of your medical history and personal health
                      information may be discussed and shared with other health professionals and staff.
                    </li>

                    <li data-block-type="core">
                      Our Privacy Policy which describes how your private information will be handled, can be found at
                      <a href="https://dentistry.one/privacy-policy/">https://dentistry.one/privacy-policy/</a>.
                    </li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>What if I want an office visit, not a telehealth visit?</strong>
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">
                      Dentistry.One is a virtual first oral health platform. We do not have brick-and-mortar dental
                      practices though do have care coordination services that can assist you in locating a provider in
                      your area.
                    </li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>What if I try teledentistry and don’t like it?</strong>
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">
                      You can stop using teledentistry at any time, even during a teledentistry visit.
                    </li>

                    <li data-block-type="core">
                      You can still get an office visit if you no longer want a teledentistry visit.&nbsp; Our Care
                      Coordination team would be happy to assist you.
                    </li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>How much does a telehealth visit cost?&nbsp;</strong>
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">If you have insurance, what you pay depends on your insurance.</li>

                    <li data-block-type="core">A teledentistry visit will not cost any more than an office visit.</li>

                    <li data-block-type="core">
                      If the dentist decides you need an office visit in addition to your telehealth visit, you may have
                      to pay for both.
                    </li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>What digital communication is used by Dentistry.One&nbsp;to contact me?</strong>
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">
                      As a teledentistry platform Dentistry.One will provide oral health services via mobile phone,
                      messages, e-mail, and online communication.
                    </li>

                    <li data-block-type="core">
                      These communications comply with privacy regulations as outlined under
                      <a href="https://dentistry.one/privacy-policy/">Privacy Practices</a>.&nbsp;
                    </li>

                    <li data-block-type="core">
                      In addition, we may receive your contact information through your employer, broker, etc., that
                      contract with Dentistry. One is to provide a teledentistry solution.
                    </li>

                    <li data-block-type="core">
                      By consenting, you agree that Dentistry.One can communicate this way and understand that
                      safeguards are in place; the content could be intercepted by others or not be encrypted. If you
                      decline, the use of Dentistry.One will not be available to you.
                    </li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>What are my rights as a patient?</strong>
                  </h3>

                  <ul data-block-type="core">
                    <li data-block-type="core">
                      You have the right to ask questions of your provider about the risks, benefits, and alternatives
                      to teledentistry visits and to know the provider’s licensure and qualifications.&nbsp;
                    </li>

                    <li data-block-type="core">
                      You have the right to receive interactive, real-time communications with the dental provider(s)
                      providing your teledentistry visit, both at the time and within thirty (30) days after your
                      teledentistry visit.
                    </li>

                    <li data-block-type="core">
                      You may withhold or withdraw your consent to a teledentistry visit at any time before and/or
                      during the visit.
                    </li>

                    <li data-block-type="core">
                      For a complete list of rights and more information regarding the privacy of your data and health
                      information, please consult our <a href="https://dentistry.one/privacy-policy/">Privacy Policy</a>
                      .
                    </li>
                  </ul>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>Do I have to sign this document?</strong>
                  </h3>

                  <p>No. Only sign this document if you want to use teledentistry.</p>

                  <p>
                    &nbsp;<strong>Do not sign this form until you start your first telehealth visit.</strong> The
                    dentist will discuss it with you.
                  </p>

                  <h3 className="wp-block-heading" data-block-type="core">
                    <strong>What does it mean if I sign this document?</strong>
                  </h3>

                  <p>If you sign this document, you agree that:</p>

                  <ul data-block-type="core">
                    <li data-block-type="core">
                      A Dentistry.One provider or employee talked about the information in this document.
                    </li>

                    <li data-block-type="core">A Dentistry.One provider or employee answered all your questions.</li>

                    <li data-block-type="core">You want a teledentistry visit.</li>
                  </ul>

                  <p>
                    I have read and understand the Consent to Care for a Teledentistry Visit and, with this, give my
                    consent to participate in a Teledentistry visit. If I consent on behalf of a minor patient, I now
                    certify that I am the legal parent or guardian of the patient and am authorized to provide such
                    consent.
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ConsentPolicy;
