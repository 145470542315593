import Container from '@brands/Components/Container/Container';
import styles from '@brands/Dashboard/Dashboard/Provider/styles.module.scss';
import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import cn from 'classnames';
import React from 'react';

import { useAppSelector } from '../../hooks/useReduxHook';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import Schedule from './Provider/Sections/Schedule/Schedule';

const Dashboard: React.FC = () => {
  const user: AuthState = useAppSelector(selectAuth);

  switch (user.userInfo?.role.name) {
    case UserRoleName.CareAdvisor:
      <Schedule />;
      break;
    default:
      // call 404 page
      <NotFoundPage />;
      break;
  }

  return (
    <Container
      isBGEclipse={false}
      containerStyle={{
        justifyContent: 'start',
      }}
      childClassName={styles.providerDashboard}
      childrenStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        paddingTop: '2rem',
        height: 'calc(100vh - 74px)',
      }}
      isProvider
    >
      <div
        className={cn(styles.containerPf, styles.providerDashboardBody)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Schedule />
      </div>
    </Container>
  );
};

export default Dashboard;
