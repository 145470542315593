/* eslint-disable no-nested-ternary */
import useWindowSize from '@brands/hooks/useWindowSize';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import { setIsVisible, setVCCaseId } from '@brands/store/slices/videoCallCredentialsSlice';
import { formatDate } from '@brands/Utils/formatDate';
import { getTimezoneAbbrWithTimezone } from '@brands/Utils/getParsedTimezoneName';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';
import { AiFillFilePdf } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import { MdOutlineRemoveCircle } from 'react-icons/md';
import { TbMessage2 } from 'react-icons/tb';
import { TiMessages } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../Components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import { CaseType, ICase, Status } from '../../../../services/cases/types/ICase';
import { getConversation } from '../../../../services/communication/getConversation';
import { UnassignProviderModal } from '../../../../services/forms/types/ImageNote';
import { getUserAvatar } from '../../../../services/identity/getUserAvatar';
import { UserRoleName } from '../../../../services/identity/types/UserProfile';
import { calculateCaseDue, formatDateWithAddedDay } from '../../../../Utils/calculateCaseDue';
import { calculateDueDate } from '../../../../Utils/calculateDueDate';
import {
  checkAllowCAToCancelCase,
  checkAllowCAToCancelCaseOnly,
  checkCollapsibleHeaderColor,
  checkEditValid,
  checkEditValidForCA,
} from '../../../../Utils/getCollapsibleHeader';
import { getColorForStatus } from '../../../../Utils/getColorForStatus';
import { getFriendlyName } from '../../../../Utils/getFriendlyName';
import { getGenderName } from '../../../../Utils/getGenderName';
import { getTextForStatus } from '../../../../Utils/getTextForStatus';
import {
  hasUnreadMessageInPatientCA,
  hasUnreadMessageInPatientProvider,
  hasUnreadMessageInPrivate,
} from '../../../../Utils/getUnreadMessageNotification';
import phone from '../../../Dashboard/CareAdvisor/assets/phone.svg';
import video from '../../assets/icons/liveVideo.svg';
import report from '../../assets/icons/oralHealthReport.svg';
import sVideo from '../../assets/icons/scheduledVideo.svg';
import styles from '../../styles.module.scss';

type ConsultationHeaderProps = {
  thisCase: ICase;
  setUpdateCaseStatusModal: React.Dispatch<
    React.SetStateAction<{
      isopen: boolean;
      type: string;
    }>
  >;
  setSelectCTModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectCTimeModal: React.Dispatch<React.SetStateAction<boolean>>;
  assignCareAdvisor: (selectedCareAdvisor: number) => Promise<void>;
  setProviderProfileCardModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectProvidersModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUnassignProviderModal: React.Dispatch<React.SetStateAction<UnassignProviderModal>>;
  setCAProfileCardModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectCCModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConsulationHeader = ({
  thisCase,
  setUpdateCaseStatusModal,
  setSelectCTModal,
  setSelectCTimeModal,
  assignCareAdvisor,
  setProviderProfileCardModal,
  setSelectProvidersModal,
  setUnassignProviderModal,
  setCAProfileCardModal,
  setSelectCCModal,
}: ConsultationHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const { userInfo } = useAppSelector(selectAuth);
  const { is_visible: isVisible } = useAppSelector(selectVideoCallCredentials);
  const navigate = useNavigate();
  const [conversationState, setConversationState] = useState(true);
  const [openAdminOptionList, setAdminOptionList] = useState(false);

  useEffect(() => {
    if (thisCase) {
      const patientCAConvPromise = getConversation(thisCase.patient_care_advisor_conversation_sid, false);
      const patientProviderConvPromise = getConversation(thisCase.patient_provider_conversation_sid, false);
      const privateConvPromise = thisCase.provider_care_advisor_conversation_sid
        ? getConversation(thisCase.provider_care_advisor_conversation_sid, false)
        : Promise.resolve({ state: 'active' });

      Promise.all([patientCAConvPromise, patientProviderConvPromise, privateConvPromise]).then(
        ([patientCAConvList, patientProviderConvList, privateConvList]) => {
          if (privateConvList.state !== 'closed') {
            if (patientCAConvList.state !== 'closed') {
              setConversationState(true);
            } else if (patientProviderConvList.state !== 'closed') {
              setConversationState(true);
            } else {
              setConversationState(false);
            }
          } else if (patientCAConvList.state !== 'closed' || patientProviderConvList.state !== 'closed') {
            setConversationState(true);
          } else {
            setConversationState(false);
          }
        },
      );
    }
  }, [thisCase]);

  const displayPhoneNumber = useMemo(() => {
    if (thisCase.type === CaseType.phone_call_scheduled)
      if (thisCase?.patient.guardian_id === null && thisCase?.patient?.phone_number) {
        const phoneNumber = parsePhoneNumber(thisCase?.patient?.phone_number, 'US');
        if (phoneNumber) {
          return phoneNumber.formatNational();
        }
      } else if (thisCase?.patient.guardian_id !== null && thisCase.patient.guardian?.phone_number) {
        const phoneNumber = parsePhoneNumber(thisCase?.patient.guardian?.phone_number, 'US');
        if (phoneNumber) {
          return phoneNumber.formatNational();
        }
      }

    return '';
  }, [thisCase?.patient]);

  const assignCareAdvisorHandler = async (selectedCareAdvisor: number): Promise<void> => {
    await assignCareAdvisor(selectedCareAdvisor);
  };

  return (
    <div className={styles.flexWrapper}>
      <div
        style={{
          justifyContent: thisCase?.status === 'completed' || thisCase?.status === 'canceled' ? 'space-between' : 'end',
        }}
        className={`${styles.consultationStatusHeader} ${
          thisCase?.status === 'completed' || thisCase?.status === 'canceled'
            ? styles.normalBackground
            : (thisCase.type === 'video_call_scheduled' || thisCase.type === CaseType.phone_call_scheduled) &&
              thisCase.video_conference_date &&
              calculateDueDate(thisCase.video_conference_date || '') &&
              calculateDueDate(thisCase.video_conference_date || '') !== 'Expired!'
            ? styles.secondBackground
            : styles.primaryColorBackground
        }`}
      >
        {thisCase?.status === 'completed' && (
          <span className={styles.completedSpan}>
            <Button type="button" onClick={() => navigate(`/oral-health-report/${thisCase?.id}`)}>
              <AiFillFilePdf /> View as PDF
            </Button>
          </span>
        )}
        {thisCase?.type === CaseType.video_call_scheduled &&
          (thisCase.provider?.id === userInfo.id || thisCase.care_advisor?.id === userInfo.id) &&
          checkCollapsibleHeaderColor(thisCase || '', userInfo, 'JoinWaitingRoom') !== 'readOnly' && (
            <Button
              type="button"
              onClick={() => {
                dispatch(setIsVisible(true));
                dispatch(setVCCaseId(thisCase?.id.toString()));
              }}
              style={{ marginRight: '10px' }}
              disabled={isVisible}
            >
              Join Waiting Room
            </Button>
          )}
        {thisCase.type === CaseType.phone_call_scheduled ? (
          isMobile || mobileDetect ? (
            <a href={`tel:${displayPhoneNumber}`} className={styles.linkPhoneNumber}>
              Call the patient on [{displayPhoneNumber}]
            </a>
          ) : (
            <span className={styles.callPatientSpan}>Call the patient on [{displayPhoneNumber}]</span>
          )
        ) : null}

        <Button
          className={`${
            thisCase?.status === 'completed' || thisCase?.status === 'canceled' ? styles.secondartColor : ''
          }`}
          type="button"
          onClick={() => navigate(`/case-chat/${thisCase?.id}`)}
        >
          <TbMessage2 />
          {userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin ||
          (userInfo.id !== thisCase.provider_id &&
            userInfo.id !== thisCase.care_advisor_id &&
            userInfo.id !== thisCase.patient_id) ||
          !conversationState
            ? 'Read Messages'
            : 'Message Participants'}
        </Button>
      </div>
      <div className={styles.consultationStatusBody}>
        <div className={`${styles.detailsWrapper} ${styles.headerDetailsWrapper}`}>
          <div className={styles.isMobileStatus}>
            <div className={styles.caseStatusDiv}>
              <span>Status</span>
              <span className={styles.caseStatus}>
                <span className={styles.statusSpan} style={{ backgroundColor: getColorForStatus(thisCase?.status) }}>
                  {getTextForStatus(userInfo.role.name, thisCase?.type as string, thisCase?.status)}
                </span>
                <span
                  style={{
                    cursor:
                      thisCase?.status === 'completed' ||
                      thisCase?.status === 'canceled' ||
                      !checkAllowCAToCancelCase(thisCase, userInfo)
                        ? 'default'
                        : 'pointer',
                  }}
                  className={`${styles.editCase} ${
                    thisCase?.status === 'completed' ||
                    thisCase?.status === 'canceled' ||
                    !checkAllowCAToCancelCase(thisCase, userInfo)
                      ? styles.secondaryColor
                      : styles.primaryColor
                  }`}
                  onClick={() =>
                    checkAllowCAToCancelCase(thisCase, userInfo) &&
                    (userInfo.role.name === UserRoleName.CareAdvisor ||
                      userInfo.role.name === UserRoleName.OrganizationAdmin)
                      ? setUpdateCaseStatusModal({
                          isopen: true,
                          type: 'cancel',
                        })
                      : checkAllowCAToCancelCase(thisCase, userInfo) &&
                        (userInfo.role.name === UserRoleName.Admin || userInfo.role.name === UserRoleName.SuperAdmin)
                      ? setAdminOptionList(!openAdminOptionList)
                      : undefined
                  }
                >
                  {thisCase?.status !== 'completed' &&
                    thisCase?.status !== 'canceled' &&
                    (userInfo.role.name === UserRoleName.CareAdvisor ||
                      userInfo.role.name === UserRoleName.Admin ||
                      userInfo.role.name === UserRoleName.SuperAdmin ||
                      userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                    checkAllowCAToCancelCase(thisCase, userInfo) && <FaRegEdit />}
                </span>
              </span>
              {openAdminOptionList && (
                <ul className={styles.dropdownList}>
                  {checkAllowCAToCancelCaseOnly(thisCase, UserRoleName.CareAdvisor) && (
                    <li key="0">
                      <button
                        type="button"
                        onClick={() => {
                          setAdminOptionList(false);
                          setUpdateCaseStatusModal({
                            isopen: true,
                            type: 'cancel',
                          });
                        }}
                      >
                        Cancel this Case
                      </button>
                    </li>
                  )}
                  <li key="1">
                    <button
                      type="button"
                      onClick={() => {
                        setAdminOptionList(false);
                        setUpdateCaseStatusModal({
                          isopen: true,
                          type: 'complete',
                        });
                      }}
                    >
                      Complete this Case
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <div>
              <span>Consultation Type</span>
              <span
                style={{
                  cursor:
                    thisCase?.status === 'completed' ||
                    thisCase?.status === 'canceled' ||
                    !checkEditValid(thisCase, userInfo)
                      ? 'default'
                      : 'pointer',
                }}
                className={`${styles.editCase} ${
                  thisCase?.status === 'completed' ||
                  thisCase?.status === 'canceled' ||
                  !checkEditValid(thisCase, userInfo)
                    ? styles.secondaryColor
                    : styles.primaryColor
                }`}
                onClick={() => checkEditValid(thisCase, userInfo) && setSelectCTModal(true)}
              >
                {thisCase?.type === 'messaging' && <TiMessages />}
                {thisCase?.type === 'video_call_instant' && <img className={styles.video} src={video} alt="video" />}
                {thisCase.type === 'video_call_scheduled' && <img className={styles.sVideo} src={sVideo} alt="video" />}
                {thisCase.type === CaseType.phone_call_scheduled && (
                  <img className={styles.phone} src={phone} alt="Phone" />
                )}
                {thisCase?.type === 'one_report' && <img className={styles.cReport} src={report} alt="video" />}
                {getFriendlyName(thisCase.type)}
                {thisCase?.status !== 'completed' &&
                  thisCase?.status !== 'canceled' &&
                  (userInfo.role.name === UserRoleName.CareAdvisor ||
                    userInfo.role.name === UserRoleName.Admin ||
                    userInfo.role.name === UserRoleName.SuperAdmin ||
                    userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                  checkEditValid(thisCase, userInfo) && <FaRegEdit />}
              </span>
            </div>
          </div>
          <div>
            <div style={{ borderRight: 'none' }}>
              <span>
                {thisCase.type !== 'video_call_scheduled' && thisCase.type !== CaseType.phone_call_scheduled
                  ? thisCase.status === Status.Completed
                    ? 'Completed Date'
                    : thisCase.status === Status.Canceled
                    ? 'Canceled Date'
                    : 'Case Due'
                  : 'Scheduled'}
              </span>
              <span
                style={{
                  cursor:
                    thisCase?.status === 'completed' ||
                    thisCase?.status === 'canceled' ||
                    !checkEditValid(thisCase, userInfo) ||
                    (thisCase.type !== 'video_call_scheduled' && thisCase.type !== CaseType.phone_call_scheduled)
                      ? 'default'
                      : 'pointer',
                }}
                className={`${styles.editCase} ${
                  thisCase?.status === 'completed' ||
                  thisCase?.status === 'canceled' ||
                  !checkEditValid(thisCase, userInfo) ||
                  (thisCase.type !== 'video_call_scheduled' && thisCase.type !== CaseType.phone_call_scheduled)
                    ? styles.secondaryColor
                    : styles.primaryColor
                }`}
                onClick={() =>
                  checkEditValid(thisCase, userInfo) &&
                  (thisCase.type === 'video_call_scheduled' || thisCase.type === CaseType.phone_call_scheduled) &&
                  setSelectCTimeModal(true)
                }
              >
                {thisCase.status === Status.Completed &&
                thisCase.type !== 'video_call_scheduled' &&
                thisCase.type !== CaseType.phone_call_scheduled
                  ? formatDate(thisCase?.completed_at || '', true)
                  : thisCase.status === Status.Canceled &&
                    thisCase.type !== 'video_call_scheduled' &&
                    thisCase.type !== CaseType.phone_call_scheduled
                  ? formatDate(thisCase?.updated_at || '', true)
                  : calculateCaseDue(
                      thisCase?.type || '',
                      thisCase?.started_at || '',
                      thisCase?.video_conference_date || '',
                      true,
                    )}
                {thisCase?.status !== 'completed' &&
                  thisCase?.status !== 'canceled' &&
                  (userInfo.role.name === UserRoleName.CareAdvisor ||
                    userInfo.role.name === UserRoleName.Admin ||
                    userInfo.role.name === UserRoleName.SuperAdmin ||
                    userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                  (thisCase.type === 'video_call_scheduled' || thisCase.type === CaseType.phone_call_scheduled) &&
                  checkEditValid(thisCase, userInfo) && <FaRegEdit />}
              </span>
            </div>
            {(thisCase.type === 'video_call_scheduled' || thisCase.type === CaseType.phone_call_scheduled) && (
              <div className={styles.videoCaseDue}>
                <span>
                  {thisCase.status === Status.Completed
                    ? 'Completed Date'
                    : thisCase.status === Status.Canceled
                    ? 'Canceled Date'
                    : 'Case Due'}
                </span>
                <span className={styles.editCase} style={{ cursor: 'default' }}>
                  {thisCase.status === Status.Completed
                    ? formatDate(thisCase?.completed_at || '', true)
                    : thisCase.status === Status.Canceled
                    ? formatDate(thisCase?.updated_at || '', true)
                    : formatDateWithAddedDay(thisCase?.video_conference_date || '', true)}
                </span>
              </div>
            )}
          </div>
          {(thisCase?.status === Status.New ||
            thisCase?.status === Status.CompletedByProvider ||
            ((thisCase.status === Status.AcceptedProvider || thisCase.status === Status.InProgressProvider) &&
              thisCase.care_advisor === null)) &&
            thisCase.care_advisor === null &&
            userInfo.role.name === UserRoleName.CareAdvisor && (
              <div>
                <span>Care Advisor</span>
                <Button
                  className={styles.acceptBtn}
                  type="button"
                  onClick={() => assignCareAdvisorHandler(userInfo.id)}
                >
                  Accept Case
                </Button>
              </div>
            )}
          {thisCase?.provider && (
            <div className={`${styles.padding10} ${styles.caInfo}`}>
              <span>
                <div className={styles.careAdvisor}>
                  <span className={styles.imageContainer} onClick={() => setProviderProfileCardModal(true)}>
                    {(userInfo.role.name === UserRoleName.CareAdvisor ||
                      userInfo.role.name === UserRoleName.Admin ||
                      userInfo.role.name === UserRoleName.SuperAdmin ||
                      userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                      (hasUnreadMessageInPrivate(thisCase, thisCase.provider_id) ||
                        hasUnreadMessageInPatientProvider(thisCase, thisCase.provider_id)) && (
                        <span className={styles.unreasMessage} />
                      )}
                    <img
                      alt="Avatar"
                      onError={(event: any) => {
                        const img = event.target;
                        img.src =
                          'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                        img.onError = null;
                      }}
                      className={styles.caImage}
                      src={getUserAvatar(Number(thisCase?.provider.id))}
                    />
                  </span>
                  <div className={styles.patientDiv}>
                    <span className={styles.patientName} style={{ cursor: 'default' }}>
                      Provider
                    </span>
                    <span
                      style={{
                        cursor:
                          thisCase?.status === 'completed' ||
                          thisCase?.status === 'canceled' ||
                          !checkEditValid(thisCase, userInfo)
                            ? 'default'
                            : 'pointer',
                      }}
                      className={`${styles.caName} ${
                        thisCase?.status === 'completed' ||
                        thisCase?.status === 'canceled' ||
                        !checkEditValid(thisCase, userInfo)
                          ? styles.secondaryColor
                          : styles.primaryColor
                      }`}
                      onClick={() =>
                        checkEditValid(thisCase, userInfo) &&
                        (userInfo.role.name === UserRoleName.CareAdvisor ||
                          userInfo.role.name === UserRoleName.Admin ||
                          userInfo.role.name === UserRoleName.SuperAdmin ||
                          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                        setSelectProvidersModal(true)
                      }
                    >
                      {thisCase?.provider.full_name}
                      {thisCase?.provider.provider_profile?.credentials ? ', ' : ''}
                      {thisCase?.provider.provider_profile?.credentials || ''}{' '}
                      {thisCase?.status !== 'completed' &&
                        thisCase?.status !== 'canceled' &&
                        (userInfo.role.name === UserRoleName.CareAdvisor ||
                          userInfo.role.name === UserRoleName.Admin ||
                          userInfo.role.name === UserRoleName.SuperAdmin ||
                          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                        checkEditValid(thisCase, userInfo) && <FaRegEdit />}
                    </span>
                    <span
                      style={{
                        cursor:
                          thisCase?.status === 'completed' ||
                          thisCase?.status === 'canceled' ||
                          !checkEditValid(thisCase, userInfo)
                            ? 'default'
                            : 'pointer',
                      }}
                      className={`${styles.removeProvider} ${
                        thisCase?.status === 'completed' ||
                        thisCase?.status === 'canceled' ||
                        !checkEditValid(thisCase, userInfo)
                          ? styles.secondaryColor
                          : styles.primaryColor
                      }`}
                      onClick={() =>
                        checkEditValid(thisCase, userInfo) &&
                        (userInfo.role.name === UserRoleName.CareAdvisor ||
                          userInfo.role.name === UserRoleName.Admin ||
                          userInfo.role.name === UserRoleName.SuperAdmin ||
                          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                        setUnassignProviderModal({
                          isOpen: true,
                          caseId: thisCase.id,
                          providerName: thisCase.provider.full_name,
                        })
                      }
                    >
                      {thisCase?.status !== 'completed' &&
                        thisCase?.status !== 'canceled' &&
                        (userInfo.role.name === UserRoleName.CareAdvisor ||
                          userInfo.role.name === UserRoleName.Admin ||
                          userInfo.role.name === UserRoleName.SuperAdmin ||
                          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
                        checkEditValid(thisCase, userInfo) && (
                          <>
                            Remove <MdOutlineRemoveCircle />
                          </>
                        )}
                    </span>
                  </div>
                </div>
              </span>
              <span>
                <div className={styles.patient}>
                  <div className={styles.detailsWrapper}>
                    <div>
                      <span>Gender</span>
                      <p>
                        {getGenderName(thisCase?.provider?.basic_info?.gender)} -{' '}
                        {thisCase?.provider?.basic_info?.pronouns}
                      </p>
                    </div>
                    <div>
                      <span>Location</span>
                      <p>
                        {thisCase.provider?.address?.state} - {thisCase.provider?.address?.zip_code} - (
                        {getTimezoneAbbrWithTimezone(thisCase?.provider?.address?.timezone as string)})
                      </p>
                    </div>
                    <div>
                      <span>Language(s)</span>
                      <p>
                        {thisCase?.provider?.basic_info?.languages
                          ?.map((language) => typeof language !== 'string' && language.name)
                          .join(', ')}
                      </p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          )}
          {thisCase?.care_advisor && (
            <div className={styles.caInfo}>
              <span>
                <div className={styles.careAdvisor}>
                  <span className={styles.imageContainer} onClick={() => setCAProfileCardModal(true)}>
                    {userInfo.role.name === UserRoleName.Provider &&
                      (hasUnreadMessageInPrivate(thisCase, thisCase.care_advisor_id) ||
                        hasUnreadMessageInPatientCA(thisCase, thisCase.care_advisor_id)) && (
                        <span className={styles.unreasMessage} />
                      )}
                    <img
                      alt="Avatar"
                      onError={(event: any) => {
                        const img = event.target;
                        img.src =
                          'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                        img.onError = null;
                      }}
                      className={styles.caImage}
                      src={getUserAvatar(Number(thisCase?.care_advisor.id))}
                    />
                  </span>
                  <div className={styles.patientDiv}>
                    <span className={styles.patientName} style={{ cursor: 'default' }}>
                      Care Advisor
                    </span>
                    <span
                      style={{
                        cursor:
                          userInfo.role.name === UserRoleName.CareAdvisor ||
                          userInfo.role.name === UserRoleName.Admin ||
                          userInfo.role.name === UserRoleName.SuperAdmin ||
                          userInfo.role.name === UserRoleName.OrganizationAdmin
                            ? 'pointer'
                            : 'default',
                      }}
                      className={`${styles.caName} ${
                        userInfo.role.name === UserRoleName.CareAdvisor ||
                        userInfo.role.name === UserRoleName.Admin ||
                        userInfo.role.name === UserRoleName.SuperAdmin ||
                        userInfo.role.name === UserRoleName.OrganizationAdmin
                          ? styles.primaryColor
                          : styles.secondartColor
                      }`}
                      onClick={() => checkEditValidForCA(thisCase, userInfo) && setSelectCCModal(true)}
                    >
                      {thisCase?.care_advisor.full_name} {checkEditValidForCA(thisCase, userInfo) && <FaRegEdit />}
                    </span>
                  </div>
                </div>
              </span>
              <span>
                <div className={styles.patient}>
                  <div className={styles.detailsWrapper}>
                    <div>
                      <span>Gender</span>
                      <p>
                        {getGenderName(thisCase?.care_advisor?.basic_info?.gender)} -{' '}
                        {thisCase?.care_advisor?.basic_info?.pronouns}
                      </p>
                    </div>
                    <div>
                      <span>Language(s)</span>
                      <p>
                        {' '}
                        {thisCase?.care_advisor?.basic_info?.languages
                          ?.map((language) => typeof language !== 'string' && language.name)
                          .join(', ')}
                      </p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ConsulationHeader;
