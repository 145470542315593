import { selectAuth } from '@brands/store/selectors/auth';
import cn from 'classnames';
import moment from 'moment';
import { SnackbarContent, SnackbarKey, useSnackbar } from 'notistack';
import * as React from 'react';
import { HiOutlineChat, HiX } from 'react-icons/hi';

import { useAppSelector } from '../../../hooks/useReduxHook';
import { INotification } from '../../../services/notification/types/INotification';
import { formatDate } from '../../../Utils/formatDate';
import { replaceTemplate } from '../../../Utils/replaceTemplate';
import { isNotificationClickable } from '../utils/notification';
import styles from './styles.module.scss';

interface Props {
  id: SnackbarKey;
  notification: INotification;
  onNotification: (notification: INotification) => void;
}

const NotificationPopUp = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { userInfo } = useAppSelector(selectAuth);

  const { id, notification, onNotification, ...other } = props;

  const templatePayload: Record<string, string | number> = React.useMemo(() => {
    const payload: Record<string, string | number> = {};
    if (notification.payload?.date_time) {
      payload.date_time = moment.utc(notification.payload?.date_time).isValid()
        ? formatDate(notification.payload?.date_time, true)
        : notification.payload?.date_time;
    }

    return payload;
  }, [notification]);

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <div className={styles.popupContainer}>
        <div className={styles.contentWrapper}>
          <HiOutlineChat />
          <div
            className={cn(styles.content, {
              [styles.clickable]: isNotificationClickable(notification, userInfo),
            })}
            onClick={() => onNotification(notification)}
          >
            <span>{replaceTemplate(notification.body || '', templatePayload)}</span>
          </div>
          <HiX className={styles.clickable} onClick={() => closeSnackbar(id)} />
        </div>
      </div>
    </SnackbarContent>
  );
});

export default NotificationPopUp;
