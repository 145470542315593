import React, { memo } from 'react';
import Modal from 'react-modal';

import modalClose from '../../assets/icons/close.svg';
import { useIdentityContext, useVideoContext } from '../../hooks';
import { backgroundConfig } from '../../hooks/video-hooks/useBackgroundSettings';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import { customStylesVirtualBackground } from '../../Utils/customStyles';
import BackgroundThumbnail from '../BackgroundThumbnail/BackgroundThumbnail';
import styles from './styles.module.scss';

const SelectBackgroundModal: React.FC = () => {
  const { isBackgroundSelectionOpen, setIsBackgroundSelectionOpen } = useVideoContext();
  const { imageNames } = backgroundConfig;
  const { images } = backgroundConfig;
  const { userInfo } = useIdentityContext();

  return (
    <Modal isOpen={isBackgroundSelectionOpen} shouldCloseOnOverlayClick style={customStylesVirtualBackground}>
      <button
        type="button"
        className={styles.modalClose}
        style={{ backgroundImage: `url(${modalClose})` }}
        aria-label="Close modal"
        onClick={() => setIsBackgroundSelectionOpen(false)}
      />
      <div
        className={`d-flex justify-content-center gap-5 mb-5 ${
          userInfo?.role.name === UserRoleName.Patient ? 'px-5 pt-5 mx-5' : ''
        }`}
      >
        <BackgroundThumbnail thumbnail="none" name="None" />
        <BackgroundThumbnail thumbnail="blur" name="Blur" />
      </div>
      {userInfo?.role.name !== UserRoleName.Patient && (
        <div className="d-flex flex-wrap justify-content-center align-items-center gap-2">
          {images.map((image, index) => (
            <BackgroundThumbnail
              thumbnail="image"
              name={imageNames[index]}
              index={index}
              imagePath={image}
              key={image}
            />
          ))}
        </div>
      )}
    </Modal>
  );
};

Modal.setAppElement('#root');

export default memo(SelectBackgroundModal);
