import { purifyPayloadForBasicInformation } from '../../Utils/purifyPayload';
import { client } from '../client';
import { BasicInfo, UserProfile } from './types/UserProfile';

export const createBasicInformation = async (payload: BasicInfo, user_id: number): Promise<UserProfile> => {
  const purifiedPayload = purifyPayloadForBasicInformation(payload);

  const { data } = await client.post<UserProfile>(`/users/${user_id}/basic_info`, purifiedPayload);

  return data;
};
