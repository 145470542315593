import { client } from '../client';
import { ProviderAvailability } from './types/ProviderAvailability';

export interface Payload {
  type: string;
  date?: string;
  weekday?: number;
  start_time: string;
  end_time: string;
  synchronous: boolean;
  asynchronous: boolean;
  other_slots_at_same_request?: ProviderAvailability[];
}

export const addUserAvailability = async (user_id: number, payload: Payload): Promise<ProviderAvailability> => {
  const { data } = await client.post<ProviderAvailability>(`/users/${user_id}/user_availability`, payload);

  return data;
};
