/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const config = {
  organization_id: process.env.REACT_APP_ORGANIZATION_ID || 'dentistry-one',
  division_id: process.env.REACT_APP_Division_ID,
  practice_id: process.env.REACT_APP_Practice_ID,
  domain_name: process.env.REACT_APP_COOKIE_DOMAIN_NAME || '.dentistry.one',
  api: {
    baseUrl: process.env.REACT_APP_DENTISTRY_ONE_API_URL || 'https://develop-api.dentistry.one',
    googleAPI: process.env.REACT_APP_GOOGLE_API || 'AIzaSyCuTJG59A-p6p3MUr_d9swbytRJ0tnamiM',
  },
  cognito: {
    region: process.env.REACT_APP_COGNITO_REGION!,
    user_pools_id: process.env.REACT_APP_COGNITO_USER_POOLS_ID!,
    app_client_id: process.env.REACT_APP_COGNITO_APP_CLIENT_ID!,
    endpoint: process.env.REACT_APP_COGNITO_ENDPOINT,
  },
  iot: {
    prefix: process.env.REACT_APP_IOT_PREFIX || 'develop',
    reconnectPeriod: parseInt(process.env.REACT_APP_IOT_RECONNECT_PERIOD || '10000', 10),
  },
  square: {
    src: process.env.REACT_APP_SQUARE_SRC || 'https://sandbox.web.squarecdn.com/v1/square.js',
    applicationId: process.env.REACT_APP_SQUARE_APPLICATION_ID!,
    locationId: process.env.REACT_APP_SQUARE_LOCATION_ID!,
  },
  ga: {
    trackingId: process.env.REACT_APP_GA_TRACKING_ID || null,
  },
  organization: {
    name: process.env.REACT_APP_APP_BRAND || 'default',
  },
};
