/* eslint-disable @typescript-eslint/naming-convention */
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { License } from '../../../../../services/identity/types/UserProfile';
import {
  checkExpiredDate,
  checkValidDate,
  isValidAddress,
  isValidDate,
  isValidEmail,
  isValidName,
  isValidZipCode,
} from '../../../../../Utils/validation';
import { atLeastOneFieldNotEmpty } from '../Patient/validationSchema';

export const validationSchema = (): any => {
  const checkAllFieldsFilled = (licenses: License): boolean => {
    if (licenses) {
      const { state, number, expiry_date } = licenses;
      if (state && number && expiry_date) {
        return true;
      }
    }
    return false;
  };

  return Yup.object({
    first_name: Yup.string()
      .required('First name is required')
      .test('test-name', 'Enter Valid First Name', (value: any) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    middle_name: Yup.string().test('test-name', 'Enter Valid Last Name', (value: any) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    last_name: Yup.string()
      .required('Last name is required')
      .test('test-name', 'Enter Valid Last Name', (value: any) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    language: Yup.array(),
    address1: Yup.string().test(
      'test-name',
      'Valid Address is required when at least one Address field is provided',
      function () {
        const { parent } = this;
        const { address1, address2, city, state, zip_code } = parent as any;
        if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
          return !!address1 && isValidAddress(address1);
        }
        return true;
      },
    ),
    address2: Yup.string(),
    city: Yup.string().test(
      'test-required',
      'City is required when at least one Address field is provided',
      function () {
        const { parent } = this;
        const { address1, address2, city, state, zip_code } = parent as any;
        if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
          return !!city;
        }
        return true;
      },
    ),
    state: Yup.string().test(
      'test-required',
      'State is required when at least one Address field is provided',
      function () {
        const { parent } = this;
        const { address1, address2, city, state, zip_code } = parent as any;
        if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
          return !!state;
        }
        return true;
      },
    ),
    zip_code: Yup.string().test(
      'test-name',
      'Valid Zip Code is required when at least one Address field is provided',
      function () {
        const { parent } = this;
        const { address1, address2, city, state, zip_code } = parent as any;
        if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
          return !!zip_code && isValidZipCode(zip_code);
        }
        return true;
      },
    ),
    email: Yup.string()
      .required('Email is required')
      .test('test-name', 'Enter Valid Email', (value: any) => {
        if (value && !isValidEmail(value)) {
          return false;
        }
        return true;
      }),
    phone_number: Yup.string().test('test-name', 'Enter Valid Phone Number', (value: any) => {
      if (value && !isValidPhoneNumber(value)) {
        return false;
      }
      return true;
    }),
    national_provider_id: Yup.string().required('Provider NPI # is required'),
    credentials: Yup.string().required('Credentials is required'),
    licences: Yup.array()
      .min(1, 'Licenses is required')
      .required('Licenses is required')
      .test('all-fields-filled', 'Please complete all license fields', (licenses: License[] = []) => {
        if (licenses.length === 0) {
          return true;
        }
        const isFilled = licenses.every((license) => {
          const { state, number, expiry_date } = license;
          return state === '' && number === '' && expiry_date === '';
        });
        return isFilled ? false : licenses.every((license) => checkAllFieldsFilled(license));
      })
      .test('expiry-date', 'Invalid expiry date', (licenses: License[] = []) => {
        return licenses.every((license) => {
          const { expiry_date } = license;
          if (expiry_date && !isValidDate(expiry_date)) {
            return false;
          }
          if (expiry_date) {
            const values = expiry_date?.split('/');
            if (!checkValidDate(values[0], values[1], values[2])) {
              return false;
            }
          }
          return true;
        });
      })
      .test('test-future', `You can't enter expired licence`, (licenses: License[] = []) => {
        return licenses.every((license) => {
          const { expiry_date } = license;
          if (expiry_date) {
            const values = expiry_date?.split('/');
            if (checkExpiredDate(values[0], values[1], values[2])) {
              return false;
            }
          }
          return true;
        });
      })
      .test('insurance-plans', 'Please fill all insurance plan fields', (licenses: License[] = []) => {
        return licenses.every((license) => {
          const { insurance_carriers } = license;
          if (insurance_carriers && insurance_carriers.length > 0) {
            return insurance_carriers.every((plan) => {
              const { insurance_carrier_id, effective_date, recredential_date } = plan;
              return (
                insurance_carrier_id !== undefined &&
                insurance_carrier_id !== null &&
                effective_date !== '' &&
                recredential_date !== ''
              );
            });
          }
          return true;
        });
      })
      .test('insurance-plans', 'Please enter valid effective date', (licenses: License[] = []) => {
        return licenses.every((license) => {
          const { insurance_carriers } = license;
          if (insurance_carriers && insurance_carriers.length > 0) {
            return insurance_carriers.every((plan) => {
              const { insurance_carrier_id, effective_date, recredential_date } = plan;
              return (
                insurance_carrier_id !== undefined &&
                insurance_carrier_id !== null &&
                effective_date !== '' &&
                recredential_date !== '' &&
                isValidDate(effective_date)
              );
            });
          }
          return true;
        });
      })
      .test('insurance-plans', 'Please enter valid re-credentialing date', (licenses: License[] = []) => {
        return licenses.every((license) => {
          const { insurance_carriers } = license;
          if (insurance_carriers && insurance_carriers.length > 0) {
            return insurance_carriers.every((plan) => {
              const { insurance_carrier_id, effective_date, recredential_date } = plan;
              const values = recredential_date?.split('/');
              return (
                insurance_carrier_id !== undefined &&
                insurance_carrier_id !== null &&
                effective_date !== '' &&
                recredential_date !== '' &&
                isValidDate(recredential_date) &&
                !checkExpiredDate(values[0], values[1], values[2])
              );
            });
          }
          return true;
        });
      }),
    primary_specialty: Yup.array(),
    secondary_specialty: Yup.array().max(3, 'Select up to 3'),
    additional_credentials: Yup.string(),
    education: Yup.array(),
    biography: Yup.string(),
    organization: Yup.array().min(1, 'Organization is required').required('Please select organization'),
  });
};
