import { useAppSelector } from '@brands/hooks/useReduxHook';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import modalClose from '../../../assets/icons/close.svg';
import Check from '../../../Auth/assets/check.svg';
import styles from './dashboardModal.module.scss';

const SubmitConfirmationModal = (): JSX.Element => {
  const navigate = useNavigate();
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const { userInfo } = useAppSelector(selectAuth);
  const goToDashboard = useCallback(() => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      navigate(`/admin/review-profile/Patient/${currentPatient.id}`);
    } else {
      navigate('/dashboard');
    }
  }, [userInfo.role.name, navigate]);
  return (
    <div className={styles.modalBackground} onClick={() => goToDashboard()}>
      <div
        className={`${styles.modalContainer} ${styles.submitConfirmationModal}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          type="button"
          className={styles.modalClose}
          style={{ backgroundImage: `url(${modalClose})` }}
          aria-label="Close modal"
          onClick={() => goToDashboard()}
        />
        <div className={styles.body}>
          <div className={styles.success}>
            <img src={Check} alt="Success" />
          </div>
          <p>
            Thank you! You have successfully submitted everything needed for a Photo Review consultation. A dentist will
            begin working on it shortly and you’ll be notified as soon as it’s ready.
          </p>
        </div>
      </div>
    </div>
  );
};
export default SubmitConfirmationModal;
