import { config } from '@brands/config/config';
import { userValidation, UserValidationPayload } from '@brands/services/identity/userValidation';
import { isValidEmail, isValidPhoneNumber } from '@brands/Utils/validation';
import * as Yup from 'yup';

export type UserSubmitForm = {
  email?: string;
  phoneNumber?: string;
  password?: string;
  confirmPassword?: string;
  isTermsAccepted?: boolean;
  d1Code?: string;
};

const userValidate = (type: string, value: string): Promise<boolean> => {
  const payload: UserValidationPayload = {
    org: config.organization_id,
    [type]: value,
  };
  return userValidation(payload);
};

export const validationSchema = (
  setPhoneNumber: React.Dispatch<React.SetStateAction<UserSubmitForm>>,
  setEmail: React.Dispatch<React.SetStateAction<UserSubmitForm>>,
  hash: string | null,
  displayAccessCodeInput: boolean,
): any => {
  return Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .test('test-name', 'Enter Valid Email', (value) => {
        if (!isValidEmail(value as string) && !isValidPhoneNumber(value as string)) {
          return false;
        }
        if (isValidEmail(value as string)) {
          setPhoneNumber({ phoneNumber: '' });
          setEmail({ email: value });
        }
        if (isValidPhoneNumber(value as string)) {
          setEmail({ email: '' });
          setPhoneNumber({ phoneNumber: value });
        }
        return true;
      })
      .test('user-validate', 'This email already exists', async (value: any) => {
        if (hash) return true;
        if (isValidEmail(value)) {
          const isEmailExists = await userValidate('email', value.toLowerCase());
          return isEmailExists;
        }
        if (isValidPhoneNumber(value)) {
          const isPhoneNumberExists = await userValidate('phone_number', `+1${value}`);
          return isPhoneNumberExists;
        }
        return true;
      }),
    password: Yup.string()
      .required('Password is required')
      .min(8)
      .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[><?@_+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-])(?=.{8,})/),
    confirmPassword: Yup.string().required('Confirm Password is required'),
    isTermsAccepted: Yup.boolean().oneOf([true], 'Accepting terms of service is required'),
    d1Code: displayAccessCodeInput ? Yup.string().required('Dentistry.One Code is required') : Yup.string(),
  });
};
