/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import { Language, License, UserProfile } from '../services/identity/types/UserProfile';

export type SortKeys = keyof UserProfile;
export type SortOrder = 'ascn' | 'desc';

export const sortData = <T extends Record<string, any>>({
  tableData,
  sortKey,
  reverse,
}: {
  tableData: T[];
  sortKey: SortKeys;
  reverse: boolean;
}): T[] => {
  const sortedData = [...tableData].sort((a, b) => {
    let aValue: any;
    let bValue: any;
    switch (sortKey) {
      case 'id':
        aValue = a.id;
        bValue = b.id;
        break;
      case 'full_name':
        aValue = a?.user?.full_name || '';
        bValue = b?.user?.full_name || '';
        break;
      case 'state':
        aValue = a.user?.provider_profile?.state || '';
        bValue = b.user?.provider_profile?.state || '';
        break;

      case 'specialty':
        aValue = a.user?.provider_profile?.primary_specialty || '';
        bValue = b.user?.provider_profile?.primary_specialty || '';
        break;
      case 'license_states':
        aValue = (a.user?.provider_profile?.licenses || []).map((license: License) => license.state);
        bValue = (b.user?.provider_profile?.licenses || []).map((license: License) => license.state);

        // Compare entire arrays of states
        aValue = aValue.join(', ');
        bValue = bValue.join(', ');

        break;

      case 'plan':
        aValue = (a.user?.provider_profile?.licenses || []).flatMap(
          (license: License) =>
            license.insurance_carriers.map((licenseInsurance) => licenseInsurance.insurance_carrier?.name) || [],
        );

        bValue = (b.user?.provider_profile?.licenses || []).flatMap(
          (license: License) =>
            license.insurance_carriers.map((licenseInsurance) => licenseInsurance.insurance_carrier?.name) || [],
        );

        // Define a custom sorting function that compares arrays without sorting them
        const customSort = (arrA: string[], arrB: string[]): number => {
          const lenA = arrA.length;
          const lenB = arrB.length;
          const minLength = Math.min(lenA, lenB);

          for (let i = 0; i < minLength; i++) {
            if (arrA[i] < arrB[i]) {
              return reverse ? 1 : -1;
            }
            if (arrA[i] > arrB[i]) {
              return reverse ? -1 : 1;
            }
          }

          // If we reached here, the common elements are equal, so the longer array comes first
          return reverse ? lenB - lenA : lenA - lenB;
        };

        // Use the custom sorting function
        const result = customSort(aValue, bValue);

        // Convert arrays to strings
        aValue = aValue.join(', ');
        bValue = bValue.join(', ');

        return result;

      case 'availability':
        aValue = (a?.sync_time_intervals?.length || 0) + (a?.async_time_intervals?.length || 0);
        bValue = (b?.sync_time_intervals?.length || 0) + (b?.async_time_intervals?.length || 0);
        break;

      case 'languages':
        const aLanguages = a.user?.basic_info?.languages || [];
        const bLanguages = b.user?.basic_info?.languages || [];
        const aLangNames = aLanguages.map((lang: Language) => lang.name);
        const bLangNames = bLanguages.map((lang: Language) => lang.name);
        aValue = aLangNames
          .sort((l1: string, l2: string) => {
            const l1Index = aLanguages.findIndex((lang: Language) => lang.name === l1);
            const l2Index = aLanguages.findIndex((lang: Language) => lang.name === l2);
            return l1Index - l2Index;
          })
          .join(', ');
        bValue = bLangNames
          .sort((l1: string, l2: string) => {
            const l1Index = bLanguages.findIndex((lang: Language) => lang.name === l1);
            const l2Index = bLanguages.findIndex((lang: Language) => lang.name === l2);
            return l1Index - l2Index;
          })
          .join(', ');
        break;
      default:
        aValue = '';
        bValue = '';
    }
    if (sortKey === 'full_name' && aValue && bValue) {
      return reverse ? bValue?.localeCompare(aValue) : aValue?.localeCompare(bValue);
    }
    if (aValue < bValue) {
      return reverse ? 1 : -1;
    }
    if (aValue > bValue) {
      return reverse ? -1 : 1;
    }
    return 0;
  });
  return sortedData;
};
