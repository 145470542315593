import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { isValidAddress, isValidEmail, isValidName, isValidZipCode } from '../../../../Utils/validation';

export const profileValidationSchema = (reviewProfile = false): any => {
  return Yup.object().shape({
    profile_img: Yup.string(),
    first_name: Yup.string()
      .required('First name is required')
      .test('test-name', 'Enter Valid First Name', (value: any) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    last_name: Yup.string()
      .required('Last name is required')
      .test('test-name', 'Enter Valid Last Name', (value: any) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    address1: Yup.string()
      .required('Address is required')
      .test('test-adress1', 'Valid Address is required', (value: any) => {
        return isValidAddress(value);
      }),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string()
      .required('Zip Code is required')
      .test('test-zipcode', 'Valid Zip Code is required', (value: any) => {
        return isValidZipCode(value);
      }),

    email: Yup.string()
      .required('Email is required')
      .test('test-email', 'Enter Valid Email', (value: any) => {
        return isValidEmail(value);
      }),
    organization: reviewProfile
      ? Yup.object().shape({
          value: Yup.string().required('Organization is required'),
          label: Yup.string().required('Organization is required'),
        })
      : Yup.object(),
    phone_number: Yup.string().test('test-name', 'Enter Valid Phone Number', (value) => {
      if (value && !isValidPhoneNumber(value)) {
        return false;
      }
      return true;
    }),
  });
};
