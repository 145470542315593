import cn from 'classnames';
import React from 'react';
import { FieldErrors } from 'react-hook-form';

import styles from './styles.module.scss';

type CustomCheckboxProps = {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  containerClassName?: string;
  size?: string;
  textStyles?: object;
  errors?: FieldErrors;
  disabled?: boolean;
};

const CustomCheckbox = React.forwardRef((props: CustomCheckboxProps, ref): JSX.Element => {
  return (
    <div className={cn(styles.checkInput, props.containerClassName)}>
      <div className="form-check m-0 p-0 d-flex gap-2 align-items-center" style={{ minHeight: 'unset' }}>
        <input
          type="checkbox"
          name={props.label.toLowerCase()}
          className="form-check-input"
          checked={props.checked}
          onChange={props.onChange}
          ref={ref as React.RefObject<HTMLInputElement>}
          style={{
            width: props.size,
            height: props.size,
            borderColor: props?.errors?.checkboxes ? '#BA271A' : '',
          }}
          disabled={props.disabled}
        />
        <label
          className={styles.text}
          style={{ whiteSpace: 'pre-line', color: props.disabled ? '#777e84' : '', ...props.textStyles }}
          htmlFor={`${props.label}`}
        >
          {props.label}
        </label>
      </div>
    </div>
  );
});

CustomCheckbox.defaultProps = {
  containerClassName: '',
  size: '16px',
  textStyles: {},
  errors: {},
  disabled: false,
};

export default CustomCheckbox;
