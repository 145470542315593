import { defaultFormValues } from '@brands/Dashboard/MyProfile/Provider/utils/defaultFromValues';
import { ProviderMyProfileSubmit } from '@brands/Dashboard/MyProfile/Provider/utils/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProviderMyProfileState = {
  myProfile: ProviderMyProfileSubmit;
};

const initialState: ProviderMyProfileState = {
  myProfile: defaultFormValues,
};

export const providerMyProfileSlice = createSlice({
  name: 'providerMyProfile',
  initialState,
  reducers: {
    setProviderMyProfile: (state, action: PayloadAction<ProviderMyProfileSubmit>) => {
      return { ...state, myProfile: action.payload };
    },
    clearProviderMyProfile: () => {
      return initialState;
    },
  },
});

export const { setProviderMyProfile, clearProviderMyProfile } = providerMyProfileSlice.actions;

export default providerMyProfileSlice.reducer;
