import { client } from '../client';

export interface Payload {
  refresh_token: string;
}

export interface RefreshTokenResponse {
  access_token: string;
}
export const refreshToken = async (payload: Payload): Promise<RefreshTokenResponse> => {
  const { data } = await client.post<RefreshTokenResponse>(`/auth/refresh_token`, payload);

  return data;
};
