/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-promise-executor-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */
import { useLocalStorageState } from '@brands/hooks';
import {
  GaussianBlurBackgroundProcessor,
  ImageFit,
  isSupported,
  Pipeline,
  VirtualBackgroundProcessor,
} from '@twilio/video-processors';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { LocalVideoTrack, Room } from 'twilio-video';

import Chair from '../../VideoRoom/assets/backgroundImages/Chair.jpg';
import Ferns from '../../VideoRoom/assets/backgroundImages/Ferns.jpg';
import SecondShelves from '../../VideoRoom/assets/backgroundImages/SecondShelves.jpg';
import Shelves from '../../VideoRoom/assets/backgroundImages/Shelves.jpg';
import ChairThumb from '../../VideoRoom/assets/backgroundImages/thumbs/Chair.jpg';
import FernsThumb from '../../VideoRoom/assets/backgroundImages/thumbs/Ferns.jpg';
import SecondShelvesThumb from '../../VideoRoom/assets/backgroundImages/thumbs/SecondShelves.jpg';
import ShelvesThumb from '../../VideoRoom/assets/backgroundImages/thumbs/Shelves.jpg';

export interface BackgroundSettings {
  type: 'none' | 'blur' | 'image';
  index?: number;
}

const imageNames: string[] = ['Chair', 'Ferns', 'Shelves', 'Second Shelves'];

const images = [ChairThumb, FernsThumb, ShelvesThumb, SecondShelvesThumb];

const rawImagePaths = [Chair, Ferns, Shelves, SecondShelves];

const imageElements = new Map();

const getImage = (index: number): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    if (imageElements.has(index)) {
      return resolve(imageElements.get(index));
    }
    const img = new Image();
    img.onload = () => {
      imageElements.set(index, img);
      resolve(img);
    };
    img.onerror = reject;
    img.src = rawImagePaths[index];
  });
};

export const backgroundConfig = {
  imageNames,
  images,
};

const virtualBackgroundAssets = '/assets/virtualBackground';
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;
export const BACKGROUND_FILTER_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 640,
  height: 480,
  frameRate: 24,
};

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

export default function useBackgroundSettings(
  videoTrack: LocalVideoTrack | undefined,
  room?: Room | null,
): readonly [BackgroundSettings, Dispatch<SetStateAction<BackgroundSettings>>] {
  const [backgroundSettings, setBackgroundSettings] = useLocalStorageState<BackgroundSettings>('backgroundSettings', {
    type: 'none',
    index: 0,
  });

  const setCaptureConstraints = useCallback(() => {
    const { mediaStreamTrack, processor } = videoTrack ?? {};
    if (!mediaStreamTrack) {
      console.warn('No mediaStreamTrack found.');
      return;
    }
    const { type } = backgroundSettings;
    if (type === 'none' && processor) {
      return mediaStreamTrack?.applyConstraints(DEFAULT_VIDEO_CONSTRAINTS as MediaTrackConstraints);
    }
    if (type !== 'none' && !processor) {
      return mediaStreamTrack?.applyConstraints(BACKGROUND_FILTER_VIDEO_CONSTRAINTS as MediaTrackConstraints);
    }
  }, [backgroundSettings, videoTrack]);

  const removeProcessor = useCallback(() => {
    if (videoTrack && videoTrack.processor) {
      videoTrack.removeProcessor(videoTrack.processor);
    }
  }, [videoTrack]);

  const addProcessor = useCallback(
    (processor: GaussianBlurBackgroundProcessor | VirtualBackgroundProcessor) => {
      if (!videoTrack || videoTrack.processor === processor) {
        return;
      }
      removeProcessor();
      videoTrack.addProcessor(processor, {
        inputFrameBufferType: 'video',
        outputFrameBufferContextType: 'webgl2',
      });
    },
    [videoTrack, removeProcessor],
  );

  useEffect(() => {
    if (!isSupported) {
      console.warn('Virtual background is not supported in this browser.');
      return;
    }
    const handleProcessorChange = async (): Promise<any> => {
      if (!blurProcessor) {
        blurProcessor = new GaussianBlurBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
          maskBlurRadius: 5,
          blurFilterRadius: 15,
        });
        await blurProcessor.loadModel();
      }
      if (!virtualBackgroundProcessor) {
        virtualBackgroundProcessor = new VirtualBackgroundProcessor({
          pipeline: Pipeline.WebGL2,
          assetsPath: virtualBackgroundAssets,
          backgroundImage: await getImage(0),
          fitType: ImageFit.Cover,
          maskBlurRadius: 5,
        });
        await virtualBackgroundProcessor.loadModel();
      }
      if (!videoTrack) {
        console.warn('No video track found.');
        return;
      }
      // Switch to 640x480 dimensions on desktop Chrome or browsers that
      // do not support WebAssembly SIMD to achieve optimum performance.
      const processor = blurProcessor || virtualBackgroundProcessor;
      // @ts-ignore
      if (!processor._isSimdEnabled || !processor._isChrome) {
        await setCaptureConstraints();
      }
      setTimeout(async () => {
        if (backgroundSettings.type === 'blur') {
          addProcessor(blurProcessor);
        } else if (backgroundSettings.type === 'image' && typeof backgroundSettings.index === 'number') {
          virtualBackgroundProcessor.backgroundImage = await getImage(backgroundSettings.index);
          addProcessor(virtualBackgroundProcessor);
        } else {
          removeProcessor();
        }
      }, 500);
    };
    handleProcessorChange();
  }, [backgroundSettings, videoTrack, room, addProcessor, removeProcessor]);

  return [backgroundSettings, setBackgroundSettings] as const;
}
