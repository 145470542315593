import React, { useCallback, useEffect, useState } from 'react';

import { Direction } from './constants';
import styles from './styles.module.scss';

interface ResizerProps {
  onResize: (direction: string, movementX: number, movementY: number) => void;
}

const Resizer: React.FC<ResizerProps> = ({ onResize }) => {
  const [direction, setDirection] = useState<string>('');
  const [mouseDown, setMouseDown] = useState(false);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!direction) return;

      e.preventDefault();
      e.stopPropagation();

      const ratio = window.devicePixelRatio;
      onResize(direction, e.movementX / ratio, e.movementY / ratio);
    },
    [direction, onResize],
  );

  useEffect(() => {
    if (mouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
    }

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [mouseDown, handleMouseMove]);

  useEffect(() => {
    const handleMouseUp = (e: MouseEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      setMouseDown(false);
    };
    window.addEventListener('mouseup', handleMouseUp);

    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, []);

  const handleMouseDown = (dir: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDirection(dir);
    setMouseDown(true);
  };

  return (
    <>
      <div className={styles.topLeft} onMouseDown={handleMouseDown(Direction.TopLeft)} />
      <div className={styles.top} onMouseDown={handleMouseDown(Direction.Top)} />
      <div className={styles.topRight} onMouseDown={handleMouseDown(Direction.TopRight)} />
      <div className={styles.right} onMouseDown={handleMouseDown(Direction.Right)} />
      <div className={styles.rightBottom} onMouseDown={handleMouseDown(Direction.BottomRight)} />
      <div className={styles.bottom} onMouseDown={handleMouseDown(Direction.Bottom)} />
      <div className={styles.bottomLeft} onMouseDown={handleMouseDown(Direction.BottomLeft)} />
      <div className={styles.left} onMouseDown={handleMouseDown(Direction.Left)} />
    </>
  );
};

export default Resizer;
