/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import imageDrag from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/drag-image-icon.svg';
import { CaseType } from '@brands/services/cases/types/ICase';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setForm } from '@brands/store/slices/patientFormSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import { truncateFilename } from '../../../../Utils/truncateString';
import AddDependentModal from '../../../MyProfile/Patient/Sections/AddDependentModal/AddDependentModal';
import styles from './styles.module.scss';

type ImageMobileUploadSubmit = {
  images: UploadedFile[];
};

interface UploadedFile {
  name: string;
  dataURL: string;
}

const ImageMobileUpload = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const screenSize = useWindowSize();
  const { state } = useLocation();
  const [showAddDependentModal, setShowAddDependentModal] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);
  const [images, setImages] = useState<UploadedFile[]>(
    (values.find((item) => item.form_question_id === 17)?.value.images as UploadedFile[]) || [],
  );

  const onSubmit = (data: ImageMobileUploadSubmit): void => {
    const payload = [...values!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 17);

    filteredPayload.push({
      form_question_id: 17,
      value: { ...data },
    });

    dispatch(setForm(filteredPayload));
    navigate('/medical-information-summary', {
      state: {
        caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
        patientId: selectedPatient?.value || '',
        shouldSeeLastModal: false,
        fromPatientIntake: true,
      },
    });
  };

  const onBack = (): void => {
    dispatch(setForm([...values!]));
    navigate(-1);
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const validationSchema = Yup.object().shape({
    images:
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
        ? Yup.array()
            .min(1, 'At least 1 images are required to upload.')
            .max(10, 'Only 10 images are allowed to upload.')
            .required('At least 1 images are required to upload.')
        : (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
        ? Yup.array()
            .min(1, 'At least 1 attachment is required to upload.')
            .max(10, 'Only 10 attachments are allowed to upload.')
            .required('At least 1 attachment is required to upload.')
        : Yup.array().max(10, 'Only 10 images are allowed to upload.').nullable(),
  });

  const { setValue, register, handleSubmit } = useForm<ImageMobileUploadSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const addImages = (uploadedFiles: UploadedFile[]): void => {
    const newImages = uploadedFiles.filter(
      (file) =>
        file.dataURL.startsWith('data:image/') ||
        file.dataURL.startsWith('data:application/pdf') ||
        file.dataURL.startsWith('data:application/msword') ||
        file.dataURL.startsWith('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        file.dataURL.startsWith('data:application/vnd.ms-powerpoint') ||
        file.dataURL.startsWith('data:application/vnd.openxmlformats-officedocument.presentationml.presentation'),
    );

    if (images.length + newImages.length > 10) {
      alert(
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
          ? 'Only 1-10 files are allowed to upload.'
          : 'Max 10 files are allowed to upload.',
      );
      return;
    }

    setImages(uniqBy([...images, ...newImages], 'dataURL'));
  };

  const handleDragOver = (event: any): void => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLImageElement>): void => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);

    const uploadedFilesPromises: Promise<UploadedFile>[] = files.map((file) => {
      return new Promise<UploadedFile>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            name: file.name,
            dataURL: reader.result as string,
          });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(uploadedFilesPromises).then((uploadedFiles) => {
      addImages(uploadedFiles);
    });
  };

  const handleDragEnter = (): void => {
    setIsDragging(true);
  };

  const handleDragLeave = (): void => {
    setIsDragging(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const files = Array.from(event.target.files as FileList);

    const uploadedFilesPromises: Promise<UploadedFile>[] = files.map((file) => {
      return new Promise<UploadedFile>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            name: file.name,
            dataURL: reader.result as string,
          });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(uploadedFilesPromises).then((uploadedFiles) => {
      addImages(uploadedFiles);
    });
  };

  const deleteMedia = (mediaName: string): void => {
    const selectedMedia = images.find((image) => image.dataURL === mediaName);
    if (selectedMedia) {
      const idx = images.indexOf(selectedMedia);
      setImages(images.filter((image, index) => index !== idx));
    }
  };

  useEffect(() => {
    const payload = [...values!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 17);

    if (images.length > 0) {
      setValue('images', images);

      filteredPayload.push({
        form_question_id: 17,
        value: { images },
      });
    } else {
      setValue('images', []);
      filteredPayload.push({
        form_question_id: 17,
        value: { images: [] },
      });
    }

    dispatch(setForm(filteredPayload));
  }, [images]);

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.uploadImageContainer}>
        <div className={styles.uploadImageContainerDiv}>
          <ProgressBar currentStep={3} totalSteps={4} onBack={onBack} hideStepNumbers />
          <div className={`${styles.formTitle} d-flex mt-3`}>Guided Image Capture</div>
          {images.length > 0 && (
            <div className={styles.uploadImageQRContainerText2} style={{ textAlign: 'center', margin: '15px 0' }}>
              Review captured images
            </div>
          )}
          <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <div className={styles.wrapper}>
              <div className={styles.formContent}>
                <div
                  className={`${styles.draggingArea} ${isDragging ? styles.dragging : ''}`}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  {images.length > 0 ? (
                    <div className={`${styles.grid}`}>
                      {images.map((file) => {
                        const isPDF = /^data:application\/pdf/i.test(file.dataURL);
                        const isDoc =
                          /^data:application\/vnd.openxmlformats-officedocument.wordprocessingml.document|^data:application\/msword/i.test(
                            file.dataURL,
                          );
                        const isPpt =
                          /^data:application\/vnd.openxmlformats-officedocument.presentationml.presentation/i.test(
                            file.dataURL,
                          ) || /^data:application\/vnd.ms-powerpoint/i.test(file.dataURL);

                        const isImage = /^data:image/i.test(file.dataURL);
                        return (
                          <div className={styles.imageWrap} key={file.dataURL}>
                            <span className={styles.removeImage} onClick={() => deleteMedia(file.dataURL)}>
                              <IoClose />
                            </span>
                            {isPDF || isDoc || isPpt ? (
                              <p>{truncateFilename(file.name, 20)}</p>
                            ) : isImage ? (
                              <img src={file.dataURL} alt={file.name} />
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  ) : (values.find((item) => item.form_question_id === 20)?.value.caseType as string) !==
                      CaseType.video_call_scheduled && images.length === 0 ? (
                    <div className={`${styles.requiredGrid}`}>
                      At least one image is required for a Photo Review. Please go back or click below to add images.
                    </div>
                  ) : null}
                  <span
                    className={`${styles.textDrag} ${
                      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                        CaseType.video_call_scheduled &&
                      images.length === 0 &&
                      styles.noBoarder
                    }`}
                  >
                    <img className={styles.dragImage} src={imageDrag} alt="Drap here" />
                    <p>
                      To add additional photos{' '}
                      <span>
                        <label htmlFor="formId" className={`${styles.uploadImageLabel}`}>
                          <input
                            {...register('images')}
                            name=""
                            type="file"
                            id="formId"
                            onChange={handleChange}
                            multiple
                            hidden
                            accept={
                              (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                              'second_opinion'
                                ? '.pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .jpeg, .jfif, .pjpeg, .pjp'
                                : '.png, .jpg, .jpeg, .jfif, .pjpeg, .pjp'
                            }
                          />

                          <div className={styles.selectImages}>
                            <span>Tap Here</span>
                          </div>
                        </label>
                      </span>{' '}
                      to upload images from your phone.
                    </p>
                  </span>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <Button
                  type="submit"
                  style={{ width: '245px', height: '46px' }}
                  disabled={
                    ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' &&
                      (images.length < 1 || images.length > 10)) ||
                    ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                      'second_opinion' &&
                      (images.length === 0 || images.length > 10))
                  }
                >
                  {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' ||
                  (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
                    ? 'Next'
                    : images.length > 0
                    ? 'Next'
                    : 'Skip'}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showAddDependentModal && (
        <AddDependentModal isOpen={showAddDependentModal} setIsOpen={setShowAddDependentModal} isFromSelectNeeds />
      )}
    </Container>
  );
};
export default ImageMobileUpload;
