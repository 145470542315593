import { client } from '../client';
import { ICase } from './types/ICase';

export interface IAssignProviderCase {
  provider_id: number;
}

export const assignProvider = async (case_id: number, payload: IAssignProviderCase): Promise<ICase> => {
  const { data } = await client.patch(`/cases/${case_id}/assign_provider`, payload);

  return data;
};
