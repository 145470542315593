import { client } from '../client';
import { UserProfile } from './types/UserProfile';

type UpdateDivisionPayload = {
  division_ids: string[];
};

export const updateUserDivision = async (user_id: number, payload: UpdateDivisionPayload): Promise<UserProfile> => {
  const { data } = await client.patch(`/admin/users/${user_id}/divisions`, payload);

  return data;
};
