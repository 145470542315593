import { setUser } from '@brands/store/slices/authSlice';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { setOrganization } from '@brands/store/slices/organizationSlice';
import Cookies from 'js-cookie';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../hooks/useReduxHook';
import { displayErrorDetails } from '../../Utils/displayError';
import ConfirmOTP from '../ConfirmOTP/ConfirmOTP';
import { setShowVideoThumbnail } from '@brands/store/slices/showVideoThumbnailSlice';

const CompleteSignUp: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const goToDashboard = useCallback(() => navigate('/dashboard'), [navigate]);

  const initialize = (): void => {
    try {
      const expired = Cookies.get('jwtTokenExpire');
      if (Number(expired) >= Date.now() / 1000) {
        goToDashboard();
        return;
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }

    dispatch(setUser({ userInfo: null }));
    dispatch(setShowVideoThumbnail(false));
    dispatch(setOrganization({ currentOrganization: null }));
    dispatch(setOrganizationPrices({ currentOrganizationPrices: null }));
  };

  const [username, setUsername] = useState<string>('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const extractedUsername = searchParams.get('username');
    setUsername(extractedUsername || '');
  }, []);

  const onConfirmedOTP = (): void => {
    navigate('/');
  };

  useEffect(() => {
    initialize();
  }, []);

  return <ConfirmOTP username={username} onConfirmed={onConfirmedOTP} />;
};

export default CompleteSignUp;
