import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';

import { useAppSelector } from './useReduxHook';

export default function useIdentityContext(): AuthState {
  const context = useAppSelector(selectAuth);
  if (!context) {
    throw new Error('useIdentityContext must be used within a IdentityProvider');
  }
  return context;
}
