/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import React from 'react';
import { AiOutlineStop } from 'react-icons/ai';
import { MdLensBlur } from 'react-icons/md';

import { useVideoContext } from '../../hooks';

export type Thumbnail = 'none' | 'blur' | 'image';

interface BackgroundThumbnailProps {
  thumbnail: Thumbnail;
  imagePath?: string;
  name?: string;
  index?: number;
}

export default function BackgroundThumbnail({ thumbnail, imagePath, name, index }: BackgroundThumbnailProps) {
  const { setBackgroundSettings, setIsBackgroundSelectionOpen } = useVideoContext();
  const icons = {
    none: AiOutlineStop,
    blur: MdLensBlur,
    image: null,
  };
  const ThumbnailIcon = icons[thumbnail];

  const onClickThumbnail = async (): Promise<void> => {
    await setBackgroundSettings({
      type: thumbnail,
      index,
    });
    await setIsBackgroundSelectionOpen(false);
  };

  return (
    <div
      onClick={async () => {
        await onClickThumbnail();
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      {ThumbnailIcon ? (
        <div>
          <ThumbnailIcon size={48} />
        </div>
      ) : (
        <img src={imagePath} alt={name} />
      )}
      <div style={{ fontSize: ThumbnailIcon ? '24px' : '16px' }}>{name}</div>
    </div>
  );
}
