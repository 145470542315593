/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import React, { FC, memo, useEffect } from 'react';

import { useAppSelector } from '../../hooks/useReduxHook';
import ProviderNotJoinedModal from '../ProviderNotJoinedModal/ProviderNotJoinedModal';
import SelectBackgroundModal from '../SelectBackgroundModal/SelectBackgroundModal';
import VideoApp from '../VideoApp';
import styles from './styles.module.scss';

type VideoRoomProps = {
  endCurrentCall?: boolean;
  uploadFile?: (file: File) => Promise<void>;
  setHasJoinedFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  alreadyJoined?: boolean | undefined;
};

const handleKeyDown = (event: any): void => {
  if (event.key === 'Tab' || event.key === ' ') {
    event.preventDefault();
  }
};

const VideoRoom: FC<VideoRoomProps> = ({ endCurrentCall, uploadFile, setHasJoinedFlag, alreadyJoined }) => {
  const { is_visible: isVisible } = useAppSelector(selectVideoCallCredentials);
  const { userInfo } = useAppSelector(selectAuth);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      if (!isVisible && userInfo.role.name === UserRoleName.Patient) {
        event.preventDefault();
        event.returnValue = '';
        window.close();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isVisible]);
  return (
    <div className={styles.videoRoom} onKeyDown={handleKeyDown}>
      <div className={styles.videoBody} style={{ paddingTop: isVisible ? '0px' : '', height: isVisible ? '100%' : '' }}>
        <div className={styles.videoContainer}>
          <VideoApp
            endCurrentCall={endCurrentCall}
            uploadFile={uploadFile}
            setHasJoinedFlag={setHasJoinedFlag}
            alreadyJoined={alreadyJoined}
          />
        </div>
      </div>
      <SelectBackgroundModal />
      {userInfo?.role?.name === UserRoleName.Patient && <ProviderNotJoinedModal />}
    </div>
  );
};

VideoRoom.defaultProps = {
  endCurrentCall: false,
  uploadFile: undefined,
  setHasJoinedFlag: undefined,
  alreadyJoined: false,
};

export default memo(VideoRoom);
