import NotFoundPage from '@brands/Dashboard/NotFoundPage/NotFoundPage';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';
import { useParams } from 'react-router-dom';

import { UserRoleName } from '../../../services/identity/types/UserProfile';
import CreateConsultationFor from './CreateConsultationFor';

const CreateConsultationForValidation = (): JSX.Element => {
  const roleName = useParams().role as string;
  const user: AuthState = useAppSelector(selectAuth);
  let component: JSX.Element;

  switch (roleName) {
    case UserRoleName.Patient:
    case UserRoleName.Dependent:
      if (
        user.userInfo?.role.name === UserRoleName.Admin ||
        user.userInfo?.role.name === UserRoleName.SuperAdmin ||
        user.userInfo?.role.name === UserRoleName.OrganizationAdmin
      ) {
        component = <CreateConsultationFor />;
        break;
      } else {
        component = <NotFoundPage />;
        break;
      }
    default:
      component = <NotFoundPage />;
      break;
  }
  return <div>{component}</div>;
};
export default CreateConsultationForValidation;
