import { CaseType } from '@brands/services/cases/types/ICase';

import { UserProfile, UserRoleName } from '../../../services/identity/types/UserProfile';
import { INotification, NotificationType } from '../../../services/notification/types/INotification';

const asyncCaseTypes = [CaseType.one_report, CaseType.second_opinion, CaseType.messaging];

export const isNotificationClickable = (notification: INotification, user: UserProfile): boolean => {
  if ([NotificationType.case_provider_unassigned, NotificationType.case_ca_unassigned].includes(notification.type)) {
    return false;
  }
  if (
    notification.type === NotificationType.case_canceled &&
    (user.role.name === UserRoleName.Patient || user.role.name === UserRoleName.Provider)
  ) {
    return false;
  }
  if (notification.type === NotificationType.cc_reauthorization_failed && notification.payload?.update_disabled) {
    return false;
  }
  if (notification.payload?.case_type === CaseType.phone_call_scheduled && user.role.name === UserRoleName.Patient) {
    return false;
  }
  if (notification.type === NotificationType.case_updated && asyncCaseTypes.includes(notification.payload?.case_type)) {
    return false;
  }
  return true;
};
