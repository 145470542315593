import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import React, { FC, useEffect, useState } from 'react';
import Modal, { Styles } from 'react-modal';

import { useAppSelector } from '../../../../../hooks/useReduxHook';
import { getCurrentProfile } from '../../../../../services/identity/getCurrentProfile';
import { getUserById } from '../../../../../services/identity/getUserById';
import { listAllLanguages } from '../../../../../services/identity/listAllLanguages';
import { UserProfile, UserRoleName } from '../../../../../services/identity/types/UserProfile';
import { customStylesDashboard } from '../../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import { OptionLanguage } from '../../../../../Utils/selectOptions';
import styles from '../../../styles.module.scss';
import EditDependent from '../Dependent/EditDependent';

interface CustomStylesDashboard {
  overlay: Styles['overlay'];
  content: Styles['content'];
}

interface AddDependentModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFromSelectNeeds?: boolean;
}

const userRoleCustomStylesDashboard: CustomStylesDashboard = {
  ...customStylesDashboard,
  overlay: {
    ...customStylesDashboard.overlay,
    backgroundColor: '#1f2b38f0',
    overflowY: 'hidden',
  },
  content: {
    ...customStylesDashboard.content,
    width: '100%',
    maxWidth: '90vw',
    height: '90%',
    maxHeight: '90vh',
    backgroundColor: '#F0F0F0',
    borderRadius: '11px',
    opacity: '1',
    padding: '0',
    alignItems: 'flex-start',
  },
};

const AddDependentModal: FC<AddDependentModalProps> = ({ isOpen, setIsOpen, isFromSelectNeeds }) => {
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient } = useAppSelector(selectPatient);

  const [selection, setSelection] = useState<[number | null, number | null] | null>(null);
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const [thisUser, setThisUser] = useState<UserProfile>(
    userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
      ? selectedPatient
      : userInfo,
  );
  const [validationResponseId, setValidationResponseId] = useState('');

  const ref = React.useRef<HTMLInputElement>(null);

  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  const fetchLanguageOptions = async (): Promise<void> => {
    const data = await listAllLanguages();
    const transformedData = data.map((language) => ({
      value: language.locale,
      label: language.name,
      id: language.id,
    }));
    setLanguageOptions(transformedData);
  };

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const getCurrentUser = async (id: string): Promise<void> => {
    try {
      const res = await getUserById(Number(id));
      setThisUser(res);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const fetchUserDetails = async (): Promise<void> => {
    const userDetails = await getCurrentProfile();
    setThisUser(userDetails);
  };

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        getCurrentUser(selectedPatient.id);
      }
    } else {
      fetchUserDetails();
    }
  }, [userInfo]);

  useEffect(() => {
    fetchLanguageOptions();
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={userRoleCustomStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <div className={styles.profileContainer} style={{ paddingTop: 0, width: '100%', height: '100%' }}>
        <div className={styles.contentContainer} style={{ padding: '40px 80px' }}>
          <div>
            <EditDependent
              setIsEditDependent={setIsOpen}
              setSelection={setSelection}
              setThisUser={setThisUser}
              dependentId={0}
              thisUser={thisUser}
              languageOptions={languageOptions}
              isModal
              validationResponseId={validationResponseId}
              setValidationResponseId={setValidationResponseId}
              isFromSelectNeeds={isFromSelectNeeds}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

AddDependentModal.defaultProps = {
  isFromSelectNeeds: false,
};

export default AddDependentModal;
