import { client } from '../client';
import { IGMConversation } from './types/IGMConversation';

export const getConversation = async (sid: string, includeParticipants = true): Promise<IGMConversation> => {
  if (!sid) {
    return Promise.reject(new Error('SID is required'));
  }

  const { data } = await client.get(`/conversations/${sid}?include_participants=${includeParticipants}`);

  return data;
};
