import { JSONObject, Message } from '@twilio/conversations';

import { IGMMessage } from '../services/communication/types/IGMMessage';

export const mapToGMMessage = (message: Message): IGMMessage => {
  let archivedAt: string | null = null;
  const mediaIds = message.attributes as JSONObject;
  const { ...attributes } = message.attributes as JSONObject;
  if (typeof message.attributes === 'string') {
    try {
      const attributesPayload = JSON.parse(message.attributes);
      archivedAt = attributesPayload.archivedAt || null;
    } catch {
      archivedAt = null;
    }
  } else {
    archivedAt = null;
  }
  return {
    sid: message.sid,
    conversation_sid: message.conversation.sid,
    index: message.index,
    created_by: message.author,
    body: message.body,
    media_ids: mediaIds.media_ids as string[],
    attributes,
    created_at: message.dateCreated!.toISOString(),
    updated_at: message.dateUpdated!.toISOString(),
    archived_at: archivedAt,
  };
};
