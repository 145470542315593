import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import ConfirmPasswordModal from '@brands/Components/Modals/ConfirmPasswordModal/ConfirmPasswordModal';
import { getMfaStatus } from '@brands/services/auth/getMfaStatus';
import { manageMfa } from '@brands/services/auth/manageMfa';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { CSSObjectWithLabel } from 'react-select';

import Button from '../../../../../Components/Button/Button';
import { useAppSelector } from '../../../../../hooks/useReduxHook';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import styles from '../../../styles.module.scss';

type EditAccountSecurityProps = {
  setIsEditSecurity: (value: boolean) => void;
};

type MfaOptions = {
  value: string;
  label: string;
  disabled?: boolean;
};

const selectCommonCustomStylesMFA = {
  ...selectCommonCustomStylesLarge,
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    div: {
      cursor: 'pointer',
      '&:first-child': {
        cursor: 'not-allowed',
        backgroundColor: '#f5f5f5',
        opacity: 0.5,
      },
    },
  }),
};

const EditAccountSecurity = ({ setIsEditSecurity }: EditAccountSecurityProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] = React.useState<boolean>(false);
  const [isSelected, setIsSelected] = React.useState<number | null>(null);
  const [selectedMethod, setSelectedMethod] = React.useState<string>('');
  const [buttonNumber, setButtonNumber] = React.useState<number>(0);
  const [mfaOptionsList, setMfaOptionsList] = React.useState<MfaOptions[]>([{ value: 'EMAIL_MFA', label: 'Email' }]);
  const [initialValues, setInitialValues] = React.useState<{ isSelected: number | null; selected_method: string }>({
    isSelected: null,
    selected_method: '',
  });

  const enableOptions = [
    { id: 1, value: 'true', label: 'On' },
    { id: 2, value: 'false', label: 'Off' },
  ];

  const addSelectedClass = (e: React.MouseEvent<HTMLButtonElement>, bNumber: number): void => {
    e.preventDefault();
    if (isSelected === bNumber) {
      return;
    }
    setShowConfirmPasswordModal(true);
    setButtonNumber(bNumber);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (userInfo.phone_number) {
        setMfaOptionsList([
          { value: 'SMS_MFA', label: 'SMS', disabled: true },
          { value: 'EMAIL_MFA', label: 'Email' },
        ]);
      }
      const mfaStatus = await getMfaStatus(userInfo.id);
      setIsSelected(mfaStatus.mfa_method.includes('SMS_MFA') || mfaStatus.mfa_method.includes('EMAIL_MFA') ? 1 : 2);
      if (mfaStatus.mfa_method.length > 0) {
        setSelectedMethod(mfaStatus.mfa_method[0]);
      } else {
        setSelectedMethod('EMAIL_MFA');
      }
      setInitialValues({
        isSelected: mfaStatus.mfa_method.includes('SMS_MFA') || mfaStatus.mfa_method.includes('EMAIL_MFA') ? 1 : 2,
        selected_method: mfaStatus.mfa_method[0],
      });
      setLoading(false);
    })();
  }, [userInfo.id]);

  const onSubmitAccountSecurity = async (): Promise<void> => {
    try {
      await manageMfa(userInfo.id, { selected_method: isSelected === 1 ? selectedMethod : null });
      setInitialValues({ isSelected, selected_method: selectedMethod });
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setIsEditSecurity(false);
    }
  };

  const onCancel = (): void => {
    setIsEditSecurity(false);
  };

  const onCloseConfirmPasswordModal = (): void => {
    setShowConfirmPasswordModal(false);
  };

  if (loading) {
    return <Loading dotted isHeightAuto />;
  }

  return (
    <div className={styles.editProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Account Security</span>
      </div>
      <div className={styles.editContainer}>
        <form className={styles.formContainer}>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={styles.caption}>Multi-factor Authentication</div>
              <div className={styles.container}>
                <div className={styles.flexWrap}>
                  {enableOptions.map((selection) => (
                    <button
                      className={
                        isSelected === selection.id
                          ? `${styles.multiSelector} ${styles.buttonSelected}`
                          : `${styles.multiSelector}`
                      }
                      value={selection.value}
                      type="button"
                      onClick={(e) => userInfo.role.name === 'Patient' && addSelectedClass(e, selection.id)}
                      key={selection.id}
                      disabled={userInfo.role.name !== 'Patient'}
                    >
                      <span>{selection.label}</span>
                      <div className={styles.checkInput} />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={styles.caption}>Preferred Delivery Method</div>
              <SelectInput
                name="preferredMethod"
                options={mfaOptionsList}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesMFA}
                selectedValue={mfaOptionsList.find((option) => option.value === selectedMethod)}
                onChange={(value) => {
                  setSelectedMethod(value?.value);
                }}
                isDisabled={isSelected !== 1}
              />
            </div>
          </div>
          <div className={styles.actionBtnContainer}>
            <Button type="button" className={styles.cancel} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="button"
              className={styles.submit}
              onClick={onSubmitAccountSecurity}
              disabled={initialValues.isSelected === isSelected || (isSelected === 1 && !selectedMethod)}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      <ConfirmPasswordModal
        isOpen={showConfirmPasswordModal}
        onClose={onCloseConfirmPasswordModal}
        email={userInfo.email}
        buttonNumber={buttonNumber}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
      />
    </div>
  );
};

export default EditAccountSecurity;
