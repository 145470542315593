import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../hooks/useReduxHook';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import CareAdvisorDashboard from './CareAdvisor/CareAdvisorDashboard';
import PatientDashboard from './Patient/Dashboard';
import ProviderDashboard from './Provider/ProviderDashboard';

const Dashboard: React.FC = () => {
  let component: JSX.Element;
  const user: AuthState = useAppSelector(selectAuth);
  const navigate = useNavigate();

  switch (user.userInfo?.role.name) {
    case UserRoleName.CareAdvisor:
      component = <CareAdvisorDashboard />;
      break;
    case UserRoleName.Provider:
      component = <ProviderDashboard />;
      break;
    case UserRoleName.Patient:
      component = <PatientDashboard />;
      break;
    case UserRoleName.Admin:
      navigate('/admin/dashboard');
      return null;
    case UserRoleName.SuperAdmin:
      navigate('/admin/dashboard');
      return null;
    case UserRoleName.OrganizationAdmin:
      navigate('/admin/dashboard');
      return null;
    default:
      // call 404 page
      component = <NotFoundPage />;
      break;
  }

  return <div>{component}</div>;
};

export default Dashboard;
