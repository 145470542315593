import { useEffect, useState } from 'react';
import { RemoteParticipant, Room } from 'twilio-video';

export default function useParticipants(room: Room | null): RemoteParticipant[] {
  const [participants, setParticipants] = useState(Array.from(room?.participants.values() ?? []));

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const participantConnected = (participant: RemoteParticipant): void => {
        if (participant.state === 'connected') {
          setParticipants((prevParticipants) => [...prevParticipants, participant]);
        }
      };

      const participantDisconnected = (participant: RemoteParticipant): void =>
        setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));

      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  return participants;
}
