import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type VideoCallCredentialsSliceState = {
  is_visible: boolean;
  vc_case_id: string;
  uploadFileMethod: (file: File) => Promise<void>;
};

const initialState: VideoCallCredentialsSliceState = {
  is_visible: false,
  vc_case_id: '',
  uploadFileMethod: () => Promise.resolve(),
};

export const videoCallCredentialsSlice = createSlice({
  name: 'videoCallCredentials',
  initialState,
  reducers: {
    setIsVisible(state, action: PayloadAction<boolean>) {
      Object.assign(state, { is_visible: action.payload });
    },
    setVCCaseId(state, action: PayloadAction<string>) {
      Object.assign(state, { vc_case_id: action.payload });
    },
    setUploadFileMethod(state, action: PayloadAction<(file: File) => Promise<void>>) {
      Object.assign(state, { uploadFileMethod: action.payload });
    },
  },
});

export const { setIsVisible, setVCCaseId, setUploadFileMethod } = videoCallCredentialsSlice.actions;

export default videoCallCredentialsSlice.reducer;
