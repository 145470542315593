import { client } from '../client';
import { UserProfile } from './types/UserProfile';

export const updateEmailAddress = async (user_id: number, email: string): Promise<UserProfile> => {
  const { data } = await client.patch(`/users/${user_id}/unverified_email`, {
    unverified_email: email,
  });

  return data;
};
