/* eslint-disable prefer-destructuring */
import { ICDT } from '@brands/services/oneReport/types/IOneReport';
import { multiSelectCommonCustomStyles } from '@brands/Utils/customStyles';
import React, { SetStateAction, useEffect, useState } from 'react';
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { GoTrash } from 'react-icons/go';

import MultiselectInput from '../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import { CaseType, ICase, Status } from '../../../../services/cases/types/ICase';
import { listCDTOptions } from '../../../../services/identity/listCDTOptions';
import { UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OptionCDT } from '../../../../Utils/selectOptions';
import { OptionCdts, OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type ServicesProvidedSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  getValues: UseFormGetValues<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const evaluationCodes = ['D0120', 'D0140', 'D0145', 'D0150', 'D0160', 'D0170', 'D0171', 'D0180', 'D0190', 'D0191'];

const ServicesProvidedSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  getValues,
  trigger,
  errors,
}: ServicesProvidedSectionProps): JSX.Element => {
  const [cdtOptions, setCdtOptions] = useState<OptionCdts[]>([]);
  const [originalCDTCodes, setOriginalCDTCodes] = useState<ICDT[]>([]);
  const [cdtOptionsToDisplay, setCdtOptionsToDisplay] = useState<OptionCDT[]>([]);
  const [selectedEvaluationCode, setSelectedEvaluationCode] = useState<string | null>(null);
  const [otherCodes, setOtherCodes] = useState<string[]>([]);

  const saveCaseCDT = async (caseCDT: string[]): Promise<void> => {
    const { cdts: res } = await updateOneReport(thisCase?.one_report.id || '', {
      cdts: caseCDT.length > 0 ? caseCDT : [''],
    });
    setCase({
      ...thisCase,
      one_report: {
        ...thisCase.one_report,
        cdts: res,
      },
    });
    setConsultationForm({
      ...consultationForm,
      cdt: res.map((currentCDT) => currentCDT.code),
    });
  };

  useEffect(() => {
    if (thisCase) {
      setCdtOptionsToDisplay(thisCase?.one_report?.cdts || []);
    }
  }, [thisCase]);

  useEffect(() => {
    const currentCdtCodes = getValues('cdt');
    const initialCode = evaluationCodes.find((code) => currentCdtCodes.includes(code));

    if (initialCode) {
      setSelectedEvaluationCode(initialCode);
    }

    const uniqueCdtCodes = Array.from(new Set(currentCdtCodes));
    const filteredOtherCodes = uniqueCdtCodes.filter(
      (code) => code !== 'D9995' && code !== 'D9996' && !evaluationCodes.includes(code),
    );

    setOtherCodes(filteredOtherCodes);
  }, [getValues, evaluationCodes]);

  const handleRemoveEvaluationCode = async (): Promise<void> => {
    if (selectedEvaluationCode) {
      const updatedCdtCodes = getValues('cdt').filter((code: string) => code !== selectedEvaluationCode);
      setValue('cdt', updatedCdtCodes);
      setSelectedEvaluationCode(null);
      saveCaseCDT(updatedCdtCodes);
      await trigger('cdt');
    }
  };

  const handleRemoveOtherCode = async (codeToRemove: string): Promise<void> => {
    const updatedCdtCodes = getValues('cdt').filter((code) => code !== codeToRemove);
    setValue('cdt', updatedCdtCodes);
    setOtherCodes(
      updatedCdtCodes.filter((code) => !evaluationCodes.includes(code) && code !== 'D9995' && code !== 'D9996'),
    );
    saveCaseCDT(updatedCdtCodes);
    await trigger('cdt');
  };

  const setDefaultCDTOptions = (options: OptionCdts[]): void => {
    const defaultCDTOption =
      thisCase?.type === 'video_call_scheduled' ||
      thisCase?.type === 'video_call_instant' ||
      thisCase?.type === CaseType.phone_call_scheduled
        ? options.find((item) => item?.value === 'D9995')
        : options.find((item) => item?.value === 'D9996');

    const isDefaultCDTExist = thisCase?.one_report?.cdts?.find((item) => item.code === defaultCDTOption?.value);

    if (defaultCDTOption && !isDefaultCDTExist) {
      setConsultationForm({ ...consultationForm, cdt: [defaultCDTOption?.value] });
      setValue('cdt', [defaultCDTOption?.value]);
      setCase({
        ...thisCase,
        cdts: [{ code: defaultCDTOption?.value, nomenclature: defaultCDTOption.label.split(': ')[1] }],
        one_report: { ...thisCase.one_report, assessments: getValues('assessment') || '' },
      });
      saveCaseCDT([defaultCDTOption?.value]);
    }
  };

  const fetchCDTOptions = async (): Promise<void> => {
    try {
      const data = await listCDTOptions();
      setOriginalCDTCodes(data);
      const transformedData = data
        .sort((a, b) => a.code.localeCompare(b.code))
        .map((cdts) => ({
          value: cdts.code,
          label: `${cdts.code}: ${cdts.nomenclature}`,
          disabled: cdts.code === 'D9995' || cdts.code === 'D9996',
        }))
        .filter((option) =>
          selectedEvaluationCode
            ? !evaluationCodes.includes(option?.value) || option?.value === selectedEvaluationCode
            : true,
        );

      setCdtOptions(transformedData);
      setDefaultCDTOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    fetchCDTOptions();
  }, [selectedEvaluationCode]);

  return (
    <div className={styles.assessmentDiv}>
      <div className={`${styles.cDT}`}>
        <span>CDT codes*</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <MultiselectInput
            {...register('cdt')}
            name="cdt"
            placeholder="Please add CDT"
            options={cdtOptions}
            style={multiSelectCommonCustomStyles}
            containerClass={styles.inputWrapper}
            selectorClass={styles.selector}
            errors={errors}
            selectedValue={(() => {
              const selectedCDTValues = getValues('cdt');
              const d9995Index = selectedCDTValues.indexOf('D9995');
              const d9996Index = selectedCDTValues.indexOf('D9996');

              if (d9995Index !== -1) {
                const temp = selectedCDTValues[d9995Index];
                selectedCDTValues[d9995Index] = selectedCDTValues[0];
                selectedCDTValues[0] = temp;
              } else if (d9996Index !== -1) {
                const temp = selectedCDTValues[d9996Index];
                selectedCDTValues[d9996Index] = selectedCDTValues[0];
                selectedCDTValues[0] = temp;
              }
              return selectedCDTValues.map((item: string) => {
                return { label: item, value: item };
              });
            })()}
            // Inside the onChange handler
            onChange={(value) => {
              if (value) {
                const isValueArray = Array.isArray(value);
                if (isValueArray && thisCase) {
                  const selectedOption = value[value.length - 1]?.value;

                  // Check if the selected option is an evaluation code
                  if (evaluationCodes.includes(selectedOption)) {
                    setSelectedEvaluationCode(selectedOption);

                    // Disable other evaluation codes in the dropdown
                    setCdtOptions((prevOptions) =>
                      prevOptions.map((option) =>
                        evaluationCodes.includes(option?.value) && option?.value !== selectedOption
                          ? { ...option, disabled: true }
                          : option,
                      ),
                    );
                  } else {
                    // If selected evaluation code is removed, reset the selected evaluation code
                    if (selectedEvaluationCode && !value.some((item) => item?.value === selectedEvaluationCode)) {
                      setSelectedEvaluationCode(null);
                      fetchCDTOptions();
                    }

                    const selectedValues = value.map((item: any) => item?.value);

                    // Filter newOtherCodes to remove duplicates and meet your criteria
                    const newOtherCodes = Array.from(
                      new Set(
                        selectedValues.filter(
                          (code) =>
                            code !== 'D9995' &&
                            code !== 'D9996' &&
                            !evaluationCodes.includes(code) &&
                            !otherCodes.includes(code),
                        ),
                      ),
                    );

                    // Update `otherCodes` with unique values
                    setOtherCodes((prevOtherCodes) => {
                      const updatedOtherCodes = [
                        ...prevOtherCodes.filter((code) => selectedValues.includes(code)),
                        ...newOtherCodes,
                      ];

                      // Return unique otherCodes
                      return Array.from(new Set(updatedOtherCodes));
                    });
                  }

                  // Update consultation form
                  setConsultationForm({
                    ...consultationForm,
                    cdt: value.map((item: any) => item?.value),
                  });

                  // Update the form value
                  setValue(
                    'cdt',
                    value.map((item: any) => item?.value),
                    { shouldValidate: true },
                  );

                  // Filter and convert values for case state
                  const filteredValues = cdtOptions.filter((item) =>
                    value.map((i: OptionCdts) => i?.value).includes(item?.value),
                  );
                  const convertedValueToOptionCDT = filteredValues.map((item) => ({
                    code: item?.value,
                    nomenclature: item.label.split(': ')[1],
                  }));
                  setCase({
                    ...thisCase,
                    cdts: convertedValueToOptionCDT,
                    one_report: {
                      ...thisCase.one_report,
                      assessments: getValues('assessment') || '',
                    },
                  });
                  saveCaseCDT(value.map((item: any) => item?.value));
                }
              }
            }}
            closeMenuOnSelect
          />
        ) : (
          <ul
            className={styles.cdtItem}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '1rem',
              marginBottom: '0rem',
            }}
          >
            {cdtOptionsToDisplay.map((item) => {
              return (
                <li key={item.code}>
                  {item.code}: {item.nomenclature}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className={styles.cdtChildren}>
        <div className={styles.fixedValue}>
          <div className={styles.boldText}>
            {getValues('cdt').includes('D9996') ? 'D9996: Teledentistry' : 'D9995: Teledentistry'}
            <span className={styles.noteText}> (Code cannot be deleted)</span>
          </div>
          <div>
            {getValues('cdt').includes('D9996')
              ? 'Asynchronous; information stored and forwarded to dentist for subsequent review'
              : 'synchronous; real-time encounter'}
          </div>
        </div>
        {selectedEvaluationCode && (
          <div className={styles.evaluationCodesDiv}>
            <div className={styles.evaluationCodes}>
              {userInfo.role.name === UserRoleName.Provider && thisCase.status === Status.InProgressProvider && (
                <GoTrash
                  className={styles.trashIcon}
                  onClick={handleRemoveEvaluationCode}
                  title="Remove this evaluation code"
                />
              )}
              <div className={styles.evaluationCodesInfo}>
                <div className={styles.boldText}>
                  {
                    originalCDTCodes.find(
                      (selectedEvaluationCodeValue) => selectedEvaluationCodeValue.code === selectedEvaluationCode,
                    )?.code
                  }
                </div>
                <div>
                  {
                    originalCDTCodes.find(
                      (selectedEvaluationCodeValue) => selectedEvaluationCodeValue.code === selectedEvaluationCode,
                    )?.nomenclature
                  }
                </div>
              </div>
            </div>
          </div>
        )}
        {otherCodes.length > 0 &&
          otherCodes.map((otherCode) => (
            <div key={otherCode} className={styles.otherCodesDiv}>
              <div className={`${styles.otherCodes}`}>
                {userInfo.role.name === UserRoleName.Provider && thisCase.status === Status.InProgressProvider && (
                  <GoTrash
                    className={styles.trashIcon}
                    onClick={() => handleRemoveOtherCode(otherCode)}
                    title="Remove this other code"
                  />
                )}
                <div className={styles.evaluationCodesInfo}>
                  <div className={styles.boldText}>
                    {originalCDTCodes.find((code) => code.code === otherCode)?.code}
                  </div>
                  <div>{originalCDTCodes.find((code) => code.code === otherCode)?.nomenclature}</div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default React.memo(ServicesProvidedSection);
