import { FormQuestion } from '../../../../services/forms/types/FormQuestion';

export const selectNeedsQuestions = [
  {
    value: 'dental_emergency',
    question: 'I have a dental emergency',
    needDescription: false,
  },
  {
    value: 'sensitive_teeth',
    question: 'I have sensitive teeth',
    needDescription: false,
  },
  {
    value: 'second_opinion',
    question: 'A second opinion',
    needDescription: false,
  },
  {
    value: 'improve_smile',
    question: 'I want to improve my smile',
    needDescription: false,
  },
  {
    value: 'improve_gum_health',
    question: 'I want to improve my gum health',
    needDescription: false,
  },
  {
    value: 'grind_teeth_or_snore',
    question: 'I grind my teeth/snore',
    needDescription: false,
  },
  {
    value: 'something_else',
    question: 'Something Else',
    needDescription: true,
  },
];

export const describePainLevelQuestions: FormQuestion[] = [
  {
    id: 8,
    form_id: 2,
    form_step_id: 2,
    title: 'What do you need help with today?',
    description: null,
    is_required: true,
    is_conditional: false,
    type: 'checkbox',
    options: [
      'I have a dental emergency',
      'I have sensitive teeth',
      'I want to improve my smile',
      'I want to improve my gum health',
      'I grind my teeth/snore',
      'I have questions about my child’s mouth',
      'Something else',
    ],
    validators: [],
    created_at: '2022-11-28T09:47:44.576371',
    updated_at: null,
  },
  {
    id: 9,
    form_id: 2,
    form_step_id: 2,
    title: 'Are you in pain?',
    description: null,
    is_required: true,
    is_conditional: false,
    type: 'radio',
    options: ['no', 'yes'],
    validators: [],
    created_at: '2022-11-28T09:47:46.378403',
    updated_at: null,
  },
  {
    id: 10,
    form_id: 2,
    form_step_id: 2,
    title: 'How would you describe the pain?',
    description: 'Please select all that applies.',
    is_required: true,
    is_conditional: false,
    type: 'checkbox',
    options: [
      'Sharp',
      'Off and On',
      'Dull',
      'Constant',
      'Throbbing',
      'Burning',
      'Sensitive to hot',
      'Sensitive to cold',
      'Sensitive to sweet',
    ],
    optionsObject: {
      sharp: 'Sharp',
      off_and_on: 'Off and On',
      dull: 'Dull',
      constant: 'Constant',
      throbbing: 'Throbbing',
      burning: 'Burning',
      sensitive_to_hot: 'Sensitive to hot',
      sensitive_to_cold: 'Sensitive to cold',
      sensitive_to_sweet: 'Sensitive to sweet',
    },
    validators: [],
    created_at: '2022-11-28T09:47:47.963191',
    updated_at: null,
  },
  {
    id: 11,
    form_id: 2,
    form_step_id: 2,
    title: 'How long have you been in pain?',
    description: null,
    is_required: true,
    is_conditional: false,
    type: 'select',
    options: ['Less than one day', 'A couple of days', 'About a week', 'A couple of weeks', 'A month or more'],
    optionsObject: {
      'Less than one day': 'Less than one day',
      'A couple of days': 'A couple of days',
      'About a week': 'About a week',
      'A couple of weeks': 'A couple of weeks',
      'A month or more': 'A month or more',
    },
    validators: [],
    created_at: '2022-11-28T09:47:49.636697',
    updated_at: null,
  },
  {
    id: 12,
    form_id: 2,
    form_step_id: 2,
    title: 'How would you rate your pain level?',
    description: null,
    is_required: true,
    is_conditional: false,
    type: 'select',
    options: ['No Pain', 'Mild Pain', 'Moderate Pain', 'Severe Pain', 'Very Severe Pain', 'Worst Pain Possible'],
    optionsObject: {
      noPain: 'No\nPain',
      mildPain: 'Mild\nPain',
      moderatePain: 'Moderate\nPain',
      severePain: 'Severe\nPain',
      verySeverePain: 'Very Severe\nPain',
      worstPainPossible: 'Worst Pain\nPossible',
    },
    validators: [],
    created_at: '2022-11-28T09:47:51.275217',
    updated_at: null,
  },
  {
    id: 13,
    form_id: 2,
    form_step_id: 2,
    title: 'Is there anything else that you want the dentist to know? (Optional)',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'textarea',
    options: [],
    validators: [],
    created_at: '2022-11-28T09:47:52.809611',
    updated_at: null,
  },
  {
    id: 14,
    form_id: 2,
    form_step_id: 2,
    title: 'If possible, please upload 2-6 images',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'multifileupload',
    options: [],
    validators: [],
    created_at: '2022-11-28T09:47:54.438682',
    updated_at: null,
  },
];
