/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { RemoteVideoTrack } from 'twilio-video';

import { displayErrorDetails } from './displayError';

const dataURItoBlob = (dataURI: string): Blob => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export async function capture(videoTrack: RemoteVideoTrack): Promise<File> {
  const imageCapture = new (window as any).ImageCapture(videoTrack?.mediaStreamTrack);

  try {
    const bitmap = await imageCapture.grabFrame();
    const canvas = document.createElement('canvas');
    canvas.width = bitmap.width;
    canvas.height = bitmap.height;

    const context = canvas.getContext('2d');
    context?.drawImage(bitmap, 0, 0);

    const dataURL = canvas.toDataURL('image/png');
    const blob = dataURItoBlob(dataURL);
    return new File([blob], 'image.png', { type: 'image/png' });
  } catch (error: unknown) {
    displayErrorDetails('Failed to capture image using ImageCapture.');
    throw new Error('Failed to capture image using ImageCapture.');
  }
}

export function getBlobFromMediaStream(videoTrack: RemoteVideoTrack): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const video = document.createElement('video');

    video.srcObject = new MediaStream([videoTrack.mediaStreamTrack]);
    video.muted = true;

    video.onloadedmetadata = () => {
      video
        .play()
        .then(() => {
          try {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            context?.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Failed to create Blob from canvas.'));
              }
            }, 'image/png');
          } catch (error) {
            reject(error);
          }
        })
        .catch(() => {
          reject(new Error('Error playing video before capturing frame.'));
        });
    };

    video.onerror = () => {
      reject(new Error('Error loading video metadata.'));
    };
  });
}
