/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { BsCameraVideoOff } from 'react-icons/bs';
import { TbMicrophoneOff } from 'react-icons/tb';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

import { useIsTrackEnabled, useVideoContext } from '../../hooks';
import styles from '../ParticipantInfo/styles.module.scss';
import VideoTrack from '../VideoTrack/VideoTrack';

const LocalVideoPreview = () => {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video',
  ) as LocalVideoTrack;

  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', height: '100%' }}>
      {audioTrack && !isEnabled ? (
        <div className={styles.videoParticipantMuted} style={{ position: 'absolute' }}>
          <TbMicrophoneOff /> <span className={styles.text}>Muted</span>
        </div>
      ) : null}
      <div>
        {!audioTrack ? (
          <div style={{ height: '270px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="spinner-border text-primary" role="status" />
          </div>
        ) : videoTrack ? (
          <VideoTrack track={videoTrack} isWaitingRoom />
        ) : (
          <div style={{ height: '270px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <BsCameraVideoOff size={80} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LocalVideoPreview;
