import { CaseType } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useOrganizationFlags } from '../../../../hooks';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { UserRoleName } from '../../../../services/identity/types/UserProfile';
import { DEFAULT_PATIENT_LOCATION_STATE } from '../../../../Utils/localStorage';
import SelectNeeds from './SelectNeeds';

const SelectNeedsValidation: React.FC = () => {
  const { userInfo } = useAppSelector(selectAuth);
  const { state } = useLocation();
  const { values } = useAppSelector(selectPatientForm);
  const { selectedPatient } = useAppSelector(selectPatient);
  const currentState = useAppSelector(selectCurrentPatientState);

  const patientState = useMemo(() => {
    return userInfo?.role?.name === UserRoleName.OrganizationAdmin ||
      userInfo?.role?.name === UserRoleName.SuperAdmin ||
      userInfo?.role?.name === UserRoleName.Admin
      ? currentState[selectedPatient.id] || DEFAULT_PATIENT_LOCATION_STATE
      : currentState[userInfo?.id] || DEFAULT_PATIENT_LOCATION_STATE;
  }, [userInfo]);

  const isForVideoCall = useMemo(() => {
    return (
      values.find((item) => item.form_question_id === 20)?.value.caseType === 'video_call_instant' ||
      values.find((item) => item.form_question_id === 20)?.value.caseType === 'video_call_scheduled' ||
      values.find((item) => item.form_question_id === 20)?.value.caseType === CaseType.phone_call_scheduled
    );
  }, [state]);

  const { asyncCaseEnabled } = useOrganizationFlags(patientState);

  if (
    (userInfo?.role.name === UserRoleName.Patient ||
      userInfo?.role.name === UserRoleName.OrganizationAdmin ||
      userInfo?.role.name === UserRoleName.SuperAdmin ||
      userInfo?.role.name === UserRoleName.Admin) &&
    (asyncCaseEnabled || isForVideoCall)
  ) {
    return (
      <div>
        <SelectNeeds />
      </div>
    );
  }
  return <Navigate to="/dashboard" />;
};

export default SelectNeedsValidation;
