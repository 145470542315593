/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

import { InsuranceCarrier, listInsuranceCarriers } from '../services/identity/listInsuranceCarriers';
import { displayErrorDetails } from '../Utils/displayError';

export default function useInsuranceCarriers(): InsuranceCarrier[] {
  const [insuranceCarriers, setInsuranceCarriers] = useState<InsuranceCarrier[]>([]);

  useEffect(() => {
    listInsuranceCarriers()
      .then((data) => {
        setInsuranceCarriers(data);
      })
      .catch(displayErrorDetails);
  }, []);

  return insuranceCarriers;
}
