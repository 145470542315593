import { client } from '../client';
import { Address, UserProfile } from './types/UserProfile';

export interface UpdateMe {
  address?: Address;
  phone_number?: string | null;
  email_opt_in?: boolean;
  sms_opt_in?: boolean;
}

export const updateMe = async (payload: UpdateMe, user_id: number): Promise<UserProfile> => {
  const purifiedPayload = {
    address: payload.address,
    phone_number: payload.phone_number,
    email_opt_in: payload.email_opt_in,
    sms_opt_in: payload.sms_opt_in,
  };

  const { data } = await client.patch<UserProfile>(`/users/${user_id}`, purifiedPayload);

  return data;
};
