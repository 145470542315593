import React from 'react';

import { useScreenShareParticipant, useVideoContext } from '../../hooks';

interface MainParticipantInfoProps {
  children: React.ReactNode;
}

const MainParticipantInfo = ({ children }: MainParticipantInfoProps): JSX.Element => {
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  return (
    <div
      style={{
        height: isRemoteParticipantScreenSharing ? '85%' : '100%',
        position: 'relative',
        width: '100%',
        display: isRemoteParticipantScreenSharing ? 'flex' : '',
        justifyContent: isRemoteParticipantScreenSharing ? 'center' : '',
      }}
      data-cy-main-participant
    >
      {children}
    </div>
  );
};

export default MainParticipantInfo;
