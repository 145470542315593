// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UBbTjRp4SVqI808qN81j{position:initial;top:16px;left:16px;width:720px;height:480px;min-height:280px;min-width:20vw;max-width:100vw;max-height:100vh;border:0px solid #000;box-sizing:border-box}.idDsKtTZa6lhps3TJXYZ{height:100%;position:relative}.ferUmABevMMRbVaeau0K{padding:4px;background-color:#e2e0e0}.X8dz134PqLCNoC0Y2UBR{padding:4px}@media screen and (max-width: 992px){.UBbTjRp4SVqI808qN81j{height:100vh !important}.ferUmABevMMRbVaeau0K{padding:4px;background-color:#e2e0e0}.X8dz134PqLCNoC0Y2UBR{padding:4px}}@media screen and (max-width: 992px)and (max-width: 992px){.UBbTjRp4SVqI808qN81j{height:100vh !important}}`, "",{"version":3,"sources":["webpack://./src/brands/default/Dashboard/ConsultationPage/Modals/VideoModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,QAAA,CACA,SAAA,CACA,WAAA,CACA,YAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,qBAAA,CACA,qBAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CAGF,sBACE,WAAA,CACA,wBAAA,CAGF,sBACE,WAAA,CAGF,qCACE,sBACE,uBAAA,CAGF,sBACE,WAAA,CACA,wBAAA,CAGF,sBACE,WAAA,CAAA,CAGF,2DACE,sBACE,uBAAA,CAAA","sourcesContent":[".panel {\n  position: initial;\n  top: 16px;\n  left: 16px;\n  width: 720px;\n  height: 480px;\n  min-height: 280px;\n  min-width: 20vw;\n  max-width: 100vw;\n  max-height: 100vh;\n  border: 0px solid black;\n  box-sizing: border-box;\n}\n\n.panelContainer {\n  height: 100%;\n  position: relative;\n}\n\n.panelHeader {\n  padding: 4px;\n  background-color: #e2e0e0;\n}\n\n.panelContent {\n  padding: 4px;\n}\n\n@media screen and (max-width: 992px) {\n  .panel {\n    height: 100vh !important;\n  }\n\n  .panelHeader {\n    padding: 4px;\n    background-color: #e2e0e0;\n  }\n\n  .panelContent {\n    padding: 4px;\n  }\n\n  @media screen and (max-width: 992px) {\n    .panel {\n      height: 100vh !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `UBbTjRp4SVqI808qN81j`,
	"panelContainer": `idDsKtTZa6lhps3TJXYZ`,
	"panelHeader": `ferUmABevMMRbVaeau0K`,
	"panelContent": `X8dz134PqLCNoC0Y2UBR`
};
export default ___CSS_LOADER_EXPORT___;
