/* eslint-disable @typescript-eslint/naming-convention */
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { isValidAddress, isValidEmail, isValidName, isValidZipCode } from '../../../../../Utils/validation';
import { atLeastOneFieldNotEmpty } from '../Patient/validationSchema';

export const validationSchema = Yup.object().shape({
  profile_img: Yup.string(),
  first_name: Yup.string().test('test-name', 'Enter Valid First Name', (value: any) => {
    if (value && !isValidName(value)) {
      return false;
    }
    return true;
  }),
  middle_name: Yup.string().test('test-name', 'Enter Valid Last Name', (value: any) => {
    if (value && !isValidName(value)) {
      return false;
    }
    return true;
  }),
  email: Yup.string()
    .required('Email is required')
    .test('test-name', 'Enter Valid Email', (value: any) => {
      if (value && !isValidEmail(value)) {
        return false;
      }
      return true;
    }),
  last_name: Yup.string().test('test-name', 'Enter Valid Last Name', (value: any) => {
    if (value && !isValidName(value)) {
      return false;
    }
    return true;
  }),
  language: Yup.array(),
  phone_number: Yup.string().test('test-name', 'Enter Valid Phone Number', (value: any) => {
    if (value && !isValidPhoneNumber(value)) {
      return false;
    }
    return true;
  }),
  address1: Yup.string().test(
    'test-name',
    'Valid Address is required when at least one Address field is provided',
    function () {
      const { parent } = this;
      const { address1, address2, city, state, zip_code } = parent as any;
      if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
        return !!address1 && isValidAddress(address1);
      }
      return true;
    },
  ),
  address2: Yup.string(),
  city: Yup.string().test('test-required', 'City is required when at least one Address field is provided', function () {
    const { parent } = this;
    const { address1, address2, city, state, zip_code } = parent as any;
    if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
      return !!city;
    }
    return true;
  }),
  state: Yup.string().test(
    'test-required',
    'State is required when at least one Address field is provided',
    function () {
      const { parent } = this;
      const { address1, address2, city, state, zip_code } = parent as any;
      if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
        return !!state;
      }
      return true;
    },
  ),
  zip_code: Yup.string().test(
    'test-name',
    'Valid Zip Code is required when at least one Address field is provided',
    function () {
      const { parent } = this;
      const { address1, address2, city, state, zip_code } = parent as any;
      if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
        return !!zip_code && isValidZipCode(zip_code);
      }
      return true;
    },
  ),
  biography: Yup.string(),
});
