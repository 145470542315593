import React, { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import { ICase } from '../../../../services/cases/types/ICase';
import { AttachmentNoteState } from '../../../../services/forms/types/ImageNote';
import { deleteOneReportMedia } from '../../../../services/oneReport/deleteOneReportMedia';
import styles from './styles.module.scss';

interface Modal {
  setOpenModal: Dispatch<SetStateAction<AttachmentNoteState>>;
  media_name: string;
  media_id: string;
  oneReportId: string;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
}

const RemoveOneReportAttachment = ({
  setOpenModal,
  media_name,
  media_id,
  oneReportId,
  thisCase,
  setCase,
}: Modal): JSX.Element => {
  const removeMedia = async (): Promise<void> => {
    const updatedOneReport = await deleteOneReportMedia(oneReportId, media_id);
    setCase({
      ...thisCase,
      one_report: updatedOneReport,
    });
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    removeMedia();
    setOpenModal({ isOpen: false, media_name: '', media_id: '' });
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.modalCloseIcon}>
          <button
            type="button"
            onClick={() => setOpenModal({ isOpen: false, media_name: '', media_id: '' })}
            aria-label="Close Modal"
          >
            <AiFillCloseCircle />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <span>{media_name}</span>
              <span>Are you sure that you want to remove this attachment?</span>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button type="button" onClick={() => setOpenModal({ isOpen: false, media_name: '', media_id: '' })}>
              No
            </button>
            <button type="submit">Yes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RemoveOneReportAttachment;
