import { selectAuth } from '@brands/store/selectors/auth';
import { openModal } from '@brands/store/slices/modalSlice';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useMemo } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import ProfileImage from '../../../../Components/ProfileImage/ProfileImage';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { Language, UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { getGenderName } from '../../../../Utils/getGenderName';
import { pronounsOptions } from '../../../../Utils/selectButtonOptions';
import ReviewProfileHeader from '../../Components/ReviewProfileHeader';
import ReviewProviderCases from '../../Provider/ReviewProviderCases';
import styles from '../../styles.module.scss';

type ReadOnlyProfileProps = {
  userId: string;
  onEditProfile: () => void;
  setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  image: string | undefined;
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  reviewProfile: boolean | undefined;
  resetEmailSent: boolean;
  setChangeUserStatusFlag: React.Dispatch<React.SetStateAction<string>>;
  setResetPasswordConfirmation: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      userState: string;
    }>
  >;
};

const ReadOnlyProfile = ({
  userId,
  onEditProfile,
  setImage,
  image,
  thisUser,
  setThisUser,
  reviewProfile,
  resetEmailSent,
  setChangeUserStatusFlag,
  setResetPasswordConfirmation,
}: ReadOnlyProfileProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const userRole = useMemo(() => {
    if (!userInfo) {
      return null;
    }

    return userInfo.role.name;
  }, [userInfo]);
  const dispatch = useDispatch();
  const selectedGender = useMemo(() => {
    if (thisUser?.basic_info) {
      const selectedOption = getGenderName(thisUser?.basic_info.gender);

      if (selectedOption) {
        return selectedOption;
      }

      return thisUser?.basic_info.gender;
    }

    return ' ';
  }, [thisUser]);

  const selectedPronouns = useMemo(() => {
    if (thisUser?.basic_info) {
      const selectedOption = pronounsOptions.find((option) => option.value === thisUser?.basic_info.pronouns);

      if (selectedOption) {
        return selectedOption.label;
      }

      return thisUser?.basic_info.pronouns;
    }

    return ' ';
  }, [thisUser]);

  const fullAddress = useMemo(() => {
    if (thisUser?.address) {
      const { address1, address2, city, state, zip_code: zipCode } = thisUser.address;
      return `${address1}${address2 ? ` , ${address2}` : ''}, ${city}, ${state} ${zipCode}`;
    }

    return ' ';
  }, [thisUser]);

  const languageList = useMemo(() => {
    if (thisUser && thisUser.basic_info && thisUser.basic_info.languages && thisUser.basic_info.languages.length > 0) {
      return thisUser.basic_info.languages
        .map((language: Language | string) => {
          if (typeof language === 'string') {
            return language;
          }
          return language.name;
        })
        .join(', ');
    }
    return ' ';
  }, [thisUser]);

  const biography = useMemo(() => {
    if (thisUser?.care_advisor_profile?.biography) {
      return thisUser.care_advisor_profile.biography;
    }

    return ' ';
  }, [thisUser]);

  const displayPhoneNumber = useMemo(() => {
    if (thisUser?.phone_number) {
      const phoneNumber = parsePhoneNumber(thisUser.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    }

    return '';
  }, [thisUser]);

  const onEditEmailAddress = (): void => {
    dispatch(openModal({ modal: 'updateEmailAddress' }));
  };

  return (
    <div className={styles.readOnlyProfileContainer}>
      {reviewProfile && (
        <ReviewProfileHeader
          thisUser={thisUser}
          setThisUser={setThisUser}
          resetEmailSent={resetEmailSent}
          setChangeUserStatusFlag={setChangeUserStatusFlag}
          setResetPasswordConfirmation={setResetPasswordConfirmation}
          userId={userId}
          userRole={thisUser.role.name}
          userOrganizations={thisUser.organizations}
        />
      )}
      <div className={styles.titleContainer}>
        <span className={styles.title}>Profile</span>
        <button className={styles.editBtn} type="button" onClick={onEditProfile}>
          Edit
          <FiEdit className={styles.editIcon} />
        </button>
      </div>
      <div className={styles.detailsContainer}>
        <ProfileImage
          reviewProfile={reviewProfile}
          id={userId}
          setImage={setImage}
          image={image}
          className={styles.profileImg}
          size="80px"
          isUpload={false}
        />
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Name</div>
          <div className={styles.value}>
            {thisUser?.basic_info?.first_name} {thisUser?.basic_info?.last_name}
          </div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Gender assigned at birth</div>
          <div className={styles.value}>{selectedGender}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Pronouns</div>
          <div className={styles.value}>{selectedPronouns}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Languages</div>
          <div className={styles.value}>{languageList}</div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>
            Email Address
            {(!reviewProfile ||
              (reviewProfile &&
                userRole !== UserRoleName.Admin &&
                userRole !== UserRoleName.SuperAdmin &&
                userRole !== UserRoleName.OrganizationAdmin)) && (
              <button className={styles.editBtn} type="button" onClick={onEditEmailAddress}>
                Edit
                <FiEdit className={styles.editIcon} />
              </button>
            )}
          </div>
          <div className={styles.value}>{thisUser?.email}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Phone Number</div>
          <div className={styles.value}>{displayPhoneNumber}</div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Address</div>
          <div className={styles.value}>{fullAddress}</div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Biography</div>
          <div className={styles.value} style={{ whiteSpace: 'unset' }}>
            {biography}
          </div>
        </div>
      </div>
      {reviewProfile && <ReviewProviderCases role="ca" providerId={Number(userId)} />}
    </div>
  );
};

export default ReadOnlyProfile;
