import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

interface Props {
  setChatOnScreen: (value: boolean) => void;
}

const VideoConversationDetail: React.FC<Props> = ({ setChatOnScreen }) => {
  if (!true) {
    return null;
  }
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={styles.closeButtonContainer} onClick={() => setChatOnScreen(false)}>
        <AiFillCloseCircle size="24px" /> Close
      </div>
    </div>
  );
};

export default VideoConversationDetail;
