import React from 'react';
import { LocalTrackPublication, LocalVideoTrack, RemoteTrackPublication, RemoteVideoTrack } from 'twilio-video';

import { useTrack } from '../../hooks';
import VideoTrack from '../VideoTrack/VideoTrack';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
}

type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

const Publication = ({ publication }: PublicationProps): JSX.Element | null => {
  const track = useTrack(publication);
  if (!track) return null;
  switch (track.kind) {
    case 'video':
      return <VideoTrack track={track as IVideoTrack} />;
    default:
      return null;
  }
};

export default Publication;
