/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import ProgressBar from '@brands/Components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from '@brands/hooks/useReduxHook';
import { CaseType } from '@brands/services/cases/types/ICase';
import { Pricings } from '@brands/services/identity/getPricings';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setForm } from '@brands/store/slices/patientFormSlice';
import React, { ReactElement } from 'react';
import { TbAmbulance } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import useWindowSize from '../../../../hooks/useWindowSize';
import emergencyIcon from '../../utils/icons/emergency.svg';
import notEmergencyIcon from '../../utils/icons/not-emergency.svg';
import styles from '../styles.module.scss';
import { SelectNeedsSubmit } from '../utils/types';

const cardDetails = [
  {
    title: 'I Have a Dental',
    subTitle: 'Emergency',
    icon: 'emergencyIcon',
    description: [
      'Moderate to severe tooth pain',
      'Abscess and/or swelling',
      'Bleeding or swollen gums',
      'Broken or chipped tooth or lost filling causing pain',
      'Trauma to the teeth (i.e. tooth knocked out)',
    ],
    url: 'select-needs',
    state: 'dental_emergency',
  },
  {
    title: 'I Have a Non-urgent ',
    subTitle: 'Dental Concern',
    icon: 'notEmergencyIcon',
    description: [
      'Dull or mild tooth pain/sensitivity',
      'Minor injury to the mouth, tongue, cheeks, or gums',
      'Loose filling or crown',
      'Dry mouth',
      'Expert advice and tips on dental products',
    ],
    url: 'select-dental-insurance',
    state: 'something_else',
  },
];

type CardProps = {
  title: string;
  subTitle: string;
  icon: string;
  description: string[];
  onClick: () => void;
  index: number;
};

const Card = ({ title, subTitle, icon, description, onClick, index }: CardProps): ReactElement => (
  <div className={`${styles.card}`}>
    <div className={styles.titleContainer}>
      <span className={styles.cardTitle}>{title}</span>
      <span className={styles.cardTitle}>{subTitle}</span>
    </div>
    <div
      className={`${styles.cardIcon} ${styles[icon]}`}
      style={{
        backgroundImage: `url(${icon === 'emergencyIcon' ? emergencyIcon : notEmergencyIcon})`,
      }}
    />
    <div className={`${styles.cardDescription} ${index === 0 && styles.paddingVertical} `}>
      <ul>
        {description.map((point, i) => (
          <li key={i}>{point}</li>
        ))}
      </ul>
    </div>
    <Button className={styles.cardButton} onClick={onClick}>
      Select
    </Button>
  </div>
);

const SelectAppointmentType = (): JSX.Element => {
  const screenSize = useWindowSize();
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const organizationPrices = useAppSelector(selectOrganizationPrices);
  const isPaymentRequired = currentOrganization.payment_options.length > 0;
  const currentOrganizationPrices = organizationPrices?.currentOrganizationPrices.find(
    (currentOrganizationPrise: Pricings) =>
      currentOrganizationPrise.case_types.includes(
        values.find((item) => item.form_question_id === 20)?.value.caseType as string,
      ),
  )?.amount;

  const onSubmit = (data: SelectNeedsSubmit): void => {
    if (!selectedPatient?.value) {
      toast.error('Please select a patient');
      return;
    }

    const payload = [...values!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 15);
    filteredPayload.push({
      form_question_id: 15,
      value: { ...data },
    });
    dispatch(setForm(filteredPayload));
    navigate('/describe-pain-level', {
      state: {
        caseType: 'video_call_scheduled',
        patientId: selectedPatient?.value,
      },
    });
    // navigate('/describe-pain-level', {
    //   state: {
    //     caseType: state ? values.find((item) => item.form_question_id === 20)?.value.caseType as string : '',
    //     userOrganization: state ? state.userOrganization : '',
    //     patientId: state ? state.patientId : '',
    //   },
    // });
  };
  const onBack = (): void => {
    navigate(
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
        CaseType.video_call_scheduled ||
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
          CaseType.phone_call_scheduled
        ? '/select-date-time'
        : isPaymentRequired && currentOrganizationPrices?.toString() !== '0'
        ? '/payment-information'
        : userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
        ? `/admin/review-profile/Patient/${currentPatient?.id}}/create-consultation-for`
        : '/select-patient',
    );
  };

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        overflow: 'hidden',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width < 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px', paddingBottom: '100px' }}
    >
      <div className={styles.selectTypeContainer}>
        <div className={`${styles.title} d-flex mt-3`}>What do you need help with today?*</div>
        <ProgressBar
          currentStep={1}
          totalSteps={4}
          containerStyle={{ marginBottom: '0' }}
          onBack={onBack}
          hideStepNumbers
        />
        <section className={styles.cardsContainer}>
          {cardDetails.map((card, index) => (
            <Card
              key={card.title}
              title={card.title}
              subTitle={card.subTitle}
              icon={card.icon}
              description={card.description}
              onClick={() =>
                onSubmit({
                  dental_emergency: card.state === 'dental_emergency' ? 'yes' : 'no',
                  something_else: card.state === 'something_else' ? 'yes' : 'no',
                })
              }
              index={index}
            />
          ))}
        </section>
        <div className={styles.containerFooter}>
          <span className={styles.footerTitle}>
            <TbAmbulance size={28} />
            When Should you go to the Emergency Room?
          </span>
          <div>
            <span className={styles.footerTitleHeader}>
              Although dental emergencies are rare, there are a few life-threatening situations that require immediate
              supervision. If you experience any of the below, please call 911 or go to your nearest emergency
              department:
            </span>
          </div>
          <div>
            <ul className={styles.dotList}>
              <li>Difficulty breathing</li>
              <li>Intense pain in your left arm that is spreading to your neck and/or jaw</li>
              <li>Intense pain behind the eyes and/or double vision</li>
              <li>Swelling/pressure that is preventing you from swallowing your spit</li>
              <li>Swelling/pressure behind the tongue moving it out of place</li>
              <li>Swelling/pressure from your mouth preventing you from opening your mouth</li>
              <li>Swelling from your mouth to your upper eyelid</li>
              <li>Fever or vomiting possibly caused by a dental infection</li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default SelectAppointmentType;
