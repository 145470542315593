export const defaultFormValues = {
  profile_img: '',
  first_name: '',
  last_name: '',
  email: '',
  organizations: {} as any,
  organization: {} as any,
  address1: '',
  city: '',
  state: '',
  zip_code: '',
};
