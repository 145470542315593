import React, { useEffect } from 'react';

const ZendeskLogout: React.FC = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('return_to')) {
      window.location.href = params.get('return_to') || '/';
    } else {
      window.location.href = '/';
    }
  }, []);

  return null;
};

export default ZendeskLogout;
