import { Organization } from '@brands/services/identity/types/UserProfile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  currentOrganization: Organization | null;
}

const initialState: AuthState = {
  currentOrganization: null,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<{ currentOrganization: Organization | null }>) => {
      return { currentOrganization: action.payload.currentOrganization };
    },
  },
});

export const { setOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
