import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';

export const getCurrentPatientState = (userId: number): string | null => {
  const currentState: string = useAppSelector(selectCurrentPatientState);
  return currentState[`${userId}`] || null;
};

export const getCurrentPState = (userId: number): string | null => {
  const currentStates = JSON.parse(localStorage.getItem('currentStates') || '{}');
  return currentStates[`${userId}`] || null;
};

export const setCurrentPatientState = (userId: number, state: string): void => {
  const currentStates = JSON.parse(localStorage.getItem('currentStates') || '{}');
  currentStates[`${userId}`] = state;
  localStorage.setItem('currentStates', JSON.stringify(currentStates));
};

export const DEFAULT_PATIENT_LOCATION_STATE = 'NY';
