import React from 'react';
import { LocalTrackPublication, Participant, RemoteTrackPublication, TrackPublication } from 'twilio-video';

import { usePublications } from '../../hooks';
import Publication from '../Publication/Publication';

interface ParticipantTracksProps {
  participant: Participant;
  enableScreenShare?: boolean;
}

const ParticipantTracks = ({ participant, enableScreenShare }: ParticipantTracksProps): JSX.Element => {
  const publications = usePublications(participant);
  let filteredPublications;

  if (enableScreenShare && publications.some((p: TrackPublication) => p.trackName.includes('screen'))) {
    filteredPublications = publications.filter(
      (p: TrackPublication) => p.trackName.includes('screen') || p.kind !== 'video',
    );
  } else {
    filteredPublications = publications.filter((p: TrackPublication) => !p.trackName.includes('screen'));
  }
  return (
    <>
      {filteredPublications.map((publication: TrackPublication) => (
        <Publication
          key={publication.kind}
          publication={publication as LocalTrackPublication | RemoteTrackPublication}
        />
      ))}
    </>
  );
};

ParticipantTracks.defaultProps = {
  enableScreenShare: false,
};

export default ParticipantTracks;
