import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { closeAccount } from '../../../services/identity/closeAccount';
import { CloseAccountReasons, getCloseReasons } from '../../../services/identity/getCloseReasons';
import { displayErrorDetails } from '../../../Utils/displayError';
import styles from './styles.module.scss';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
}
type DeleteAccountFormSubmit = {
  deleteAccountReasonId: number;
  deleteAccountText: string;
};

const DeleteAccount = ({ setOpenModal }: IModal): JSX.Element => {
  const navigate = useNavigate();
  const goToHomePage = useCallback(() => navigate('/'), [navigate]);
  const [checkedState, setCheckedState] = useState('');
  const [generalQuestions, setGeneralQuestions] = useState<CloseAccountReasons[]>([]);

  useEffect(() => {
    const fetchReasons = async (): Promise<void> => {
      const reasons = await getCloseReasons();
      setGeneralQuestions(reasons);
    };
    fetchReasons();
  }, []);

  const onSubmit = async (data: DeleteAccountFormSubmit): Promise<void> => {
    try {
      await closeAccount({
        close_reason_id: data.deleteAccountReasonId,
        close_reason_text: data.deleteAccountText,
      });
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    goToHomePage();
  };

  const validationSchema = Yup.object().shape({
    deleteAccountReasonId: Yup.string().required('A radio option is required'),
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<DeleteAccountFormSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    trigger();
  }, []);
  return (
    <div className={styles.modalBackground} onClick={() => setOpenModal(false)}>
      <div className={`${styles.deleteAccountModal} ${styles.modalContainer}`} onClick={(e) => e.stopPropagation()}>
        <div className={styles.body}>
          <div className={styles.padding} style={{ marginBottom: '10px' }}>
            <p>
              We are sorry to <span className={styles.noWrap}>see you go!</span>
            </p>
            <span className={styles.subTitle}>Tell us why you’re closing your account:</span>
          </div>
          <div className={`${styles.modalBody} flex-pf`}>
            <form className="px-lg-3" onSubmit={handleSubmit(onSubmit)}>
              {generalQuestions.map((element) => (
                <div className={`row ${styles.padding}`} key={element.id}>
                  <div className="col col-pl-0">
                    <div className={styles.eduFormCheck}>
                      <input
                        {...register('deleteAccountReasonId')}
                        className={`form-check-input ${styles.eduFormCheckInput}`}
                        type="radio"
                        value={element.id}
                        id={element.id.toString()}
                        checked={checkedState === element.id.toString()}
                        onChange={() => {
                          setCheckedState(element.id.toString());
                          setValue('deleteAccountReasonId', element.id);
                          trigger();
                        }}
                      />
                      <label className={`form-check-label ${styles.eduRadioLabel}`} htmlFor={element.id.toString()}>
                        {element.text}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              <div className="row">
                <div className="col col-pl-0">
                  <div className={`form-check-label ${styles.ohrfRadioLabel} w-100 py-3`} />
                  <div className="validate-input col-12 mt-0 h-100">
                    <textarea
                      {...register('deleteAccountText')}
                      className={`form-control ${styles.formControl} ${styles.textareaOhrf}`}
                      id={generalQuestions.find((reason) => reason.text === 'Something else')?.id.toString()}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.modalButtons} style={{ flexDirection: 'row', marginTop: 'auto' }}>
                <button
                  type="submit"
                  className={`${
                    Object.keys(errors).length !== 0 ? styles.login100FormBtnDisabled : styles.login100FormBtn
                  } p-0`}
                  disabled={Object.keys(errors).length !== 0}
                >
                  Submit
                </button>
                <button type="button" id="cancelBtn" onClick={() => setOpenModal(false)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteAccount;
