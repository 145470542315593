import { checkValue } from './validation';

export const checkAndSetDate = (e: React.ChangeEvent<HTMLInputElement>): string => {
  const input = e.target.value;
  const n: any = e.nativeEvent;
  if (n.inputType === 'deleteContentBackward') return input;
  const values = input.split('/').map((v) => v.replace(/\D/g, ''));
  if (values[0]) values[0] = checkValue(values[0], 12);
  if (values[1]) values[1] = checkValue(values[1], 31);
  const output = values.map((v, i) => (v.length == 2 && i < 2 ? `${v} / ` : v));
  return output.join('').substr(0, 14).replace(/ /g, '');
};

export const checkAndSetprescription_date = (input: string): string => {
  if (input) {
    const values = input?.split('/').map((v) => v.replace(/\D/g, ''));
    if (values[0]) values[0] = checkValue(values[0], 12);
    if (values[1]) values[1] = checkValue(values[1], 31);
    const output = values.map((v, i) => (v.length == 2 && i < 2 ? `${v} / ` : v));
    return output.join('').substr(0, 14).replace(/ /g, '');
  } else {
    return '';
  }
};

export const checkAndSetDateWithoutDays = (input: string): string => {
  const values = input.split('/').map((v) => v.replace(/\D/g, ''));
  if (values[0]) values[0] = checkValue(values[0], 12);
  const output = values.map((v, i) => (v.length == 2 && i < 1 ? `${v} / ` : v));
  return output.join('').substr(0, 14).replace(/ /g, '');
};

export const dateAutoSlashes = (e: React.ChangeEvent<HTMLInputElement>, key: string, setValue: any): void => {
  const { value } = e.target;
  setValue(key, value, { shouldValidate: true });
  if (key === 'expiryDate') {
    return setValue(key, checkAndSetDateWithoutDays(value), { shouldValidate: true });
  }
  return setValue(key, checkAndSetDate(e), { shouldValidate: true });
};
