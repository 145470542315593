import { CaseType } from '@brands/services/cases/types/ICase';
import { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';

import { getPrimaryColor, getSecondary, getSecondaryColor } from './getPrimaryColor';
import { Option } from './selectOptions';

export type TextAlign = 'end' | 'left' | 'center' | 'right' | 'start' | undefined;

export const customStyles = {
  overlay: {
    backgroundColor: '#f2f2f2',
  },
  content: {
    backgroundColor: '#f2f2f2',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    width: '100%',
    height: '100vh',
  },
};

export const customStylesDraggable = {
  overlay: {
    backgroundColor: getSecondaryColor(),
    top: '76px',
  },
  content: {
    maxWidth: '100vw',
    maxHeight: '100vh',
    minWidth: '20vw',
    minHeight: '280px',
    overflow: 'hidden',
    '@media only screen and (max-width: 768px)': {
      minWidth: '300px',
    },
  },
};

export const customStylesForgotPassword = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1200px',
    maxHeight: '100vh',
    paddingTop: '40px',
    paddingBottom: '40px',
    overflow: 'hidden',
  },
};

export const customStylesVirtualBackground = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: '999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '800px',
    maxHeight: '800px',
    paddingTop: '40px',
    paddingBottom: '40px',
    overflow: 'hidden',
    zIndex: '999',
  },
};

export const customStylesDashboard = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: '999',
    minHeight: '300px',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    borderRadius: '28px',
    width: 'unset',
    height: 'unset',
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: '999',
  },
};

export const selectCustomStyles: StylesConfig<Option, false> = {
  control: (provided: CSSObjectWithLabel, { isDisabled }) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '5px',
    position: 'relative',
    opacity: isDisabled ? 0.5 : 1,
    minHeight: '35px',
    '&:hover': {
      border: 'none',
    },
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: getSecondaryColor(),
    fontSize: '12px',
    marginLeft: '0px',
    whiteSpace: 'nowrap',
    fontWeight: 300,
    '@media only screen and (max-width: 540px)': {
      fontSize: '8px',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingLeft: '0px',
    paddingRight: '0px',
    height: '30px',
    width: '70%',
    flex: 'none',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '0px',
    width: 'fit-content',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: getSecondaryColor(),
    fontSize: '6px',
    width: '32px',
    height: '30px',
    marginLeft: '-7px',
    '@media only screen and (max-width: 540px)': {
      fontSize: '3px',
      width: '23px',
    },
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    fontSize: '14px',
    backgroundColor: state.isSelected ? getSecondary() : '',
    borderColor: getSecondary(),
    '@media only screen and (max-width: 540px)': {
      fontSize: '12px',
    },
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '0px',
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    position: 'absolute',
    top: '-10px',
    borderColor: getSecondary(),
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
};

export const selectSortByStyles: StylesConfig<Option, false> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    justifyContent: 'flex-start',
    paddingLeft: '5px',
    position: 'relative',
    width: '100%',
    minHeight: '35px',
    '&:hover': {
      border: 'none',
    },
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#1AA1E0',
    fontSize: '12px',
    marginLeft: '0px',
    fontWeight: 300,
    '@media only screen and (max-width: 540px)': {
      fontSize: '12px',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingLeft: '0px',
    paddingRight: '0px',
    width: 'auto',
    flex: 'none',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '0px',
    width: 'fit-content',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#1AA1E0',
    fontSize: '10px',
    width: '32px',
    height: '30px',
    marginLeft: '-7px',
    position: 'relative',
    top: '-3px',
    '@media only screen and (max-width: 540px)': {
      fontSize: '3px',
      width: '30px',
    },
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    fontSize: '14px',
    backgroundColor: state.isSelected ? getSecondary() : '',
    borderColor: getSecondary(),
    '@media only screen and (max-width: 540px)': {
      fontSize: '12px',
    },
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '0px',
    color: '#1AA1E0',
    fontSize: '12px',
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    position: 'absolute',
    top: '-10px',
    borderColor: getSecondary(),
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
};
export const consultationSelectStyles = {
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    height: '30px',
    fontSize: '10px',
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    fontSize: '10px',
    borderColor: 'none',
    minHeight: '30px',
    borderRadius: '5px 0 0 5px',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: '30px',
    minHeight: '30px',
    padding: '0 4px',
    border: '1px solid #E5E5E5',
    borderRadius: '5px 0 0 5px',
    borderColor: '#E5E5E5',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: '30px',
    minHeight: '30px',
    width: '30px',
    backgroundColor: getPrimaryColor(),
    borderRadius: '0 4px 4px 0',
    color: '#fff',
    padding: '0',
    div: {
      padding: '0',
      color: '#fff',
      width: '100%',
      justifyContent: 'center',
      '&:hover': {
        color: '#fff',
      },
    },
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#fff',
    padding: '0',
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    fontSize: '10px',
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    backgroundColor: state.isSelected ? getSecondary() : '',
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: 'fit-content',
    minWidth: 'calc(100% - 30px)',
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    maxWidth: '150%',
  }),
};

export const consultationPharmacySectionSelectStyles = {
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    height: '35px',
    fontSize: '10px',
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    fontSize: '10px',
    borderColor: 'none',
    minHeight: '35px',
    borderRadius: '5px 0 0 5px',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: '35px',
    minHeight: '35px',
    padding: '0 4px',
    border: '1px solid #E5E5E5',
    borderRadius: '5px 0 0 5px',
    borderColor: '#E5E5E5',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: '35px',
    minHeight: '35px',
    width: '35px',
    backgroundColor: getPrimaryColor(),
    borderRadius: '0 4px 4px 0',
    color: '#fff',
    padding: '0',
    div: {
      padding: '0',
      color: '#fff',
      width: '100%',
      height: '27px',
      justifyContent: 'center',
      '&:hover': {
        color: '#fff',
      },
    },
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#fff',
    padding: '0',
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    fontSize: '10px',
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    backgroundColor: state.isSelected ? getSecondary() : '',
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: 'fit-content',
    minWidth: 'calc(100% - 30px)',
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    maxWidth: '150%',
  }),
};

export const consultationSelectStylesWithoutIndicator = {
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    height: '30px',
    fontSize: '10px',
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    fontSize: '10px',
    borderColor: 'none',
    minHeight: '30px',
    borderRadius: '5px',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: '30px',
    minHeight: '30px',
    padding: '0 4px',
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    borderColor: '#E5E5E5',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: '0px',
    width: '0px',
    display: 'none',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#fff',
    padding: '0',
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    fontSize: '10px',
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    backgroundColor: state.isSelected ? getSecondary() : '',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
};

export const profileSelectStyles = {
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    height: '50px',
    fontSize: '16px',
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    fontSize: '20px',
    borderColor: 'none',
    borderRadius: '5px 0 0 5px',
    minHeight: '35px',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: 'auto',
    minHeight: '50px',
    padding: '0 12px',
    border: '1px solid #E5E5E5',
    borderRadius: '5px 0 0 5px',
    borderColor: '#E5E5E5',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    height: 'auto',
    minHeight: '50px',
    width: '50px',
    backgroundColor: getPrimaryColor(),
    borderRadius: '0 4px 4px 0',
    color: '#fff',
    padding: '0',
    div: {
      padding: '0',
      color: '#fff',
      width: '100%',
      justifyContent: 'center',
      '&:hover': {
        color: '#fff',
      },
    },
    // Hide the dropdown indicator when isDisabled is true
    display: state.isDisabled ? 'none' : provided.display,
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '16px',
    color: getSecondaryColor(),
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#fff',
    padding: '0',
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    fontSize: '16px',
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    backgroundColor: state.isSelected ? getSecondary() : '',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
};

export const consultTimeMultiSelectStyles = {
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    height: '30px',
    fontSize: '10px',
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    fontSize: '10px',
    borderColor: 'none',
    minHeight: '30px',
    borderRadius: '5px 0 0 5px',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: '30px',
    minHeight: '30px',
    padding: '0 4px',
    border: '1px solid #E5E5E5',
    borderRadius: '5px 0 0 5px',
    borderColor: '#E5E5E5',
    textAlign: 'center' as TextAlign,
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: '30px',
    minHeight: '30px',
    width: '30px',
    backgroundColor: getPrimaryColor(),
    borderRadius: '0 4px 4px 0',
    color: '#fff',
    padding: '0',
    div: {
      padding: '0',
      color: '#fff',
      width: '100%',
      justifyContent: 'center',
      '&:hover': {
        color: '#fff',
      },
    },
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#fff',
    padding: '0',
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    fontSize: '10px',
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    backgroundColor: state.isSelected ? getSecondary() : '',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
};

export const multiSelectCustomStyles: StylesConfig<Option, true> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '5px',
    minHeight: '35px',
    '&:hover': {
      border: 'none',
    },
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: getSecondaryColor(),
    fontSize: '12px',
    marginLeft: '0px',
    fontWeight: 300,
    '@media only screen and (max-width: 540px)': {
      fontSize: '9px',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingLeft: '0px',
    paddingRight: '0px',
    flex: 'none',
    width: '70%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '0px',
    width: 'fit-content',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    fontSize: '14px',
    backgroundColor: state.isSelected ? getSecondary() : '',
    '@media only screen and (max-width: 540px)': {
      fontSize: '12px',
    },
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '0px',
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '12px',
    '@media only screen and (max-width: 540px)': {
      fontSize: '9px',
    },
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '12px',
    '@media only screen and (max-width: 540px)': {
      fontSize: '9px',
    },
  }),
};

export const profileCardsMultiSelectStyles = {
  ...multiSelectCustomStyles,
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    paddingLeft: '0px',
    paddingRight: '0px',
    flex: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
    position: 'absolute',
    top: 'calc(100% + 1px)',
  }),
};

export const multiSelectLanguageCustomStyles: StylesConfig<Option, true> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '5px',
    minHeight: '35px',
    '&:hover': {
      border: 'none',
    },
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: getSecondaryColor(),
    fontSize: '12px',
    marginLeft: '0px',
    fontWeight: 300,
    '@media only screen and (max-width: 540px)': {
      fontSize: '9px',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingLeft: '0px',
    paddingRight: '0px',
    flex: 'none',
    width: '80%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    paddingBottom: '0px',
    flexWrap: 'inherit',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: getSecondaryColor(),
    fontSize: '6px',
    width: '32px',
    height: '30px',
    marginLeft: '-7px',
    '@media only screen and (max-width: 540px)': {
      fontSize: '3px',
      width: '23px',
    },
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '0px',
    width: 'fit-content',
    fontSize: '12px',
    color: getSecondaryColor(),
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    color: state.isSelected ? '#fff' : getSecondaryColor(),
    fontSize: '14px',
    backgroundColor: state.isSelected ? getSecondary() : '',
    '@media only screen and (max-width: 540px)': {
      fontSize: '12px',
    },
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '0px',
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'white',
    border: '1px solid',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '0',
    padding: '3px 0px',
    color: '2c3e50',
    fontSize: '12px',
    '@media only screen and (max-width: 540px)': {
      fontSize: '9px',
    },
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '12px',
    padding: '4px',
    paddingLeft: '0px',
    '@media only screen and (max-width: 540px)': {
      fontSize: '9px',
    },
  }),
};

export const multiSelectLanguageCustomStylesDisabled: StylesConfig<Option, true> = {
  ...multiSelectLanguageCustomStyles,
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    pointerEvents: 'none',
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '12px',
    padding: '4px',
    '@media only screen and (max-width: 540px)': {
      fontSize: '9px',
    },
  }),
  multiValueRemove: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
};

export const selectCommonCustomStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #D0D0D0',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    height: '100%',
    minHeight: '30px',
    background: 'white',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
    borderRadius: '0px 4px 4px 0px',
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '60%',
    fontSize: '12px',
    padding: '0 10px 0 0',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#8E97A0',
    fontSize: '12px',
    marginLeft: '0px',
  }),
  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
    color: getSecondaryColor(),
  }),
};

export const selectCommonCustomStylesSmall: StylesConfig<Option, false, GroupBase<Option>> = {
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    height: 'fit-content',
    fontSize: '14px',
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: 'none',
    borderRadius: 'unset',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    paddingLeft: '0px',
    height: 'fit-content',
    width: '100%',
    margin: '0px',
    outline: 'none',
    boxShadow: 'none',
    '&:focus': {
      border: 'none',
      outline: 'none !important',
    },
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '14px',
    color: getSecondaryColor(),
    padding: '0',
    border: 'none',
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '14px',
    color: getSecondaryColor(),
    marginLeft: '0px',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    fontSize: '14px',
    color: getSecondaryColor(),
    textAlign: 'center',
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    fontSize: '14px',
    color: getSecondaryColor(),
    textAlign: 'center',
    width: '100%',
    paddingLeft: '20%',
    display: 'flex',
    justifyContent: 'center',
    '::after': {
      content: 'none',
      width: '100%',
    },
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: 9999,
    minWidth: '92px',
    fontSize: '14px',
  }),
};

export const selectCommonCustomStylesLarge: StylesConfig<Option, false, GroupBase<Option>> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #D0D0D0',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    minHeight: '50px',
    background: 'white',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
    borderRadius: '0px 4px 4px 0px',
    color: 'white',
    height: 'auto',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&:hover': {
      color: 'white',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '60%',
    fontSize: '16px',
    padding: '0 10px 0 0',
    height: 'auto',
    minHeight: '50px',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#8E97A0',
    fontSize: '16px',
    marginLeft: '0px',
  }),
  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
    color: getSecondaryColor(),
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: 'auto',
    minHeight: '50px',
    width: '50px',
    backgroundColor: getPrimaryColor(),
    borderRadius: '0 4px 4px 0',
    color: '#fff',
    padding: '0',
    div: {
      padding: '0',
      color: '#fff',
      width: '100%',
      justifyContent: 'center',
      '&:hover': {
        color: '#fff',
      },
    },
  }),
};

export const selectCommonCustomStylesSlim: StylesConfig<Option, false, GroupBase<Option>> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #D0D0D0',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    minHeight: '30px',
    background: 'white',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
    borderRadius: '0px 4px 4px 0px',
    color: 'white',
    height: 'auto',
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&:hover': {
      color: 'white',
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '60%',
    fontSize: '12px',
    padding: '0 10px 0 0',
    height: 'auto',
    minHeight: '30px',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#8E97A0',
    fontSize: '12px',
    marginLeft: '0px',
  }),
  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
    color: getSecondaryColor(),
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    height: 'auto',
    minHeight: '30px',
    width: '50px',
    backgroundColor: getPrimaryColor(),
    borderRadius: '0 4px 4px 0',
    color: '#fff',
    padding: '0',
    div: {
      padding: '0',
      color: '#fff',
      width: '100%',
      justifyContent: 'center',
      '&:hover': {
        color: '#fff',
      },
    },
  }),
};

export const multiSelectCommonCustomStyles: StylesConfig<Option, true> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #D0D0D0',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    minHeight: '30px',
    background: 'white',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
    borderRadius: '0px 4px 4px 0px',
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '60%',
    padding: '0 10px 0 0',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#8E97A0',
    fontSize: '12px',
    marginLeft: '2px',
  }),
  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
    color: getSecondaryColor(),
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'white',
    border: '1px solid',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '0',
    padding: '3px 0px',
    color: '2c3e50',
    fontSize: '12px',
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '12px',
    padding: '4px',
    paddingLeft: '0px',
  }),
};

export const multiSelectCommonCustomStylesFilter: StylesConfig<Option, true> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #D0D0D0',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    minHeight: '30px',
    background: 'white',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
    borderRadius: '0px 4px 4px 0px',
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '60%',
    padding: '0 10px 0 0',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#8E97A0',
    fontSize: '12px',
    marginLeft: '2px',
  }),
  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
    color: getSecondaryColor(),
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'white',
    border: '1px solid',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '0',
    padding: '3px 0px',
    color: '2c3e50',
    fontSize: '12px',
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '2px',
    padding: '4px',
    paddingLeft: '0px',
  }),
};

export const getMultiSelectCommonCustomStyles = (caseType: string): StylesConfig<Option, true> => ({
  ...multiSelectCommonCustomStyles,
  multiValueLabel: (provided: CSSObjectWithLabel, props) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '12px',
    padding: '4px',
    paddingLeft:
      (props.data.value === 'D9995' &&
        (caseType === 'video_call_scheduled' ||
          caseType === 'video_call_instant' ||
          caseType === CaseType.phone_call_scheduled)) ||
      (props.data.value === 'D9996' &&
        caseType !== 'video_call_scheduled' &&
        caseType !== 'video_call_instant' &&
        caseType !== CaseType.phone_call_scheduled)
        ? '4px'
        : '0px',
  }),
  multiValueRemove(base, props) {
    return {
      ...base,
      display:
        (props.data.value === 'D9995' &&
          (caseType === 'video_call_scheduled' ||
            caseType === 'video_call_instant' ||
            caseType === CaseType.phone_call_scheduled)) ||
        (props.data.value === 'D9996' &&
          caseType !== 'video_call_scheduled' &&
          caseType !== 'video_call_instant' &&
          caseType !== CaseType.phone_call_scheduled)
          ? 'none'
          : base.display,
    };
  },
});

export const multiSelectCommonCustomStylesLarge: StylesConfig<Option, true> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #D0D0D0',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    minHeight: '50px',
    background: 'white',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
    borderRadius: '0px 4px 4px 0px',
    color: 'white',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: '-1px',
    '&:hover': {
      color: 'white',
    },
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '60%',
    padding: '0 10px 0 0',
    minHeight: '50px',
    opacity: '1',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#8E97A0',
    fontSize: '16px',
    marginLeft: '2px',
  }),
  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
    color: getSecondaryColor(),
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'white',
    border: '1px solid',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '0',
    padding: '3px 0px',
    color: '2c3e50',
    fontSize: '16px',
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '16px',
    padding: '4px',
    paddingLeft: '0px',
  }),
};
export const multiSelectCommonWithCommaCustomStylesLargeSelectDateAndTime: StylesConfig<Option, true> = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #D0D0D0',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    minHeight: '30px',
    background: 'white',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
    borderRadius: '0px 4px 4px 0px',
    color: 'white',
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: '-1px',
    '&:hover': {
      color: 'white',
    },
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: `${getPrimaryColor()} 0% 0% no-repeat padding-box`,
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '60%',
    minHeight: '30px',
    fontSize: '12px',
    alignSelf: 'center',
    padding: '0px 16px 0 0',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#8E97A0',
    fontSize: '16px',
    marginLeft: '2px',
  }),
  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
    color: getSecondaryColor(),
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'white',
    border: '1px solid',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '0',
    padding: '3px 0px',
    color: '2c3e50',
    fontSize: '26px',
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '2c3e50',
    fontSize: '26px',
    padding: '4px',
    paddingLeft: '0px',
  }),
};

export const selectDateModalCustomStyles = {
  overlay: {
    backgroundColor: 'RGBA(0, 0, 0, 1)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '11px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'fit-content',
    backgroundColor: '#F0F0F0',
    maxHeight: '96vh',
    padding: '0px',
    width: 'fit-content',
  },
};

export const selectDateModalCustomStylesMobile = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    borderRadius: '11px',
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: '#F0F0F0',
    justifyContent: 'center',
    alignItems: 'start',
    height: 'fit-content',
    maxHeight: '96vh',
  },
};

export const selectDateModalCustomStylesMobileExtended = {
  ...selectDateModalCustomStylesMobile,
  content: {
    ...selectDateModalCustomStylesMobile.content,
    height: '100%',
    maxHeight: '100vh',
    borderRadius: '0px',
  },
};
