import React, { FC, useEffect } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import Modal from 'react-modal';

import { customStylesForgotPassword } from '../../Utils/customStyles';
import Check from '../assets/check.svg';
import styles from './styles.module.scss';

interface ForgotPasswordModalSuccessProps {
  forgotPasswordStep: number;
  setForgotPasswordStep: (num: number) => void;
}

const ForgotPasswordModalSuccess: FC<ForgotPasswordModalSuccessProps> = ({
  forgotPasswordStep,
  setForgotPasswordStep,
}) => {
  const closeModal = (): void => {
    setForgotPasswordStep(0);
  };

  useEffect(() => {
    if (forgotPasswordStep === 4) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && forgotPasswordStep === 0) {
      document.body.classList.remove('modal-open');
    }
  }, [forgotPasswordStep]);
  return (
    <div className={styles.container}>
      <Modal
        isOpen={forgotPasswordStep === 4}
        onRequestClose={closeModal}
        style={customStylesForgotPassword}
        contentLabel="Modal"
        ariaHideApp={false}
        className={styles.wrapSuccess}
        shouldCloseOnOverlayClick={false}
      >
        <div>
          <div className={styles.flexSuccess}>
            <div className={styles.success} style={{ marginTop: '20px' }}>
              <img src={Check} alt="Success" />
            </div>
            <div className={`${styles.success} text-center px-4 px-sm-0`}>
              <p className="text-center">Your new password has been created!</p>
            </div>
            <div className={`${styles.success} text-center pt-4 pt-sm-0 px-2 px-sm-0`}>
              <p>Continue to dentistry.one</p>
            </div>
            <div className={styles.success} style={{ marginTop: '40px' }}>
              <a href="#" onClick={() => closeModal()} className={`${styles.success} button`}>
                Continue to dentistry.one
                <BsArrowRightShort style={{ fontSize: '25px' }} />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Modal.setAppElement('#root');

export default ForgotPasswordModalSuccess;
