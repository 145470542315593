/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PageState {
  isFromSelectPatient: boolean;
  selectedDependentId: string | null;
  isEditInsuranceFlag: boolean;
}

const initialState: PageState = {
  isFromSelectPatient: false,
  selectedDependentId: null,
  isEditInsuranceFlag: false,
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setIsFromSelectPatient: (state, action: PayloadAction<boolean>) => {
      state.isFromSelectPatient = action.payload;
    },
    setDependentId: (state, action: PayloadAction<string | null>) => {
      state.selectedDependentId = action.payload;
    },
    setIsEditInsurance: (state, action: PayloadAction<boolean>) => {
      state.isEditInsuranceFlag = action.payload;
    },
    clearPageState: (state) => {
      state.isFromSelectPatient = initialState.isFromSelectPatient;
      state.selectedDependentId = initialState.selectedDependentId;
      state.isEditInsuranceFlag = initialState.isEditInsuranceFlag;
    },
  },
});

export const { setIsFromSelectPatient, setDependentId, setIsEditInsurance, clearPageState } = pageSlice.actions;

export default pageSlice.reducer;
