import { Insurance } from '@brands/services/identity/types/UserProfile';
import { Option } from '@brands/Utils/selectOptions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SingleValue } from 'react-select';

interface UploadedFile {
  name: string;
  dataURL: string;
}

export type FormValues = {
  form_question_id: number;
  value: {
    [key: string]: string | string[] | UploadedFile[] | number | boolean | Insurance | undefined;
  };
};

export type PatientFormSliceState = {
  form_id: number;
  checkExternalForms: string[];
  values: FormValues[];
  selectedPatient: SingleValue<Option>;
};

const initialState: PatientFormSliceState = {
  form_id: 3,
  checkExternalForms: [],
  values: [],
  selectedPatient: { value: '', label: '' },
};

export const patientFormSlice = createSlice({
  name: 'patientForm',
  initialState,
  reducers: {
    setForm(state, action: PayloadAction<FormValues[]>) {
      Object.assign(state, { values: action.payload });
    },
    addFormItem(state, action: PayloadAction<FormValues>) {
      const index = state.values.findIndex((item) => item.form_question_id === action.payload.form_question_id);
      if (index !== -1) {
        Object.assign(state.values[index], action.payload);
      } else {
        state.values.push(action.payload);
      }
    },
    removeFormItem(state, action: PayloadAction<number>) {
      const index = state.values.findIndex((item) => item.form_question_id === action.payload);
      if (index !== -1) {
        state.values.splice(index, 1);
      }
    },
    resetForm(state) {
      Object.assign(state, { values: [] });
    },
    setExternalForms(state, action: PayloadAction<string[]>) {
      Object.assign(state, { checkExternalForms: action.payload });
    },
    resetExternalForms(state) {
      Object.assign(state, { checkExternalForms: [] });
    },
    setSelectedPatient(state, action: PayloadAction<{ value: string; label: string; image?: string }>) {
      Object.assign(state, { selectedPatient: action.payload });
    },
    resetSelectedPatient(state) {
      Object.assign(state, { selectedPatient: { value: '', label: '' } });
    },
  },
});

export const {
  setForm,
  addFormItem,
  removeFormItem,
  resetForm,
  setExternalForms,
  resetExternalForms,
  setSelectedPatient,
  resetSelectedPatient,
} = patientFormSlice.actions;

export default patientFormSlice.reducer;
