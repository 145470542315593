import { DentalHistorySubmit } from '@brands/Dashboard/MedicalHistory/DentalHistory/utils/types';
import { getForm } from '@brands/services/forms/getForm';
import { FormAnswerValue, getFormAnswer } from '@brands/services/forms/getFormAnswer';
import { listForms } from '@brands/services/forms/listForms';
import { FormQuestion } from '@brands/services/forms/types/FormQuestion';
import { UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';

export const checkEssentialProfileForm = (userInfo: UserProfile): boolean => {
  const basicInfo = userInfo?.basic_info;

  return !!(basicInfo?.first_name && basicInfo?.last_name && basicInfo?.date_of_birth);
};

export const checkProfileForm = (userInfo: UserProfile, selectedPatient: UserProfile | null): boolean => {
  let userProfileInfo = userInfo;
  if (selectedPatient) {
    userProfileInfo = selectedPatient;
  }
  const basicInfo = userProfileInfo?.basic_info;
  const address = userProfileInfo?.address;

  return !!(
    basicInfo?.first_name &&
    basicInfo?.last_name &&
    basicInfo?.gender &&
    basicInfo?.gender_identity &&
    basicInfo?.date_of_birth &&
    basicInfo?.languages &&
    basicInfo?.languages?.length > 0 &&
    userInfo?.phone_number &&
    userInfo?.email &&
    address?.address1 &&
    address?.city &&
    address?.state &&
    address?.zip_code
  );
};

const getFormAnswers = async (
  formId: number,
  defaultVal: DentalHistorySubmit,
  roleName: string,
  createdBy: string,
): Promise<{ formAnswerIdentity: number | null; formObj: DentalHistorySubmit }> => {
  if (roleName === UserRoleName.Patient) {
    let formAnsId: number | null = null;
    const temporaryValues = { ...defaultVal };
    const formAnswers = await getFormAnswer({ form_id: formId, created_by_id: Number(createdBy) });
    Object.values(formAnswers.form_answers).every((answer) => {
      let check = true;
      Object.values(answer.values).forEach((value: FormAnswerValue) => {
        if (Object.keys(temporaryValues).includes(`question_${value.form_question_id}`)) {
          temporaryValues[`question_${value.form_question_id}`] = value.value.data;
          check = false;
          formAnsId = answer.id;
        }
      });
      return check;
    });
    return { formAnswerIdentity: formAnsId, formObj: temporaryValues };
  }
  return { formAnswerIdentity: null, formObj: defaultVal };
};

export const checkMedicalHistoryForms = async (roleName: string, createdBy: string): Promise<boolean> => {
  let defaultFormValues = {};
  const { forms: formList } = await listForms();
  const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
  await getForm(newFormId.toString()).then((res) => {
    if (res) {
      defaultFormValues = res.steps[0].questions.reduce((acc: DentalHistorySubmit, question: FormQuestion) => {
        return {
          ...acc,
          [`question_${question.id}`]: 'no',
        };
      }, {});
    }
  });
  const { formObj } = await getFormAnswers(newFormId, defaultFormValues, roleName, createdBy);
  if (
    formObj[Object.keys(defaultFormValues)[5]] !== 'no' &&
    formObj[Object.keys(defaultFormValues)[5]] !== '' &&
    formObj[Object.keys(defaultFormValues)[6]] !== 'no' &&
    formObj[Object.keys(defaultFormValues)[6]] !== ''
  ) {
    return true;
  }
  return false;
};

export const checkMedicalConditionsForms = async (roleName: string, createdBy: string): Promise<boolean> => {
  let defaultFormValues = {};
  const { forms: formList } = await listForms();
  const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
  await getForm(newFormId.toString()).then((res) => {
    if (res) {
      defaultFormValues = res.steps[1].questions.reduce((acc: DentalHistorySubmit, question: FormQuestion) => {
        return {
          ...acc,
          [`question_${question.id}`]: 'no',
        };
      }, {});
    }
  });
  const { formObj } = await getFormAnswers(newFormId, defaultFormValues, roleName, createdBy);
  const containsYesOrNonEmptyString = Object.values(formObj).some(
    (value) => value === 'yes' || (typeof value === 'string' && value.trim() !== '' && value !== 'no'),
  );
  if (containsYesOrNonEmptyString) {
    return true;
  }
  return false;
};

export const checkDentalHistoryForms = async (roleName: string, createdBy: string): Promise<boolean> => {
  let defaultFormValues = {};
  const { forms: formList } = await listForms();
  const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
  await getForm(newFormId.toString()).then((res) => {
    if (res) {
      defaultFormValues = res.steps[2].questions.reduce((acc: DentalHistorySubmit, question: FormQuestion) => {
        return {
          ...acc,
          [`question_${question.id}`]: 'no',
        };
      }, {});
    }
  });
  const { formObj } = await getFormAnswers(newFormId, defaultFormValues, roleName, createdBy);
  if (
    formObj[Object.keys(defaultFormValues)[0]] === 'dontKnow' ||
    (formObj[Object.keys(defaultFormValues)[0]] !== 'no' && formObj[Object.keys(defaultFormValues)[0]] !== '')
  ) {
    return true;
  }
  return false;
};

export const checkExternalFormAnswers = async (
  userInfo: UserProfile,
  createdBy: string,
  selectedPatient: UserProfile | null,
): Promise<boolean[]> => {
  const checkProfileFormAnswer = await checkProfileForm(userInfo, selectedPatient);
  const checkMedicalHistoryFormAnswer = await checkMedicalHistoryForms(userInfo.role.name, createdBy);
  const checkMedicalConditionsFormAnswer = await checkMedicalConditionsForms(userInfo.role.name, createdBy);
  const checkDentalHistoryFormAnswer = await checkDentalHistoryForms(userInfo.role.name, createdBy);
  const checkEssentialProfileFormAnswer = await checkEssentialProfileForm(userInfo);

  return [
    checkProfileFormAnswer,
    checkMedicalHistoryFormAnswer,
    checkMedicalConditionsFormAnswer,
    checkDentalHistoryFormAnswer,
    checkEssentialProfileFormAnswer,
  ];
};
