/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import QuestionAndDropdown from '@brands/Components/QuestionAndAnswers/QuestionAndDropdown/QuestionAndDropdown';
import QuestionAndTextField from '@brands/Components/QuestionAndAnswers/QuestionAndTextField/QuestionAndTextField';
import { getUserById } from '@brands/services/identity/getUserById';
import { UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { FormValues, setDentalFormCompleted, setFormAnswer } from '@brands/store/slices/formAnswersSlice';
import { addFormItem } from '@brands/store/slices/formQuestionsSlice';
import { setExternalForms } from '@brands/store/slices/patientFormSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { Option } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import { useWindowSize } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { createFormAnswer } from '../../../services/forms/createFormAnswer';
import { getForm } from '../../../services/forms/getForm';
import { FormAnswerValue, getFormAnswer } from '../../../services/forms/getFormAnswer';
import { listForms } from '../../../services/forms/listForms';
import { FormQuestion } from '../../../services/forms/types/FormQuestion';
import { updateFormAnswer } from '../../../services/forms/updateFormAnswer';
import { validationSchema } from '../MedicalHistory/utils/validationSchema';
import styles from '../styles.module.scss';
import { DentalHistoryProps, DentalHistorySubmit } from './utils/types';

const monthOptions = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' },
];

const visitOptions = [
  { value: 'Exam', label: 'Exam' },
  { value: 'Emergency', label: 'Emergency' },
  { value: 'Consultation', label: 'Consultation' },
  { value: 'Procedure', label: 'Procedure' },
  { value: 'Cleaning', label: 'Cleaning' },
  { value: 'Other', label: 'Other' },
];

const DentalHistory = ({ isProfileCard }: DentalHistoryProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const screenSize = useWindowSize();
  const { state } = useLocation();
  const { selectedPatient } = useAppSelector(selectPatientForm);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const [questions, setQuestions] = React.useState<FormQuestion[]>();
  const { userInfo } = useAppSelector(selectAuth);
  const { values } = useAppSelector(selectPatientForm);
  const [rendered, setRendered] = React.useState(true);
  const [defaultValues, setDefaultValues] = useState<DentalHistorySubmit>({});
  const [formId, setFormId] = useState(0);
  const [formAnswerId, setFormAnswerId] = React.useState<number | null>(null);
  const [dentalHistoryForm, setDentalHistoryForm] = useState<DentalHistorySubmit>({
    ...defaultValues,
  });
  const navigate = useNavigate();
  const [guardianPatient, setGuardianPatient] = useState<UserProfile>();

  const yearOptions: Option[] = [];
  for (let year = 2000; year <= 2024; year++) {
    yearOptions.push({ value: String(year), label: String(year) });
  }

  useEffect(() => {
    const getGuardianPAtient = async (): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        if (currentPatient.guardian_id !== null) {
          const guardianResponse = await getUserById(currentPatient?.guardian_id as number);
          setGuardianPatient(guardianResponse);
        }
      }
    };
    getGuardianPAtient();
  }, [currentPatient]);
  const setValuesPayload = (data: DentalHistorySubmit): FormValues[] => {
    return [
      ...Object.keys(data).map((value) => {
        const questionId = Number(value.replace('question_', ''));
        return {
          form_question_id: questionId,
          value: { data: data[value as keyof DentalHistorySubmit] },
        };
      }),
    ];
  };

  const isPaymentRequired = currentOrganization.payment_options.length > 0;

  const checkExternalForms = async (): Promise<void> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? guardianPatient ?? (currentPatient as UserProfile)
          : userInfo,
        selectedPatient?.value,
        userInfo.role.name === UserRoleName.Patient && currentPatient.role.name === UserRoleName.Dependent
          ? currentPatient
          : currentPatient?.guardian_id !== null
          ? currentPatient
          : null,
      );
    const formsToSet = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
  };

  const onSubmit = async (data: DentalHistorySubmit): Promise<void | null> => {
    setRendered(false);
    if (!formAnswerId) {
      await createFormAnswer({
        form_id: formId,
        user_id:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? currentPatient.guardian_id !== null
              ? (guardianPatient as UserProfile).id
              : (currentPatient as UserProfile).id
            : Number(userInfo.id),
        values: [...setValuesPayload(data)],
        dependent_id:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? currentPatient.guardian_id !== null
              ? (currentPatient as UserProfile).id
              : undefined
            : Number(selectedPatient?.value) !== Number(userInfo.id)
            ? Number(selectedPatient?.value)
            : undefined,
      });
    } else {
      await updateFormAnswer(
        formAnswerId,
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id !== null
            ? (guardianPatient as UserProfile).id
            : (currentPatient as UserProfile).id
          : Number(userInfo.id),
        {
          values: [...setValuesPayload(data)],
        },
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id !== null
            ? (currentPatient as UserProfile).id
            : undefined
          : Number(selectedPatient?.value) !== Number(userInfo.id)
          ? Number(selectedPatient?.value)
          : undefined,
      );
    }
    await checkExternalForms();
    dispatch(
      setFormAnswer({
        form_id: formId,
        form_answer_id: formAnswerId,
        values: [...setValuesPayload(data)],
      }),
    );

    dispatch(setDentalFormCompleted(true));
    setRendered(true);
    if (values.find((item) => item.form_question_id === 20)?.value.caseType as string) {
      if (state.isPatientForm || state?.fromPatientIntake) {
        return navigate('/medical-information-summary', {
          state: {
            fromPatientIntake: state?.fromPatientIntake ?? false,
            caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
            isPatientForm: state?.isPatientForm,
            shouldSeeLastModal: state?.shouldSeeLastModal,
            patientId: state?.patientId,
          },
        });
      }
    } else if (state?.fromSummary || state?.fromPatientIntake) {
      return navigate('/medical-information-summary', {
        state: {
          fromPatientIntake: state?.fromPatientIntake ?? false,
          caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
          isPatientForm: state?.isPatientForm,
          shouldSeeLastModal: state?.shouldSeeLastModal,
          patientId: state?.patientId,
        },
      });
    }
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const { register, handleSubmit, setValue } = useForm<DentalHistorySubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(questions, false, false, true)),
  });

  const getFormAnswers = useCallback(
    async (formIds: number, defaultVal: DentalHistorySubmit) => {
      let formAnsId: number | null = null;
      const temporaryValues = { ...defaultVal };
      const formAnswers = await getFormAnswer({
        form_id: formIds,
        created_by_id: Number(selectedPatient?.value),
      });

      Object.values(formAnswers.form_answers)
        .sort((a, b) => a.id - b.id)
        .every((answer) => {
          let check = true;
          Object.values(answer.values).forEach((value: FormAnswerValue) => {
            if (Object.keys(temporaryValues).includes(`question_${value.form_question_id}`)) {
              temporaryValues[`question_${value.form_question_id}`] = value.value.data;
              check = false;
              formAnsId = answer.id;
            }
          });
          return check;
        });
      return { formAnswerIdentity: formAnsId, formObj: temporaryValues };
    },
    [defaultValues, dispatch, formId],
  );

  useEffect(() => {
    (async () => {
      setRendered(false);
      let defaultFormValues = {};
      const { forms: formList } = await listForms();
      const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
      setFormId(newFormId);
      await getForm(newFormId.toString()).then((res) => {
        if (res) {
          defaultFormValues = res.steps[2].questions.reduce((acc: DentalHistorySubmit, question: FormQuestion) => {
            return {
              ...acc,
              [`question_${question.id}`]: 'no',
            };
          }, {});
          setDefaultValues(defaultFormValues);
          dispatch(
            addFormItem({
              form_id: res.id,
              step_id: res.steps[2].id,
              questions: res.steps[2].questions,
            }),
          );
        }
        setQuestions(res?.steps[2].questions);
      });
      const { formAnswerIdentity, formObj } = await getFormAnswers(newFormId, defaultFormValues);
      setFormAnswerId(formAnswerIdentity);
      setDentalHistoryForm(formObj as DentalHistorySubmit);
      setRendered(true);
    })();
  }, []);

  useEffect(() => {
    Object.keys(dentalHistoryForm).forEach((key: unknown) =>
      setValue(key as string, dentalHistoryForm[key as keyof DentalHistorySubmit], {
        shouldValidate: true,
      }),
    );
  }, [dentalHistoryForm]);

  if (!rendered || !questions) {
    return <Loading fullScreen />;
  }

  return (
    <Container
      isBGEclipse={false}
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px' }}
    >
      <div className={styles.medicalContainer}>
        <div className={`${styles.formTitle} d-flex mt-3`} style={{ display: isProfileCard ? 'none' : '' }}>
          {Number(selectedPatient?.value) !== Number(userInfo.id) ? 'Their Dental History' : 'Dental History'}
        </div>
        <ProgressBar
          currentStep={state?.isPatientForm ? 4 : 3}
          totalSteps={state?.isPatientForm && isPaymentRequired ? 5 : state?.isPatientForm ? 4 : 3}
          hideStepNumbers
        />
        <form
          className={styles.formContainer}
          style={{ width: isProfileCard ? '100%' : '', marginRight: isProfileCard ? '0px' : '' }}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <div
            className={styles.wrapper}
            style={{ width: isProfileCard ? '734px' : screenSize.width < 992 ? '90%' : '' }}
          >
            <div className={styles.formContent}>
              {questions !== undefined &&
                questions.map((element) => {
                  if (element.type === 'select') {
                    if (element.title === 'Date of your last dental visit') {
                      return (
                        <QuestionAndDropdown
                          {...register(`question_${element.id}`)}
                          element={element}
                          key={element.id}
                          form={dentalHistoryForm}
                          setForm={setDentalHistoryForm}
                          givingOptions={monthOptions}
                          twoInputs
                          givingOptionsTwo={yearOptions as Option[]}
                          givingOptionTitle="Month"
                          givingOptionTwoTitle="Year"
                          dontKnowOption
                        />
                      );
                    }
                    return (
                      <QuestionAndDropdown
                        {...register(`question_${element.id}`)}
                        element={element}
                        key={element.id}
                        form={dentalHistoryForm}
                        setForm={setDentalHistoryForm}
                        givingOptions={visitOptions}
                        fullSize
                      />
                    );
                  }
                  return (
                    <QuestionAndTextField
                      {...register(`question_${element.id}`)}
                      element={element}
                      key={element.id}
                      form={dentalHistoryForm}
                      setForm={setDentalHistoryForm}
                      questionTitle="Name"
                    />
                  );
                })}
            </div>
            <div className={styles.btnContainer}>
              <Button type="submit" style={{ width: '245px', height: '46px' }}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
DentalHistory.defaultProps = {
  isProfileCard: false,
};
export default DentalHistory;
