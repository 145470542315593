import { refreshToken } from '@brands/services/auth/refreshToken';
import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import { signOut } from '@brands/Utils/signOut';
import Cookies from 'js-cookie';
import moment from 'moment';
import * as React from 'react';
import { IIdleTimer, withIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';

import { config } from '../../config/config';
import { useAppSelector } from '../../hooks/useReduxHook';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import { displayErrorDetails } from '../../Utils/displayError';
import { sleep } from '../../Utils/promise';
import IdleNoticeModal from './IdleNoticeModal';

const isDevMode = config.iot.prefix === 'develop';
const NON_PATIENT_PROMPT_TIME = isDevMode ? 10 : 90;
const PATIENT_PROMPT_TIME = isDevMode ? 6 : 30;
const REFRESH_TOKEN = isDevMode ? 3 : 10;
const PROMPT_TIME_OUT = 2;
const MINUTE_MILLISECONDS = 60 * 1000;

const IdleTimerComponent = withIdleTimer(() => null);

const IdleTimer: React.FC = () => {
  const dispatch = useDispatch();
  const auth: AuthState = useAppSelector(selectAuth);
  const { userInfo: userInfoInContext } = useAppSelector(selectAuth);

  const timerRef = React.useRef<IIdleTimer>(null);
  const [prompt, showPrompt] = React.useState(false);
  const [log, setLog] = React.useState({
    lastActiveTime: '',
    remainingTime: '',
  });

  const promptTime = React.useMemo(() => {
    if (auth.userInfo?.role.name === UserRoleName.Patient) {
      return PATIENT_PROMPT_TIME;
    }
    return NON_PATIENT_PROMPT_TIME;
  }, [auth]);

  const logout = React.useCallback(async () => {
    try {
      await signOut(dispatch);

      window.location.href = '/';
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  }, [dispatch, userInfoInContext]);

  const onPrompt = React.useCallback(async () => {
    if (timerRef.current && timerRef.current?.isLeader()) {
      // try {
      //   // Check if there is any in-progress video conference
      //   const res = await getVideoConferencesLambda(authToken)({
      //     call_status: 'in-progress',
      //     per_page: 1,
      //   });
      //   if (res.data.length > 0) {
      //     timerRef.current?.activate();
      //     return;
      //   }
      // } catch (e) {
      //   console.error(e);
      // }
    }

    const lastActiveTime = timerRef.current?.getLastActiveTime()?.getTime() ?? 0;

    // Finding if there are any video playing
    const videos = document.getElementsByTagName('video');
    await sleep(100);
    for (let i = 0; i < videos.length; i++) {
      if (videos.item(i)?.id !== 'preview' && !videos.item(i)?.paused) {
        timerRef.current?.activate();
        return;
      }
    }

    if (Date.now() - lastActiveTime <= promptTime * MINUTE_MILLISECONDS) {
      timerRef.current?.message('prompt', true);
    } else {
      timerRef.current?.message('logout', true);
    }
  }, [promptTime]);

  const onActive = React.useCallback(() => {
    showPrompt(false);
  }, []);

  const onClose = React.useCallback(() => {
    timerRef.current?.activate();
    showPrompt(false);
  }, []);

  const onMessage = React.useCallback(
    async (data: string) => {
      if (data === 'prompt') {
        showPrompt(true);
      } else if (data === 'logout') {
        await logout();
      }
    },
    [logout],
  );

  React.useEffect(() => {
    const timerId = setInterval(() => {
      const remainingTime = (timerRef.current?.getRemainingTime() ?? 0) / 1000;
      setLog({
        lastActiveTime: timerRef.current?.getLastActiveTime()?.toLocaleTimeString() ?? '',
        remainingTime: remainingTime?.toFixed(0),
      });
      if (remainingTime === 0) {
        showPrompt(false);
        timerRef.current?.message('logout', true);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  // Refresh cognito token
  React.useEffect(() => {
    const timerId = setInterval(async () => {
      const currentRefreshToken = Cookies.get('refreshToken');
      if (!currentRefreshToken) {
        return;
      }
      const { access_token: accessToken } = await refreshToken({
        refresh_token: currentRefreshToken,
      });

      Cookies.remove('jwtToken', { domain: config.domain_name });
      Cookies.remove('jwtTokenExpire', { domain: config.domain_name });

      Cookies.set('jwtToken', accessToken, {
        domain: config.domain_name,
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set('jwtTokenExpire', moment().add(20, 'hours').valueOf().toString(), {
        domain: config.domain_name,
        secure: true,
        sameSite: 'strict',
      });
    }, REFRESH_TOKEN * MINUTE_MILLISECONDS);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  // Logout from all tabs when jwtToken removed in localStorage
  React.useEffect(() => {
    const handleStorageTokenChange = (e: StorageEvent): void => {
      if (e.key === 'jwtToken' && !e.newValue) {
        logout();
      }
    };
    window.addEventListener('storage', handleStorageTokenChange);
    return () => {
      window.removeEventListener('storage', handleStorageTokenChange);
    };
  }, [logout]);

  React.useEffect(() => {
    if (auth.userInfo) {
      timerRef.current?.start();
    } else {
      timerRef.current?.pause();
    }
  }, [auth.userInfo]);

  return (
    <>
      {isDevMode && (
        <p style={{ zIndex: 1000, position: 'absolute' }}>
          Last Active Time: {log.lastActiveTime}, Remaining Time: {log.remainingTime}s
        </p>
      )}
      <IdleTimerComponent
        ref={timerRef}
        onPrompt={onPrompt}
        onActive={onActive}
        onMessage={onMessage}
        timeout={promptTime * MINUTE_MILLISECONDS}
        promptBeforeIdle={PROMPT_TIME_OUT * MINUTE_MILLISECONDS}
        startManually
        crossTab
        leaderElection
        syncTimers={200}
      />
      {Boolean(auth.userInfo) && prompt && <IdleNoticeModal onClose={onClose} />}
    </>
  );
};

export default IdleTimer;
