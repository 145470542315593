import { client } from '../client';

export interface Payload {
  email: string;
  old_password: string;
  new_password: string;
}

export interface ChangePasswordResponse {
  message: string;
}
export const changePassword = async (payload: Payload): Promise<ChangePasswordResponse> => {
  const { data } = await client.post<ChangePasswordResponse>(`/auth/change_password`, payload);

  return data;
};
