/* eslint-disable no-nested-ternary */
import { selectAuth } from '@brands/store/selectors/auth';
import React, { useEffect } from 'react';
import { TbMicrophoneOff, TbPinned, TbVideoOff } from 'react-icons/tb';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import {
  useIsTrackEnabled,
  usePublications,
  useScreenShareParticipant,
  useVideoContext,
  useWindowSize,
} from '../../hooks';
import { useAppSelector } from '../../hooks/useReduxHook';
import { isMobile } from '../../Utils';
import styles from './styles.module.scss';

interface ParticipantInfoProps {
  participant: Participant;
  onClick?: () => void;
  hideParticipant?: boolean;
  children: React.ReactNode;
  isSelected?: boolean;
  showVideoThumbnail?: boolean;
}

const ParticipantInfo = ({
  participant,
  onClick,
  hideParticipant,
  children,
  isSelected,
  showVideoThumbnail,
}: ParticipantInfoProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const { room, participantsDetails, isSideBySide } = useVideoContext();
  const publications = usePublications(participant);
  const localParticipant = room?.localParticipant;
  const remoteParticipantsSize = room?.participants.size || 0;
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) => !p.trackName.includes('screen') && p.kind === 'video');

  const isVideoEnabled = useIsTrackEnabled(videoPublication?.track as RemoteVideoTrack | LocalVideoTrack);
  const isAudioEnabled = useIsTrackEnabled(audioPublication?.track as RemoteAudioTrack | LocalAudioTrack);

  const isLocalParticipant = Number(participant.identity) === Number(userInfo?.id);

  const currentParticipantDetails = participantsDetails.find(
    (participantDetails) => Number(participantDetails.identity) === Number(participant.identity),
  );

  const [isOverlapping, setIsOverlapping] = React.useState(false);

  useEffect(() => {
    if (
      isLocalParticipant &&
      !isSelected &&
      !isRemoteParticipantScreenSharing &&
      remoteParticipantsSize > 0 &&
      !isSideBySide
    ) {
      setIsOverlapping(true);
    } else {
      setIsOverlapping(false);
    }
  }, [
    isLocalParticipant,
    currentParticipantDetails,
    isSelected,
    isRemoteParticipantScreenSharing,
    remoteParticipantsSize,
    isSideBySide,
  ]);

  return (
    <div
      key={Number(isOverlapping)}
      className={styles.participantInfoContainer}
      style={{
        display: hideParticipant ? 'none' : '',
        position: isOverlapping ? 'absolute' : 'relative',
        bottom: isOverlapping ? '20px' : '',
        right: isOverlapping ? '20px' : '',
        zIndex: isOverlapping ? 1 : '',
        width: isOverlapping && !isVideoEnabled && !isMobile && isLocalParticipant ? '15%' : isOverlapping ? '15%' : '',
        minWidth: isOverlapping ? '20px' : '',
        height: isOverlapping
          ? '20%'
          : showVideoThumbnail
          ? `50%`
          : screenSize.width < 768 && !isRemoteParticipantScreenSharing
          ? '100%'
          : isRemoteParticipantScreenSharing
          ? '100%'
          : '',
        minHeight: isOverlapping ? '50px' : '',
        border: isOverlapping ? '3px solid #fff' : '',
        borderRadius: isOverlapping ? '5px' : '',
      }}
      ref={(element) => {
        if (element && isOverlapping) element.style.setProperty('height', '20%', 'important');
      }}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      <div className={styles.participantInfoWrapper}>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div className={styles.notificationContainer} style={{ position: 'absolute' }} key={Number(isSelected)}>
            {!isAudioEnabled && (
              <div className={styles.videoParticipantMuted} key={Number(isSelected)}>
                <TbMicrophoneOff /> <span className={styles.text}>Muted</span>
              </div>
            )}
            {isSelected && (
              <div className={styles.videoParticipantMuted}>
                <TbPinned /> <span className={styles.text}>Pinned</span>
              </div>
            )}
          </div>
          {audioPublication && !videoPublication && (
            <div className={styles.participantInfoVideoEnabled}>
              <TbVideoOff className={styles.icon} />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '64px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

ParticipantInfo.defaultProps = {
  onClick: () => {},
  hideParticipant: false,
  isSelected: false,
  showVideoThumbnail: false,
};

export default ParticipantInfo;
