/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useAppSelector } from '../../../../hooks/useReduxHook';
import styles from '../styles.module.scss';
import { AdditionalInformationSubmit } from '../utils/types';

type AdditionalInformationProps = {
  setAdditionalInformation?: React.Dispatch<React.SetStateAction<string>>;
};

const AdditionalInformation = ({ setAdditionalInformation }: AdditionalInformationProps): JSX.Element => {
  const { values } = useAppSelector(selectPatientForm);
  const [patientForm, setPatientForm] = useState<AdditionalInformationSubmit>(
    (values.find((item) => item.form_question_id === 18)?.value as AdditionalInformationSubmit) || {
      anythingElse: '',
    },
  );

  const { register, setValue } = useForm<AdditionalInformationSubmit>({
    mode: 'onChange',
    resolver: yupResolver(Yup.object().shape({})),
  });

  useEffect(() => {
    Object.keys(patientForm).forEach((key: unknown) => {
      setValue(key as keyof AdditionalInformationSubmit, patientForm[key as keyof AdditionalInformationSubmit]);
    });
  }, [patientForm]);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.formContent}`}>
        <div className={styles.textAreaWrapper}>
          <textarea
            {...register('anythingElse')}
            className={styles.textArea}
            onChange={(e) => {
              if (setAdditionalInformation) setAdditionalInformation(e.target.value);
              setPatientForm({ ...patientForm, anythingElse: e.target.value });
            }}
            placeholder="Start note…"
          />
        </div>
      </div>
    </div>
  );
};

AdditionalInformation.defaultProps = {
  setAdditionalInformation: () => {},
};

export default AdditionalInformation;
