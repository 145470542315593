// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vSHIJYThA8lReiCpRiEQ{width:300px;background-color:#fff;z-index:999999999;padding:16px 16px 10px;display:flex;flex-direction:column;border-radius:4px;-webkit-box-shadow:0px 2px 8px rgba(0,0,0,.15);-moz-box-shadow:0px 2px 8px rgba(0,0,0,.15);box-shadow:0px 2px 8px rgba(0,0,0,.15)}.vSHIJYThA8lReiCpRiEQ .xzIA9j1kXwNZ6WXe_kbQ{display:flex;flex-direction:row;align-items:center}.vSHIJYThA8lReiCpRiEQ .qeLk4NtrlVr8mOmf3YHv{flex:1;font-size:12px;margin:0 10px}.vSHIJYThA8lReiCpRiEQ .pBXlR_7RJEuJ6EE9djbQ{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/brands/default/Dashboard/Sidenav/NotificationPopup/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,qBAAA,CACA,iBAAA,CACA,sBAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,8CAAA,CACA,2CAAA,CACA,sCAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,kBAAA,CAGF,4CACE,MAAA,CACA,cAAA,CACA,aAAA,CAGF,4CACE,cAAA","sourcesContent":[".popupContainer {\n  width: 300px;\n  background-color: white;\n  z-index: 999999999;\n  padding: 16px 16px 10px;\n  display: flex;\n  flex-direction: column;\n  border-radius: 4px;\n  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);\n  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);\n  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);\n\n  .contentWrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .content {\n    flex: 1;\n    font-size: 12px;\n    margin: 0 10px;\n  }\n\n  .clickable {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `vSHIJYThA8lReiCpRiEQ`,
	"contentWrapper": `xzIA9j1kXwNZ6WXe_kbQ`,
	"content": `qeLk4NtrlVr8mOmf3YHv`,
	"clickable": `pBXlR_7RJEuJ6EE9djbQ`
};
export default ___CSS_LOADER_EXPORT___;
