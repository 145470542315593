import * as Yup from 'yup';

import { checkFutureDate, checkValidDate, isValidDate, isValidZipCode } from '../../../Utils/validation';

export const validationSchema = Yup.object({
  plan: Yup.string().required('Plan name/type is required'),
  memberID: Yup.string()
    .required('Member ID is required')
    .test('no-empty-space', 'Member ID cannot be empty spaces', (value) => {
      if (value) {
        return !/^\s+$/.test(value);
      }
      return true;
    }),
  groupNumber: Yup.string()
    .required('Group number is required')
    .test('no-empty-space', 'Group number cannot be empty spaces', (value) => {
      if (value) {
        return !/^\s+$/.test(value);
      }
      return true;
    }),
  iFirstName: Yup.string().when(['insuranceThrough'], {
    is: (insuranceThrough: string) =>
      insuranceThrough === 'spouse' ||
      insuranceThrough === 'parent' ||
      insuranceThrough === 'fparent' ||
      insuranceThrough === 'sparent' ||
      insuranceThrough === 'gparent' ||
      insuranceThrough === 'lg',
    then: Yup.string().required('Insurance Holder First Name is required'),
  }),
  iMiddleName: Yup.string(),
  iLastName: Yup.string().when(['insuranceThrough'], {
    is: (insuranceThrough: string) =>
      insuranceThrough === 'spouse' ||
      insuranceThrough === 'parent' ||
      insuranceThrough === 'fparent' ||
      insuranceThrough === 'sparent' ||
      insuranceThrough === 'gparent' ||
      insuranceThrough === 'lg',
    then: Yup.string().required('Insurance Holder Last Name is required'),
  }),
  iDateOfBirth: Yup.string()
    .when(['insuranceThrough'], {
      is: (insuranceThrough: string) =>
        insuranceThrough === 'spouse' ||
        insuranceThrough === 'parent' ||
        insuranceThrough === 'fparent' ||
        insuranceThrough === 'sparent' ||
        insuranceThrough === 'gparent' ||
        insuranceThrough === 'lg',
      then: Yup.string().required('Insurance Holder Date of birth is required'),
    })
    .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value: any) => {
      if (value && !isValidDate(value)) {
        return false;
      }
      if (value) {
        const values = value?.split('/');
        if (!checkValidDate(values[0], values[1], values[2])) {
          return false;
        }
      }
      return true;
    })
    .test('test-future', 'The Date of Birth can not be in the future.', (value: any) => {
      if (value) {
        const values = value?.split('/');
        if (!checkFutureDate(values[0], values[1], values[2])) {
          return false;
        }
      }
      return true;
    }),
  iAddress1: Yup.string().when(['insuranceThrough'], {
    is: (insuranceThrough: string) =>
      insuranceThrough === 'spouse' ||
      insuranceThrough === 'parent' ||
      insuranceThrough === 'fparent' ||
      insuranceThrough === 'sparent' ||
      insuranceThrough === 'gparent' ||
      insuranceThrough === 'lg',
    then: Yup.string().required('Insurance Holder Address is required'),
  }),
  iZipCode: Yup.string()
    .when(['insuranceThrough'], {
      is: (insuranceThrough: string) =>
        insuranceThrough === 'spouse' ||
        insuranceThrough === 'parent' ||
        insuranceThrough === 'fparent' ||
        insuranceThrough === 'sparent' ||
        insuranceThrough === 'gparent' ||
        insuranceThrough === 'lg',
      then: Yup.string().required('Required'),
    })
    .test('test-name', 'Not valid zipcode', (value: any) => {
      if (value && !isValidZipCode(value)) {
        return false;
      }
      return true;
    }),
  iState: Yup.string().when(['insuranceThrough'], {
    is: (insuranceThrough: string) =>
      insuranceThrough === 'spouse' ||
      insuranceThrough === 'parent' ||
      insuranceThrough === 'fparent' ||
      insuranceThrough === 'sparent' ||
      insuranceThrough === 'gparent' ||
      insuranceThrough === 'lg',
    then: Yup.string().required('Required'),
  }),
});
