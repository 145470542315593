import { useCallback, useRef, useState } from 'react';
import { Room, Track, TwilioError } from 'twilio-video';

type ErrorCallback = (error: TwilioError | Error) => void;

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: 'debug' | 'info' | 'warn' | 'error' | 'off';
}
const useScreenShareToggle = (room: Room | null, onError: ErrorCallback): readonly [boolean, () => void] => {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>(null!);

  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: true,
      })
      .then((stream) => {
        const track = stream.getTracks()[0];

        room?.localParticipant
          .publishTrack(track, {
            name: 'screen',
            priority: 'low',
          } as MediaStreamTrackPublishOptions)
          .then(() => {
            stopScreenShareRef.current = () => {
              room?.localParticipant.unpublishTrack(track);
              track.stop();
              setIsSharing(false);
            };

            track.onended = stopScreenShareRef.current;
            setIsSharing(true);
          })
          .catch(onError);
      })
      .catch((error) => {
        if (
          error.message === 'Permission denied by system' ||
          (error.name !== 'AbortError' && error.name !== 'NotAllowedError')
        ) {
          onError(error);
        }
      });
  }, [room, onError]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      if (!isSharing) {
        shareScreen();
      } else {
        stopScreenShareRef.current();
      }
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
};
export default useScreenShareToggle;
