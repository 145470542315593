import { UserProfile } from '@brands/services/identity/types/UserProfile';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import Button from '../../../../../Components/Button/Button';
import { Loading } from '../../../../../Components/LoadingSpinner/Loading';
import { useWindowSize } from '../../../../../hooks';
import useCareAdvisor from '../../../../../hooks/useCareAdvisor';
import CCard from './CCard/CCard';
import styles from './ccList.module.scss';

interface IModal {
  openModal: (arg0: boolean) => void;
  assignCareAdvisor: (selectedCareAdvisor: number) => Promise<void>;
  setCAProfileCardIdentity: React.Dispatch<React.SetStateAction<UserProfile | null>>;
  setCAProfileCardModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CareAdvisorList = ({
  openModal,
  assignCareAdvisor,
  setCAProfileCardIdentity,
  setCAProfileCardModal,
}: IModal): JSX.Element => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;

  const observer = useRef<IntersectionObserver>();
  const lastCareAdvisorItemRef = useRef<HTMLTableRowElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      limit: 1000,
    }),
    [],
  );

  const { careAdvisors, loadNextPage, dataLoading } = useCareAdvisor(params, true);

  const [selectedCareAdvisor, isSelectedCareAdvisor] = useState<number>(0);

  const handleAssignedCareAdvisor = (target: number): void => {
    if (selectedCareAdvisor === target) {
      isSelectedCareAdvisor(0);
    } else {
      isSelectedCareAdvisor(target);
    }
  };
  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastCareAdvisorItemRef.current) observer.current.observe(lastCareAdvisorItemRef.current);
  }, [careAdvisors]);

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.providerListContainer}>
          {isMobile && (
            <div className={styles.mobileHeader}>
              <p>Care Advisor List</p>
            </div>
          )}
          <div className={styles.mobileStickyHeader}>
            <div className={`row ${styles.providerListHeader}`}>
              <span>Care Advisor List</span>
              <div className={styles.btnGroup}>
                <Button
                  className={styles.btn}
                  onClick={() => {
                    openModal(false);
                    assignCareAdvisor(selectedCareAdvisor);
                  }}
                  disabled={!selectedCareAdvisor}
                >
                  Assign Care Advisor
                </Button>
                <button className={styles.cBtn} type="button" onClick={() => openModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className={styles.providerListScroll}>
            {!dataLoading ? (
              careAdvisors.map((careAdvisor) => (
                <Fragment key={careAdvisor.id}>
                  <CCard
                    careAdvisor={careAdvisor}
                    handleAssignedCareAdvisor={handleAssignedCareAdvisor}
                    selectedCareAdvisor={selectedCareAdvisor}
                    setCAProfileCardIdentity={setCAProfileCardIdentity}
                    setCAProfileCardModal={setCAProfileCardModal}
                  />
                </Fragment>
              ))
            ) : (
              <span>
                <Loading dotted />
              </span>
            )}
            {!dataLoading && (
              <div className={styles.lastItemRef} ref={lastCareAdvisorItemRef}>
                <span>{isLoading && <Loading dotted />}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CareAdvisorList;
