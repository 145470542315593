import { client } from '../client';
import { IGMMessage } from './types/IGMMessage';

export interface SendMessagePayload {
  body: string;
  media_ids?: string[];
}

export const sendConversationMessage = async (
  conversationSid: string | undefined,
  payload: SendMessagePayload,
): Promise<IGMMessage> => {
  const { data } = await client.post(`/conversations/${conversationSid}/messages`, payload);

  return data;
};
