import { client } from '../client';
import { UserProfile } from './types/UserProfile';

type UpdatePracticePayload = {
  practice_ids: string[];
};

export const updateUserPractice = async (user_id: number, payload: UpdatePracticePayload): Promise<UserProfile> => {
  const { data } = await client.patch(`/admin/users/${user_id}/practices`, payload);

  return data;
};
