import React from 'react';

import Container from '../../Components/Container/Container';
import useWindowSize from '../../hooks/useWindowSize';
import styles from './styles.module.scss';

const Solutions = (): JSX.Element => {
  const screenSize = useWindowSize();

  return (
    <Container
      isBGEclipse={false}
      containerStyle={{
        justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
      }}
    >
      <div className={styles.solutions}>
        <div className={styles.containerSolutions}>
          <div className={styles.titleContainer}>Solutions</div>
          <div className={styles.cardsContainer}>
            <div className={styles.card}>
              <iframe
                className={styles.iframe}
                title="Iframes in React"
                src="https://www.thinkific.com/&output=embed"
              />
              <div className={styles.eclipsLeftS} />
              <div className={styles.eclipsRightS} />
            </div>
            <div className={styles.card}>
              <iframe className={styles.iframe} title="Iframes in React" src="https://www.icoreconnect.com" />
              <div className={styles.eclipsLeftS} />
              <div className={styles.eclipsRightS} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Solutions;
