import { client } from '../client';
import { ITags } from './listAllTags';

export interface IaddTag {
  answer: true;
  note: 'string';
  tag: ITags;
}
export interface AddTagPayload {
  answer: boolean;
  note: string | string[];
}

export const setUserTag = async (user_id: number, tag_id: number, payload: AddTagPayload): Promise<IaddTag> => {
  const { data } = await client.put(`/users/${user_id}/tag/${tag_id}`, payload);

  return data;
};
