import { client } from '../client';
import { IGMConversationMedia } from './types/IGMConversationMedia';

export interface ConversationMessageMedia {
  url: string | URL;
}

export const getMedia = async (media_id: string, conversation_sid: string): Promise<IGMConversationMedia> => {
  const { data } = await client.get(`/conversations/${conversation_sid}/media/${media_id}`);
  return data;
};
