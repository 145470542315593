import { client } from '../client';

export const confirmAvatarMedia = async (
  media_id: string,
  user_id: number,
  dependent_id?: number,
): Promise<Response> => {
  const { data } = await client.patch<Response>(`/users/${user_id}/confirm_avatar`, { media_id, dependent_id });

  return data;
};
