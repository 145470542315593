import { Conversation, ConversationUpdateReason } from '@twilio/conversations';
import { useEffect, useState } from 'react';

import { displayErrorDetails } from '../../Utils/displayError';
import useTwilioConversationsClient from './useTwilioConversationsClient';

export default function useTwilioConversationBySid(sid: string | null): Conversation | null {
  const client = useTwilioConversationsClient();
  const [conversation, setConversation] = useState<Conversation | null>(null);

  useEffect(() => {
    let isSubscribed = true;

    const fetchConversation = async (): Promise<void> => {
      try {
        if (!client || !sid) {
          setConversation(null);
          return;
        }

        const reconnectWithDelay = (): Promise<Conversation | null> => {
          return new Promise((resolve) => {
            setTimeout(async () => {
              const conv = await client.getConversationBySid(sid);
              resolve(conv || reconnectWithDelay());
            }, 1000); // Delay before retrying
          });
        };

        const conv = await reconnectWithDelay();

        if (isSubscribed) {
          setConversation(conv);
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
    };

    fetchConversation();

    return () => {
      isSubscribed = false;
    };
  }, [client, sid]);

  useEffect(() => {
    let isSubscribed = true;

    if (!conversation) {
      return () => {};
    }

    function onConversationUpdated(data: {
      conversation: Conversation;
      updateReasons: ConversationUpdateReason[];
    }): void {
      if (isSubscribed) {
        setConversation(data.conversation);
      }
    }

    conversation.on('updated', onConversationUpdated);

    return () => {
      isSubscribed = false;
      conversation.off('updated', onConversationUpdated);
    };
  }, [conversation]);

  useEffect(() => {
    // When `sid` changes, update the conversation reference
    setConversation(null);
  }, [sid]);

  return conversation;
}
