/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

import { UserRoleName } from '../../services/identity/types/UserProfile';
import styles from './styles.module.scss';

interface Props {
  open?: boolean;
  children: React.ReactNode;
  header: string | React.ReactNode;
  headerStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
  collapsibleStyles?: React.CSSProperties;
  collapsibleContentStyles?: React.CSSProperties;
  error?: boolean;
  patientStyle?: boolean;
  isMobile?: boolean;
  thisCaseStatus: string;
  userRole: string;
  currentStatus: string;
}

const Collapsible: React.FC<Props> = ({
  open,
  collapsibleStyles,
  headerStyle,
  titleStyle,
  contentContainerStyle,
  collapsibleContentStyles,
  children,
  header,
  error,
  patientStyle,
  isMobile,
  thisCaseStatus,
  userRole,
  currentStatus,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0);
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = (): void => {
    setIsOpen((prev) => !prev);
  };
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (thisCaseStatus === 'edit' || thisCaseStatus === 'editImages' || thisCaseStatus === 'editNotRequired')
      setIsOpen(true);
    else if (
      userRole === UserRoleName.Provider &&
      (currentStatus === 'completed' || currentStatus === 'in_progress_care_advisor')
    )
      setIsOpen(true);
    else if (
      (userRole === UserRoleName.CareAdvisor ||
        userRole === UserRoleName.Admin ||
        userRole === UserRoleName.SuperAdmin ||
        userRole === UserRoleName.OrganizationAdmin) &&
      currentStatus === 'completed' &&
      thisCaseStatus === 'readOnly'
    ) {
      setIsOpen(true);
    }
  }, [currentStatus, thisCaseStatus, userRole]);

  useEffect(() => {
    if (isOpen) {
      setHeight(ref.current?.getBoundingClientRect().height);
    } else setHeight(0);
  }, [isOpen]);

  return (
    <div className={styles.collapsibleEard} style={collapsibleStyles}>
      <div
        className={!patientStyle ? styles.collapsibleHeader : styles.pInfoCollapsibleHeader}
        style={{
          ...headerStyle,
          backgroundColor:
            (userRole === UserRoleName.CareAdvisor ||
              userRole === UserRoleName.Admin ||
              userRole === UserRoleName.SuperAdmin ||
              userRole === UserRoleName.OrganizationAdmin) &&
            (thisCaseStatus === 'edit' || thisCaseStatus === 'editImages' || thisCaseStatus === 'editNotRequired')
              ? headerStyle?.backgroundColor
              : (userRole === UserRoleName.CareAdvisor ||
                  userRole === UserRoleName.Admin ||
                  userRole === UserRoleName.SuperAdmin ||
                  userRole === UserRoleName.OrganizationAdmin) &&
                thisCaseStatus === 'readOnly'
              ? '#D0D0D0'
              : error
              ? '#BA271A'
              : userRole === UserRoleName.Provider && thisCaseStatus === 'edit'
              ? headerStyle?.backgroundColor
              : userRole === UserRoleName.Provider && thisCaseStatus === 'editNotRequired'
              ? headerStyle?.backgroundColor
              : userRole === UserRoleName.Provider && thisCaseStatus === 'readOnly'
              ? '#D0D0D0'
              : !isOpen && !isMobile
              ? '#D0D0D0'
              : headerStyle?.backgroundColor
              ? headerStyle?.backgroundColor
              : '',
          borderRadius: !isOpen && !isMobile ? '11px' : '',
        }}
        onClick={handleFilterOpening}
      >
        <div className={!patientStyle ? styles.titleText : styles.pTitleText} style={titleStyle}>
          {header}
        </div>
        <div className={styles.arrowIcon}>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</div>
      </div>
      <div
        className={!patientStyle ? styles.collapsibleContent : styles.pCollapsibleContent}
        style={{ border: !isOpen ? 'none' : '', height, ...collapsibleContentStyles }}
      >
        <div ref={ref}>
          <div
            className={!patientStyle ? styles.collapsibleContentPadding : styles.pCollapsibleContentPadding}
            style={contentContainerStyle}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Collapsible.defaultProps = {
  open: false,
  headerStyle: {},
  titleStyle: {},
  contentContainerStyle: {},
  collapsibleStyles: {},
  collapsibleContentStyles: {},
  error: false,
  patientStyle: false,
  isMobile: false,
};

export default Collapsible;
