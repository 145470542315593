/* eslint-disable no-param-reassign */
import qs from 'qs';

import { client } from '../client';

export interface Value {
  data: string;
}

export interface FormAnswerValue {
  id: number;
  form_answer_id: number;
  form_question_id: number;
  value: Value;
  created_by_id: number;
  created_at: Date;
  updated_at?: string | null;
}

export interface FormAnswer {
  id: number;
  form_id: number;
  values: FormAnswerValue[];
  created_by_id: number;
  created_at: Date;
  updated_at?: string | null;
}

export interface FormAnswerRes {
  total_count: number;
  form_answers: FormAnswer[];
}

export interface IListCasesParams {
  form_id: number;
  created_by_id?: number;
  page_number?: number;
  page_size?: number;
}

export const getFormAnswer = async (params: IListCasesParams): Promise<FormAnswerRes> => {
  if (params.created_by_id === 0) {
    params.created_by_id = undefined;
  }
  const { data } = await client.get(`/form_answers?${qs.stringify(params, { skipNulls: true })}`);

  return data;
};
