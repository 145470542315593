import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import modalClose from '../../../assets/icons/close.svg';
import styles from './styles.module.scss';

interface IModal {
  openModal: { isOpen: boolean; type: string };
  setOpenModal: ({ isOpen, type }: { isOpen: boolean; type: string }) => void;
}

const PaymentModal = ({ openModal, setOpenModal }: IModal): JSX.Element => {
  const navigate = useNavigate();
  const goToDashboard = useCallback(() => navigate('/dashboard'), [navigate]);

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <button
          type="button"
          className={styles.modalCloseBtn}
          style={{ backgroundImage: `url(${modalClose})` }}
          aria-label="Close modal"
          onClick={() => setOpenModal({ isOpen: false, type: '' })}
        />
        <div className={styles.body}>
          {openModal.type === 'imperfect' ? (
            <p className={styles.title}>Please fill in your payment information</p>
          ) : (
            <p className={styles.title}>Payment declined. Please update your payment information to proceed</p>
          )}
          <div className={styles.modalButtons}>
            {openModal.type === 'imperfect' ? (
              <button
                type="button"
                onClick={() => {
                  setOpenModal({ isOpen: false, type: '' });
                }}
                id="cancelBtn"
              >
                OK
              </button>
            ) : (
              <>
                <button type="button" onClick={goToDashboard}>
                  Go to Homepage
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenModal({ isOpen: false, type: '' });
                  }}
                  id="cancel"
                >
                  Go to Payment
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentModal;
