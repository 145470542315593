import { client } from '../client';
import { UserProfile } from './types/UserProfile';

export const verifyEmailAddress = async (code: string): Promise<UserProfile> => {
  const { data } = await client.post(`/users/verify_email`, {
    code,
  });

  return data;
};
