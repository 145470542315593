export enum NotificationType {
  message = 'message',
  case_created = 'case_created',
  case_updated = 'case_updated',
  case_completed = 'case_completed',
  case_canceled = 'case_canceled',
  case_ca_unassigned = 'case_ca_unassigned',
  case_provider_unassigned = 'case_provider_unassigned',
  appointment_reminder_hour = 'appointment_reminder_hour',
  appointment_reminder_min = 'appointment_reminder_min',
  patient_vc_joined = 'patient_vc_joined',
  provider_vc_joined = 'provider_vc_joined',
  ca_vc_joined = 'ca_vc_joined',
  patient_vc_not_joined = 'patient_vc_not_joined',
  provider_vc_not_joined = 'provider_vc_not_joined',
  instant_vc_not_started = 'instant_vc_not_started',
  cc_reauthorization_failed = 'cc_reauthorization_failed',
  case_new_conversation_created = 'case_new_conversation_created',
  mobile_handoff = 'mobile_handoff',
  mobile_case_creation_complete = 'mobile_case_creation_complete',
}

export interface INotification {
  id: number;
  recipient_id: number;
  acknowledged_at: Date | null;
  type: NotificationType;
  body: string | null;
  payload: any | null;
  domain?: string;
  created_at?: string;
}
