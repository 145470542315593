import { client } from '../client';

export interface ITags {
  id: number;
  description: string;
  patient_owned: boolean;
  name: string;
}

export const listAllTags = async (): Promise<ITags[]> => {
  const { data } = await client.get('/tags');

  return data;
};
