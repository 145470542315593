import { client } from '../client';
import { Insurance } from './types/UserProfile';

export const createPatientInsurance = async (payload: {
  user_id?: number;
  insurance: Insurance[];
}): Promise<Insurance[]> => {
  const { data } = await client.post<Insurance[]>(`/insurances`, payload);

  return data;
};
