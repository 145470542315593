import { verifyPassword } from '@brands/services/auth/verifyPassword';
import { getSecondaryColor } from '@brands/Utils/getPrimaryColor';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { TbEye } from 'react-icons/tb';

import { toastError } from '../../../Utils/toastError';
import Button from '../../Button/Button';
import Modal from '../Modal';
import styles from './styles.module.scss';

interface ConfirmPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  buttonNumber: number;
  isSelected: number | null;
  setIsSelected: (value: number | null) => void;
  title?: string;
}

const ConfirmPasswordModal: FC<ConfirmPasswordModalProps> = ({
  isOpen,
  onClose,
  email,
  buttonNumber,
  isSelected,
  setIsSelected,
  title,
}) => {
  const [password, setPassword] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    setPassword('');
  }, [isOpen]);

  const onChangeCode = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const onConfirm = async (): Promise<void> => {
    setVerifying(true);
    try {
      await verifyPassword({ email, password }).then((res) => {
        if (res.verified) {
          if (isSelected === buttonNumber) {
            setIsSelected(null);
            return;
          }
          setIsSelected(buttonNumber);
          onClose();
        }
      });
    } catch (err) {
      toastError(err);
    }
    setVerifying(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p className={styles.label}>
          Password <span>*</span>
        </p>
        <div className={styles.inputWrapper}>
          <span className={styles.btnShowPass}>
            {passwordShown ? (
              <TbEye
                className={styles.eyeIcon}
                style={{ color: '#276fe7' }}
                title="Hide Password"
                onClick={() => setPasswordShown(false)}
              />
            ) : (
              <TbEye
                className={styles.eyeIcon}
                style={{ color: getSecondaryColor() }}
                title="Show Password"
                onClick={() => setPasswordShown(true)}
              />
            )}
          </span>
          <input
            className={styles.input}
            type={passwordShown ? 'text' : 'password'}
            value={password}
            onChange={onChangeCode}
          />
        </div>
        <div className={styles.actionBtnContainer}>
          <Button type="button" className={styles.cancel} onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" disabled={!password || verifying} className={styles.submit} onClick={onConfirm}>
            Verify
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmPasswordModal.defaultProps = {
  title: 'Add your account password to save this update',
};

export default ConfirmPasswordModal;
