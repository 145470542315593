import * as Yup from 'yup';

import {
  isValidAddress,
  isValidCreditCardNumber,
  isValidCvvNumber,
  isValidExpiryDate,
  isValidFullName,
  isValidZipCode,
} from '../../../Utils/validation';

export const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Name on card is required')
    .test('test-name', 'Enter Valid Full Name', (value) => {
      if (value && !isValidFullName(value)) {
        return false;
      }
      return true;
    }),
  address1: Yup.string()
    .required('Billing address is required')
    .test('test-name', 'Enter Valid Full Name', (value) => {
      if (value && !isValidAddress(value)) {
        return false;
      }
      return true;
    }),
  address2: Yup.string(),
  creditCardNumber: Yup.string()
    .required('Credit card number is required')
    .test('test-name', 'Enter Valid Credit Card Number', (value) => {
      if (value && !isValidCreditCardNumber(value)) {
        return false;
      }
      return true;
    }),
  expiryDate: Yup.string()
    .required('Expiry date is required')
    .test('test-name', 'Enter Valid Expiry Date (MM/YY)', (value) => {
      if (value && !isValidExpiryDate(value)) {
        return false;
      }
      return true;
    }),
  zipCode: Yup.string()
    .required('Zip code is required')
    .test('test-name', 'Enter Valid Zip Code', (value) => {
      if (value && !isValidZipCode(value)) {
        return false;
      }
      return true;
    }),
  cvvNumber: Yup.string()
    .required('CVV is required')
    .test('test-name', 'Enter Valid CVV Number', (value) => {
      if (value && !isValidCvvNumber(value)) {
        return false;
      }
      return true;
    }),
  state: Yup.string().test('state', 'Select State', (value, context) => {
    if (context.parent.country === 'US' && !value) {
      return false;
    }
    return true;
  }),
  country: Yup.string().required('Country is required'),
});
