import NotFoundPage from '@brands/Dashboard/NotFoundPage/NotFoundPage';
import useWindowSize from '@brands/hooks/useWindowSize';
import { CaseType } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';

import { useAppSelector } from '../../../../hooks/useReduxHook';
import { UserRoleName } from '../../../../services/identity/types/UserProfile';
import ImageUploadMobile from './ImageUploadMobile';
import ImageUploadSecondOpinion from './ImageUploadSecondOpinion';
import ImageUploadWeb from './ImageUploadWeb';

const ImageUploadValidation: React.FC = () => {
  const user: AuthState = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768 || mobileDetect;
  const { values } = useAppSelector(selectPatientForm);
  const caseType = values.find((item) => item.form_question_id === 20)?.value.caseType as string;
  const userRole = user.userInfo?.role.name;

  const isSecondOpinion = caseType === CaseType.second_opinion;
  const isAuthorizedUser =
    userRole === UserRoleName.Patient ||
    userRole === UserRoleName.OrganizationAdmin ||
    userRole === UserRoleName.SuperAdmin ||
    userRole === UserRoleName.Admin;

  const renderComponent = (): JSX.Element => {
    if (!isAuthorizedUser) {
      return <NotFoundPage />;
    }

    if (isSecondOpinion) {
      return <ImageUploadSecondOpinion />;
    }

    return isMobile ? <ImageUploadMobile /> : <ImageUploadWeb />;
  };

  return <div>{renderComponent()}</div>;
};

export default ImageUploadValidation;
