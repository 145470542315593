import React, { useEffect, useState } from 'react';

import { calculateDueDate } from '../../../Utils/calculateDueDate';

interface Props {
  video_conference_date: string;
}

const ConsultStartsIn = ({ video_conference_date }: Props): JSX.Element => {
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const dueDate = calculateDueDate(video_conference_date);
      if (dueDate) setRemainingTime(dueDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [video_conference_date]);

  return (
    <div>
      <span>Consult starts in </span>
      <span style={{ fontWeight: 'bold' }}>{remainingTime}</span>
    </div>
  );
};

export default ConsultStartsIn;
