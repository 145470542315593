import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import { closeModal, openModal } from '@brands/store/slices/modalSlice';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ConfirmationModal from '../../Components/Modals/ConfirmationModal/ConfirmationModal';
import UpdateEmailAddressModal from '../../Components/Modals/UpdateEmailAddressModal/UpdateEmailAddressModal';
import VerifyEmailAddressModal from '../../Components/Modals/VerifyEmailAddressModal/VerifyEmailAddressModal';
import { useAppSelector } from '../../hooks/useReduxHook';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import { updateEmailAddress } from '../../services/identity/updateEmailAddress';
import { toastError } from '../../Utils/toastError';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import CareAdvisorMyProfile from './CareAdvisor/MyProfile';
import OrganizationAdminMyProfile from './OrganizationAdmin/MyProfile';
import PatientMyProfile from './Patient/MyProfile';
import ProviderMyProfile from './Provider/MyProfile';

const Myprofile: React.FC = () => {
  let component: JSX.Element;
  const [newEmail, setNewEmail] = useState('');
  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);
  const navigate = useNavigate();
  const user: AuthState = useAppSelector(selectAuth);
  const dispatch = useDispatch();
  const showUpdateEmailAddressModal = useAppSelector((state) => state.modal.updateEmailAddress);
  const showUpdateEmailConfirmationModal = useAppSelector((state) => state.modal.confirmEmailAddress);
  const showVerifyEmailModal = useAppSelector((state) => state.modal.verifyEmailAddress);

  const onCloseShowUpdateEmailAddressModal = (): void => {
    dispatch(closeModal({ modal: 'updateEmailAddress' }));
  };

  const onUpdateEmailAddress = (email: string): void => {
    setNewEmail(email);
    dispatch(closeModal({ modal: 'updateEmailAddress' }));
    dispatch(openModal({ modal: 'confirmEmailAddress' }));
  };

  const onCloseEmailConfirmationModal = (): void => {
    dispatch(closeModal({ modal: 'confirmEmailAddress' }));
  };

  const onCloseVerifyEmailModal = (): void => {
    dispatch(closeModal({ modal: 'verifyEmailAddress' }));
  };

  const onContinueUpdatingEmail = async (): Promise<void> => {
    setIsUpdatingEmail(true);

    try {
      if (user.userInfo) {
        await updateEmailAddress(user.userInfo.id, newEmail);
        dispatch(openModal({ modal: 'verifyEmailAddress' }));
      }
    } catch (err) {
      toastError(err);
    }
    setIsUpdatingEmail(false);
    dispatch(closeModal({ modal: 'confirmEmailAddress' }));
  };

  switch (user.userInfo?.role.name) {
    case UserRoleName.CareAdvisor:
      component = <CareAdvisorMyProfile />;
      break;
    case UserRoleName.Provider:
      component = <ProviderMyProfile />;
      break;
    case UserRoleName.Patient:
      component = <PatientMyProfile />;
      break;
    case UserRoleName.OrganizationAdmin:
      component = <OrganizationAdminMyProfile />;
      break;
    case UserRoleName.Admin:
      navigate('/admin/my-profile');
      return null;
    case UserRoleName.SuperAdmin:
      navigate('/admin/my-profile');
      return null;
    default:
      // call 404 page
      component = <NotFoundPage />;
      break;
  }

  return (
    <div>
      {component}
      <UpdateEmailAddressModal
        isOpen={showUpdateEmailAddressModal}
        onClose={onCloseShowUpdateEmailAddressModal}
        onSubmit={onUpdateEmailAddress}
      />
      <VerifyEmailAddressModal isOpen={showVerifyEmailModal} onClose={onCloseVerifyEmailModal} />
      <ConfirmationModal
        title="Confirmation"
        content={`Are you sure you want to update your email address? \
This will require verification of your new email address.`}
        isOpen={showUpdateEmailConfirmationModal}
        onClose={onCloseEmailConfirmationModal}
        onConfirm={onContinueUpdatingEmail}
        isSubmitDisabled={isUpdatingEmail}
      />
    </div>
  );
};

export default Myprofile;
