import { client } from '../client';
import { ICase } from './types/ICase';

export interface AddAddtionalInfoPayload {
  additional_info: string;
}

export const addAdditionalInfo = async (case_id: number, payload: AddAddtionalInfoPayload): Promise<ICase> => {
  const { data } = await client.post(`/cases/${case_id}/additional_info`, payload);

  return data;
};
