import { client } from '../client';

interface Response {
  url: string;
}

export const getEventSubscribeUrl = async (): Promise<Response> => {
  const { data } = await client.post<Response>('/events/subscribe');

  return data;
};
