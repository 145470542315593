import qs from 'qs';

import { client } from '../client';
import { UserProfile, UserRoleName } from './types/UserProfile';

export interface IListUsers {
  nodes: UserProfile[];
  total: number;
}

export interface IListUsersParams {
  limit?: number;
  q?: string;
  skip?: number;
  page?: number;
  sort_by?: 'full_name' | 'email' | 'status' | 'type';
  sort_direction?: 'asc' | 'desc';
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
  type?: (
    | UserRoleName.CareAdvisor
    | UserRoleName.Provider
    | UserRoleName.Patient
    | UserRoleName.Admin
    | UserRoleName.SuperAdmin
    | UserRoleName.OrganizationAdmin
    | UserRoleName.Dependent
  )[];
}

export const listAllUsers = async (params?: IListUsersParams): Promise<IListUsers> => {
  const { data } = await client.get<IListUsers>(
    `/admin/users?${qs.stringify(params, { skipNulls: true, arrayFormat: 'repeat' })}`,
  );

  return data;
};
