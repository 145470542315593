import { client } from '../client';
import { UserProfile } from './types/UserProfile';

export interface CloseAccountPayload {
  close_reason_id: number;
  close_reason_text: string;
}

export const closeAccount = async (payload: CloseAccountPayload): Promise<UserProfile> => {
  const { data } = await client.post<UserProfile>('/me/close', payload);

  return data;
};
