import qs from 'qs';

import { client } from '../client';
import { IGMMessage } from './types/IGMMessage';
import { Meta } from './types/Meta';

export interface IListConversationMessages {
  nodes: IGMMessage[];
  meta: Meta;
}

export interface IListConversationMessagesParams {
  limit?: number;
  cursor_direction?: 'next' | 'previous';
  cursor?: string;
  search?: string;
}

export const listConversationMessages = async (
  conversationSid: string,
  params?: IListConversationMessagesParams,
): Promise<IListConversationMessages> => {
  const { data } = await client.get<IListConversationMessages>(
    `/conversations/${conversationSid}/messages?${qs.stringify(params, { skipNulls: true })}`,
  );
  return data;
};
