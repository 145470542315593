import { JSONValue } from '@twilio/conversations';

import { client } from '../client';
import { IGMConversation } from './types/IGMConversation';

export interface CreateConversationPayload {
  friendly_name?: string;
  participants: Array<{
    identity: string;
  }>;
  attributes?: JSONValue;
  conversation_type: string;
  case_id: number;
}

export const createConversation = async (payload: CreateConversationPayload): Promise<IGMConversation> => {
  const { data } = await client.post<IGMConversation>('/conversations', payload);

  return data;
};
