import React from 'react';

import styles from './styles.module.scss';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
  confirmChangeCaseTypeAndAssignProvider: () => void;
}

const ConfirmChangeCaseTypeModal = ({ setOpenModal, confirmChangeCaseTypeAndAssignProvider }: IModal): JSX.Element => {
  const changeCaseType = (): void => {
    confirmChangeCaseTypeAndAssignProvider();
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.body}>
          <p>Are you sure that you want to update the case type to a scheduled video call?</p>
          <div className={styles.modalButtons}>
            <button
              type="button"
              onClick={() => {
                changeCaseType();
              }}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenModal(false);
              }}
              id="cancelBtn"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmChangeCaseTypeModal;
