import { signOut } from '@brands/Utils/signOut';
import React, { useState } from 'react';

import { useAppDispatch } from '../../../hooks/useReduxHook';
import { displayErrorDetails } from '../../../Utils/displayError';

interface Props {
  setOpenModal: (arg0: boolean) => void;
}

const Logout = ({ setOpenModal }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [loading, setloading] = useState(false);

  const logOut = async (): Promise<void> => {
    setloading(true);
    try {
      await signOut(dispatch);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setloading(false);
  };

  return (
    <div className="modalBackground" style={{ zIndex: '999' }} onClick={() => setOpenModal(false)}>
      <div className="modalContainer logout-modal" onClick={(e) => e.stopPropagation()}>
        <div className="body">
          <p>Do you want to log out?</p>
          <div className="modal-buttons" style={{ flexDirection: 'row', marginTop: 'auto' }}>
            <button disabled={loading} type="button" onClick={() => logOut()}>
              Yes
            </button>
            <button type="button" id="cancelBtn" onClick={() => setOpenModal(false)}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Logout;
