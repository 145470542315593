import { mapProfileToMyProfileSubmit } from '@brands/store/actions/careAdvisorAction';
import { selectAuth } from '@brands/store/selectors/auth';
import { setCareAdvisorMyProfile } from '@brands/store/slices/careAdvisorMyProfile';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MultiValue } from 'react-select';

import BlueCircle from '../../../assets/icons/light-blue-circle.svg';
import GrayCircle from '../../../assets/icons/light-gray-circle.svg';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { defaultUserValues } from '../../../services/cases/types/defaultUserValues';
import { getCurrentProfile } from '../../../services/identity/getCurrentProfile';
import { getUserById } from '../../../services/identity/getUserById';
import { listAllLanguages } from '../../../services/identity/listAllLanguages';
import { Language, UserProfile } from '../../../services/identity/types/UserProfile';
import { displayErrorDetails } from '../../../Utils/displayError';
import { OptionLanguage } from '../../../Utils/selectOptions';
import Sidenav from '../../Sidenav/Sidenav';
import ChangeUserStatusModal from '../Modal/ChangeUserStatusModal';
import ResetPasswordModal from '../Modal/ResetPasswordModal';
import EditAccountSecurity from '../Patient/Sections/AccountSecurity/EditAccountSecurity';
import ReadOnlyAccountSecurity from '../Patient/Sections/AccountSecurity/ReadOnlyAccountSecurity';
import styles from '../styles.module.scss';
import EditProfile from './Sections/EditProfile';
import ReadOnlyProfile from './Sections/ReadOnlyProfile';
import { CAMyProfileSubmit } from './utils/types';

type ReviewMyProfileProps = {
  reviewProfile?: boolean;
};

const MyProfile = ({ reviewProfile }: ReviewMyProfileProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const userId = useParams().userId as string;
  const { userInfo } = useAppSelector(selectAuth);
  const [thisUser, setThisUser] = useState<UserProfile>(userInfo || defaultUserValues);
  const [loading, setLoading] = useState(true);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [currentAvatarURL, setCurrentAvatarURL] = useState<string>('');
  const [image, setImage] = React.useState<string>();
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const [validationResponseId, setValidationResponseId] = useState('');
  const [resetPasswordConfirmation, setResetPasswordConfirmation] = useState<{ isOpen: boolean; userState: string }>({
    isOpen: false,
    userState: thisUser.state || '',
  });
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [changeUserStatusFlag, setChangeUserStatusFlag] = useState('');
  const [myProfileForm, setMyProfileForm] = useState<CAMyProfileSubmit>({} as CAMyProfileSubmit);
  const [isEditSecurity, setIsEditSecurity] = useState(false);

  const disableButton = (): void => {
    setResetEmailSent(true);
  };

  const disableDeleteButton = (): void => {
    setThisUser({
      ...thisUser,
      status: 'Deactivated',
    });
  };

  const getCurrentUser = async (id: string): Promise<void> => {
    try {
      setLoading(true);
      if (reviewProfile && userId) {
        const res = await getUserById(Number(id));
        setThisUser(res);
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetails = async (): Promise<void> => {
    const userDetails = await getCurrentProfile();
    setThisUser(userDetails);
  };

  useEffect(() => {
    (async (): Promise<void> => {
      if (userId) {
        await getCurrentUser(userId);
      } else {
        await fetchUserDetails();
      }
      setLoading(false);
    })();
  }, [userId]);

  const fetchLanguageOptions = async (): Promise<void> => {
    const data = await listAllLanguages();
    const transformedData = data.map((language) => ({
      value: language.locale,
      label: language.name,
      id: language.id,
    }));
    setLanguageOptions(transformedData);
  };

  useEffect(() => {
    fetchLanguageOptions();
  }, []);

  const onEditProfile = (): void => {
    setAvatarAction('');
    setIsEditProfile(true);
  };

  const fetchProfile = (): void => {
    const mappedProfile = mapProfileToMyProfileSubmit(thisUser);

    function isLanguageArray(value: Language[] | string[]): value is Language[] {
      return Array.isArray(value) && value.length > 0 && value[0] instanceof Object && 'id' in value[0];
    }

    let selectedLanguage: MultiValue<OptionLanguage> = [];
    if (isLanguageArray(mappedProfile.languages)) {
      for (let i = 0; i < languageOptions.length; i++) {
        for (let j = 0; j < mappedProfile.languages.length; j++) {
          if (Number(languageOptions[i].id) === Number(mappedProfile.languages[j].id)) {
            selectedLanguage = [...selectedLanguage, languageOptions[i]];
          }
        }
      }
    }

    dispatch(
      setCareAdvisorMyProfile({
        ...(mappedProfile as CAMyProfileSubmit),
        language: selectedLanguage,
      }),
    );
    setMyProfileForm({
      ...mappedProfile,
      language: selectedLanguage,
      profile_img: currentAvatarURL,
    });
  };

  useEffect(() => {
    fetchProfile();
  }, [thisUser, languageOptions]);

  if (loading) {
    return <Loading fullScreen />;
  }

  return (
    <div className={styles.profileContainer}>
      <img className={styles.bkgTopImage} src={BlueCircle} alt="profile" />
      <img className={styles.bkgBottomImage} src={GrayCircle} alt="profile" />
      <Sidenav isVisibleOnMobile />
      <div className={styles.contentContainer}>
        <div>
          {isEditProfile ? (
            <EditProfile
              myProfileForm={myProfileForm}
              setMyProfileForm={setMyProfileForm}
              setImage={setImage}
              image={image}
              setIsEditProfile={setIsEditProfile}
              setCurrentAvatarURL={setCurrentAvatarURL}
              currentAvatarURL={currentAvatarURL}
              thisUser={thisUser}
              setThisUser={setThisUser}
              avatarAction={avatarAction}
              setAvatarAction={setAvatarAction}
              reviewProfile={reviewProfile}
              languageOptions={languageOptions}
              setLoading={setLoading}
              userId={userId}
              validationResponseId={validationResponseId}
              setValidationResponseId={setValidationResponseId}
            />
          ) : (
            <ReadOnlyProfile
              userId={userId}
              onEditProfile={onEditProfile}
              setImage={setImage}
              image={image}
              thisUser={thisUser}
              setThisUser={setThisUser}
              reviewProfile={reviewProfile}
              resetEmailSent={resetEmailSent}
              setChangeUserStatusFlag={setChangeUserStatusFlag}
              setResetPasswordConfirmation={setResetPasswordConfirmation}
            />
          )}
          <div className={styles.separator} />
          {isEditSecurity ? (
            <EditAccountSecurity setIsEditSecurity={setIsEditSecurity} />
          ) : (
            <ReadOnlyAccountSecurity setIsEditSecurity={setIsEditSecurity} />
          )}
          <div className={styles.separator} />
          <div
            className={styles.resetPassword}
            style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '20px', width: '100%' }}
          >
            <button
              type="button"
              className={styles.resetPasswordButton}
              onClick={(): void =>
                setResetPasswordConfirmation({ isOpen: true, userState: thisUser.status || 'Active' })
              }
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
      {resetPasswordConfirmation.isOpen && (
        <ResetPasswordModal
          setOpenModal={setResetPasswordConfirmation}
          disableButton={disableButton}
          userId={Number(userId)}
          userEmail={thisUser.email}
          userName={thisUser.full_name}
          isDeactivated={thisUser.status === 'Deactivated'}
          userState={resetPasswordConfirmation.userState}
        />
      )}
      {changeUserStatusFlag && (
        <ChangeUserStatusModal
          setOpenModal={setChangeUserStatusFlag}
          disableButton={disableDeleteButton}
          userId={Number(userId)}
          userName={thisUser.full_name}
        />
      )}
    </div>
  );
};
MyProfile.defaultProps = {
  reviewProfile: false,
};
export default MyProfile;
