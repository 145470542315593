import React, { FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface Validator {
  id: number;
  form_question_id: number;
  type: string;
  operator: string;
  value: string;
  custom_error_message: string;
  created_at: string;
  updated_at: string | null;
}

interface QuestionAndRadioButtonProps {
  element: {
    id: number;
    form_id: number;
    form_step_id: number;
    title: string;
    description: string | null;
    is_required: boolean;
    is_conditional: boolean;
    type: string;
    options: string[] | [] | null;
    validators: Validator[] | [];
    created_at: string;
    updated_at: string | null;
  };
  form: any;
  setForm: any;
  noneOfTheAboveFlag?: boolean;
  noneOfTheAboveQuestionId?: number | undefined;
}

const QuestionAndRadioButton: FC<QuestionAndRadioButtonProps> = React.forwardRef(
  ({ element, form, setForm, noneOfTheAboveFlag, noneOfTheAboveQuestionId }, ref) => {
    const [isSelected, setIsSelected] = useState(false);
    const [description, setDescription] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
      if (form[`question_${element.id}`] === 'no') {
        setIsSelected(false);
      } else {
        setIsSelected(true);
        if (element.id) {
          setDescription(form[`question_${element.id}`]);
          setIsEdit(true);
        }
      }
    }, []);

    useEffect(() => {
      if (noneOfTheAboveFlag && noneOfTheAboveQuestionId && Number(element.id) !== Number(noneOfTheAboveQuestionId)) {
        setIsSelected(form[`question_${element.id}`] === 'yes');
        if (Number(element.id) !== Number(noneOfTheAboveQuestionId) && form[`question_${element.id}`] === 'yes') {
          setForm((prev: any) => ({
            ...prev,
            [`question_${noneOfTheAboveQuestionId}`]: 'no',
          }));
        }
      }
    }, [form[`question_${element.id}`]]);

    useEffect(() => {
      if (noneOfTheAboveFlag && noneOfTheAboveQuestionId) {
        setForm((prev: any) => {
          const updatedForm = { ...prev };
          Object.keys(updatedForm).forEach((key: string) => {
            if (key.startsWith('question_') && key) {
              updatedForm[key] = 'no';
            }
          });
          updatedForm[`question_${noneOfTheAboveQuestionId}`] = 'yes';
          return updatedForm;
        });
      }
    }, [noneOfTheAboveFlag]);

    useEffect(() => {
      if (element.type === 'textarea') {
        if (description && isSelected) {
          setForm((prev: any) => ({
            ...prev,
            [`question_${element.id}`]: description.trim(),
          }));
        } else if (!description && isSelected) {
          if (element.type === 'textarea') {
            setForm((prev: any) => ({
              ...prev,
              [`question_${element.id}`]: '',
            }));
          } else {
            setForm((prev: any) => ({
              ...prev,
              [`question_${element.id}`]: 'yes',
            }));
          }
        } else {
          setForm((prev: any) => ({
            ...prev,
            [`question_${element.id}`]: 'no',
          }));
        }
      } else {
        setForm((prev: any) => ({
          ...prev,
          [`question_${element.id}`]: isSelected ? 'yes' : 'no',
        }));
      }
    }, [description, isSelected]);

    return (
      <div
        className={`${styles.row} row ${styles.medicalFormRow} ${isSelected ? styles.selectClass : ''}`}
        onClick={() => {
          setDescription('');
          setIsEdit(true);
          if (!isSelected) {
            setIsSelected(true);
          } else {
            setIsSelected(false);
          }
        }}
      >
        <div className={styles.questionAndButtonContainer}>
          <div className={`col-6 col-sm-3 px-0 ${styles.select}`}>
            <div className={styles.check}>
              <input
                className={`form-check-input ${styles.checkInput} mt-0 ${isSelected ? styles.primaryColor : ''}`}
                type="checkbox"
                name={`question_${element.id}`}
                id={`question_${element.id}`}
                value={isSelected ? 'no' : 'yes'}
                ref={ref as any}
              />
            </div>
          </div>
          <div className={`${styles.question} col-12 col-sm-8 px-0`}>
            <div className={`${styles.label}`}>{element.title}</div>
          </div>
        </div>
        {element.type === 'textarea' && isSelected && isEdit && (
          <div className={styles.selectSelf} onClick={(e) => e.stopPropagation()}>
            <div className={styles.textField}>
              <span className={styles.textFieldTitle}>{element.description || 'Please tell us more'}</span>
              <input
                name={`question_${element.id}`}
                type="text"
                className={element.description && element.description.length > 32 ? styles.placeholder : null}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                ref={ref as any}
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);

QuestionAndRadioButton.defaultProps = {
  noneOfTheAboveFlag: false,
  noneOfTheAboveQuestionId: undefined,
};

export default QuestionAndRadioButton;
