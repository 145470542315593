/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';

import useVideoContext from './useVideoContext';

export default function useLocalVideoToggle() {
  const {
    room,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
    backgroundSettings,
    setBackgroundSettings,
  } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video',
  ) as LocalVideoTrack;
  const [isPublishing, setIspublishing] = useState(false);

  const toggleVideoEnabled = useCallback(async () => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        await getLocalVideoTrack()
          .then((track: LocalVideoTrack) => {
            localParticipant?.publishTrack(track, { priority: 'low' });
          })
          .catch(onError)
          .finally(() => {
            setIspublishing(false);
          });
        await setTimeout(() => {
          setBackgroundSettings(backgroundSettings);
        }, 100);
      }
    }
  }, [
    isPublishing,
    videoTrack,
    localParticipant,
    removeLocalVideoTrack,
    setBackgroundSettings,
    getLocalVideoTrack,
    onError,
    backgroundSettings,
  ]);

  return [!!videoTrack, toggleVideoEnabled] as const;
}
