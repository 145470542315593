import React, { FC, ReactNode, useMemo } from 'react';
import ReactModal from 'react-modal';

import { useWindowSize } from '../../hooks';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  children: ReactNode;
}

export const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    width: '100%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '480px',
    overflow: 'hidden',
    background: '#F0F0F0',
    borderRadius: '11px',
    height: 'fit-content',
  },
};

export const customStylesMobile = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    width: '100%',
    height: 'fit-content',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '410px',
    overflow: 'hidden',
    background: '#F0F0F0',
    borderRadius: '11px',
  },
};

const Modal: FC<ModalProps> = ({ isOpen, onClose, shouldCloseOnOverlayClick, children }) => {
  const screenSize = useWindowSize();
  const isMobile = useMemo(() => screenSize.width < 768, [screenSize]);

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onClose}
      style={isMobile ? customStylesMobile : customStyles}
    >
      {children}
    </ReactModal>
  );
};

Modal.defaultProps = {
  shouldCloseOnOverlayClick: true,
};
export default Modal;
