import { ConnectOptions } from 'twilio-video';

import { isMobile } from '../../Utils';

export default function useConnectionOptions(): ConnectOptions {
  const connectionOptions: ConnectOptions = {
    bandwidthProfile: {
      video: {
        mode: 'collaboration',
        dominantSpeakerPriority: 'standard',
        trackSwitchOffMode: 'predicted',
        contentPreferencesMode: 'auto',
        clientTrackSwitchOffControl: 'auto',
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },

    maxAudioBitrate: Number('16000'),

    preferredVideoCodecs: 'auto',
  };

  if (isMobile && connectionOptions?.bandwidthProfile?.video) {
    connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
  }

  return connectionOptions;
}
