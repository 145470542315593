/* eslint-disable @typescript-eslint/no-shadow */
import { FollowUp } from '@brands/Dashboard/ConsultationPage/assets/types';
import { PharmacySubmit } from '@brands/Dashboard/MyProfile/Patient/Sections/Pharmacy/EditPharmacy';

import { client } from '../client';
import { IEducation } from './types/IEducation';
import { IOneReport } from './types/IOneReport';

export enum ObjectType {
  Prescription = 'prescription',
  Pharmacy = 'pharmacy',
  FollowUp = 'follow_up',
  Education = 'education',
  Treatment = 'treatment',
}
export interface ITreatmentsOptoinal {
  category?: string;
  tooth_area?: string;
  tooth_number?: string;
  description?: string;
  urgency?: string;
  id?: string;
}

export interface IPrescriptionOptoinal {
  drug?: string;
  sig?: string;
  prescription_date?: string;
  dispense?: string;
  refills?: string;
  id?: string;
}

export const updateOneReportObject = async (
  one_report_id: string,
  object_type: ObjectType,
  payload: ITreatmentsOptoinal | IPrescriptionOptoinal | IEducation | PharmacySubmit | FollowUp,
): Promise<IOneReport> => {
  const { data } = await client.put<IOneReport>(`/one_reports/${one_report_id}/${object_type}/${payload.id}`, payload);

  return data;
};
