/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import { MedicalHistorySubmit } from '@brands/Dashboard/MedicalHistory/MedicalHistory/utils/types';
import { createFormAnswer } from '@brands/services/forms/createFormAnswer';
import { getForm } from '@brands/services/forms/getForm';
import { FormAnswerValue, getFormAnswer } from '@brands/services/forms/getFormAnswer';
import { listForms } from '@brands/services/forms/listForms';
import { FormQuestion } from '@brands/services/forms/types/FormQuestion';
import { updateFormAnswer } from '@brands/services/forms/updateFormAnswer';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPageState } from '@brands/store/selectors/pageState';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { FormValues } from '@brands/store/slices/formAnswersSlice';
import { addFormItem } from '@brands/store/slices/formQuestionsSlice';
import { setDependentId, setIsFromSelectPatient } from '@brands/store/slices/pageStateSlice';
import { selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { Option, stateOptions } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../../../Components/Button/Button';
import PhoneInput from '../../../../../Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '../../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHook';
import { UserProfile, UserRoleName } from '../../../../../services/identity/types/UserProfile';
import styles from '../../../styles.module.scss';
import { displayPhoneNumber, isValidPhoneNumberFormat } from '../../MyProfile';
import { pharmacyValidationSchema } from '../../utils/validationSchema';

type EditPharmacyProps = {
  medicalHistoryForm: Record<string, { type: string; value: string }>;
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile | undefined>>;
  setIsEditPharmacy: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setPharmacyName: React.Dispatch<React.SetStateAction<string>>;
  setPharmacyAddress: React.Dispatch<React.SetStateAction<string>>;
  setPharmacyPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  dependentId: number | undefined;
};

export type PharmacySubmit = {
  pharmacy_name: string;
  pharmacy_street_address: string;
  pharmacy_city: string;
  pharmacy_state: string;
  pharmacy_zip_code: string;
  pharmacy_phone_number: string;
  id?: string;
};

const EditPharmacy = ({
  medicalHistoryForm,
  thisUser,
  setThisUser,
  setIsEditPharmacy,
  loading,
  setPharmacyName,
  setPharmacyAddress,
  setPharmacyPhoneNumber,
  dependentId,
}: EditPharmacyProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { values } = useAppSelector(selectPatientForm);
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient } = useAppSelector(selectPatientForm);
  const { isFromSelectPatient } = useAppSelector(selectPageState);
  const [formId, setFormId] = useState(0);
  const [questions, setQuestions] = React.useState<FormQuestion[]>();
  const [defaultValues, setDefaultValues] = useState<MedicalHistorySubmit>({});
  const [formAnswerId, setFormAnswerId] = useState<number | null>(null);
  const [currentMedicalHistoryForm, setCurrentMedicalHistoryForm] = useState<MedicalHistorySubmit>({
    ...defaultValues,
  });

  function getEnhancedGivingOptions(options: Option[]): any {
    return [{ value: 'noState', label: 'Select a state' }, ...options];
  }

  const enhancedGivingOptions = getEnhancedGivingOptions(stateOptions);

  const getCurrentValuesPayload = (): FormValues[] => {
    return [
      ...Object.keys(currentMedicalHistoryForm).map((value) => {
        const questionId = Number(value.replace('question_', ''));
        return {
          form_question_id: questionId,
          value: { data: currentMedicalHistoryForm[value as keyof MedicalHistorySubmit] },
        };
      }),
    ];
  };

  const setValuesPayload = (data: MedicalHistorySubmit): FormValues[] => {
    const updatedForm: FormValues[] = getCurrentValuesPayload();

    Object.keys(data).forEach((value) => {
      const key = value.replace(/_/g, ' ').toLowerCase();
      const question = questions?.find((q) => q.title.toLowerCase() === key);

      if (question) {
        const formQuestionId = question.id;
        const existingIndex = updatedForm.findIndex((item) => item.form_question_id === formQuestionId);
        if (existingIndex !== -1) {
          updatedForm[existingIndex] = {
            form_question_id: formQuestionId,
            value: { data: data[value as keyof MedicalHistorySubmit] },
          };
        } else {
          updatedForm.push({
            form_question_id: formQuestionId,
            value: { data: data[value as keyof MedicalHistorySubmit] },
          });
        }
      }
    });

    return updatedForm;
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<PharmacySubmit>({
    mode: 'onChange',
    resolver: yupResolver(pharmacyValidationSchema()),
  });

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const onSubmitPharmacy = async (profileData: PharmacySubmit): Promise<void> => {
    if (!formAnswerId) {
      await createFormAnswer({
        form_id: formId,
        user_id:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? (thisUser as UserProfile).id
            : Number(userInfo.id),
        values: [...setValuesPayload(profileData)],
        dependent_id: dependentId ? Number(dependentId) : undefined,
      });
    } else {
      await updateFormAnswer(
        formAnswerId,
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? (thisUser as UserProfile).id
          : Number(userInfo.id),
        {
          values: [...setValuesPayload(profileData)],
        },
        dependentId ? Number(dependentId) : undefined,
      );
    }
    setThisUser({
      ...thisUser,
      pharmacy_name: profileData.pharmacy_name,
      pharmacy_street_address: profileData.pharmacy_street_address,
      pharmacy_city: profileData.pharmacy_city,
      pharmacy_state: profileData.pharmacy_state,
      pharmacy_zip_code: profileData.pharmacy_zip_code,
      pharmacy_phone_number: profileData.pharmacy_phone_number,
    });
    let address = '';
    Object.keys(profileData).forEach((key) => {
      const value = profileData[key as keyof PharmacySubmit];
      if (key === 'pharmacy_name' && value) {
        setPharmacyName(value);
      } else if (
        key === 'pharmacy_street_address' ||
        key === 'pharmacy_city' ||
        key === 'pharmacy_state' ||
        key === 'pharmacy_zip_code'
      ) {
        if (value) {
          address += `${value}, `;
        }
      } else if (key === 'pharmacy_phone_number') {
        setPharmacyPhoneNumber(
          value && isValidPhoneNumberFormat(value) ? (displayPhoneNumber(value) as string) : (value as string),
        );
      }
    });

    setPharmacyAddress(address.slice(0, -2));
    setIsEditPharmacy(false);
    if (isFromSelectPatient) {
      dispatch(setIsFromSelectPatient(false));
      dispatch(setDependentId(null));
      navigate('/select-patient', {
        state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
      });
    }
  };

  const onCancelEditPharmacy = (): void => {
    setIsEditPharmacy(false);
    if (isFromSelectPatient) {
      dispatch(setIsFromSelectPatient(false));
      dispatch(setDependentId(null));
      navigate('/select-patient', {
        state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
      });
    }
  };

  useEffect(() => {
    if (Object.keys(medicalHistoryForm).length > 0) {
      const pharmacyInfo: Record<string, unknown> = Object.entries(medicalHistoryForm)
        .filter(([key]) => key.startsWith('Pharmacy'))
        .reduce((acc: Record<string, unknown>, [key, value]) => {
          const lowerCaseKey = key.toLowerCase().replace(/\s+/g, '_') as keyof PharmacySubmit;
          acc[lowerCaseKey] = value.value;
          return acc;
        }, {});

      Object.keys(pharmacyInfo).forEach((key) => {
        setValue(key as keyof PharmacySubmit, pharmacyInfo[key as keyof PharmacySubmit] as string, {
          shouldValidate: !loading,
        });
      });
    }
  }, [medicalHistoryForm, setValue]);

  const getFormAnswers = useCallback(
    async (formIds: number, defaultVal: MedicalHistorySubmit) => {
      let formAnsId: number | null = null;
      const temporaryValues = { ...defaultVal };
      const formAnswers = await getFormAnswer({
        form_id: formIds,
        created_by_id: dependentId || Number(selectedPatient?.value),
      });

      Object.values(formAnswers.form_answers)
        .sort((a, b) => a.id - b.id)
        .every((answer) => {
          let check = true;
          Object.values(answer.values)
            .sort((a, b) => a.form_question_id - b.form_question_id)
            .forEach((value: FormAnswerValue) => {
              if (Object.keys(temporaryValues).includes(`question_${value.form_question_id}`)) {
                temporaryValues[`question_${value.form_question_id}`] = value.value.data;
                check = false;
                formAnsId = answer.id;
              }
            });
          return check;
        });
      return { formAnswerIdentity: formAnsId, formObj: temporaryValues };
    },
    [defaultValues, dispatch, formId, selectedPatient?.value],
  );

  useEffect(() => {
    (async () => {
      let defaultFormValues = {};
      const { forms: formList } = await listForms();
      const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
      setFormId(newFormId);
      await getForm(newFormId.toString()).then((res) => {
        if (res) {
          defaultFormValues = res.steps[0].questions.reduce((acc: MedicalHistorySubmit, question: FormQuestion) => {
            return {
              ...acc,
              [`question_${question.id}`]: 'no',
            };
          }, {});
          setDefaultValues(defaultFormValues);
          dispatch(
            addFormItem({
              form_id: res.id,
              step_id: res.steps[0].id,
              questions: res.steps[0].questions,
            }),
          );
        }
        setQuestions(res?.steps[0].questions);
      });
      const { formAnswerIdentity, formObj } = await getFormAnswers(newFormId, defaultFormValues);
      setFormAnswerId(formAnswerIdentity);
      setCurrentMedicalHistoryForm(formObj);
    })();
  }, [thisUser]);

  return (
    <div className={styles.editProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Pharmacy</span>
      </div>
      <div className={styles.editContainer}>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitPharmacy, onInvalid)}>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy Name</div>
              <TextField
                {...register('pharmacy_name')}
                errors={errors}
                name="pharmacy_name"
                value={getValues('pharmacy_name') ?? ''}
                onChange={(e) => {
                  setValue('pharmacy_name', e.target.value, { shouldValidate: true });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy Address</div>
              <TextField
                {...register('pharmacy_street_address')}
                errors={errors}
                name="pharmacy_street_address"
                value={getValues('pharmacy_street_address') ?? ''}
                onChange={(e) => {
                  setValue('pharmacy_street_address', e.target.value, { shouldValidate: true });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy City</div>
              <TextField
                {...register('pharmacy_city')}
                errors={errors}
                name="pharmacy_city"
                value={getValues('pharmacy_city') ?? ''}
                onChange={(e) => {
                  setValue('pharmacy_city', e.target.value, { shouldValidate: true });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy State</div>
              <SelectInput
                {...register('pharmacy_state')}
                name="pharmacy_state"
                options={enhancedGivingOptions}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={
                  getValues('pharmacy_state') === '' ||
                  getValues('pharmacy_state') === null ||
                  getValues('pharmacy_state') === undefined
                    ? enhancedGivingOptions[0]
                    : getValues('pharmacy_state')
                }
                onChange={(value) => {
                  if (value) {
                    setValue('pharmacy_state', value.value === 'noState' ? '' : value.value, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy Zip Code</div>
              <TextField
                {...register('pharmacy_zip_code')}
                errors={errors}
                name="pharmacy_zip_code"
                value={getValues('pharmacy_zip_code') ?? ''}
                onChange={(e) => {
                  setValue('pharmacy_zip_code', e.target.value, { shouldValidate: true });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
                isUnderlined={false}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy Phone number</div>
              <PhoneInput
                {...register('pharmacy_phone_number')}
                value={getValues('pharmacy_phone_number') ?? ''}
                id="pharmacy_phone_number"
                onChange={(val) => {
                  setValue('pharmacy_phone_number', (val as string) ?? '', {
                    shouldValidate: true,
                  });
                }}
                inputClass={styles.input}
                errors={errors}
                wrapperClassName={styles.inputWrapper}
                isUnderlined={false}
              />
            </div>
          </div>
          <div className={styles.actionBtnContainer}>
            <Button type="button" className={styles.cancel} onClick={onCancelEditPharmacy}>
              Cancel
            </Button>
            <Button type="submit" className={styles.submit}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPharmacy;
