import { client } from '../client';

export interface ITags {
  id: number;
  description: string;
  patient_owned: boolean;
  name: string;
}

export const getMyTags = async (dependent_id?: number): Promise<ITags[]> => {
  let endpoint = '/tags/me';
  if (dependent_id && dependent_id !== undefined) {
    endpoint += `?dependent_id=${dependent_id}`;
  }

  const { data } = await client.get(endpoint);

  return data;
};
