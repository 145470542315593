import React from 'react';

import { downloadConversation } from '../../services/communication/downloadConversation';
import styles from './styles.module.scss';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
  conversationSid: string | undefined;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadModal = ({ setOpenModal, conversationSid, setLoading }: IModal): JSX.Element => {
  const newDate = new Date();

  const getFileName = (): string => `${newDate}_sample-file`;

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const downloadFunc = async (): Promise<void> => {
    setOpenModal(false);
    setLoading(true);

    try {
      const data = await downloadConversation(conversationSid!, { timezone: timeZone });

      // Create a Blob with the PDF data
      const blob = new Blob([data], { type: 'application/pdf' });

      // Create a download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${getFileName()}.pdf`;

      // Append the link to the body and trigger a click event
      document.body.appendChild(a);
      a.click();

      // Open the PDF in a new tab
      window.open(url, '_blank');

      // Remove the link from the body
      document.body.removeChild(a);

      // Release the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the conversation:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.body}>
          <p>Do you want to download this chat?</p>
          <div className={styles.modalButtons}>
            <button
              type="button"
              onClick={() => {
                downloadFunc();
              }}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenModal(false);
              }}
              id="cancelBtn"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DownloadModal;
