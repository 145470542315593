import PWDRequisite from '@brands/Components/PasswordValidation/PasswordValidation';
import { changePassword } from '@brands/services/auth/changePassword';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { getSecondaryColor } from '@brands/Utils/getPrimaryColor';
import { hasCapsLetter, hasNumber, hasSpecialChar } from '@brands/Utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TbEye } from 'react-icons/tb';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { toastError } from '../../../Utils/toastError';
import Modal from '../Modal';
import styles from './styles.module.scss';

interface ResetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  title?: string;
}

interface ResetPasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({ isOpen, onClose, email, title }) => {
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const [PWDRequisiteFlag, setPWDRequisiteFlag] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialFlag: false,
  });

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .min(8)
      .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[><?@_+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-])(?=.{8,})/),
    confirmNewPassword: Yup.string()
      .required('Confirm New Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<ResetPasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onReset = async (data: ResetPasswordForm): Promise<void> => {
    if (data.newPassword !== data.confirmNewPassword) {
      toastError('New passwords do not match.');
      return;
    }

    try {
      await changePassword({ email, old_password: data.currentPassword, new_password: data.newPassword })
        .then(() => {
          toast.success('Password changed successfully');
        })
        .catch((error) => {
          displayErrorDetails(error);
        })
        .finally(() => {
          onClose();
        });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'newPassword') setPWDRequisiteFlag(true);
    const { value } = e.target;
    setValue(e.target.name as 'currentPassword' | 'newPassword' | 'confirmNewPassword', value);
    if (e.target.name === 'newPassword') {
      const capsLetterCheck = hasCapsLetter(value);
      const numberCheck = hasNumber(value);
      const specialFlag = hasSpecialChar(value);
      const pwdLengthCheck = value.length >= 8;
      setChecks({
        capsLetterCheck,
        numberCheck,
        pwdLengthCheck,
        specialFlag,
      });
    }
    if (
      errors.confirmNewPassword !== undefined ||
      errors.newPassword !== undefined ||
      errors.currentPassword !== undefined ||
      getValues('confirmNewPassword') === '' ||
      getValues('newPassword') === '' ||
      getValues('currentPassword') === '' ||
      getValues('confirmNewPassword') !== getValues('newPassword')
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <form className={styles.formContainer} onSubmit={handleSubmit(onReset)}>
          <div>
            <div className={styles.inputWrapper} data-validate="Enter password">
              <span className={styles.btnShowPass}>
                {currentPasswordShown ? (
                  <TbEye
                    className={styles.eyeIcon}
                    style={{ color: '#276fe7' }}
                    title="Hide Password"
                    onClick={() => setCurrentPasswordShown(false)}
                  />
                ) : (
                  <TbEye
                    className={styles.eyeIcon}
                    style={{ color: getSecondaryColor() }}
                    title="Show Password"
                    onClick={() => setCurrentPasswordShown(true)}
                  />
                )}
              </span>
              <input
                {...register('currentPassword')}
                className={`${styles.input100} ${errors.currentPassword ? 'is-invalid' : ''}`}
                type={currentPasswordShown ? 'text' : 'password'}
                name="currentPassword"
                placeholder="Current Password"
                onChange={handleOnChange}
                style={{ paddingRight: '24px' }}
              />
              <span className={`${styles.focusInput100} ${styles.password}`} />
            </div>
          </div>
          <div>
            <div className={styles.inputWrapper} data-validate="Enter password">
              <span className={styles.btnShowPass}>
                {passwordShown ? (
                  <TbEye
                    className={styles.eyeIcon}
                    style={{ color: '#276fe7' }}
                    title="Hide Password"
                    onClick={() => setPasswordShown(false)}
                  />
                ) : (
                  <TbEye
                    className={styles.eyeIcon}
                    style={{ color: getSecondaryColor() }}
                    title="Show Password"
                    onClick={() => setPasswordShown(true)}
                  />
                )}
              </span>
              <input
                {...register('newPassword')}
                className={`${styles.input100} ${errors.newPassword ? 'is-invalid' : ''}`}
                type={passwordShown ? 'text' : 'password'}
                name="newPassword"
                placeholder="New Password"
                onChange={handleOnChange}
                style={{ paddingRight: '24px' }}
              />
              <span className={`${styles.focusInput100} ${styles.password}`} />
              {PWDRequisiteFlag ? (
                <PWDRequisite
                  capsLetterFlag={checks.capsLetterCheck ? 'valid' : 'invalid'}
                  numberFlag={checks.numberCheck ? 'valid' : 'invalid'}
                  pwdLengthFlag={checks.pwdLengthCheck ? 'valid' : 'invalid'}
                  specialFlag={checks.specialFlag ? 'valid' : 'invalid'}
                />
              ) : (
                <>
                  <div className={`${styles.invalidFeedback} invalid-feedback`}>Password is required</div>
                  {!errors.newPassword?.message && (
                    <p className={styles.passwordHint}>
                      ● 8 characters &nbsp;● 1 uppercase &nbsp;● 1 number &nbsp;● 1 special character
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.inputWrapper} data-validate="Enter password" style={{ marginTop: '40px' }}>
            <span className={styles.btnShowPass}>
              {repeatPasswordShown ? (
                <TbEye
                  className={styles.eyeIcon}
                  style={{ color: '#276fe7' }}
                  title="Hide Password"
                  onClick={() => setRepeatPasswordShown(false)}
                />
              ) : (
                <TbEye
                  className={styles.eyeIcon}
                  style={{ color: getSecondaryColor() }}
                  title="Show Password"
                  onClick={() => setRepeatPasswordShown(true)}
                />
              )}
            </span>
            <input
              {...register('confirmNewPassword')}
              className={`${styles.input100} ${errors.confirmNewPassword ? 'is-invalid' : ''}`}
              type={repeatPasswordShown ? 'text' : 'password'}
              name="confirmNewPassword"
              placeholder="Confirm Password"
              onChange={handleOnChange}
              style={{ paddingRight: '24px' }}
            />
            {errors.confirmNewPassword && (
              <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.confirmNewPassword.message}</div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonBG} />
              <button type="submit" className={`${styles.formBtn} ${styles.signIn}`} disabled={isDisabled}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

ResetPasswordModal.defaultProps = {
  title: 'Reset Password',
};

export default ResetPasswordModal;
