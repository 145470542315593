import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Participant, Room } from 'twilio-video';

type SelectedParticipantContextValue = readonly [Participant | null, (participant: Participant) => void];

export const SelectedParticipantContext = createContext<SelectedParticipantContextValue>([null, () => {}]);

export default function useSelectedParticipant(): SelectedParticipantContextValue {
  const [selectedParticipant, setSelectedParticipant] = useContext(SelectedParticipantContext);
  return [selectedParticipant, setSelectedParticipant] as const;
}

type SelectedParticipantProviderProps = {
  room: Room | null;
  children: React.ReactNode;
};

export const SelectedParticipantProvider: React.FC<SelectedParticipantProviderProps> = ({ room, children }) => {
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
  const setSelectedParticipantNew = useCallback(
    (participant: Participant): void =>
      setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : participant)),
    [setSelectedParticipant],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const onDisconnect = (): void => {
        setSelectedParticipant(null);
      };
      const handleParticipantDisconnected = (participant: Participant): void =>
        setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : prevParticipant));

      room.on('disconnected', onDisconnect);
      room.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        room.off('disconnected', onDisconnect);
        room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [room]);

  const value = useMemo(
    () => [selectedParticipant, setSelectedParticipantNew] as SelectedParticipantContextValue,
    [selectedParticipant, setSelectedParticipantNew],
  );

  return <SelectedParticipantContext.Provider value={value}>{children}</SelectedParticipantContext.Provider>;
};
