/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import Button from '@brands/Components/Button/Button';
import Modal from '@brands/Components/Modals/Modal';
import React, { FC } from 'react';

import styles from './styles.module.scss';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  whatYouEntered: string;
  recommended: boolean;
  recommendedAddress: string;
  onClose: (() => void) | undefined;
  onKeepCurrentAddress: (() => void) | undefined;
  onConfirmChange: (() => void) | undefined;
}

const ValidateAddressModal: FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  title,
  whatYouEntered,
  recommended,
  recommendedAddress,
  onKeepCurrentAddress,
  onConfirmChange,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose!}>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p style={{ fontWeight: '500' }}>What you entered</p>
        <span className={styles.enteredAddressBox}>
          <p className={styles.label}>{whatYouEntered}</p>
        </span>
        {recommended && (
          <>
            <p style={{ fontWeight: '500' }}>Recommended</p>
            <span className={styles.enteredAddressBox}>
              <p className={styles.label}>{recommendedAddress}</p>
            </span>
          </>
        )}
        <div className={styles.actionBtnContainer}>
          <Button type="button" className={styles.cancel} onClick={onKeepCurrentAddress}>
            Use Anyway
          </Button>
          <Button type="button" disabled={!recommended} className={styles.submit} onClick={onConfirmChange}>
            Save Changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ValidateAddressModal;
