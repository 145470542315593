import React, { FC } from 'react';

import Button from '../../Button/Button';
import Modal from '../Modal';
import styles from './styles.module.scss';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  content: string;
  onClose: () => void;
  onConfirm: () => void;
  isSubmitDisabled: boolean;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  onConfirm,
  isSubmitDisabled,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p className={styles.label}>{content}</p>
        <div className={styles.actionBtnContainer}>
          <Button type="button" className={styles.cancel} onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" disabled={isSubmitDisabled} className={styles.submit} onClick={onConfirm}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
