export interface RiskTexts {
  cavity_score: {
    main: string;
    risk_level: {
      low: string;
      moderate: string;
      high: string;
    };
  };
  plaque_buildup_score: {
    main: string;
    risk_level: {
      low: string;
      moderate: string;
      high: string;
    };
  };
  swollen_gums_score: {
    main: string;
    risk_level: {
      low: string;
      moderate: string;
      high: string;
    };
  };
  root_recession_score: {
    main: string;
    risk_level: {
      low: string;
      moderate: string;
      high: string;
    };
  };
  gaps_detected: {
    main: string;
    risk_level: {
      low: string;
      moderate: string;
      high: string;
    };
  };
}

export const riskTexts: RiskTexts = {
  cavity_score: {
    main: 'An area of concern on a tooth refers to an area on a tooth that has raised concern due to potential issues such as decay, discoloration, or damage. A dental professional may require further examination and treatment to address any underlying issues.',
    risk_level: {
      low: 'Based on your SmileScan results, you appear to have a low risk for cavities. There are no signs of tooth structure changes that could indicate the presence of a cavity. By maintaining good oral hygiene and following a healthy diet, you can continue to keep your risk of developing cavities low.',
      moderate:
        'Based on your SmileScan results, you appear to have a moderate risk of cavities. This is due to observed changes in your tooth structure that may indicate the presence of a cavity. By maintaining good dental hygiene and following a healthy diet, you can reduce your risk of developing cavities in the future.',
      high: 'Based on your SmileScan results,  you appear to be at a high risk for cavities due to the presence of multiple areas of concern. Should it be a cavity, you can prevent it from worsening, stop the spread of infection, and reduce your risk of developing additional cavities. By receiving treatment to address and prevent cavities, maintaining good oral hygiene, and adhering to a healthy diet, you can lower your risk of future cavities.',
    },
  },
  plaque_buildup_score: {
    main: 'Tartar, also known as dental calculus, is hardened plaque that forms on the teeth and along the gum line due to mineral buildup from saliva and plaque. If not removed through regular brushing and flossing, it can lead to gum disease. Dental professionals can remove tartar through a process called scaling during regular dental cleaning.',
    risk_level: {
      low: 'Based on your SmileScan results, you have low tartar buildup. This indicates that there is minimal tartar accumulation on your teeth, and your risk of developing significant tartar buildup and related oral health issues is low.',
      moderate:
        'Based on your SmileScan results, you have a moderate tartar buildup. This shows that there is a moderate level of tartar accumulation on your teeth, indicating the need for some attention. By consistently maintaining oral hygiene practices, such as regular brushing, flossing, and dental cleanings, you can reduce the risk of further tartar buildup and maintain good oral health.',
      high: 'Based on the results of your SmileScan, it appears that you have a significant tartar buildup. This accumulation of tartar on your teeth puts you at high risk for oral health issues such as gum disease and tooth decay. It is crucial to take proactive measures to address this risk. This includes professional dental cleaning, improving oral hygiene practices, and possibly additional treatments recommended by your dentist to prevent further complications.',
    },
  },
  swollen_gums_score: {
    main: "Inflammation of gums is a condition characterized by redness, swelling, and irritation of the gum tissue. It is commonly caused by a buildup of plaque and tartar along the gumline, leading to bacterial growth and the body's immune response. If left untreated, gingivitis can progress to more severe forms of gum disease. Proper oral hygiene, including regular brushing, flossing, and professional cleanings, is essential in preventing and managing gum inflammation.",
    risk_level: {
      low: 'Based on the results of your SmileScan, Low risk for swollen gums means that fewer than 4 areas are identified in the SmileScan assessment to suggest the presence of swollen gums. This indicates minimal swelling present, and your risk of developing significant gum inflammation and related issues is low. Continuing with regular brushing, flossing, and routine dental check-ups can help you maintain healthy gums and minimize the risk of swelling.',
      moderate:
        'Based on the results of your SmileScan, Moderate risk for swollen gums means that 4 to 6 areas were identified in the SmileScan assessment, indicating a moderate level of gum swelling. This suggests the presence of some inflammation that requires attention. Practicing thorough oral hygiene, including gentle brushing, flossing, and possibly using an antiseptic mouthwash, along with professional dental care, can help reduce the risk of further gum swelling and maintain overall gum health.',
      high: 'Based on the results of your SmileScan, High risk for swollen gums means that there are more than 6 areas identified in the SmileScan assessment, indicating significant gum swelling. This suggests a high risk for gum disease and related oral health issues. It is crucial to seek professional dental care to address this risk, including deep cleaning to remove plaque and tartar, as well as implementing a thorough oral hygiene routine and possibly using prescribed medications to reduce inflammation and prevent further complications.',
    },
  },
  root_recession_score: {
    main: "Gum recession refers to the process in which the margin of the gum tissue surrounding the teeth wears away or pulls back, leading to the exposure of the tooth's root. This can result in sensitivity to hot and cold substances, as well as the potential for tooth decay or tooth loss if left untreated. Causes of gum recession can include periodontal disease, aggressive tooth brushing, genetic predisposition, and poor dental care. Treatment options may include scaling and root planing, gum grafting, or other surgical procedures depending on the severity of the recession.",
    risk_level: {
      low: 'Low risk for gum recession means that there are fewer than 4 areas identified in the SmileScan assessment, indicating minimal gum recession. This suggests that your risk of developing significant gum recession and related issues is low. Consistent oral hygiene practices, regular dental check-ups, and preventive measures can help maintain healthy gums and reduce the risk of recession.',
      moderate:
        'Moderate risk for gum recession means that 4 to 6 areas were identified in the SmileScan assessment, indicating a moderate level of gum recession. This suggests the presence of some recession that requires attention. Practicing thorough oral hygiene, regular dental check-ups, and possibly preventive measures can help reduce the risk of further recession and maintain overall gum health.',
      high: 'High risk for gum recession means that more than 6 areas were identified in the SmileScan assessment, indicating significant gum recession and a high risk for oral health issues. It is essential to seek professional dental care to address this risk and prevent further complications. This may include periodontal treatment, improved oral hygiene practices, and possibly surgical interventions to address the recession and prevent additional complications.',
    },
  },
  gaps_detected: {
    main: "Gaps in teeth, also known as diastema or crooked teeth, are dental conditions in which there is uneven spacing between two or more teeth or where the teeth are not aligned properly. This can occur due to various reasons, such as genetics, improper tooth development, missing teeth, or poor oral habits. Treatment options may include braces, aligners, or dental bonding to correct the spacing or alignment of the teeth for improved dental health and aesthetics.\nThe SmileScan results use a simple 'Yes' or 'No' to indicate the presence of crooked teeth or noticeable gaps. 'Yes' means that there are crooked teeth or noticeable gaps, while 'No' means they are not present. If the SmileScan results indicate a 'Yes' for crooked teeth or noticeable gaps, the patient should consider scheduling a consultation with a dentist or orthodontist to discuss potential treatment options. If the results show a 'No' the patient may continue regular dental care and maintenance.",
    risk_level: {
      low: '',
      moderate:
        '',
      high: '',
    },
  },
};
