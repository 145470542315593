import NotFoundPage from '@brands/Dashboard/NotFoundPage/NotFoundPage';
import React from 'react';
import { useParams } from 'react-router-dom';

import { UserRoleName } from '../../../services/identity/types/UserProfile';
import AdminMyProfile from '../Admin/MyProfile';
import CareAdvisorMyProfile from '../CareAdvisor/MyProfile';
import OrganizationAdmin from '../OrganizationAdmin/MyProfile';
import PatientMyProfile from '../Patient/MyProfile';
import ProviderMyProfile from '../Provider/MyProfile';

const ReviewProfile = (): JSX.Element => {
  const roleName = useParams().role as string;
  let component: JSX.Element;

  switch (roleName) {
    case UserRoleName.CareAdvisor:
      component = <CareAdvisorMyProfile reviewProfile />;
      break;
    case UserRoleName.Provider:
      component = <ProviderMyProfile reviewProfile />;
      break;
    case UserRoleName.Patient:
    case UserRoleName.Dependent:
      component = <PatientMyProfile reviewProfile />;
      break;
    case UserRoleName.Admin:
    case UserRoleName.SuperAdmin:
      component = <AdminMyProfile reviewProfile />;
      break;
    case UserRoleName.OrganizationAdmin:
      component = <OrganizationAdmin reviewProfile />;
      break;
    default:
      // call 404 page
      component = <NotFoundPage />;
      break;
  }
  return <div>{component}</div>;
};
export default ReviewProfile;
