import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import React, { memo, useEffect } from 'react';

import { useSelectedParticipant, useSpeakerViewParticipants, useVideoContext } from '../../hooks';
import changeViewIcon from '../assets/change-view.svg';
import Participant from '../Participant/Participant';
import styles from './styles.module.scss';

interface ParticipantListType {
  showVideoThumbnail?: boolean;
}

const ParticipantList = ({ showVideoThumbnail }: ParticipantListType): JSX.Element | null => {
  const { room, isSideBySide, setIsSideBySide } = useVideoContext();
  const { is_visible: isVisible } = useAppSelector(selectVideoCallCredentials);
  const localParticipant = room?.localParticipant;
  const speakerViewParticipants = useSpeakerViewParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const hideLocalParticipant = selectedParticipant && selectedParticipant !== localParticipant;

  useEffect(() => {
    if (localParticipant?.videoTracks && localParticipant.videoTracks.size > 0) {
      const firstVideoTrack = localParticipant.videoTracks.values().next().value;
      if (firstVideoTrack && firstVideoTrack.track.isMuted && isVisible) {
        room?.refreshInactiveMedia();
      }
    }
  }, [localParticipant]);

  return (
    <div className={styles.videoParticipantList} key={Number(isSideBySide)}>
      {speakerViewParticipants.length > 0 && !selectedParticipant && (
        <div className={styles.changeViewButton}>
          <div
            className={styles.changeViewIcon}
            onClick={() => setIsSideBySide(!isSideBySide)}
            style={{ backgroundImage: `url(${changeViewIcon})` }}
          />
        </div>
      )}
      {localParticipant && !showVideoThumbnail && (
        <Participant
          participant={localParticipant}
          onClick={() => setSelectedParticipant(localParticipant)}
          hideParticipant={hideLocalParticipant}
          isSelected={selectedParticipant === localParticipant}
          showVideoThumbnail={showVideoThumbnail}
        />
      )}
      {speakerViewParticipants.map((participant) => {
        const isSelected = participant === selectedParticipant;
        const hideParticipant = selectedParticipant && !isSelected;
        return (
          <Participant
            key={participant.sid}
            participant={participant}
            onClick={() => setSelectedParticipant(participant)}
            hideParticipant={hideParticipant}
            isSelected={isSelected}
            showVideoThumbnail={showVideoThumbnail}
          />
        );
      })}
    </div>
  );
};

ParticipantList.defaultProps = {
  showVideoThumbnail: false,
};

export default memo(ParticipantList);
