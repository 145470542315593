import React, { memo } from 'react';
import Modal from 'react-modal';

import { customStylesVirtualBackground } from '../../Utils/customStyles';
import Button from '../Button/Button';
import styles from './styles.module.scss';

interface IModal {
  onClose: () => void;
}

const IdleNoticeModal: React.FC<IModal> = ({ onClose }) => {
  return (
    <div className={styles.modalBackground}>
      <Modal isOpen onRequestClose={onClose} shouldCloseOnOverlayClick style={customStylesVirtualBackground}>
        <div className="d-flex flex-wrap justify-content-center align-items-center gap-2">
          You've been idle for a while. Are you still there?
        </div>
        <Button
          type="button"
          style={{ marginTop: '16px', maxWidth: '150px', marginLeft: 'auto', marginRight: 'auto' }}
          onClick={onClose}
        >
          Yes
        </Button>
      </Modal>
    </div>
  );
};

Modal.setAppElement('#root');

export default memo(IdleNoticeModal);
