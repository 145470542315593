// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg version=%271.1%27 id=%27Capa_1%27 xmlns=%27http://www.w3.org/2000/svg%27  fill=%27%237ed321%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 x=%270px%27 y=%270px%27 width=%279px%27 height=%279px%27 viewBox=%270 0 405.272 405.272%27 style=%27enable-background:new 0 0 405.272 405.272;%27 xml:space=%27preserve%27%3E%3Cg%3E%3Cpath d=%27M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836 c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064 c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z%27/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qQl9mnJYr9BCg1Ms_fvl{font-size:10px;color:#cb0000}.qQl9mnJYr9BCg1Ms_fvl::before{content:"● "}.E0jcDZ5QOPR6pxF_bnfg{font-size:10px;color:#7ed321}.E0jcDZ5QOPR6pxF_bnfg::before{content:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) " "}@media(max-width: 480px){.E0jcDZ5QOPR6pxF_bnfg{font-size:8px !important}.qQl9mnJYr9BCg1Ms_fvl{font-size:8px !important}}`, "",{"version":3,"sources":["webpack://./src/brands/default/Components/PasswordValidation/styles.module.scss"],"names":[],"mappings":"AAAA,sBAAA,cACE,CAAA,aACA,CAAA,8BAEF,YACE,CAAA,sBAEF,cACE,CAAA,aACA,CAAA,8BAEF,mDACE,CAAA,yBAIF,sBACE,wBACE,CAAA,sBAEF,wBACE,CAAA","sourcesContent":[".invalid {\n  font-size: 10px;\n  color: #cb0000;\n}\n.invalid::before {\n  content: '● ';\n}\n.valid {\n  font-size: 10px;\n  color: #7ed321;\n}\n.valid::before {\n  content: url(\"data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg'  fill='%237ed321' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='9px' height='9px' viewBox='0 0 405.272 405.272' style='enable-background:new 0 0 405.272 405.272;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836 c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064 c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E\")\n    '\\00a0 ';\n}\n\n@media (max-width: 480px) {\n  .valid {\n    font-size: 8px !important;\n  }\n  .invalid {\n    font-size: 8px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invalid": `qQl9mnJYr9BCg1Ms_fvl`,
	"valid": `E0jcDZ5QOPR6pxF_bnfg`
};
export default ___CSS_LOADER_EXPORT___;
