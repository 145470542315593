import { config } from '@brands/config/config';
import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';

import { useAppSelector } from '../../hooks/useReduxHook';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import SmileScanMobile from './Patient/SmileScanMobile';

const SmileScan: React.FC = () => {
  const user: AuthState = useAppSelector(selectAuth);

  const getComponent = (): JSX.Element => {
    if (user.userInfo?.role.name === UserRoleName.Patient && config.organization.name === 'default') {
      return <SmileScanMobile />;
    }
    return <NotFoundPage />;
  };

  return <div>{getComponent()}</div>;
};

export default SmileScan;
