/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Select, { CSSObjectWithLabel } from 'react-select';

import Button from '../../../../../Components/Button/Button';
import { Loading } from '../../../../../Components/LoadingSpinner/Loading';
import { useWindowSize } from '../../../../../hooks';
import useAvailableProviders from '../../../../../hooks/useAvailableProviders';
import useDebounce from '../../../../../hooks/useDebounce';
import { CasePayload } from '../../../../../services/cases/createNewCase';
import { CaseType, ICase } from '../../../../../services/cases/types/ICase';
import { IListAvailableProviderParams } from '../../../../../services/identity/listAvailableProvidersDate';
import { consultationSelectStyles, TextAlign } from '../../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import {
  dayOptions,
  dayTimeOptions,
  hourOptions,
  minuteOptions,
  monthOptions,
  yearOptions,
} from '../../../../../Utils/selectOptions';
import styles from './consultTime.module.scss';

interface IModal {
  openModal: (arg0: boolean) => void;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  editCase: (case_id: number, body: CasePayload) => Promise<ICase | null>;
}

const consultTimeMultiSelectStyles = {
  ...consultationSelectStyles,
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    textAlign: 'center' as TextAlign,
    fontSize: '12px',
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
};

const ConsultTime = ({ openModal, thisCase, setCase, setLoading, editCase }: IModal): JSX.Element => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 992;
  const [displayError, setDisplayError] = useState(false);
  const dateObj = new Date();
  dateObj.setHours(0, 0, 0, 0);

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentLocalTime = moment().tz(browserTimeZone);

  const utcTime = currentLocalTime.utc();

  const videoConferenceDateUTC = moment.utc(thisCase.video_conference_date);

  const [params, setParams] = useState<IListAvailableProviderParams>({
    date: videoConferenceDateUTC.isBefore(utcTime)
      ? moment().format('YYYY-MM-DD')
      : videoConferenceDateUTC.tz(browserTimeZone).format('YYYY-MM-DD'),
    tz: browserTimeZone,
    provider_id: thisCase.provider_id,
    synchronous: true,
  });

  const debouncedParams = useDebounce(params, 500);
  const { providers, dataLoading } = useAvailableProviders(debouncedParams);

  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [dayTime, setDayTime] = useState('');
  const second = '00';

  useEffect(() => {
    if (day !== '' && month !== '' && year !== '') {
      const selectedDate = new Date(Number(year), Number(month) - 1, Number(day));
      // Note: Months in the Date constructor are 0-indexed, so we subtract 1 from the month value.
      selectedDate.setHours(0, 0, 0, 0); // Set the time to midnight (00:00)
      setParams({
        ...params,
        date: moment(selectedDate).format('YYYY-MM-DD'),
      });
    }
  }, [day, month, year]);

  const consultTime = useMemo(() => {
    setDisplayError(false);
    let hour24;
    if (dayTime === 'AM') {
      hour24 = String(Number(hour) % 12).padStart(2, '0');
    } else {
      hour24 = String((Number(hour) % 12) + 12);
    }

    const date = new Date(`${year}-${month}-${day}T${hour24}:${minute}:${second}Z`);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    return utcDate;
  }, [year, month, day, hour, minute, dayTime]);

  const changeCaseDue = async (formattedDate: string): Promise<void> => {
    const payload: CasePayload = {
      video_conference_date: formattedDate,
    };

    if (typeof thisCase?.id === 'number' && formattedDate !== '') {
      try {
        setLoading(true);
        const updatedCase = await editCase(thisCase.id, payload);
        if (updatedCase) {
          setCase({
            ...thisCase,
            video_conference_date: updatedCase.video_conference_date,
            updated_at: updatedCase.updated_at,
          });
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const formatDateToCurrentTimezone = (inputDateString: string): string => {
    const NewDateObj = new Date(inputDateString);

    // Format the date to the desired format "YYYY-MM-DD HH:mm"
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZoneName: 'short',
    }).format(NewDateObj);

    return formattedDate.replace(',', '');
  };

  const isPastConsultTime = (time: Date): boolean => time < new Date();

  const handleChangeConsultTime = (): void => {
    const formattedDate = consultTime.toISOString().replace(/\.[0-9]{3}Z$/, 'Z');

    if (isPastConsultTime(consultTime)) {
      if (!displayError) setDisplayError(true);
      return;
    }

    try {
      changeCaseDue(formattedDate);
      openModal(false);
    } catch (error) {
      const errorMessage = `This provider is not available at the selected date and time. Please review the provider's availability and try again.`;
      displayErrorDetails(errorMessage);
    }
  };
  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.providerListContainer}>
          {isMobile && (
            <div className={styles.mobileHeader}>
              <p>Consult Time</p>
            </div>
          )}
          <div className={styles.mobileStickyHeader}>
            <div className={`row ${styles.providerListHeader}`}>
              <span>Consult Time</span>
              <div className={styles.btnGroup}>
                <Button
                  className={styles.btn}
                  onClick={() => {
                    handleChangeConsultTime();
                  }}
                  disabled={
                    !(day && month && year && hour && minute && dayTime) || consultTime < new Date() || displayError
                  }
                >
                  Change Consult Time
                </Button>
                <button className={styles.cBtn} type="button" onClick={() => openModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {(thisCase.type === 'video_call_scheduled' || thisCase.type === CaseType.phone_call_scheduled) &&
          !dataLoading ? (
            providers.length > 0 ? (
              providers.map((provider) => (
                <div className={`${styles.cardsContainer} row`} key={provider.id}>
                  <div className={`row ${styles.header} ${styles.requestedTime}`}>
                    <div>Provider ({provider.user.full_name}) Availability Period</div>
                    <div>
                      {provider?.sync_time_intervals.map((value, index) => (
                        <span key={index}>
                          {`${formatDateToCurrentTimezone(value.start_time)} - ${formatDateToCurrentTimezone(
                            value.end_time,
                          )}`}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={`${styles.cardsContainer} row`}>
                <div className={`row ${styles.header} ${styles.requestedTime}`}>
                  <div>This provider is not available at this date</div>
                </div>
              </div>
            )
          ) : (
            <span>
              <Loading dotted />
            </span>
          )}

          <div className={`${styles.cardsContainer} row`}>
            <div className={`col-md-5 ${styles.mobile}`}>
              <div className={`row ${styles.header}`}>
                <div className="col-md-4">Month</div>
                <div className="col-md-4">Day</div>
                <div className="col-md-4">Year</div>
              </div>
              <div className={`row ${styles.customRow}`}>
                <div className="col-md-4">
                  <div className={styles.input}>
                    <Select
                      styles={consultTimeMultiSelectStyles}
                      placeholder=""
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      options={monthOptions}
                      onChange={(selectedOption) => setMonth(selectedOption ? selectedOption.value : '')}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.input}>
                    <Select
                      styles={consultTimeMultiSelectStyles}
                      placeholder=""
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      options={dayOptions}
                      onChange={(selectedOption) => setDay(selectedOption ? selectedOption.value : '')}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.input} style={{ width: '80px' }}>
                    <Select
                      styles={consultTimeMultiSelectStyles}
                      placeholder=""
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      options={yearOptions}
                      onChange={(selectedOption) => setYear(selectedOption ? selectedOption.value : '')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-md-5 ${styles.mobile}`}>
              <div className={`row ${styles.header}`}>
                <div className="col-md-4">Hour</div>
                <div className="col-md-4">Minute</div>
              </div>
              <div className={`row ${styles.customRow}`}>
                <div className={`col-md-4 ${styles.doutDiv}`}>
                  <div className={styles.input}>
                    <Select
                      styles={consultTimeMultiSelectStyles}
                      placeholder=""
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      options={hourOptions}
                      onChange={(selectedOption) => setHour(selectedOption ? selectedOption.value : '')}
                    />
                  </div>
                  <span>:</span>
                </div>
                <div className="col-md-4">
                  <div className={styles.input}>
                    <Select
                      styles={consultTimeMultiSelectStyles}
                      placeholder=""
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      options={minuteOptions}
                      onChange={(selectedOption) => setMinute(selectedOption ? selectedOption.value : '')}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.input} style={{ width: '80px' }}>
                    <Select
                      styles={consultTimeMultiSelectStyles}
                      placeholder=""
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      options={dayTimeOptions}
                      onChange={(selectedOption) => setDayTime(selectedOption ? selectedOption.value : '')}
                    />
                  </div>
                </div>
              </div>
            </div>
            {(consultTime < new Date() || displayError) && (
              <div className={styles.warning}>
                <span>You are about to schedule a past date/time. Please try again.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConsultTime;
