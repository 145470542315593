import { saveProviderProfile } from '@brands/services/identity/saveProviderProfile';
import React, { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import { deleteUserById } from '../../../services/identity/deleteUserById';
import { displayErrorDetails } from '../../../Utils/displayError';
import styles from './styles.module.scss';

interface Modal {
  setOpenModal: Dispatch<SetStateAction<string>>;
  userId: number;
  userName: string;
  disableButton: (val: boolean) => void;
  thisUser?: any;
  setThisUser?: Dispatch<SetStateAction<any>>;
  status?: string;
}

const ChangeUserStatusModal = ({
  setOpenModal,
  userId,
  userName,
  disableButton,
  thisUser,
  setThisUser,
  status,
}: Modal): JSX.Element => {
  const resetPassword = async (): Promise<void> => {
    try {
      await deleteUserById(userId);
      disableButton(true);
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setOpenModal('');
    }
  };

  const pendingUser = async (): Promise<void> => {
    if (!thisUser || !setThisUser) return;

    try {
      await saveProviderProfile({ status: 'pending' }, thisUser.id);
      setThisUser({ ...thisUser, status: 'Pending' });
    } catch (error: unknown) {
      displayErrorDetails(error);
    }

    setOpenModal('');
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (status === 'deactivated') resetPassword();
    else pendingUser();
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.modalCloseIcon}>
          <button type="button" onClick={() => setOpenModal('')} aria-label="Close modal">
            <AiFillCloseCircle />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <span>{userName}</span>
              <span>
                {status === 'deactivated'
                  ? 'Are you sure that you want to deactivate this user?'
                  : 'Are you sure that you want to set the status for this user to Pending?'}
              </span>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button type="button" onClick={() => setOpenModal('')}>
              No
            </button>
            <button type="submit">Yes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

ChangeUserStatusModal.defaultProps = {
  thisUser: {},
  setThisUser: () => {},
  status: 'deactivated',
};

export default ChangeUserStatusModal;
