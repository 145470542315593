import { useEffect, useState } from 'react';

import { IListUsersParams, listAllUsers } from '../services/identity/listAllUsers';
import { UserProfile } from '../services/identity/types/UserProfile';

interface IUseUsers {
  users: UserProfile[];
  dataLoading: boolean;
  totalRecords: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export default function useUsers(
  params: Pick<IListUsersParams, 'sort_by' | 'sort_direction' | 'q' | 'limit' | 'type'>,
): IUseUsers {
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setDataLoading(true);
    let canceled = false;
    listAllUsers(params).then((usersList) => {
      if (!canceled) {
        setTotalRecords(usersList.total);
        setUsers([...usersList.nodes]);
        setDataLoading(false);
      }
    });

    return (): void => {
      canceled = true;
    };
  }, [params]);

  useEffect(() => {
    setCurrentPage(1);
  }, [params.q, params.type]);

  return {
    users,
    totalRecords,
    dataLoading,
    currentPage,
    setCurrentPage,
  };
}
