import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';

import { getConversation } from '../../services/communication/getConversation';
import { IGMConversation } from '../../services/communication/types/IGMConversation';

interface IUseConversationBySid {
  conversation: IGMConversation | null;
  refreshConversation: () => void;
}

export default function useConversationBySid(sid: string | null): IUseConversationBySid {
  const jwtToken = Cookies.get('jwtToken');
  const [conversation, setConversation] = useState<IGMConversation | null>(null);

  const refreshConversation = useCallback(() => {
    if (!sid) {
      setConversation(null);
      return;
    }
    getConversation(sid).then(setConversation);
  }, [sid, jwtToken]);

  useEffect(() => refreshConversation(), [refreshConversation]);

  return {
    conversation,
    refreshConversation,
  };
}
