import { client } from '../client';
import { ICase } from './types/ICase';

export type RescheduleCasePayload = {
  video_conference_date: string;
  provider_id?: number;
};

export const rescheduleCase = async (case_id: number, payload: RescheduleCasePayload): Promise<ICase> => {
  const { data } = await client.patch(`patient/reschedule_case/${case_id}`, payload);

  return data;
};
