import { client } from '../client';

interface Response {
  url: string;
}

export const getZendeskUrl = async (redirectTo?: string): Promise<Response> => {
  let url = '/zendesk';

  if (redirectTo) {
    url += `?redirect_to=${redirectTo}`;
  }

  const { data } = await client.get<Response>(url);
  return data;
};
