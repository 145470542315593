import { client } from '../client';

export interface Payload {
  username: string;
  email: string;
  password: string;
  phone_number?: string;
  attributes?: object;
  validation_data?: object;
}

export interface SignupResponse {
  message: string;
}
export const signup = async (payload: Payload): Promise<SignupResponse> => {
  const { data } = await client.post<SignupResponse>(`/auth/signup`, payload);

  return data;
};
