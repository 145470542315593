import { JSONObject, Participant } from '@twilio/conversations';

import { IGMConversationParticipant } from '../services/communication/types/IGMConversationParticipant';

export const mapToGMParticipant = (participant: Participant): IGMConversationParticipant => {
  const { full_name: fullName } = participant.attributes as JSONObject;
  return {
    sid: participant.sid,
    identity: participant.identity!,
    full_name: fullName as string,
    status: participant.type,
    role: participant.type,
    last_read_message_index: participant.lastReadMessageIndex,
    last_read_timestamp: participant.lastReadTimestamp?.toISOString(),
    created_at: participant.dateCreated!.toISOString(),
    updated_at: participant.dateUpdated!.toISOString(),
  };
};
