import * as Yup from 'yup';

import { isValidEmail, isValidPhoneNumber } from '../../../Utils/validation';

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .test('test-name', 'Enter Valid Email', (value) => {
      if (!isValidEmail(value as string) && !isValidPhoneNumber(value as string)) {
        return false;
      }
      return true;
    }),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Must include 8 characters')
    .matches(/^(?=.*[A-Z]).+$/, 'At least 1 uppercase')
    .matches(/^(?=.*[0-9]).+$/, 'At least 1 number')
    .matches(/^(?=.*[><?@_+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-]).*$/, 'At least 1 special character'),
});
