import { useCallback, useState } from 'react';
import { Room } from 'twilio-video';

const useChatWindowToggle = (room: Room | null): readonly [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleChatWindow = useCallback(() => {
    if (room) {
      if (!isOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [isOpen, room]);

  return [isOpen, toggleChatWindow] as const;
};
export default useChatWindowToggle;
