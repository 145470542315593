/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
import Button from '@brands/Components/Button/Button';
import MultiselectInput from '@brands/Components/Inputs/MultiselectInput/MultiselectInput';
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import video from '@brands/Dashboard/Dashboard/CareAdvisor/assets/liveVideo.svg';
import report from '@brands/Dashboard/Dashboard/CareAdvisor/assets/oralHealthReport.svg';
import phone from '@brands/Dashboard/Dashboard/CareAdvisor/assets/phone.svg';
import sVideo from '@brands/Dashboard/Dashboard/CareAdvisor/assets/scheduledVideo.svg';
import secondOpinion from '@brands/Dashboard/Dashboard/CareAdvisor/assets/secondOpinion.svg';
import SmileScanMobile from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_mobile.svg';
import useCases from '@brands/hooks/useCases';
import useDebounce from '@brands/hooks/useDebounce';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import useSubscribe, { Subscriber } from '@brands/hooks/useSubscribe';
import { CaseType, Status } from '@brands/services/cases/types/ICase';
import { listAllClientTags } from '@brands/services/identity/listAllClientTags';
import { listAllOrganizations } from '@brands/services/identity/listAllOrganizations';
import { listInsuranceCarriers } from '@brands/services/identity/listInsuranceCarriers';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { calculateDueDate } from '@brands/Utils/calculateDueDate';
import { multiSelectCommonCustomStylesFilter } from '@brands/Utils/customStyles';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { formatDate } from '@brands/Utils/formatDate';
import { getColorForStatus } from '@brands/Utils/getColorForStatus';
import { getFriendlyName } from '@brands/Utils/getFriendlyName';
import { getTextForStatus } from '@brands/Utils/getTextForStatus';
import {
  caseStatusOptions,
  caseTypeOptions,
  patientTypeOptions,
  paymentMethodOptions,
  stateOptions,
} from '@brands/Utils/selectOptions';
import { sortData, SortKeys, SortOrder } from '@brands/Utils/sortData';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { MdFilterList } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import ProfileComponent from '../CareAdvisor/Component/ProfileComponent';
import ConsultStartsIn from '../ConsultStartsIn/ConsultStartsIn';
import styles from './styles.module.scss';

type Option = {
  value: string;
  label: string;
  id?: string;
};

const CasesList = (): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const navigate = useNavigate();

  const [clientTagOptions, setClientTagOptions] = useState<Option[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<Option[]>([]);
  const [insurancePlanName, setInsurancePlanName] = useState<Option[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastAllCasesItemRef = useRef<HTMLTableRowElement>(null);

  const [showFilterInputs, setShowFilterInputs] = useState(false);
  const [filterValues, setFilterValues] = useState<{ [key: string]: string | string[] }>({});

  const handleFilterChange = (key: string, value: string | string[]): void => {
    setFilterValues((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if ((typeof value === 'string' && value === '') || (Array.isArray(value) && value.length === 0)) {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = value;
      }
      return updatedFilters;
    });
  };

  const fetchOrganizationOptions = async (): Promise<void> => {
    try {
      const data = await listAllOrganizations();
      if (userInfo.role.name === UserRoleName.OrganizationAdmin) {
        data.nodes = data.nodes.filter((org) => org.id === userInfo.organizations[0].id);
      }
      const transformedData = data.nodes.map((organization) => ({
        value: organization.id,
        label: organization.name,
        id: organization.id.toString(),
      }));
      setOrganizationOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const fetchClientTagsOptions = async (): Promise<void> => {
    try {
      const data = await listAllClientTags();
      const transformedData = data.nodes.map((clientTag) => ({
        value: clientTag.code as string,
        label: clientTag.name as string,
        id: clientTag.id.toString(),
      }));
      setClientTagOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const fetchInsurancePlanOptions = async (): Promise<void> => {
    try {
      const data = await listInsuranceCarriers();
      const transformedData = data.map((insurancePlan) => ({
        id: insurancePlan.id.toString(),
        label: insurancePlan.name,
        value: insurancePlan.id.toString(),
      }));
      setInsurancePlanName(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      fetchClientTagsOptions();
      fetchOrganizationOptions();
      fetchInsurancePlanOptions();
    }
  }, []);

  const params = useMemo(() => {
    const baseParams = {
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      limit: 15,
      include_patient_care_advisor_conversation: false,
      include_patient_provider_conversation: false,
      include_provider_care_advisor_conversation: false,
    };

    return {
      ...baseParams,
      ...filterValues,
    };
  }, [filterValues]);

  const debouncedParams = useDebounce(params, 1000);

  const { cases, reloadAll, loadNextPage, dataLoading } = useCases(debouncedParams, true);

  const callReloadAllOpenCase = (): void => {
    reloadAll();
  };

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (['case_updated', 'case_cancelled', 'case_new_message_created'].includes(message.command)) {
          reloadAll();
        } else if (['case_created'].includes(message.command)) {
          callReloadAllOpenCase();
        }
      },
      commands: ['case_created', 'case_updated', 'case_cancelled', 'case_new_message_created'],
    }),
    [reloadAll],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastAllCasesItemRef.current) observer.current.observe(lastAllCasesItemRef.current);
  }, [cases]);

  const headers: { key: SortKeys; label: string; isSorted: boolean; isSticky: boolean }[] = [
    { key: 'id', label: 'Case ID', isSorted: false, isSticky: true },
    { key: 'status', label: 'Case Status', isSorted: false, isSticky: true },
    { key: 'caseType', label: 'Case Type', isSorted: false, isSticky: false },
    { key: 'video_conference_date', label: 'Video/Phone Consultation Date', isSorted: false, isSticky: false },
    { key: 'completed_at', label: 'Completed Date', isSorted: false, isSticky: false },
    { key: 'updated_at', label: 'Canceled Date', isSorted: false, isSticky: false },
    { key: 'patient_current_state', label: 'Case State', isSorted: false, isSticky: false },
    { key: 'patient_role', label: 'Patient User Type', isSorted: false, isSticky: false },
    { key: 'markers', label: 'Client Tags', isSorted: false, isSticky: false },
    { key: 'brandAssoc', label: 'Case Organization', isSorted: false, isSticky: false },
    { key: 'patient', label: 'Patient', isSorted: false, isSticky: false },
    { key: 'care_advisor', label: 'Care Advisor', isSorted: false, isSticky: false },
    { key: 'provider', label: 'Provider', isSorted: false, isSticky: false },
    { key: 'payment_method', label: 'Payment Method', isSorted: false, isSticky: false },
    { key: 'patient_self_pay_cost', label: 'Patient Self Pay Cost', isSorted: false, isSticky: false },
    { key: 'insurance_carrier', label: 'Insurance Carrier', isSorted: false, isSticky: false },
    { key: 'action', label: '', isSorted: false, isSticky: true },
  ];

  const [headersState, setHeadersState] = useState(headers);
  const [sortKey, setSortKey] = useState<SortKeys>('due_date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ascn');

  const sortedData = useCallback(
    () => sortData({ tableData: cases, sortKey, reverse: sortOrder === 'desc', userRole: userInfo.role.name }),
    [cases, sortKey, sortOrder],
  );

  const changeSort = (key: SortKeys): void => {
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn');
    setSortKey(key);
    setHeadersState(
      headersState.map((header) => {
        if (sortKey === key) {
          return { ...header, isSorted: !header.isSorted };
        }
        return { ...header, isSorted: false };
      }),
    );
  };
  const [hoverText, setHoverText] = useState(0);
  return (
    <div className={styles.ccDashboardBody}>
      {!dataLoading ? (
        <>
          <MdFilterList className={styles.filterIcon} onClick={() => setShowFilterInputs(!showFilterInputs)} />
          <div className={styles.tableContainer}>
            <table className={styles.allCasesTable}>
              <thead className={`${styles.providerListTableHeader} ${styles.dashboardHeaderName} ${styles.titles}`}>
                <tr>
                  {headersState.map((row, index) => {
                    return (
                      <th
                        key={row.key}
                        onClick={() => changeSort(row.key)}
                        className={row.isSticky ? `${styles[`sticky${index + 1}`]}` : ''}
                      >
                        <span className={`${sortKey === row.key ? styles.bold : ''}`}>
                          {row.label}
                          {row.label !== '' ? (
                            row.isSorted && sortOrder === 'ascn' ? (
                              <HiChevronDown />
                            ) : (
                              <HiChevronUp />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
                {showFilterInputs && (
                  <tr className={styles.filterInputs}>
                    <th className={styles.sticky1} style={{ zIndex: '99' }}>
                      <input
                        type="text"
                        className={styles.inputSearch2}
                        value={filterValues.case_id || ''}
                        onChange={(e) => handleFilterChange('case_id', e.target.value)}
                        placeholder="Case ID"
                      />
                    </th>
                    <th className={styles.sticky2} style={{ zIndex: '99' }}>
                      <MultiselectInput
                        name="Case Status"
                        placeholder="Status"
                        options={caseStatusOptions as { value: string; label: string }[]}
                        selectorClass={styles.selector}
                        style={multiSelectCommonCustomStylesFilter}
                        containerClass={styles.filterItemSelector}
                        selectedValue={caseStatusOptions.filter((option) =>
                          filterValues.status?.includes(option.value),
                        )}
                        onChange={(selectedOptions) => {
                          let selectedValues: string[] = [];
                          if (Array.isArray(selectedOptions)) {
                            selectedValues = selectedOptions.map((option) => option.value);
                          } else if (selectedOptions) {
                            selectedValues = [(selectedOptions as Option).value];
                          }

                          handleFilterChange('status', selectedValues);
                        }}
                        isFilterInput
                      />
                    </th>
                    <th>
                      <MultiselectInput
                        name="Case Type"
                        placeholder="Type"
                        options={caseTypeOptions as { value: string; label: string }[]}
                        selectorClass={styles.selector}
                        style={multiSelectCommonCustomStylesFilter}
                        containerClass={styles.filterItemSelector}
                        selectedValue={caseTypeOptions.filter((option) => filterValues.type?.includes(option.value))}
                        onChange={(selectedOptions) => {
                          let selectedValues: string[] = [];
                          if (Array.isArray(selectedOptions)) {
                            selectedValues = selectedOptions.map((option) => option.value);
                          } else if (selectedOptions) {
                            selectedValues = [(selectedOptions as Option).value];
                          }

                          handleFilterChange('type', selectedValues);
                        }}
                        isFilterInput
                      />
                    </th>
                    <th>
                      <input
                        type="date"
                        className={styles.inputSearch2}
                        value={filterValues.consultation_date || ''}
                        onChange={(e) => handleFilterChange('consultation_date', e.target.value)}
                        placeholder="Date"
                        style={{ width: '150px' }}
                      />
                    </th>
                    <th>
                      <input
                        type="date"
                        className={styles.inputSearch2}
                        value={filterValues.completed_date || ''}
                        onChange={(e) => handleFilterChange('completed_date', e.target.value)}
                        placeholder="Completed Date"
                        style={{ width: '150px' }}
                      />
                    </th>
                    <th>
                      <input
                        type="date"
                        className={styles.inputSearch2}
                        value={filterValues.canceled_date || ''}
                        onChange={(e) => handleFilterChange('canceled_date', e.target.value)}
                        placeholder="Canceled Date"
                        style={{ width: '150px' }}
                      />
                    </th>
                    <th>
                      <MultiselectInput
                        name="Case State"
                        placeholder="State"
                        options={stateOptions as { value: string; label: string }[]}
                        selectorClass={styles.selector}
                        style={multiSelectCommonCustomStylesFilter}
                        containerClass={styles.filterItemSelector}
                        selectedValue={stateOptions.filter((option) => filterValues.case_state?.includes(option.value))}
                        onChange={(selectedOptions) => {
                          let selectedValues: string[] = [];
                          if (Array.isArray(selectedOptions)) {
                            selectedValues = selectedOptions.map((option) => option.value);
                          } else if (selectedOptions) {
                            selectedValues = [(selectedOptions as Option).value];
                          }

                          handleFilterChange('case_state', selectedValues);
                        }}
                        isFilterInput
                      />
                    </th>
                    <th>
                      <MultiselectInput
                        name="Patient Type"
                        placeholder="Patient Type"
                        options={patientTypeOptions as { value: string; label: string }[]}
                        selectorClass={styles.selector}
                        style={multiSelectCommonCustomStylesFilter}
                        containerClass={styles.filterItemSelector}
                        selectedValue={patientTypeOptions.filter((option) =>
                          filterValues.patient_user_type?.includes(option.value),
                        )}
                        onChange={(selectedOptions) => {
                          let selectedValues: string[] = [];
                          if (Array.isArray(selectedOptions)) {
                            selectedValues = selectedOptions.map((option) => option.value);
                          } else if (selectedOptions) {
                            selectedValues = [(selectedOptions as Option).value];
                          }

                          handleFilterChange('patient_user_type', selectedValues);
                        }}
                        isFilterInput
                      />
                    </th>
                    <th>
                      <MultiselectInput
                        name="Client Tags"
                        placeholder="Client Tags"
                        options={clientTagOptions as { value: string; label: string }[]}
                        selectorClass={styles.selector}
                        style={multiSelectCommonCustomStylesFilter}
                        containerClass={styles.filterItemSelector}
                        selectedValue={clientTagOptions.filter((option) =>
                          filterValues.client_tag?.includes(option.value as string),
                        )}
                        onChange={(selectedOptions) => {
                          let selectedValues: string[] = [];
                          if (Array.isArray(selectedOptions)) {
                            selectedValues = selectedOptions.map((option) => option.value);
                          } else if (selectedOptions) {
                            selectedValues = [(selectedOptions as Option).value as string];
                          }

                          handleFilterChange('client_tag', selectedValues);
                        }}
                        isFilterInput
                      />
                    </th>
                    <th>
                      <MultiselectInput
                        name="Case Organization"
                        placeholder="Case Organization"
                        options={organizationOptions as { id: string; value: string; label: string }[]}
                        selectorClass={styles.selector}
                        style={multiSelectCommonCustomStylesFilter}
                        containerClass={styles.filterItemSelector}
                        selectedValue={organizationOptions.filter((option) =>
                          filterValues.organization_id?.includes(option.id as string),
                        )}
                        onChange={(selectedOptions) => {
                          let selectedValues: string[] = [];
                          if (Array.isArray(selectedOptions)) {
                            selectedValues = selectedOptions.map((option) => option.value);
                          } else if (selectedOptions) {
                            selectedValues = [(selectedOptions as Option).id as string];
                          }

                          handleFilterChange('organization_id', selectedValues);
                        }}
                        isFilterInput
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className={styles.inputSearch2}
                        value={filterValues.patient_full_name || ''}
                        onChange={(e) => handleFilterChange('patient_full_name', e.target.value)}
                        placeholder="Patient name"
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className={styles.inputSearch2}
                        value={filterValues.care_advisor_full_name || ''}
                        onChange={(e) => handleFilterChange('care_advisor_full_name', e.target.value)}
                        placeholder="CA name"
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className={styles.inputSearch2}
                        value={filterValues.provider_full_name || ''}
                        onChange={(e) => handleFilterChange('provider_full_name', e.target.value)}
                        placeholder="Provider name"
                      />
                    </th>
                    <th>
                      <MultiselectInput
                        name="Payment Method"
                        placeholder="Payment Method"
                        options={paymentMethodOptions as { value: string; label: string }[]}
                        selectorClass={styles.selector}
                        style={multiSelectCommonCustomStylesFilter}
                        containerClass={styles.filterItemSelector}
                        selectedValue={paymentMethodOptions.filter((option) =>
                          filterValues.payment_method?.includes(option.value as string),
                        )}
                        onChange={(selectedOptions) => {
                          let selectedValues: string[] = [];
                          if (Array.isArray(selectedOptions)) {
                            selectedValues = selectedOptions.map((option) => option.value);
                          } else if (selectedOptions) {
                            selectedValues = [(selectedOptions as Option).value as string];
                          }
                          handleFilterChange('payment_method', selectedValues);
                        }}
                        isFilterInput
                      />
                    </th>
                    <th />
                    <th>
                      <MultiselectInput
                        name="insurancePlanName"
                        placeholder="Insurance Plan Name"
                        options={insurancePlanName as { id: string; value: string; label: string }[]}
                        selectorClass={styles.selector}
                        style={multiSelectCommonCustomStylesFilter}
                        containerClass={styles.filterItemSelector}
                        selectedValue={insurancePlanName.filter((option) =>
                          filterValues.insurance_carrier?.includes(option.id as string),
                        )}
                        onChange={(selectedOptions) => {
                          let selectedValues: string[] = [];
                          if (Array.isArray(selectedOptions)) {
                            selectedValues = selectedOptions.map((option) => option.value);
                          } else if (selectedOptions) {
                            selectedValues = [(selectedOptions as Option).id as string];
                          }
                          handleFilterChange('insurance_carrier', selectedValues);
                        }}
                        isFilterInput
                      />
                    </th>
                    <th />
                  </tr>
                )}
              </thead>
              {cases.length > 0 ? (
                <tbody>
                  {sortedData().map((caseRow) => {
                    return (
                      <Fragment key={caseRow.id}>
                        <tr className={`${styles.providerListTableBody}`}>
                          <td className={`${styles.mobileDue} ${styles.sticky1}`}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>CaseID</span>
                              <span className={styles.mobileDueDate}>{caseRow.id}</span>
                            </div>
                          </td>
                          <td className={`${styles.mobileStatus} ${styles.sticky2}`}>
                            <div>
                              <span className={styles.mobileHeader}>Status</span>
                              {(() => {
                                for (const key in caseRow) {
                                  if (key === 'status') {
                                    return (
                                      <span
                                        className={styles.statusSpan}
                                        style={{ backgroundColor: getColorForStatus(caseRow[key]) }}
                                      >
                                        {getTextForStatus(userInfo.role.name, caseRow.type, caseRow[key])}
                                      </span>
                                    );
                                  }
                                }
                              })()}
                            </div>
                          </td>
                          <td className={styles.mobileCType}>
                            <div className={`${styles.availability}`}>
                              <span className={styles.mobileHeader}>Type</span>
                              <div className={styles.castType}>
                                {caseRow.type === CaseType.smile_scan && (
                                  <img
                                    className={styles.smileScan}
                                    src={SmileScanMobile}
                                    alt="SmileScan"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'video_call_instant' && (
                                  <img
                                    className={styles.video}
                                    src={video}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'video_call_scheduled' && (
                                  <img
                                    className={styles.sVideo}
                                    src={sVideo}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === CaseType.phone_call_scheduled && (
                                  <img
                                    className={styles.sVideo}
                                    src={phone}
                                    alt="Phone"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'one_report' && (
                                  <img
                                    className={styles.cReport}
                                    src={report}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'second_opinion' && (
                                  <img
                                    className={styles.cReport}
                                    src={secondOpinion}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {Number(hoverText) === Number(caseRow.id) && (
                                  <div className={styles.hoverText}>{getFriendlyName(caseRow.type)}</div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className={styles.mobileDue}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>Video/Phone Consultation Date</span>
                              <span className={styles.mobileDueDate}>
                                {caseRow.video_conference_date &&
                                (caseRow.type === CaseType.phone_call_scheduled ||
                                  caseRow.type === CaseType.video_call_scheduled)
                                  ? formatDate(caseRow.video_conference_date)
                                  : 'N/A'}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileDue}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>Completed Date</span>
                              <span className={styles.mobileDueDate}>
                                {caseRow.status === Status.Completed ? formatDate(caseRow.completed_at) : 'N/A'}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileDue}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>Canceled Date</span>
                              <span className={styles.mobileDueDate}>
                                {caseRow.status === Status.Canceled && caseRow.updated_at
                                  ? formatDate(caseRow.updated_at)
                                  : 'N/A'}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileDue}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>Case State</span>
                              <span className={styles.mobileDueDate}>{caseRow.patient_current_state}</span>
                            </div>
                          </td>
                          <td className={styles.mobileDue}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>Patient User Type</span>
                              <span className={styles.mobileDueDate}>
                                {caseRow?.patient?.guardian_id === null ? 'Patient' : 'Dependent'}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileTags}>
                            <div>
                              <span className={styles.mobileHeader}>Client Tags</span>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                {caseRow.patient.markers?.map((marker) => marker.value).join(', ')}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileBrand}>
                            <div>
                              <span className={styles.mobileHeader}>Organization</span>
                              <span style={{ whiteSpace: 'nowrap' }}>{caseRow.organization.name}</span>
                            </div>
                          </td>
                          <td className={styles.mobilePatient} aria-label="Patient">
                            <ProfileComponent caseRow={caseRow} role="Patient" showImage={false} />
                          </td>
                          <td className={styles.mobileCA} aria-label="Care Advisor">
                            <ProfileComponent caseRow={caseRow} role="CA" showImage={false} />
                          </td>
                          <td className={styles.mobileProvider} aria-label="Provider">
                            <ProfileComponent caseRow={caseRow} role="Provider" showImage={false} />
                          </td>
                          <td className={styles.mobileBrand}>
                            <div>
                              <span className={styles.mobileHeader}>Payment Method</span>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                {caseRow.pricing?.amount && caseRow.pricing?.amount > 0
                                  ? caseRow?.insurance_id
                                    ? 'Insurance'
                                    : 'Self pay'
                                  : 'Free case'}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileBrand}>
                            <div>
                              <span className={styles.mobileHeader}>Patient Self Pay Cost</span>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                {caseRow.pricing?.amount && caseRow.pricing?.amount > 0
                                  ? caseRow?.insurance_id !== null
                                    ? 'N/A'
                                    : (() => {
                                        const priceInDollars = (caseRow.pricing?.amount || 0) / 100;
                                        const formattedPrice =
                                          priceInDollars === 0
                                            ? ''
                                            : priceInDollars % 1 === 0
                                            ? `$${priceInDollars.toFixed(0)}`
                                            : `$${priceInDollars.toFixed(2)}`;
                                        return formattedPrice;
                                      })()
                                  : '$0'}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <span className={styles.mobileHeader}>Insurance Carrier</span>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                {caseRow.pricing?.amount && caseRow.pricing?.amount > 0
                                  ? caseRow?.insurance_id !== null
                                    ? caseRow.insurance?.insurance_membership?.insurance_carrier?.name
                                    : 'N/A'
                                  : 'N/A'}
                              </span>
                            </div>
                          </td>

                          <td className={`${styles.mobileBtn} ${styles.stickyBtn}`}>
                            {caseRow.type !== CaseType.smile_scan && (
                              <div className={`${styles.viewBtn}`}>
                                <Button type="button" onClick={() => navigate(`/consultation-page/${caseRow.id}`)}>
                                  View
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                        {(caseRow.type === 'video_call_scheduled' || caseRow.type === CaseType.phone_call_scheduled) &&
                        caseRow.video_conference_date &&
                        calculateDueDate(caseRow.video_conference_date) ? (
                          <tr className={styles.startTime} key={`${caseRow.id}-2`}>
                            <ConsultStartsIn video_conference_date={caseRow.video_conference_date} />
                          </tr>
                        ) : (
                          <tr className={styles.noStartTime} key={`${caseRow.id}-3`}>
                            <div>
                              <span />
                            </div>
                          </tr>
                        )}
                      </Fragment>
                    );
                  })}
                  <tr className={styles.lastItemRef} ref={lastAllCasesItemRef} />
                </tbody>
              ) : (
                <span className={styles.noData}>No data to display</span>
              )}
            </table>
            {isLoading && <Loading dotted />}
          </div>
        </>
      ) : (
        <span>
          <Loading dotted />
        </span>
      )}
    </div>
  );
};
export default CasesList;
