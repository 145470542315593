import { selectAuth } from '@brands/store/selectors/auth';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useState } from 'react';

import { Meta } from '../services/communication/types/Meta';
import { IListCAParams, listAllCareAdvisors } from '../services/identity/listAllCA';
import { UserProfile } from '../services/identity/types/UserProfile';
import { useAppSelector } from './useReduxHook';

interface IUseConversations {
  careAdvisors: UserProfile[];
  reloadAll: () => Promise<void>;
  loadNextPage: () => Promise<void>;
  loadPreviousPage: () => Promise<void>;
  dataLoading: boolean;
}

export default function useCareAdvisor(
  params: Pick<IListCAParams, 'sort_by' | 'sort_direction'>,
  shouldLoadAllCases = false,
): IUseConversations {
  const { userInfo } = useAppSelector(selectAuth);
  const [careAdvisors, setCareAdvisors] = useState<UserProfile[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [firstPageMeta, setFirstPageMeta] = useState<Meta | null>(null);
  const [lastPageMeta, setLastPageMeta] = useState<Meta | null>(null);

  const sortCareAdvisors = async (careAdvisorsToSort: UserProfile[]): Promise<UserProfile[]> => {
    const filterCAsWithoutName = await careAdvisorsToSort.filter((careAdvisor) => careAdvisor.full_name);
    if (userInfo.role.name !== 'CareAdvisor' || !userInfo.full_name) {
      return filterCAsWithoutName;
    }
    const currentCareAdvisorIndex = await filterCAsWithoutName.findIndex(
      (careAdvisor) => careAdvisor.id === userInfo.id,
    );
    if (currentCareAdvisorIndex === -1) {
      return filterCAsWithoutName;
    }
    const careAdvisorsToSortCopy = [...filterCAsWithoutName];
    const currentCareAdvisorCopy = await careAdvisorsToSortCopy.splice(currentCareAdvisorIndex, 1)[0];
    return [currentCareAdvisorCopy, ...careAdvisorsToSortCopy];
  };

  const reloadAll = useCallback(async (): Promise<void> => {
    setDataLoading(true);
    const careAdvisorList = await listAllCareAdvisors(params);
    const sortedCareAdvisorList = await sortCareAdvisors(careAdvisorList.nodes);
    setCareAdvisors(sortedCareAdvisorList);
    setFirstPageMeta(careAdvisorList.meta);
    setLastPageMeta(careAdvisorList.meta);
    setDataLoading(false);
  }, []);

  const loadNextPage = async (): Promise<void> => {
    if (!lastPageMeta?.has_next_page) {
      return;
    }
    const { meta, nodes } = await listAllCareAdvisors({
      cursor: lastPageMeta.end_cursor,
      cursor_direction: 'next',
      ...params,
    });
    setLastPageMeta(meta);
    if (!firstPageMeta) {
      setFirstPageMeta(meta);
    }
    const sortedCareAdvisorList = await sortCareAdvisors(nodes);
    setCareAdvisors((prevCareAdvisors) => uniqBy([...prevCareAdvisors, ...sortedCareAdvisorList], 'id'));
  };

  const loadPreviousPage = async (): Promise<void> => {
    if (!firstPageMeta?.has_previous_page) {
      return;
    }
    const { meta, nodes } = await listAllCareAdvisors({
      cursor: firstPageMeta.start_cursor,
      cursor_direction: 'previous',
      ...params,
    });
    setFirstPageMeta(meta);
    if (!lastPageMeta) {
      setLastPageMeta(meta);
    }
    const sortedCareAdvisorList = await sortCareAdvisors(nodes);
    setCareAdvisors((prevCareAdvisors) => uniqBy([...sortedCareAdvisorList, ...prevCareAdvisors], 'id'));
  };

  useEffect(() => {
    if (shouldLoadAllCases) {
      reloadAll();
    }
  }, [shouldLoadAllCases]);

  return {
    careAdvisors,
    reloadAll,
    loadNextPage,
    loadPreviousPage,
    dataLoading,
  };
}
