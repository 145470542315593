import { isValidZipCode } from '@brands/Utils/validation';
import * as Yup from 'yup';

import { FormQuestion } from '../../../../services/forms/types/FormQuestion';

const isValidHeight = (value: string): boolean => {
  const heightRegex = /^\d+ft, \d+in$/;
  return heightRegex.test(value);
};

export const validationSchema = (
  questions: FormQuestion[] | undefined,
  medicalHistory?: boolean,
  medicalCondition?: boolean,
  dentalHistory?: boolean,
): any => {
  return Yup.object().shape({
    ...questions?.reduce((acc, question) => {
      return {
        ...acc,
        [`question_${question.id}`]: Yup.mixed()
          .test(
            'required',
            question.description
              ? `${question.title} ${question.description}`
              : `Please answer this question: ${question.title}`,
            (value) => {
              if (question.is_required) {
                if (Array.isArray(value)) {
                  return value.length > 0;
                }
                return !!value?.trim() && value !== 'no' && (isValidHeight(value) || question.title !== 'Height');
              }
              if (question.description) {
                if (value === 'no') {
                  return true;
                }
                if (value?.trim() !== '') {
                  return true;
                }
                return false;
              }
              return true;
            },
          )
          .test('medicalHistory', 'If yes, are you interested in stopping?', (value, context) => {
            if (
              medicalHistory &&
              !question.is_required &&
              question.title === 'If yes, are you interested in stopping?'
            ) {
              const selectedOptions = context.parent[`question_${question.id - 1}`];
              if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
                return (
                  context.parent[`question_${question.id}`]?.length > 0 &&
                  context.parent[`question_${question.id}`] !== 'no'
                );
              }
              return true;
            }
            return true;
          })
          .test('pharmacyZipCode', 'Not a valid pharmacy zip code', (value) => {
            if (
              medicalHistory &&
              !question.is_required &&
              question.title === 'Pharmacy Zip Code' &&
              value.trim() !== '' &&
              value !== 'no' &&
              !isValidZipCode(value)
            ) {
              return new Yup.ValidationError('Not a valid zip code');
            }
            return true;
          })
          .test('medicalCondition', 'At least one value must be checked', (value, context) => {
            if (medicalCondition && !question.is_required) {
              const values = Object.values(context.parent);
              return values.some(
                (val: any) => val === 'yes' || (typeof val === 'string' && val.trim() !== '' && val.trim() !== 'no'),
              );
            }

            return true;
          })
          .test('dentalHistory', 'Please enter a valid last dental visit date', (value) => {
            if (dentalHistory && question.is_required) {
              if (value === 'dontKnow') {
                return true;
              }
              const datePattern =
                /^(January|February|March|April|May|June|July|August|September|October|November|December), \d{4}$/;

              if (!datePattern.test(value.trim())) {
                return false;
              }
            }
            return true;
          })
          .test('dentalHistory', `You can't enter future date on last dental visit date`, (value) => {
            if (dentalHistory && question.is_required) {
              const enteredDate = new Date(value.trim());

              const currentDate = new Date();

              if (enteredDate > currentDate) {
                return false;
              }
            }
            return true;
          }),
      };
    }, {}),
  });
};
