import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';

import { useAppSelector } from '../../hooks/useReduxHook';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import PaymentPage from './PaymentPage';

const PaymentPageValidation: React.FC = () => {
  let component: JSX.Element;
  const user: AuthState = useAppSelector(selectAuth);

  switch (user.userInfo?.role.name) {
    case 'Patient':
      component = <PaymentPage />;
      break;
    default:
      // call 404 page
      component = <NotFoundPage />;
      break;
  }

  return <div>{component}</div>;
};

export default PaymentPageValidation;
