import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { formatTimeForApi } from '@brands/Utils/formatTime';
import moment from 'moment';
import React, { useEffect } from 'react';
import { BsTrash3 } from 'react-icons/bs';
import { HiPlusCircle } from 'react-icons/hi';
import { toast } from 'react-toastify';

import { generateTimeOptions } from '../../../../../../../Components/TimeInput/assets/options';
import TimeInput from '../../../../../../../Components/TimeInput/TimeInput';
import { useWindowSize } from '../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../hooks/useReduxHook';
import { addUserAvailability } from '../../../../../../../services/availability/addUserAvailability';
import { checkUserAvailability } from '../../../../../../../services/availability/checkUserAvailability';
import { deleteUserAvailability } from '../../../../../../../services/availability/deleteUserAvailability';
import { ProviderAvailability } from '../../../../../../../services/availability/types/ProviderAvailability';
import { updateUserAvailability } from '../../../../../../../services/availability/updateUserAvailability';
import { CaseType, ICase } from '../../../../../../../services/cases/types/ICase';
import { getPrimaryColor } from '../../../../../../../Utils/getPrimaryColor';
import { Option } from '../../../../../../../Utils/selectOptions';
import styles from './styles.module.scss';

export const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

type WeeklyHoursProps = {
  weeklyHours: ProviderAvailability[];
  setWeeklyHours: React.Dispatch<React.SetStateAction<ProviderAvailability[]>>;
  setAlertModal: React.Dispatch<
    React.SetStateAction<{
      cases: ICase[];
      kind: 'override' | 'weekly';
      type: 'delete' | 'update' | 'add';
      selectedDates?: Date[];
      isUnavailable?: boolean;
      slots: ProviderAvailability[];
    } | null>
  >;
};

const WeeklyHours = ({ weeklyHours, setWeeklyHours, setAlertModal }: WeeklyHoursProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const [key, setKey] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const sortByStartTime = (array: ProviderAvailability[]): ProviderAvailability[] => {
    return array.sort((a: ProviderAvailability, b: ProviderAvailability) => {
      if (a.start_time && b.start_time) {
        const aStartTime = moment(a.start_time, 'h:mmA');
        const bStartTime = moment(b.start_time, 'h:mmA');
        if (aStartTime.isBefore(bStartTime)) return -1;
        if (aStartTime.isAfter(bStartTime)) return 1;
      } else if (!a.start_time && b.start_time) {
        return 1;
      } else if (a.start_time && !b.start_time) {
        return -1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (weeklyHours.length > 0) {
      setWeeklyHours((prev) => sortByStartTime(prev));
      setKey((prev) => prev + 1);
    }
  }, []);

  const endTimeOptions = (hour: ProviderAvailability): Option[] => {
    let filteredTimeOptions = generateTimeOptions(false);

    if (hour.start_time) {
      const startTimeId = filteredTimeOptions.find((option) => option.value === hour.start_time)?.id;

      if (startTimeId) {
        filteredTimeOptions = filteredTimeOptions.filter((option) => Number(option.id) >= Number(startTimeId) + 1);
      }
    }

    return filteredTimeOptions;
  };

  const startTimeOptions = (hour: ProviderAvailability): Option[] => {
    let filteredTimeOptions = generateTimeOptions(true);

    if (hour.end_time) {
      const endTimeId = filteredTimeOptions.find((option) => option.value === hour.end_time)?.id;

      if (endTimeId) {
        filteredTimeOptions = filteredTimeOptions.filter((option) => Number(option.id) <= Number(endTimeId) - 1);
      }
    }

    return filteredTimeOptions;
  };

  const convertDayIndex = (day: number): number => {
    if (day === 0) return 6;
    return day - 1;
  };

  const addNewWeeklyHourRow = (day: number): void => {
    setWeeklyHours((prev) => [
      ...prev,
      {
        id: null,
        type: 'weekday',
        start_time: '',
        end_time: '',
        date: '',
        weekday: day,
        created_at: '',
        synchronous: true,
        asynchronous: true,
      },
    ]);
    setKey((prev) => prev + 1);
  };

  const sendWeeklyHour = async (slot: ProviderAvailability): Promise<void> => {
    if (slot.id) {
      await updateUserAvailability(userInfo.id, slot.id, {
        start_time: formatTimeForApi(slot.start_time),
        end_time: slot.end_time === '11:59pm' ? '23:59:59' : formatTimeForApi(slot.end_time),
        synchronous: slot.synchronous,
        asynchronous: slot.asynchronous,
      }).catch((err: unknown) => {
        displayErrorDetails(err);
      });
      return;
    }

    await addUserAvailability(userInfo.id, {
      type: 'weekday',
      date: new Date().toISOString(),
      weekday: slot.weekday,
      start_time: formatTimeForApi(slot.start_time),
      end_time: slot.end_time === '11:59pm' ? '23:59:59' : formatTimeForApi(slot.end_time),
      synchronous: userInfo.role.name === UserRoleName.Provider ? slot.synchronous : true,
      asynchronous: userInfo.role.name === UserRoleName.Provider ? slot.asynchronous : true,
    })
      .then((res) => {
        const updatedSlot = { ...slot, id: res.id };
        setWeeklyHours((prev) => prev.map((h) => (h === slot ? updatedSlot : h)));
        setKey((prev) => prev + 1);
      })
      .catch((err: unknown) => {
        displayErrorDetails(err);
      });
  };

  const handleTimeChange = async (
    val: Option,
    dayIndex: number,
    index: number,
    type: 'start_time' | 'end_time',
  ): Promise<void> => {
    const day = convertDayIndex(dayIndex);
    const dayHours = weeklyHours.filter((hour) => hour.weekday === day);
    const currentHour = dayHours[index];

    if (type === 'start_time' && currentHour.end_time) {
      let filteredTimeOptions = generateTimeOptions(true);

      const endTimeOption = generateTimeOptions(false).find((option) => option.value === currentHour.end_time);
      const endTimeId = endTimeOption?.id;
      const isEndTimeMidnight = currentHour.end_time === '12:00am';

      if (endTimeId) {
        filteredTimeOptions = generateTimeOptions(false).filter((option) => {
          return isEndTimeMidnight || Number(option.id) <= Number(endTimeId) - 1;
        });
      }

      if (!filteredTimeOptions.find((option) => option.value === val.value)) {
        toast.error('Start time must be at least 30 minutes before end time');
        return;
      }
    }

    let overlappedHours: ProviderAvailability[] = [];
    const modifiedCurrentHour = {
      ...currentHour,
      start_time: formatTimeForApi(currentHour.start_time),
      end_time: currentHour.end_time === '11:59pm' ? '23:59:59' : formatTimeForApi(currentHour.end_time),
    };

    modifiedCurrentHour[type] = moment(val.value, 'h:mmA').format('HH:mm:ss');

    const filteredHoursOfSelectedDay = dayHours.filter((hour) => hour.id && hour.id !== currentHour.id);
    overlappedHours = filteredHoursOfSelectedDay.filter((hour) => {
      if (
        hour.synchronous !== modifiedCurrentHour.synchronous &&
        hour.asynchronous !== modifiedCurrentHour.asynchronous
      ) {
        return false;
      }
      const startTime = moment(hour.start_time, 'h:mmA').format('HH:mm:ss');
      const endTime = moment(hour.end_time, 'h:mmA').format('HH:mm:ss');
      return (
        (startTime <= modifiedCurrentHour.start_time && modifiedCurrentHour.start_time <= endTime) ||
        (startTime <= modifiedCurrentHour.end_time && modifiedCurrentHour.end_time <= endTime) ||
        (modifiedCurrentHour.start_time !== 'Invalid date' && modifiedCurrentHour.end_time !== 'Invalid date'
          ? modifiedCurrentHour.start_time <= startTime && endTime <= modifiedCurrentHour.end_time
          : false)
      );
    });

    if (overlappedHours.length > 0) {
      toast.error(
        'You already have an existing time slot that overlaps with this time. Please review your existing times and update this time slot accordingly.',
      );
      return;
    }

    let isOverlapped: ICase[] = [];
    if (modifiedCurrentHour.id) {
      if (UserRoleName.Provider === userInfo.role.name) {
        await checkUserAvailability(userInfo.id, {
          availability_id: modifiedCurrentHour.id,
          type: 'weekday',
          weekday: modifiedCurrentHour.weekday,
          start_time: modifiedCurrentHour.start_time
            ? moment(modifiedCurrentHour.start_time, 'h:mmA').format('HH:mm:ss')
            : null,
          end_time: modifiedCurrentHour.end_time
            ? modifiedCurrentHour.end_time === '12:00am'
              ? '23:59:59'
              : moment(modifiedCurrentHour.end_time, 'h:mmA').format('HH:mm:ss')
            : null,
          synchronous: modifiedCurrentHour.synchronous,
          asynchronous: modifiedCurrentHour.asynchronous,
        })
          .then((res) => {
            if (res.length > 0) isOverlapped = res;
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }

    if (isOverlapped.length > 0) {
      setAlertModal({
        cases: isOverlapped,
        kind: 'weekly',
        type: 'update',
        slots: [modifiedCurrentHour],
      });
      return;
    }

    currentHour[type] = val.value;

    const newDayHours = dayHours.map((hour, i) => {
      if (i === index) {
        return currentHour;
      }
      return hour;
    });
    setWeeklyHours((prev) => {
      const updatedHours = prev.filter((h) => h.weekday !== day);
      return sortByStartTime([...updatedHours, ...newDayHours]);
    });

    setKey((prev) => prev + 1);

    if (
      currentHour &&
      currentHour.start_time &&
      currentHour.end_time &&
      (currentHour.synchronous || currentHour.asynchronous)
    ) {
      await sendWeeklyHour(currentHour);
    }
  };

  const handleTypeChange = async (
    dayIndex: number,
    index: number,
    type: 'synchronous' | 'asynchronous',
  ): Promise<void> => {
    const day = convertDayIndex(dayIndex);
    const dayHours = weeklyHours.filter((hour) => hour.weekday === day);
    const currentHour = dayHours[index];
    const isChecked = !currentHour[type];
    const otherType = type === 'synchronous' ? 'asynchronous' : 'synchronous';
    let isTypesOverlapped = false;

    const fetchOverlappedCases = async (): Promise<ICase[]> => {
      if (currentHour.id && UserRoleName.Provider === userInfo.role.name) {
        try {
          const res = await checkUserAvailability(userInfo.id, { availability_id: currentHour.id });
          return res.filter((item) =>
            type === 'asynchronous'
              ? item.type !== 'video_call_scheduled' && item.type !== CaseType.phone_call_scheduled
              : item.type === 'video_call_scheduled' || item.type === CaseType.phone_call_scheduled,
          );
        } catch (err) {
          displayErrorDetails(err);
          return [];
        }
      }
      return [];
    };

    // const checkOverlap = (hour: any, startTime: string, endTime: string): boolean => {
    //   const currentHourStart = moment(currentHour.start_time, 'h:mmA').format('HH:mm:ss');
    //   const currentHourEnd = moment(currentHour.end_time, 'h:mmA').format('HH:mm:ss');
    //   return (
    //     (startTime <= currentHourStart && currentHourStart <= endTime) ||
    //     (startTime <= currentHourEnd && currentHourEnd <= endTime) ||
    //     (currentHourStart <= startTime && endTime <= currentHourEnd)
    //   );
    // };

    const updateWeeklyHours = (): void => {
      const newDayHours = dayHours.map((hour, i) => (i === index ? { ...hour, [type]: isChecked } : hour));
      setWeeklyHours((prev) => {
        const updatedHours = prev.filter((h) => h.weekday !== day);
        return sortByStartTime([...updatedHours, ...newDayHours]);
      });
      setKey((prev) => prev + 1);
      currentHour[type] = isChecked;
    };

    const sendHourUpdate = async (): Promise<void> => {
      if (currentHour.start_time && currentHour.end_time && (currentHour.synchronous || currentHour.asynchronous)) {
        await sendWeeklyHour(currentHour);
      }
    };

    const isOverlapped = isChecked ? [] : await fetchOverlappedCases();

    if (isChecked) {
      dayHours.forEach((hour) => {
        const currentHourStart = moment(currentHour.start_time, 'h:mmA').format('HH:mm:ss');
        const currentHourEnd = moment(currentHour.end_time, 'h:mmA').format('HH:mm:ss');
        if (hour.id && hour.id !== currentHour.id && hour[type]) {
          const startTime = moment(hour.start_time, 'h:mmA').format('HH:mm:ss');
          const endTime = moment(hour.end_time, 'h:mmA').format('HH:mm:ss');
          if (
            (startTime <= currentHourStart && currentHourStart <= endTime) ||
            (startTime <= currentHourEnd && currentHourEnd <= endTime) ||
            (currentHourStart !== 'Invalid date' && currentHourEnd !== 'Invalid date'
              ? currentHourStart <= startTime && endTime <= currentHourEnd
              : false)
          ) {
            if (hour[type]) {
              isTypesOverlapped = true;
            }
          }
        }
      });
    }

    if (!isChecked && !currentHour[otherType]) {
      toast.error('At least one of the consultation types must be selected.');
    } else if (isTypesOverlapped) {
      toast.error(
        'You already have an existing time slot that overlaps with this time. Please review your existing times and update this time slot accordingly.',
      );
    } else if (isOverlapped.length > 0) {
      const hour = { ...currentHour, [type]: isChecked };
      setAlertModal({
        cases: isOverlapped,
        kind: 'weekly',
        type: 'update',
        slots: [hour],
      });
    } else {
      updateWeeklyHours();
      await sendHourUpdate();
    }
  };

  const checkIsDeletePossible = async (availabilityId: ProviderAvailability): Promise<ICase[]> => {
    let overlappedCases: ICase[] = [];
    if (!availabilityId.id) return [];
    if (UserRoleName.Provider === userInfo.role.name) {
      await checkUserAvailability(userInfo.id, {
        availability_id: availabilityId.id,
      })
        .then((res) => {
          overlappedCases = res;
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    return overlappedCases;
  };

  const handleDeleteHour = async (hour: ProviderAvailability, dayIndex: number, index: number): Promise<void> => {
    try {
      setLoading(true);
      const isDeletePossible = await checkIsDeletePossible(hour);

      if (
        isDeletePossible.length > 0 &&
        !isDeletePossible.every(
          (item) => item.status === 'canceled' || item.status === 'deleted' || item.status === 'completed',
        )
      ) {
        setAlertModal({
          cases: isDeletePossible,
          kind: 'weekly',
          type: 'delete',
          slots: [hour],
        });
      } else if (!hour.id) {
        const day = convertDayIndex(dayIndex);
        const dayHours = weeklyHours.filter((h) => h.weekday === day);
        dayHours.splice(index, 1);
        setWeeklyHours((prev) => {
          const updatedHours = prev.filter((h) => h.weekday !== day);
          return sortByStartTime([...updatedHours, ...dayHours]);
        });
        setKey((prev) => prev + 1);
      } else {
        await deleteUserAvailability(userInfo.id, hour.id?.toString())
          .then(() => {
            setWeeklyHours((prev) => prev.filter((h) => h.id !== hour.id));
            setKey((prev) => prev + 1);
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      displayErrorDetails(err);
    }
  };

  const renderWeeklyHoursHeader = (): JSX.Element | null => {
    if (screenSize.width >= 1024) {
      return <div className={styles.weeklyHoursHeader}>Set Weekly Hours</div>;
    }
    return null;
  };

  const renderWeeklyHoursBodyRowSelectHours = (index: number): JSX.Element => {
    const hours = weeklyHours.filter((hour) => hour.weekday === convertDayIndex(index));
    if (hours.length > 0) {
      return (
        <>
          {hours.map((hour, i) => (
            <div className={styles.weeklyHoursBodyRowSelectHourContainer} key={hour.id}>
              <div className={styles.weeklyHoursBodyRowSelectHour}>
                {endTimeOptions(hour).length > 0 && (
                  <div className={styles.weeklyHoursBodyRowSelectHoursInputContainer}>
                    <TimeInput
                      timeOptions={startTimeOptions(hour) || generateTimeOptions(true)}
                      time={
                        {
                          value: hour.start_time,
                          label: hour.start_time,
                        } as Option
                      }
                      onChange={(val) => handleTimeChange(val, index, i, 'start_time')}
                    />
                    <span className={styles.weeklyHoursBodyRowSelectHoursInputSeparator}>-</span>
                    <TimeInput
                      timeOptions={endTimeOptions(hour) || generateTimeOptions(false)}
                      time={
                        {
                          value: hour.end_time,
                          label: hour.end_time,
                        } as Option
                      }
                      disabled={hour.start_time === null}
                      onChange={(val) => handleTimeChange(val, index, i, 'end_time')}
                    />
                    <div className={styles.weeklyHoursBodyRowSelectHoursInputContainerRemove}>
                      <button
                        className={styles.weeklyHoursBodyRowSelectHoursInputContainerRemoveButton}
                        type="button"
                        onClick={() => {
                          handleDeleteHour(hour, index, i);
                        }}
                        disabled={loading}
                        aria-label="Weekly Hours Body Row Select Hours Input Container Remove Button"
                      >
                        <BsTrash3 size={18} color={getPrimaryColor()} />
                      </button>
                    </div>
                  </div>
                )}
                {userInfo.role.name === UserRoleName.Provider && (
                  <div className={styles.weeklyHoursBodyRowType}>
                    Type:
                    <div className={styles.weeklyHoursBodyRowTypeSelect}>
                      <div className={styles.checkInput}>
                        <div className="form-check m-0" style={{ minHeight: 'unset' }}>
                          <input
                            type="checkbox"
                            name="synchronous"
                            className="form-check-input"
                            checked={hour.synchronous}
                            onClick={() => {
                              handleTypeChange(index, i, 'synchronous');
                            }}
                          />
                          <label className={styles.weeklyHoursBodyRowTypeSelectText} htmlFor="synchronous">
                            Synchronous
                          </label>
                        </div>
                      </div>

                      <div className={styles.checkInput}>
                        <div className="form-check m-0" style={{ minHeight: 'unset' }}>
                          <input
                            type="checkbox"
                            name="asynchronous"
                            className="form-check-input"
                            checked={hour.asynchronous}
                            onClick={() => {
                              handleTypeChange(index, i, 'asynchronous');
                            }}
                          />
                          <label className={styles.weeklyHoursBodyRowTypeSelectText} htmlFor="asynchronous">
                            Asynchronous
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {i !== weeklyHours.filter((h) => h.weekday === convertDayIndex(index)).length - 1 && (
                <hr className={styles.weeklyHoursBodyRowSelectHoursSeparator} />
              )}
            </div>
          ))}
        </>
      );
    }
    return (
      <div className={styles.weeklyHoursBodyRowSelectHours}>
        <span className={styles.weeklyHoursBodyRowSelectHoursUnavailable}>Unavailable</span>
      </div>
    );
  };

  const renderWeeklyHoursBodyRowSelection = (day: string, index: number): JSX.Element => (
    <div className={styles.weeklyHoursBodyRowSelection}>
      <div className={styles.mobileWeeklyHoursBodyRowSelection}>
        <div className={styles.weeklyHoursBodyRowSelectDay}>
          <div className={styles.checkInput}>
            <div className="form-check m-0" style={{ minHeight: 'unset' }}>
              <label className={styles.weeklyHoursBodyRowDaySelectText} htmlFor="asynchronous">
                {day}
              </label>
            </div>
          </div>
        </div>
        {screenSize.width < 1024 && (
          <div className={styles.weeklyHoursBodyRowAddSelection}>
            <button
              className={styles.weeklyHoursBodyRowAddSelectionButton}
              type="button"
              onClick={() => addNewWeeklyHourRow(convertDayIndex(index))}
              aria-label="Weekly Hours Body Row Add Selection Button"
            >
              <HiPlusCircle size={24} color={getPrimaryColor()} />
            </button>
          </div>
        )}
      </div>
      <div className={styles.weeklyHoursBodyRowSelectHours} key={key}>
        {renderWeeklyHoursBodyRowSelectHours(index)}
      </div>
      {screenSize.width >= 1024 && (
        <div className={styles.weeklyHoursBodyRowAddSelection}>
          <button
            className={styles.weeklyHoursBodyRowAddSelectionButton}
            type="button"
            onClick={() => addNewWeeklyHourRow(convertDayIndex(index))}
            aria-label="Weekly Hours Body Row Add Selection Button"
          >
            <HiPlusCircle size={24} color={getPrimaryColor()} />
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.weeklyHoursContainer}>
      {renderWeeklyHoursHeader()}
      <div className={styles.weeklyHoursBody}>
        {days.map((day, index) => (
          <div className={styles.weeklyHoursBodyRow} key={day}>
            {renderWeeklyHoursBodyRowSelection(day, index)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklyHours;
