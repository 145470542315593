import { OralHealthReportSubmit } from '@brands/Dashboard/ConsultationPage/assets/types';
import { completeCase } from '@brands/services/cases/completeCase';
import React from 'react';

import { useWindowSize } from '../../../../../hooks';
import { cancelCase } from '../../../../../services/cases/cancelCase';
import { ICase } from '../../../../../services/cases/types/ICase';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import styles from './styles.module.scss';

interface IModal {
  openModal: React.Dispatch<
    React.SetStateAction<{
      isopen: boolean;
      type: string;
    }>
  >;
  thisCase: ICase;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  type: string;
  consultationForm: OralHealthReportSubmit;
  saveCaseNotes: (internalCasesNotes: string) => Promise<void>;
}

const CancelCaseModal = ({
  openModal,
  thisCase,
  setLoading,
  setCase,
  type,
  consultationForm,
  saveCaseNotes,
}: IModal): JSX.Element => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;

  const cancelCaseFn = async (): Promise<void> => {
    setLoading(true);

    try {
      if (consultationForm.caseNotes !== '') {
        await saveCaseNotes(consultationForm.caseNotes);
      }

      localStorage.removeItem('notes');

      if (type === 'cancel') {
        const updatedCase = await cancelCase(thisCase.id);
        setCase(updatedCase);
      } else if (type === 'complete') {
        const updatedCase = await completeCase(thisCase.id);
        setCase(updatedCase);
      }
      openModal({
        isopen: false,
        type: '',
      });
      setLoading(false);
    } catch (error: unknown) {
      openModal({
        isopen: false,
        type: '',
      });
      setLoading(false);
      displayErrorDetails(error);
    }
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.providerListContainer}>
          {isMobile && (
            <div className={styles.mobileHeader}>
              <p>Consult Update</p>
            </div>
          )}
          <div className={styles.mobileStickyHeader}>
            <div className={`row ${styles.providerListHeader}`}>
              <span>Consult Update</span>
              <div className={styles.btnGroup}>
                <button
                  className={styles.cBtn}
                  type="button"
                  onClick={() =>
                    openModal({
                      isopen: false,
                      type: '',
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className={styles.cardsContainer}>
            <div className={styles.inputsContainer}>
              <div className={styles.inputContainer}>
                <span>Case No. {thisCase.id}</span>
                <span>Are you sure that you want to {type} this case?</span>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <button
                type="button"
                onClick={() =>
                  openModal({
                    isopen: false,
                    type: '',
                  })
                }
              >
                No
              </button>
              <button type="button" onClick={() => cancelCaseFn()}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CancelCaseModal;
