import React, { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import { ICase } from '../../../../services/cases/types/ICase';
import { ImageNoteState } from '../../../../services/forms/types/ImageNote';
import { ImageWithNotes } from '../../../../services/forms/types/ImageWithNotes';
import { deleteOneReportMedia } from '../../../../services/oneReport/deleteOneReportMedia';
import { IOneReport } from '../../../../services/oneReport/types/IOneReport';
import styles from './styles.module.scss';

interface Modal {
  setOpenModal: Dispatch<SetStateAction<ImageNoteState>>;
  media_src: string;
  media_id: string;
  images: ImageWithNotes[] | undefined;
  oneReportId: string;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  updateSelectedImages: (updatedReport: IOneReport) => void;
}

const RemoveOneReportImage = ({
  setOpenModal,
  media_src,
  media_id,
  images,
  oneReportId,
  thisCase,
  setCase,
  updateSelectedImages,
}: Modal): JSX.Element => {
  const image = images?.find((selectedImage) => selectedImage.src === media_src);

  const removeMedia = async (): Promise<void> => {
    const updatedOneReport = await deleteOneReportMedia(oneReportId, media_id);
    setCase({
      ...thisCase,
      one_report: updatedOneReport,
    });
    updateSelectedImages(updatedOneReport);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    removeMedia();
    setOpenModal({ isOpen: false, media_src: '', media_id: '' });
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.imageContainer}>
        <div className={styles.image}>
          <img src={image?.src} alt="modal display for notes" />
        </div>
      </div>
      <div className={styles.modalContainer}>
        <div className={styles.modalCloseIcon}>
          <button
            type="button"
            onClick={() => setOpenModal({ isOpen: false, media_src: '', media_id: '' })}
            aria-label="Close Modal"
          >
            <AiFillCloseCircle />
          </button>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <span>Are you sure that you want to remove this photo?</span>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button type="button" onClick={() => setOpenModal({ isOpen: false, media_src: '', media_id: '' })}>
              No
            </button>
            <button type="submit">Yes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RemoveOneReportImage;
