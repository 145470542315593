import { ConnectionState, Conversation, ConversationUpdateReason } from '@twilio/conversations';
import Cookies from 'js-cookie';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';

import { IListConversationsParams } from '../../services/communication/listConversations';
import { IGMConversation } from '../../services/communication/types/IGMConversation';
import { mapToGMConversation } from '../../Utils/mapToGMConversation';
import useTwilioConversationsClient from './useTwilioConversationsClient';

interface IUseConversations {
  ccJoin: string;
}

export default function useConversations(
  params: Pick<IListConversationsParams, 'include_participants' | 'include_inactive' | 'sort_by' | 'sort_direction'>,
): IUseConversations {
  const client = useTwilioConversationsClient();
  const jwtToken = Cookies.get('jwtToken');
  const [conversations, setConversations] = useState<IGMConversation[]>([]);
  const [ccJoin, setCCJoin] = useState<string>('');

  const onConversationUpdated = (data: {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
  }): void => {
    const idx = conversations.findIndex((m) => m.sid === data.conversation.sid);
    if (idx >= 0) {
      conversations[idx].friendly_name = data.conversation.friendlyName;
    }
  };

  const onConversationJoined = async (conversation: Conversation): Promise<void> => {
    const newConversation = await mapToGMConversation(conversation, params.include_participants);
    setConversations((prevConversations) => uniqBy([newConversation, ...prevConversations], 'sid'));
    setCCJoin(newConversation.sid);
  };

  const onConversationLeft = (conversation: Conversation): void => {
    setConversations((prevConversations) => prevConversations.filter((it) => it.sid !== conversation.sid));
  };

  const onConversationStateChanged = (state: ConnectionState): void => {
    if (['disconnecting', 'disconnected', 'denied'].includes(state)) {
      setConversations([]);
    }
  };

  useEffect(() => {
    if (!client) {
      return () => {};
    }
    client.on('conversationUpdated', onConversationUpdated);
    client.on('conversationJoined', onConversationJoined);
    client.on('conversationLeft', onConversationLeft);
    client.on('connectionStateChanged', onConversationStateChanged);
    return () => {
      client.off('conversationUpdated', onConversationUpdated);
      client.off('conversationJoined', onConversationJoined);
      client.off('conversationLeft', onConversationLeft);
      client.off('connectionStateChanged', onConversationStateChanged);
    };
  }, [jwtToken, client]);

  return {
    ccJoin,
  };
}
