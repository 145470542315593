/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import leftSide from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/left-side.svg';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setForm } from '@brands/store/slices/patientFormSlice';
import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { VscDeviceCamera } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';

import Container from '../../../../Components/Container/Container';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import Button from '../../../../Components/SkipButton/Button';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import AddDependentModal from '../../../MyProfile/Patient/Sections/AddDependentModal/AddDependentModal';
import Camera from './Components/Camera';
import styles from './styles.module.scss';

interface UploadedFile {
  name: string;
  dataURL: string;
}

const ImageUploadStepTwo = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { values } = useAppSelector(selectPatientForm);
  const screenSize = useWindowSize();
  const [openCamera, setOpenCamera] = useState(false);
  const [showAddDependentModal, setShowAddDependentModal] = useState<boolean>(false);
  const [images, setImages] = useState<UploadedFile | null>(
    (values.find((item) => item.form_question_id === 17)?.value.images as UploadedFile[])?.find(
      (leftSideImage) => leftSideImage.name === 'LeftSideImage.png',
    ) || null,
  );

  const onBack = (): void => {
    dispatch(setForm([...values!]));
    navigate(-1);
  };

  const onCloseCamera = (): void => {
    setOpenCamera(false);
  };

  const onPhotoTaken = (uri: string): void => {
    setImages({ dataURL: uri, name: 'LeftSideImage.png' });
  };

  const deleteMedia = (): void => {
    setImages(null);
  };

  useEffect(() => {
    const payload = [...values];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 17);

    if (images === null) {
      const existingImages =
        (values.find((item) => item.form_question_id === 17)?.value.images as UploadedFile[]) || [];

      const updatedImages = existingImages.filter((img) => img.name !== 'LeftSideImage.png');

      filteredPayload.push({
        form_question_id: 17,
        value: {
          images: updatedImages,
        },
      });

      dispatch(setForm(filteredPayload));
    } else {
      const existingImages =
        (values.find((item) => item.form_question_id === 17)?.value.images as UploadedFile[]) || [];

      const updatedImages = existingImages.filter((img) => img.name !== images.name);
      updatedImages.push(images);

      filteredPayload.push({
        form_question_id: 17,
        value: {
          images: updatedImages,
        },
      });

      dispatch(setForm(filteredPayload));
    }
  }, [images]);

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.uploadImageContainer}>
        <div className={styles.uploadImageContainerDiv}>
          <ProgressBar currentStep={3} totalSteps={4} onBack={onBack} hideStepNumbers />
          <div className={`${styles.formTitle} d-flex mt-3`}>Guided Image Capture</div>
          <div className={styles.uploadImageQRContainerText2} style={{ textAlign: 'center', margin: '15px 0' }}>
            Image 2 of 5
          </div>
          <form className={styles.formContainer}>
            <div className={styles.wrapper}>
              <div className={styles.formContent}>
                <div className={styles.uploadFlow}>
                  <span className={styles.formContentFlowImageSpan}>
                    {images !== null ? (
                      <div className={`${styles.formContentFlowImageSpan}`}>
                        <div className={styles.imageWrap} key={images.dataURL}>
                          <span className={styles.removeImage} onClick={() => deleteMedia()}>
                            <IoClose />
                          </span>
                          <img src={images.dataURL} alt={images.name} />
                        </div>
                      </div>
                    ) : (
                      <img src={leftSide} />
                    )}
                  </span>
                  <span className={styles.uploadImageQRContainerText1}>Left Side</span>
                  {images === null && (
                    <span className={styles.uploadImageQRContainerText3}>
                      Turn your head slightly to the right, and use your index and middle fingers of your left hand to
                      pull back your left cheek.
                    </span>
                  )}

                  <div className={`${styles.selectImages} ${styles.cameraBtn}`} onClick={() => setOpenCamera(true)}>
                    <VscDeviceCamera />
                    {images === null ? 'Take Photo' : 'Retake Photo'}
                  </div>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <Button
                  type="button"
                  onClick={() => {
                    navigate('/upload-image-flow-step-three');
                  }}
                  onTouchStart={() => {
                    navigate('/upload-image-flow-step-three');
                  }}
                  style={{ width: '245px', height: '46px', cursor: 'pointer', touchAction: 'manipulation' }}
                  className={`${images !== null ? styles.nextBtn : styles.skipBtn}`}
                >
                  {images !== null ? 'Next' : 'Skip'}
                </Button>
              </div>
            </div>
          </form>
          {openCamera && <Camera onClose={onCloseCamera} onPhotoTaken={onPhotoTaken} />}
        </div>
      </div>
      {showAddDependentModal && (
        <AddDependentModal isOpen={showAddDependentModal} setIsOpen={setShowAddDependentModal} isFromSelectNeeds />
      )}
    </Container>
  );
};
export default ImageUploadStepTwo;
