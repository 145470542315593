import { Dependent } from '@brands/services/identity/types/Dependent';
import { UserProfile } from '@brands/services/identity/types/UserProfile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SelectedPatient {
  selectedPatient: UserProfile | Dependent | null;
}

const initialState: SelectedPatient = {
  selectedPatient: null,
};

export const selectedPatientSlice = createSlice({
  name: 'selectedPatient',
  initialState,
  reducers: {
    setSelectedPatientByAdmin: (state, action: PayloadAction<{ selectedPatient: UserProfile | Dependent | null }>) => {
      return { selectedPatient: action.payload.selectedPatient };
    },
  },
});

export const { setSelectedPatientByAdmin } = selectedPatientSlice.actions;

export default selectedPatientSlice.reducer;
