/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import cn from 'classnames';
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';

import styles from './styles.module.scss';

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  wrapperClassName?: string;
  inputClass?: string;
  focusClassName?: string;
  register?: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  errors?: { [key: string]: any };
  isUnderlined?: boolean;
  wrapperStyle?: React.CSSProperties;
  type?: string;
  enableCursorPos?: boolean;
}

const TextField = React.forwardRef(
  (
    {
      wrapperClassName,
      inputClass,
      focusClassName,
      wrapperStyle,
      name,
      register,
      registerOptions,
      errors,
      type,
      enableCursorPos,
      isUnderlined,
      onChange,
      onBlur,
      ...props
    }: InputProps,
    ref,
  ): ReactElement => {
    const inputRef = useRef<HTMLInputElement>(null);
    const cursorPositionRef = useRef<number | null>(null);
    useMemo(() => {
      if (cursorPositionRef.current !== null && inputRef.current) {
        inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
        cursorPositionRef.current = null;
      }
    }, [cursorPositionRef.current]);

    const removeExtraSpace = (s: string): string => s.trim().split(/ +/).join(' ');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (enableCursorPos) {
        const cursorPosition = e.target.selectionStart;
        if (onChange) onChange(e);
        setTimeout(() => {
          e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 10);
      } else {
        if (onChange) {
          onChange(e);
        }
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
      if (onBlur) onBlur(e);
      if (onChange) {
        const trimmedValue = removeExtraSpace(e.target.value);
        e.target.value = trimmedValue;
        onChange(e as React.ChangeEvent<HTMLInputElement>); // Manually trigger the onChange event
      }
    };

    const [errorMessage, setErrorMessage] = React.useState('');
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0 && name) {
        const nameArray = name.split('.');
        if (nameArray.length > 1) {
          setErrorMessage(errors[nameArray[0]]?.[nameArray[1]]?.message);
        } else {
          setErrorMessage(errors[name]?.message);
        }
      } else {
        setErrorMessage('');
      }
    }, [errors, name, errors && Object.keys(errors)]);

    return (
      <div className={cn(styles.inputWrapper, wrapperClassName)} style={wrapperStyle} ref={ref as any}>
        <input
          className={cn(styles.input, inputClass, { 'is-invalid': errorMessage })}
          {...(register ? register(name!, registerOptions) : {})}
          type={type}
          {...props}
          disabled={props.disabled || false}
          maxLength={props.maxLength || 255}
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
        {isUnderlined && <span className={`${styles.inputFocus} ${focusClassName}`} />}
        {errorMessage && <div className={`${styles.invalidFeedback} invalid-feedback`}>{errorMessage}</div>}
      </div>
    );
  },
);

TextField.defaultProps = {
  wrapperClassName: '',
  type: 'text',
  inputClass: '',
  focusClassName: '',
  register: undefined,
  registerOptions: undefined,
  errors: undefined,
  isUnderlined: true,
  wrapperStyle: {},
  enableCursorPos: true,
};

export default TextField;
