import { SmileScanReport } from '@brands/services/cases/types/SmileScanReport';

export type DiseaseAssessment = {
  id: string;
  title: string;
  value: number | boolean;
};

export interface SmileScanReportWithDiseaseAssessment extends SmileScanReport {
  diseaseAssessment: DiseaseAssessment[];
}

export const smileScanMapper = (smileScanReport: SmileScanReport): SmileScanReportWithDiseaseAssessment => {
  return {
    ...smileScanReport,
    overall_score: smileScanReport?.overall_score || 0,
    diseaseAssessment: [
      {
        id: 'cavity_score',
        title: 'Area of concern on tooth',
        value: smileScanReport.cavity_score || 0,
      },
      {
        id: 'plaque_buildup_score',
        title: 'Tartar buildup',
        value: smileScanReport.plaque_buildup_score || 0,
      },
      {
        id: 'swollen_gums_score',
        title: 'Inflammation of gums',
        value: smileScanReport.swollen_gums_score || 0,
      },
      {
        id: 'root_recession_score',
        title: 'Gum recession',
        value: smileScanReport.root_recession_score || 0,
      },
      {
        id: 'gaps_detected',
        title: 'Gaps or crooked teeth',
        value: smileScanReport.gaps_detected || false,
      },
    ],
  };
};
