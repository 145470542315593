export const mapPainLevelLabel = (painLevel: string): string => {
  switch (painLevel) {
    case 'noPain':
      return 'No Pain';
    case 'mildPain':
      return 'Mild Pain';
    case 'moderatePain':
      return 'Moderate Pain';
    case 'severePain':
      return 'Severe Pain';
    case 'verySeverePain':
      return 'Very Severe Pain';
    case 'worstPainPossible':
      return 'Worst Pain Possible';
    default:
      return '-';
  }
};
