import { useEffect, useState } from 'react';
import {
  LocalAudioTrack,
  LocalDataTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteDataTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
} from 'twilio-video';

type Track =
  | LocalAudioTrack
  | LocalVideoTrack
  | LocalDataTrack
  | RemoteAudioTrack
  | RemoteVideoTrack
  | RemoteDataTrack
  | null
  | undefined;

const useTrack = (publication: LocalTrackPublication | RemoteTrackPublication | undefined): Track => {
  const [track, setTrack] = useState(publication && publication.track);

  useEffect(() => {
    setTrack(publication && publication.track);

    if (publication) {
      const removeTrack = (): void => {
        setTrack(null);
      };

      publication.on('subscribed', setTrack);
      publication.on('unsubscribed', removeTrack);
      return () => {
        publication.off('subscribed', setTrack);
        publication.off('unsubscribed', removeTrack);
      };
    }

    return undefined;
  }, [publication]);

  return track;
};

export default useTrack;
