import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { setPatientState } from '@brands/store/slices/currentPatientState';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';

import Button from '../../../../Components/Button/Button';
import SelectInput from '../../../../Components/Inputs/SelectInput/SelectInput';
import { useWindowSize } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import { selectCommonCustomStyles } from '../../../../Utils/customStyles';
import { DEFAULT_PATIENT_LOCATION_STATE, setCurrentPatientState } from '../../../../Utils/localStorage';
import { stateOptions } from '../../../../Utils/selectOptions';
import styles from './styles.module.scss';

interface StateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  content: {
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '410px',
    maxHeight: '300px',
    overflow: 'hidden',
    background: '#F0F0F0',
    borderRadius: '11px',
  },
};

export const customStylesMobile = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  content: {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: '#F0F0F0',
    borderRadius: '0',
  },
};

const StateModal: FC<StateModalProps> = ({ isOpen, onClose }) => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const isMobile = useMemo(() => screenSize.width < 768, [screenSize]);
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const dispatch = useAppDispatch();

  const [state, setState] = useState<string>(DEFAULT_PATIENT_LOCATION_STATE);

  const onConfirm = (): void => {
    dispatch(setPatientState({ userId: userInfo.id, state }));
    setCurrentPatientState(userInfo.id, state);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setState(currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE);
    }
  }, [isOpen, userInfo]);

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClose}
      style={isMobile ? customStylesMobile : customStyles}
    >
      <div className={styles.content}>
        <p className={styles.title}>Where is the patient located?</p>
        <p className={styles.label}>Choose current location for patient</p>
        <SelectInput
          name="state"
          options={stateOptions}
          selectorClass={styles.selector}
          style={selectCommonCustomStyles}
          containerClass={styles.selectorContainer}
          selectedValue={state}
          onChange={(value) => {
            setState(value?.value || DEFAULT_PATIENT_LOCATION_STATE);
          }}
        />
        <div className={styles.actionBtnContainer}>
          <Button type="button" className={styles.cancel} onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" className={styles.submit} onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default StateModal;
