import { FormQuestion } from '@brands/services/forms/types/FormQuestion';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Form = {
  form_id: number;
  step_id: number;
  questions: FormQuestion[];
};

export type FormQuestionsSliceState = {
  forms: Form[];
};

const initialState: FormQuestionsSliceState = {
  forms: [],
};

export const formQuestionsSlice = createSlice({
  name: 'formQuestions',
  initialState,
  reducers: {
    addFormItem(state, action: PayloadAction<Form>) {
      const index = state.forms.findIndex(
        (item) => item.form_id === action.payload.form_id && item.step_id === action.payload.step_id,
      );
      if (index !== -1) {
        Object.assign(state.forms[index], action.payload);
      } else {
        state.forms.push(action.payload);
      }
    },
  },
});

export const { addFormItem } = formQuestionsSlice.actions;

export default formQuestionsSlice.reducer;
