/* eslint-disable @typescript-eslint/no-empty-interface */
import { client } from '../client';

interface UserWebState {}

export const setUserWebState = async (state: UserWebState): Promise<any> => {
  const { data } = await client.post<any>('/users/state', {
    state,
  });

  return data;
};
