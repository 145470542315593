/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import { IMedia } from '@brands/services/oneReport/types/IMedia';
import React, { useEffect, useState } from 'react';
import { FaSearchPlus } from 'react-icons/fa';

import styles from './styles.module.scss';

interface SlideshowProps {
  images: IMedia[];
  interval?: number;
}

const Slideshow: React.FC<SlideshowProps> = ({ images, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!interval || images.length <= 1) return;
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);
    return () => clearInterval(timer);
  }, [images.length, interval]);

  const goToPrevious = (): void => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = (): void => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.slideshow}>
      <div className={styles.imageContainer}>
        {images.map((image, index) => (
          <>
            <img
              key={index}
              src={image.url}
              alt="Slideshow"
              className={`${styles.image} ` + `smile-scan-images`}
              style={{ display: index === currentIndex ? 'block' : 'none' }}
              onError={(event: any) => {
                const img = event.target;
                img.src = image.url;
                img.onError = null;
              }}
            />
            <FaSearchPlus className={styles.plusIcon} onClick={openModal} />
          </>
        ))}
      </div>
      <div className={styles.controls}>
        <button onClick={goToPrevious} className={styles.navButton} type="button">
          &lt;
        </button>
        <div className={styles.dots}>
          {images.map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${currentIndex === index ? styles.active : ''}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
        <button onClick={goToNext} className={styles.navButton} type="button">
          &gt;
        </button>
      </div>
      {isModalOpen && (
        <div className={styles.modal} onClick={closeModal}>
          <img src={images[currentIndex].url} alt="Full Size" className={styles.modalImage} />
        </div>
      )}
    </div>
  );
};

Slideshow.defaultProps = {
  interval: 5000,
};

export default Slideshow;
