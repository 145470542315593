import { client } from '../client';

export interface PricingsPayload {
  include_inactive?: boolean;
  organization_id?: string;
  division_id?: string;
  practice_id?: string;
}

export interface Pricings {
  code: string;
  label: string;
  amount: number;
  currency: string;
  case_types: string[];
  is_active: boolean;
  created_at: string;
}

export const getPricings = async (): Promise<Pricings[]> => {
  const { data } = await client.get(`/pricings`);

  return data;
};
