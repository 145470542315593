import { selectAuth } from '@brands/store/selectors/auth';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import React, { FC, memo, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';

import ProfileImage from '../../Components/ProfileImage/ProfileImage';
import CareAdvisorProfileCardModal from '../../Dashboard/ProfileCards/CareAdvisor/ProfileCards';
import ProviderProfileCardModal from '../../Dashboard/ProfileCards/Dentist/ProfileCards';
import PatientProfileCardModal from '../../Dashboard/ProfileCards/Patient/ProfileCards';
import { useSpeakerViewParticipants, useVideoContext, useWindowSize } from '../../hooks';
import { useAppSelector } from '../../hooks/useReduxHook';
import { getOneCase } from '../../services/cases/getCaseById';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import { displayErrorDetails } from '../../Utils/displayError';
import styles from './styles.module.scss';

interface VideoRoomSidenavProps {
  participantsOnScreen: boolean;
  setParticipantsOnScreen: (value: boolean) => void;
}

const VideoRoomSidenav: FC<VideoRoomSidenavProps> = ({ participantsOnScreen, setParticipantsOnScreen }) => {
  const { userInfo } = useAppSelector(selectAuth);
  const { vc_case_id: vcCaseID } = useAppSelector(selectVideoCallCredentials);
  const p = useParams();
  const caseId = useParams().caseId as string;
  const [profileCardModal, setProfileCardModal] = useState(false);
  const [caProfileCardModal, setCAProfileCardModal] = useState(false);
  const [providerProfileCardModal, setProviderProfileCardModal] = useState(false);
  const [profileCardData, setProfileCardData] = useState<any>([]);
  const { participantsDetails, room } = useVideoContext();
  const { width } = useWindowSize();
  const localParticipant = room?.localParticipant.identity;
  const speakerViewParticipants = useSpeakerViewParticipants().map((participant) => participant?.identity);
  const activeParticipantsSids = [localParticipant, ...speakerViewParticipants];
  const activeParticipantsDetails = participantsDetails.filter((participant) => {
    return activeParticipantsSids.includes(participant.user.id.toString());
  });

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={styles.closeButtonContainer} onClick={() => setParticipantsOnScreen(false)}>
        <span>
          <AiFillCloseCircle size="24px" /> {width > 768 ? 'Close' : 'Back'}
        </span>
      </div>
      <div className={`${styles.vSidenav} ${!participantsOnScreen && 'd-none'}`}>
        <div className={`${styles.participantsDiv} h-100`}>
          <div className={styles.vParticipantsInfo}>
            {activeParticipantsDetails.length > 0 &&
              activeParticipantsDetails.map((participant) => (
                <div key={participant.user.id}>
                  <div
                    className={styles.participantDiv}
                    onClick={async () => {
                      try {
                        if (participant.user.role.name === UserRoleName.Patient) {
                          await getOneCase(
                            Number(userInfo?.role?.name === UserRoleName.Patient ? p.caseId : vcCaseID),
                          ).then((res) => {
                            setProfileCardData(res.patient);
                          });
                          setProfileCardModal(true);
                        } else if (participant.user.role.name === UserRoleName.CareAdvisor) {
                          await getOneCase(
                            Number(userInfo?.role?.name === UserRoleName.Patient ? p.caseId : vcCaseID),
                          ).then((res) => {
                            setProfileCardData(res.care_advisor);
                          });
                          setCAProfileCardModal(true);
                        } else if (participant.user.role.name === UserRoleName.Provider) {
                          await getOneCase(
                            Number(userInfo?.role?.name === UserRoleName.Patient ? p.caseId : vcCaseID),
                          ).then((res) => {
                            setProfileCardData(res.provider);
                          });
                          setProviderProfileCardModal(true);
                        }
                      } catch (error: unknown) {
                        displayErrorDetails(error);
                      }
                    }}
                  >
                    <span className={styles.imageContainer}>
                      <ProfileImage id={participant.user.id.toString()} size="50px" isUpload={false} />
                    </span>
                    <div className={styles.titleContainer}>
                      <span className={styles.participantTitle}>{participant.user.role.name}</span>
                      <span className={styles.participantName}>{participant.user.full_name}</span>
                    </div>
                  </div>
                  {profileCardModal && (
                    <PatientProfileCardModal
                      isOpen={profileCardModal}
                      toggleModal={setProfileCardModal}
                      patientIdentity={profileCardData}
                    />
                  )}
                  {caProfileCardModal && (
                    <CareAdvisorProfileCardModal
                      isOpen={caProfileCardModal}
                      toggleModal={setCAProfileCardModal}
                      caIdentity={profileCardData}
                    />
                  )}
                  {providerProfileCardModal && (
                    <ProviderProfileCardModal
                      isOpen={providerProfileCardModal}
                      toggleModal={setProviderProfileCardModal}
                      providerIdentity={profileCardData}
                      caseId={Number(caseId)}
                      patientCurrentStateValue={undefined}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(VideoRoomSidenav);
