/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Container from '../../../Components/Container/Container';
import DashboardHeader from '../Components/DashboardHeader/DashboardHeader';
import Appointments from './Sections/Appointments/Appointments';
import History from './Sections/History/History';
import Schedule from './Sections/Schedule/Schedule';
import styles from './styles.module.scss';

const titles = ['Appointments', 'History', 'Schedule / Availability'];

const ProviderDashboard = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const [activeTitle, setActiveTitle] = useState<number>(() => {
    if (searchParams.get('tab') === 'schedule') {
      return 2;
    }
    const hash = window.location.hash.replace('#', '');
    switch (hash) {
      case 'availability':
        return 2;
      case 'history':
        return 1;
      default:
        return 0;
    }
  });

  // Update URL hash when the active tab is changed
  const setTab = (tabIndex: number): void => {
    setActiveTitle(tabIndex);
    const tabHash = tabIndex === 2 ? 'availability' : tabIndex === 1 ? 'history' : 'appointments';
    window.location.hash = tabHash; // Update the URL hash
  };

  // Handle URL hash change directly (if the user navigates back/forward)
  useEffect(() => {
    const handleHashChange = (): void => {
      const hash = window.location.hash.replace('#', '');
      switch (hash) {
        case 'availability':
          setActiveTitle(2);
          break;
        case 'history':
          setActiveTitle(1);
          break;
        default:
          setActiveTitle(0);
          break;
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  // Render the active title based on the selected tab
  const renderActiveTitle = (): JSX.Element => {
    switch (activeTitle) {
      case 0:
        return <Appointments />;
      case 1:
        return <History />;
      case 2:
        return <Schedule />;
      default:
        return <Appointments />;
    }
  };

  return (
    <Container
      isBGEclipse={false}
      containerStyle={{
        justifyContent: 'start',
      }}
      childClassName={styles.providerDashboard}
      childrenStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        paddingTop: '2rem',
        height: 'calc(100vh - 74px)',
      }}
      isProvider
    >
      <div
        className={cn(styles.containerPf, styles.providerDashboardBody)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <DashboardHeader titles={titles} activeTitle={activeTitle} setTab={setTab} />
        {renderActiveTitle()}
      </div>
    </Container>
  );
};

export default ProviderDashboard;
