/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';

import styles from './styles.module.scss';

const NotFoundPage: React.FC = () => {
  useEffect(() => {
    document.title = '404 Not Found';
    document.body.classList.add(styles.notFoundPage);
    return () => {
      document.body.classList.remove(styles.notFoundPage);
    };
  }, []);

  return (
    <div className={styles.c}>
      <div className={`${styles._404}`}>404</div>
      <hr />
      <div className={`${styles._1}`}>THE PAGE</div>
      <div className={`${styles._2}`}>WAS NOT FOUND</div>
    </div>
  );
};

export default NotFoundPage;
