/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react';
import { Room } from 'twilio-video';

import { Callback } from '../../services/communication/types/VideoRoom';

export default function useHandleTrackPublicationFailed(room: Room | null, onError: Callback) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', onError);
      return () => {
        room.localParticipant.off('trackPublicationFailed', onError);
      };
    }
  }, [room, onError]);
}
