import React from 'react';

import styles from './styles.module.scss';

interface Props {
  capsLetterFlag: string;
  numberFlag: string;
  pwdLengthFlag: string;
  specialFlag: string;
}

const PWDRequisite: React.FC<Props> = ({ capsLetterFlag, numberFlag, pwdLengthFlag, specialFlag }): JSX.Element => (
  <div style={{ display: 'inline-flex' }}>
    <p className={styles[pwdLengthFlag]}>8 characters &nbsp;</p>
    <p className={styles[capsLetterFlag]}>1 uppercase &nbsp;</p>
    <p className={styles[numberFlag]}>1 number &nbsp;</p>
    <p className={styles[specialFlag]}>1 special character &nbsp;</p>
  </div>
);

export default PWDRequisite;
