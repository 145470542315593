import React, { FC, useCallback, useRef } from 'react';
import { TbVideo, TbVideoOff } from 'react-icons/tb';

import { useLocalVideoToggle } from '../../../hooks';
import styles from './styles.module.scss';

const ToggleVideoButton: FC = () => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <button type="button" onClick={() => toggleVideo()}>
      <span className={styles.vcVideoIcon}>{isVideoEnabled ? <TbVideo size={20} /> : <TbVideoOff size={20} />}</span>
    </button>
  );
};
export default ToggleVideoButton;
