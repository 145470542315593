/* eslint-disable jsx-a11y/media-has-caption */
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import React, { useCallback, useEffect, useRef } from 'react';
import { LocalVideoTrack, RemoteVideoTrack } from 'twilio-video';

import { useVideoContext, useVideoTrackDimensions } from '../../hooks';
import { isMobile } from '../../Utils';
import styles from './styles.module.scss';

interface VideoTrackProps {
  track: LocalVideoTrack | RemoteVideoTrack;
  isWaitingRoom?: boolean;
}
const VideoTrack: React.FC<VideoTrackProps> = ({ track, isWaitingRoom }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { userInfo } = useAppSelector(selectAuth);
  const { is_visible: isVisible } = useAppSelector(selectVideoCallCredentials);
  const { room, isSideBySide } = useVideoContext();
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);
  const remoteParticipantsSize = room?.participants.size || 0;

  const isSafari = useCallback(() => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  }, []);

  useEffect(() => {
    if (videoRef.current && track) {
      track.detach();

      track.attach(videoRef.current);

      if (track.kind === 'video' && track instanceof LocalVideoTrack) {
        if (!track.isEnabled) {
          track.enable();
        }
        videoRef.current.muted = false;
      }
    }
    return () => {
      if (videoRef.current && track) {
        track?.detach(videoRef.current);

        videoRef.current.srcObject = null;
      }
    };
  }, [videoRef, track]);

  useEffect(() => {
    if (videoRef.current && track) {
      track.detach();

      track.attach(videoRef.current);

      if (track.kind === 'video' && track instanceof LocalVideoTrack && track.isMuted && isVisible) {
        room?.refreshInactiveMedia();
      }
    }
  }, [videoRef, track]);

  useEffect(() => {
    if (track.kind === 'video' && track instanceof LocalVideoTrack && track.isMuted && isVisible) {
      room?.refreshInactiveMedia();
    }
  }, [track]);

  const isRemoteVideoTrack = (track as RemoteVideoTrack).sid !== undefined;

  const style = {
    objectFit: 'contain',
    width:
      userInfo.role.name === UserRoleName.Patient
        ? (isMobile && !isRemoteVideoTrack && !isWaitingRoom && remoteParticipantsSize > 0) ||
          (isSafari() && !isWaitingRoom) ||
          isPortrait
          ? ''
          : '100%'
        : '100%',
    height: '100%',
  };

  if (!track) {
    return null;
  }
  return (
    <video
      ref={videoRef}
      playsInline
      autoPlay
      onClick={() => {
        if (videoRef.current && videoRef.current.paused) {
          videoRef.current.play();
        }
      }}
      style={style}
      className={isSideBySide ? styles.video : ''}
      id={isWaitingRoom ? 'preview' : track.name}
      controls={false}
    />
  );
};

VideoTrack.defaultProps = {
  isWaitingRoom: false,
};

export default VideoTrack;
