import { client } from '../client';
import { IOneReport } from './types/IOneReport';

interface IUpdateOneReportMedia {
  assessments?: string;
  internal_notes?: string;
  chief_complaint_problem?: string;
  pain_scale?: number;
  medical_history?: string;
  is_media_utilized?: boolean;
  is_intra_oral_utilized?: boolean;
  is_extra_oral_utilized?: boolean;
  disposition?: string;
  prescription_in_icore?: string;
  pharmacy?: string;
  need_for_oral_health_coaching?: string;
  cdts?: string[];
  is_terms_accepted?: boolean;
}

export const updateOneReport = async (one_report_id: string, payload: IUpdateOneReportMedia): Promise<IOneReport> => {
  const { data } = await client.patch<IOneReport>(`/one_reports/${one_report_id}`, payload);

  return data;
};
