import './index.css';

import { checkUserAvailability } from '@brands/services/availability/checkUserAvailability';
import { ICase } from '@brands/services/cases/types/ICase';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { DayCellContentArg, EventClickArg, EventContentArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';

import { Loading } from '../../../../../../../Components/LoadingSpinner/Loading';
import { useAppSelector } from '../../../../../../../hooks/useReduxHook';
import { deleteUserAvailability } from '../../../../../../../services/availability/deleteUserAvailability';
import { ProviderAvailability } from '../../../../../../../services/availability/types/ProviderAvailability';
import { capitalizeFirstLetterOfEachWord } from '../../../../../../../Utils/capitalizeFirstLetterOfEachWord';
import { getPrimaryColor } from '../../../../../../../Utils/getPrimaryColor';
import calendar from './assets/calendar.svg';
import repeating from './assets/repeating.svg';
import reset from './assets/reset.svg';

const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const AVAILABILITY_DISPLAY_TIME_PERIOD_IN_WEEKS = 52;

const headerToolbar = {
  left: '',
  center: 'prev title next',
  right: '',
};

type DashboardCalendarProps = {
  weeklyHours: ProviderAvailability[];
  dateOverrides: ProviderAvailability[];
  setDateOverrides: React.Dispatch<React.SetStateAction<ProviderAvailability[]>>;
  setView: React.Dispatch<React.SetStateAction<string>>;
  setAddDateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[] | null>>;
  setAlertModal: React.Dispatch<
    React.SetStateAction<{
      cases: ICase[];
      kind: 'override' | 'weekly';
      type: 'delete' | 'update' | 'add';
      selectedDates?: Date[];
      isUnavailable?: boolean;
      slots: ProviderAvailability[];
    } | null>
  >;
};

const DashboardCalendar = ({
  weeklyHours,
  dateOverrides,
  setDateOverrides,
  setView,
  setAddDateModal,
  setSelectedDates,
  setAlertModal,
}: DashboardCalendarProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const [currentEvents, setCurrentEvents] = React.useState<EventInput[] | null>(null);
  const [displayedPopup, setDisplayedPopup] = React.useState<HTMLElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [key, setKey] = React.useState<number>(0);
  const [displayedMonth, setDisplayedMonth] = React.useState<string>('');

  const disablePreviousSelection = (startStr: string): boolean => {
    const now = moment().subtract(1, 'days');
    const selected = moment(startStr);
    return now.isBefore(selected);
  };

  const removePopup = (): void => {
    if (displayedPopup) {
      displayedPopup.remove();
      setDisplayedPopup(null);
    }
  };

  const deleteOverridesAtSelectedDate = async (date: string): Promise<void> => {
    const deletedOverrides = dateOverrides.filter((override) => override.date === date);
    await Promise.all(
      deletedOverrides.map(async (override) => {
        if (override.id) {
          await deleteUserAvailability(userInfo.id, override.id.toString());
        }
      }),
    );
  };

  const addWeeklyHoursAndRemoveDateOverridesForThisDate = async (date: string): Promise<void> => {
    try {
      setLoading(true);
      let cases: ICase[] = [];
      const selectedDateOverrides = dateOverrides.filter((override) => override.date === date);

      if (UserRoleName.Provider === userInfo.role.name) {
        await Promise.all(
          selectedDateOverrides.map(async (override) => {
            await checkUserAvailability(userInfo.id, {
              availability_id: override?.id || 0,
            }).then((response) => {
              if (response.length > 0) {
                cases = [...cases, ...response];
              }
            });
          }),
        );
      }
      if (cases.length > 0) {
        setAlertModal({
          cases,
          kind: 'override',
          type: 'delete',
          slots: selectedDateOverrides,
        });
      } else {
        const newDateOverrides = dateOverrides.filter((override) => override.date !== date);

        await deleteOverridesAtSelectedDate(date);

        setDateOverrides(newDateOverrides);
      }
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    const editDateBtn = document.getElementById('edit_date');
    editDateBtn?.addEventListener('click', (e) => {
      e.stopPropagation();
      setSelectedDates([moment(displayedPopup?.parentElement?.getAttribute('data-date')).toDate()]);
      setAddDateModal(true);
    });

    const editAllFridaysBtn = document.getElementById('edit_all_fridays');

    editAllFridaysBtn?.addEventListener('click', (e) => {
      e.stopPropagation();
      setView('list');
    });

    const resetToWeeklyHoursBtn = document.getElementById('reset-to-weekly-hours');

    resetToWeeklyHoursBtn?.addEventListener('click', (e) => {
      e.stopPropagation();
      addWeeklyHoursAndRemoveDateOverridesForThisDate(displayedPopup?.parentElement?.getAttribute('data-date') || '');
    });

    document.addEventListener('click', (e) => {
      if (
        displayedPopup &&
        !displayedPopup.contains(e.target as Node) &&
        !(e.target as HTMLElement)?.classList.contains('fc-event') &&
        !(e.target as HTMLElement)?.classList.contains('fc-daygrid-day-frame') &&
        (e.target as HTMLElement)?.tagName !== 'B' &&
        (e.target as HTMLElement)?.tagName !== 'I'
      ) {
        removePopup();
      }
    });

    return () => {
      document.removeEventListener('click', () => {
        removePopup();
      });
    };
  }, [displayedPopup]);

  const getDateOfAllWeekdaysIncludingCurrentDay = (weekday: number): string[] => {
    const today = new Date();
    const nextWeekday = new Date(today.setDate(today.getDate() + ((weekday - today.getDay() + 7) % 7)));
    const allWeekdays = [nextWeekday];
    const allWeekdaysList: string[] = [];
    for (let i = 0; i < AVAILABILITY_DISPLAY_TIME_PERIOD_IN_WEEKS; i += 1) {
      const nextWeekdayDate =
        i === 0
          ? new Date(allWeekdays[i].setDate(allWeekdays[i].getDate()))
          : new Date(allWeekdays[i].setDate(allWeekdays[i].getDate() + 7));
      allWeekdays.push(nextWeekdayDate);
      allWeekdaysList.push(nextWeekdayDate.toISOString().replace(/T.*$/, ''));
    }
    const previousWeekday = new Date(today.setDate(today.getDate() - ((today.getDay() - weekday + 7) % 7)));
    for (let i = 1; i < 12; i += 1) {
      const previousWeekdayDate = new Date(previousWeekday.setDate(previousWeekday.getDate() - 7));
      allWeekdaysList.push(previousWeekdayDate.toISOString().replace(/T.*$/, ''));
    }
    return allWeekdaysList;
  };

  const alignWeekdays = (weekday: number): number => {
    if (weekday === 6) {
      return 0;
    }
    return weekday + 1;
  };

  const getWeeklyEvents = useMemo(async () => {
    const weeklyEvents: EventInput[] = [];
    if (weeklyHours.length > 0) {
      await weekdays?.forEach((_, index) => {
        weeklyHours.forEach((weeklyHour) => {
          if (alignWeekdays(weeklyHour.weekday) === index) {
            const allWeekdays = getDateOfAllWeekdaysIncludingCurrentDay(index);
            allWeekdays.forEach((date) => {
              const event = {
                id: weeklyHour?.id?.toString(),
                title: `${weeklyHour.synchronous ? 'Synchronous' : ''}${
                  weeklyHour.synchronous && weeklyHour.asynchronous ? ', ' : ''
                }${weeklyHour.asynchronous ? 'Asynchronous' : ''}`,
                start: `${date}T${moment(weeklyHour.start_time, 'h:mmA').format('HH:mm:ss')}`,
                end: `${date}T${moment(weeklyHour.end_time, 'h:mmA').format('HH:mm:ss')}`,
                kind: 'weekly',
                date,
              };
              weeklyEvents.push(event);
            });
          }
        });
      });
    }
    return weeklyEvents;
  }, [weeklyHours]);

  const getOverrideEvents = useMemo(() => {
    const overrideEvents: EventInput[] = [];
    if (dateOverrides.length > 0) {
      dateOverrides?.forEach((dateOverride) => {
        let event;
        if (dateOverride.start_time === '12:00am' && dateOverride.end_time === '12:00am') {
          event = {
            id: dateOverride?.id?.toString(),
            title: 'Unavailable',
            allDay: true,
            kind: 'dateOverride',
            date: dateOverride.date,
          };
        } else {
          event = {
            id: dateOverride?.id?.toString(),
            title: `${dateOverride.synchronous ? 'Synchronous' : ''}${
              dateOverride.synchronous && dateOverride.asynchronous ? ', ' : ''
            }${dateOverride.asynchronous ? 'Asynchronous' : ''}`,
            start: `${dateOverride.date}T${moment(dateOverride.start_time, 'h:mmA').format('HH:mm:ss')}`,
            end: `${dateOverride.date}T${moment(dateOverride.end_time, 'h:mmA').format('HH:mm:ss')}`,
            kind: 'dateOverride',
            date: dateOverride.date,
          };
        }
        overrideEvents.push(event);
      });
    }
    return overrideEvents;
  }, [dateOverrides]);

  useEffect(() => {
    (async () => {
      if (weeklyHours.length > 0 || dateOverrides.length > 0) {
        setLoading(true);
        let weeklyEvents: EventInput[] = [];
        let overrideEvents: EventInput[] = [];
        weeklyEvents = await getWeeklyEvents;
        overrideEvents = await getOverrideEvents;

        if (weeklyHours.length > 0 && dateOverrides.length > 0) {
          const overrideEventDates = await overrideEvents.map((event) => event.date);
          const filteredWeeklyEvents = await weeklyEvents.filter((event) => !overrideEventDates.includes(event.date));
          setCurrentEvents([...filteredWeeklyEvents, ...overrideEvents]);
        } else {
          setCurrentEvents([...weeklyEvents, ...overrideEvents]);
        }

        setKey((prevKey) => prevKey + 1);
        setLoading(false);
      }
    })();
  }, [getWeeklyEvents, getOverrideEvents]);

  const getPopupContent = (date: Date, popup: HTMLDivElement): HTMLDivElement => {
    let kind = '';

    currentEvents?.forEach((event) => {
      if (event.date === moment(date).format('YYYY-MM-DD')) {
        if (event.kind === 'weekly') {
          kind = 'weekly';
        } else if (event.kind === 'dateOverride') {
          kind = 'override';
        }
      }
    });

    const commonButtons = `
      <div class="popup__buttons">
        <div class="popup__button" id="edit_date">
          <span>Edit Date</span><img src="${calendar}" alt="calendar" />
        </div>
        <div class="separator"></div>
      </div>
    `;

    const weeklyButtons = `
      ${commonButtons}
      <div class="popup__button" id="edit_all_fridays">
        <span>Edit all ${moment(date).format('dddd')}</span>
        <img src="${repeating}" alt="reset" class="blue__icon" />
      </div>
    `;

    const overrideButtons = `
      ${commonButtons}
      <div class="popup__button" id="reset-to-weekly-hours">
        <span>Reset to Weekly Hours</span>
        <img src="${reset}" alt="reset" />
      </div>
    `;

    const popupContent = `
      <div class="popup-wrapper">
        ${kind === 'override' ? overrideButtons : weeklyButtons}
      </div>
    `;

    const popupElement = popup;
    popupElement.innerHTML = popupContent;
    return popupElement;
  };

  const insertPopupIntoDayCell = (dayInfo: DateClickArg): void => {
    const popup: HTMLDivElement = document.createElement('div');
    popup.className = 'popup arrow-top';
    popup.setAttribute('data-date', moment(dayInfo.dateStr).format('YYYY-MM-DD'));
    popup.style.top = `${dayInfo.dayEl.offsetTop + dayInfo.dayEl.offsetHeight / 1.5}px`;
    popup.style.left = '-50px';
    const popupWithContent = getPopupContent(dayInfo.date, popup);
    setDisplayedPopup(popupWithContent);
    dayInfo.dayEl.appendChild(popupWithContent);
  };

  const insertPopupIntoDayCellForEventClick = (eventInfo: EventClickArg): void => {
    const popup: HTMLDivElement = document.createElement('div');
    popup.className = 'popup arrow-top';
    popup.setAttribute('data-date', moment(eventInfo.event.startStr).format('YYYY-MM-DD'));
    const parentElement = eventInfo.el.parentElement?.parentElement?.parentElement?.parentElement as HTMLElement;
    popup.style.top = `${parentElement?.offsetTop ?? 0 + parentElement.offsetHeight / 1.5}px`;
    popup.style.left = '-50px';
    const popupWithContent = getPopupContent(new Date(eventInfo.event.startStr), popup);
    setDisplayedPopup(popupWithContent);
    parentElement?.appendChild(popupWithContent);
  };

  const renderEventContent = (eventContent: EventContentArg): JSX.Element => {
    return (
      <>
        <b>{eventContent.timeText.toLowerCase().replaceAll(' ', '').replace('-', ' - ')}</b>
        <i>{capitalizeFirstLetterOfEachWord(eventContent.event.title)}</i>
      </>
    );
  };

  const findBGColor = useCallback(
    (info: DayCellContentArg) => {
      setLoading(true);
      let bg = '';
      currentEvents?.forEach((event) => {
        if (event.date === moment(info.date).format('YYYY-MM-DD')) {
          if (event.kind === 'weekly') {
            bg = 'weekly';
          } else if (event.kind === 'dateOverride') {
            bg = 'override';
          }
        }
      });
      setLoading(false);
      return bg;
    },
    [currentEvents],
  );

  const handleEventClick = (info: EventClickArg): void => {
    if (!disablePreviousSelection(info.event.startStr)) {
      return;
    }

    const dateAttribute = displayedPopup?.getAttribute('data-date');
    const eventDate = moment(info.event.startStr).format('YYYY-MM-DD');

    if (dateAttribute && dateAttribute !== eventDate) {
      removePopup();
      insertPopupIntoDayCellForEventClick(info);
      return;
    }

    displayedPopup ? removePopup() : insertPopupIntoDayCellForEventClick(info);
  };

  const handleDateClick = (info: DateClickArg): void => {
    if (!disablePreviousSelection(info.dateStr)) {
      return;
    }

    const dateAttribute = displayedPopup?.getAttribute('data-date');
    const clickedDate = moment(info.dateStr).format('YYYY-MM-DD');

    if (dateAttribute && dateAttribute !== clickedDate) {
      removePopup();
      insertPopupIntoDayCell(info);
      return;
    }

    displayedPopup ? removePopup() : insertPopupIntoDayCell(info);
  };

  const renderDayCellContent = (info: DayCellContentArg): JSX.Element => {
    const bg = findBGColor(info);
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: bg === '' ? 'flex-end' : 'space-between' }}>
        {bg === 'weekly' && (
          <span>
            <img src={repeating} alt="reset" />
          </span>
        )}
        {bg === 'override' && (
          <span>
            <AiOutlineCalendar size={20} color={getPrimaryColor()} />
          </span>
        )}
        <span>{info.dayNumberText}</span>
      </div>
    );
  };

  const getDayCellClassNames = (info: DayCellContentArg): string => {
    const bg = findBGColor(info);
    return bg === 'override' ? 'override' : '';
  };

  const handleDatesSet = (i: { view: { currentStart: Date } }): void => {
    const formattedMonth = moment(i.view.currentStart).format('MMMM YYYY');

    if (formattedMonth !== displayedMonth) {
      setDisplayedMonth(formattedMonth);
    }

    removePopup();
    setKey(0);
  };

  if (loading) {
    return <Loading dotted />;
  }

  return (
    <div className="demo-app">
      <div className="demo-app-main">
        <FullCalendar
          key={key}
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={headerToolbar}
          initialView="dayGridMonth"
          editable={false}
          selectable
          dayMaxEvents
          weekends
          displayEventEnd
          events={currentEvents || []}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: true,
          }}
          selectAllow={(selectInfo) => disablePreviousSelection(selectInfo.startStr)}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          dayCellContent={renderDayCellContent}
          dayCellClassNames={getDayCellClassNames}
          eventContent={renderEventContent}
          datesSet={handleDatesSet}
        />
      </div>
    </div>
  );
};

export default memo(DashboardCalendar);
