import { client } from '../client';

interface Payload {
  email: string;
}

export interface SendResetEmailResponse {
  message: string;
}
export const sendResetEmail = async (payload: Payload): Promise<SendResetEmailResponse> => {
  const { data } = await client.post<SendResetEmailResponse>(`/auth/send_reset_email`, payload);

  return data;
};
