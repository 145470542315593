import NotFoundPage from '@brands/Dashboard/NotFoundPage/NotFoundPage';
import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';

import { useAppSelector } from '../../../hooks/useReduxHook';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import DentalHistory from './DentalHistory';

const DentalHistoryValidation: React.FC = () => {
  let component: JSX.Element;
  const user: AuthState = useAppSelector(selectAuth);

  switch (user.userInfo?.role.name) {
    case UserRoleName.Patient:
    case UserRoleName.OrganizationAdmin:
    case UserRoleName.SuperAdmin:
    case UserRoleName.Admin:
      component = <DentalHistory />;
      break;
    default:
      // call 404 page
      component = <NotFoundPage />;
      break;
  }

  return <div>{component}</div>;
};

export default DentalHistoryValidation;
