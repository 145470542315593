/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Select from 'react-select';

import { useWindowSize } from '../../../hooks';
import {
  consultationPharmacySectionSelectStyles,
  consultationSelectStyles,
  consultationSelectStylesWithoutIndicator,
} from '../../../Utils/customStyles';
import styles from './styles.module.scss';

const CustomDropdown = React.forwardRef((props: any, ref): JSX.Element => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;

  const MobileMenu = (mobileMenuProps: any): JSX.Element => {
    const { children, innerRef, innerProps } = mobileMenuProps;

    return (
      <div className={styles.menuDivContainer} {...innerProps} ref={innerRef}>
        {children}
      </div>
    );
  };

  if (isMobile) {
    return (
      <Select
        {...props}
        styles={
          props.isConsultationPharmacySection && !props.isDisabled
            ? consultationPharmacySectionSelectStyles
            : props.isConsultationPharmacySection && props.isDisabled
            ? consultationSelectStylesWithoutIndicator
            : !props.isDisabled
            ? consultationSelectStyles
            : consultationSelectStylesWithoutIndicator
        }
        isSearchable={false}
        menuPortalTarget={document.body}
        menuPlacement="bottom"
        menuPosition="fixed"
        components={{
          Menu: MobileMenu,
        }}
        ref={ref}
      />
    );
  }

  return (
    <Select
      {...props}
      styles={
        props.isConsultationPharmacySection && !props.isDisabled
          ? consultationPharmacySectionSelectStyles
          : props.isConsultationPharmacySection && props.isDisabled
          ? consultationSelectStylesWithoutIndicator
          : !props.isDisabled
          ? consultationSelectStyles
          : consultationSelectStylesWithoutIndicator
      }
    />
  );
});

export default CustomDropdown;
