import '../../../ChatWindow/ChatModal/ChatModals.scss';

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { GrFormClose } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';

import modalClose from '../../../assets/icons/close.svg';
import { createConversation, CreateConversationPayload } from '../../../services/communication/createConversation';
import { IGMConversation } from '../../../services/communication/types/IGMConversation';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
}

const CreateNewConversation = ({ setOpenModal }: IModal): JSX.Element => {
  const navigate = useNavigate();
  const goToChatPage = useCallback((Sid: string) => navigate(`/chat/${Sid}`), [navigate]);
  const [conversationName, setConversationName] = useState<string>('');
  const [user, setUser] = useState<string>('');
  const [users, setUsers] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (users.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [users]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setConversationName(event.target.value);
  };
  const handleOnChangeUser = (event: ChangeEvent<HTMLInputElement>): void => {
    setUser(event.target.value);
  };
  const addUser = (): void => {
    if (user.trim().length !== 0) {
      setUsers([...users, user]);
      setUser('');
    }
  };

  const reset = (): void => {
    setUsers([]);
    setConversationName('');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const payload: CreateConversationPayload = {
      friendly_name: conversationName,
      participants: Array.from(new Set(users)).map((userId) => ({
        identity: userId,
      })),
    };
    await createConversation(payload).then((res: IGMConversation) => {
      goToChatPage(res.sid);
      reset();
    });
  };

  const deleteParticipant = (participantID: string): void => {
    const newList = users.filter((item) => item !== participantID);

    setUsers(newList);
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer create-conversation">
        <div className="body">
          <button
            type="button"
            className="modal-close"
            aria-label="Close modal"
            onClick={() => setOpenModal(false)}
            style={{ backgroundImage: `url(${modalClose})` }}
          />
          <form onSubmit={(e) => handleSubmit(e)}>
            <p>Create New Conversation</p>
            <div className="modal-body">
              <div className="wrap-input100 validate-input">
                <input
                  className="input100"
                  type="text"
                  name="conversationName"
                  value={conversationName}
                  onChange={handleOnChange}
                  placeholder="Conversation Name: (optional)"
                />
                <span className="focus-input100" />
              </div>
              <div className="wrap-input100 validate-input with-add-button">
                <input
                  id="participantID"
                  className="input100"
                  type="text"
                  value={user}
                  name="participantID"
                  onChange={handleOnChangeUser}
                  placeholder="Add Participant ID"
                />
                <button className="add-button" type="button" onClick={() => addUser()}>
                  Add
                </button>
                <span className="focus-input100" />
              </div>
              {users.length > 0 && (
                <div>
                  <p>Participant: </p>
                  <ul style={{ display: 'flex', gap: '15px' }}>
                    {users.map((userId) => (
                      <li key={userId}>
                        <span className="participant-span">{userId}</span>
                        <GrFormClose onClick={() => deleteParticipant(userId)} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                  <div className="login100-form-bgbtn" />
                  <button type="submit" className="login100-form-btn" disabled={isDisabled}>
                    Add new conversation
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateNewConversation;
