import { Conversation, Message, MessageUpdateReason } from '@twilio/conversations';
import Cookies from 'js-cookie';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { deleteConversationMessage } from '../../services/communication/deleteConversationMessage';
import { editConversationMessage } from '../../services/communication/editConversationMessage';
import {
  IListConversationMessagesParams,
  listConversationMessages,
} from '../../services/communication/listConversationMessages';
import { sendConversationMessage, SendMessagePayload } from '../../services/communication/sendConversationMessage';
import { IGMConversation } from '../../services/communication/types/IGMConversation';
import { IGMMessage } from '../../services/communication/types/IGMMessage';
import { Meta } from '../../services/communication/types/Meta';
import { mapToGMMessage } from '../../Utils/mapToGMMessage';

interface IConversationMessages {
  messages: IGMMessage[];
  status: 'initializing' | 'failed' | 'ready' | 'loading';
  editMessage: (message: IGMMessage, body: string) => Promise<void>;
  deleteMessage: (message: IGMMessage) => Promise<void>;
  sendMessage: (params: SendMessagePayload) => Promise<void>;
  loadNextPage: () => void;
  loadPreviousPage: () => void;
}

export default function useConversationMessages(
  twilioConversation: Conversation | null,
  params: Pick<IListConversationMessagesParams, 'search'>,
  readOnlyConversation?: IGMConversation | null,
): IConversationMessages {
  const jwtToken = Cookies.get('jwtToken');
  const [messages, setMessages] = useState<IGMMessage[]>([]);
  const [status, setStatus] = useState<IConversationMessages['status']>('initializing');
  const [firstPageMeta, setFirstPageMeta] = useState<Meta | null>(null);
  const [lastPageMeta, setLastPageMeta] = useState<Meta | null>(null);

  const sortedNodes = (nodes: IGMMessage[]): IGMMessage[] => {
    return nodes.sort((a, b) => {
      if (a.created_at < b.created_at) {
        return -1;
      }
      if (a.created_at > b.created_at) {
        return 1;
      }
      return 0;
    });
  };

  const onMessageAdded = useCallback(
    (message: Message): void => {
      const idx = messages.findIndex((c) => c.sid === message.sid);
      if (idx >= 0) {
        messages.splice(idx, 1, mapToGMMessage(message));
      } else {
        messages.push(mapToGMMessage(message));
      }
      setMessages([...messages]);
    },
    [messages],
  );

  const onMessageRemoved = useCallback(
    (message: Message): void => {
      const idx = messages.findIndex((m) => m.sid === message.sid);
      if (idx >= 0) {
        messages.splice(idx, 1);
        setMessages([...messages]);
      }
    },
    [messages],
  );

  const onMessageUpdated = useCallback(
    ({ message }: { message: Message; updateReasons: MessageUpdateReason[] }): void => {
      const idx = messages.findIndex((m) => m.sid === message.sid);
      if (idx >= 0) {
        messages.splice(idx, 1, mapToGMMessage(message));
        setMessages([...messages]);
      }
    },
    [messages],
  );

  const editMessage = async (message: IGMMessage, body: string): Promise<void> => {
    const updatedMessage = await editConversationMessage(message.conversation_sid, message.sid, { body });
    const idx = messages.findIndex((c) => c.sid === updatedMessage.sid);
    if (idx >= 0) {
      messages.splice(idx, 1, updatedMessage);
    } else {
      messages.push(updatedMessage);
    }
    setMessages([...messages]);
  };

  const deleteMessage = async (message: IGMMessage): Promise<void> => {
    const updatedMessage = await deleteConversationMessage(message.conversation_sid, message.sid);
    const idx = messages.findIndex((c) => c.sid === updatedMessage.sid);
    if (idx >= 0) {
      messages.splice(idx, 1, updatedMessage);
    } else {
      messages.push(updatedMessage);
    }
    setMessages([...messages]);
  };

  const sendMessage = async (messagePayload: SendMessagePayload): Promise<void> => {
    try {
      if (!twilioConversation?.sid) {
        return;
      }

      const newMessage = await sendConversationMessage(twilioConversation.sid, messagePayload);
      const messageIndex = messages.findIndex((message) => message.sid === newMessage.sid);

      if (messageIndex !== -1) {
        // If the message already exists in the array, update its contents
        messages.splice(messageIndex, 1, newMessage);
      } else {
        // If the message does not exist in the array, insert it at the correct position
        let insertIndex = 0;
        while (insertIndex < messages.length && newMessage.created_at < messages[insertIndex].created_at) {
          insertIndex++;
        }
        messages.splice(insertIndex, 0, newMessage);
      }

      setMessages([...messages]);
    } catch (error) {
      toast.error('Failed to send message');
    }
  };

  const loadNextPage = async (): Promise<void> => {
    if ((!twilioConversation && !readOnlyConversation) || !jwtToken || !lastPageMeta?.has_next_page) {
      return;
    }
    setStatus('loading');
    const { meta, nodes } = await listConversationMessages(
      twilioConversation ? twilioConversation.sid : readOnlyConversation?.sid || '',
      {
        cursor: lastPageMeta.end_cursor,
        cursor_direction: 'next',
        ...params,
      },
    );
    setMessages(sortedNodes(uniqBy([...nodes, ...messages], 'sid')));
    setLastPageMeta(meta);
    if (!firstPageMeta) {
      setFirstPageMeta(meta);
    }
    setStatus('ready');
  };

  const loadPreviousPage = async (): Promise<void> => {
    if ((!twilioConversation && !readOnlyConversation) || !jwtToken || !firstPageMeta?.has_previous_page) {
      return;
    }

    setStatus('loading');
    const { meta, nodes } = await listConversationMessages(
      twilioConversation ? twilioConversation.sid : readOnlyConversation?.sid || '',
      {
        cursor: firstPageMeta.start_cursor,
        cursor_direction: 'previous',
        ...params,
      },
    );
    setMessages(sortedNodes(uniqBy([...messages, ...nodes], 'sid')));
    setFirstPageMeta(meta);
    if (!lastPageMeta) {
      setLastPageMeta(meta);
    }
    setStatus('ready');
  };

  useEffect(() => {
    if (!twilioConversation && !readOnlyConversation) {
      setMessages([]);
      return;
    }
    setStatus('initializing');
    listConversationMessages(
      twilioConversation ? twilioConversation.sid : readOnlyConversation?.sid || '',
      params,
    ).then(({ meta, nodes }) => {
      setMessages(sortedNodes(nodes));
      setFirstPageMeta(meta);
      setLastPageMeta(meta);
      setStatus('ready');
    });
  }, [twilioConversation, readOnlyConversation, jwtToken, params]);

  useEffect(() => {
    if (!twilioConversation) {
      return () => {};
    }

    twilioConversation.on('messageAdded', onMessageAdded);
    twilioConversation.on('messageRemoved', onMessageRemoved);
    twilioConversation.on('messageUpdated', onMessageUpdated);

    return () => {
      twilioConversation.off('messageAdded', onMessageAdded);
      twilioConversation.off('messageRemoved', onMessageRemoved);
      twilioConversation.off('messageUpdated', onMessageUpdated);
    };
  }, [twilioConversation, onMessageAdded, onMessageRemoved, onMessageUpdated]);

  useEffect(() => {
    if (!twilioConversation || messages.length === 0) {
      return;
    }
    twilioConversation.getMessages(messages.length, messages[0].index, 'forward');
  }, [twilioConversation, messages]);

  return {
    messages,
    status,
    editMessage,
    deleteMessage,
    sendMessage,
    loadNextPage,
    loadPreviousPage,
  };
}
