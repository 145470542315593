import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';

import styles from './styles.module.scss';

const SuccessAlert = (): JSX.Element => (
  <div className={styles.alertText}>
    <div>Your information has been saved</div>
    <div className={styles.alertIcon}>
      <AiOutlineCheck />
    </div>
  </div>
);

export default SuccessAlert;
