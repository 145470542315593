import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ModalState {
  updateEmailAddress: boolean;
  confirmEmailAddress: boolean;
  verifyEmailAddress: boolean;
}

const initialState: ModalState = {
  updateEmailAddress: false,
  confirmEmailAddress: false,
  verifyEmailAddress: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ modal: string }>) => {
      return {
        ...state,
        [action.payload.modal]: true,
      };
    },
    closeModal: (state, action: PayloadAction<{ modal: string }>) => {
      return {
        ...state,
        [action.payload.modal]: false,
      };
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
