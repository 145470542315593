/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import SmileScanMobile from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_mobile.svg';
import uploadImage from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/drag-image-icon.svg';
import useSubscribe, { Subscriber } from '@brands/hooks/useSubscribe';
import { setUserWebState } from '@brands/services/auth/setUserWebState';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setForm } from '@brands/store/slices/patientFormSlice';
import store from '@brands/store/store';
import { displayErrorDetails } from '@brands/Utils/displayError';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import MobileHandoffModal from './Components/MobileHandoffModal';
import styles from './styles.module.scss';

const ImageUploadWeb = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const screenSize = useWindowSize();
  const { state, search } = useLocation();
  const { userInfo } = useAppSelector(selectAuth);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const { currentOrganizationPrices } = useAppSelector(selectOrganizationPrices);
  const [loading, setLoading] = useState(false);
  const [displayMobileHandOffModal, setDisplayMobileHandOffModal] = useState(false);
  const queryParams = useMemo(() => queryString.parse(search), [search]);
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const currentState: string = useAppSelector(selectCurrentPatientState);

  const [newUrl, setNewUrl] = useState('');

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (['mobile_handoff'].includes(message.command)) {
          setDisplayMobileHandOffModal(true);
        } else if (['mobile_case_creation_complete'].includes(message.command)) {
          setDisplayMobileHandOffModal(false);
          goToDashboard();
        }
      },
      commands: ['mobile_handoff', 'mobile_case_creation_complete'],
    }),
    [setDisplayMobileHandOffModal],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  const appendStateToUrl = (jwtToken: string): string => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);

    // Set the 'auth' parameter with the JWT token
    url.searchParams.set('auth', encodeURIComponent(jwtToken));

    return url.toString();
  };

  const goToDashboard = useCallback(() => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      navigate(`/admin/review-profile/Patient/${state.patientId}`);
    } else {
      navigate('/dashboard');
    }
  }, [userInfo.role.name, navigate]);

  useEffect(() => {
    const fetchDataAndSetState = async (): Promise<void> => {
      setLoading(true);
      if (!queryParams.state) {
        // Retrieve and compress the Redux state
        const reduxState = store.getState();

        // Extract only the needed parts
        const { auth, pageState } = reduxState;

        // Create a new object with the selected parts
        const selectedState = {
          auth,
          patientForm: reduxState.patientForm,
          selectedPatient: currentPatient,
          pageState,
          patientcurrentOrganization: currentOrganization,
          patientcurrentOrganizationPrices: currentOrganizationPrices,
          refreshToken: Cookies.get('refreshToken'),
          jwtTokenExpire: Cookies.get('jwtTokenExpire'),
          patientState: currentState,
        };

        // Set User Web State
        try {
          await setUserWebState(selectedState);
        } catch (error: unknown) {
          displayErrorDetails(error);
        }

        setNewUrl(appendStateToUrl(Cookies.get('jwtToken') as string));
        setLoading(false);
      }
    };
    if (queryParams.auth === undefined) {
      fetchDataAndSetState();
    }
  }, [queryParams]);

  const onBack = (): void => {
    dispatch(setForm([...values!]));
    navigate(-1);
  };

  if (loading) {
    return <Loading fullScreen />;
  }

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.uploadImageContainer}>
        <div className={styles.uploadImageContainerDiv}>
          <ProgressBar currentStep={3} totalSteps={4} onBack={onBack} hideStepNumbers />
          <div className={`${styles.formTitle} d-flex mt-3`}>
            {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' ||
            (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
              ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
                ? 'Image upload'
                : 'Attachment Upload *'
              : 'Image upload'}
          </div>
          <div className={styles.uploadImageQRContainer}>
            <div>
              {' '}
              <img className={styles.smileScanMobile} src={SmileScanMobile} alt="SmileScanMobile" />
            </div>
            <div className={styles.uploadImageQRContainerText}>
              <span className={styles.uploadImageQRContainerText1}>Use Our Guided Image Capture</span>
              <span className={styles.uploadImageQRContainerText2}>
                For a more seamless experience continue from your phone
              </span>
              <span className={styles.uploadImageQRContainerText3}>Use the QR code to get started.</span>
            </div>
            <div>
              <QRCode value={newUrl} size={150} />
            </div>
          </div>
          <div className={styles.uploadImageQRFooter}>
            <div>
              <img className={styles.uploadImageQRFooterImage} src={uploadImage} alt="Upload Imahe" />
            </div>
            <div className={styles.uploadImageQRFooterText}>
              <span className={styles.clickHere}>
                <a onClick={() => navigate('/upload-image-flow')}>Click Here</a>
              </span>{' '}
              to upload images of your mouth and teeth from your computer.
            </div>
          </div>
          {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) !== 'one_report' &&
            (values.find((item) => item.form_question_id === 20)?.value.caseType as string) !== 'second_opinion' && (
              <div className={styles.btnContainer}>
                <Button
                  type="button"
                  style={{ width: '245px', height: '46px' }}
                  onClick={() =>
                    navigate('/medical-information-summary', {
                      state: {
                        caseType: state
                          ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string)
                          : '',
                        patientId: selectedPatient?.value || '',
                        shouldSeeLastModal: false,
                        fromPatientIntake: true,
                      },
                    })
                  }
                >
                  Skip
                </Button>
              </div>
            )}
        </div>
        {displayMobileHandOffModal && <MobileHandoffModal />}
      </div>
    </Container>
  );
};
export default ImageUploadWeb;
