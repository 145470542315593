import React from 'react';

import styles from './styles.module.scss';

interface FileUploadProgressBarProps {
  progress: number;
}

const FileUploadProgressBar: React.FC<FileUploadProgressBarProps> = ({ progress }) => {
  const cappedProgress = Math.min(progress, 100);
  const roundedProgress = Math.floor(cappedProgress);

  return (
    <div className={styles.fileUploadProgressDiv}>
      <div className={styles.progressBar}>
        <span className={styles.progressText}>Upload Progress: {roundedProgress}%</span>
        <div className={styles.progressTextGreen} style={{ width: `${roundedProgress}%` }} />
      </div>
    </div>
  );
};

export default FileUploadProgressBar;
