import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import Button from '../../../../../Components/Button/Button';
import { useWindowSize } from '../../../../../hooks';
import { CasePayload } from '../../../../../services/cases/createNewCase';
import { ICase } from '../../../../../services/cases/types/ICase';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import video from '../../assets/liveVideo.svg';
import report from '../../assets/oralHealthReport.svg';
import sVideo from '../../assets/scheduledVideo.svg';
import sOpinion from '../../assets/secondOpinion.svg';
import styles from './consultType.module.scss';

interface IModal {
  openModal: (arg0: boolean) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  editCaseTypeFn: (case_id: number, body: CasePayload) => Promise<ICase | null>;
}

const ConsultType = ({ openModal, setLoading, thisCase, setCase, editCaseTypeFn }: IModal): JSX.Element => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;

  const [selectedCT, isSelectedCT] = useState<string>('');

  const handleCT = (target: string): void => {
    if (selectedCT === target) {
      isSelectedCT('');
    } else {
      isSelectedCT(target);
    }
  };

  const changeCaseType = async (selectedCaseType: string): Promise<void> => {
    const payload: CasePayload = {
      type: selectedCaseType,
    };

    if (typeof thisCase?.id === 'number' && selectedCaseType !== '') {
      try {
        setLoading(true);
        const updatedCase = await editCaseTypeFn(thisCase.id, payload);
        if (updatedCase) {
          setCase({
            ...thisCase,
            type: updatedCase.type,
            updated_at: updatedCase.updated_at,
            started_at: updatedCase.started_at,
            video_conference_date: updatedCase.video_conference_date,
          });
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.providerListContainer}>
          {isMobile && (
            <div className={styles.mobileHeader}>
              <p>Consult Type</p>
            </div>
          )}
          <div className={styles.mobileStickyHeader}>
            <div className={`row ${styles.providerListHeader}`}>
              <span>Consult Type</span>
              <div className={styles.btnGroup}>
                <Button
                  className={styles.btn}
                  onClick={() => {
                    openModal(false);
                    changeCaseType(selectedCT);
                  }}
                >
                  Change Consult Type
                </Button>
                <button className={styles.cBtn} type="button" onClick={() => openModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className={styles.cardsContainer}>
            <div
              className={`${styles.consultTypeCard} ${
                selectedCT === 'video_call_instant' ? styles.selectedClass : styles.notSelectedClass
              }`}
            >
              <div className={styles.mobileWrap}>
                <span>Live Video</span>
                <img className={`${styles.icon} ${styles.video}`} src={video} alt="video" />
              </div>
              <button type="button" onClick={() => handleCT('video_call_instant')} aria-label="Live Video">
                <FaCheckCircle
                  className={`${selectedCT === 'video_call_instant' ? styles.primaryColor : styles.defaultColor}`}
                />
              </button>
            </div>
            <div
              className={`${styles.consultTypeCard} ${
                selectedCT === 'video_call_scheduled' ? styles.selectedClass : styles.notSelectedClass
              }`}
            >
              <div className={styles.mobileWrap}>
                <span>Scheduled Video</span>
                <img className={`${styles.icon} ${styles.sVideo}`} src={sVideo} alt="video" />
              </div>
              <button type="button" onClick={() => handleCT('video_call_scheduled')} aria-label="Scheduled Video">
                <FaCheckCircle
                  className={`${selectedCT === 'video_call_scheduled' ? styles.primaryColor : styles.defaultColor}`}
                />
              </button>
            </div>
            <div
              className={`${styles.consultTypeCard} ${
                selectedCT === 'one_report' ? styles.selectedClass : styles.notSelectedClass
              }`}
            >
              <div className={styles.mobileWrap}>
                <span>Photo Review</span>
                <img className={`${styles.icon} ${styles.report}`} src={report} alt="video" />
              </div>
              <button type="button" onClick={() => handleCT('one_report')} aria-label="Oral Health Report">
                <FaCheckCircle
                  className={`${selectedCT === 'one_report' ? styles.primaryColor : styles.defaultColor}`}
                />
              </button>
            </div>
            <div
              className={`${styles.consultTypeCard} ${
                selectedCT === 'second_opinion' ? styles.selectedClass : styles.notSelectedClass
              }`}
            >
              <div className={styles.mobileWrap}>
                <span>Second Opinion</span>
                <img className={`${styles.icon} ${styles.sOpinion}`} src={sOpinion} alt="video" />
              </div>
              <button type="button" onClick={() => handleCT('second_opinion')} aria-label="Second Opinion">
                <FaCheckCircle
                  className={`${selectedCT === 'second_opinion' ? styles.primaryColor : styles.defaultColor}`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConsultType;
