import { Status } from '../services/cases/types/ICase';
import { getSecondaryColor } from './getPrimaryColor';

export const getColorForStatus = (status: Status): string => {
  switch (status) {
    case Status.New:
      return '#ffe9cd';
    case Status.AcceptedCareAdvisor:
      return '#FFFCC9';
    case Status.AcceptedProvider:
      return 'yellow';
    case Status.InProgressProvider:
      return '#cce3d7';
    case Status.CompletedByProvider:
      return '#cce3d7';
    case Status.InProgressCareAdvisor:
      return '#61a174';
    case Status.Completed:
      return '#3b72b8';
    case Status.Canceled:
      return 'Grey';
    default:
      return 'transparent';
  }
};

export const getTextColorForStatus = (status: Status): string => {
  switch (status) {
    case Status.New:
      return getSecondaryColor();
    case Status.AcceptedCareAdvisor:
      return getSecondaryColor();
    case Status.AcceptedProvider:
      return '2c3e50';
    case Status.CompletedByProvider:
      return getSecondaryColor();
    case Status.InProgressProvider:
      return getSecondaryColor();
    case Status.InProgressCareAdvisor:
      return getSecondaryColor();
    case Status.Completed:
      return 'white';
    case Status.Canceled:
      return 'white';
    default:
      return 'transparent';
  }
};
