import './ChatModals.scss';

import { displayErrorDetails } from '@brands/Utils/displayError';
import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  addConversationParticipant,
  ParticipantPayload,
} from '../../services/communication/addConversationParticipant';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
  conversationSid: string;
}

const AddParticipantModal = ({ setOpenModal, conversationSid }: IModal): JSX.Element => {
  const [user, setUser] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (user !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [user]);

  const handleOnChangeUser = (event: ChangeEvent<HTMLInputElement>): void => {
    setUser(event.target.value);
  };
  const addUser = (): void => {
    if (user.trim().length !== 0) {
      setUser(user);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const payload: ParticipantPayload = {
      identity: user,
    };
    await addConversationParticipant(payload, conversationSid).catch((err) => {
      Promise.reject(err.message);
      displayErrorDetails(err);
    });

    setOpenModal(false);
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer create-conversation">
        <div className="body">
          <button type="button" className="modal-close" aria-label="Close modal" onClick={() => setOpenModal(false)} />
          <form onSubmit={(e) => handleSubmit(e)}>
            <p>Add participant</p>
            <div className="modal-body">
              <div className="wrap-input100 validate-input with-add-button">
                <input
                  id="participantID"
                  className="input100"
                  type="text"
                  value={user}
                  name="participantID"
                  onChange={handleOnChangeUser}
                  placeholder="Add Participant ID"
                />
                <button className="add-button" type="button" onClick={() => addUser()}>
                  Add
                </button>
                <span className="focus-input100" />
              </div>
              {user && (
                <div>
                  <p>Participant: </p>
                  <ul style={{ display: 'flex', gap: '15px' }}>
                    <li key={user}>
                      <span className="participant-span">{user}</span>
                      {/* <GrFormClose onClick={() => deleteParticipant(user)} /> */}
                    </li>
                  </ul>
                </div>
              )}
              <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                  <div className="login100-form-bgbtn" />
                  <button type="submit" className="login100-form-btn" disabled={isDisabled}>
                    Add participant
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddParticipantModal;
