/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { getForm } from '@brands/services/forms/getForm';
import { FormQuestion } from '@brands/services/forms/types/FormQuestion';
import { selectAuth } from '@brands/store/selectors/auth';
import { pronounsOptions } from '@brands/Utils/selectButtonOptions';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { BsArrowRightCircleFill } from 'react-icons/bs';

import Collapsible from '../../../../Components/Collapsible/Collapsible';
import { useWindowSize } from '../../../../hooks';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { ICase } from '../../../../services/cases/types/ICase';
import { getFormAnswer } from '../../../../services/forms/getFormAnswer';
import { listForms } from '../../../../services/forms/listForms';
import { getUserAvatar } from '../../../../services/identity/getUserAvatar';
import { ITags, listAllTags } from '../../../../services/tags/listAllTags';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { getGenderName } from '../../../../Utils/getGenderName';
import {
  hasUnreadMessageInPatientCA,
  hasUnreadMessageInPatientProvider,
} from '../../../../Utils/getUnreadMessageNotification';
import { Option, stateOptions } from '../../../../Utils/selectOptions';
import { Tag } from '../../assets/types';
import styles from '../../styles.module.scss';
import PatientCasesList from '../PatientCasesList/PatientCasesList';

type ConsulationPatientInfoProps = {
  thisCase: ICase;
  profileCardTags: Option[];
  setProfileCardModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMedicalInformationModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConsultationPatientInfo = ({
  thisCase,
  profileCardTags,
  setProfileCardModal,
  setMedicalInformationModal,
}: ConsulationPatientInfoProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const [medications, setMedications] = useState<string>('');
  const [allergies, setAllergies] = useState<string>('');
  const [height, setHeight] = useState<string>('');
  const [weight, setWeight] = useState<string>('');

  const [dateOfLastDentalVisit, setDateOfLastDentalVisit] = useState<string>('');
  const [visitType, setvisitType] = useState<string>('');
  const [whoDidYouSee, setwhoDidYouSee] = useState<string>('');

  const [pharmacyName, setPharmacyName] = useState<string>('');
  const [pharmacyAddress, setPharmacyAddress] = useState<string>('');
  const [pharmacyPhoneNumber, setPharmacyPhoneNumber] = useState<string>('');
  const [conditionTags, setConditionTags] = React.useState<Tag[]>([]);
  const displayPhoneNumber = useMemo(() => {
    if (thisCase?.patient?.phone_number) {
      const phoneNumber = parsePhoneNumber(thisCase.patient.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    } else if (thisCase?.patient?.guardian?.phone_number) {
      const phoneNumber = parsePhoneNumber(thisCase?.patient?.guardian?.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    }
    return '';
  }, [thisCase]);

  const displayPharmacyPhoneNumber = (pharmacyPhoneNum: string): string => {
    if (!pharmacyPhoneNum) {
      return '';
    }
    const phoneNumber = parsePhoneNumber(pharmacyPhoneNum, 'US');
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return '';
  };

  const fetchOptions = async (): Promise<void> => {
    const response = await listAllTags();
    const filteredData = response.filter((item: ITags) => !item.patient_owned);
    const options: Option[] = filteredData.map((item: ITags) => ({
      value: item.id,
      label: item.name,
    }));
    setConditionTags(options);
  };

  const findQuestionIdByTitle = (questions: FormQuestion[], title: string): number | undefined => {
    const question = questions.find((q) => q.title === title);
    return question ? question.id : undefined;
  };

  const getFormAnswers = async (): Promise<void> => {
    try {
      const { forms: formList } = await listForms();
      const formId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
      const formAnswers = await getFormAnswer({
        form_id: formId,
        created_by_id: thisCase?.patient?.role?.name === 'Dependent' ? thisCase?.patient?.id : thisCase?.patient_id,
      });

      const sortedFormAnswers = Object.values(formAnswers.form_answers).sort((a, b) => a.id - b.id);

      const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
      const { steps } = await getForm(newFormId.toString());
      const { questions } = steps[0];
      const dentalQuestions = steps[2].questions;

      const medicationsQuestionId = findQuestionIdByTitle(
        questions,
        'Are you taking, have you recently taken (within the last month), or are you supposed to be taking any medications? (Prescription, over-the-counter, diet supplements, vitamins, natural, or herbal)',
      );
      const allergiesQuestionId = findQuestionIdByTitle(
        questions,
        'Are you allergic to any medications, metal, latex, or certain materials?',
      );
      const heightQuestionId = findQuestionIdByTitle(questions, 'Height');
      const weightQuestionId = findQuestionIdByTitle(questions, 'Weight');
      const pharmacyNameQuestionId = findQuestionIdByTitle(questions, 'Pharmacy Name');
      const pharmacyStreetAddressQuestionId = findQuestionIdByTitle(questions, 'Pharmacy Street Address');
      const pharmacyCityQuestionId = findQuestionIdByTitle(questions, 'Pharmacy City');
      const pharmacyStateQuestionId = findQuestionIdByTitle(questions, 'Pharmacy State');
      const pharmacyZipCodeQuestionId = findQuestionIdByTitle(questions, 'Pharmacy Zip Code');
      const pharmacyPhoneNumberQuestionId = findQuestionIdByTitle(questions, 'Pharmacy Phone Number');

      const lastDentalVisitId = findQuestionIdByTitle(dentalQuestions, 'Date of your last dental visit');
      const visitTypeId = findQuestionIdByTitle(dentalQuestions, 'Visit Type');
      const whoDidYouSeeId = findQuestionIdByTitle(dentalQuestions, 'Who did you see?');

      const medicationFormAnswer = sortedFormAnswers.find((answer) =>
        answer.values.some((value) => value.form_question_id === medicationsQuestionId),
      );

      const lastDentalVisitFormAnswer = sortedFormAnswers.find((answer) =>
        answer.values.some((value) => value.form_question_id === lastDentalVisitId),
      );

      setMedications(
        medicationFormAnswer?.values.find((value) => value.form_question_id === medicationsQuestionId)?.value.data ||
          '',
      );
      setAllergies(
        medicationFormAnswer?.values.find((value) => value.form_question_id === allergiesQuestionId)?.value.data || '',
      );
      setHeight(
        medicationFormAnswer?.values
          .find((value) => value.form_question_id === heightQuestionId)
          ?.value.data?.replace(/,/g, ' ') || '',
      );
      setWeight(
        medicationFormAnswer?.values.find((value) => value.form_question_id === weightQuestionId)?.value.data || '',
      );
      setPharmacyName(
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyNameQuestionId)?.value.data ||
          '',
      );

      setDateOfLastDentalVisit(
        lastDentalVisitFormAnswer?.values.find((value) => value.form_question_id === lastDentalVisitId)?.value.data ===
          'dontKnow'
          ? 'Don’t Know'
          : lastDentalVisitFormAnswer?.values.find((value) => value.form_question_id === lastDentalVisitId)?.value
              .data || '',
      );

      setvisitType(
        lastDentalVisitFormAnswer?.values.find((value) => value.form_question_id === visitTypeId)?.value.data || '',
      );

      setwhoDidYouSee(
        lastDentalVisitFormAnswer?.values.find((value) => value.form_question_id === whoDidYouSeeId)?.value.data || '',
      );

      const pharmacyAddressValues = [
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyStreetAddressQuestionId)?.value
          .data || '',
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyCityQuestionId)?.value.data ||
          '',
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyStateQuestionId)?.value.data ||
          '',
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyZipCodeQuestionId)?.value
          .data || '',
      ];

      const filteredPharmacyAddressValues = pharmacyAddressValues.filter(
        (value) => value !== undefined && value !== null && value !== '',
      );

      setPharmacyAddress(filteredPharmacyAddressValues.join(', '));
      setPharmacyPhoneNumber(
        displayPharmacyPhoneNumber(
          medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyPhoneNumberQuestionId)?.value
            .data || '',
        ),
      );
    } catch (error) {
      console.error('Error fetching form answers:', error);
    }
  };

  useEffect(() => {
    fetchOptions();
    getFormAnswers();
  }, []);

  return (
    <div className={styles.patientInfoContainer}>
      <div className={styles.patient}>
        <span className={styles.imageContainer} onClick={() => setProfileCardModal(true)}>
          {(hasUnreadMessageInPatientCA(
            thisCase,
            thisCase.patient.guardian_relationship === null ? thisCase.patient_id : thisCase.patient.guardian_id!,
          ) ||
            hasUnreadMessageInPatientProvider(
              thisCase,
              thisCase.patient.guardian_relationship === null ? thisCase.patient_id : thisCase.patient.guardian_id!,
            )) && <span className={styles.unreasMessage} />}
          <img
            alt="Avatar"
            onError={(event: any) => {
              const img = event.target;
              img.src =
                'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
              img.onError = null;
            }}
            className={styles.patientImage}
            src={getUserAvatar(Number(thisCase?.patient.id))}
          />
        </span>
        <div className={styles.patientDiv}>
          <span className={styles.patientName} onClick={() => setProfileCardModal(true)}>
            {thisCase?.patient.full_name}
          </span>
        </div>
      </div>
      <div className={styles.patient}>
        <div className={styles.detailsWrapper}>
          <div>
            <span>Gender assigned at birth</span>
            <p>
              {getGenderName(thisCase?.patient?.basic_info?.gender)} -{' '}
              {pronounsOptions.find((option) => option.value === thisCase?.patient?.basic_info?.pronouns)?.label ||
                thisCase?.patient?.basic_info?.pronouns}
            </p>
          </div>
          <div>
            <span>DOB</span>
            <p>{moment(thisCase?.patient?.basic_info?.date_of_birth).format('MM/DD/YYYY')}</p>
          </div>
          <div>
            <span>Location</span>
            <p>
              {stateOptions.find((option) => option.value === thisCase?.patient_current_state)?.label.split(' - ')[1]}
            </p>
          </div>
          {!isMobile && (
            <div>
              <span>Language(s)</span>
              <p>
                {thisCase?.patient?.basic_info?.languages
                  ?.map((language) => typeof language !== 'string' && language.name)
                  .join(', ')}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.patient}>
        <div
          className={`${styles.detailsWrapper} ${styles.addressDetails} ${styles.additionalDetailsWrapper}`}
          style={{ paddingTop: '0px' }}
        >
          <div>
            <span className="text-nowrap">Phone Number</span>
            <p className="text-nowrap">{displayPhoneNumber}</p>
          </div>
          <div className={styles.withoutRightBorderOnMobile}>
            <span>Email Address</span>
            <p>{thisCase?.patient?.email || thisCase?.patient?.guardian?.email}</p>
          </div>
          <div style={{ borderRight: 'none', flex: '2' }}>
            <span>Address</span>
            <p>
              {thisCase?.patient?.address?.address1} {thisCase?.patient?.address?.address2},
              {thisCase?.patient?.address?.city}, {thisCase?.patient?.address?.state},
              {thisCase?.patient?.address?.zip_code}
            </p>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className={styles.patient}>
          <div className={styles.detailsWrapper} style={{ padding: '0' }}>
            <div>
              <span>Language(s)</span>
              <p>
                {thisCase?.patient?.basic_info?.languages
                  ?.map((language) => typeof language !== 'string' && language.name)
                  .join(', ')}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className={styles.patient}>
        <div className={styles.underlinedDiv}>
          <div className={styles.flexDiv}>
            <div className={styles.flexRowDiv}>
              <span>Insurance: </span>
              <p>{thisCase?.insurance?.insurance_membership?.insurance_carrier?.name || '-'}</p>
            </div>
            <div className={styles.flexRowDiv}>
              <span>Insurance Member ID:</span>
              <p>{thisCase?.insurance?.insurance_membership?.member_id || '-'}</p>
            </div>
            <div className={styles.flexRowDiv}>
              <span>Insurance Group Number:</span>
              <p>{thisCase?.insurance?.insurance_membership?.group_number || '-'}</p>
            </div>
            <div className={`${styles.flexRowDiv} ${styles.brandViv}`}>
              <span>Organization: </span>
              <div className="d-flex">
                <p className={styles.brand}>{thisCase?.organization.name}</p>
              </div>
            </div>
            <div className={`${styles.flexRowDiv} ${styles.brandViv}`}>
              <span>Client Tags: </span>
              <div className="d-flex">
                <p className={styles.brand}>{thisCase?.patient.markers?.map((marker) => marker.value).join(', ')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? (
        <Collapsible
          isMobile={isMobile}
          patientStyle
          headerStyle={styles.pInfoCollapsibleHeader}
          header={<div className={styles.pInfoCollapsibleHeader}>Medical and Dental Information</div>}
          thisCaseStatus={thisCase?.status || ''}
          userRole={userInfo.role.name}
          currentStatus={thisCase?.status || ''}
        >
          <div className={styles.patient}>
            <div className={styles.underlinedDiv}>
              <div className={styles.flexCol}>
                <div>
                  <span>Medical and Dental Information</span>
                </div>
                <p>{profileCardTags.map((tag) => tag.label).join(', ')}</p>
                <div className={styles.tags}>
                  {conditionTags.map((tag) => (
                    <div className={styles.tag} key={tag.label}>
                      <div className={styles.label}>{tag.label}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.measurementsDiv}>
                <div>
                  <span className={styles.title}>Height: </span> <span className={styles.value}>{height}</span>
                </div>
                <div>
                  <span className={styles.title}>Weight: </span> <span className={styles.value}>{weight}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.patient}>
            <div className={styles.underlinedDiv}>
              <div className={`${styles.dental}`}>
                <div className={styles.flexCol}>
                  <div>
                    <span>Date of your last dental visit?</span>
                  </div>
                  <span className={styles.dentalAnswer}>{dateOfLastDentalVisit}</span>
                </div>
                <div className={styles.flexCol}>
                  <div>
                    <span>Visit type</span>
                  </div>
                  <span className={styles.dentalAnswer}>{visitType}</span>
                </div>
                <div className={styles.flexCol}>
                  <div>
                    <span>Who did you see?</span>
                  </div>
                  <span className={styles.dentalAnswer}>{whoDidYouSee}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.patient} style={{ maxWidth: '50%' }}>
            <div className={styles.underlinedDiv}>
              <div className={styles.flexCol}>
                <span>Medications</span>
                <p>{medications}</p>
              </div>
            </div>
          </div>
          <div className={styles.patient}>
            <div className={styles.underlinedDiv}>
              <div className={styles.flexCol}>
                <span>Allergies</span>
                <p>{allergies}</p>
              </div>
            </div>
          </div>
          <div className={styles.patient}>
            {((pharmacyName !== '' && pharmacyName !== 'no') ||
              (pharmacyAddress !== '' && !pharmacyAddress.includes('no')) ||
              (pharmacyPhoneNumber !== '' && pharmacyPhoneNumber !== 'no')) && (
              <div className={styles.underlinedDiv}>
                <div className={styles.flexCol}>
                  <div className={styles.pharmacyDiv}>
                    <div className={styles.pharmacyTitle}>Pharmacy</div>
                    <div>
                      <div>{pharmacyName !== '' && pharmacyName}</div>
                      <div>{pharmacyAddress !== '' && pharmacyAddress}</div>
                      <div>{pharmacyPhoneNumber !== '' && pharmacyPhoneNumber}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Collapsible>
      ) : (
        <>
          <div className={styles.patient}>
            <div className={`${styles.underlinedDiv} ${styles.medicalWrap}`}>
              <div className={`${styles.medical}`}>
                <div className={styles.flexCol}>
                  <div>
                    <span>Medical and Dental Information</span>
                  </div>
                  <p>{profileCardTags.map((tag) => tag.label).join(', ')}</p>
                  <div className={styles.tags}>
                    {conditionTags.map((tag) => (
                      <div className={styles.tag} key={tag.value}>
                        <div className={styles.label}>{tag.label}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.measurementsDiv}>
                  <div>
                    <span className={styles.title}>Height: </span> <span className={styles.value}>{height}</span>
                  </div>
                  <div>
                    <span className={styles.title}>Weight: </span> <span className={styles.value}>{weight}</span>
                  </div>
                </div>
              </div>
              <div className={`${styles.dental}`}>
                <div className={styles.flexCol}>
                  <div>
                    <span>Date of your last dental visit?</span>
                  </div>
                  <span className={styles.dentalAnswer}>{dateOfLastDentalVisit}</span>
                </div>
                <div className={styles.flexCol}>
                  <div>
                    <span>Visit type</span>
                  </div>
                  <span className={styles.dentalAnswer}>{visitType}</span>
                </div>
                <div className={styles.flexCol}>
                  <div>
                    <span>Who did you see?</span>
                  </div>
                  <span className={styles.dentalAnswer}>{whoDidYouSee}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.patient}>
            <div className={styles.underlinedDiv}>
              <div className={styles.seperator}>
                <div className={styles.flexCol}>
                  <span>Medications</span>
                  <p>{medications}</p>
                </div>
                <div className={styles.flexCol}>
                  <span>Allergies</span>
                  <p>{allergies}</p>
                </div>
              </div>
              {((pharmacyName !== '' && pharmacyName !== 'no') ||
                (pharmacyAddress !== '' && !pharmacyAddress.includes('no')) ||
                (pharmacyPhoneNumber !== '' && pharmacyPhoneNumber !== 'no')) && (
                <div className={styles.pharmacyDiv}>
                  <div className={styles.pharmacyTitle}>Pharmacy</div>
                  <div>
                    {pharmacyName !== '' && <span>{pharmacyName}</span>}
                    {pharmacyAddress !== '' && <span>{pharmacyAddress}</span>}
                    {pharmacyPhoneNumber !== '' && <span>{pharmacyPhoneNumber}</span>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className={`${styles.patient} ${styles.viewMedicalHistory}`} style={{ paddingTop: !isMobile ? '20px' : '' }}>
        <button
          type="button"
          onClick={() => {
            if (thisCase.patient.status === 'Active') {
              setMedicalInformationModal(true);
            } else {
              const error: any = {
                response: {
                  data: {
                    message: `Previous medical/dental information is not available for deleted ${thisCase.patient.role.name.toLowerCase()}`,
                  },
                },
              };
              displayErrorDetails(error);
            }
          }}
        >
          View Medical History <BsArrowRightCircleFill />
        </button>
      </div>
      {isMobile ? (
        <Collapsible
          isMobile={isMobile}
          patientStyle
          headerStyle={styles.pInfoCollapsibleHeader}
          header={<div className={styles.pInfoCollapsibleHeader}>Other Consultations for this Patient</div>}
          thisCaseStatus={thisCase?.status || ''}
          userRole={userInfo.role.name}
          currentStatus={thisCase?.status || ''}
        >
          <PatientCasesList patient_id={thisCase.patient_id} isMobile currentCaseId={thisCase.id} />
        </Collapsible>
      ) : (
        <PatientCasesList patient_id={thisCase.patient_id} currentCaseId={thisCase.id} />
      )}
    </div>
  );
};
export default ConsultationPatientInfo;
