import { useAppSelector } from '@brands/hooks/useReduxHook';
import { ICase } from '@brands/services/cases/types/ICase';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { convertZoneAbbr, getTimezoneAbbreviation } from '@brands/Utils/getParsedTimezoneName';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import ProfileComponent from '../CareAdvisor/Component/ProfileComponent';
import styles from './dashboardModal.module.scss';

type ScheduleLiveConsultationType = {
  newCase: ICase;
  isRescheduled?: boolean;
};

const ScheduleLiveConsultation: React.FC<ScheduleLiveConsultationType> = ({ newCase, isRescheduled }) => {
  const navigate = useNavigate();
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const { userInfo } = useAppSelector(selectAuth);
  const currentTimezone =
    userInfo.role.name === UserRoleName.Admin ||
    userInfo.role.name === UserRoleName.SuperAdmin ||
    userInfo.role.name === UserRoleName.OrganizationAdmin
      ? getTimezoneAbbreviation(newCase.patient.address?.state as string)
      : getTimezoneAbbreviation(currentState[userInfo.id]);
  const timezoneWithUnderscore = convertZoneAbbr(currentTimezone as string);
  const goToDashboard = useCallback(() => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      navigate(`/admin/review-profile/Patient/${currentPatient.id}`);
    } else if (isRescheduled) {
      navigate('/appointments');
    } else {
      navigate('/dashboard');
    }
  }, [userInfo.role.name, navigate]);

  const getCaseDate = (): string => {
    const date = moment.utc(newCase.video_conference_date as string)?.tz(timezoneWithUnderscore);

    if (date) {
      return date.format('dddd, MMMM D, YYYY');
    }

    return '';
  };

  const getCaseTime = (): string => {
    const date = moment.utc(newCase.video_conference_date as string).tz(timezoneWithUnderscore);

    if (date) {
      return date.format('h:mm A z');
    }

    return '';
  };

  return (
    <div className={styles.modalBackground} onClick={() => goToDashboard()}>
      <div
        className={`${styles.modalContainer} ${styles.scheduleLiveConsultation} ${styles.customizedScheduleLiveConsultation}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modalCloseIcon}>
          <button type="button" onClick={() => goToDashboard()} aria-label="Close modal">
            <AiFillCloseCircle />
            Close
          </button>
        </div>
        <div className={`${styles.body} ${styles.bodyWrap}`}>
          <span className={styles.bodyHeader}>
            {!isRescheduled ? 'Your appointment has been scheduled with' : 'Your appointment has been rescheduled with'}
          </span>
          <div className={styles.doctorDetails}>
            <div className={styles.imageWrapper}>
              <ProfileComponent caseRow={newCase} role="Provider" displayRole={false} />
            </div>
            <div className={styles.details}>
              <span>{getCaseDate()}</span>
              <span>{getCaseTime()}</span>
            </div>
            <span className={styles.notify}>We’ll notify you 10 minutes before your scheduled appointment.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

ScheduleLiveConsultation.defaultProps = {
  isRescheduled: false,
};

export default ScheduleLiveConsultation;
