import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ConnectOptions,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video';

import useChatWindowToggle from '../hooks/conversation-hooks/useChatWindowToggle';
import useBackgroundSettings, { BackgroundSettings } from '../hooks/video-hooks/useBackgroundSettings';
import useHandleRoomDisconnection from '../hooks/video-hooks/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from '../hooks/video-hooks/useHandleTrackPublicationFailed';
import useLocalTracks from '../hooks/video-hooks/useLocalTracks';
import useRoom from '../hooks/video-hooks/useRoom';
import useScreenShareToggle from '../hooks/video-hooks/useScreenShareToggle';
import { IParticipantSituation } from '../services/communication/types/IParticipantSituation';
import { ParticipantsDetails } from '../services/communication/types/ParticipantsDetails';
import { ErrorCallback } from '../services/communication/types/VideoRoom';
import { SelectedParticipantProvider } from './SelectedParticipantProvider';

export interface IVideoContext {
  room: Room | null;
  localTracks: LocalTrack[];
  isConnecting: boolean;
  connect: (token: string, localTrack: LocalTrack[]) => void;
  onError: ErrorCallback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  isSharing: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: (controller?: AbortController) => Promise<any>;
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: () => void;
  participantsSituation: IParticipantSituation[];
  setParticipantsSituation: (situation: IParticipantSituation[]) => void;
  participantsDetails: ParticipantsDetails[];
  setParticipantsDetails: (details: ParticipantsDetails[]) => void;
  isBackgroundSelectionOpen: boolean;
  setIsBackgroundSelectionOpen: (value: boolean) => void;
  backgroundSettings: BackgroundSettings;
  setBackgroundSettings: (settings: BackgroundSettings) => void;
  displaySSNotification: boolean;
  setDisplaySSNotification: (value: boolean) => void;
  isSideBySide: boolean;
  setIsSideBySide: (value: boolean) => void;
}

export const VideoContext = createContext<IVideoContext | null>(null);

interface VideoProviderProps {
  options: ConnectOptions;
  children: ReactNode;
}

export const VideoProvider: React.FC<VideoProviderProps> = ({ options, children }) => {
  const onError = useCallback<ErrorCallback>((error) => {
    toast.error(error.message);
  }, []);
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      toast.error(error.message);
      onError(error);
    },
    [onError],
  );

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();

  const { isConnecting, connect, room } = useRoom(onErrorCallback, options);

  const [isSharing, toggleScreenShare] = useScreenShareToggle(room, onError);
  const [isChatWindowOpen, setIsChatWindowOpen] = useChatWindowToggle(room);

  const [participantsSituation, setParticipantsSituation] = useState<IParticipantSituation[]>([]);
  const [participantsDetails, setParticipantsDetails] = useState<ParticipantsDetails[]>([]);
  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(false);
  const videoTrack = useMemo(
    () =>
      localTracks.find((track: LocalTrack) => !track.name.includes('screen') && track.kind === 'video') as
        | LocalVideoTrack
        | undefined,
    [localTracks],
  );

  const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(videoTrack, room);

  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharing,
    toggleScreenShare,
    setBackgroundSettings,
  );

  useHandleTrackPublicationFailed(room, onError);

  const [displaySSNotification, setDisplaySSNotification] = useState(false);
  const [isSideBySide, setIsSideBySide] = useState(false);
  const videoSettings = useMemo(
    () => ({
      room,
      localTracks,
      isConnecting,
      onError: onErrorCallback,
      getLocalVideoTrack,
      getLocalAudioTrack,
      connect,
      isAcquiringLocalTracks,
      removeLocalVideoTrack,
      removeLocalAudioTrack,
      isSharing,
      toggleScreenShare,
      getAudioAndVideoTracks,
      isChatWindowOpen,
      setIsChatWindowOpen,
      participantsSituation,
      setParticipantsSituation,
      participantsDetails,
      setParticipantsDetails,
      isBackgroundSelectionOpen,
      setIsBackgroundSelectionOpen,
      backgroundSettings,
      setBackgroundSettings,
      displaySSNotification,
      setDisplaySSNotification,
      isSideBySide,
      setIsSideBySide,
    }),
    [
      room,
      localTracks,
      isConnecting,
      onErrorCallback,
      getLocalVideoTrack,
      getLocalAudioTrack,
      connect,
      isAcquiringLocalTracks,
      removeLocalVideoTrack,
      removeLocalAudioTrack,
      isSharing,
      toggleScreenShare,
      getAudioAndVideoTracks,
      isChatWindowOpen,
      setIsChatWindowOpen,
      participantsSituation,
      participantsDetails,
      isBackgroundSelectionOpen,
      backgroundSettings,
      setBackgroundSettings,
      displaySSNotification,
      isSideBySide,
    ],
  );

  return (
    <VideoContext.Provider value={videoSettings}>
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
    </VideoContext.Provider>
  );
};
