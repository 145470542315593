import { resendConfirmationCode } from '@brands/services/auth/resendConfirmationCode';
import { verifyConfirmationCode } from '@brands/services/auth/verifyConfirmationCode';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { displayErrorDetails } from '../../Utils/displayError';
import styles from './styles.module.scss';

interface Props {
  username: string;
  onConfirmed: () => void;
}

type UserSubmitForm = {
  code: string;
};

const ConfirmOTP: FC<Props> = ({ username, onConfirmed }) => {
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required('Verification Code is required')
      .test('test-name', 'Enter Valid Code', (value: string | undefined) => {
        const verificationCode = /^(\+91-|\+91|0)?\d{6}$/;
        const isValidCode = verificationCode.test(value as string);
        if (!isValidCode) {
          return false;
        }
        return true;
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: UserSubmitForm): Promise<void> => {
    setLoading(true);
    try {
      await verifyConfirmationCode({
        email: username?.toLowerCase(),
        confirmation_code: data.code,
      });
      onConfirmed();
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setLoading(false);
  };

  const onResend = async (): Promise<void> => {
    try {
      await resendConfirmationCode({
        email: username?.toLowerCase(),
      });
    } finally {
      toast.success('Successfully sent you the verification code.');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <span className={`${styles.formTitle} p-b-26`}>
            <span className={styles.formTitleWelcome}>
              Welcome to dentistry.
              <span className={styles.formTitleOne}>one</span>
            </span>
          </span>
          <div className={styles.verifyEmail}>Please check your email and enter the verification code here:</div>
          <div className={styles.inputWrapper} data-validate="Valid code is: xxxxxx">
            <input
              {...register('code')}
              className={`${styles.input100} ${errors.code ? 'is-invalid' : ''}`}
              type="text"
              name="code"
              placeholder="Verification Code"
            />
            <span className={styles.focusInput100} />
            <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.code?.message}</div>
          </div>
          <div className={styles.resendContainer}>
            <p className={styles.resendDescription}>Didn't receive the code?</p>
            <a className={styles.resendBtn} onClick={onResend}>
              Resend
            </a>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonBG} />
              <button type="submit" className={styles.formBtn} disabled={loading}>
                Done
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmOTP;
