import { Option } from '@brands/Utils/selectOptions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SingleValue } from 'react-select';

export type DependentsState = {
  dependents: SingleValue<Option>[];
};

const initialState: DependentsState = {
  dependents: [],
};

const dependentsSlice = createSlice({
  name: 'dependents',
  initialState,
  reducers: {
    setDependents: (state, action: PayloadAction<SingleValue<Option>[]>) => {
      Object.assign(state, { dependents: action.payload });
    },
    addDependent: (state, action: PayloadAction<SingleValue<Option>>) => {
      state.dependents.push(action.payload);
    },
    updateDependent: (state, action: PayloadAction<SingleValue<Option>>) => {
      const index = state.dependents.findIndex((dependent) => dependent?.value === action?.payload?.value);
      Object.assign(state.dependents[index] || 0, action.payload);
    },
    removeDependent: (state, action: PayloadAction<string>) => {
      const index = state.dependents.findIndex((dependent) => dependent?.value === Number(action.payload));
      state.dependents.splice(index, 1);
    },
    resetDependents: (state) => {
      Object.assign(state, initialState);
    },
    setExternalFormsInformation: (state, action: PayloadAction<string[]>) => {
      Object.assign(state, { checkExternalForms: action.payload });
    },
    resetExternalFormsInformation: (state) => {
      Object.assign(state, { checkExternalForms: [] });
    },
  },
});

export const { setDependents, addDependent, updateDependent, removeDependent, resetDependents } =
  dependentsSlice.actions;

export default dependentsSlice.reducer;
