import Container from '@brands/Components/Container/Container';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import useWindowSize from '@brands/hooks/useWindowSize';
import React, { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

const TermsOfService: FC = () => {
  const screenSize = useWindowSize();
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <Container
        isBGEclipse={false}
        patientDashboard
        childClassName={styles.dashboard}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
          overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
        }}
      >
        <div className={styles.tOSBody}>
          <div id="content" className={styles.tOSBodySiteContent}>
            <main id="primary" className="site-main">
              <article id="post-889" className="post-889 page type-page status-publish hentry">
                <div className="entry-content">
                  <h2 style={{ marginBottom: '20px' }}>
                    <strong>Dentistry One Terms of Service</strong>
                  </h2>

                  <p>
                    Welcome to Dentistry.One.&nbsp; Please read these terms of service carefully before using the
                    services offered by Dentistry.One LLC (“D1”, “us”, “we”). These terms (the “Agreement”) set forth
                    the terms and conditions of our relationship and govern your (“you”, “yours”, “Patients”,
                    “Providers”) use of the Dentisry.One platform (the “Site”) and the services, features, content, and
                    applications offered by D1, which among other things provide potential patients access to a network
                    of dental practitioners, who will provide dental and related services by and through an e-commerce
                    marketplace platform (collectively, the “Services”). By accessing and/or using Dentistry.One. or any
                    “Dentistry One” software services, programs or applications, you agree to be bound by the terms of
                    this Agreement, which also incorporate D1’s&nbsp;
                    <a href="https://dentistry.one/privacy-policy/">Privacy Policy</a>.
                  </p>

                  <p>
                    <a />
                    1. The Site and/or Services (including “Dentistry One”) are provided through the Site, as well as
                    various D1 mobile applications, desktop applications.&nbsp; These various applications provide a
                    telehealth/telemedicine solution that allows participating dental health professionals and dental
                    health care staff (“Dental Providers” or “Providers”) to make certain telehealth related information
                    available to you and/or facilitate your access to professional dental services, communicate with
                    their clients and patients (“Patients”) and to electronically provide certain dental health care
                    services online.&nbsp; To the extent the Agreement refers to “you” or “your”, such reference shall
                    be deemed a reference to Patients and Providers.
                  </p>

                  <p>
                    2. D1 is a dental service provider and D1 does render dental health care/services through the Site
                    and/or Services. Also, you understand that D1 is not in the business of health insurance and is not
                    a health insurance company or provider and D1 is not a prescription fulfillment warehouse though
                    providers can prescribe needed non-narcotic medications. Furthermore, Providers are paid by Patients
                    (or their insurance providers) for their services and the Providers are contractors of D1.&nbsp;
                    While the Site and/or Services may provide access to certain general medical information and also
                    allow Patients to communicate with Providers, Patients recognize that the Providers are limited to
                    what can be addressed using a telehealth medium. D1 is independent from Providers who provide dental
                    and/or medical services and is not responsible for such Providers’ acts, omissions or for any
                    content of the communications made by them.
                  </p>

                  <p>
                    3. While accessing and/or using the Site and/or Services, we may obtain information about you or you
                    may be required to provide certain information to us. All uses of your information will be treated
                    in accordance with our <a href="https://dentistry.one/privacy-policy/">Privacy Policy</a>, which
                    forms an integral part of this Agreement. If you use the Site and/or Services, and/or if you
                    register for an account, you are accepting our Privacy Policy, as may be amended from time to time.
                    If you do not agree to have your information used in any of the ways described in the Privacy
                    Policy, you must discontinue use of the Site and/or Services.
                  </p>

                  <p>
                    4. The Services do provide oral health care advice but do not provide medical advice and do not
                    create a medical provider/patient relationship between you and D1 or with any other party, but for
                    any Providers engaged by you.
                  </p>

                  <p>
                    <strong>
                      USE OF SERVICES IS NOT APPROPRIATE FOR MEDICAL EMERGENCIES. IF YOU THINK YOU HAVE A MEDICAL
                      EMERGENCY, OR IF AT ANY TIME YOU ARE CONCERNED ABOUT YOUR CARE OR TREATMENT, CALL 911 OR GO TO THE
                      NEAREST OPEN CLINIC OR EMERGENCY ROOM.
                    </strong>
                  </p>

                  <p>
                    D1 advises seeking the advice of a dentist or other qualified dental healthcare provider with any
                    questions regarding personal dental health or medical conditions. Never disregard, avoid, or delay
                    in obtaining dental/medical advice from a dentist or other qualified dental healthcare provider
                    because of information obtained from D1 on the Site and/or Services. If you have or suspect that you
                    have a dental health problem or condition that was not addressed by a D1 consultation, please
                    contact a qualified dental healthcare professional immediately.&nbsp;
                  </p>

                  <p>
                    <strong>
                      <em>The Relationship between Patients and Providers</em>
                    </strong>
                  </p>

                  <p>
                    1. Any and all persons who use the Site and/or Services are bound by this Agreement and you
                    understand that even if you are a Provider, this Agreement applies to you as well. In order to
                    participate in the Site and/or Service as a Provider, you will be obligated to review and accept the
                    Dentistry.One Provider Agreement (the “Provider Agreement”).&nbsp; To the extent there are any
                    conflicts between terms and conditions of this Agreement specifically as they pertain to any
                    Providers, and the Provider Agreement, the terms and conditions of the Provider Agreement shall
                    control.
                  </p>

                  <p>
                    2. To the extent dental advice is provided to a Patient by a Provider through the Site and/or
                    Services, such dental advice is based on your personal dental health data as provided by a Patient
                    to the Provider and the local standards of care. The dental advice provided by any Provider is not
                    under the control of D1.
                  </p>

                  <p>
                    3. If you are a Provider, you accept responsibility for your Patients as well as yourself in the use
                    of the Site and/or Services. Providers are also responsible for the quality of the services they
                    provide during your use of the Site and/or Services and for complying with all applicable laws in
                    connection with their use of the Site and/or Services.&nbsp; Without limitation, Providers are
                    responsible for obtaining any and all Federal, State and local licenses and certifications which may
                    be required to practice any dental profession when using the Site and/or Services and maintaining
                    malpractice and liability insurance in compliance with regulatory and local requirements. Providers
                    are also responsible for obtaining Patient consent if required by law and complying with any and all
                    privacy laws applicable to the use of the Site and/or Services with their Patients, including the
                    Health Insurance Portability and Accountability Act of 1996 (“HIPAA”).&nbsp; D1 has established
                    reasonable safeguards and procedures to protect the security of Patient information, but Providers
                    must also take steps to protect their privacy and confidentiality that of their Patients.&nbsp;
                    Providers may also be asked to enter a HIPAA business associate agreement with D1.
                  </p>

                  <p>
                    4. If you are a Patient, you accept responsibility for yourself in the use of the Site and/or
                    Services.&nbsp; You acknowledge that your relationship for dental health care services is with your
                    Provider, and your obtaining services from the Provider is solely at your own risk and you assume
                    full responsibility for all risk associated therewith, to the extent permitted by law.&nbsp; You
                    understand that Providers contractors with D1 and that any medical advice obtained through the Site
                    and/or Services are not provided by D1, but by your Provider. Under no circumstances will D1 be
                    liable for any loss or damage caused by your reliance on any information, opinion, advice, or
                    statement available on or through the Site and/or Services. By using the Service, you agree to not
                    hold D1 liable in any way for any malpractice or substandard treatment the Provider may render.
                  </p>

                  <p>
                    5. You are solely responsible for all Submissions from your account. “Submissions” means anything
                    that a user transmits to the Site and/or Services or to another in the course of using the Site
                    and/or Services. You shall remain at all times solely responsible for the full functionality,
                    accuracy, reliability, integrity, quality or validity of any statement, data, facts, information, or
                    any other detail contained in the Submissions.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>Conditions of Telehealth/TeleMedicine</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. You understand that D1 provides telehealth/telemedicine services, by which the primary means of
                    contact between Patient and Provider is audio, photography and video, and the Provider and Patient
                    are physically in different locations. During your telehealth/telemedicine session, information
                    about your health and/or personal health information may be disclosed. You understand that while you
                    may receive the intended benefit of engaging in telehealth with the Provider, results are not
                    guaranteed. While telehealth/telemedicine intends to mimic the relationship between in-person
                    counseling, it is not the same, and therefore you should evaluate whether telehealth/telemedicine is
                    right for you prior to engaging with a Provider.&nbsp;
                  </p>

                  <p>
                    <strong>
                      IT IS THE OBLIGATION OF THE PROVIDERS TO USE AND DISCLOSE ANY OF YOUR INFORMATION IN ACCORDANCE
                      WITH APPLICABLE STATE AND FEDERAL LAWS, INCLUDING, WITHOUT LIMITATION, OBTAINING ANY CONSENTS OR
                      AUTHORIZATIONS THAT MAY BE REQUIRED FOR YOUR INFORMATION TO BE SHARED WITH THIRD PARTIES.
                    </strong>
                  </p>

                  <p>
                    2. Generally, a licensed dental professional practitioner, provider, or office who provides services
                    via telehealth/telemedicine (collectively, the “Provider”) is subject to the requirements and
                    definitions set forth in the laws, rules, or regulations adopted by the state and applicable medical
                    and/or dentistry boards (collectively, the “Laws”). “Telehealth/telemedicine” is the mode of
                    delivering health care services and public health via information and communication technologies to
                    facilitate the diagnosis, consultation, treatment, education, care management and self-management of
                    a patient’s health care while the patient is at the originating site and the health care provider is
                    at a distant site. Telehealth/telemedicine includes synchronous interactions and asynchronous
                    store-and-forward transfers. “Asynchronous store and forward” means the transmission of a patient’s
                    information from an originating site to the health care provider at a distant site without the
                    presence of the patient. Generally speaking, most states require that prior to the delivery of
                    health care via Telehealth/telemedicine, a health care provider at the originating site must inform
                    the patient that telehealth/telemedicine may be used and obtain verbal and/or written consent from
                    the patient for this use (collectively, “Consent Instruments”). Generally, under the Laws of a
                    particular state, it is a sanctionable offense for the applicable health care provider not to
                    properly acquire such consent.
                  </p>

                  <p>
                    3. You understand that the D1 platform is not the Provider and rather is a provider of software and
                    technology services to D1 Providers.&nbsp; As a result, any rights, duties, and obligations arising
                    under any applicable Consent Instruments are strictly between yourself and the D1 Providers,
                    including any Consent Instruments you may electronically and/or digitally sign or execute using any
                    Services, which establishes a legal relationship between you and the applicable Providers.&nbsp;
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>CONTENT AND SERVICES ACCESSED THROUGH THE SERVICES</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    You may choose to allow a third-party service provider (such as a Personal Health Record provider,
                    health care providers, and any other entity chosen by You) to retrieve, provide, modify or otherwise
                    use health and other information in Your account or otherwise share Your information with the
                    service provider. Once You permit a specific third-party service provider to access Your account,
                    such service provider may continue to access Your account until You affirmatively disable access. It
                    is Your sole responsibility to review and approve each such third-party service before sharing Your
                    information through or otherwise accessing it. USE OF THESE SERVICES AND RELIANCE ON THIS CONTENT IS
                    SOLELY AT YOUR OWN RISK. D1 MAY NOT BE HELD LIABLE FOR ANY DAMAGES ARISING OUT OF OR RELATED TO YOUR
                    USE OF ANY THIRD-PARTY SERVICE OR CONTENT.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>SMS TEXT MESSAGING AND EMAIL MESSAGING</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. The Services MAY enable communication between D1 and you by means of SMS text messages or
                    standard messaging to and from your mobile device. D1 will only use information you provide via SMS
                    message as permitted by D1’s privacy policy and applicable law.
                  </p>

                  <p>
                    2. D1’s SMS messaging is supported by all US mobile carriers under Twilio. If Your mobile operator
                    is not supported, You will not receive a reply to Your messages. Pre-paid cellphone users may not be
                    able to participate – check with Your mobile operator. Your mobile operator may charge standard and
                    other text messaging fees for text messages sent and received. Neither D1 nor the mobile operators
                    listed above shall be liable for delayed or undelivered messages.
                  </p>

                  <p>
                    3. Message frequency is recurring/ongoing based on Your preferences as indicated under
                    “Communication Preferences” in Your account.&nbsp;
                    <strong>
                      Text STOP at any time to end a conversation using the SMS text service. You may continue to
                      receive other SMS messages in which you are enrolled.
                    </strong>
                  </p>

                  <p>
                    4. SMS text messaging is not required as a condition of registering for or using the Services. If
                    you do receive SMS text messages, you acknowledge that such messages will be sent to the phone
                    number you provide to D1. Such messages may include protected health information (PHI) based on your
                    account preferences, and whoever has access to devices connected to that number will also be able to
                    see this information. Additionally, you acknowledge that emails and SMS text messages may not be a
                    secure method of communication and that they are sent through an unencrypted method of communication
                    and that information contained in an unencrypted message is at risk of being intercepted and read
                    by, or disclosed to, unauthorized third parties, such as your mobile operator’s SMS text messaging
                    system.
                  </p>

                  <p>5. Message and data rates may apply.</p>

                  <p>
                    6. The Services also offers access to messages via email. You acknowledge that communications will
                    be sent to the email address you provide to D1. Such alerts may include PHI based on your account
                    preferences, and whoever has access to the email address will also be able to see this information.
                    Additionally, you acknowledge that email may not be a secure method of communication and that they
                    may be sent through an unencrypted method of communication and that information contained in an
                    unencrypted e-mail is at risk of being intercepted and read by, or disclosed to, unauthorized third
                    parties, such as your email service provider.
                  </p>

                  <p>
                    7. By enrolling to receive SMS messages and/or email communications, you agree to this Agreement,
                    which becomes effective upon your enrollment.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>You are responsible for reviewing changes to this Agreement</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. This Agreement applies to all users of the Site and/or Services, including Patients and
                    Providers.
                  </p>

                  <p>
                    2. This Agreement supersedes all prior or contemporaneous agreements, representations, warranties
                    and understandings with respect to the Site and/or Services, the content, products or services
                    provided by or through the Site and/or Services, and the subject matter of this Agreement, EXCEPT
                    THAT to the extent there are any conflicts between terms and conditions of this Agreement
                    specifically as they pertain to any Providers, and the Provider Agreement, the terms and conditions
                    of the Provider Agreement shall control.
                  </p>

                  <p>3. D1 may make changes to this Agreement from time to time without specifically notifying you.</p>

                  <p>
                    4. D1 will post the latest Agreement on the Site or within its various applications facilitating the
                    Services, but it is up to you to review it before using the Site and/or Services.
                  </p>

                  <p>
                    5. If you continue to use the Site and/or Services after any of these changes, your continued use
                    will mean that you have accepted any changes to the Agreement.
                  </p>

                  <p>
                    6. In addition, some services offered through the Site and/or Services may be subject to additional
                    terms and conditions specified by D1 from time to time and your use of such services is subject to
                    those additional terms and conditions, which are incorporated into this Agreement by this reference.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>D1 may edit or modify anything on the Site and/or Services without notice</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    D1 is committed to delivering a positive user experience and you understand that D1 reserves the
                    right (but without undertaking any duty) to edit, monitor, review, delete, modify, or move any
                    content or material provided or placed on or though the Site and/or Services in its sole discretion,
                    without notice and without undertaking any duty to do so.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>You are responsible for accepting updated versions of the Site and/or Services</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. If D1 provides updated versions of the Site and/or Services and you do not accept these updated
                    versions, you may not enjoy the most recent content, feature sets, and materials.
                  </p>

                  <p>
                    2. If you do not accept updated versions of the Site and/or Services, D1 shall not bear any
                    responsibility or liability for your decision.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>What Laws and Rules You Are Responsible for Following</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. By using the Site and/or Services as a Patient, you represent, acknowledge and agree that you are
                    at least 18 years of age, or if you are under 18 years of age but are at least 13 years old (a
                    “Minor”), that you are using the Site and/or Services with the consent of your parent or legal
                    guardian and that you have received your parent’s or legal guardian’s permission to use the Site
                    and/or Services and agree to this Agreement.&nbsp; If you are a parent or legal guardian of a Minor,
                    you hereby agree to bind the Minor to this Agreement and to fully indemnify and hold harmless D1 if
                    the Minor breaches any of this Agreement.&nbsp; If you are not at least 13 years old, you may not
                    use the Site and/or Services as a Patient at any time or in any manner or submit any information to
                    the D1 or the Site.&nbsp; If you are a Provider you must be eligible to use the Site and/or Services
                    pursuant to the terms and conditions contained in the Provider Agreement.&nbsp;
                  </p>

                  <p>
                    2. You promise not to use the Site and/or Services for any purpose that is prohibited by this
                    Agreement.
                  </p>

                  <p>4. You are responsible for all of your activity in connection with the Site and/or Services.</p>

                  <p>
                    3. You understand that you will be fully responsible for all activities that occur under your
                    account, user name and/or password.
                  </p>

                  <p>
                    4. You agree not to allow someone who is not you to use your account, username or password at any
                    time.
                  </p>

                  <p>
                    5. You agree to immediately notify Company of any unauthorized use of your password or your user
                    name.
                  </p>

                  <p>
                    6. We strongly encourage you to change your password at least once a month and you understand that
                    D1 will not be liable for any loss that you incur as a result of someone else using your account or
                    your password.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>
                          It is your responsibility to make sure this Agreement and your use of the Site and/or Services
                          complies with all laws applicable to you
                        </em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. You understand D1 may, in its sole discretion, refuse to offer the Site and/or Services to any
                    person or entity and change its eligibility criteria at any time and that D1 reserves the right
                    (without undertaking any duty) to use “geo-filtering” or other technologies to block access to
                    certain jurisdictions, in its sole discretion, without notice.
                  </p>

                  <p>
                    2. You are solely responsible for ensuring that this Agreement complies with all laws, rules and
                    regulations applicable to you.
                  </p>

                  <p>
                    3. You understand that your right to access the Site and/or Services will be revoked where this
                    Agreement or use of the Site and/or Services is prohibited and, if that is the case, you agree not
                    to use or access the Site and/or Services in any way.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>D1 may terminate your use of the Site and/or Services without reason or notice to you</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    While D1 respects its users, you agree that D1 may terminate your membership or use of the Site
                    and/or Services and prohibit you from accessing the Site and/or Services, for any reason, or no
                    reason, and with or without notice.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>You agree to comply with D1’s conduct policies when using the Site and/or Services</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    We do our best to keep the Site and/or Services safe and operational, but we cannot guarantee it. We
                    need your help to do that, which includes the following commitments:
                  </p>

                  <p>
                    1. You will not modify, adapt, appropriate, reproduce, distribute, translate, create derivative
                    works or adaptations of, publicly display, republish, repurpose, sell, trade, or in any way exploit
                    the Site and/or Services except as expressly authorized by D1;
                  </p>

                  <p>
                    2. You will not take any action that imposes or may impose (as determined by D1 in its sole
                    discretion) an unreasonable or disproportionately large load on D1’s (or its third party providers’)
                    infrastructure;
                  </p>

                  <p>
                    3. You will not decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive
                    any source code or underlying ideas or algorithms of any part of the Services, except to the limited
                    extent applicable laws specifically prohibit such restriction;
                  </p>

                  <p>
                    4. You will not interfere or attempt to interfere with the proper working of the Site and/or
                    Services or any activities conducted on the Site and/or Services;
                  </p>

                  <p>
                    5. You will not bypass any measures D1 may use to prevent or restrict access to the Site and/or
                    Services (or other accounts, computer systems or networks connected to the Site and/or Services);
                  </p>

                  <p>6. You will not run any form of auto-responder or “spam” on the Site and/or Services;</p>

                  <p>
                    7. You will not use manual or automated software, devices, or other processes to “crawl” or “spider”
                    any page of the Site and/or Services;
                  </p>

                  <p>8. You will not harvest or scrape any content or materials from the Site and/or Services;</p>

                  <p>
                    9. You will not copy, rent, lease, distribute, or otherwise transfer any of the rights that you
                    receive hereunder;
                  </p>

                  <p>10. You will not threaten, intimidate or harass another user, Patient, or any Provider;</p>

                  <p>
                    11. You will not solicit passwords or personally identifying information (this includes, but is not
                    limited to, someone else’s name, birthdate, home address, IP address, social security number, or
                    credit card number) for commercial or unlawful purposes;
                  </p>

                  <p>
                    12. You will not upload, post, transmit, share, store or otherwise make available any material that
                    contains software viruses or any other computer code, files or programs designed to interrupt,
                    destroy or limit the functionality of any computer software or hardware or telecommunications
                    equipment;
                  </p>

                  <p>
                    13. You will not upload, post, transmit, share, store or otherwise make available any content (such
                    as a picture on a profile page) other than those of a personal nature that: (i) are of you (ii) are
                    taken by you or your friends, or (iii) are original content created by you;
                  </p>

                  <p>
                    14. You will not knowingly transmit false or misleading information through the Site and/or
                    Services.
                  </p>

                  <p>
                    15. You will not use the Site and/or Services in any manner or transmit any Submission that:
                    infringes (or results in the infringement of) D1’s or any third party’s intellectual property or
                    other rights; is (or you reasonably believe to be) illegal, fraudulent, or unauthorized, or in
                    furtherance of any illegal, counterfeiting, fraudulent, pirating, unauthorized, or violent activity,
                    or that involves (or you reasonably believe to involve) any stolen, illegal, counterfeit,
                    fraudulent, pirated, or unauthorized material; does not comply with all applicable laws, rules, or
                    regulations, including obtaining all necessary permits, licenses, registrations, etc. In the case of
                    any proposed or actual transaction, “applicable” refers to both your own location and to location(s)
                    of all other parties to the transaction; or would cause D1 to be in violation of any law or
                    regulation, or to infringe any right of any third party.
                  </p>

                  <p>
                    16. You will not publish falsehoods or misrepresentations that may damage D1 or any third party.
                  </p>

                  <p>
                    17. You will not transmit any Submission and will not use the Site and/or Services to post, store,
                    transmit, offer, or solicit anything that contains the following, or that you know contains links to
                    the following or to locations that in turn contain links to the following: material that we
                    determine to be offensive (including material promoting or glorifying hate, violence, bigotry, or
                    any entity (past or present) principally dedicated to such causes, or items associated with such an
                    entity); material that is racially or ethnically insensitive, material that is defamatory, harassing
                    or threatening; pornography (including any obscene material, and anything depicting children in
                    sexually suggestive situations whether or not depicting actual children) or may be harmful to a
                    minor; any virus, worm, Trojan horse, or other harmful or disruptive component; or anything that
                    encourages conduct that would be considered a criminal offense, give rise to civil liability,
                    violate any law or regulation or is otherwise inappropriate.
                  </p>

                  <p>
                    18. You will not use the Site and/or Services to post, store, or transmit advertising, promotional
                    material, or solicitations of goods or services, except in areas of the Site and/or Services
                    designated for the purpose.
                  </p>

                  <p>19. You will not otherwise take any action in violation of D1’s guidelines and policies.</p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>
                          D1 is not responsible for what happens outside of the Site and/or Services, including third
                          party websites that the Site and/or Services may link to or advertise on behalf of
                        </em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. D1 or its business partners may present advertisements or promotional materials via the Site
                    and/or Services.
                  </p>

                  <p>
                    2. Your dealings with, or participation in promotions of any third-party advertisers via the Site
                    and/or Service are solely between you and such third party and your participation is subject to the
                    terms and conditions associated with that advertisement or promotion.
                  </p>

                  <p>
                    3. You agree that D1 is not responsible or liable for any loss or damage of any sort incurred as the
                    result of any such dealings or as the result of the presence of such third parties via the Services.
                  </p>

                  <p>
                    4. You understand the Site and/or Services may contain (or you may receive from D1, third parties or
                    users) links to other web sites (“Third Party Sites”) or content posted, owned or originating from
                    third parties such as, by way of example only, pictures, designs, photographs, graphics, text,
                    sound, recipes, video, information, software, applications and any other content (“Third Party
                    Content”).
                  </p>

                  <p>
                    5. You understand and agree that D1 is not responsible for, and does not control, Third Party Sites
                    and Third Party Content. You also understand and agree that D1 is not responsible for the
                    availability of such external sites or resources, and does not endorse and is not responsible or
                    liable for any content, advertising, products or other materials on or available from such Third
                    Party Sites and Third Party Content.
                  </p>

                  <p>
                    6. You acknowledge and agree that D1 shall not be responsible or liable, directly or indirectly, for
                    any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance
                    upon, any such content, goods or services available on or through any such Third Party Site or Third
                    Party Content.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>Who Owns What and How You Can Use It</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. The content, organization, graphics, design, compilation, magnetic translation, digital
                    conversion and other matters related to the Site and/or Services (“Copyright Content”) are the
                    property of D1 or may be, to a certain extent, the property of its content suppliers, including but
                    not limited to Providers, and are protected under applicable copyright law, trademark law and other
                    proprietary rights.
                  </p>

                  <p>
                    2. You may not copy, redistribute, use or publish any part of the Site and/or Services, except as
                    allowed by this Agreement.
                  </p>

                  <p>
                    3. You do not acquire ownership rights to any content, document or other materials viewed through
                    the Site and/or Services.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>
                          You may not use trademarks appearing on the Site and/or Services in an infringing manner
                        </em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. You agree that MOUTHWATCH,&nbsp;MOUTHWATCH.COM, DENTISTRY ONE, DENTISTRY ONE.COM and D1 graphics,
                    logos, page headers, button icons, scripts, and service names are trademarks, registered trademarks
                    or trade dress of D1 or its affiliates (“Trademark Content”).
                  </p>

                  <p>
                    2. D1 trademarks and trade dress may not be used in connection with any product or service that is
                    not D1’s, in any manner that is likely to cause confusion among customers, or in any manner that
                    disparages or discredits D1.
                  </p>

                  <p>
                    3. All other trademarks not owned by D1 or its affiliates that may appear on this Site and/or
                    Services are the property of their respective owners, who may or may not be affiliated with,
                    connected to, or sponsored by D1 or its affiliates.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>
                          Unless you are a Provider and have registered with D1 as a Provider and Accepted this
                          Agreement And Conditions of the Provider Agreement, You may use the D1 Site and/or Services
                          for limited, noncommercial purposes
                        </em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. Unless you are a Provider and have registered with D1 as a Provider and accepted D1’s Provider
                    Terms and Conditions, D1 grants you a limited license to access and make personal use of the Site
                    and/or Services.
                  </p>

                  <p>
                    2. D1 does not grant you the right to download (other than page caching) or modify the Site and/or
                    Services, or any portion of the Site and/or Services.
                  </p>

                  <p>
                    3. You understand D1 does not grant you the right to resell or make commercial use (except as
                    provided herein or in the applicable Provider Terms and Conditions) of the Site and/or Services or
                    their contents; make any derivative use of the Site and/or Services or their contents; any
                    downloading or copying of account information for the benefit of a third party, or merchant; or any
                    use of data mining, robots, or similar data gathering and extraction tools.
                  </p>

                  <p>
                    4. You understand the Site and/or Services or any portion of Site and/or Services may not be
                    reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial
                    purpose.
                  </p>

                  <p>
                    5. You may not frame or utilize framing techniques to enclose any trademark, logo, or other
                    proprietary information of D1 and our affiliates without express written consent.
                  </p>

                  <p>
                    6. You may not use any meta tags or any other “hidden text” utilizing D1’s name or trademarks
                    without the express written consent of D1. Any unauthorized use terminates the permission or license
                    granted by D1.
                  </p>

                  <p>
                    7. You may not engage in the copying, reproduction, publication, rearrangement, redistribution,
                    modification, revision, alteration, or reverse engineering, of the Services.
                  </p>

                  <p>
                    8. You are granted a limited, revocable, and nonexclusive right to create a hyperlink, or at the
                    Company’s sole discretion other items such as widgets, buttons, or other web page elements to the
                    home page of D1 so long as the link does not portray D1, its affiliates, or their products or
                    services in a false, misleading, derogatory, or otherwise offensive matter.
                  </p>

                  <p>
                    9. You may not use any D1 logo or other proprietary graphic or trademark as part of the link without
                    express written permission.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>You have the necessary rights to share content and materials should you choose to</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. You understand that you may be invited to participate in blogs, online forums, promotions,
                    surveys and other such items and that, accordingly, we may provide you with the opportunity, to
                    create, submit, post, transmit or distribute content and materials to us via the Site, such as text,
                    drawings, icons, photos, etc. (collectively, “User Content”).
                  </p>

                  <p>
                    2. You warrant that either: (a) your User Content is wholly original to you and you exclusively own
                    the rights to your User Content, including the right to grant all of the rights and licenses in this
                    Agreement; or (b) all parties whose materials are included in your User Content, or who contributed
                    in any way, are depicted in, or have any rights to your User Content, have granted you permission to
                    submit and license your User Content to D1 as set forth herein, with full knowledge that D1 may
                    exploit it in any manner whatsoever. You make such warranties without D1 incurring any third party
                    obligations or liability arising out of its exercise of the rights thereto granted herein by you.
                  </p>

                  <p>
                    3. You grant to D1 the non-exclusive, unrestricted, unconditional, unlimited, worldwide,
                    irrevocable, perpetual and royalty-free right and license to host, use, copy, distribute, reproduce,
                    disclose, sell, re-sell, sub-license, display, perform, transmit, publish, broadcast, modify, make
                    derivative works from, re-title, reformat, translate, archive, store, cache or otherwise exploit in
                    any manner whatsoever, all or any portion of your User Content, for any purpose whatsoever, in any
                    and all formats; on or through any and all media, software, formula or medium now known or hereafter
                    known; and with any technology or devices now known or hereafter developed and to advertise, market
                    and promote same.
                  </p>

                  <p>
                    4. You further agree that D1 is free to use any ideas, concepts, know-how or techniques contained in
                    any User Content you send to the Site or D1, for any purposes whatsoever, including, without
                    limitation, developing, producing, marketing and otherwise exploiting products and/or services using
                    such User Content; and without remuneration of any kind.
                  </p>

                  <p>
                    5. You further perpetually and irrevocably grant D1 the unconditional right to use and exploit your
                    name, persona and likeness included in any User Content.
                  </p>

                  <p>
                    6. You also grant to D1 the right to sub-license and authorize others to exercise any of the rights
                    granted to D1.
                  </p>

                  <p>
                    7. You authorize D1 to publish your User Content in a searchable format that may be accessed by
                    users of the Site and the Internet, and you waive any rights of attribution and/or any so-called
                    moral rights you may have in any User Content you submit, even if such User Content is altered or
                    changed in a manner not agreeable to you.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>Feedback</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    If you send or transmit any communications, comments, questions, suggestions, or related materials
                    to D1, whether by letter, email, telephone, or otherwise (collectively, “Feedback”), suggesting or
                    recommending changes to the Site and/or Services, including, without limitation, new features or
                    functionality relating thereto, all such Feedback is, and will be treated as, non-confidential and
                    non-proprietary.&nbsp; You hereby assign all right, title, and interest in, and D1 is free to use,
                    without any attribution or compensation to you, any ideas, know-how, concepts, techniques, or other
                    intellectual property and proprietary rights contained in the Feedback, whether or not patentable,
                    for any purpose whatsoever, including but not limited to, developing, manufacturing, having
                    manufactured, licensing, marketing, and selling, directly or indirectly, products and services using
                    such Feedback.&nbsp; You understand and agree that D1 is not obligated to use, display, reproduce,
                    or distribute any such ideas, know-how, concepts, or techniques contained in the Feedback, and you
                    have no right to compel such use, display, reproduction, or distribution.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>Copyright Policy</em>
                      </strong>
                    </li>
                  </ol>

                  <p>1. D1 takes the intellectual property of others seriously.</p>

                  <p>
                    2. You understand that D1 at its sole discretion, may terminate the account or access of users who
                    infringe the intellectual property rights of others.
                  </p>

                  <p>
                    3. If you believe that your work has been copied in a manner that results in copyright infringement,
                    please contact D1’s Copyright Agent. Our designated agent for notice of alleged copyright
                    infringement on the Site and/or Services is:
                  </p>

                  <p>
                    D1 – Copyright Department
                    <br />
                    [company DMCA agent]
                  </p>

                  <p>By Email:&nbsp;[email address]</p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>Additional Terms for Mobile Applications</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. D1 may make available mobile applications to access the Site via a mobile device (“Mobile
                    Applications”).&nbsp; To use the Mobile Application you must have a mobile device that is compatible
                    with the mobile service.&nbsp; D1 does not warrant that the Mobile Application will be compatible
                    with your mobile device.&nbsp; D1 hereby grants to you a non-exclusive, non-transferable, revocable
                    license to use an object code copy of the Mobile Application for one registered account on one
                    mobile device owned or leased solely by you, for your personal use.&nbsp;
                  </p>

                  <p>
                    2. You may not: (i) modify, disassemble, decompile or reverse engineer the Mobile Application,
                    except to the extent that such restriction is expressly prohibited by law; (ii) rent, lease, loan,
                    resell, sublicense, distribute or otherwise transfer the Mobile Application to any third-party or
                    use the Mobile Application to provide time sharing or similar services for any third-party; (iii)
                    make any copies of the Mobile Application; (iv) remove, circumvent, disable, damage or otherwise
                    interfere with security-related features of the Mobile Application, features that prevent or
                    restrict use or copying of any content accessible through the Mobile Application, or features that
                    enforce limitations on use of the Mobile Application; or (v) delete the copyright and other
                    proprietary rights notices on the Mobile Application.&nbsp; You acknowledge that D1 may from time to
                    time issue upgraded versions of the Mobile Application, and may automatically electronically upgrade
                    the version of the Mobile Application that you are using on your mobile device.&nbsp; You consent to
                    such automatic upgrading on your mobile device, and agree that this Agreement will apply to all such
                    upgrades.&nbsp; The foregoing license grant is not a sale of the Mobile Application or any copy
                    thereof, and D1 and its third-party licensors or suppliers retain all right, title, and interest in
                    and to the Mobile Application (and any copy of the Mobile Application).&nbsp; Standard carrier data
                    charges may apply to your use of the Mobile Application.
                  </p>

                  <p>
                    3. The following additional terms and conditions apply with respect to any Mobile Application that
                    D1 provides to you designed for use on an Apple iOS-powered mobile device (an “iOS App”):
                  </p>

                  <ul data-block-type="core">
                    <li>
                      You acknowledge that this Agreement is between you and D1 only, and not with Apple, Inc.
                      (“Apple”).
                    </li>
                    <li>Your use of D1’s iOS App must comply with Apple’s then-current App Store Terms of Service.</li>
                    <li>
                      D1, and not Apple, are solely responsible for our iOS App and the Services and Content available
                      thereon. You acknowledge that Apple has no obligation to provide maintenance and support services
                      with respect to our iOS App.&nbsp; To the maximum extent permitted by applicable law, Apple will
                      have no warranty obligation whatsoever with respect to our iOS App.
                    </li>
                    <li>
                      You agree that D1, and not Apple, are responsible for addressing any claims by you or any
                      third-party relating to our iOS App or your possession and/or use of our iOS App, including, but
                      not limited to: (i) product liability claims; (ii) any claim that the iOS App fails to conform to
                      any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection
                      or similar legislation, and all such claims are governed solely by this Agreement and any law
                      applicable to us as provider of the iOS App.
                    </li>
                    <li>
                      You agree that D1, and not Apple, shall be responsible, to the extent required by these Terms, for
                      the investigation, defense, settlement and discharge of any third-party intellectual property
                      infringement claim related to our iOS App or your possession and use of our iOS App.
                    </li>
                    <li>
                      You represent and warrant that (i) you are not located in a country that is subject to a U.S.
                      Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting”
                      country; and (ii) You are not listed on any U.S. Government list of prohibited or restricted
                      parties.
                    </li>
                    <li>
                      You agree to comply with all applicable third-party terms of agreement when using our iOS App
                      (e.g., you must not be in violation of your wireless data service terms of agreement when using
                      the iOS App).
                    </li>
                    <li>
                      The parties agree that Apple and Apple’s subsidiaries are third-party beneficiaries to this
                      Agreement as they relate to your license of D1’s iOS App. Upon your acceptance of these Terms,
                      Apple will have the right (and will be deemed to have accepted the right) to enforce this
                      Agreement against you as they relate to your license of the iOS App as a third-party beneficiary
                      thereof.
                    </li>
                  </ul>

                  <p>
                    4. The following additional terms and conditions apply with respect to any Mobile Application that
                    D1 provides to you designed for use on an Android-powered mobile device (an “Android App”):
                  </p>

                  <ul data-block-type="core">
                    <li>
                      You acknowledge that this Agreement is between you and D1 only, and not with Google, Inc.
                      (“Google”).
                    </li>
                    <li>
                      Your use of D1’s Android App must comply with Google’s then-current Android Market Terms of
                      Service.
                    </li>
                    <li>
                      Google is only a provider of the Android Market where you obtained the Android App. D1, and not
                      Google, are solely responsible for D1’s Android App and the Services and Content available
                      thereon.&nbsp; Google has no obligation or liability to you with respect to D1’s Android App or
                      these Terms.
                    </li>
                    <li>
                      You acknowledge and agree that Google is a third-party beneficiary to this Agreement as they
                      relate to D1’s Android App.
                    </li>
                  </ul>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>Legal Conditions and D1’s liability is Limited</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. D1 is not responsible for any Third Party Sites, Third Party Content, or any other content posted
                    on the Site and/or Services, whether caused by users of the Site and/or Services, D1, third parties
                    or by any of the equipment or programming associated with or utilized in the Site and/or Services.
                  </p>

                  <p>
                    2. D1 is not responsible for the conduct, whether online or offline, of any user of the Site and/or
                    Services or any user, Patient, or Provider.
                  </p>

                  <p>
                    3. D1 assumes no responsibility for any error, omission, interruption, deletion, defect, delay in
                    operation or transmission, communications line failure, theft or destruction or unauthorized access
                    to, or alteration of Patient communications or Provider communications.
                  </p>

                  <p>
                    4. D1 is not responsible for any problems or technical malfunction of any telephone network or
                    lines, cellular data provider or network, computer online systems, servers or providers, computer
                    equipment, software, failure of email or players on account of technical problems or traffic
                    congestion on the Site and/or Services, including injury or damage to users or to any other person’s
                    computer, and/or mobile device.
                  </p>

                  <p>
                    5. Neither D1 nor any of its affiliates, advertisers, promoters or distribution partners shall be
                    responsible for any loss or damage, including personal injury or death, resulting from anyone’s use
                    of the Site and/or Services, any content posted on the Site and/or Services or transmitted to users,
                    or any interactions between users of the Site and/or Services and between users of the Site and/or
                    Services and Providers, whether online or offline.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>General Disclaimer and Limitation of Liability</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    WE TRY TO KEEP THE SITE AND/OR SERVICES UP, BUG-FREE&nbsp;AND SAFE, BUT&nbsp;YOU USE THEM AT YOUR
                    OWN RISK. THE&nbsp;INFORMATION FROM OR THROUGH THE SITE AND/OR SERVICES IS PROVIDED&nbsp;“AS IS,”
                    “AS AVAILABLE,”&nbsp;AND ALL WARRANTIES, EXPRESS&nbsp;OR IMPLIED, ARE&nbsp;DISCLAIMED.
                    THE&nbsp;INFORMATION, SITE AND/OR SERVICES MAY CONTAIN VIRUSES, BUGS,&nbsp;ERRORS, PROBLEMS&nbsp;OR
                    OTHER LIMITATIONS. IN&nbsp;NO EVENT WILL D1 OR ITS DIRECTORS, EMPLOYEES&nbsp;OR AGENTS HAVE ANY
                    LIABILITY WHATSOEVER FOR YOUR USE OF ANY INFORMATION OR SERVICE, OR&nbsp;THE SITE. WE&nbsp;ARE NOT
                    LIABLE FOR ANY INDIRECT, SPECIAL,&nbsp;INCIDENTAL OR CONSEQUENTIAL
                    DAMAGES&nbsp;(INCLUDING&nbsp;DAMAGES FOR LOSS OF BUSINESS, LOSS&nbsp;OF PROFITS, LITIGATION,&nbsp;OR
                    THE LIKE),&nbsp;WHETHER BASED ON BREACH OF CONTRACT, BREACH&nbsp;OF WARRANTY, TORT
                    (INCLUDING&nbsp;NEGLIGENCE),&nbsp;PRODUCT LIABILITY OR OTHERWISE, EVEN&nbsp;IF ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. NO&nbsp;ADVICE OR INFORMATION OBTAINED BY YOU FROM US THROUGH THE
                    SITE/SERVICE&nbsp;SHALL CREATE ANY WARRANTY, REPRESENTATION&nbsp;OR GUARANTEE NOT EXPRESSLY STATED
                    IN THIS AGREEMENT.
                  </p>

                  <p>
                    D1’S&nbsp;LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND&nbsp;REGARDLESS OF THE FORM OF THE ACTION,
                    WILL&nbsp;AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF&nbsp;ANY, BY&nbsp;YOU TO US FOR THE SERVICE
                    AND USE OF THE SITE, BUT&nbsp;IN NO CASE WILL OUR LIABILITY TO YOU EXCEED&nbsp;$50.&nbsp;YOU
                    ACKNOWLEDGE THAT IF NO FEES ARE PAID TO US FOR THE SITE AND/OR SERVICES, YOU&nbsp;SHALL BE LIMITED
                    TO INJUNCTIVE RELIEF ONLY, UNLESS&nbsp;OTHERWISE PERMITTED BY LAW, AND&nbsp;SHALL NOT BE ENTITLED TO
                    DAMAGES OF ANY KIND FROM US, REGARDLESS&nbsp;OF THE CAUSE OF ACTION.
                  </p>

                  <p>
                    IF YOU ARE A CALIFORNIA RESIDENT, YOU&nbsp;WAIVE CALIFORNIA CIVIL CODE SECTION 1542,
                    WHICH&nbsp;STATES, IN&nbsp;PART: “A&nbsp;GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
                    CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE,
                    WHICH&nbsp;IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>You agree to indemnify D1</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    You agree to indemnify, defend, and hold harmless D1, its contractors, licensors, subsidiaries and
                    affiliates and their respective partners, directors, officers, employees and agents from and against
                    any and all claims and expenses, including any and all losses, costs, liabilities, and attorneys’
                    fees, arising out of or in connection with: (1) your use of the Site and/or Services, (2) any user
                    Content, Third Party Content, Third Party Sites and any other content, (3) your violation of this
                    Agreement, or of any law or the rights of any third party, and (4) your breach of this Agreement
                    and/or any breach of your representations and warranties set forth herein.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>You agree that New York law applies to this Agreement</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    Subject to the arbitration clauses included below, if there is any dispute arising out of the Site
                    and/or Services, by using the Site and/or Services, you expressly agree that any such dispute shall
                    be governed by the laws of the State of New York, without regard to its conflict of law provisions,
                    and you expressly agree and consent to the exclusive jurisdiction and venue of the state and federal
                    courts of the State of New York, in New York County, for the resolution of any such dispute.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>Your general representation and warranty</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. You represent and warrant that you will use the Site and/or Services in accordance with the
                    Privacy Policy, with this Agreement (and, if applicable, the Provider Agreement) and with all
                    applicable laws and regulations (including without limitation any local laws or regulations in your
                    country, state, city, or other governmental area, regarding online conduct and acceptable content,
                    and including all applicable laws regarding the transmission of technical data exported from the
                    United States or the country in which you reside).
                  </p>

                  <p>
                    2. You represent and warrant that you will use the Site and/or Services so not to infringe or
                    misappropriate the intellectual property rights of any third party.
                  </p>

                  <ol type="I" data-block-type="core">
                    <li>
                      <strong>
                        <em>Other Terms</em>
                      </strong>
                    </li>
                  </ol>

                  <p>
                    1. You and D1 agree that any cause of action arising out of or related to the Site and/or Services
                    must commence within one (1) year after the cause of action arose; otherwise, such cause of action
                    is permanently barred.
                  </p>

                  <p>
                    2. You agree that, except for claims for injunctive or equitable relief or claims regarding
                    intellectual property rights (which may be brought in any competent court without the posting of a
                    bond), any dispute arising under this Agreement shall be finally settled in accordance with the
                    Comprehensive Arbitration Rules of the Judicial Arbitration and Mediation Service, Inc. (“JAMS”) by
                    arbitrators appointed in accordance with such rules.
                  </p>

                  <p>
                    3. You agree any arbitration shall take place in New York County in the State of New York in the
                    English language and the arbitral decision may be enforced in any court. The prevailing party in any
                    action or proceeding to enforce this Agreement shall be entitled to costs and attorneys’ fees.
                  </p>

                  <p>
                    4. If any part of this Agreement is held invalid or unenforceable, that part will be construed to
                    reflect the parties’ original intent, and the remaining portions will remain in full force and
                    effect.
                  </p>

                  <p>
                    5. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any
                    one instance, will not waive such term or condition or any subsequent breach thereof.
                  </p>

                  <p>6. D1 may assign its rights under this Agreement without condition.</p>

                  <p>
                    7. This Agreement will be binding upon and will inure to the benefit of the parties, their
                    successors and permitted assigns. The headings of the paragraphs of this Agreement are inserted for
                    convenience only and shall not be deemed to constitute part of this Agreement or to affect the
                    construction thereof.
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </Container>
    </>
  );
};

export default TermsOfService;
