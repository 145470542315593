import NotFoundPage from '@brands/Dashboard/NotFoundPage/NotFoundPage';
import useWindowSize from '@brands/hooks/useWindowSize';
import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';

import { useAppSelector } from '../../../../hooks/useReduxHook';
import { UserRoleName } from '../../../../services/identity/types/UserProfile';
import ImageUploadMobile from './ImageMobileUpload';
import ImageUploadWeb from './ImageUpload';

const ImageUploadProcessValidation: React.FC = () => {
  let component: JSX.Element;
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const user: AuthState = useAppSelector(selectAuth);

  switch (user.userInfo?.role.name) {
    case UserRoleName.Patient:
    case UserRoleName.OrganizationAdmin:
    case UserRoleName.SuperAdmin:
    case UserRoleName.Admin:
      if (isMobile || mobileDetect) component = <ImageUploadMobile />;
      else component = <ImageUploadWeb />;
      break;
    default:
      // call 404 page
      component = <NotFoundPage />;
      break;
  }

  return <div>{component}</div>;
};

export default ImageUploadProcessValidation;
