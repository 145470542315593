/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import React, { Dispatch, FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface Validator {
  id: number;
  form_question_id: number;
  type: string;
  operator: string;
  value: string;
  custom_error_message: string;
  created_at: string;
  updated_at: string | null;
}

interface QuestionAndPhoneInputProps {
  element: {
    id: number;
    form_id: number;
    form_step_id: number;
    title: string;
    is_required: boolean;
    is_conditional: boolean;
    type: string;
    options: string[] | [] | null;
    validators: Validator[] | [];
    created_at: string;
    updated_at: string | null;
  };
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
}

const QuestionAndPhoneInput: FC<QuestionAndPhoneInputProps> = React.forwardRef(({ element, form, setForm }, ref) => {
  const [enteredValue, setEnteredValue] = useState('');
  useEffect(() => {
    if (!form[`question_${element.id}`] || form[`question_${element.id}`] === 'no') {
      setEnteredValue('');
    } else {
      setEnteredValue(form[`question_${element.id}`]);
    }
  }, []);
  useEffect(() => {
    if (enteredValue) {
      setForm((prev: any) => ({
        ...prev,
        [`question_${element.id}`]: enteredValue,
      }));
    } else {
      setForm((prev: any) => ({
        ...prev,
        [`question_${element.id}`]: '',
      }));
    }
  }, [enteredValue]);

  return (
    <div className={`${styles.row} row ${styles.medicalFormRow}`}>
      <div className="row" style={{ paddingBottom: '20px' }}>
        <div className="col">
          <div className={styles.inputWrap}>
            <div>
              <span>
                {element.title.startsWith('Pharmacy') ? element.title.replace(/^Pharmacy\s*/, '') : element.title}
                {element.is_required ? <span className={styles.redText}> *</span> : ''}
              </span>
            </div>
            <PhoneInput
              id="iPhoneNumber"
              value={enteredValue}
              onChange={(e) => {
                setEnteredValue(e as string);
              }}
              inputClass={styles.input}
              wrapperClassName={styles.inputWrapper}
              isUnderlined={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default QuestionAndPhoneInput;
