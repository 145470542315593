import qs from 'qs';

import { client } from '../client';

export interface PDFTimeZone {
  timezone?: string;
}

export const downloadConversation = async (sid: string, payload?: PDFTimeZone): Promise<string> => {
  const { data } = await client.get(`/conversations/${sid}/download?${qs.stringify(payload, { skipNulls: true })}`);

  return data;
};
