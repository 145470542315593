import { defaultFormValues } from '@brands/Dashboard/MyProfile/Admin/utils/defaultFromValues';
import { AdminMyProfileSubmit } from '@brands/Dashboard/MyProfile/Admin/utils/types';
import { UserProfile } from '@brands/services/identity/types/UserProfile';

export const mapProfileToMyProfileSubmit = (adminProfile: UserProfile): AdminMyProfileSubmit => {
  const mappedProfile: AdminMyProfileSubmit = {
    ...defaultFormValues,
    first_name: adminProfile.basic_info?.first_name || '',
    last_name: adminProfile.basic_info?.last_name || '',
    phone_number: adminProfile?.phone_number || '',
    email: adminProfile.email || '',
    address1: adminProfile?.address?.address1 || '',
    address2: adminProfile?.address?.address2 || '',
    zip_code: adminProfile?.address?.zip_code || '',
    state: adminProfile?.address?.state || '',
    city: adminProfile?.address?.city || '',
  };
  return mappedProfile;
};
