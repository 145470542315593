import { useEffect, useState } from 'react';
import { Participant } from 'twilio-video';

import useVideoContext from './useVideoContext';

export default function useSelectedParticipant(): any {
  const { room } = useVideoContext();
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
  const setSelectedParticipantEl = (participant: Participant): void =>
    setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : participant));
  useEffect(() => {
    if (!room) {
      return () => {};
    }
    const onDisconnect = (): void => {
      setSelectedParticipant(null);
    };
    const handleParticipantDisconnected = (participant: Participant): void =>
      setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : prevParticipant));

    room.on('disconnected', onDisconnect);
    room.on('participantDisconnected', handleParticipantDisconnected);
    return () => {
      room.off('disconnected', onDisconnect);
      room.off('participantDisconnected', handleParticipantDisconnected);
    };
  }, [room]);

  return [selectedParticipant, setSelectedParticipantEl] as const;
}
