export const truncateString = (str: string, maxChars: number): string => {
  if (str?.length <= maxChars) {
    return str;
  }
  const separators = /[-\s]+/; // Matches one or more hyphens or spaces
  const words = str?.split(separators);
  const lastWord = words.pop();
  const truncated = `${words.join('-').slice(0, maxChars / 2)}...${lastWord}`;
  return truncated;
};

export const truncateFilename = (filename: string, maxLength: number): string => {
  if (filename.length <= maxLength) {
    return filename;
  }

  const insertIndex = Math.floor(maxLength / 2);
  const charsToRemove = filename.length - maxLength + 3; // add 3 for the "..." string

  return `${filename.substring(0, insertIndex)}...${filename.substring(insertIndex + charsToRemove)}`;
};

export const getFileMimeType = (fileName: string): string => {
  const extension = fileName.split('.').pop()?.toLowerCase() || '';
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'doc':
    case 'docx':
      return 'application/msword';
    case 'ppt':
    case 'pptx':
      return 'application/vnd.ms-powerpoint';
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
    case 'jfif':
    case 'pjpeg':
    case 'pjp':
      return 'image/jpeg';
    default:
      return '';
  }
};
