import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';

import { useAppSelector } from '../../hooks/useReduxHook';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import UserSearch from './SuperAdmin/UserSearch';

const Dashboard: React.FC = () => {
  let component: JSX.Element;
  const user: AuthState = useAppSelector(selectAuth);

  switch (user.userInfo?.role.name) {
    case UserRoleName.Admin:
      component = <UserSearch />;
      break;
    case UserRoleName.SuperAdmin:
      component = <UserSearch />;
      break;
    case UserRoleName.OrganizationAdmin:
      component = <UserSearch />;
      break;
    default:
      // call 404 page
      component = <NotFoundPage />;
      break;
  }

  return <div>{component}</div>;
};

export default Dashboard;
