export default function cleanPayload<T extends Record<string, any>>(p: T): Partial<T> {
  const cleaned: Partial<T> = {};
  Object.entries(p).forEach(([key, value]) => {
    // NOTE: Removed value !== null condition here for https://mouthwatch.atlassian.net/browse/DO-1158
    // We always have a need to send null values to backend for patch/post so it is a little bit awkward
    // to omit null fields from the request payload.
    // So even though we encounter any issues due to this change, we should not add back value !== null here
    if (value !== undefined) {
      cleaned[key as keyof T] = value;
    }
  });
  return cleaned;
}
