import { defaultFormValues } from '@brands/Dashboard/MyProfile/Provider/utils/defaultFromValues';
import { ProviderMyProfileSubmit } from '@brands/Dashboard/MyProfile/Provider/utils/types';
import { License, UserProfile } from '@brands/services/identity/types/UserProfile';
import { specialtyOptions } from '@brands/Utils/selectOptions';
import moment from 'moment';

export const mapProfileToMyProfileSubmit = (providerProfile: UserProfile): ProviderMyProfileSubmit => {
  const selectedPrimarySpecialty = providerProfile.provider_profile?.primary_specialty || [];
  const selectedSecondarySpecialty = providerProfile.provider_profile?.secondary_specialty || [];
  const mappedProfile: ProviderMyProfileSubmit = {
    ...defaultFormValues,
    providerID: providerProfile.id,
    date_of_birth: providerProfile?.basic_info?.date_of_birth
      ? moment(providerProfile.basic_info?.date_of_birth).format('MM/DD/YYYY')
      : '',
    first_name: providerProfile.basic_info?.first_name || '',
    middle_name: providerProfile.basic_info?.middle_name || '',
    last_name: providerProfile.basic_info?.last_name || '',
    phone_number:
      providerProfile?.phone_number === null
        ? ''
        : providerProfile?.phone_number.replace(/^(\+\d)(\d{3})(\d{3})(\d{4})$/, '$1 ($2) $3 $4'),
    email: providerProfile?.email || '',
    gender: providerProfile.basic_info?.gender || '',
    pronouns: providerProfile.basic_info?.pronouns || '',
    gender_identity: providerProfile.basic_info?.gender_identity || '',
    languages: providerProfile.basic_info?.languages || [''],
    language: [],
    licences: (providerProfile?.provider_profile?.licenses as License[]) || [],
    education: providerProfile.provider_profile?.education || [],
    primary_specialty: specialtyOptions.filter((option) => selectedPrimarySpecialty.includes(option.value)),
    secondary_specialty: specialtyOptions.filter((option) => selectedSecondarySpecialty.includes(option.value)),
    address1: providerProfile?.address?.address1 || '',
    address2: providerProfile?.address?.address2 || '',
    city: providerProfile?.address?.city || '',
    state: providerProfile?.address?.state || '',
    zip_code: providerProfile?.address?.zip_code || '',
    additional_credentials: providerProfile.provider_profile?.additional_credentials || '',
    biography: providerProfile.provider_profile?.biography || '',
    providerPlan: [],
    plan: providerProfile.provider_profile?.plan || [''],
    credentials: providerProfile.provider_profile?.credentials || '',
    national_provider_id: providerProfile.provider_profile?.national_provider_id || '',
    organizations: providerProfile.organizations || [],
    organization: [],
  };
  return mappedProfile;
};
