import React, { useEffect, useState } from 'react';

export function useLocalStorageState<T = undefined>(
  key: string,
  initialState: T | undefined,
): [T, React.Dispatch<React.SetStateAction<T | undefined>>];

export default function useLocalStorageState<T>(
  key: string,
  initialState: T,
): readonly [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item) as T;
    }
    return initialState;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
}
