import { toast } from 'react-toastify';

export interface ErrorType {
  response: { data: ErrorResponse };
}

export interface ErrorResponse {
  message?: string;
  details?: string;
}

export function displayErrorDetails(error: any): void {
  if (
    (error as ErrorType).response &&
    (error as ErrorType).response.data &&
    error.code !== 'ERR_NETWORK' &&
    error.message !== 'Network Error'
  ) {
    const { response } = error as ErrorType;
    if (response.data.message && response.data.details) {
      toast.error(`${response.data.message} \n${response.data.details}`);
    } else if (response.data.details) {
      toast.error(response.data.details);
    } else {
      toast.error(response.data.message);
    }
  } else if (typeof error === 'string' && error !== 'ERR_NETWORK' && error !== 'Network Error') {
    toast.error(error);
  } else if (error.code !== 'ERR_NETWORK' && error.message !== 'Network Error') {
    toast.error((error as Error).message);
  }
}
