import { defaultFormValues } from '@brands/Dashboard/MyProfile/Patient/utils/defaultFormValues';
import { MyProfileSubmit } from '@brands/Dashboard/MyProfile/Patient/utils/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PatientMyProfileState = {
  myProfile: MyProfileSubmit;
};

const initialState: PatientMyProfileState = {
  myProfile: defaultFormValues,
};

export const patientMyProfileSlice = createSlice({
  name: 'patientMyProfile',
  initialState,
  reducers: {
    setPatientMyProfile: (state, action: PayloadAction<MyProfileSubmit>) => {
      return { ...state, myProfile: action.payload };
    },
    clearPatientMyProfile: () => {
      return initialState;
    },
  },
});

export const { setPatientMyProfile, clearPatientMyProfile } = patientMyProfileSlice.actions;

export default patientMyProfileSlice.reducer;
