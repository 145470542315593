import qs from 'qs';

import { client } from '../client';
import { Meta } from '../communication/types/Meta';
import { ProviderAvailability } from './types/ProviderAvailability';

export interface ListProviderAvailabilitiesResponse {
  nodes: ProviderAvailability[];
  total: number;
  meta: Meta;
}

export interface ListProviderAvailabilities {
  user_id: number;
  type?: string;
  limit?: number;
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
}

export const listUserAvailabilities = async (
  user_id: number,
  params?: ListProviderAvailabilities,
): Promise<ListProviderAvailabilitiesResponse> => {
  const { data } = await client.get(`/users/${user_id}/user_availability?${qs.stringify(params, { skipNulls: true })}`);
  return data;
};
