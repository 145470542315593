import { client } from '../client';
import { ProviderAvailability } from './types/ProviderAvailability';

export interface Payload {
  start_time: string;
  end_time: string;
  synchronous: boolean;
  asynchronous: boolean;
}

export const updateUserAvailability = async (
  user_id: number,
  availability_id: number,
  payload: Payload,
): Promise<ProviderAvailability> => {
  const { data } = await client.patch<ProviderAvailability>(
    `/users/${user_id}/user_availability/${availability_id}`,
    payload,
  );

  return data;
};
