export const defaultFormValues = {
  profile_img: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  providerID: 0,
  email: '',
  phone_number: '',
  date_of_birth: '',
  pronouns: '',
  gender: '',
  gender_identity: '',
  licence: '',
  licences: [{ id: 0, state: '', number: '', expiry_date: '', insurance_carriers: [] }],
  credentials: '',
  statesOfLicense: [],
  license_number: '',
  licencesObj: [],
  languages: [
    {
      id: '',
      name: '',
      locale: '',
    },
  ],
  additional_credentials: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip_code: '',
  language: [],
  dea_number: '',
  national_provider_id: '',
  education: [],
  primary_specialty: [],
  secondary_specialty: [],
  biography: '',
  plan: [''],
  providerPlan: [],
  availability: [],
  npi: '',
};
