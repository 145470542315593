/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import SmileScanMobile from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_mobile.svg';
import { triggerMobileHandoff } from '@brands/services/communication/triggerMobileHandover';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setIsFromHandoff } from '@brands/store/slices/isFromHandOffSlice';
import { setForm } from '@brands/store/slices/patientFormSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import styles from './styles.module.scss';

const ImageUploadMobile = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const screenSize = useWindowSize();
  const { state } = useLocation();
  const location = useLocation();
  const queryParams = useMemo(() => queryString.parse(location.search), [location.search]);

  const onBack = (): void => {
    dispatch(setForm([...values!]));
    navigate(-1);
  };

  const handleUrlState = async (): Promise<void> => {
    try {
      await triggerMobileHandoff();
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    if (queryParams.auth) {
      dispatch(setIsFromHandoff(true));
      handleUrlState();
    }
  }, [queryParams]);

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.uploadImageContainer}>
        <div className={styles.uploadImageContainerDiv}>
          <ProgressBar currentStep={3} totalSteps={4} onBack={onBack} hideStepNumbers />
          <div className={`${styles.formTitle} d-flex mt-3`}>
            {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' ||
            (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
              ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
                ? 'Image upload'
                : 'Attachment Upload *'
              : 'Image upload'}
          </div>
          <div className={styles.uploadImageQRContainer}>
            <div>
              {' '}
              <img className={styles.smileScanMobile} src={SmileScanMobile} alt="SmileScanMobile" />
            </div>
            <div className={styles.uploadImageQRContainerText}>
              <span className={styles.uploadImageQRContainerText1}>Guided Image Capture</span>
              <span className={styles.uploadImageQRContainerText3}>
                We’ll guide you through 5 simple photos that will help your Dentist give you the best experience.
              </span>
            </div>
            <div className={styles.btnContainer}>
              <Button
                type="button"
                style={{ width: '245px', height: '46px' }}
                onClick={() => navigate('/upload-image-flow-step-one', {})}
              >
                Begin
              </Button>
            </div>
          </div>
          <div className={styles.uploadImageQRFooter}>
            <div className={styles.uploadImageQRFooterText}>
              Already Have Photos?{' '}
              <span className={styles.clickHere}>
                <a onClick={() => navigate('/upload-image-flow')}>Tap Here</a>
              </span>{' '}
              to upload images of your mouth and teeth from your phone.
            </div>
          </div>
          {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) !== 'one_report' &&
            (values.find((item) => item.form_question_id === 20)?.value.caseType as string) !== 'second_opinion' && (
              <div className={styles.btnContainer}>
                <Button
                  type="button"
                  className={styles.skipButton}
                  style={{ width: '245px', height: '46px' }}
                  onClick={() =>
                    navigate('/medical-information-summary', {
                      state: {
                        caseType: state
                          ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string)
                          : '',
                        patientId: selectedPatient?.value || '',
                        shouldSeeLastModal: false,
                        fromPatientIntake: true,
                      },
                    })
                  }
                >
                  Skip
                </Button>
              </div>
            )}
        </div>
      </div>
    </Container>
  );
};
export default ImageUploadMobile;
