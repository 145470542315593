import { client } from '../client';
import { IGMConversation } from './types/IGMConversation';

export interface ParticipantPayload {
  identity: string;
}

export const addConversationParticipant = async (
  payload: ParticipantPayload,
  conversation_sid: string,
): Promise<IGMConversation> => {
  const { data } = await client.post<IGMConversation>(`/conversations/${conversation_sid}/participants`, payload);

  return data;
};
