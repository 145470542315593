import React, { SetStateAction } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';
import PossibleTreatmentNeedsSection from '../PossibleTreatmentNeedsSection/PossibleTreatmentNeedsSection';
import { useParams } from 'react-router-dom';

type PlanSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
  addRequiredField: boolean;
  setAddRequiredField: React.Dispatch<React.SetStateAction<boolean>>;
};

const PlanSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
  addRequiredField,
  setAddRequiredField,
}: PlanSectionProps): JSX.Element => {
  const saveOneReportPlan = async (attributeName: string, attributeValue: any): Promise<void> => {
    if (attributeValue || attributeValue === '') {
      const updateData = {
        [attributeName]: attributeValue,
      };
      const response = await updateOneReport(thisCase?.one_report.id || '', updateData);

      setCase({
        ...thisCase,
        one_report: {
          ...thisCase.one_report,
          ...response,
        },
      });
    }
  };

  return (
    <div className={styles.subjectiveDiv}>
      <div className={styles.subjective}>
        <span className={styles.subjectiveSpan}>Disposition/Intervention or recommendations provided*</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.disposition ? '#BA271A' : '' }}
            {...register('plan.disposition')}
            value={consultationForm.plan?.disposition || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                plan: {
                  ...consultationForm.plan,
                  disposition: e.target.value,
                },
              });

              setValue('plan.disposition', e.target.value, { shouldValidate: true });

              if (errors?.plan?.disposition) {
                trigger('plan.disposition');
              }
            }}
            onBlur={() => {
              saveOneReportPlan('disposition', consultationForm.plan.disposition);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.disposition || ''}
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.disposition ? '#BA271A' : '' }}
          />
        )}
      </div>
      <PossibleTreatmentNeedsSection
        consultationForm={consultationForm}
        setConsultationForm={setConsultationForm}
        thisCase={thisCase}
        setCase={setCase}
        userInfo={userInfo}
        register={register}
        setValue={setValue}
        trigger={trigger}
        errors={errors}
        routeCaseId={useParams().caseId as string}
        addRequiredField={addRequiredField}
        setAddRequiredField={setAddRequiredField}
      />
    </div>
  );
};

export default React.memo(PlanSection);
