/* eslint-disable max-len */
import Moment from 'moment';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { BsThreeDots } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';

import CareAdvisorProfileCardModal from '../../../Dashboard/ProfileCards/CareAdvisor/ProfileCards';
import ProviderProfileCardModal from '../../../Dashboard/ProfileCards/Dentist/ProfileCards';
import PatientProfileCardModal from '../../../Dashboard/ProfileCards/Patient/ProfileCards';
import { IGMConversationParticipant } from '../../../services/communication/types/IGMConversationParticipant';
import { IGMMessage } from '../../../services/communication/types/IGMMessage';
import { getUserAvatar } from '../../../services/identity/getUserAvatar';
import { UserProfile, UserRoleName } from '../../../services/identity/types/UserProfile';
import styles from './case-styles.module.scss';
import MessageContent from './MessageContent';

interface IActiveChatBubbleProps {
  direction: 'incoming' | 'outgoing';
  messageDetails: {
    id: string;
    authorRelation: string | undefined;
    dependentName: string | undefined;
  };
  conversationState: 'active' | 'inactive' | 'closed' | undefined;
  participants: IGMConversationParticipant[] | null | undefined;
  message: IGMMessage;
  isVCRoom?: boolean;
  editMessage: (message: IGMMessage, body: string) => Promise<void>;
  deleteMessage: (message: IGMMessage) => Promise<void>;
  messageIndex: number;
  handleMediaLoad: (index: number) => void;
  listInnerRef: React.RefObject<HTMLDivElement>;
  messageCreator: UserProfile | undefined | null;
  scrollTothis: (number: number, index: number) => void;
  patientCurrentState: string | undefined;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  isInEditMode: string;
  setIsInEditMode: React.Dispatch<React.SetStateAction<string>>;
}

const CaseActiveChatBubble: React.FC<IActiveChatBubbleProps> = ({
  conversationState,
  direction,
  isVCRoom,
  messageDetails,
  editMessage,
  deleteMessage,
  message,
  participants,
  messageIndex,
  handleMediaLoad,
  messageCreator,
  listInnerRef,
  scrollTothis,
  patientCurrentState,
  setEditMode,
  isInEditMode,
  setIsInEditMode,
}) => {
  const [profileCardModal, setProfileCardModal] = useState(false);
  const [providerProfileCardModal, setProviderProfileCardModal] = useState(false);
  const [caProfileCardModal, setCAProfileCardModal] = useState(false);
  const [patientParticipant, setPatientParticipant] = useState<UserProfile | null>(null);
  const [providerParticipant, setProviderParticipant] = useState<UserProfile | null>(null);
  const [caParticipant, setCAParticipant] = useState<UserProfile | null>(null);
  const absoluteRef = useRef<HTMLDivElement>(null);
  const { itemStyle, divStyle, timeContainer } =
    direction === 'incoming'
      ? {
          itemStyle: isVCRoom ? styles.vc_received_msg : styles.received_msg,
          divStyle: isVCRoom ? styles.vc_received_withd_msg : styles.received_withd_msg,
          timeContainer: styles.time_container_received,
        }
      : {
          itemStyle: isVCRoom ? styles.vc_outgoing_msg : styles.outgoing_msg,
          divStyle: isVCRoom ? styles.vc_sent_msg : styles.sent_msg,
          timeContainer: styles.time_container_outgoing,
        };

  const [newMessage, setNewMessage] = useState('');
  const [hoveredOnMessage, setHoveredOnMessage] = useState<boolean>(false);

  const handleEditButtonClick = (): void => {
    setHoveredOnMessage(false);
    setIsInEditMode(messageDetails.id);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter' && isInEditMode === messageDetails.id) {
      setIsInEditMode('');
      setHoveredOnMessage(false);
      editMessage(message, newMessage);
    }
  };

  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (hoveredOnMessage) {
        setHoveredOnMessage(false);
      }
      if (isInEditMode === '') {
        setIsInEditMode('');
      }
    },
  });

  const onMessageChanged = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setNewMessage(event.target.value);
  };

  const [avatarIcon, setAvatarIcon] = useState(false);
  const handleAvatarError = (): void => {
    setAvatarIcon(true);
  };

  const [containerHeight, setContainerHeight] = useState(0);
  const chatMessageContainerRef = useRef<HTMLDivElement>(null);

  const displayParticipantProfile = (e: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();
    if (messageCreator?.role.name === UserRoleName.Patient || messageCreator?.role.name === UserRoleName.Dependent) {
      setPatientParticipant(messageCreator || null);
      setProfileCardModal(true);
    } else if (messageCreator?.role.name === UserRoleName.Provider) {
      setProviderParticipant(messageCreator || null);
      setProviderProfileCardModal(true);
    } else if (messageCreator?.role.name === UserRoleName.CareAdvisor) {
      setCAParticipant(messageCreator || null);
      setCAProfileCardModal(true);
    }
  };

  useEffect(() => {
    if (chatMessageContainerRef.current) {
      const newHeight = chatMessageContainerRef.current.clientHeight;
      if (newHeight !== containerHeight) {
        setContainerHeight(newHeight);
        // scrollIntoMessage();
        chatMessageContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [containerHeight, chatMessageContainerRef]);

  const handleScrollEdit = (index: number): void => {
    if (listInnerRef.current && absoluteRef.current) {
      const containerHeight2 = listInnerRef.current.clientHeight;
      const containerScrollHeight = listInnerRef.current.scrollHeight;
      const absoluteHeight = absoluteRef.current.clientHeight;
      const spaceAvailable = containerScrollHeight - containerHeight2;
      if (absoluteHeight < spaceAvailable) {
        scrollTothis(containerScrollHeight - containerHeight + absoluteHeight, index);
      }
    }
  };
  return (
    <div
      style={{
        padding: '0 15px 0px 15px',
      }}
      className={`${isInEditMode === messageDetails.id && styles.isInEditMode}`}
    >
      <li ref={ref} className={itemStyle} key={messageDetails.id} style={{ position: 'relative' }}>
        <div className={`${divStyle} ${styles.divStyle}`}>
          <div className={styles.message_content}>
            {isInEditMode === messageDetails.id ? (
              <div>
                <textarea
                  className={styles.editBox}
                  defaultValue={message.body!}
                  onChange={onMessageChanged}
                  onKeyDown={onKeyDown}
                />
                <div className={styles.edit_container}>
                  <button
                    type="button"
                    onClick={() => {
                      setIsInEditMode('');
                      setHoveredOnMessage(false);
                      editMessage(message, newMessage);
                      setEditMode(false);
                    }}
                    className={styles.submit_buttton}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setHoveredOnMessage(false);
                      setIsInEditMode('');
                    }}
                    className={styles.cancel_buttton}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <MessageContent
                messageIndex={messageIndex}
                handleMediaLoad={handleMediaLoad}
                message={message}
                mediaIDs={message.media_ids}
                direction={direction}
              />
            )}
          </div>
          <section
            className={styles.dateContainer}
            style={{ justifyContent: direction === 'incoming' ? 'flex-start' : 'flex-end' }}
          >
            <div
              style={{ display: direction === 'incoming' ? 'flex' : 'none', cursor: 'pointer' }}
              onClick={(e) => displayParticipantProfile(e)}
            >
              {!avatarIcon ? (
                <img
                  alt="Avatar"
                  onError={handleAvatarError}
                  className={styles.profileImage}
                  src={getUserAvatar(Number(message.created_by))}
                />
              ) : (
                <img
                  alt="Profile"
                  className={styles.profileImage}
                  src="https://www.seekpng.com/png/detail/41-410093_circled-user-icon-user-profile-icon-png.png"
                />
              )}
            </div>
            <span className={timeContainer}>
              <span style={{ display: direction === 'incoming' ? 'flex' : 'none' }}>
                <span
                  className={styles.authorName}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => displayParticipantProfile(e)}
                >
                  {participants?.find((participant) => +participant.identity === +message.created_by!)?.role ===
                    'Provider' && <span className={styles.providerTag}>Dr. </span>}
                  {participants?.find((participant) => +participant.identity === +message.created_by!)?.full_name}
                  <span className={styles.hyphen}> - </span>
                  {messageCreator?.role.name === UserRoleName.Dependent && messageDetails.authorRelation
                    ? `${messageDetails.dependentName}'s ${messageDetails.authorRelation}`
                    : ''}
                </span>
                {messageCreator?.role.name === UserRoleName.Dependent && messageDetails.authorRelation && (
                  <span className={styles.hyphen}> - </span>
                )}
              </span>
              <span className={!isVCRoom ? styles.time_date : styles.vc_time_date}>
                {Moment(`${message.created_at}Z`).from(Moment().utcOffset('GMT-00:00').format(), true) !==
                'Invalid date'
                  ? `${Moment(`${message.created_at}Z`).from(Moment().utcOffset('GMT-00:00').format(), true)} ago`
                  : 'a few seconds ago'}
              </span>
              {message.attributes.archived_at === undefined &&
              message.body !== null &&
              message.updated_at !== null &&
              message.updated_at !== message.created_at ? (
                <span className={styles.time_date}>
                  This message was edited.(
                  {Moment(`${message.created_at}Z`).from(Moment().utcOffset('GMT-00:00').format(), true) !==
                  'Invalid date'
                    ? `${Moment(`${message.updated_at}Z`).from(Moment().utcOffset('GMT-00:00').format(), true)} ago`
                    : 'a few seconds ago'}
                  )
                </span>
              ) : (
                ''
              )}
            </span>
            {direction === 'outgoing' &&
              (conversationState === 'active' || conversationState === 'inactive') &&
              message.body !== null &&
              !isVCRoom &&
              message.attributes.archived_at === undefined && (
                <BsThreeDots
                  className={styles.message_edit_svg}
                  onClick={async () => {
                    await setHoveredOnMessage(!hoveredOnMessage);
                    handleScrollEdit(messageIndex);
                  }}
                />
              )}
            {direction === 'outgoing' &&
            (conversationState === 'active' || conversationState === 'inactive') &&
            message.body !== null &&
            hoveredOnMessage === true ? (
              <div className={styles.dropDown} ref={absoluteRef}>
                <div className={styles.borderBottom} onClick={handleEditButtonClick}>
                  Edit
                  <FaRegEdit className={styles.dropDownIcon} title="Edit Message" />
                </div>
                <div onClick={() => deleteMessage(message)}>
                  Delete
                  <RiDeleteBinLine className={styles.dropDownIcon} title="Delete Message" />
                </div>
              </div>
            ) : (
              ''
            )}
          </section>
        </div>
        <span
          ref={chatMessageContainerRef}
          id={messageDetails.id}
          style={{ position: 'absolute', bottom: '0', right: '0' }}
        />
      </li>
      {profileCardModal && (
        <PatientProfileCardModal
          patientIdentity={patientParticipant?.guardian_relationship ? patientParticipant.guardian : patientParticipant}
          isOpen={profileCardModal}
          toggleModal={setProfileCardModal}
        />
      )}
      {providerProfileCardModal && (
        <ProviderProfileCardModal
          isOpen={providerProfileCardModal}
          toggleModal={setProviderProfileCardModal}
          providerIdentity={providerParticipant}
          patientCurrentStateValue={patientCurrentState}
        />
      )}
      {caProfileCardModal && (
        <CareAdvisorProfileCardModal
          isOpen={caProfileCardModal}
          toggleModal={setCAProfileCardModal}
          caIdentity={caParticipant}
        />
      )}
    </div>
  );
};

CaseActiveChatBubble.defaultProps = {
  isVCRoom: false,
};

export default CaseActiveChatBubble;
