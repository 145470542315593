export const defaultFormValues = {
  profile_img: '',
  first_name: '',
  last_name: '',
  email: '',
  organizations: {},
  organization: undefined,
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip_code: '',
  phone_number: '',
};
