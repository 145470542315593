/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import CareAdvisorProfileCardModal from '@brands/Dashboard/ProfileCards/CareAdvisor/ProfileCards';
import ProviderProfileCardModal from '@brands/Dashboard/ProfileCards/Dentist/ProfileCards';
import PatientProfileCardModal from '@brands/Dashboard/ProfileCards/Patient/ProfileCards';
import { selectAuth } from '@brands/store/selectors/auth';
import { clearPageState } from '@brands/store/slices/pageStateSlice';
import { resetForm, resetSelectedPatient, setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import cn from 'classnames';
import { throttle } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { HiChevronDown, HiChevronUp, HiPlusCircle } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useLocalStorageState, useWindowSize } from '../../../hooks';
import useDebounce from '../../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useUsers from '../../../hooks/useUsers';
import { getUserAvatar } from '../../../services/identity/getUserAvatar';
import { IListUsersParams } from '../../../services/identity/listAllUsers';
import { UserProfile, UserRoleName } from '../../../services/identity/types/UserProfile';
import { selectSortByStyles } from '../../../Utils/customStyles';
import { SortKeys, SortOrder } from '../../../Utils/sortUserData';
import PaginationController from './Components/PaginationController';
import AddAdminUserModal from './modal/Admin/AddAdminUserModal';
import AddCareAdvisorUserModal from './modal/CareAdvisor/AddCareAdvisorUserModal';
import AddOrganizationAdminUserModal from './modal/OrganizationAdmin/AddOrganizationAdminUserModal';
import AddPatientUserModal from './modal/Patient/AddPatientUserModal';
import AddProviderUserModal from './modal/Provider/AddProviderUserModal';
import styles from './styles.module.scss';
import LinkWithUnderline from '@brands/Dashboard/MyProfile/Components/LinkWithUnderline';
import CasesList from './CasesList';

interface Option {
  label: string;
  action: () => void;
}

const UserSearch = (): JSX.Element => {
  const screenSize = useWindowSize();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useAppSelector(selectAuth);

  const queryParams = useMemo(() => queryString.parse(location.search), [location.search]);

  const [filterRole, setFilterRole] = useState<UserRoleName[]>(
    queryParams.type ? ([] as UserRoleName[]).concat(queryParams.type as UserRoleName) : [],
  );

  const [activeLink, setActiveLink] = useState<number | null>(() => {
    if (location.hash === '#cases') {
      return 5;
    }

    return filterRole.includes(UserRoleName.Patient || UserRoleName.Dependent)
      ? 1
      : filterRole.includes(UserRoleName.Provider)
      ? 2
      : filterRole.includes(UserRoleName.CareAdvisor)
      ? 3
      : filterRole.includes(UserRoleName.Admin || UserRoleName.SuperAdmin || UserRoleName.OrganizationAdmin)
      ? 4
      : 1;
  });

  const [query, setQuery] = useState<string>(queryParams.q ? (queryParams.q as string) : '');
  const isMobile = screenSize.width < 768;
  const [sortKey, setSortKey] = useState<SortKeys>(
    queryParams.sort_by ? (queryParams.sort_by as 'email' | 'status' | 'full_name' | 'type') : 'full_name',
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    queryParams.sort_direction ? (queryParams.sort_direction as 'asc' | 'desc') : 'asc',
  );
  const [search, setSearch] = useState<string>('');
  const throttledSearch = throttle(setSearch, 100, { trailing: true });
  const [patientRoleModal, setPatientRoleModal] = useState(false);
  const [providerRoleModal, setProviderRoleModal] = useState(false);
  const [caRoleModal, setCARoleModal] = useState(false);
  const [adminRoleModal, setAdminRoleModal] = useState(false);
  const [organizationAdminRoleModal, setOrganizationAdminRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [profileCardModalUser, setProfileCardModalUser] = useState<UserProfile | null>(null);
  const [profileCardModal, setProfileCardModal] = useState(false);
  const [, setUrl] = useLocalStorageState<string>('adminDashboardURL', '/admin/dashboard');

  const headers: { key: SortKeys; label: string; isSorted: boolean; sortDirection: 'asc' | 'desc' }[] = [
    {
      key: 'id',
      label: 'ID',
      isSorted: queryParams.sort_by === 'id',
      sortDirection: queryParams.sort_direction as 'asc' | 'desc',
    },
    {
      key: 'full_name',
      label: 'Name',
      isSorted: queryParams.sort_by === 'full_name',
      sortDirection: queryParams.sort_direction as 'asc' | 'desc',
    },
    {
      key: 'email',
      label: 'User Name',
      isSorted: queryParams.sort_by === 'email',
      sortDirection: queryParams.sort_direction as 'asc' | 'desc',
    },
    {
      key: 'status',
      label: 'Status',
      isSorted: queryParams.sort_by === 'status',
      sortDirection: queryParams.sort_direction as 'asc' | 'desc',
    },
    {
      key: 'type',
      label: 'Type',
      isSorted: queryParams.sort_by === 'type',
      sortDirection: queryParams.sort_direction as 'asc' | 'desc',
    },
  ];
  const [headersState, setHeadersState] = useState(headers);

  const messagesParams = useMemo(() => (search ? { search } : {}), [search]);

  const options: Option[] = [];

  const handleReset = (): void => {
    dispatch(resetForm());
    dispatch(resetSelectedPatient());
    dispatch(clearPageState());
  };

  const optionsForGeneralAdmin: Option[] = [
    {
      label: 'Organization Admin',
      action: () => {
        setOrganizationAdminRoleModal(true);
        setSelectedRole('OrganizationAdmin');
      },
    },
  ];

  if (userInfo.role.name === UserRoleName.Admin) {
    options.push(...optionsForGeneralAdmin);
  }

  if (userInfo.role.name === UserRoleName.SuperAdmin) {
    options.push(
      ...optionsForGeneralAdmin,
      {
        label: 'Admin',
        action: () => {
          setAdminRoleModal(true);
          setSelectedRole('Admin');
        },
      },
      {
        label: 'Super Admin',
        action: () => {
          setAdminRoleModal(true);
          setSelectedRole('SuperAdmin');
        },
      },
    );
  }

  const handleButtonClick = (): void => {
    if (activeLink === 1) {
      setPatientRoleModal(true);
    } else if (activeLink === 2) {
      setProviderRoleModal(true);
    } else if (activeLink === 3) {
      setCARoleModal(true);
    } else if (activeLink === 4) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (action: () => void): void => {
    action();
    setIsOpen(false);
  };

  // Initialize the state with the parsed URL parameters
  const [params, setParams] = useState<IListUsersParams>({
    q: query || '',
    page: parseInt(queryParams.page as string, 10) || 1,
    skip: parseInt(queryParams.skip as string, 10) || 0,
    limit: parseInt(queryParams.limit as string, 10) || 10,
    sort_by: (queryParams.sort_by || sortKey) as 'email' | 'status' | 'full_name' | 'type',
    sort_direction: (queryParams.sort_direction || sortOrder) as 'asc' | 'desc',
  });

  const debouncedParams = useDebounce(params, 500);
  const { users, totalRecords, dataLoading, currentPage, setCurrentPage } = useUsers(debouncedParams);
  type UpdatedParams = IListUsersParams & { [key: string]: any };

  const sortByProviderAppointment: { value: SortKeys; label: string }[] = [
    { value: 'full_name', label: 'Name' },
    { value: 'email', label: 'User Name' },
    { value: 'status', label: 'Status' },
    { value: 'type', label: 'Type' },
  ];

  const changeSort = (key: SortKeys): void => {
    const selectedHeader = headers.find((header) => header.key === key);
    const headerIndex = headers.findIndex((header) => header.key === key);
    setSortOrder(selectedHeader?.sortDirection === 'asc' ? 'desc' : 'asc');
    setSortKey(key);
    setParams((prevParams) => ({
      ...prevParams,
      sort_direction: selectedHeader?.sortDirection === 'asc' ? 'desc' : 'asc',
    }));
    const updatedHeadersState = headersState.map((header, index) => {
      if (index === headerIndex) {
        return {
          ...header,
          isSorted: true,
          sortDirection: selectedHeader?.sortDirection === 'asc' ? 'desc' : ('asc' as 'asc' | 'desc'),
        };
      }
      return {
        ...header,
        isSorted: false,
      };
    });
    setHeadersState(updatedHeadersState);
  };

  const updatePageNumber = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
    queryParams.page = pageNumber.toString();
    queryParams.skip = ((pageNumber - 1) * 10).toString();
  };
  useEffect(() => {
    handleReset();
  }, []);

  useEffect(() => {
    if (filterRole.length === 0 && activeLink !== 5) {
      setActiveLink(1);
    }
  }, [filterRole]);
  // Update the URL and persist the state when the state changes
  useEffect(() => {
    const updatedParams: UpdatedParams = {
      ...params,
      page: queryParams.page ? parseInt(queryParams.page as string, 10) : currentPage,
      q: query || '',
    };

    // Remove any undefined or null parameters
    Object.keys(updatedParams).forEach((key) => {
      if (
        updatedParams[key as keyof UpdatedParams] === undefined ||
        updatedParams[key as keyof UpdatedParams] === null ||
        updatedParams[key as keyof UpdatedParams] === ''
      ) {
        delete updatedParams[key];
      }
    });

    // Convert the parameters to a query string
    const queryString2 = queryString.stringify(updatedParams);

    // Update the URL
    const newUrl = `?${queryString2}`;
    setUrl(newUrl);

    // Update the URL without refreshing the page
    navigate(newUrl, { replace: true });

    // Update the browser's URL without triggering a page refresh
    window.history.pushState(null, '', newUrl);
  }, [params, currentPage, navigate]);

  useEffect(() => {
    if (currentPage !== 1) {
      setParams((prevParams) => ({
        ...prevParams,
        skip: (currentPage - 1) * 10,
      }));
    }
  }, [currentPage]);

  useEffect(() => {
    if (profileCardModalUser) {
      setProfileCardModal(true);
    } else {
      setProfileCardModal(false);
    }
  }, [profileCardModalUser]);

  useEffect(() => {
    if (!profileCardModal) {
      setProfileCardModalUser(null);
    }
  }, [profileCardModal]);

  useEffect(() => {
    if (activeLink !== 5) {
      setParams((prevParams: IListUsersParams) => {
        const updatedParams: IListUsersParams = {
          ...prevParams,
          q: query || '',
          page: parseInt(queryParams.page as string, 10) || 1,
          skip: parseInt(queryParams.skip as string, 10) || (currentPage - 1) * 10,
          limit: parseInt(queryParams.limit as string, 10) || 10,
          sort_by: (sortKey || queryParams.sort_by) as 'email' | 'status' | 'full_name' | 'type',
          sort_direction: (sortOrder || queryParams.sort_direction) as 'asc' | 'desc',
        };

        if (Array.isArray(queryParams.type)) {
          queryParams.type.forEach((value: any) => {
            updatedParams.type = value as (
              | UserRoleName.CareAdvisor
              | UserRoleName.Provider
              | UserRoleName.Patient
              | UserRoleName.Admin
              | UserRoleName.SuperAdmin
              | UserRoleName.OrganizationAdmin
              | UserRoleName.Dependent
            )[];
          });
        }
        if (filterRole) {
          updatedParams.type = filterRole as (
            | UserRoleName.CareAdvisor
            | UserRoleName.Provider
            | UserRoleName.Patient
            | UserRoleName.Admin
            | UserRoleName.SuperAdmin
            | UserRoleName.OrganizationAdmin
            | UserRoleName.Dependent
          )[];
        } else {
          delete updatedParams.type;
        }
        return updatedParams;
      });
    }
  }, [messagesParams, filterRole, currentPage, messagesParams.search, sortKey, sortOrder]);

  useEffect(() => {
    if (activeLink && activeLink !== 5) {
      setIsOpen(false);
      setFilterRole((prevFilterRole) => prevFilterRole.filter((role) => role !== UserRoleName.Patient));
      setFilterRole((prevFilterRole) => prevFilterRole.filter((role) => role !== UserRoleName.Dependent));
      setFilterRole((prevFilterRole) => prevFilterRole.filter((role) => role !== UserRoleName.Provider));
      setFilterRole((prevFilterRole) => prevFilterRole.filter((role) => role !== UserRoleName.CareAdvisor));
      setFilterRole((prevFilterRole) => prevFilterRole.filter((role) => role !== UserRoleName.Admin));
      setFilterRole((prevFilterRole) => prevFilterRole.filter((role) => role !== UserRoleName.SuperAdmin));
      setFilterRole((prevFilterRole) => prevFilterRole.filter((role) => role !== UserRoleName.OrganizationAdmin));
      updatePageNumber(1);
      setQuery('');
      setSearch('');
      setParams((prevParams) => ({
        ...prevParams,
        q: '',
        skip: 0,
        page: 1,
      }));
      switch (activeLink) {
        case 1:
          setFilterRole((prevFilterRole) => [...prevFilterRole, UserRoleName.Patient]);
          break;
        case 2:
          setFilterRole((prevFilterRole) => [...prevFilterRole, UserRoleName.Provider]);
          break;
        case 3:
          setFilterRole((prevFilterRole) => [...prevFilterRole, UserRoleName.CareAdvisor]);
          break;
        case 4:
          setFilterRole((prevFilterRole) => [...prevFilterRole, UserRoleName.Admin]);
          break;
      }
    } else if (activeLink === 5) {
      const urlWithoutQueryParams = `${location.pathname}#cases`;
      navigate(urlWithoutQueryParams, { replace: true });
    }
  }, [activeLink]);

  return (
    <Container
      isBGEclipse={false}
      containerStyle={{
        justifyContent: 'start',
      }}
      childClassName={styles.providerDashboard}
    >
      <div className={cn(styles.containerPf, styles.dropdown)}>
        <div className={styles.filterDiv}>
          <LinkWithUnderline text="Patients" id={1} activeLink={activeLink} setActiveLink={setActiveLink} />
          {userInfo.role.name !== UserRoleName.OrganizationAdmin && (
            <>
              <LinkWithUnderline text="Providers" id={2} activeLink={activeLink} setActiveLink={setActiveLink} />
              <LinkWithUnderline text="Care Team" id={3} activeLink={activeLink} setActiveLink={setActiveLink} />
              <LinkWithUnderline text="Admins" id={4} activeLink={activeLink} setActiveLink={setActiveLink} />
            </>
          )}
          <LinkWithUnderline text="Cases" id={5} activeLink={activeLink} setActiveLink={setActiveLink} />
        </div>

        {activeLink !== 5 && (
          <div className={`row ${styles.dashboardHeader}`}>
            <Button onClick={handleButtonClick}>
              {activeLink === 1
                ? 'Add a Patient'
                : activeLink === 2
                ? 'Add a Provider'
                : activeLink === 3
                ? 'Add a Care Advisor'
                : activeLink === 4
                ? 'Add an Amin'
                : 'Add a Patient'}
              <span className={styles.addIconSpan}>
                <HiPlusCircle />
              </span>
            </Button>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {options.map((option) => (
                  <div key={option.label} className={styles.options} onClick={() => handleOptionClick(option.action)}>
                    <span>{option.label}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {isMobile && activeLink !== 5 && (
          <div className={styles.sortBy}>
            <span>Sort by</span>
            <div className={`${styles.wrapInput100}`} data-validate="Choose Sort by">
              <Select
                className="input100 p-0 select"
                styles={selectSortByStyles}
                placeholder={sortByProviderAppointment[0].label}
                onChange={(value) => changeSort(value?.value as SortKeys)}
                menuPortalTarget={document.body}
                options={sortByProviderAppointment}
              />
            </div>
          </div>
        )}
        <div className={styles.providerDashboardBody}>
          {activeLink !== 5 && (
            <div className={styles.filterContainer}>
              <div className={styles.searchBox}>
                <input
                  type="text"
                  className={styles.inputSearch}
                  placeholder="Search by Name or User Name"
                  onChange={(e) => {
                    updatePageNumber(1);
                    const inputValue = e.target.value;
                    setQuery(inputValue);
                    if (inputValue === '') {
                      setSearch('');
                      setParams((prevParams) => ({
                        ...prevParams,
                        q: '',
                      }));
                    } else {
                      // Perform the search or any other actions
                      throttledSearch(inputValue);
                    }
                  }}
                  value={query}
                />
              </div>
              {(activeLink === 1 || activeLink === 4) && (
                <div className={styles.filterCheckboxs}>
                  <span className={`${styles.filterSpan} ${styles.filterText}`}>Filter by: </span>

                  {activeLink !== 4 && (
                    <>
                      {/* Patient and Dependent Group */}
                      <span className={styles.filterSpan}>
                        <input
                          className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
                          type="checkbox"
                          name="Patient"
                          id="Patient"
                          value="Patient"
                          checked={filterRole.includes(UserRoleName.Patient)}
                          onChange={(e) => {
                            const { checked } = e.target;
                            updatePageNumber(1);
                            setFilterRole((prevFilterRole) => {
                              if (checked) {
                                return [...prevFilterRole, UserRoleName.Patient];
                              }
                              // Ensure at least one checkbox (Patient/Dependent) is always checked
                              if (!checked && prevFilterRole.includes(UserRoleName.Dependent)) {
                                return prevFilterRole.filter((role) => role !== UserRoleName.Patient);
                              }
                              return prevFilterRole;
                            });
                          }}
                        />
                        <label className={styles.label} htmlFor="Patient">
                          Patient
                        </label>
                      </span>

                      <span className={styles.filterSpan}>
                        <input
                          className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
                          type="checkbox"
                          name="Dependent"
                          id="Dependent"
                          value="Dependent"
                          checked={filterRole.includes(UserRoleName.Dependent)}
                          onChange={(e) => {
                            const { checked } = e.target;
                            updatePageNumber(1);
                            setFilterRole((prevFilterRole) => {
                              if (checked) {
                                return [...prevFilterRole, UserRoleName.Dependent];
                              }
                              // Ensure at least one checkbox (Patient/Dependent) is always checked
                              if (!checked && prevFilterRole.includes(UserRoleName.Patient)) {
                                return prevFilterRole.filter((role) => role !== UserRoleName.Dependent);
                              }
                              return prevFilterRole;
                            });
                          }}
                        />
                        <label className={styles.label} htmlFor="Dependent">
                          Dependent
                        </label>
                      </span>
                    </>
                  )}

                  {/* Admin, Super Admin, Organization Admin Group */}
                  {userInfo.role.name !== UserRoleName.OrganizationAdmin && activeLink === 4 && (
                    <>
                      <span className={styles.filterSpan}>
                        <input
                          className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
                          type="checkbox"
                          name="Admin"
                          id="Admin"
                          value="Admin"
                          checked={filterRole.includes(UserRoleName.Admin)}
                          onChange={(e) => {
                            const { checked } = e.target;
                            updatePageNumber(1);
                            setFilterRole((prevFilterRole) => {
                              if (checked) {
                                return [...prevFilterRole, UserRoleName.Admin];
                              }
                              // Ensure at least one checkbox (Admin/SuperAdmin/OrganizationAdmin) is always checked
                              if (
                                !checked &&
                                (prevFilterRole.includes(UserRoleName.SuperAdmin) ||
                                  prevFilterRole.includes(UserRoleName.OrganizationAdmin))
                              ) {
                                return prevFilterRole.filter((role) => role !== UserRoleName.Admin);
                              }
                              return prevFilterRole;
                            });
                          }}
                        />
                        <label className={styles.label} htmlFor="Admin">
                          Admin
                        </label>
                      </span>

                      <span className={styles.filterSpan}>
                        <input
                          className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
                          type="checkbox"
                          name="SuperAdmin"
                          id="SuperAdmin"
                          value="SuperAdmin"
                          checked={filterRole.includes(UserRoleName.SuperAdmin)}
                          onChange={(e) => {
                            const { checked } = e.target;
                            updatePageNumber(1);
                            setFilterRole((prevFilterRole) => {
                              if (checked) {
                                return [...prevFilterRole, UserRoleName.SuperAdmin];
                              }
                              if (
                                !checked &&
                                (prevFilterRole.includes(UserRoleName.Admin) ||
                                  prevFilterRole.includes(UserRoleName.OrganizationAdmin))
                              ) {
                                return prevFilterRole.filter((role) => role !== UserRoleName.SuperAdmin);
                              }
                              return prevFilterRole;
                            });
                          }}
                        />
                        <label className={styles.label} htmlFor="SuperAdmin">
                          Super Admin
                        </label>
                      </span>

                      <span className={styles.filterSpan}>
                        <input
                          className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
                          type="checkbox"
                          name="OrganizationAdmin"
                          id="OrganizationAdmin"
                          value="OrganizationAdmin"
                          checked={filterRole.includes(UserRoleName.OrganizationAdmin)}
                          onChange={(e) => {
                            const { checked } = e.target;
                            updatePageNumber(1);
                            setFilterRole((prevFilterRole) => {
                              if (checked) {
                                return [...prevFilterRole, UserRoleName.OrganizationAdmin];
                              }
                              if (
                                !checked &&
                                (prevFilterRole.includes(UserRoleName.Admin) ||
                                  prevFilterRole.includes(UserRoleName.SuperAdmin))
                              ) {
                                return prevFilterRole.filter((role) => role !== UserRoleName.OrganizationAdmin);
                              }
                              return prevFilterRole;
                            });
                          }}
                        />
                        <label className={styles.label} htmlFor="OrganizationAdmin">
                          Organization Admin
                        </label>
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          )}

          {!dataLoading && activeLink !== 5 ? (
            users && users.length > 0 ? (
              <>
                <table className={styles.providerDashboardTable}>
                  <thead className={`${styles.providerListTableHeader} ${styles.dashboardHeaderName} ${styles.titles}`}>
                    <tr>
                      {headersState.map((row) => {
                        return (
                          <th key={row.key} onClick={() => changeSort(row.key)}>
                            <span className={`${sortKey === row.key ? styles.bold : ''}`}>
                              {row.label}{' '}
                              {row.isSorted && row.sortDirection === 'asc' ? <HiChevronDown /> : <HiChevronUp />}
                            </span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((userRow) => {
                      return (
                        <React.Fragment key={userRow.id}>
                          <tr
                            className={`${styles.providerListTableBody}`}
                            style={{ backgroundColor: userRow.status === 'Deactivated' ? '#FFF5F5' : 'white' }}
                          >
                            <td>
                              <div
                                style={{
                                  cursor:
                                    userRow.role.name === 'Patient' ||
                                    userRow.role.name === 'Provider' ||
                                    userRow.role.name === 'CareAdvisor'
                                      ? 'pointer'
                                      : '',
                                }}
                                className={`col-md-2 ${styles.providerInfo}`}
                                onClick={() => {
                                  if (
                                    userRow.role.name === 'Patient' ||
                                    userRow.role.name === 'Provider' ||
                                    userRow.role.name === 'CareAdvisor'
                                  ) {
                                    setProfileCardModalUser(userRow);
                                  }
                                }}
                              >
                                <img
                                  alt="Avatar"
                                  onError={(event: any) => {
                                    event.target.src =
                                      // eslint-disable-next-line max-len
                                      'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                                    event.target.onError = null;
                                  }}
                                  className={styles.providerImage}
                                  src={getUserAvatar(userRow.id)}
                                />
                                <div className={styles.mobileInfo}>
                                  <span>{userRow.full_name}</span>
                                </div>
                              </div>
                            </td>
                            <td className={styles.mobileDue}>
                              <div className={`${styles.due}`}>
                                <span className={styles.mobileHeader}>User Name</span>
                                <span
                                  className={styles.mobileDueDate}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    dispatch(
                                      setSelectedPatient({
                                        value: userRow.id.toString(),
                                        label: 'Current Patient',
                                      }),
                                    );
                                    navigate(`/admin/review-profile/${userRow.role.name}/${userRow.id}`);
                                  }}
                                >
                                  {userRow.email}
                                </span>
                              </div>
                            </td>
                            <td className={`${styles.mobileDue} ${styles.duaOnMobile}`}>
                              <div className={`${styles.due}`}>
                                <span className={styles.mobileHeader}>Status</span>
                                <span
                                  className={styles.mobileDueDate}
                                  style={{
                                    color:
                                      userRow.status === 'Deactivated' ||
                                      userRow.status === 'Not Invited' ||
                                      userRow.status === 'Invited'
                                        ? '#FA5151'
                                        : userRow.status === 'Active'
                                        ? '#4DB546'
                                        : '#FF7F00',
                                  }}
                                >
                                  {userRow.status}
                                </span>
                              </div>
                            </td>
                            <td className={styles.mobileCType}>
                              <div className={`col-md-2 ${styles.availability}`}>
                                <span className={styles.mobileHeader}>Type</span>
                                {userRow.role.name}
                              </div>
                            </td>

                            <td className={styles.mobileBtn}>
                              <div className={`${styles.viewBtn}`}>
                                <div
                                  onClick={() => {
                                    dispatch(
                                      setSelectedPatient({
                                        value: userRow.id.toString(),
                                        label: 'Current Patient',
                                      }),
                                    );
                                    navigate(`/admin/review-profile/${userRow.role.name}/${userRow.id}`);
                                  }}
                                >
                                  <span className={styles.viewButton}>View</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className={styles.noStartTime}>
                            <div>
                              <span />
                            </div>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
                <PaginationController
                  limit={10}
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  onPageChange={updatePageNumber}
                />
              </>
            ) : (
              <span className={styles.noData}>No data to display</span>
            )
          ) : activeLink === 5 ? (
            <CasesList />
          ) : (
            <span>
              <Loading dotted />
            </span>
          )}
        </div>
      </div>
      {patientRoleModal && <AddPatientUserModal isOpen={patientRoleModal} toggleModal={setPatientRoleModal} />}
      {providerRoleModal && <AddProviderUserModal isOpen={providerRoleModal} toggleModal={setProviderRoleModal} />}
      {caRoleModal && <AddCareAdvisorUserModal isOpen={caRoleModal} toggleModal={setCARoleModal} />}
      {adminRoleModal && (
        <AddAdminUserModal isOpen={adminRoleModal} toggleModal={setAdminRoleModal} role={selectedRole} />
      )}
      {organizationAdminRoleModal && (
        <AddOrganizationAdminUserModal
          isOpen={organizationAdminRoleModal}
          toggleModal={setOrganizationAdminRoleModal}
          role="OrganizationAdmin"
        />
      )}
      {profileCardModalUser?.role.name === 'Patient' && (
        <PatientProfileCardModal
          patientIdentity={profileCardModalUser}
          isOpen={profileCardModal}
          toggleModal={setProfileCardModal}
        />
      )}
      {profileCardModalUser?.role.name === 'Provider' && (
        <ProviderProfileCardModal
          isOpen={profileCardModal}
          toggleModal={setProfileCardModal}
          providerIdentity={profileCardModalUser}
        />
      )}
      {profileCardModalUser?.role.name === 'CareAdvisor' && (
        <CareAdvisorProfileCardModal
          isOpen={profileCardModal}
          toggleModal={setProfileCardModal}
          caIdentity={profileCardModalUser}
        />
      )}
    </Container>
  );
};

export default UserSearch;
