import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/useReduxHook';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import OralHealthReportComponent from './OralHealthReport';

const OralHealthReportValidation: React.FC = (): any => {
  const user: AuthState = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const goToDashboard = useCallback(() => navigate('/dashboard'), [navigate]);

  if (
    user.userInfo?.role.name === UserRoleName.CareAdvisor ||
    user.userInfo?.role.name === UserRoleName.Provider ||
    user.userInfo?.role.name === UserRoleName.Patient ||
    user.userInfo?.role.name === UserRoleName.Admin ||
    user.userInfo?.role.name === UserRoleName.SuperAdmin ||
    user.userInfo?.role.name === UserRoleName.OrganizationAdmin
  ) {
    return (
      <div>
        <OralHealthReportComponent />
      </div>
    );
  }
  return goToDashboard();
};

export default OralHealthReportValidation;
