import axios from 'axios';

import { config } from '../../config/config';

export interface UserValidationPayload {
  org: string;
  email?: string;
  phone_number?: string;
}

interface UserValidationResponse {
  success: boolean;
}

export const userValidation = async (payload: UserValidationPayload): Promise<boolean> => {
  const { data } = await axios.post<UserValidationResponse>(`${config.api.baseUrl}/users/validate`, payload, {
    headers: {
      'x-organization-id': config.organization_id,
    },
  });

  return data.success;
};
