import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import { useEffect, useRef, useState } from 'react';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';

import { Callback } from '../../services/communication/types/VideoRoom';
import { DataTrackManager } from '../../Utils/dataTrack';
import { useAppSelector } from '../useReduxHook';

interface IUseRoom {
  room: Video.Room | null;
  isConnecting: boolean;
  connect: (token: string, localTrack: LocalTrack[]) => Promise<void>;
}

const useRoom = (onError: Callback, options: ConnectOptions): IUseRoom => {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const { is_visible: isVisible } = useAppSelector(selectVideoCallCredentials);
  const optionsRef = useRef(options);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  useEffect(() => {
    if (isVisible) room?.refreshInactiveMedia();
  }, [room]);

  const connect = async (token: string, localTrack: LocalTrack[]): Promise<void> => {
    setIsConnecting(true);

    DataTrackManager.reset();
    await Video.connect(token, { ...optionsRef.current, tracks: [...localTrack, DataTrackManager.dataTrack] }).then(
      (newRoom) => {
        setRoom(newRoom);

        newRoom.setMaxListeners(16);

        newRoom.localParticipant.videoTracks.forEach((publication) => publication.setPriority('low'));
        newRoom.localParticipant.on('trackPublished', (publication) => {
          if (publication.track === DataTrackManager.dataTrack) {
            if (DataTrackManager.dataTrackPublished.resolve) {
              DataTrackManager.dataTrackPublished.resolve();
            }
          }
        });
        newRoom.localParticipant.on('trackPublicationFailed', (error, track) => {
          if (track === DataTrackManager.dataTrack) {
            if (DataTrackManager.dataTrackPublished.reject) {
              DataTrackManager.dataTrackPublished.reject(error);
            }
          }
        });

        setIsConnecting(false);
      },
      (error) => {
        onError(error);
        setIsConnecting(false);
      },
    );
  };

  return {
    room,
    isConnecting,
    connect,
  };
};

export default useRoom;
