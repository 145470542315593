import { ProviderProfile, UserProfile } from '@brands/services/identity/types/UserProfile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  userInfo: UserProfile | null;
}

const initialState: AuthState = {
  userInfo: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ userInfo: UserProfile | null }>) => {
      return { userInfo: action.payload.userInfo };
    },
    updateAvailabilities: (
      state,
      action: PayloadAction<{ availabilities: string[]; currentProviderProfile: ProviderProfile }>,
    ) => {
      if (state.userInfo) {
        Object.assign(state.userInfo, {
          provider_profile: {
            ...action.payload.currentProviderProfile,
            availability: action.payload.availabilities,
          },
        });
      }
    },
    setNotificationSettings: (state, action: PayloadAction<{ sms: boolean; email: boolean }>) => {
      if (state.userInfo) {
        Object.assign(state.userInfo, {
          sms_opt_in: action.payload.sms,
          email_opt_in: action.payload.email,
          provider_profile: {
            ...state.userInfo.provider_profile,
            sms_opt_in: action.payload.sms,
            email_opt_in: action.payload.email,
          },
        });
      }
    },
  },
});

export const { setUser, updateAvailabilities, setNotificationSettings } = authSlice.actions;

export default authSlice.reducer;
