export const getPrimaryColor = (): string => {
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
  return primaryColor.trim();
};

export const getHoverColor = (): string => {
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--btn_hover_color2');
  return primaryColor.trim();
};

export const getSecondaryColor = (): string => {
  const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary_color');
  return secondaryColor.trim();
};

export const getSecondary = (): string => {
  const secondary = getComputedStyle(document.documentElement).getPropertyValue('--secondary');
  return secondary.trim();
};
