import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import { SnackbarProvider } from 'notistack';
import React, { FC } from 'react';

import { Card } from './assets/types';
import IdleTimer from './brands/default/Components/IdleTimer';
import Toast from './brands/default/Components/ToastContainer/ToastContainer';
import { config } from './brands/default/config/config';
import VideoModal from './brands/default/Dashboard/ConsultationPage/Modals/VideoModal/VideoModal';
import { useAppSelector } from './brands/default/hooks/useReduxHook';
import useScript from './brands/default/hooks/useScript';
import { UserRoleName } from './brands/default/services/identity/types/UserProfile';
import Routing from './Routes/Routes';

declare global {
  interface Window {
    dataLayer: any[];
    Square: {
      payments: (appId: string, locationId: string) => { card: () => Promise<Card> };
    };
  }
}

const App: FC = () => {
  const userData: AuthState = useAppSelector(selectAuth);
  useScript(config.square.src);

  return (
    <>
      <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} maxSnack={3} preventDuplicate>
        <IdleTimer />
        {userData.userInfo?.role.name !== UserRoleName.Patient && <VideoModal />}
        <Routing />
      </SnackbarProvider>
      <Toast />
    </>
  );
};
export default App;
