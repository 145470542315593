/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import CustomDropdown from '@brands/Components/Inputs/CustomDropdown/CustomDropdown';
import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import TextArea from '@brands/Components/Inputs/TextArea/TextArea';
import TextField from '@brands/Components/Inputs/TextField/TextField';
import { MedicalHistorySubmit } from '@brands/Dashboard/MedicalHistory/MedicalHistory/utils/types';
import { PharmacySubmit } from '@brands/Dashboard/MyProfile/Patient/Sections/Pharmacy/EditPharmacy';
import { pharmacyValidationSchema } from '@brands/Dashboard/MyProfile/Patient/utils/validationSchema';
import { useAppDispatch, useAppSelector } from '@brands/hooks/useReduxHook';
import { ICase } from '@brands/services/cases/types/ICase';
import { getForm } from '@brands/services/forms/getForm';
import { FormAnswerValue, getFormAnswer } from '@brands/services/forms/getFormAnswer';
import { listForms } from '@brands/services/forms/listForms';
import { FormQuestion } from '@brands/services/forms/types/FormQuestion';
import { UserProfile } from '@brands/services/identity/types/UserProfile';
import { ObjectType, updateOneReportObject } from '@brands/services/oneReport/updateOneReportObject';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { addFormItem } from '@brands/store/slices/formQuestionsSlice';
import { consultationPharmacySectionSelectStyles } from '@brands/Utils/customStyles';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { checkCollapsibleHeaderColor } from '@brands/Utils/getCollapsibleHeader';
import { Option, stateOptions } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type PharmacySectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  medicalHistoryForm: Record<string, { type: string; value: string }>;
  thisUser: UserProfile;
  loading: boolean;
  dependentId: number | undefined;
};

const PharmacySection = ({
  consultationForm,
  setConsultationForm,
  medicalHistoryForm,
  thisCase,
  thisUser,
  loading,
  dependentId,
}: PharmacySectionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient } = useAppSelector(selectPatientForm);
  const [formId, setFormId] = useState(0);
  const [questions, setQuestions] = React.useState<FormQuestion[]>();
  const [defaultValues, setDefaultValues] = useState<MedicalHistorySubmit>({});
  const [formAnswerId, setFormAnswerId] = useState<number | null>(null);
  const [currentMedicalHistoryForm, setCurrentMedicalHistoryForm] = useState<MedicalHistorySubmit>({
    ...defaultValues,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  function getEnhancedGivingOptions(options: Option[]): any {
    return [{ value: 'noState', label: 'Select a state' }, ...options];
  }

  const enhancedGivingOptions = getEnhancedGivingOptions(stateOptions);

  const {
    register,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<PharmacySubmit>({
    mode: 'onChange',
    resolver: yupResolver(pharmacyValidationSchema()),
  });

  const checkAllPharmacyInputsAreEmpty = (): boolean => {
    if (thisCase.one_report.pharmacies) {
      const {
        id,
        pharmacy_name,
        pharmacy_city,
        pharmacy_state,
        pharmacy_street_address,
        pharmacy_phone_number,
        pharmacy_zip_code,
      } = thisCase.one_report.pharmacies;
      if (
        id &&
        pharmacy_name.trim() === '' &&
        pharmacy_city.trim() === '' &&
        pharmacy_state.trim() === '' &&
        pharmacy_street_address.trim() === '' &&
        pharmacy_phone_number.trim() === '' &&
        pharmacy_zip_code.trim() === ''
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    if (Object.keys(medicalHistoryForm).length > 0 && checkAllPharmacyInputsAreEmpty()) {
      const pharmacyInfo: Record<string, unknown> = Object.entries(medicalHistoryForm)
        .filter(([key]) => key.startsWith('Pharmacy'))
        .reduce((acc: Record<string, unknown>, [key, value]) => {
          const lowerCaseKey = key.toLowerCase().replace(/\s+/g, '_') as keyof PharmacySubmit;
          acc[lowerCaseKey] = value.value;
          return acc;
        }, {});

      Object.keys(pharmacyInfo).forEach((key) => {
        setValue(key as keyof PharmacySubmit, pharmacyInfo[key as keyof PharmacySubmit] as string, {
          shouldValidate: !loading,
        });
      });
      setConsultationForm({
        ...consultationForm,
        pharmacies: getValues(),
      });
      updatePharmacy(thisCase.one_report.pharmacies.id || '1', getValues());
    } else {
      setConsultationForm({
        ...consultationForm,
        pharmacies: thisCase.one_report.pharmacies,
      });
    }
  }, [medicalHistoryForm, setValue, thisCase]);

  const getFormAnswers = useCallback(
    async (formIds: number, defaultVal: MedicalHistorySubmit) => {
      let formAnsId: number | null = null;
      const temporaryValues = { ...defaultVal };
      const formAnswers = await getFormAnswer({
        form_id: formIds,
        created_by_id: dependentId || Number(selectedPatient?.value),
      });

      Object.values(formAnswers.form_answers)
        .sort((a, b) => a.id - b.id)
        .every((answer) => {
          let check = true;
          Object.values(answer.values)
            .sort((a, b) => a.form_question_id - b.form_question_id)
            .forEach((value: FormAnswerValue) => {
              if (Object.keys(temporaryValues).includes(`question_${value.form_question_id}`)) {
                temporaryValues[`question_${value.form_question_id}`] = value.value.data;
                check = false;
                formAnsId = answer.id;
              }
            });
          return check;
        });
      return { formAnswerIdentity: formAnsId, formObj: temporaryValues };
    },
    [defaultValues, dispatch, formId, selectedPatient?.value],
  );

  useEffect(() => {
    (async () => {
      let defaultFormValues = {};
      const { forms: formList } = await listForms();
      const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
      setFormId(newFormId);
      await getForm(newFormId.toString()).then((res) => {
        if (res) {
          defaultFormValues = res.steps[0].questions.reduce((acc: MedicalHistorySubmit, question: FormQuestion) => {
            return {
              ...acc,
              [`question_${question.id}`]: 'no',
            };
          }, {});
          setDefaultValues(defaultFormValues);
          dispatch(
            addFormItem({
              form_id: res.id,
              step_id: res.steps[0].id,
              questions: res.steps[0].questions,
            }),
          );
        }
        setQuestions(res?.steps[0].questions);
      });
      const { formAnswerIdentity, formObj } = await getFormAnswers(newFormId, defaultFormValues);
      setFormAnswerId(formAnswerIdentity);
      setCurrentMedicalHistoryForm(formObj);
    })();
  }, [thisUser]);

  const updatePharmacy = async (id: string, nonEmptyPayload: PharmacySubmit): Promise<void> => {
    try {
      const res = await updateOneReportObject(thisCase?.one_report.id || '', ObjectType.Pharmacy, {
        ...nonEmptyPayload,
        id,
      });
      setConsultationForm({
        ...consultationForm,
        pharmacies: res.pharmacies,
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const checkAtLeastOneFieldIsFilled = (pharmacy: PharmacySubmit): boolean => {
    if (pharmacy) {
      const {
        pharmacy_name,
        pharmacy_street_address,
        pharmacy_city,
        pharmacy_state,
        pharmacy_zip_code,
        pharmacy_phone_number,
      } = pharmacy;
      if (
        pharmacy_name?.trim() !== '' ||
        pharmacy_street_address?.trim() !== '' ||
        pharmacy_city?.trim() !== '' ||
        pharmacy_state?.trim() !== '' ||
        pharmacy_zip_code?.trim() !== '' ||
        pharmacy_phone_number?.trim() !== ''
      ) {
        return true;
      }
    }
    return false;
  };

  const handlePharmacyOnBlur = async (pharmacy: PharmacySubmit): Promise<void> => {
    const { id } = pharmacy;
    const nonEmptyPayload = {
      ...(pharmacy.pharmacy_name ? { pharmacy_name: pharmacy.pharmacy_name } : { pharmacy_name: '' }),
      ...(pharmacy.pharmacy_street_address
        ? { pharmacy_street_address: pharmacy.pharmacy_street_address }
        : { pharmacy_street_address: '' }),
      ...(pharmacy.pharmacy_city ? { pharmacy_city: pharmacy.pharmacy_city } : { pharmacy_city: '' }),
      ...(pharmacy.pharmacy_state ? { pharmacy_state: pharmacy.pharmacy_state } : { pharmacy_state: '' }),
      ...(pharmacy.pharmacy_zip_code ? { pharmacy_zip_code: pharmacy.pharmacy_zip_code } : { pharmacy_zip_code: '' }),
      ...(pharmacy.pharmacy_phone_number
        ? { pharmacy_phone_number: pharmacy.pharmacy_phone_number }
        : { pharmacy_phone_number: '' }),
    };

    if (id !== undefined && checkAtLeastOneFieldIsFilled(pharmacy)) {
      await updatePharmacy(id, nonEmptyPayload);
    }
  };

  return (
    <div className={styles.descriptionInputs}>
      <div className={styles.selectInput}>
        <div className={styles.title}>Pharmacy Name*</div>
        <TextArea
          {...register(`pharmacy_name`)}
          wrapperStyle={{
            marginTop: '0',
            borderBottom: 'none',
            minHeight: '30px',
            height: 'auto',
          }}
          inputClass={styles.input}
          placeholder=""
          isUnderlined={false}
          value={consultationForm?.pharmacies?.pharmacy_name ?? ''}
          onChange={(e) => {
            setValue('pharmacy_name', e.target.value, { shouldValidate: true });
            setConsultationForm({
              ...consultationForm,
              pharmacies: {
                ...consultationForm?.pharmacies,
                pharmacy_name: e.target.value,
              },
            });
          }}
          onBlur={(e: any) =>
            handlePharmacyOnBlur({
              ...consultationForm.pharmacies,
              pharmacy_name: e.target.value,
            })
          }
          disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
        />
      </div>
      <div className={styles.selectInput}>
        <div className={styles.title}>Street Address*</div>
        <TextField
          {...register('pharmacy_street_address')}
          wrapperStyle={{
            borderBottom: 'none',
          }}
          errors={errors}
          name="pharmacy_street_address"
          value={consultationForm?.pharmacies?.pharmacy_street_address ?? ''}
          onChange={(e) => {
            setValue('pharmacy_street_address', e.target.value, { shouldValidate: true });
            setConsultationForm({
              ...consultationForm,
              pharmacies: {
                ...consultationForm?.pharmacies,
                pharmacy_street_address: e.target.value,
              },
            });
          }}
          onBlur={(e: any) =>
            handlePharmacyOnBlur({
              ...consultationForm.pharmacies,
              pharmacy_street_address: e.target.value,
            })
          }
          wrapperClassName={styles.inputWrapper}
          inputClass={styles.input}
          disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
        />
      </div>
      <div className={styles.descriptionInputsFirstGroup}>
        <div className={`${styles.selectInput}`}>
          <div className={styles.title}>City*</div>
          <div className={styles.input}>
            <TextArea
              {...register(`pharmacy_city`)}
              wrapperStyle={{
                marginTop: '0',
                borderBottom: 'none',
                minHeight: '30px',
                height: 'auto',
              }}
              inputClass={styles.input}
              placeholder=""
              isUnderlined={false}
              value={consultationForm?.pharmacies?.pharmacy_city ?? ''}
              onChange={(e) => {
                setValue('pharmacy_city', e.target.value, { shouldValidate: true });
                setConsultationForm({
                  ...consultationForm,
                  pharmacies: {
                    ...consultationForm?.pharmacies,
                    pharmacy_city: e.target.value,
                  },
                });
              }}
              onBlur={(e: any) =>
                handlePharmacyOnBlur({
                  ...consultationForm.pharmacies,
                  pharmacy_city: e.target.value,
                })
              }
              disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
            />
          </div>
        </div>
        <div className={`${styles.selectInput}`}>
          <div className={styles.title}>State*</div>
          <div className={styles.input}>
            <CustomDropdown
              {...register(`pharmacy_state`)}
              styles={consultationPharmacySectionSelectStyles}
              placeholder=""
              menuPortalTarget={document.body}
              menuPlacement="auto"
              options={enhancedGivingOptions}
              closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
              value={
                !consultationForm?.pharmacies?.pharmacy_state
                  ? enhancedGivingOptions[0]
                  : enhancedGivingOptions.find(
                      (option: Option) => option.value === consultationForm.pharmacies.pharmacy_state,
                    )
              }
              onChange={(value: any) => {
                if (value) {
                  setValue('pharmacy_state', value.value === 'noState' ? '' : value.value, {
                    shouldValidate: true,
                  });
                  if (setConsultationForm) {
                    setConsultationForm({
                      ...consultationForm,
                      pharmacies: {
                        ...consultationForm?.pharmacies,
                        pharmacy_state: value.value,
                      },
                    });
                  }
                }
              }}
              onBlur={(e: any) =>
                handlePharmacyOnBlur({
                  ...consultationForm.pharmacies,
                  pharmacy_state: e.target.value,
                })
              }
              isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
              isConsultationPharmacySection
            />
          </div>
        </div>
        <div className={`${styles.selectInput}`}>
          <div className={styles.title}>Zip Code*</div>
          <div className={styles.input}>
            <TextArea
              {...register(`pharmacy_zip_code`)}
              wrapperStyle={{
                marginTop: '0',
                borderBottom: 'none',
              }}
              inputClass={styles.input}
              placeholder=""
              isUnderlined={false}
              value={consultationForm?.pharmacies?.pharmacy_zip_code ?? ''}
              onChange={(e) => {
                setValue('pharmacy_zip_code', e.target.value, { shouldValidate: true });
                setConsultationForm({
                  ...consultationForm,
                  pharmacies: {
                    ...consultationForm?.pharmacies,
                    pharmacy_zip_code: e.target.value,
                  },
                });
              }}
              onBlur={(e: any) =>
                handlePharmacyOnBlur({
                  ...consultationForm.pharmacies,
                  pharmacy_zip_code: e.target.value,
                })
              }
              disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
            />
          </div>
        </div>
      </div>
      <div className={styles.selectInput}>
        <div className={styles.title}>Phone Number*</div>
        <PhoneInput
          {...register('pharmacy_phone_number')}
          value={consultationForm?.pharmacies?.pharmacy_phone_number ?? ''}
          id="pharmacy_phone_number"
          onChange={(val) => {
            setValue('pharmacy_phone_number', (val as string) ?? '', {
              shouldValidate: true,
            });
            setConsultationForm({
              ...consultationForm,
              pharmacies: {
                ...consultationForm?.pharmacies,
                pharmacy_phone_number: val as string,
              },
            });
          }}
          onBlur={(e: any) =>
            handlePharmacyOnBlur({
              ...consultationForm.pharmacies,
              pharmacy_phone_number: e.target.value,
            })
          }
          inputClass={styles.input}
          errors={errors}
          wrapperClassName={styles.inputWrapper}
          isUnderlined={false}
          disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
        />
      </div>
    </div>
  );
};

export default PharmacySection;
