import React, { forwardRef } from 'react';
import Select, { createFilter, GroupBase, OptionProps, SelectComponentsConfig } from 'react-select';

import { selectCommonCustomStylesSmall } from '../../Utils/customStyles';
import { getPrimaryColor } from '../../Utils/getPrimaryColor';
import { Option } from '../../Utils/selectOptions';
import styles from './styles.module.scss';

type TimeInputProps = {
  timeOptions: Option[];
  time: Option | null;
  disabled?: boolean;
  onChange?: (val: Option) => void;
};

const getComponents = (): unknown => ({
  Option: (props: OptionProps<Option[]>) => {
    const { isFocused, innerProps, innerRef, label } = props;
    const style = isFocused
      ? {
          backgroundColor: getPrimaryColor(),
          color: 'white',
          padding: '5px',
          fontSize: '14px',
        }
      : { padding: '5px', fontSize: '14px' };
    return (
      <div {...innerProps} ref={innerRef} style={style} className={styles.selectOptions}>
        {label}
      </div>
    );
  },
});

const TimeInput = forwardRef(({ timeOptions, time, disabled, onChange }: TimeInputProps, ref): JSX.Element => {

  const selectedTime =
    time?.value && !timeOptions.find((option) => option.value === time.value)
      ? time
      : timeOptions.find((option) => option.value === time?.value) || null;

  return (
    <div className={styles.timePicker} key={time?.id} ref={ref as React.RefObject<HTMLDivElement>}>
      <div className={styles.timePickerContainer}>
        <div className={styles.timePickerInput}>
          <div className={styles.timePickerSelector}>
            <Select
              onChange={(value) => {
                if (value) {
                  if (onChange) {
                    onChange(value);
                  }
                }
              }}
              value={selectedTime}
              styles={selectCommonCustomStylesSmall}
              isClearable={false}
              menuPortalTarget={document.body}
              options={timeOptions}
              components={
                getComponents() as Partial<SelectComponentsConfig<Option, false, GroupBase<Option>>> | undefined
              }
              isDisabled={disabled}
              placeholder=""
              filterOption={createFilter({ matchFrom: 'start' })}
              menuPlacement="auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

TimeInput.defaultProps = {
  disabled: false,
  onChange: () => {},
};

export default TimeInput;
