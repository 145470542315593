/* eslint-disable no-param-reassign */
// showVideoThumbnailSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ShowVideoThumbnailState {
  showVideoThumbnail: boolean;
}

const initialState: ShowVideoThumbnailState = {
  showVideoThumbnail: false,
};

const showVideoThumbnailSlice = createSlice({
  name: 'videoThumbnail',
  initialState,
  reducers: {
    setShowVideoThumbnail: (state, action: PayloadAction<boolean>) => {
      state.showVideoThumbnail = action.payload;
    },
    toggleVideoThumbnail: (state) => {
      state.showVideoThumbnail = !state.showVideoThumbnail;
    },
  },
});

export const { setShowVideoThumbnail, toggleVideoThumbnail } = showVideoThumbnailSlice.actions;

export default showVideoThumbnailSlice.reducer;
