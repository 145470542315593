import { client } from '../client';
import { ClientTag } from './types/UserProfile';

export interface IClientTag {
  nodes: ClientTag[];
}

export const listAllClientTags = async (): Promise<IClientTag> => {
  const { data } = await client.get('/admin/clients');

  return data;
};
