import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import SelectInput from '../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../Components/Inputs/TextField/TextField';
import useWindowSize from '../../../../hooks/useWindowSize';
import { listInsuranceCarriers } from '../../../../services/identity/listInsuranceCarriers';
import { selectCommonCustomStylesLarge } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { OptionInsurance } from '../../../../Utils/selectOptions';
import styles from './styles.module.scss';
import { ScheduleAppointmentFormSubmit } from './utils/types';
import { validationSchema } from './utils/validationSchema';

const ScheduleAppointment = (): JSX.Element => {
  const screenSize = useWindowSize();
  const navigate = useNavigate();
  const { selectedPatient } = useAppSelector(selectPatientForm);
  const [insuranceCarrierOptions, setInsuranceCarrierOptions] = useState<OptionInsurance[]>([]);

  const fetchInsuranceCarrierOptions = async (): Promise<void> => {
    try {
      const data = await listInsuranceCarriers();
      const transformedData = data.map((insuranceCarrier) => ({
        value: insuranceCarrier.id,
        label: insuranceCarrier.name,
        id: insuranceCarrier.id,
      }));
      transformedData.unshift({
        value: 0,
        label: "I don't see my carrier",
        id: 0,
      });
      setInsuranceCarrierOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    fetchInsuranceCarrierOptions();
  }, []);
  const onSubmit = (data: ScheduleAppointmentFormSubmit): void => {
    navigate('/select-date-time', {
      state: {
        caseType: 'video_call_scheduled',
        insuranceCarrierId: data.insurance_carrier_id || '',
        customInsuranceCarrier: data.custom_carrier || '',
        patientId: selectedPatient?.value,
      },
    });
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(Object.values(errors).length > 1 ? 'Please select a day and time' : (error.message as string));
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<ScheduleAppointmentFormSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        overflow: 'hidden',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width < 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: screenSize.width > 768 ? '40px' : '16px' }}
    >
      <div className={styles.scheduleAppointmentContainer}>
        <section className={styles.backButtonContainer}>
          <button
            type="button"
            className={styles.backButton}
            onClick={() => {
              navigate(-1);
            }}
          >
            <MdKeyboardArrowLeft />
            Back
          </button>
        </section>
        <div className={`${styles.title} d-flex mt-3`}>Select your dental insurance</div>
        <div className={styles.planNotes}>
          <span>Not all carriers are covered.</span>
          <span>If you don’t see your carrier you may still self pay per visit.</span>
        </div>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <section className={styles.cardsContainer}>
            <div className={styles.row}>
              <div className={cn(styles.rowItem, styles.rowItemHalf)}>
                <div className={styles.caption}>
                  Insurance Carrier <span>*</span>
                </div>
                <SelectInput
                  {...register('insurance_carrier_id')}
                  name="insurance_carrier_id"
                  options={insuranceCarrierOptions}
                  containerClass={styles.inputWrapper}
                  selectorClass={styles.selector}
                  style={selectCommonCustomStylesLarge}
                  errors={errors}
                  selectedValue={getValues('insurance_carrier_id')}
                  onChange={(value) => {
                    if (value) {
                      setValue('insurance_carrier_id', value.value, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              </div>
            </div>
            {getValues('insurance_carrier_id') !== undefined && getValues('insurance_carrier_id') === 0 && (
              <div className={styles.row}>
                <div className={cn(styles.rowItem, styles.rowItemHalf)}>
                  <div className={styles.caption}>
                    Please enter your carrier below. Although we don't currently accept your insurance, we are working
                    on adding more carriers in the future
                  </div>
                  <TextField
                    {...register('custom_carrier')}
                    errors={errors}
                    name="custom_carrier"
                    value={getValues('custom_carrier')}
                    onChange={(e) => setValue('custom_carrier', e.target.value)}
                    wrapperClassName={styles.inputWrapper}
                    inputClass={styles.input}
                  />
                </div>
              </div>
            )}
          </section>
          <span className={styles.smallNote}>
            <span>No insurance coverage?</span>
            <span
              onClick={() =>
                navigate('/select-date-time', {
                  state: {
                    caseType: 'video_call_scheduled',
                    insuranceCarrierId: '',
                    insuranceCarrier: undefined,
                    customInsuranceCarrier: undefined,
                    patientId: selectedPatient?.value,
                  },
                })
              }
            >
              {' '}
              You can also pay per visit
              <MdKeyboardArrowRight />
            </span>
          </span>
          <div className={styles.btnContainer}>
            <Button
              type="submit"
              className={styles.submitBtn}
              disabled={getValues('insurance_carrier_id') === undefined}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default ScheduleAppointment;
