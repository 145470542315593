/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
import TextField from '@brands/Components/Inputs/TextField/TextField';
import React, { Dispatch, FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface Validator {
  id: number;
  form_question_id: number;
  type: string;
  operator: string;
  value: string;
  custom_error_message: string;
  created_at: string;
  updated_at: string | null;
}

interface QuestionAndTextFieldProps {
  element: {
    id: number;
    form_id: number;
    form_step_id: number;
    title: string;
    is_required: boolean;
    is_conditional: boolean;
    type: string;
    options: string[] | [] | null;
    validators: Validator[] | [];
    created_at: string;
    updated_at: string | null;
  };
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
  questionTitle?: string;
  medicalCondition?: boolean;
  noneOfTheAboveFlag?: boolean;
  noneOfTheAboveQuestionId?: number | undefined;
}

const QuestionAndTextField: FC<QuestionAndTextFieldProps> = React.forwardRef(
  ({ element, form, setForm, questionTitle, medicalCondition, noneOfTheAboveFlag, noneOfTheAboveQuestionId }, ref) => {
    const [enteredValue, setEnteredValue] = useState('');
    useEffect(() => {
      if (!form[`question_${element.id}`] || form[`question_${element.id}`] === 'no') {
        setEnteredValue('');
      } else {
        setEnteredValue(form[`question_${element.id}`]);
      }
    }, []);
    useEffect(() => {
      if (enteredValue && enteredValue.trim() !== '') {
        setForm((prev: any) => ({
          ...prev,
          [`question_${element.id}`]: enteredValue,
          ...(noneOfTheAboveQuestionId && { [`question_${noneOfTheAboveQuestionId}`]: 'no' }),
        }));
      } else {
        setForm((prev: any) => ({
          ...prev,
          [`question_${element.id}`]: '',
        }));
      }
    }, [enteredValue]);

    useEffect(() => {
      if (noneOfTheAboveFlag && noneOfTheAboveQuestionId) {
        setEnteredValue(form[`question_${element.id}`] === 'no' ? '' : form[`question_${element.id}`]);
      }
    }, [form[`question_${element.id}`]]);

    useEffect(() => {
      if (noneOfTheAboveFlag && noneOfTheAboveQuestionId) {
        setForm((prev: any) => {
          const updatedForm = { ...prev };
          Object.keys(updatedForm).forEach((key: string) => {
            if (key.startsWith('question_') && key) {
              updatedForm[key] = 'no';
            }
          });
          updatedForm[`question_${noneOfTheAboveQuestionId}`] = 'yes';
          return updatedForm;
        });
      }
    }, [noneOfTheAboveFlag]);

    return (
      <div className={`${styles.row} row ${styles.medicalFormRow}`}>
        <div className={styles.inputsDiv}>
          <div className={styles.rowItem}>
            <span
              className={questionTitle && styles.mainTitle}
              style={{ opacity: questionTitle !== '' || medicalCondition ? '1' : '0.5' }}
            >
              {element.title.startsWith('Pharmacy') ? element.title.replace(/^Pharmacy\s*/, '') : element.title}
              {element.is_required ? <span className={styles.redText}> *</span> : ''}
            </span>
            {questionTitle !== '' && <span className={styles.subTitle}>{questionTitle}</span>}
            <TextField
              name={`question_${element.id}`}
              value={enteredValue || ''}
              onChange={(e) => setEnteredValue(e.target.value)}
              wrapperClassName={styles.inputWrapper}
              inputClass={styles.input}
            />
          </div>
        </div>
      </div>
    );
  },
);

QuestionAndTextField.defaultProps = {
  questionTitle: '',
  medicalCondition: false,
  noneOfTheAboveFlag: false,
  noneOfTheAboveQuestionId: undefined,
};

export default QuestionAndTextField;
