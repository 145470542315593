/* eslint-disable @typescript-eslint/explicit-function-return-type */
import useScreenShareParticipant from './useScreenShareParticipant';
import useVideoContext from './useVideoContext';

export default function useMainParticipant() {
  const { room } = useVideoContext();
  const screenShareParticipant = useScreenShareParticipant();
  const localParticipant = room?.localParticipant;
  const remoteScreenShareParticipant = screenShareParticipant !== localParticipant ? screenShareParticipant : null;

  return remoteScreenShareParticipant;
}
