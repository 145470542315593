/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useAppDispatch, useAppSelector } from '@brands/hooks/useReduxHook';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectDependents } from '@brands/store/selectors/dependents';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setExternalForms, setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import React, { forwardRef, useEffect } from 'react';
import { GroupBase, SingleValue, StylesConfig } from 'react-select';

import { selectCommonCustomStylesLarge } from '../../../Utils/customStyles';
import styles from './styles.module.scss';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { getUserById } from '@brands/services/identity/getUserById';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { getUserAvatar } from '@brands/services/identity/getUserAvatar';
import SelectInputWithImage from '../SelectInput/SelectInputWithImage';

interface Option {
  value: string;
  label: string;
  image?: string;
}

interface SelectorProps {
  name: string;
  activeOnly?: boolean;
  title?: string;
  containerClass?: string;
  style?: StylesConfig<Option, false, GroupBase<Option>> | undefined;
  addDependent?: boolean;
  setAddDependentModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PatientSelector = forwardRef(
  (
    { name, activeOnly, title, containerClass, style, addDependent, setAddDependentModal }: SelectorProps,
    ref,
  ): JSX.Element => {
    const dispatch = useAppDispatch();
    const { userInfo } = useAppSelector(selectAuth);
    const { selectedPatient } = useAppSelector(selectPatientForm);
    const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
    const { dependents } = useAppSelector(selectDependents);
    const [patientOptions, setPatientOptions] = React.useState<SingleValue<Option>[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<SingleValue<Option> | null>(null);
    const [key, setKey] = React.useState<number>(0);

    const checkExternalForms = async (): Promise<void> => {
      const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
        await checkExternalFormAnswers(
          userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
            ? currentPatient
            : userInfo,
          selectedPatient?.value,
          null,
        );
      if (!checkProfile && !checkMedicalHistory) {
        dispatch(setExternalForms(['patient_profile', 'patient_medical_history']));
      } else if (!checkProfile) {
        dispatch(setExternalForms(['patient_profile']));
      } else if (!checkMedicalHistory) {
        dispatch(setExternalForms(['patient_medical_history']));
      } else if (!checkMedicalConditionsFormAnswer) {
        dispatch(setExternalForms(['patient_medical_conditions']));
      } else if (!checkDentalHistoryFormAnswer) {
        dispatch(setExternalForms(['patient_dental_history']));
      } else {
        dispatch(setExternalForms([]));
      }
    };
    const defaultPatient = {
      value:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? selectedPatient?.value.toString() ?? ''
          : userInfo.id.toString(),
      label:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? 'Current Patient'
          : 'Myself',
      status: 'Active',
      image: getUserAvatar(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? Number(selectedPatient?.value)
          : Number(userInfo.id),
      ),
    };

    useEffect(() => {
      const fetchAndSetPatientOptions = async () => {
        setIsLoading(true);
        if (userInfo.id) {
          // Filter dependents based on the activeOnly flag
          const filteredDependents = activeOnly
            ? dependents.filter((dependent) => dependent?.status === 'Active')
            : dependents;

          const dependentsWithImages = await Promise.all(
            filteredDependents.map(async (dependent) => ({
              ...dependent,
              label: dependent?.label ?? '',
              value: dependent?.value ?? '',
              image: dependent?.image,
            })),
          );
          setPatientOptions([defaultPatient, ...dependentsWithImages]);
        }
        setIsLoading(false);
      };

      fetchAndSetPatientOptions();
    }, [dependents, activeOnly, userInfo.id]);

    useEffect(() => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        dispatch(
          setSelectedPatient({
            value: selectedPatient?.value.toString(),
            label: 'Current Patient',
            image: getUserAvatar(Number(selectedPatient?.value)),
          }),
        );
      }
    }, []);

    const startPatientForm = async (): Promise<void> => {
      await checkExternalForms();
    };

    const updateSelectedPatientByAdmin = async (id: string): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        const selectedPatientAdmin = await getUserById(Number(id));
        dispatch(setSelectedPatientByAdmin({ selectedPatient: selectedPatientAdmin }));
      }
    };

    useEffect(() => {
      setValue(selectedPatient);
      setKey(key + 1);
      updateSelectedPatientByAdmin(selectedPatient?.value);
    }, [selectedPatient]);

    return (
      <div className={`${styles.serviceForOptionsContainer} ${containerClass}`} key={key}>
        {title}
        <SelectInputWithImage
          name={name}
          options={patientOptions as Option[]}
          containerClass={styles.inputWrapper}
          selectorClass={styles.selector}
          style={style}
          selectedValue={value}
          onChange={(val) => {
            startPatientForm();
            if (val) {
              dispatch(setSelectedPatient(val));
            }
          }}
          defaultValue={value || defaultPatient}
          components={
            !addDependent
              ? undefined
              : {
                  MenuList: (props) => {
                    const { children } = props;
                    return (
                      <div {...props}>
                        {children}
                        <button
                          type="button"
                          className={styles.addDependentButton}
                          onClick={() => {
                            if (setAddDependentModal) setAddDependentModal(true);
                          }}
                        >
                          + Add Dependent
                        </button>
                      </div>
                    );
                  },
                }
          }
          ref={ref}
          isLoading={isLoading}
          withImage
        />
      </div>
    );
  },
);

PatientSelector.defaultProps = {
  title: 'Who is this service for?',
  containerClass: '',
  activeOnly: true,
  style: selectCommonCustomStylesLarge,
  addDependent: false,
  setAddDependentModal: undefined,
};

export default PatientSelector;
