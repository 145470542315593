/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLocalStorageState } from '@brands/hooks';
import { useAppDispatch, useAppSelector } from '@brands/hooks/useReduxHook';
import { activateUserById } from '@brands/services/identity/activateUserById';
import { Organization, UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { MdKeyboardArrowLeft, MdOutlineChangeCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import Button from '../../../Components/Button/Button';
import { reinviteUserById } from '../../../services/identity/reinviteUserById';
import resetPasswordIcon from '../../ConsultationPage/assets/icons/resetPassword.svg';
import createCaseIcon from './assets/createCase.svg';
import styles from './styles.module.scss';

interface ReviewProfileHeaderProps {
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  setResetPasswordConfirmation: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      userState: string;
    }>
  >;
  resetEmailSent: boolean;
  setChangeUserStatusFlag: React.Dispatch<React.SetStateAction<string>>;
  adminRole?: boolean;
  userId?: string;
  userRole: string;
  userOrganizations: Organization[];
}

const ReviewProfileHeader: React.FC<ReviewProfileHeaderProps> = ({
  thisUser,
  setThisUser,
  setResetPasswordConfirmation,
  resetEmailSent,
  setChangeUserStatusFlag,
  adminRole,
  userId,
  userRole,
  userOrganizations,
}) => {
  const { userInfo } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isInvitedUser, setIsInvitedUser] = useState(false);
  const [isOperationDropdownOpen, setOperationDropdownOpen] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (thisUser?.status) {
      setKey((prevKey) => prevKey + 1);
    }
  }, [thisUser?.status]);

  const statusOptions = useMemo(() => {
    if (thisUser) {
      const options = [];
      if (thisUser?.role.name === UserRoleName.Provider && thisUser?.status !== 'Pending') {
        options.push({ id: 'pending', name: 'Pending' });
      }
      if (thisUser?.role.name === UserRoleName.Provider && thisUser?.status === 'Pending') {
        options.push({ id: 'active', name: 'Active' }, { id: 'deactivated', name: 'Deactivated' });
      } else if (thisUser?.status !== 'Deactivated') {
        options.push({ id: 'deactivated', name: 'Deactivated' });
      } else {
        options.push({ id: 'active', name: 'Active' });
      }
      return options;
    }
    return [];
  }, [thisUser?.status, thisUser?.role.name]);

  const getInvitationDomain = (): string => {
    const organization =
      userOrganizations.find((org) => org.id !== 'dentistry-one') ||
      userOrganizations.find((org) => org.id === 'dentistry-one');
    return organization
      ? organization.divisions?.length
        ? organization.divisions[0]!.domain || ''
        : organization.practices[0]!.domain || ''
      : '';
  };

  const inviteUser = async (): Promise<void> => {
    setIsInvitedUser(true);
    try {
      const res = await reinviteUserById(userId || '', { frontend_website: `https://${getInvitationDomain()}` });
      setThisUser({ ...thisUser, status: res?.status });
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setKey((prevKey) => prevKey + 1);
    setIsInvitedUser(false);
  };
  const [url] = useLocalStorageState<string>('adminDashboardURL', '/admin/dashboard');

  const handleStatusClick = async (status: string): Promise<void> => {
    if (status === 'deactivated' || status === 'pending') {
      setChangeUserStatusFlag(status);
    } else {
      try {
        const userProfile = await activateUserById(Number(userId));
        setThisUser({ ...thisUser, status: userProfile?.status });
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
    }
    setKey((prevKey) => prevKey + 1);
    setOperationDropdownOpen(false);
  };

  useEffect(() => {
    if (thisUser && thisUser) {
      if (
        (userInfo.role.name === UserRoleName.OrganizationAdmin ||
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin) &&
        (thisUser?.role.name === UserRoleName.Patient || thisUser?.role.name === UserRoleName.Dependent)
      ) {
        dispatch(setSelectedPatientByAdmin({ selectedPatient: thisUser }));
        dispatch(
          setSelectedPatient({
            value: thisUser?.id.toString(),
            label: 'Current Patient',
          }),
        );
      }
    }
  }, []);

  const goToDashboard = useCallback(() => {
    return url ? navigate(`/admin/dashboard${url}`) : navigate('/admin/dashboard');
  }, [navigate]);

  const handleButtonClick = (): void => {
    const currentPathname = window.location.pathname;
    const newPathname = `${currentPathname}/create-consultation-for`;
    navigate(newPathname);
  };

  return (
    <>
      <div className={styles.headerTop}>
        <span>
          <section className={styles.backButtonContainer}>
            <button type="button" className={styles.backButton} onClick={() => goToDashboard()}>
              <MdKeyboardArrowLeft />
              Back
            </button>
          </section>
        </span>
        <div className={styles.reviewProfileInvitationStatus}>
          <span className={styles.reviewProfileInvitationStatusText}>User Status:</span>
          <div className={styles.reviewProfileInvitationStatusTag}>{thisUser?.status}</div>
        </div>
      </div>
      <div className={styles.reviewProfileHeader} key={key} data-testid="review-profile-header">
        {!adminRole && thisUser && (
          <div className={styles.reviewProfileHeaderCont}>
            <span className={styles.reviewProfileHeaderButtons}>
              <Button
                type="button"
                className={styles.resetPassword}
                onClick={() => inviteUser()}
                disabled={
                  userRole === 'Dependent' ||
                  ['Active', 'Pending', 'Deactivated'].includes(thisUser?.status) ||
                  isInvitedUser
                }
              >
                <IoIosAddCircleOutline />
                Invite User
              </Button>

              {userRole && userRole !== 'Dependent' && (
                <Button
                  type="button"
                  className={styles.resetPassword}
                  onClick={() => setResetPasswordConfirmation({ isOpen: true, userState: 'Active' })}
                  disabled={['Deactivated', 'Not Invited', 'Invited'].includes(thisUser?.status) || resetEmailSent}
                >
                  <img src={resetPasswordIcon} alt="Reset Password" />
                  Reset Password
                </Button>
              )}
              <div className={styles.updateStatus}>
                <Button
                  type="button"
                  className={styles.deleteAccount}
                  onClick={() => setOperationDropdownOpen(!isOperationDropdownOpen)}
                >
                  <MdOutlineChangeCircle />
                  Update User Status
                </Button>
                {isOperationDropdownOpen && (
                  <ul className={styles.dropdownList}>
                    {statusOptions?.map((status) => (
                      <li key={status.id}>
                        <button type="button" onClick={() => handleStatusClick(status.id)}>
                          {status.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </span>
            {(thisUser?.role.name === UserRoleName.Patient || thisUser?.role.name === UserRoleName.Dependent) && (
              <span className={`${styles.reviewProfileHeaderButtons} ${styles.createCase}`}>
                <Button
                  type="button"
                  className={styles.resetPassword}
                  onClick={handleButtonClick}
                  disabled={thisUser?.address === null || thisUser?.address?.state === null}
                >
                  <img className={styles.createCaseIcon} src={createCaseIcon} alt="Create New Case" />
                  Create Consultation
                </Button>
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

ReviewProfileHeader.defaultProps = {
  adminRole: false,
  userId: '',
};

export default ReviewProfileHeader;
