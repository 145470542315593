/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BsCamera } from 'react-icons/bs';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Button from '../../Components/Button/Button';
import { Loading } from '../../Components/LoadingSpinner/Loading';
import upload from './assets/upload.svg';
import styles from './styles.module.scss';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
  setChoosenFiles: (files: File[]) => void;
  choosenFiles: File[];
}

const WebCam = ({ setOpenModal, setChoosenFiles, choosenFiles }: IModal): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [, setStringPath] = useState<string>('');
  const [mediaTracks, setMediaTracks] = useState<MediaStreamTrack[]>([]);
  const [capturedFile, setCapturedFile] = useState<File | null>();
  const [permission, setPermission] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream>();
  const [loading] = useState<boolean>(false);

  const enableCamera = async (): Promise<void> => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(mediaStream);
      setPermission(true);
      setMediaTracks(mediaStream.getTracks());
    } catch (err) {
      setPermission(false);
      toast.error('Please allow camera access to take a photo');
    }
  };

  const stopMediaTracks = (): void => {
    mediaTracks.forEach((track) => {
      track.stop();
    });
    setMediaTracks([]);
  };

  const stopTracks = (streamNew: MediaStream): void => {
    streamNew.getTracks().forEach((track) => track.stop());
  };

  const closeModal = (): void => {
    stopMediaTracks();
    if (stream) {
      stopTracks(stream);
    }

    setOpenModal(false);
  };

  useEffect(() => {
    enableCamera();

    return () => {
      stopMediaTracks();
    };
  }, []);

  useEffect(() => {
    if (permission && stream && videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }
  }, [permission, stream]);

  const takePhoto = (): void => {
    if (!canvasRef.current) {
      return;
    }

    const context = canvasRef.current.getContext('2d');

    if (!context || !videoRef.current) {
      return;
    }

    // Get the actual dimensions of the video stream
    const { videoWidth } = videoRef.current;
    const { videoHeight } = videoRef.current;

    // Set the canvas dimensions to match the video stream
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    // Use these dimensions in the drawImage method
    context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
    const path = canvasRef.current.toDataURL('image/png');
    const blobBin = atob(path.split(',')[1]);
    const array = new Uint8Array(blobBin.length);

    for (let i = 0; i < blobBin.length; i += 1) {
      array[i] = blobBin.charCodeAt(i);
    }

    const blob = new Blob([array], { type: 'image/png' });
    const file = new File([blob], `Capture_${Math.floor(Math.random() * 1000)}.png`, {
      type: blob.type,
    });

    setCapturedFile(file);
    setStringPath(path);
  };

  const clearPhoto = (): void => {
    setCapturedFile(null);
    if (!canvasRef.current) {
      return;
    }

    const context = canvasRef.current.getContext('2d');

    if (!context) {
      return;
    }

    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, 500, 500);
    const path = canvasRef.current.toDataURL('image/png');
    setStringPath(path);
    enableCamera();
  };

  const handleUpload = (): void => {
    if (!capturedFile) {
      return;
    }
    setChoosenFiles([...choosenFiles, capturedFile]);
    closeModal();
  };

  return (
    <div className={styles.modalBackground}>
      <div className={`${styles.modalContainer} ${styles.webCam}`}>
        <button
          type="button"
          className={styles.closeModal}
          aria-label="Close modal"
          onClick={() => {
            closeModal();
          }}
        >
          <AiFillCloseCircle />
          Close
        </button>
        <div className={styles.body}>
          <canvas ref={canvasRef} className={styles.webCamCanvas} />
          {!capturedFile && (
            <video
              className={styles.webCamVideo}
              ref={videoRef}
              playsInline
              muted
              autoPlay
              onClick={() => {
                if (videoRef.current && videoRef.current.paused) {
                  videoRef.current.play();
                }
              }}
            />
          )}
          {loading && <Loading />}
          <div className={styles.modalButtons}>
            {capturedFile && (
              <Button className={`${styles.actionBtn} ${styles.retake}`} type="button" onClick={() => clearPhoto()}>
                Retake
                <BsCamera />
              </Button>
            )}
            {!capturedFile && (
              <Button
                type="button"
                onClick={() => {
                  takePhoto();
                }}
                className={styles.captureBtn}
              >
                Capture
                <BsCamera />
              </Button>
            )}
            {capturedFile && (
              <Button
                className={styles.actionBtn}
                disabled={loading}
                type="button"
                onClick={capturedFile ? handleUpload : undefined}
              >
                Upload
                <img className={styles.uploadSVG} src={upload} alt="upload" />
                {loading && <FaSpinner className={styles.spinner} />}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebCam;
