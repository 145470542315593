// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/close.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tC77FimwOleaYAxullWN{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;position:absolute !important;top:14px;right:9px;width:24px;height:24px;float:right}`, "",{"version":3,"sources":["webpack://./src/brands/default/VideoRoom/SelectBackgroundModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wDAAA,CACA,2BAAA,CACA,4BAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".modalClose {\n  background-image: url(../../assets/icons/close.svg);\n  background-repeat: no-repeat;\n  position: absolute !important;\n  top: 14px;\n  right: 9px;\n  width: 24px;\n  height: 24px;\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalClose": `tC77FimwOleaYAxullWN`
};
export default ___CSS_LOADER_EXPORT___;
