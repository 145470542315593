import { client } from '../client';
import { Dependent } from './types/Dependent';

export interface IListDependents {
  include_deleted: boolean;
}

export const listDependents = async (guardian_id: number, payload: IListDependents): Promise<Dependent[]> => {
  const { data } = await client.get<Dependent[]>(`/users/${guardian_id}/dependents`, {
    params: payload,
  });

  return data;
};
