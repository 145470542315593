import { defaultFormValues } from '@brands/Dashboard/MyProfile/CareAdvisor/utils/defaultFromValues';
import { CAMyProfileSubmit } from '@brands/Dashboard/MyProfile/CareAdvisor/utils/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CareAdvisorMyProfileState = {
  myProfile: CAMyProfileSubmit;
};

const initialState: CareAdvisorMyProfileState = {
  myProfile: defaultFormValues,
};

export const careAdvisorMyProfileSlice = createSlice({
  name: 'careAdvisorMyProfile',
  initialState,
  reducers: {
    setCareAdvisorMyProfile: (state, action: PayloadAction<CAMyProfileSubmit>) => {
      return { ...state, myProfile: action.payload };
    },
    clearCareAdvisorMyProfile: () => {
      return initialState;
    },
  },
});

export const { setCareAdvisorMyProfile, clearCareAdvisorMyProfile } = careAdvisorMyProfileSlice.actions;

export default careAdvisorMyProfileSlice.reducer;
