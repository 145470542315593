export const genderOptions = [
  { id: 1, value: 'female', label: 'Female' },
  { id: 2, value: 'male', label: 'Male' },
];

export const pronounsOptions = [
  { id: 1, value: 'she/her', label: 'She/Her' },
  { id: 2, value: 'he/him', label: 'He/Him' },
  { id: 3, value: 'they/them', label: 'They/Them' },
  { id: 4, value: 'other', label: 'Other' },
  { id: 5, value: 'preferNotToRespond', label: 'Prefer not to respond' },
];

export const painDurationOptions = [
  { id: 1, value: 'Less than one day', label: 'Less than one day' },
  { id: 2, value: 'A couple of days', label: 'A couple of days' },
  { id: 3, value: 'About a week', label: 'About a week' },
  { id: 4, value: 'A couple of weeks', label: 'A couple of weeks' },
  { id: 5, value: 'A month or more', label: 'A month or more' },
];

export const genderIdentity = [
  { id: 1, value: 'woman', label: 'Woman' },
  { id: 2, value: 'man', label: 'Man' },
  { id: 3, value: 'transgender', label: 'Transgender' },
  { id: 4, value: 'non-binary', label: 'Non-binary/non-conforming' },
  { id: 5, value: 'other', label: 'Other' },
  { id: 6, value: 'preferNotToRespond', label: 'Prefer not to respond' },
];
