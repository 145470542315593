import { useWindowSize } from '@brands/hooks';
import { selectAuth } from '@brands/store/selectors/auth';
import { openModal } from '@brands/store/slices/modalSlice';
import cn from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import ProfileImage from '../../../../Components/ProfileImage/ProfileImage';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { Language, UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { getGenderName } from '../../../../Utils/getGenderName';
import { pronounsOptions } from '../../../../Utils/selectButtonOptions';
import { Option, specialtyOptions, stateOptions } from '../../../../Utils/selectOptions';
import ReviewProfileHeader from '../../Components/ReviewProfileHeader';
import styles from '../../styles.module.scss';
import ReviewProviderCases from '../ReviewProviderCases';

type ReadOnlyProfileProps = {
  userId: string;
  onEditProfile: () => void;
  setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  image: string | undefined;
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  reviewProfile: boolean | undefined;
  resetEmailSent: boolean;
  setChangeUserStatusFlag: React.Dispatch<React.SetStateAction<string>>;
  setResetPasswordConfirmation: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      userState: string;
    }>
  >;
  organizationOptions: Option[];
};

const ReadOnlyProfile = ({
  userId,
  onEditProfile,
  setImage,
  image,
  thisUser,
  setThisUser,
  reviewProfile,
  resetEmailSent,
  setChangeUserStatusFlag,
  setResetPasswordConfirmation,
  organizationOptions,
}: ReadOnlyProfileProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const userRole = useMemo(() => {
    if (!userInfo) {
      return null;
    }

    return userInfo.role.name;
  }, [userInfo]);
  const dispatch = useDispatch();
  const selectedGender = useMemo(() => {
    if (thisUser?.basic_info) {
      const selectedOption = getGenderName(thisUser?.basic_info.gender);

      if (selectedOption) {
        return selectedOption;
      }

      return thisUser?.basic_info.gender;
    }
    return ' ';
  }, [thisUser]);

  const displayPhoneNumber = useMemo(() => {
    if (thisUser?.phone_number) {
      const phoneNumber = parsePhoneNumber(thisUser.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    }
    return '';
  }, [thisUser]);

  const selectedPronouns = useMemo(() => {
    if (thisUser?.basic_info) {
      const selectedOption = pronounsOptions.find((option) => option.value === thisUser?.basic_info.pronouns);

      if (selectedOption) {
        return selectedOption.label;
      }

      return thisUser?.basic_info.pronouns;
    }
    return ' ';
  }, [thisUser]);

  const fullAddress = useMemo(() => {
    if (thisUser?.address) {
      const { address1, address2, city, state, zip_code: zipCode } = thisUser.address;
      return `${address1}${address2 ? ` , ${address2}` : ''}, ${city}, ${state} ${zipCode}`;
    }
    return ' ';
  }, [thisUser]);

  const languageList = useMemo(() => {
    if (thisUser && thisUser.basic_info && thisUser.basic_info.languages && thisUser.basic_info.languages.length > 0) {
      return thisUser.basic_info.languages
        .map((language: Language | string) => {
          if (typeof language === 'string') {
            return language;
          }
          return language.name;
        })
        .join(', ');
    }
    return ' ';
  }, [thisUser]);

  const licenseTypesDisplay = useMemo(() => {
    if (thisUser?.provider_profile?.licenses && thisUser?.provider_profile?.credentials) {
      return thisUser?.provider_profile?.credentials;
    }
    return ' ';
  }, [thisUser]);

  const providerNPIDisplay = useMemo(() => {
    if (thisUser?.provider_profile?.national_provider_id) {
      return thisUser?.provider_profile?.national_provider_id;
    }
    return ' ';
  }, [thisUser]);

  const selectedPrimarySpeciality = useMemo(() => {
    if (thisUser?.provider_profile?.primary_specialty && thisUser?.provider_profile?.primary_specialty.length > 0) {
      const selectedOptions = specialtyOptions.filter((option) =>
        thisUser.provider_profile.primary_specialty?.includes(option.value),
      );

      if (selectedOptions.length > 0) {
        return selectedOptions.map((option) => option.label).join(', ');
      }
      return ' ';
    }
    return ' ';
  }, [thisUser]);

  const selectedSecondarySpeciality = useMemo(() => {
    if (thisUser?.provider_profile?.secondary_specialty && thisUser?.provider_profile?.secondary_specialty.length > 0) {
      const selectedOptions = specialtyOptions.filter((option) =>
        thisUser.provider_profile.secondary_specialty?.includes(option.value),
      );

      if (selectedOptions.length > 0) {
        return selectedOptions.map((option) => option.label).join(', ');
      }
      return ' ';
    }
    return ' ';
  }, [thisUser]);

  const userOrganization = useMemo(() => {
    const newOrganizationsArray = thisUser?.organizations?.filter((org) =>
      organizationOptions.some((opt) => opt.id === org.id),
    );
    if (newOrganizationsArray) {
      return newOrganizationsArray
        .map((organization) => {
          return organization.name;
        })
        .join(', ');
    }
    return ' ';
  }, [organizationOptions, thisUser?.organizations]);

  const onEditEmailAddress = (): void => {
    dispatch(openModal({ modal: 'updateEmailAddress' }));
  };

  return (
    <div className={styles.readOnlyProfileContainer}>
      {reviewProfile && (
        <ReviewProfileHeader
          thisUser={thisUser}
          setThisUser={setThisUser}
          resetEmailSent={resetEmailSent}
          setChangeUserStatusFlag={setChangeUserStatusFlag}
          setResetPasswordConfirmation={setResetPasswordConfirmation}
          userId={userId}
          userRole={thisUser.role.name}
          userOrganizations={thisUser.organizations}
        />
      )}
      <div className={styles.titleContainer}>
        <span className={styles.title}>Profile</span>
        <button className={styles.editBtn} type="button" onClick={onEditProfile}>
          Edit
          <FiEdit className={styles.editIcon} />
        </button>
      </div>
      <div className={styles.detailsContainer}>
        <ProfileImage
          reviewProfile={reviewProfile}
          id={userId}
          image={image}
          setImage={setImage}
          className={styles.profileImg}
          size="80px"
          isUpload={false}
        />
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Name</div>
          <div className={styles.value}>
            {thisUser?.basic_info?.first_name} {thisUser?.basic_info?.last_name}
          </div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Gender assigned at birth</div>
          <div className={styles.value}>{selectedGender}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Pronouns</div>
          <div className={styles.value}>{selectedPronouns}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Languages</div>
          <div className={styles.value}>{languageList}</div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Address</div>
          <div className={styles.value}>{fullAddress}</div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>
            Email Address
            {(!reviewProfile ||
              (reviewProfile &&
                userRole !== UserRoleName.Admin &&
                userRole !== UserRoleName.SuperAdmin &&
                userRole !== UserRoleName.OrganizationAdmin)) && (
              <button className={styles.editBtn} type="button" onClick={onEditEmailAddress}>
                Edit
                <FiEdit className={styles.editIcon} />
              </button>
            )}
          </div>
          <div className={styles.value}>{thisUser?.email}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Phone Number</div>
          <div className={styles.value}>{displayPhoneNumber}</div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Provider NPI #</div>
          <div className={styles.value}>{providerNPIDisplay}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Credentials (DDS/DMD)</div>
          <div className={styles.value}>{licenseTypesDisplay}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Additional Credentials</div>
          <div className={styles.value}>{thisUser?.provider_profile?.additional_credentials || ' '}</div>
        </div>
      </div>
      {!isMobile ? (
        <div className={styles.detailsContainer}>
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div className={styles.tableCell}>State of License</div>
              <div className={styles.tableCell}>Provider License #</div>
              <div className={styles.tableCell}>Expiry Date</div>
              <div className={styles.tableCell}>Insurance(s)</div>
              <div className={styles.tableCell}>Effective Date</div>
              <div className={styles.tableCell}>Re-credentialing Date</div>
            </div>
            {thisUser?.provider_profile?.licenses &&
              thisUser?.provider_profile?.licenses
                .slice()
                .sort((a, b) => a.id! - b.id!)
                .map((license) => (
                  <div className={styles.tableRow} key={license.id}>
                    <div className={styles.tableCell}>
                      {stateOptions.find((state) => state.value === license.state)?.label.split(' - ')[1] as string}
                    </div>
                    <div className={styles.tableCell}>{license.number}</div>
                    <div className={styles.tableCell}>{moment(license.expiry_date).format('MM/DD/YYYY')}</div>
                    <div className={styles.tableCell}>
                      {license.insurance_carriers.map((insuranceCarrier) => (
                        <div className={styles.tableRow} key={insuranceCarrier.insurance_carrier?.id}>
                          <div className={styles.tableCell}>{insuranceCarrier.insurance_carrier?.name}</div>
                          <div className={styles.tableCell}>
                            {moment(insuranceCarrier.effective_date).format('MM/DD/YYYY')}
                          </div>
                          <div className={styles.tableCell}>
                            {moment(insuranceCarrier.recredential_date).format('MM/DD/YYYY')}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      ) : (
        thisUser?.provider_profile?.licenses &&
        thisUser?.provider_profile?.licenses
          .slice()
          .sort((a, b) => a.id! - b.id!)
          .map((license) => (
            <div className={styles.detailsContainer}>
              <hr style={{ width: '100%' }} />
              <div className={styles.readOnlyItem}>
                <div className={styles.caption}>State of License</div>
                <div className={styles.value}>
                  {stateOptions.find((state) => state.value === license.state)?.label.split(' - ')[1] as string}
                </div>
              </div>
              <div className={styles.readOnlyItem}>
                <div className={styles.caption}>Provider License #</div>
                <div className={styles.value}>{license.number}</div>
              </div>
              <div className={styles.readOnlyItem}>
                <div className={styles.caption}>Expiry Date</div>
                <div className={styles.value}>{moment(license.expiry_date).format('MM/DD/YYYY')}</div>
              </div>
              <div className={styles.readOnlyItem}>
                <div className={styles.caption}>Insurance(s)</div>
                <div className={styles.value}>
                  {license.insurance_carriers.map((insuranceCarrier) => (
                    <div className={styles.detailsContainer}>
                      <div className={styles.readOnlyItem}>
                        <div className={styles.caption}>Insurance Carrier</div>
                        <div className={styles.value}>{insuranceCarrier.insurance_carrier?.name}</div>
                      </div>
                      <div className={styles.readOnlyItem}>
                        <div className={styles.caption}>Effective Date</div>
                        <div className={styles.value}>
                          {moment(insuranceCarrier.effective_date).format('MM/DD/YYYY')}
                        </div>
                      </div>
                      <div className={styles.readOnlyItem}>
                        <div className={styles.caption}>Re-credentialing Date</div>
                        <div className={styles.value}>
                          {moment(insuranceCarrier.recredential_date).format('MM/DD/YYYY')}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
      )}
      <div className={styles.detailsContainer}>
        <div className={cn(styles.readOnlyItem, styles.maxHalf)}>
          <div className={styles.caption}>Education / Universities</div>
          <div className={styles.value}>{(thisUser?.provider_profile?.education || []).join(', ') || ' '}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Primary Specialty</div>
          <div className={styles.value}>{selectedPrimarySpeciality}</div>
        </div>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Secondary Specialty</div>
          <div className={styles.value}>{selectedSecondarySpeciality}</div>
        </div>
      </div>
      {reviewProfile && (
        <div className={styles.detailsContainer}>
          <div className={styles.readOnlyItem}>
            <div className={styles.caption}>Organization</div>
            <div className={styles.value}>{userOrganization}</div>
          </div>
        </div>
      )}
      <div className={styles.detailsContainer}>
        <div className={styles.readOnlyItem}>
          <div className={styles.caption}>Biography</div>
          <div className={styles.value}>{thisUser?.provider_profile?.biography || ' '}</div>
        </div>
      </div>
      {reviewProfile && <ReviewProviderCases role="provider" providerId={Number(userId)} />}
    </div>
  );
};

export default ReadOnlyProfile;
