import NotFoundPage from '@brands/Dashboard/NotFoundPage/NotFoundPage';
import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';

import { useAppSelector } from '../../../../hooks/useReduxHook';
import { UserRoleName } from '../../../../services/identity/types/UserProfile';
import AddAdditionalInformation from './AddAdditionalInformation';

const AddAdditionalInformationValidation: React.FC = () => {
  let component: JSX.Element;
  const user: AuthState = useAppSelector(selectAuth);
  switch (user.userInfo?.role.name) {
    case UserRoleName.Patient:
      component = <AddAdditionalInformation />;
      break;
    default:
      component = <NotFoundPage />;
      break;
  }

  return <div>{component}</div>;
};

export default AddAdditionalInformationValidation;
