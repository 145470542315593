import React, { FC, useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import Modal from 'react-modal';

import { useWindowSize } from '../../../hooks';
import { getOneCase } from '../../../services/cases/getCaseById';
import { getUserAvatar } from '../../../services/identity/getUserAvatar';
import { UserProfile } from '../../../services/identity/types/UserProfile';
import { customStylesDashboard } from '../../../Utils/customStyles';
import { displayErrorDetails } from '../../../Utils/displayError';
import styles from './styles.module.scss';

interface ProfileCardModalProps {
  isOpen: boolean;
  toggleModal: (bool: boolean) => void;
  caIdentity: UserProfile | null;
  caseId?: number;
}

const CareAdvisorProfileCardModal: FC<ProfileCardModalProps> = ({ isOpen, toggleModal, caIdentity, caseId }) => {
  const isMobile = useWindowSize().width < 992;
  const mobileCustomStylesDashboard = {
    ...customStylesDashboard,
    overlay: {
      ...customStylesDashboard.overlay,
      overflowY: 'scroll',
    },
    content: {
      ...customStylesDashboard.content,
      transform: 'translate(-50%, -50%)',
      width: '90%',
    },
  };

  const [caIdentityFromCase, setCaIdentity] = useState<UserProfile | null>(null);

  const initIdentity = async (): Promise<void> => {
    try {
      if (caseId && caIdentity?.basic_info === undefined) {
        const thisCase = await getOneCase(caseId);
        if (thisCase) {
          setCaIdentity(thisCase.care_advisor);
        }
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    initIdentity();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && !isOpen) {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);
  const closeModal = (): void => {
    toggleModal(false);
  };

  const [avatarIcon, setAvatarIcon] = useState(false);
  const handleAvatarError = (): void => {
    setAvatarIcon(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={isMobile ? mobileCustomStylesDashboard : customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <div className={styles.profilCardCC}>
        <button
          type="button"
          className={styles.modalClose}
          data-micromodal-close=""
          onClick={closeModal}
          aria-label="Close modal"
        >
          <AiOutlineCloseCircle className={styles.icon} />
        </button>
        <div className={styles.titleContainer}>
          <div className={styles.profileContainer}>
            <div className={styles.caImageContainer}>
              {!avatarIcon ? (
                <img
                  alt="Avatar"
                  onError={handleAvatarError}
                  className={styles.providerImage}
                  src={getUserAvatar(caIdentity?.id || 0)}
                />
              ) : (
                <CgProfile />
              )}
            </div>
            <div className={styles.textContainer}>
              <div className={styles.title}>{caIdentity?.full_name}</div>
              <div className={styles.details}>
                <div className={styles.gender}>
                  {caIdentity?.basic_info !== undefined
                    ? caIdentity?.basic_info?.gender
                    : caIdentityFromCase?.basic_info?.gender}{' '}
                  -{' '}
                  {caIdentity?.basic_info !== undefined
                    ? caIdentity?.basic_info?.pronouns
                    : caIdentityFromCase?.basic_info?.pronouns}
                </div>
                {false && (
                  <div className={styles.sendMessageButton}>
                    <button type="button">Send Message</button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.bio}>
            <div className={styles.title}>Bio</div>
            <div className={styles.content}>
              {caIdentity?.care_advisor_profile !== undefined
                ? caIdentity?.care_advisor_profile?.biography
                : caIdentityFromCase?.care_advisor_profile?.biography}
            </div>
          </div>
        </div>
        <div className={styles.profileCardBody}>
          <div className={styles.language}>
            <div className={styles.languages}>
              <div className={styles.title}>Language</div>
              <div className={styles.tags}>
                {caIdentity?.basic_info !== undefined
                  ? caIdentity?.basic_info?.languages?.map((language) => (
                      <div className={styles.tag}>
                        <div className={styles.label}>{typeof language !== 'string' && language.name}</div>
                      </div>
                    ))
                  : caIdentityFromCase?.basic_info?.languages?.map((language) => (
                      <div className={styles.tag}>
                        <div className={styles.label}>{typeof language !== 'string' && language.name}</div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CareAdvisorProfileCardModal.defaultProps = {
  caseId: 0,
};

Modal.setAppElement('#root');

export default CareAdvisorProfileCardModal;
