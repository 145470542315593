import { IOneReportMedia } from '@brands/services/oneReport/types/IOneReportMedia';
import { Treatment } from '@brands/services/oneReport/types/ITreatments';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Education = {
  id?: string;
  url: string;
  title: string;
};

export interface IOneReportState {
  assessments: string;
  internal_notes: string;
  media: IOneReportMedia[];
  treatments: Treatment[];
  educations: Education[];
  cdts: string[];
}

const initialState: IOneReportState = {
  assessments: '',
  internal_notes: '',
  media: [],
  treatments: [],
  educations: [],
  cdts: [],
};

const oneReportSlice = createSlice({
  name: 'oneReport',
  initialState,
  reducers: {
    setAssessments: (state, action: PayloadAction<string>) => {
      return { ...state, assessments: action.payload };
    },
    setInternalNotes: (state, action: PayloadAction<string>) => {
      return { ...state, internal_notes: action.payload };
    },
    setMedia: (state, action: PayloadAction<IOneReportMedia[]>) => {
      return { ...state, media: action.payload };
    },
    setTreatments: (state, action: PayloadAction<{ treatments: Treatment[] }>) => {
      return { ...state, treatments: action.payload.treatments };
    },
    setEducations: (state, action: PayloadAction<{ educations: Education[] }>) => {
      return { ...state, educations: action.payload.educations };
    },
    setCdts: (state, action: PayloadAction<string[]>) => {
      return { ...state, cdt: action.payload };
    },
    removeTreatment: (state, action: PayloadAction<number>) => {
      const newTreatments = state.treatments.filter((treatment, i) => i !== action.payload);
      return { ...state, treatments: newTreatments };
    },
  },
});

export const { setCdts, setAssessments, setInternalNotes, setMedia, setTreatments, setEducations } =
  oneReportSlice.actions;

export default oneReportSlice.reducer;
