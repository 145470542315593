/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Participant as IParticipant } from 'twilio-video';

import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';

interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  hideParticipant?: boolean;
  isSelected?: boolean;
  showVideoThumbnail?: boolean;
}

const Participant = ({
  participant,
  onClick,
  hideParticipant,
  isSelected,
  showVideoThumbnail,
}: ParticipantProps): JSX.Element => (
  <ParticipantInfo
    participant={participant}
    onClick={onClick}
    hideParticipant={hideParticipant}
    isSelected={isSelected}
    showVideoThumbnail={showVideoThumbnail}
  >
    <ParticipantTracks participant={participant} />
  </ParticipantInfo>
);

export default Participant;
