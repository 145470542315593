import { client } from '../client';
import { Address, UserProfile } from './types/UserProfile';

type CreateUserPayload = {
  email?: string;
  address?: Address;
  phone_number?: string;
  type?: string;
  organization_ids?: string[];
  division_ids?: string[];
  practice_ids?: string[];
  send_invitation?: boolean;
  full_name?: string;
  frontend_website?: string;
};

export const createNewUser = async (payload: CreateUserPayload): Promise<UserProfile> => {
  const { data } = await client.post<UserProfile>('/admin/users', payload);

  return data;
};
