import qs from 'qs';

import { TimeSlot } from '../../Dashboard/PatientForms/PatientForm/utils/ProviderAvailabilityCard/Components/TimeSlotComponent';
import { client } from '../client';
import { Meta } from '../communication/types/Meta';
import { UserProfile } from '../identity/types/UserProfile';

export interface ProviderAvailability {
  id: number;
  created_at: string;
  updated_at: string;
  user: UserProfile;
  sync_time_slots: string[];
  sync_time_intervals: TimeSlot[];
  async_time_slots: string[];
  async_time_intervals: TimeSlot[];
}

export interface ListAvailableProviders {
  nodes: ProviderAvailability[];
  meta: Meta;
}

export interface ListProvidersParams {
  date: string;
  tz: string;
  limit?: number;
  sort_by?: 'created_at' | 'updated_at';
  sort_direction?: 'asc' | 'desc';
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
  state?: string[];
  plan?: string;
  specialty?: string[];
  language?: string[];
  license_states?: string[];
  carrier_id?: number;
  provider_id?: number;
  synchronous?: boolean;
  asynchronous?: boolean;
  case_id?: number;
  patient_id?: number;
}

export const listAvailableProviders = async (params?: ListProvidersParams): Promise<ListAvailableProviders> => {
  const { data } = await client.get<ListAvailableProviders>(
    `/providers/available?${qs.stringify(params, { skipNulls: true, arrayFormat: 'repeat' })}`,
  );

  return data;
};
