import 'react-html5video/dist/styles.css';

import React from 'react';
import { DefaultPlayer as Video } from 'react-html5video';

interface VideoPlayerProps {
  videoUrl: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl }) => {
  return (
    <Video loop muted controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
      <source src={videoUrl} type="video/mp4" />
      <source src={videoUrl} type="video/webm" />
      <track label="English" kind="subtitles" srcLang="en" default />
    </Video>
  );
};

export default VideoPlayer;
