import { client } from '../client';

export interface Form {
  id: number;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface Forms {
  total_count: number;
  forms: Form[];
}

export const listForms = async (title?: string, page_number = 0, page_size = 10): Promise<Forms> => {
  if (title !== undefined) {
    const { data } = await client.get(`/forms?title=${title}&page_number=${page_number}&page_size=${page_size}`);

    return data;
  }
  const { data } = await client.get(`/forms?page_number=${page_number}&page_size=${page_size}`);

  return data;
};
