/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { CaseType } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import { getSecondary } from '@brands/Utils/getPrimaryColor';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { TiMessages } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import Select, { CSSObjectWithLabel } from 'react-select';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useSubscribe, useWindowSize } from '../../../hooks';
import useCases from '../../../hooks/useCases';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { Subscriber } from '../../../hooks/useSubscribe';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import { selectSortByStyles } from '../../../Utils/customStyles';
import { formatDate } from '../../../Utils/formatDate';
import { getColorForStatus } from '../../../Utils/getColorForStatus';
import { getTextForStatus } from '../../../Utils/getTextForStatus';
import { sortData, SortKeys, SortOrder } from '../../../Utils/sortData';
import video from '../../Dashboard/CareAdvisor/assets/liveVideo.svg';
import report from '../../Dashboard/CareAdvisor/assets/oralHealthReport.svg';
import phone from '../../Dashboard/CareAdvisor/assets/phone.svg';
import sVideo from '../../Dashboard/CareAdvisor/assets/scheduledVideo.svg';
import secondOpinion from '../../Dashboard/CareAdvisor/assets/secondOpinion.svg';
import ProfileComponent from '../../Dashboard/CareAdvisor/Component/ProfileComponent';
import styles from './appointments.module.scss';

type AppointmentsProps = {
  isProfileCard?: boolean;
};

const Appointments = ({ isProfileCard }: AppointmentsProps): JSX.Element => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(selectAuth);
  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      provider_id: userInfo.id,
      status: ['completed', 'canceled', 'in_progress_care_advisor'],
      limit: 15,
      include_patient_care_advisor_conversation: true,
      include_patient_provider_conversation: true,
      include_provider_care_advisor_conversation: true,
    }),
    [],
  );

  const { cases, dataLoading, loadNextPage, reloadAll } = useCases(params, true);

  const headers: { key: SortKeys; label: string; isSorted: boolean }[] = [
    { key: 'id', label: 'ID', isSorted: false },
    { key: 'completed_at', label: 'Completed Date', isSorted: false },
    { key: 'type', label: 'Consult Type', isSorted: false },
    { key: 'patient', label: 'Patient', isSorted: false },
    { key: 'care_advisor', label: 'Care Advisor', isSorted: false },
    { key: 'status', label: 'Status', isSorted: false },
  ];

  const sortByProviderAppointments: { value: SortKeys; label: string }[] = [
    { value: 'completed_at', label: 'Completed Date' },
    { value: 'type', label: 'Consult Type' },
    { value: 'patient', label: 'Patient' },
    { value: 'care_advisor', label: 'Care Advisor' },
    { value: 'status', label: 'Status' },
  ];

  const [headersState, setHeadersState] = useState(headers);
  const [sortKey, setSortKey] = useState<SortKeys>('created_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const sortedData = useCallback(
    () => sortData({ tableData: cases, sortKey, reverse: sortOrder === 'desc', userRole: userInfo.role.name }),
    [cases, sortKey, sortOrder],
  );

  const changeSort = (key: SortKeys): void => {
    const headerIndex = headers.findIndex((header) => header.key === key);
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn');
    setSortKey(key);
    const updatedHeadersState = headersState.map((header, index) => {
      if (index === headerIndex) {
        return {
          ...header,
          isSorted: true,
        };
      }
      return {
        ...header,
        isSorted: false,
      };
    });

    setHeadersState(updatedHeadersState);
  };

  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastItemRef = useRef<HTMLTableRowElement>(null);
  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastItemRef.current) observer.current.observe(lastItemRef.current);
  }, [cases]);

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (['case_updated', 'case_cancelled', 'case_new_message_created'].includes(message.command)) {
          reloadAll();
        }
      },
      commands: ['case_updated', 'case_cancelled', 'case_new_message_created'],
    }),

    [reloadAll],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  const selectSortByStylesCustomized = {
    ...selectSortByStyles,
    valueContainer: (provided: any) => ({
      ...provided,
      ...selectSortByStyles.valueContainer,
      height: 'auto',
      padding: '0',
    }),
    indicatorsContainer: () => ({ display: 'none' }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      width: '100%',
      paddingTop: '0px',
      paddingBottom: '0px',
      position: 'fixed',
      left: '0',
      right: '0',
      top: '50px',
      zIndex: '9999',
      borderColor: getSecondary(),
    }),
  };

  return (
    <Container
      childClassName={styles.appointments}
      isBGEclipse={false}
      containerStyle={{
        justifyContent: isProfileCard ? 'start' : '',
        alignItems: isProfileCard ? 'start' : '',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
    >
      <div
        className={styles.container}
        style={{
          marginTop: isProfileCard ? '0px' : '',
          paddingLeft: isProfileCard && screenSize.width >= 992 ? '6rem' : '',
        }}
      >
        <div className={`${styles.innerContainer} mb-1`}>
          <div className={styles.mainTitle} style={{ display: isProfileCard ? 'none' : '' }}>
            Previous Appointments
          </div>
          {isMobile && (
            <div className={styles.sortBy}>
              <span>Sort by</span>
              <div className={`${styles.wrapInput100}`} data-validate="Choose Sort by">
                <Select
                  className="input100 p-0 select"
                  styles={selectSortByStylesCustomized}
                  placeholder={sortByProviderAppointments[0].label}
                  onChange={(value) => changeSort(value?.value as SortKeys)}
                  menuPortalTarget={document.body}
                  options={sortByProviderAppointments}
                />
              </div>
              <span className={styles.sortIndicator}>{sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}</span>
            </div>
          )}
          <div className={styles.tableContainer}>
            {!dataLoading ? (
              sortedData().length > 0 ? (
                <table className={styles.appointmentTable}>
                  <thead>
                    <tr>
                      {headersState.map((row) => {
                        return (
                          <th key={row.key} onClick={() => changeSort(row.key)}>
                            <span className={`${sortKey === row.key ? styles.bold : ''}`}>
                              {row.label}
                              {row.isSorted && sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}
                            </span>
                          </th>
                        );
                      })}
                      <th aria-label="Sort"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData().map((appointment) => {
                      return (
                        <tr key={appointment.id} className={styles.tableRecord}>
                          <td className={`${styles.bottomSeperator} ${styles.mobileDue}}`}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>CP. Date</span>
                              <span>
                                {appointment.status === 'completed'
                                  ? formatDate(appointment.completed_at || appointment.updated_at || '')
                                  : appointment.status === 'canceled'
                                  ? formatDate(appointment.updated_at || '')
                                  : 'TBD'}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileCType}>
                            <div className={`col-md-2 ${styles.availability}`}>
                              <span className={styles.mobileHeader}>Consult Type</span>
                              {appointment.type === 'messaging' && <TiMessages />}
                              {appointment.type === 'video_call_instant' && (
                                <img className={styles.video} src={video} alt="video" />
                              )}
                              {appointment.type === 'video_call_scheduled' && (
                                <img className={styles.sVideo} src={sVideo} alt="video" />
                              )}
                              {appointment.type === CaseType.phone_call_scheduled && (
                                <img className={styles.sVideo} src={phone} alt="Phone" />
                              )}
                              {appointment.type === 'one_report' && (
                                <img className={styles.cReport} src={report} alt="video" />
                              )}
                              {appointment.type === 'second_opinion' && (
                                <img className={styles.cReport} src={secondOpinion} alt="secondOpinion" />
                              )}
                            </div>
                          </td>
                          <td className={styles.mobilePatient} aria-label="Patient">
                            <ProfileComponent caseRow={appointment} role="Patient" />
                          </td>
                          <td className={styles.mobilePatient} aria-label="Care Advisor">
                            <ProfileComponent caseRow={appointment} role="CA" />
                          </td>
                          <td>
                            <div>
                              <span className={styles.mobileHeader}>Status</span>
                              {(() => {
                                for (const key in appointment) {
                                  if (key === 'status') {
                                    return (
                                      <span
                                        className={styles.statusSpan}
                                        style={{ backgroundColor: getColorForStatus(appointment[key]) }}
                                      >
                                        {getTextForStatus(UserRoleName.Provider, appointment.type, appointment[key])}
                                      </span>
                                    );
                                  }
                                }
                              })()}
                            </div>
                          </td>
                          <td className={`${styles.actionBtn} ${styles.mobileBtn}`}>
                            {appointment.status !== 'canceled' && (
                              <Button
                                className={styles.actionBtnCompleted}
                                type="button"
                                onClick={() => navigate(`/consultation-page/${appointment.id}`)}
                              >
                                View Consult
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className={styles.lastItemRef} ref={lastItemRef} />
                  </tbody>
                </table>
              ) : (
                <span className={styles.noData}>No data to display</span>
              )
            ) : (
              <span>
                <Loading dotted />
              </span>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
Appointments.defaultProps = {
  isProfileCard: false,
};
export default Appointments;
