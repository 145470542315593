import { client } from '../client';
import { IOneReport } from './types/IOneReport';
import { IOneReportMedia } from './types/IOneReportMedia';

export const updateOneReportMedia = async (
  one_report_id: string,
  one_report_media_id: string,
  payload: IOneReportMedia,
): Promise<IOneReport> => {
  const { data } = await client.patch<IOneReport>(
    `/one_reports/${one_report_id}/media/${one_report_media_id}`,
    payload,
  );

  return data;
};
