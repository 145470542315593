import React from 'react';

import Container from '../Container/Container';
import styles from './styles.module.scss';

interface LoadingProps {
  isCADashboard?: boolean;
  dotted?: boolean;
  fullScreen?: boolean;
  isHeightAuto?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ isCADashboard, dotted, fullScreen, isHeightAuto }) => {
  return (
    <>
      {fullScreen && (
        <Container isBGEclipse={false} childrenStyle={{ paddingTop: '0px' }}>
          <div className={styles.ldsEllipsisDiv}>
            <div className={styles.ldsEllipsis}>
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </Container>
      )}
      {dotted && (
        <div className={styles.ldsEllipsisDiv} style={{ height: isHeightAuto ? 'auto' : '' }}>
          <div className={styles.ldsEllipsis}>
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
      {isCADashboard && (
        <div className={styles.defaultClass}>
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
    </>
  );
};

Loading.defaultProps = {
  isCADashboard: false,
  dotted: false,
  fullScreen: false,
  isHeightAuto: false,
};
