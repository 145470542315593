import { client } from '../client';
import { Organization } from './types/UserProfile';

export interface IListOrganization {
  nodes: Organization[];
}

export const listAllOrganizations = async (): Promise<IListOrganization> => {
  const { data } = await client.get('/admin/organizations');

  return data;
};
