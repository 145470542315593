import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import {
  checkFutureDate,
  checkValidDate,
  isValidAddress,
  isValidDate,
  isValidEmail,
  isValidName,
  isValidZipCode,
} from '../../../../../Utils/validation';

export const validationSchema = (selectedDependentId: boolean): any => {
  return Yup.object().shape({
    profile_img: Yup.string(),
    first_name: Yup.string()
      .required('First name is required')
      .test('test-name', 'Enter Valid First Name', (value: any) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    middle_name: Yup.string().test('test-name', 'Enter Valid Middle Name', (value: any) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    last_name: Yup.string()
      .required('Last name is required')
      .test('test-name', 'Enter Valid Last Name', (value: any) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    gender: Yup.string()
      .required('Gender is required')
      .test('test-name', 'Gender is required', (value: any) => {
        if (value === '' || value === 'Self Describe') {
          return false;
        }
        return true;
      }),
    gender_identity: Yup.string()
      .required('Gender identity is required')
      .test('test-name', 'Gender identity is required', (value) => {
        if (value === '') {
          return false;
        }
        return true;
      }),
    date_of_birth: Yup.string()
      .required('Date of Birth is required')
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value: any) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const values = value?.split('/');
          if (!checkValidDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value: any) => {
        if (value) {
          const values = value?.split('/');
          if (!checkFutureDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      }),
    language: Yup.array().min(1, 'Language is required').required('Language is required'),
    address1: Yup.string()
      .required('Address is required')
      .test('test-name', 'Enter Valid Full Name', (value) => {
        if (value && !isValidAddress(value)) {
          return false;
        }
        return true;
      }),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State information is required'),
    zip_code: Yup.string()
      .required('Zip Code is required')
      .test('test-name', 'Enter Valid Zip Code', (value: any) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    email: Yup.string()
      .required('Email is required')
      .test('test-name', 'Enter Valid Email', (value: any) => {
        if (value && !isValidEmail(value)) {
          return false;
        }
        return true;
      }),
    phone_number: Yup.string()
      .required('Phone number is required')
      .test('test-name', 'Enter Valid Phone Number', (value: any) => {
        if (value && !isValidPhoneNumber(value)) {
          return false;
        }
        return true;
      }),

    dependent_profile_img: Yup.string(),
    dependent_first_name: selectedDependentId
      ? Yup.string()
          .required('First name is required')
          .test('test-name', 'Enter Valid First Name', (value) => {
            if (value && !isValidName(value)) {
              return false;
            }
            return true;
          })
      : Yup.string(),
    dependent_middle_name: Yup.string().test('test-name', 'Enter Valid Middle Name', (value) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    dependent_last_name: selectedDependentId
      ? Yup.string()
          .required('Last name is required')
          .test('test-name', 'Enter Valid Last Name', (value) => {
            if (value && !isValidName(value)) {
              return false;
            }
            return true;
          })
      : Yup.string(),
    dependent_genderIdentity: selectedDependentId
      ? Yup.string()
          .required('Dependent gender identity is required')
          .test('test-name', 'Dependent gender identity is required', (value) => {
            if (value === '') {
              return false;
            }
            return true;
          })
      : Yup.string(),
    dependent_gender: selectedDependentId
      ? Yup.string()
          .required('Gender is required')
          .test('test-name', 'Gender is required', (value) => {
            if (value === '') {
              return false;
            }
            return true;
          })
      : Yup.string(),
    dependent_date_of_birth: selectedDependentId
      ? Yup.string()
          .required('Date of Birth is required')
          .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value) => {
            if (value && !isValidDate(value)) {
              return false;
            }
            if (value) {
              const values = value?.split('/');
              if (!checkValidDate(values[0], values[1], values[2])) {
                return false;
              }
            }
            return true;
          })
          .test('test-future', 'The Date of Birth can not be in the future.', (value) => {
            if (value) {
              const values = value?.split('/');
              if (!checkFutureDate(values[0], values[1], values[2])) {
                return false;
              }
            }
            return true;
          })
      : Yup.string(),
    dependent_language: selectedDependentId
      ? Yup.array().min(1, 'Language is required').required('Language is required')
      : Yup.array(),
    dependent_address1: selectedDependentId
      ? Yup.string()
          .required('Address is required')
          .test('test-name', 'Enter Valid Address', (value) => {
            if (value && !isValidAddress(value)) {
              return false;
            }
            return true;
          })
      : Yup.string(),
    dependent_address2: Yup.string(),
    dependent_city: selectedDependentId ? Yup.string().required('City is required') : Yup.string(),
    dependent_state: selectedDependentId ? Yup.string().required('State is required') : Yup.string(),
    dependent_zip_code: selectedDependentId
      ? Yup.string()
          .required('Zip Code is required')
          .test('test-name', 'Enter Valid Zip Code', (value) => {
            if (value && !isValidZipCode(value)) {
              return false;
            }
            return true;
          })
      : Yup.string(),
    guardian_relationship: selectedDependentId ? Yup.string().required('Relationship is required') : Yup.string(),
  });
};
