/* eslint-disable no-param-reassign */
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import { getUserAvatar } from '../../../../../../services/identity/getUserAvatar';
import { UserProfile } from '../../../../../../services/identity/types/UserProfile';
import styles from './cCard.module.scss';

interface IModal {
  careAdvisor: UserProfile;
  handleAssignedCareAdvisor: (target: number) => void;
  selectedCareAdvisor: number;
  setCAProfileCardIdentity: React.Dispatch<React.SetStateAction<UserProfile | null>>;
  setCAProfileCardModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CCard = ({
  careAdvisor,
  handleAssignedCareAdvisor,
  selectedCareAdvisor,
  setCAProfileCardIdentity,
  setCAProfileCardModal,
}: IModal): JSX.Element => {
  return (
    <div
      className={`row ${styles.careAdvisorListTableBody} ${
        selectedCareAdvisor === careAdvisor.id ? styles.selectedClass : styles.notSelectedClass
      }`}
      onClick={(e) => {
        e.stopPropagation();
        handleAssignedCareAdvisor(careAdvisor.id);
      }}
    >
      <div className={`col-10 col-md-6 ${styles.careAdvisorInfo}`}>
        <span
          onClick={(e) => {
            e.stopPropagation();
            setCAProfileCardIdentity(careAdvisor);
            setCAProfileCardModal(true);
          }}
        >
          <img
            alt="Avatar"
            onError={(event: any) => {
              event.target.src =
                'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
              event.target.onError = null;
            }}
            className={styles.careAdvisorImage}
            src={getUserAvatar(careAdvisor.id)}
          />

          <span>{careAdvisor.full_name}</span>
        </span>
      </div>
      <div className={`col-2 col-md-6 ${styles.checkMark}`}>
        <button
          type="button"
          onClick={() => handleAssignedCareAdvisor(careAdvisor.id)}
          aria-label="Assign Care Advisor"
        >
          <FaCheckCircle
            className={`${selectedCareAdvisor === careAdvisor.id ? styles.primaryColor : styles.defaultColor}`}
          />
        </button>
      </div>
    </div>
  );
};
export default CCard;
