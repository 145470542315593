import { useAppSelector } from '@brands/hooks/useReduxHook';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

const MobileHandoffModal = (): JSX.Element => {
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(selectAuth);
  const { state } = useLocation();
  const goToDashboard = useCallback(() => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      navigate(`/admin/review-profile/Patient/${state.patientId}`);
    } else {
      navigate('/dashboard');
    }
  }, [userInfo.role.name, navigate]);

  return (
    <div className="modalBackground" style={{ zIndex: '999' }} onClick={() => goToDashboard()}>
      <div className={`modalContainer ${styles.handoff_modal}`} onClick={(e) => e.stopPropagation()}>
        <div className="body">
          <p>It looks like you have proceeded on your phone.</p>
          <div className="modal-buttons" style={{ flexDirection: 'row', marginTop: 'auto' }}>
            <button type="button" onClick={() => goToDashboard()}>
              Back to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileHandoffModal;
