import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { verifyEmailAddress } from '../../../services/identity/verifyEmailAddress';
import { toastError } from '../../../Utils/toastError';
import Button from '../../Button/Button';
import Modal from '../Modal';
import styles from './styles.module.scss';

interface VerifyEmailAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const VerifyEmailAddressModal: FC<VerifyEmailAddressModalProps> = ({ isOpen, onClose }) => {
  const [code, setCode] = useState('');

  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    setCode('');
  }, [isOpen]);

  const onChangeCode = (event: ChangeEvent<HTMLInputElement>): void => {
    setCode(event.target.value);
  };

  const onConfirm = async (): Promise<void> => {
    setVerifying(true);
    try {
      await verifyEmailAddress(code);
      onClose();

      // NOTE: no way to update email address on profile page yet
      // as it is just based on user info from api not store.
      window.location.reload();
    } catch (err) {
      toastError(err);
    }
    setVerifying(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.content}>
        <p className={styles.title}>Verify email address</p>
        <p className={styles.label}>
          Verification code <span>*</span>
        </p>
        <input className={styles.input} type="text" value={code} onChange={onChangeCode} />
        <div className={styles.actionBtnContainer}>
          <Button type="button" className={styles.cancel} onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" disabled={!code || verifying} className={styles.submit} onClick={onConfirm}>
            Verify
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyEmailAddressModal;
