import { client } from '../client';

type MfaStatusResponse = {
  status: string;
  mfa_method: string[];
};
export const getMfaStatus = async (user_id: number): Promise<MfaStatusResponse> => {
  const { data } = await client.get(`/auth/${user_id}/mfa_status`);

  return data;
};
