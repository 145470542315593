import { useEffect, useState } from 'react';

const useScript = (url: string): { state: boolean; loading: boolean } => {
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = () => {
      setLoading(false);
      setState(true);
    };
    script.onerror = () => {
      setLoading(false);
      setState(false);
    };
    document.body.appendChild(script);

    return function cleanup() {
      document.body.removeChild(script);
    };
  }, [url]);

  return { loading, state };
};

export default useScript;
