import React from 'react';

import { useMainParticipant, useSelectedParticipant, useVideoContext } from '../../hooks';
import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';

const MainParticipant = (): JSX.Element | null => {
  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const [selectedParticipant] = useSelectedParticipant();

  if (mainParticipant || selectedParticipant) {
    return (
      <MainParticipantInfo>
        <ParticipantTracks
          participant={mainParticipant || selectedParticipant}
          enableScreenShare={mainParticipant !== localParticipant}
        />
      </MainParticipantInfo>
    );
  }
  return null;
};

export default MainParticipant;
