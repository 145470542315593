import { UserProfile } from '@brands/services/identity/types/UserProfile';

export const defaultUserValues: UserProfile = {
  id: 0,
  external_id: '',
  username: '',
  email: '',
  status: '',
  full_name: '',
  phone_number: '',
  picture_media_id: '',
  role: {
    name: '',
  },
  organizations: [],
  basic_info: {
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    pronouns: '',
    languages: [''],
  },
  patient_profile: {
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
    },
    insurances: [
      {
        insured_by: '',
        insurance_membership: {
          member_id: '',
          insurance_carrier: {
            id: 0,
            name: '',
          },
          insurance_carrier_id: 0,
          phone_number: '',
          group_number: '',
        },
        insurance_through: {
          first_name: '',
          date_of_birth: '',
          last_name: '',
          address: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: '',
            country: '',
          },
        },
      },
    ],
    payment_profile: {
      full_name: '',
      expiration_month: 0,
      expiration_year: 0,
      cc_last_four: 0,
      billing_address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
      },
      is_active: true,
    },
    divisions: [],
    practices: [],
  },
  provider_profile: {
    licenses: [],
    dea_number: '',
    national_provider_id: '',
    education: [],
    primary_specialty: [],
    secondary_specialty: [],
    biography: '',
    plan: [''],
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
    },
    availability: [],
    npi: '',
  },
  care_advisor_profile: {
    biography: '',
    address: {
      state: '',
    },
  },
  organization_admin_profile: {
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
    },
    divisions: [],
    practices: [],
  },
  created_at: '',
  updated_at: '',
};
