import { Conversation, Participant, ParticipantUpdateReason } from '@twilio/conversations';
import Cookies from 'js-cookie';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';

import { getConversation } from '../../services/communication/getConversation';
import { getParticipant } from '../../services/communication/getParticipantBySid';
import { IGMConversationParticipant } from '../../services/communication/types/IGMConversationParticipant';

interface IUseConversationParticipants {
  participants: IGMConversationParticipant[];
  status: 'initializing' | 'failed' | 'ready' | 'loading';
  participantJoin: string;
}

export default function useConversationParticipants(
  twilioConversation: Conversation | null,
): IUseConversationParticipants {
  const jwtToken = Cookies.get('jwtToken');
  const [participants, setParticipants] = useState<IGMConversationParticipant[]>([]);
  const [status, setStatus] = useState<IUseConversationParticipants['status']>('initializing');
  const [participantJoin, setParticipantJoin] = useState<string>('');

  useEffect(() => {
    if (!twilioConversation) {
      setParticipants([]);
      setStatus('ready');
      return;
    }
    setStatus('loading');
    getConversation(twilioConversation.sid, true).then((currentConversation) => {
      setParticipants(currentConversation.participants || []);
      setStatus('ready');
    });
  }, [twilioConversation, jwtToken]);

  const onParticipantJoined = async (participant: Participant): Promise<void> => {
    if (!twilioConversation) {
      return;
    }
    setParticipantJoin(participant.sid);
    const newParticipant = await getParticipant(twilioConversation.sid, participant.sid);
    setParticipants((prevParticipants) => uniqBy([...prevParticipants, newParticipant], 'sid'));
  };

  const onParticipantLeft = (participant: Participant): void => {
    setParticipants((prevParticipants) => prevParticipants.filter((p) => p.sid !== participant.sid));
  };

  const onParticipantUpdated = (data: { participant: Participant; updateReasons: ParticipantUpdateReason[] }): void => {
    console.warn(`Participant ${data.participant.sid} has been updated`);
    // window.location.reload();
  };

  useEffect(() => {
    if (!twilioConversation) {
      return () => {};
    }

    twilioConversation.on('participantJoined', onParticipantJoined);
    twilioConversation.on('participantLeft', onParticipantLeft);
    twilioConversation.on('participantUpdated', onParticipantUpdated);

    return () => {
      twilioConversation.off('participantJoined', onParticipantJoined);
      twilioConversation.off('participantLeft', onParticipantLeft);
      twilioConversation.on('participantUpdated', onParticipantUpdated);
    };
  }, [twilioConversation]);

  return {
    status,
    participants,
    participantJoin,
  };
}
