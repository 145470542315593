/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { CaseType } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import { getSecondary } from '@brands/Utils/getPrimaryColor';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { TiMessages } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import Select, { CSSObjectWithLabel } from 'react-select';

import Button from '../../../../../Components/Button/Button';
import { Loading } from '../../../../../Components/LoadingSpinner/Loading';
import { useWindowSize } from '../../../../../hooks';
import useCases from '../../../../../hooks/useCases';
import { useAppSelector } from '../../../../../hooks/useReduxHook';
import useSubscribe, { Subscriber } from '../../../../../hooks/useSubscribe';
import { CasePayload } from '../../../../../services/cases/createNewCase';
import { UserRoleName } from '../../../../../services/identity/types/UserProfile';
import { calculateCaseScheduled, calculateProviderCaseDue } from '../../../../../Utils/calculateCaseDue';
import { calculateDueDate } from '../../../../../Utils/calculateDueDate';
import { selectSortByStyles } from '../../../../../Utils/customStyles';
import { getColorForStatus } from '../../../../../Utils/getColorForStatus';
import { getFriendlyName } from '../../../../../Utils/getFriendlyName';
import { getTextForStatus } from '../../../../../Utils/getTextForStatus';
import { sortData, SortKeys, SortOrder } from '../../../../../Utils/sortData';
import video from '../../../CareAdvisor/assets/liveVideo.svg';
import report from '../../../CareAdvisor/assets/oralHealthReport.svg';
import phone from '../../../CareAdvisor/assets/phone.svg';
import sVideo from '../../../CareAdvisor/assets/scheduledVideo.svg';
import secondOpinion from '../../../CareAdvisor/assets/secondOpinion.svg';
import ProfileComponent from '../../../CareAdvisor/Component/ProfileComponent';
import ConsultStartsIn from '../../../ConsultStartsIn/ConsultStartsIn';
import styles from '../../styles.module.scss';

const Appointments = (): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastItemRef = useRef<HTMLTableRowElement>(null);

  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      provider_id: Number(userInfo.id),
      status: ['accepted_provider', 'in_progress_provider'],
      limit: 15,
      include_patient_care_advisor_conversation: true,
      include_patient_provider_conversation: true,
      include_provider_care_advisor_conversation: true,
    }),
    [],
  );

  const [hoverText, setHoverText] = useState(0);

  const { cases, reloadAll, loadNextPage, dataLoading, editCase } = useCases(params, true);

  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const updateThisCase = async (thisCaseId: number): Promise<void> => {
    const payload: CasePayload = {
      status: 'in_progress_provider',
    };

    await editCase(thisCaseId, payload);
  };

  const headers: { key: SortKeys; label: string; isSorted: boolean }[] = [
    { key: 'id', label: 'ID', isSorted: false },
    { key: 'video_conference_date', label: 'Scheduled', isSorted: false },
    { key: 'due_date', label: 'Due', isSorted: false },
    { key: 'type', label: 'Type', isSorted: false },
    { key: 'patient', label: 'Patient', isSorted: false },
    { key: 'brandAssoc', label: 'Organization', isSorted: false },
    { key: 'status', label: 'Status', isSorted: false },
  ];

  const sortByProviderAppointment: { value: SortKeys; label: string }[] = [
    { value: 'video_conference_date', label: 'Scheduled' },
    { value: 'due_date', label: 'Due' },
    { value: 'type', label: 'Type' },
    { value: 'patient', label: 'Patient' },
    { value: 'brandAssoc', label: 'Organization' },
    { value: 'status', label: 'Status' },
  ];

  const [headersState, setHeadersState] = useState(headers);
  const [sortKey, setSortKey] = useState<SortKeys>('due_date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ascn');

  const sortedData = useCallback(
    () => sortData({ tableData: cases, sortKey, reverse: sortOrder === 'desc', userRole: userInfo.role.name }),
    [cases, sortKey, sortOrder],
  );

  const changeSort = (key: SortKeys): void => {
    const headerIndex = headers.findIndex((header) => header.key === key);
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn');
    setSortKey(key);
    const updatedHeadersState = headersState.map((header, index) => {
      if (index === headerIndex) {
        return {
          ...header,
          isSorted: true,
        };
      }
      return {
        ...header,
        isSorted: false,
      };
    });

    setHeadersState(updatedHeadersState);
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastItemRef.current) observer.current.observe(lastItemRef.current);
  }, [cases]);

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (['case_updated', 'case_cancelled', 'case_new_message_created'].includes(message.command)) {
          reloadAll();
        }
      },
      commands: ['case_updated', 'case_cancelled', 'case_new_message_created'],
    }),

    [reloadAll],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, subscriber, unsubscribe]);

  const selectSortByStylesForPatient = {
    ...selectSortByStyles,
    valueContainer: (provided: any) => ({
      ...provided,
      ...selectSortByStyles.valueContainer,
      height: 'auto',
      padding: '0',
    }),
    indicatorsContainer: () => ({ display: 'none' }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      width: '100%',
      paddingTop: '0px',
      paddingBottom: '0px',
      left: '0',
      right: '0',
      top: '50px',
      zIndex: '9999',
      borderColor: getSecondary(),
    }),
  };

  return (
    <>
      <div className={`row ${styles.dashboardHeader}`}>Upcoming Appointments</div>
      {isMobile && (
        <div className={styles.sortBy}>
          <span>Sort by</span>
          <div className={`${styles.wrapInput100}`} data-validate="Choose Sort by">
            <Select
              className="input100 p-0 select"
              styles={selectSortByStylesForPatient}
              placeholder={sortByProviderAppointment[0].label}
              onChange={(value) => changeSort(value?.value as SortKeys)}
              menuPortalTarget={document.body}
              options={sortByProviderAppointment}
            />
          </div>
          <span className={styles.sortIndicator}>{sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}</span>
        </div>
      )}
      <div style={{ width: '100%' }}>
        {!dataLoading ? (
          cases && cases.filter((caseRow) => caseRow.type !== 'messaging').length > 0 ? (
            <>
              <table className={styles.providerDashboardTable}>
                <thead className={`${styles.providerListTableHeader} ${styles.dashboardHeaderName} ${styles.titles}`}>
                  <tr>
                    {headersState.map((row) => {
                      return (
                        <th key={row.key} onClick={() => changeSort(row.key)}>
                          <span className={`${sortKey === row.key ? styles.bold : ''}`}>
                            {row.label}
                            {row.isSorted && sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}
                          </span>
                        </th>
                      );
                    })}
                    <th aria-label="Sort"> </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData()
                    .filter((caseRow) => caseRow.type !== 'messaging')
                    .map((caseRow) => {
                      return (
                        <React.Fragment key={caseRow.id}>
                          <tr className={`${styles.providerListTableBody}`}>
                            <td className={styles.mobileDue}>
                              <div className={`${styles.due}`}>
                                <span className={styles.mobileHeader}>Scheduled</span>
                                <span className={styles.mobileDueDate}>
                                  {calculateCaseScheduled(caseRow.type, caseRow.video_conference_date || '')}
                                </span>
                              </div>
                            </td>
                            <td className={`${styles.mobileDue} ${styles.duaOnMobile}`}>
                              <div className={`${styles.due}`}>
                                <span className={styles.mobileHeader}>Due</span>
                                <span className={styles.mobileDueDate}>
                                  {calculateProviderCaseDue(
                                    caseRow.type,
                                    caseRow.started_at,
                                    caseRow.video_conference_date || '',
                                  )}
                                </span>
                              </div>
                            </td>
                            <td className={styles.mobileCType}>
                              <div className={`col-md-2 ${styles.availability}`}>
                                <span className={styles.mobileHeader}>Type</span>
                                {caseRow.type === 'messaging' && (
                                  <TiMessages
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'video_call_instant' && (
                                  <img
                                    className={styles.video}
                                    src={video}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'video_call_scheduled' && (
                                  <img
                                    className={styles.sVideo}
                                    src={sVideo}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === CaseType.phone_call_scheduled && (
                                  <img
                                    className={styles.sVideo}
                                    src={phone}
                                    alt="Phone"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'one_report' && (
                                  <img
                                    className={styles.cReport}
                                    src={report}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'second_opinion' && (
                                  <img
                                    className={styles.cReport}
                                    src={secondOpinion}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {hoverText === caseRow.id && (
                                  <div className={styles.hoverText}>{getFriendlyName(caseRow.type)}</div>
                                )}
                              </div>
                            </td>
                            <td className={styles.mobilePatient} aria-label="Patient">
                              <ProfileComponent caseRow={caseRow} role="Patient" />
                            </td>
                            <td className={styles.mobileBrand}>
                              <div className="col-md-2">
                                <span className={styles.mobileHeader}>Organization</span>
                                <span style={{ whiteSpace: 'nowrap' }}>{caseRow.organization.name}</span>
                              </div>
                            </td>
                            <td className={styles.mobileStatus}>
                              <div>
                                <span className={styles.mobileHeader}>Status</span>
                                {(() => {
                                  for (const key in caseRow) {
                                    if (key === 'status') {
                                      return (
                                        <span
                                          className={styles.statusSpan}
                                          style={{ backgroundColor: getColorForStatus(caseRow[key]) }}
                                        >
                                          {getTextForStatus(UserRoleName.Provider, caseRow.type, caseRow[key])}
                                        </span>
                                      );
                                    }
                                  }
                                })()}
                              </div>
                            </td>
                            <td className={styles.mobileBtn}>
                              <div className={`${styles.viewBtn}`}>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    if (caseRow.status === 'accepted_provider') {
                                      updateThisCase(caseRow.id);
                                    }
                                    navigate(`/consultation-page/${caseRow.id}`);
                                  }}
                                >
                                  View
                                </Button>
                              </div>
                            </td>
                          </tr>
                          {caseRow.type === 'video_call_scheduled' &&
                          caseRow.video_conference_date &&
                          calculateDueDate(caseRow.video_conference_date) ? (
                            <tr className={styles.startTime}>
                              <ConsultStartsIn video_conference_date={caseRow.video_conference_date} />
                            </tr>
                          ) : (
                            <tr className={styles.noStartTime}>
                              <div>
                                <span />
                              </div>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  <tr className={styles.lastItemRef} ref={lastItemRef} />
                </tbody>
              </table>
              {isLoading && <Loading dotted />}
            </>
          ) : (
            <span className={styles.noData}>No data to display</span>
          )
        ) : (
          <span>
            <Loading dotted />
          </span>
        )}
      </div>
    </>
  );
};

export default Appointments;
