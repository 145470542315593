/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react';
import { Room, TwilioError } from 'twilio-video';

export default function useHandleRoomDisconnection(
  room: Room | null,
  onError: (error: TwilioError) => void,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void,
  setBackgroundSettings: (settings: { type: 'none'; index: number }) => void,
) {
  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          onError(error);
        }
        removeLocalAudioTrack();
        removeLocalVideoTrack();
        setBackgroundSettings({ type: 'none', index: 0 });
        if (isSharingScreen) {
          toggleScreenShare();
        }
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
  }, [
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
    setBackgroundSettings,
  ]);
}
