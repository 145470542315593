// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xR8c9_Ik1muJIXO2sSCU{position:fixed;bottom:0;left:0;width:100%;height:auto;background-color:#ebebeb;overflow-y:auto;text-align:center;padding:5px 0 10px}`, "",{"version":3,"sources":["webpack://./src/brands/default/Components/Inputs/CustomDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,wBAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".menuDivContainer {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: auto;\n    background-color: #ebebeb;\n    overflow-y: auto;\n    text-align: center;\n    padding: 5px 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuDivContainer": `xR8c9_Ik1muJIXO2sSCU`
};
export default ___CSS_LOADER_EXPORT___;
