import { Address, BasicInfo } from '../services/identity/types/UserProfile';

export const purifyPayloadForAddress = (payload: any): Address => {
  const purifiedPayload = {
    address1: payload.address1,
    address2: payload.address2,
    city: payload.city,
    state: payload.state,
    zip_code: payload.zip_code,
    country: payload.country,
  };

  return purifiedPayload;
};

export const purifyPayloadForBasicInformation = (payload: any): BasicInfo => {
  const purifiedPayload = {
    first_name: payload.first_name,
    middle_name: payload.middle_name,
    last_name: payload.last_name,
    gender: payload.gender,
    date_of_birth: payload.date_of_birth,
    pronouns: payload.pronouns,
    languages: payload.languages,
    gender_identity: payload.gender_identity,
  };

  return purifiedPayload;
};
