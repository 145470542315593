// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EXNJ21l4z6JjfHMM2Ktz{display:flex;justify-content:center;align-items:center;font-weight:bold;font-size:16px;margin-top:1rem}.rvr11aBLdlMKItozGI7l{background-color:#fff;width:32px;height:32px;border-radius:50%;color:#92d148;display:flex;justify-content:center;align-items:center;margin-left:16px}`, "",{"version":3,"sources":["webpack://./src/brands/default/Components/Alert/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CAEF,sBACE,qBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,aAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":[".alertText {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold;\n  font-size: 16px;\n  margin-top: 1rem;\n}\n.alertIcon {\n  background-color: #ffffff;\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n  color: #92d148;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertText": `EXNJ21l4z6JjfHMM2Ktz`,
	"alertIcon": `rvr11aBLdlMKItozGI7l`
};
export default ___CSS_LOADER_EXPORT___;
