import { config } from '@brands/config/config';
import { setUser } from '@brands/store/slices/authSlice';
import { clearCareAdvisorMyProfile } from '@brands/store/slices/careAdvisorMyProfile';
import { resetDependents } from '@brands/store/slices/dependentsSlice';
import { setIsFromHandoff } from '@brands/store/slices/isFromHandOffSlice';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { setOrganization } from '@brands/store/slices/organizationSlice';
import { clearPageState, setDependentId, setIsFromSelectPatient } from '@brands/store/slices/pageStateSlice';
import { resetForm, resetSelectedPatient } from '@brands/store/slices/patientFormSlice';
import { clearPatientMyProfile } from '@brands/store/slices/patientMyProfileSlice';
import { clearProviderMyProfile } from '@brands/store/slices/providerMyProfileSlice';
import { setShowVideoThumbnail } from '@brands/store/slices/showVideoThumbnailSlice';
import Cookies from 'js-cookie';
import { AnyAction, Dispatch } from 'redux';

export const signOut = (dispatch: Dispatch<AnyAction>): void => {
  Cookies.remove('jwtToken', { domain: config.domain_name });
  Cookies.remove('refreshToken', { domain: config.domain_name });
  Cookies.remove('jwtTokenExpire', { domain: config.domain_name });
  Cookies.remove('userInfo', { domain: config.domain_name });

  window.localStorage.removeItem('isAuthenticated');
  window.localStorage.removeItem('jwtToken');
  window.localStorage.removeItem('expired');

  dispatch(setUser({ userInfo: null }));
  dispatch(setOrganization({ currentOrganization: null }));
  dispatch(setOrganizationPrices({ currentOrganizationPrices: null }));
  dispatch(resetForm());
  dispatch(resetSelectedPatient());
  dispatch(clearProviderMyProfile());
  dispatch(clearPatientMyProfile());
  dispatch(clearCareAdvisorMyProfile());
  dispatch(resetDependents());
  dispatch(setIsFromSelectPatient(false));
  dispatch(setDependentId(null));
  dispatch(clearPageState());
  dispatch(setIsFromHandoff(false));
  dispatch(setShowVideoThumbnail(false));
};
