import { client } from '../client';

export interface CloseAccountReasons {
  id: number;
  text: string;
}

export const getCloseReasons = async (): Promise<CloseAccountReasons[]> => {
  const { data } = await client.get(`/users/close_reasons`);

  return data;
};
