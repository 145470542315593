/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
import React, { Dispatch, FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface Validator {
  id: number;
  form_question_id: number;
  type: string;
  operator: string;
  value: string;
  custom_error_message: string;
  created_at: string;
  updated_at: string | null;
}

interface QuestionAndMultiselectProps {
  element: {
    id: number;
    form_id: number;
    form_step_id: number;
    title: string;
    description: string | null;
    is_required: boolean;
    is_conditional: boolean;
    type: string;
    options: string[] | [] | null;
    validators: Validator[] | [];
    created_at: string;
    updated_at: string | null;
  };
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
  isEmoji?: boolean;
  medicalHistory?: boolean;
  isChild?: boolean;
  isparentQuestionActive?: boolean;
}

const QuestionAndMultiselectSideQuestion: FC<QuestionAndMultiselectProps> = React.forwardRef(
  ({ element, form, setForm, isEmoji, medicalHistory, isChild, isparentQuestionActive }, ref) => {
    const [isSelected, setIsSelected] = useState<string>(form[`question_${element.id}`] || '');
    const [isEdit, setIsEdit] = useState(false);
    useEffect(() => {
      if (!form[`question_${element.id}`]) {
        setIsSelected('');
      } else {
        setIsSelected(form[`question_${element.id}`]);
      }
    }, []);
    useEffect(() => {
      if (isSelected && isparentQuestionActive) {
        setForm((prev: any) => ({
          ...prev,
          [`question_${element.id}`]: isSelected,
        }));
      } else {
        setForm((prev: any) => ({
          ...prev,
          [`question_${element.id}`]: '',
        }));
      }
    }, [isSelected]);

    return (
      <div
        className={`${styles.row} ${styles.medicalFormRow} ${isChild && styles.marginTop} ${
          isEmoji && (isSelected || isparentQuestionActive)
            ? styles.selectedClass
            : !isEmoji && isSelected
            ? styles.selectedClass
            : ''
        }`}
        onClick={() => {
          if (isSelected) {
            setIsEdit(!isEdit);
          }
        }}
      >
        <div
          className={styles.questionAndButtonContainer}
          onClick={() => {
            if (isEmoji) {
              if (isSelected === 'yes') {
                setIsSelected('yes');
              }
            } else {
              setIsSelected('no');
            }
          }}
        >
          <div className={`${styles.question} col-12 col-sm-8 px-0`}>
            <div className={`${styles.label} mb-2 mb-md-0`}>
              {element.title}
              {element.is_required ? <span className={styles.redText}> *</span> : ''}
            </div>
          </div>
        </div>
        {isEmoji && (
          <div className={styles.selectSelf} onClick={(e) => e.stopPropagation()}>
            <div className={styles.radio}>
              {element.options &&
                element.options.map((option: string) => (
                  <div className={styles.optionDiv} key={option}>
                    <label htmlFor={`question_${element.id}-${option}`}>
                      {isEmoji ? option.replace(/\p{Emoji}+/gu, '').trim() : option}
                    </label>
                    <input
                      id={`question_${element.id}-${option}`}
                      className={`form-check-input ${styles.checkInput} mt-0 ${
                        isSelected === option ? styles.primaryColor : ''
                      }`}
                      value={option}
                      type="radio"
                      onChange={(e) => {
                        setIsSelected(option);
                      }}
                      checked={isSelected === option}
                      ref={ref as any}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  },
);

QuestionAndMultiselectSideQuestion.defaultProps = {
  isEmoji: false,
  medicalHistory: false,
  isChild: false,
  isparentQuestionActive: false,
};

export default QuestionAndMultiselectSideQuestion;
