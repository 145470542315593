/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import QuestionAndTextField from '@brands/Components/QuestionAndAnswers/QuestionAndTextField/QuestionAndTextField';
import { getUserById } from '@brands/services/identity/getUserById';
import { UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { setUserTag } from '@brands/services/tags/setUserTag';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { FormValues } from '@brands/store/slices/formAnswersSlice';
import { addFormItem } from '@brands/store/slices/formQuestionsSlice';
import { setExternalForms } from '@brands/store/slices/patientFormSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import QuestionAndRadioButton from '../../../Components/QuestionAndAnswers/QuestionAndRadioButtonForMed/QuestionAndRadioButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useWindowSize from '../../../hooks/useWindowSize';
import { createFormAnswer } from '../../../services/forms/createFormAnswer';
import { getForm } from '../../../services/forms/getForm';
import { FormAnswerValue, getFormAnswer } from '../../../services/forms/getFormAnswer';
import { listForms } from '../../../services/forms/listForms';
import { FormQuestion } from '../../../services/forms/types/FormQuestion';
import { updateFormAnswer } from '../../../services/forms/updateFormAnswer';
import { listAllTags } from '../../../services/tags/listAllTags';
import { AddTagPayload, setTagsToMe } from '../../../services/tags/setTagsToMe';
import { displayErrorDetails } from '../../../Utils/displayError';
import { validationSchema } from '../MedicalHistory/utils/validationSchema';
import styles from '../styles.module.scss';
import { MedicalConditionProps, MedicalConditionSubmit } from './utils/types';

interface Option {
  id: number;
  description: string;
  patient_owned: boolean;
  name: string;
}

const MedicalCondition = ({ isProfileCard }: MedicalConditionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient, values } = useAppSelector(selectPatientForm);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const screenSize = useWindowSize();
  const { state } = useLocation();
  const [questions, setQuestions] = useState<FormQuestion[]>();
  const [questionsTitle, setQuestionsTitle] = useState('');
  const [noneOfTheAboveQuestionId, setNoneOfTheAboveQuestionId] = useState<number>();
  const [noneOfTheAboveFlag, setNoneOfTheAboveFlag] = useState<boolean>(false);
  const [showRemainingQuestions, setShowRemainingQuestions] = useState(false);
  const [rendered, setRendered] = useState(true);
  const [defaultValues, setDefaultValues] = useState<MedicalConditionSubmit>({});
  const [formId, setFormId] = useState(0);
  const [formAnswerId, setFormAnswerId] = React.useState<number | null>(null);
  const [medicalConditionForm, setMedicalConditionForm] = useState<MedicalConditionSubmit>({
    ...defaultValues,
  });
  const [guardianPatient, setGuardianPatient] = useState<UserProfile>();
  useEffect(() => {
    const getGuardianPAtient = async (): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        if (currentPatient.guardian_id !== null) {
          const guardianResponse = await getUserById(currentPatient?.guardian_id as number);
          setGuardianPatient(guardianResponse);
        }
      }
    };
    getGuardianPAtient();
  }, [currentPatient]);

  const [tagsList, setTagsList] = useState<Option[]>([]);
  useEffect(() => {
    const fetchOptions = async (): Promise<void> => {
      const response = await listAllTags();
      setTagsList(response);
    };
    fetchOptions();
  }, []);

  const removePunctuationAndLowerCase = (str: string): string => {
    return str.replace(/[^\w\s]|_/g, '').toLowerCase();
  };

  const createQuestionTagMap = (givenQuestions: FormQuestion[]): { [key: string]: number } => {
    const map: { [key: string]: number } = {};
    for (const question of givenQuestions) {
      const matchingTag = tagsList.find(
        (tag) => removePunctuationAndLowerCase(tag.description) === removePunctuationAndLowerCase(question.title),
      );
      if (matchingTag) {
        map[`question_${question.id}`] = matchingTag.id;
      }
    }
    return map;
  };
  const updateTags = async (questionsAnswers: MedicalConditionSubmit): Promise<void> => {
    for (const question in questionsAnswers) {
      if (questionsAnswers[question as keyof MedicalConditionSubmit]) {
        const tagId = createQuestionTagMap(questions!)[question];
        const payload: AddTagPayload = {
          answer: questionsAnswers[question as keyof MedicalConditionSubmit] === 'no' ? false : true,
          note: questionsAnswers[question as keyof MedicalConditionSubmit],
        };
        try {
          if (tagId) {
            if (
              userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
            ) {
              await setUserTag(selectedPatient?.value || state.patientId, tagId, payload);
            } else {
              await setTagsToMe(
                tagId,
                payload,
                Number(selectedPatient?.value) !== Number(userInfo.id) ? Number(selectedPatient?.value) : undefined,
              );
            }
          }
        } catch (error: unknown) {
          displayErrorDetails(error);
        }
      }
    }
  };
  const navigate = useNavigate();
  const setValuesPayload = (data: MedicalConditionSubmit): FormValues[] => {
    return [
      ...Object.keys(data).map((value) => {
        const questionId = Number(value.replace('question_', ''));
        return {
          form_question_id: questionId,
          value: { data: data[value as keyof MedicalConditionSubmit] },
        };
      }),
    ];
  };

  const checkExternalForms = async (): Promise<void> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? guardianPatient ?? (currentPatient as UserProfile)
          : userInfo,
        selectedPatient?.value,
        userInfo.role.name === UserRoleName.Patient && currentPatient.role.name === UserRoleName.Dependent
          ? currentPatient
          : currentPatient?.guardian_id !== null
          ? currentPatient
          : null,
      );
    const formsToSet = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
  };

  const onSubmit = async (data: MedicalConditionSubmit): Promise<void> => {
    updateTags(data);
    setRendered(false);
    const filteredData: MedicalConditionSubmit = Object.fromEntries(
      Object.entries(data).filter(([, value]) => value.trim() !== ''),
    );
    if (!formAnswerId) {
      await createFormAnswer({
        form_id: formId,
        user_id:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? currentPatient.guardian_id !== null
              ? (guardianPatient as UserProfile).id
              : (currentPatient as UserProfile).id
            : Number(userInfo.id),
        values: [...setValuesPayload(filteredData)],
        dependent_id:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? currentPatient.guardian_id !== null
              ? (currentPatient as UserProfile).id
              : undefined
            : Number(selectedPatient?.value) !== Number(userInfo.id)
            ? Number(selectedPatient?.value)
            : undefined,
      });
    } else {
      await updateFormAnswer(
        formAnswerId,
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id !== null
            ? (guardianPatient as UserProfile).id
            : (currentPatient as UserProfile).id
          : Number(userInfo.id),
        {
          values: [...setValuesPayload(filteredData)],
        },
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id !== null
            ? (currentPatient as UserProfile).id
            : undefined
          : Number(selectedPatient?.value) !== Number(userInfo.id)
          ? Number(selectedPatient?.value)
          : undefined,
      );
    }
    await checkExternalForms();
    setRendered(true);
    navigate('/medical-information-summary', {
      state: {
        fromPatientIntake: state?.fromPatientIntake ?? false,
        caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
        isPatientForm: state?.isPatientForm,
        shouldSeeLastModal: state?.shouldSeeLastModal,
        patientId: state?.patientId,
      },
    });
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const { register, trigger, handleSubmit, setValue } = useForm<MedicalConditionSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(questions, false, true, false)),
  });
  const getFormAnswers = useCallback(
    async (formIds: number, defaultVal: MedicalConditionSubmit) => {
      let formAnsId: number | null = null;
      const temporaryValues = { ...defaultVal };
      const formAnswers = await getFormAnswer({
        form_id: formIds,
        created_by_id: Number(selectedPatient?.value),
      });
      Object.values(formAnswers.form_answers).every((answer) => {
        let check = true;
        Object.values(answer.values).forEach((value: FormAnswerValue) => {
          if (Object.keys(temporaryValues).includes(`question_${value.form_question_id}`)) {
            temporaryValues[`question_${value.form_question_id}`] = value.value.data;
            check = false;
            formAnsId = answer.id;
          }
        });
        return check;
      });
      return { formAnswerIdentity: formAnsId, formObj: temporaryValues };
    },
    [defaultValues, dispatch, formId],
  );

  useEffect(() => {
    (async () => {
      setRendered(false);
      let defaultFormValues = {};
      const { forms: formList } = await listForms();
      const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
      setFormId(newFormId);
      await getForm(newFormId.toString()).then((res) => {
        if (res) {
          defaultFormValues = res.steps[1].questions.reduce((acc: MedicalConditionSubmit, question: FormQuestion) => {
            return {
              ...acc,
              [`question_${question.id}`]: 'no',
            };
          }, {});
          setDefaultValues(defaultFormValues);
          dispatch(
            addFormItem({
              form_id: res.id,
              step_id: res.steps[1].id,
              questions: res.steps[1].questions,
            }),
          );
        }
        setQuestions(res?.steps[1].questions);
        setNoneOfTheAboveQuestionId(res?.steps[1].questions.find((answer) => answer.title === 'None of the above')?.id);
        setQuestionsTitle(res?.steps[1].title);
      });
      const { formAnswerIdentity, formObj } = await getFormAnswers(newFormId, defaultFormValues);
      setFormAnswerId(formAnswerIdentity);
      setMedicalConditionForm(formObj as MedicalConditionSubmit);
      const hasAnsweredQuestions = Object.keys(formObj as MedicalConditionSubmit)
        .slice(8)
        .some(
          (key, index, array) =>
            formObj[key] !== 'no' &&
            formObj[key].trim() !== '' &&
            index !== array.length - 1 &&
            formObj[array.length - 1] !== 'yes',
        );

      setShowRemainingQuestions(hasAnsweredQuestions);
      setRendered(true);
    })();
  }, []);
  useEffect(() => {
    Object.keys(medicalConditionForm).forEach((key: unknown) => {
      setValue(key as string, medicalConditionForm[key as keyof MedicalConditionSubmit]);
    });
    setNoneOfTheAboveFlag(medicalConditionForm[`question_${noneOfTheAboveQuestionId}`] === 'yes');
    trigger();
  }, [medicalConditionForm]);

  if (!rendered || !questions) {
    return <Loading fullScreen />;
  }
  function handleNoneOfTheAbove(id: number): void {
    if (showRemainingQuestions) setShowRemainingQuestions(false);
    setNoneOfTheAboveFlag(!noneOfTheAboveFlag);
    setMedicalConditionForm((prev: any) => ({
      ...prev,
      [`question_${id}`]: medicalConditionForm[`question_${id}`] === 'yes' ? 'no' : 'yes',
    }));
  }

  function handleOtherClick(): void {
    setShowRemainingQuestions(!showRemainingQuestions);
    setNoneOfTheAboveFlag(false);
    setMedicalConditionForm((prev: any) => ({
      ...prev,
      [`question_${noneOfTheAboveQuestionId}`]: 'no',
    }));
  }

  return (
    <Container
      isBGEclipse={false}
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px' }}
    >
      <div className={styles.medicalContainer}>
        <form
          className={styles.formContainer}
          style={{ width: isProfileCard ? '100%' : '', marginRight: isProfileCard ? '0px' : '' }}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <div
            className={styles.wrapper}
            style={{ width: isProfileCard ? '734px' : screenSize.width < 992 ? '90%' : '' }}
          >
            <div className={`${styles.formQuestion}`}>
              {questionsTitle} <span className={styles.redText}> *</span>
            </div>
            <ProgressBar
              currentStep={state?.isPatientForm ? 3 : 2}
              totalSteps={state?.isPatientForm ? 5 : state?.isPatientForm ? 4 : 3}
              hideStepNumbers
            />
            <div className={styles.formContent}>
              {questions !== undefined &&
                questions.slice(0, 8)?.map((element) => {
                  return (
                    <QuestionAndRadioButton
                      {...register(`question_${element.id}` as string)}
                      element={element}
                      key={element.id}
                      form={medicalConditionForm}
                      setForm={setMedicalConditionForm}
                      noneOfTheAboveFlag={noneOfTheAboveFlag}
                      noneOfTheAboveQuestionId={noneOfTheAboveQuestionId}
                    />
                  );
                })}
              <div
                className={`${styles.row} row ${styles.medicalFormRow} ${
                  showRemainingQuestions ? styles.selectClass : ''
                }`}
                onClick={() => handleOtherClick()}
              >
                <div className={styles.questionAndButtonContainer}>
                  <div className={`col-6 col-sm-3 px-0 ${styles.select}`}>
                    <div className={styles.check}>
                      <input
                        className={`form-check-input ${styles.checkInput} mt-0 ${
                          showRemainingQuestions ? styles.primaryColor : ''
                        }`}
                        type="checkbox"
                        name="other"
                        id="other"
                        value={showRemainingQuestions ? 'no' : 'yes'}
                      />
                    </div>
                  </div>
                  <div className={`${styles.question} col-12 col-sm-8 px-0`}>
                    <div className={`${styles.label}`}>Other</div>
                  </div>
                </div>
              </div>
              {showRemainingQuestions && (
                <>
                  {questions.slice(8).map((element) =>
                    element.type !== 'textarea' && element.title !== 'None of the above' ? (
                      <QuestionAndRadioButton
                        {...register(`question_${element.id}` as string)}
                        element={element}
                        key={element.id}
                        form={medicalConditionForm}
                        setForm={setMedicalConditionForm}
                        noneOfTheAboveFlag={noneOfTheAboveFlag}
                        noneOfTheAboveQuestionId={noneOfTheAboveQuestionId}
                      />
                    ) : element.type === 'textarea' ? (
                      <div className={styles.textAreaContainer}>
                        <QuestionAndTextField
                          {...register(`question_${element.id}`)}
                          element={element}
                          key={element.id}
                          form={medicalConditionForm}
                          setForm={setMedicalConditionForm}
                          medicalCondition
                          noneOfTheAboveFlag={noneOfTheAboveFlag}
                          noneOfTheAboveQuestionId={noneOfTheAboveQuestionId}
                        />
                      </div>
                    ) : null,
                  )}
                </>
              )}
            </div>
            <div className={styles.btnContainer} style={{ justifyContent: 'space-between' }}>
              {questions !== undefined &&
                questions.slice(8)?.map(
                  (element) =>
                    element.title === 'None of the above' && (
                      <span className={styles.dontKnowClass}>
                        <input
                          className={`form-check-input ${styles.checkInput} mt-0 ${
                            medicalConditionForm[`question_${element.id}`] === 'yes' ? styles.primaryColor : ''
                          }`}
                          type="checkbox"
                          name={`question_${element.id}`}
                          id={`question_${element.id}`}
                          checked={medicalConditionForm[`question_${element.id}`] === 'yes' && !showRemainingQuestions}
                          onClick={() => handleNoneOfTheAbove(element.id)}
                        />
                        None of the above
                      </span>
                    ),
                )}
              <Button type="submit" style={{ width: '245px', height: '46px' }}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
MedicalCondition.defaultProps = {
  isProfileCard: false,
};
export default MedicalCondition;
