import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { cancelCase } from '../../../../services/cases/cancelCase';
import { ICase } from '../../../../services/cases/types/ICase';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { cancelCaseAvailability } from '../../../../Utils/getFriendlyName';
import styles from './styles.module.scss';

interface IModal {
  setOpenModal: (arg0: boolean) => void;
  thisCase: ICase;
  reloadAll: () => Promise<void>;
  refreshAll: () => Promise<void>;
}

const CancelAppointmentModal = ({ setOpenModal, thisCase, reloadAll, refreshAll }: IModal): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const cancelCaseFn = async (): Promise<void> => {
    setLoading(true);
    if (cancelCaseAvailability(thisCase)) {
      try {
        await cancelCase(thisCase.id);
        reloadAll();
        refreshAll();
        setOpenModal(false);
        setLoading(false);
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
    } else {
      setOpenModal(false);
      toast.error('You cannot cancel this case.');
    }
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.body}>
          <p>Do you want to cancel this appointment?</p>
          <div className={styles.modalButtons}>
            <button
              disabled={loading}
              type="button"
              onClick={() => {
                cancelCaseFn();
              }}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenModal(false);
              }}
              id="cancelBtn"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CancelAppointmentModal;
