import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import { Client } from '@twilio/conversations';
import { useEffect, useState } from 'react';

import { getConversationToken } from '../../services/communication/getConversationToken';
import { useJwtExpired } from '../useJwtExpired';
import { useAppSelector } from '../useReduxHook';

/**
 * This is used in `App.tsx` to initiate twilio client once when the frontend loads.
 */
export default function useTwilioConversationsClient(): Client | null {
  const userData: AuthState = useAppSelector(selectAuth);
  const [token, setToken] = useState<string | null>(null);
  const [client, setClient] = useState<Client | null>(null);

  async function renewToken(): Promise<void> {
    const newToken = await getConversationToken();
    setToken(newToken);
  }

  async function renewClient(): Promise<void> {
    if (client) {
      if (token) {
        await client.updateToken(token);
      } else {
        await client.shutdown();
      }
    } else if (token) {
      setClient(new Client(token));
    } else {
      setClient(null);
    }
  }

  useEffect(() => {
    if (userData.userInfo) {
      renewToken();
    } else {
      setToken(null);
    }
  }, [userData]);

  useEffect(() => {
    renewClient();
  }, [token]);

  useJwtExpired(renewToken, token);

  return client;
}
