// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video::-webkit-media-controls{display:none !important}video::-webkit-media-controls-start-playback-button{display:none !important}@media screen and (max-width: 768px)and (orientation: landscape){.wNCQz0roPs76aUrSd0Ql{width:100%}}`, "",{"version":3,"sources":["webpack://./src/brands/default/VideoRoom/VideoTrack/styles.module.scss"],"names":[],"mappings":"AAAA,8BACE,uBAAA,CAGF,oDACE,uBAAA,CAGF,iEACE,sBACE,UAAA,CAAA","sourcesContent":["video::-webkit-media-controls {\n  display: none !important;\n}\n\nvideo::-webkit-media-controls-start-playback-button {\n  display: none !important;\n}\n\n@media screen and (max-width: 768px) and (orientation: landscape) {\n  .video {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video": `wNCQz0roPs76aUrSd0Ql`
};
export default ___CSS_LOADER_EXPORT___;
