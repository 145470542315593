import React from 'react';

import styles from './styles.module.scss';
import LogoSVG from '../../../default/assets/icons/dentistryOneLogo.svg';

export const Spinner = () => {
  return (
    <span className={styles.loader}>
      <img className={`${styles.icon} ${styles.video}`} src={LogoSVG} alt="video" />
    </span>
  );
};
