import { client } from '../client';
import { IGMRegisteredMedia } from '../communication/types/IGMRegisteredMedia';

export interface RegisterMediaPayload {
  files: Array<{
    filename: string;
    mimetype: string;
    attributes?: { [x: string]: string };
  }>;
}

export const registerOneReportMedia = async (payload: RegisterMediaPayload): Promise<IGMRegisteredMedia> => {
  const { data } = await client.post<IGMRegisteredMedia>(`/media/register_upload`, payload);

  return data;
};
