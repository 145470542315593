/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import React, { useEffect } from 'react';
import Select, { GroupBase, OptionProps, SelectComponentsConfig, SingleValue, StylesConfig } from 'react-select';

import { selectCustomStyles } from '../../../Utils/customStyles';
import { getPrimaryColor } from '../../../Utils/getPrimaryColor';
import { Option } from '../../../Utils/selectOptions';
import styles from './styles.module.scss';

type SelectInputProps = {
  containerClass?: string;
  selectorClass?: string;
  isClearable?: boolean;
  name: string;
  placeholder?: string;
  options: Option[];
  style?: StylesConfig<Option, false, GroupBase<Option>>;
  errors?: Partial<any>;
  selectedValue: any;
  isDisabled?: boolean;
  onChange?: (
    value: SingleValue<{
      value: any;
      label: string;
    }>,
  ) => void;
  showNoInsuranceOption?: boolean;
  defaultValue?: Option;
  isSearchable?: boolean;
  components?: Partial<SelectComponentsConfig<Option, false, GroupBase<Option>>> | undefined;
  isLoading?: boolean;
};

const SelectInput = React.forwardRef(
  (
    {
      containerClass,
      selectorClass,
      name,
      placeholder,
      options,
      style,
      errors,
      selectedValue,
      isDisabled,
      isClearable,
      onChange,
      showNoInsuranceOption,
      defaultValue,
      isSearchable,
      components,
      isLoading,
    }: SelectInputProps,
    ref,
  ): JSX.Element => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const optionsList = [...options];
    if (!showNoInsuranceOption) {
      optionsList.shift();
    }

    const getComponents = (): unknown => ({
      Option: (props: OptionProps<Option>) => {
        const { isFocused, innerProps, innerRef, label, data } = props;
        const isInactive = data?.status ? data?.status !== 'Active' : false;
        const optionStyles = isFocused
          ? {
              backgroundColor: getPrimaryColor(),
              color: 'white',
            }
          : isInactive
          ? {
              color: 'gray',
            }
          : {};

        return (
          <div {...innerProps} ref={innerRef} style={optionStyles} className={styles.selectOptions}>
            {label}
          </div>
        );
      },
      ...components,
    });

    useEffect(() => {
      if (errors && Object.keys(errors).length > 0 && name) {
        const nameArray = name.split('.');
        if (nameArray.length > 1) {
          setErrorMessage(errors[nameArray[0]]?.[nameArray[1]]?.message);
        } else {
          const propertyIndex = Object.keys(errors).indexOf(name);
          if (propertyIndex !== -1) {
            setErrorMessage(errors[Object.keys(errors)[propertyIndex]].message);
          } else {
            setErrorMessage('');
          }
        }
      } else {
        setErrorMessage('');
      }
    }, [errors && Object.keys(errors).length, name, selectedValue]);

    return (
      <div className={`${styles.inputWrapper} ${containerClass}`} data-validate={`Choose ${name}`}>
        <Select
          className={cn(styles.input100, 'p-0', { 'is-valid': errorMessage }, styles.select, selectorClass)}
          onChange={onChange}
          name={name}
          styles={style}
          isClearable={isClearable}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          options={optionsList}
          noOptionsMessage={() => <span>Loading options...</span>} // Customizing the "No options" message with JSX
          value={
            selectedValue?.value
              ? optionsList.find((option) => option.value === selectedValue?.value) || null
              : optionsList.find((option) => option.value === selectedValue) || null
          }
          isDisabled={isDisabled}
          isOptionDisabled={(option) => option?.disabled === true}
          components={getComponents() as Partial<SelectComponentsConfig<Option, false, GroupBase<Option>>> | undefined}
          ref={ref as any}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          isLoading={isLoading}
        />
        <span className={styles.focusInput100} />
        {errorMessage && <div className={`${styles.invalidFeedback} invalid-feedback`}>{errorMessage}</div>}
      </div>
    );
  },
);

SelectInput.defaultProps = {
  containerClass: '',
  placeholder: '',
  style: selectCustomStyles,
  errors: {},
  isDisabled: false,
  onChange: undefined,
  isClearable: false,
  selectorClass: '',
  showNoInsuranceOption: true,
  defaultValue: undefined,
  isSearchable: true,
  components: undefined,
  isLoading: false,
};

export default SelectInput;
