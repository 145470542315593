import { client } from '../client';
import { UserProfile } from './types/UserProfile';

type ReinviteUserByIdPayload = {
  frontend_website?: string;
};

export const reinviteUserById = async (userId: string, payload: ReinviteUserByIdPayload): Promise<UserProfile> => {
  const { data } = await client.post<UserProfile>(`/admin/users/${userId}/reinvite`, payload);
  return data;
};
