import { client } from '../client';
import { IFormAnswer } from './types/IFormAnswer';

type FormValues = {
  form_question_id: number;
  value: {
    [key: string]: string | string[] | File[];
  };
};

type UpdateFormAnswerPayload = {
  values: FormValues[];
};

export const updateFormAnswer = async (
  formAnswerID: number,
  user_id: number,
  payload: UpdateFormAnswerPayload,
  dependent_id?: number,
): Promise<IFormAnswer> => {
  const queryParams = new URLSearchParams();
  queryParams.set('user_id', user_id.toString());

  if (dependent_id !== undefined) {
    queryParams.set('dependent_id', dependent_id.toString());
  }

  const { data } = await client.patch<IFormAnswer>(`/form_answers/${formAnswerID}?${queryParams.toString()}`, payload);

  return data;
};
