import { client } from '../client';

export interface Payload {
  method: string;
  user_credentials: { email: string; password: string };
  mfa_code: string;
  session: string;
}

export interface VerifyMfaCodeResponse {
  access_token: string;
  refresh_token: string;
}
export const verifyMfaCode = async (payload: Payload): Promise<VerifyMfaCodeResponse> => {
  const { data } = await client.post<VerifyMfaCodeResponse>(`/auth/verify_mfa_code`, payload);

  return data;
};
