import * as Yup from 'yup';

import { isValidFullName, isValidZipCode } from '../../../../Utils/validation';

export const validationSchema = (): any => {
  return Yup.object().shape({
    creditCardFullName: Yup.string().test('creditCardFullName', 'Full Name on card is required', function (value) {
      if (!value) {
        return false;
      }
      if (!isValidFullName(value)) {
        return this.createError({
          message: 'Please enter a valid Full Name',
        });
      }
      return true;
    }),
    creditCardAddress1: Yup.string().test('creditCardAddress1', 'Address is required', (value) => {
      return value?.trim() !== '';
    }),
    creditCardCity: Yup.string().test('creditCardCity', 'City is required', (value) => {
      return value?.trim() !== '';
    }),
    creditCardState: Yup.string().test('creditCardState', 'State is required', (value) => {
      return value?.trim() !== '' && value !== undefined;
    }),
    creditCardZipCode: Yup.string()
      .test('creditCardZipCode', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return new Yup.ValidationError('Not valid zipcode');
        }
        return true;
      })
      .test('creditCardZipCode', 'Zip Code is required', (value) => {
        return value?.trim() !== '';
      }),
  });
};
