/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useRef } from 'react';
import { AudioTrack as IAudioTrack } from 'twilio-video';

import { useDevices } from '../../hooks';

interface AudioTrackProps {
  track: IAudioTrack;
}

const AudioTrack = ({ track }: AudioTrackProps) => {
  const { audioOutputDevices } = useDevices();
  const activeSinkId = audioOutputDevices[0]?.deviceId;
  const audioEl = useRef<any>();

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current);
    return () =>
      track.detach().forEach((el) => {
        el.remove();
        el.srcObject = null;
      });
  }, [track]);

  useEffect(() => {
    audioEl.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  return null;
};

export default AudioTrack;
