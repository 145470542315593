/* eslint-disable no-nested-ternary */
import { config } from '@brands/config/config';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { setUser } from '@brands/store/slices/authSlice';
import { setPatientState } from '@brands/store/slices/currentPatientState';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { setOrganization } from '@brands/store/slices/organizationSlice';
import { setDependentId, setIsEditInsurance, setIsFromSelectPatient } from '@brands/store/slices/pageStateSlice';
import { setExternalForms, setForm, setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { signOut } from '@brands/Utils/signOut';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHook';
import { getCurrentProfile } from '../../services/identity/getCurrentProfile';

const PrivateRoutesFC: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => queryString.parse(location.search), [location.search]);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const { userInfo: userInfoInContext } = useAppSelector(selectAuth);

  const isAuthenticated = useMemo<boolean>(() => {
    return !!userInfoInContext;
  }, [userInfoInContext]);

  const dispatch = useAppDispatch();

  const handleUrlState = async (): Promise<void> => {
    try {
      const userDetails = await getCurrentProfile();
      const decompressedState = userDetails.web_state?.state;

      Cookies.set('refreshToken', (decompressedState as any).refreshToken as string, {
        domain: config.domain_name,
        secure: true,
        sameSite: 'strict',
      });

      Cookies.set('jwtTokenExpire', (decompressedState as any).jwtTokenExpire, {
        domain: config.domain_name,
        secure: true,
        sameSite: 'strict',
      });
      dispatch(setUser((decompressedState as any).auth));
      dispatch(setForm((decompressedState as any).patientForm.values));
      dispatch(setExternalForms((decompressedState as any).patientForm.checkExternalForms));
      dispatch(setSelectedPatient((decompressedState as any).patientForm.selectedPatient));
      dispatch(setSelectedPatientByAdmin({ selectedPatient: (decompressedState as any).selectedPatient }));
      dispatch(setIsFromSelectPatient((decompressedState as any).pageState.isFromSelectPatient));
      dispatch(setDependentId((decompressedState as any).pageState.selectedDependentId));
      dispatch(setIsEditInsurance((decompressedState as any).pageState.isEditInsuranceFlag));
      dispatch(setPatientState((decompressedState as any).patientState));
      const { patientState } = decompressedState as any;

      const [userId, state] = Object.entries(patientState)[0];

      dispatch(setPatientState({ userId: Number(userId), state: state as string }));
      dispatch(setOrganization({ currentOrganization: (decompressedState as any).patientcurrentOrganization }));
      dispatch(
        setOrganizationPrices({
          currentOrganizationPrices: (decompressedState as any).patientcurrentOrganizationPrices,
        }),
      );
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    if (queryParams.auth) {
      handleUrlState();
    }
  }, [queryParams]);

  const handleUrlAuth = (url: string): void => {
    if (url) {
      Cookies.set('jwtToken', url, {
        domain: config.domain_name,
        secure: true,
        sameSite: 'strict',
      });
    }
  };

  useEffect(() => {
    if (queryParams.auth) {
      handleUrlAuth(queryParams.auth as string);
    }
  }, [queryParams]);

  const initialize = async (): Promise<void> => {
    try {
      const currentUserInfo = await getCurrentProfile();
      dispatch(setUser({ userInfo: currentUserInfo }));

      if (
        location.pathname === '/dashboard' &&
        (currentUserInfo.role.name === UserRoleName.Admin ||
          currentUserInfo.role.name === UserRoleName.SuperAdmin ||
          currentUserInfo.role.name === UserRoleName.OrganizationAdmin)
      ) {
        navigate('/admin/dashboard');
      }
      setIsInitializing(false);
    } catch (error) {
      await signOut(dispatch);

      setIsInitializing(false);

      Cookies.remove('jwtToken', { domain: config.domain_name });
      Cookies.remove('refreshToken', { domain: config.domain_name });
      Cookies.remove('jwtTokenExpire', { domain: config.domain_name });
      Cookies.remove('userInfo', { domain: config.domain_name });
      window.localStorage.removeItem('isAuthenticated');
      window.localStorage.removeItem('jwtToken');
      window.location.href = `/?redirect_uri=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    }
  };
  useEffect(() => {
    initialize();
  }, []);

  if (isInitializing) {
    // TODO: Need to show some loading page until we are ready
    // to load authenticated routes
    return null;
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutesFC;
