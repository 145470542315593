/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import Button from '@brands/Components/Button/Button';
import Container from '@brands/Components/Container/Container';
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import SlideshowProps from '@brands/Components/Slideshow/Slideshow';
import Logo from '@brands/Dashboard/Appointments/assets/logo.svg';
import LogoMobile from '@brands/Dashboard/Appointments/assets/logoMobile.svg';
import { VideoCaseNotAvailableModal } from '@brands/Dashboard/PatientForms/PatientForm/SelectPatient/SelectPatient';
import { useOrganizationFlags, useWindowSize } from '@brands/hooks';
import { useAppDispatch, useAppSelector } from '@brands/hooks/useReduxHook';
import { getOneCase } from '@brands/services/cases/getCaseById';
import { ICase } from '@brands/services/cases/types/ICase';
import { SmileScanReport, SmileScanReportMedia } from '@brands/services/cases/types/SmileScanReport';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { getOneReportMedia } from '@brands/services/oneReport/getOneReportMedia';
import { getSmileScanReports } from '@brands/services/oneReport/getSmileScanReports';
import { IMedia } from '@brands/services/oneReport/types/IMedia';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { FormValues, setForm } from '@brands/store/slices/patientFormSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { convertZoneAbbr, getTimezoneAbbreviation } from '@brands/Utils/getParsedTimezoneName';
import { DEFAULT_PATIENT_LOCATION_STATE } from '@brands/Utils/localStorage';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import RiskTextDisplay, { Category, RiskLevel } from './RiskTextDisplay';
import styles from './smileScanReport.module.scss';
import arrow from './utils/icons/arrow.svg';
import download from './utils/icons/download.svg';
import { DiseaseAssessment, smileScanMapper, SmileScanReportWithDiseaseAssessment } from './utils/smileScanMapper';

const SmileScanReportComponent = (): JSX.Element => {
  const screenSize = useWindowSize();
  const { userInfo } = useAppSelector(selectAuth);
  const currentState = useAppSelector(selectCurrentPatientState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = screenSize.width < 768;
  const routeCaseId = useParams().caseId as string;
  const { videoCaseEnabled } = useOrganizationFlags(currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE);

  const [isDownloadMode, setIsDownloadMode] = useState(false);
  const [smileScanReportsWithDiseaseAssessment, setSmileScanReportsWithDiseaseAssessment] = useState<
    SmileScanReportWithDiseaseAssessment[]
  >([]);
  const [selectedSmileScanReport, setSelectedSmileScanReport] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [images, setImages] = useState<Array<IMedia>>([]);
  const [thisCase, setThisCase] = useState<ICase | null>(null);
  const [videoCaseTypeEnabled, setVideoCaseTypeEnabled] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let patientId = '';
      let reports: SmileScanReport[] = [];

      try {
        const response = await getOneCase(Number(routeCaseId));
        setThisCase(response);
        patientId = response.patient_id.toString() || '';
      } catch (error) {
        displayErrorDetails(error);
      }

      try {
        reports = await getSmileScanReports(patientId);
      } catch (error) {
        displayErrorDetails(error);
      }

      if (reports.length > 0) {
        const reportsWithDiseaseAssessment: SmileScanReportWithDiseaseAssessment[] = [];
        const currentReport = reports.find((report) => report.case_id === Number(routeCaseId));
        if (currentReport) {
          setSelectedSmileScanReport(reports.indexOf(currentReport));
        }

        await reports.forEach((smileScanReport) => {
          const mappedReport = smileScanMapper(smileScanReport);
          reportsWithDiseaseAssessment.push(mappedReport);
        });
        setSmileScanReportsWithDiseaseAssessment(reportsWithDiseaseAssessment);
      }
      setLoading(false);
    })();
  }, [routeCaseId]);

  useEffect(() => {
    (async () => {
      if (smileScanReportsWithDiseaseAssessment.length > 0) {
        setLoading(true);
        const selectedReport = smileScanReportsWithDiseaseAssessment[selectedSmileScanReport];
        if (selectedReport?.media) {
          try {
            const imgs = await Promise.all(
              selectedReport.media.map(async (media: SmileScanReportMedia) => {
                try {
                  const response = await getOneReportMedia(media.media_id || '');
                  return response;
                } catch (error) {
                  displayErrorDetails(error);
                  return null;
                }
              }),
            );

            const validImgs = imgs.filter((img): img is IMedia => img !== null);

            if (validImgs.length > 0) {
              setImages(validImgs);
            } else {
              setImages([]);
            }
          } catch (error) {
            displayErrorDetails(error);
          }
        }
        setLoading(false);
      }
    })();
  }, [smileScanReportsWithDiseaseAssessment, selectedSmileScanReport]);

  const speakToDentist = (): void => {
    if (!videoCaseEnabled) setVideoCaseTypeEnabled(true);
    else {
      const formPayload: FormValues[] = [
        {
          form_question_id: 20,
          value: {
            caseType: 'video_call_scheduled',
          },
        },
      ];

      dispatch(setForm(formPayload));
      navigate('/select-patient', {
        state: {
          caseType: '/select-patient',
          currentUserId: thisCase?.patient_id.toString(),
        },
      });
    }
  };

  const getGradeFromValue = useMemo(() => {
    return (disease: DiseaseAssessment): { grade: string; color: string; id: RiskLevel } => {
      const value = disease.value as number;
      const category = disease.id as Category;
      if (value >= (category === 'cavity_score' ? 55 : 70)) {
        return { grade: 'High Risk', color: '#FF0000', id: 'high' };
      }
      if (value >= (category === 'cavity_score' ? 50 : 40)) {
        return { grade: 'Moderate Risk', color: '#FF8A00', id: 'moderate' };
      }
      return { grade: 'Low Risk', color: '#13A700', id: 'low' };
    };
  }, []);

  const getGradeFromOverallScore = (score: number): { grade: string; color: string } => {
    if (score > 70) {
      return { grade: 'Low Risk', color: '#13A700' };
    }
    if (score > 35) {
      return { grade: 'Moderate Risk', color: '#FF8A00' };
    }
    return { grade: 'High Risk', color: '#FF0000' };
  };

  const calculateScoreChange = (
    currentScore: number,
    previousScore: number,
  ): { change: number; percentageChange: string; changeDirection: string } => {
    const change = currentScore - previousScore;
    const absChange = Math.abs(change);
    const percentageChange = ((change / previousScore) * 100).toFixed(1);
    const changeDirection = change === 0 ? '' : change > 0 ? 'up' : 'down';
    return { change: absChange, percentageChange, changeDirection };
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const downloadReport = (): void => {
    setIsDownloadMode(true);

    // Wait for the state update and the next render cycle
    setTimeout(() => {
      if (componentRef && componentRef.current) {
        const element = componentRef.current as HTMLElement;

        // Add a class to indicate PDF mode
        element.classList.add('pdf-mode');

        // Get the grid container
        const gridContainer = element.querySelector(`.${styles.smileScanReportDownloadGrid}`) as HTMLElement;

        if (gridContainer) {
          // Filter unique images and limit to 5
          const uniqueImages = Array.from(new Set(images.map((img) => img.url))).slice(0, 5);

          // Create and append new image elements
          uniqueImages.forEach((imageUrl) => {
            const gridElement = document.createElement('div');
            gridElement.className = styles.smileScanReportDownloadGridElement;

            const img = document.createElement('img');
            img.src = imageUrl;
            img.alt = 'Slideshow';
            img.className = `${styles.image} smile-scan-images`;

            gridElement.appendChild(img);
            gridContainer.appendChild(gridElement);
          });

          const options = {
            filename: 'SmileScanReport.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: window.devicePixelRatio || 2, useCORS: true },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
            margin: [10, 0, 15, 0],
          };

          // Wait for all images to load
          const selectedImages = gridContainer.getElementsByTagName('img');
          const numImages = selectedImages.length;
          let imagesLoaded = 0;
          const checkImagesLoaded = (): void => {
            imagesLoaded++;
            if (imagesLoaded === numImages) {
              // All images are loaded, generate the PDF
              html2pdf()
                .set(options)
                .from(element)
                .save()
                .then(() => {
                  // Remove the class after PDF generation
                  element.classList.remove('pdf-mode');
                });
            }
          };
          for (let i = 0; i < numImages; i++) {
            const image = selectedImages[i];
            if (!image.complete) {
              // Image is not yet loaded, add a load event listener
              image.addEventListener('load', checkImagesLoaded);
            } else {
              // Image is already loaded, increment the loaded count
              checkImagesLoaded();
            }
          }

          // If there are no images, generate the PDF immediately
          if (numImages === 0) {
            html2pdf()
              .set(options)
              .from(element)
              .save()
              .then(() => {
                // Remove the class after PDF generation
                element.classList.remove('pdf-mode');
              });
          }
        }

        // After the PDF is generated, reset the state
        setIsDownloadMode(false);
      }
    }, 1000); // Adjust the delay as necessary to ensure the container is displayed
  };

  const formatTime = (dateString: string, patientState: string): string => {
    const currentTimezone = getTimezoneAbbreviation(patientState);
    const timezoneWithUnderscore = convertZoneAbbr(currentTimezone as string);
    const date = moment.utc(dateString).tz(timezoneWithUnderscore);

    if (date.isValid()) {
      const daySuffix = (day: number): string => {
        if (day === 1 || day === 21 || day === 31) return 'st';
        if (day === 2 || day === 22) return 'nd';
        if (day === 3 || day === 23) return 'rd';
        return 'th';
      };

      const formattedDate = date.format('MMMM D');
      const formattedTime = date.format('YYYY');
      return `${formattedDate}${daySuffix(date.date())} ${formattedTime}`;
    }

    return '';
  };

  const dentalDiseaseAssessmentRowComponent = (disease: DiseaseAssessment, index: number): JSX.Element => {
    return (
      <div className={styles.dentalDiseaseAssessmentRow}>
        <div
          className={styles.dentalDiseaseAssessmentRowHeader}
          style={{
            backgroundColor:
              typeof disease.value === 'number'
                ? `${getGradeFromValue(disease).color}10`
                : disease.value
                ? '#FF000010'
                : '#13A70010',
          }}
        >
          <div className={styles.dentalDiseaseAssessmentRowTitle}>{disease.title}</div>
          <div
            style={{
              color:
                typeof disease.value === 'number'
                  ? getGradeFromValue(disease).color
                  : disease.value
                  ? '#FF0000'
                  : '#13A700',
            }}
          >
            {typeof disease.value === 'number' ? getGradeFromValue(disease).grade : disease.value ? 'Yes' : 'No'}
          </div>
        </div>
        <div className={styles.dentalDiseaseAssessmentRowContent}>
          <span>
            <RiskTextDisplay
              category={disease.id as Category}
              riskLevel={getGradeFromValue(disease).id}
              isDownloadMode={isDownloadMode}
            />
          </span>{' '}
          {isDownloadMode && index !== 4
            ? null
            : userInfo.role.name === UserRoleName.Patient && (
                <span
                  className={styles.dentalDiseaseAssessmentRowLink}
                  onClick={() => {
                    speakToDentist();
                  }}
                >
                  Speak to a Dentist
                </span>
              )}
        </div>
        <hr />
      </div>
    );
  };

  const SmileScanButtons = (displayDownloadButton = true): JSX.Element => {
    return (
      <div className={styles.smileScanReportButtonsContainer}>
        <div className={styles.smileScanReportButtons}>
          {userInfo.role.name === UserRoleName.Patient && (
            <>
              <Button
                className={`${styles.smileScanReportButton} ${styles.smileScanReportButtonPrimary}`}
                type="button"
                onClick={() => {
                  navigate('/smile-scan');
                }}
              >
                Start a New Scan
              </Button>
              <Button
                className={`${styles.smileScanReportButton} ${styles.smileScanReportButtonSecondary}`}
                type="button"
                onClick={() => {
                  speakToDentist();
                }}
              >
                Speak to a Dentist
              </Button>
            </>
          )}
        </div>
        {displayDownloadButton && (
          <div className={styles.smileScanReportButtons}>
            <button
              className={`${styles.smileScanReportButton} ${styles.smileScanReportButtonDownload}`}
              type="button"
              onClick={downloadReport}
            >
              <img src={download} alt="Download" />
              Download your report
            </button>
          </div>
        )}
      </div>
    );
  };

  const SmileScanReportColors = (): JSX.Element => {
    return (
      <div className={styles.smileScanReportColors}>
        <div className={styles.smileScanReportColor}>
          <div className={styles.smileScanReportColorBox} style={{ backgroundColor: '#E60306' }} />
          <p style={{ fontWeight: isDownloadMode ? '300' : 'inherit' }}>Area of concern on tooth</p>
        </div>
        <div className={styles.smileScanReportColor}>
          <div className={styles.smileScanReportColorBox} style={{ backgroundColor: '#1A00E0' }} />
          <p style={{ fontWeight: isDownloadMode ? '300' : 'inherit' }}>Tartar buildup</p>
        </div>
        <div className={styles.smileScanReportColor}>
          <div className={styles.smileScanReportColorBox} style={{ backgroundColor: '#599A94' }} />
          <p style={{ fontWeight: isDownloadMode ? '300' : 'inherit' }}>Inflammation of gums</p>
        </div>
        <div className={styles.smileScanReportColor}>
          <div className={styles.smileScanReportColorBox} style={{ backgroundColor: '#E49107' }} />
          <p style={{ fontWeight: isDownloadMode ? '300' : 'inherit' }}>Gum recession</p>
        </div>
      </div>
    );
  };

  const SmileScanReport = (smileScanReport: SmileScanReportWithDiseaseAssessment): JSX.Element => {
    const { created_at, overall_score, diseaseAssessment } = smileScanReport;
    return (
      <>
        <div className={styles.smileScanReportContainer}>
          <div className={styles.smileScanReportOverview}>
            {!isMobile && <h1 className={styles.smileScanReportTitle}>Your SmileScan Report</h1>}
            <div className={styles.smileScanReportOverviewWrapper}>
              <div className={styles.smileScanReportOverviewImages} id="smileScanReportOverviewImages">
                <SlideshowProps images={images} />
                {isMobile && <SmileScanReportColors />}
              </div>
              <div className={styles.smileScanReportOverviewDetails}>
                <div className={styles.smileScanReportScores}>
                  <div className={styles.smileScanReportScore}>
                    {smileScanReportsWithDiseaseAssessment.length > 1 && selectedSmileScanReport > 0 && (
                      <div className={styles.navigationButtons}>
                        <div
                          className={styles.navigationButton}
                          onClick={() => {
                            navigate(
                              `/smile-scan-report/${
                                smileScanReportsWithDiseaseAssessment[selectedSmileScanReport - 1].case_id
                              }`,
                            );
                          }}
                        >
                          <MdOutlineKeyboardArrowLeft />
                        </div>
                      </div>
                    )}
                    <div className={styles.smileScanReportScoreDetailsText}>
                      <p>
                        {isMobile ? (
                          <b style={{ fontSize: '24px', fontFamily: 'Ivy Mode' }}>
                            Your SmileScan Score
                            <br />
                          </b>
                        ) : (
                          <b style={{ fontSize: '24px' }}>SmileScan Score:</b>
                        )}{' '}
                        <span>
                          {formatTime(
                            created_at as string,
                            userInfo.role.name === UserRoleName.Patient ? currentState[userInfo.id] : 'NY',
                          )}
                        </span>
                      </p>
                    </div>
                    {smileScanReportsWithDiseaseAssessment.length > 1 &&
                      selectedSmileScanReport < smileScanReportsWithDiseaseAssessment.length - 1 && (
                        <div className={styles.navigationButtons}>
                          <div
                            className={styles.navigationButton}
                            onClick={() => {
                              navigate(
                                `/smile-scan-report/${
                                  smileScanReportsWithDiseaseAssessment[selectedSmileScanReport + 1].case_id
                                }`,
                              );
                            }}
                          >
                            <MdOutlineKeyboardArrowRight />
                          </div>
                        </div>
                      )}
                  </div>
                  <div className={styles.smileScanReportScoreChanges}>
                    <p
                      className={styles.smileScanReportScoreValue}
                      style={{ color: getGradeFromOverallScore(overall_score as number).color }}
                    >
                      {overall_score}
                    </p>
                    {smileScanReportsWithDiseaseAssessment.length > 1 && selectedSmileScanReport !== 0 && (
                      <>
                        <div className={styles.verticalLine} />
                        <p className={styles.smileScanReportScoreChange}>
                          {calculateScoreChange(
                            smileScanReport?.overall_score as number,
                            smileScanReportsWithDiseaseAssessment[selectedSmileScanReport - 1]?.overall_score as number,
                          ).changeDirection === 'up' ? (
                            <img src={arrow} alt="Arrow" />
                          ) : (
                            <img src={arrow} alt="Arrow" style={{ transform: 'rotate(180deg)' }} />
                          )}
                          <span>
                            <b>
                              {
                                calculateScoreChange(
                                  smileScanReport?.overall_score as number,
                                  smileScanReportsWithDiseaseAssessment[selectedSmileScanReport - 1]
                                    ?.overall_score as number,
                                ).change
                              }
                            </b>{' '}
                            Change
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <hr style={{ width: '100%', color: '#D0D0D0' }} />
                {!isMobile && <SmileScanReportColors />}
              </div>
            </div>

            <div className={styles.smileScanReportOverviewText}>
              <p>
                Your overall oral/dental health score is determined by the 4 SmileScan assessment categories, which
                indicate the condition of your oral health. The score is calculated by combining the individual ratings
                for area of concern on teeth, tartar buildup, swollen gums, and gum recession. Each factor is given a
                different weight in the formula, leading to an overall score ranging from 0 to 100. The higher the
                score, the better.
              </p>
            </div>

            {SmileScanButtons()}
          </div>
          <div className={styles.smileScanReportDetails}>
            {diseaseAssessment.map((disease, index) => dentalDiseaseAssessmentRowComponent(disease, index))}
            <div style={{ paddingTop: '50px' }}>{SmileScanButtons(false)}</div>
          </div>
          <div className={styles.smileScanReportDisclaimer}>
            <p>
              DISCLAIMER: The results of this report are part of a tool for pre-diagnostic information. They are not
              medical or diagnostic tools, and we do not claim that they diagnose any specific condition. They do not
              provide any medical advice and are not evaluated by a Provider. See the complete disclaimer by clicking{' '}
              <a target="_blank" rel="noreferrer" href="/smile-scan-disclaimer" className={styles.disclaimerLink}>
                here
              </a>
              .
            </p>
          </div>
        </div>
        {isDownloadMode && (
          <div
            className={styles.smileScanReportContainerDownload}
            ref={componentRef}
            id="downloadedContainer"
            style={{ display: isDownloadMode ? 'block' : 'none' }}
          >
            <div className={styles.smileScanReportDownloadHeader} style={{ paddingBottom: '15px' }}>
              <div className={styles.smileScanReportDownloadHeaderLeft}>
                <span style={{ fontWeight: 'bold' }}>
                  {thisCase?.patient.basic_info.first_name} {thisCase?.patient.basic_info.last_name}
                </span>
                <span>
                  {' '}
                  {formatTime(
                    created_at as string,
                    userInfo.role.name === UserRoleName.Patient ? currentState[userInfo.id] : 'NY',
                  )}
                </span>
              </div>
              <div className={styles.smileScanReportDownloadHeaderRight} style={{ height: '30px' }}>
                <img
                  crossOrigin="anonymous"
                  style={{ height: '30px' }}
                  className={styles.logo}
                  src={!isMobile ? Logo : LogoMobile}
                  alt="Logo"
                />
              </div>
            </div>
            <div className={styles.smileScanReportOverviewText}>
              <p>
                Your overall oral/dental health score is determined by the 4 SmileScan assessment categories, which
                indicate the condition of your oral health. The score is calculated by combining the individual ratings
                for area of concern on teeth, tartar buildup, swollen gums, and gum recession. Each factor is given a
                different weight in the formula, leading to an overall score ranging from 0 to 100. The higher the
                score, the better.
              </p>
            </div>
            <div className={styles.smileScanReportDownloadGrid}>
              <div
                className={`${styles.smileScanReportDownloadGridElement} ${styles.smileScanReportDownloadGridElementFirst}`}
              >
                <div className={styles.smileScanReportOverviewWrapper}>
                  <div className={styles.smileScanReportOverviewDetails}>
                    <div className={styles.smileScanReportScores}>
                      <div className={styles.smileScanReportScore}>
                        <div className={styles.smileScanReportScoreDetailsText}>
                          <p>
                            <b style={{ fontSize: '24px' }}>SmileScan Score:</b>
                          </p>
                        </div>
                      </div>
                      <div className={styles.smileScanReportScoreChanges}>
                        <div
                          className={styles.smileScanReportScoreValue}
                          style={{ color: getGradeFromOverallScore(overall_score as number).color }}
                        >
                          {overall_score}
                        </div>
                        {smileScanReportsWithDiseaseAssessment.length > 1 && selectedSmileScanReport > 0 && (
                          <>
                            <div className={styles.verticalLine} />
                            <div className={styles.smileScanReportScoreChange}>
                              {calculateScoreChange(
                                smileScanReport?.overall_score as number,
                                smileScanReportsWithDiseaseAssessment[selectedSmileScanReport - 1]
                                  ?.overall_score as number,
                              ).changeDirection === 'up' ? (
                                <img src={arrow} alt="Arrow" style={{ width: '11px', height: '19px' }} />
                              ) : (
                                <img
                                  src={arrow}
                                  alt="Arrow"
                                  style={{ transform: 'rotate(180deg)', width: '11px', height: '19px' }}
                                />
                              )}
                              <span style={{ paddingLeft: '5px', fontWeight: '300', fontSize: '24px' }}>
                                <b style={{ fontWeight: '700' }}>
                                  {
                                    calculateScoreChange(
                                      smileScanReport?.overall_score as number,
                                      smileScanReportsWithDiseaseAssessment[selectedSmileScanReport - 1]
                                        ?.overall_score as number,
                                    ).change
                                  }
                                </b>{' '}
                                Change
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <SmileScanReportColors />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.smileScanReportDownloadHeader}>
              <div className={styles.smileScanReportDownloadHeaderLeft}>
                <span style={{ fontWeight: 'bold' }}>
                  {thisCase?.patient.basic_info.first_name} {thisCase?.patient.basic_info.last_name}
                </span>
                <span>
                  {formatTime(
                    created_at as string,
                    userInfo.role.name === UserRoleName.Patient ? currentState[userInfo.id] : 'NY',
                  )}
                </span>
              </div>
              <div className={styles.smileScanReportDownloadHeaderRight}>
                <img crossOrigin="anonymous" className={styles.logo} src={!isMobile ? Logo : LogoMobile} alt="Logo" />
              </div>
            </div>
            <div className={styles.smileScanReportDetails}>
              {diseaseAssessment.map((disease, index) => dentalDiseaseAssessmentRowComponent(disease, index))}
            </div>
            <div className={styles.smileScanReportDownloadFooter}>
              <span className={styles.smileScanReportDownloadFooterP}>
                Visit <a href="https://app.dentistry.one/">Dentistry.One</a> to speak to a dentist about your SmileScan
                Report
              </span>
              <span className={styles.smileScanReportDownloadFooterDis}>
                DISCLAIMER: The results of this report are part of a tool for pre-diagnostic information. They are not
                medical or diagnostic tools, and we do not claim that they diagnose any specific condition. They do not
                provide any medical advice and are not evaluated by a Provider.
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  if (!videoCaseEnabled && videoCaseTypeEnabled) {
    return <VideoCaseNotAvailableModal onClose={() => setVideoCaseTypeEnabled(false)} />;
  }

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        background: '#fff',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width < 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '0px' }}
    >
      {smileScanReportsWithDiseaseAssessment.length > 0 && !loading ? (
        SmileScanReport(smileScanReportsWithDiseaseAssessment[selectedSmileScanReport])
      ) : (
        <Loading fullScreen />
      )}
    </Container>
  );
};
export default SmileScanReportComponent;
