/* eslint-disable no-param-reassign */
import { ICase } from '../cases/types/ICase';
import { client } from '../client';
import { ProviderAvailability } from './types/ProviderAvailability';

export interface Payload {
  availability_id?: number;
  type?: 'weekday' | 'date';
  date?: string;
  weekday?: number;
  start_time?: string | null;
  end_time?: string | null;
  synchronous?: boolean;
  asynchronous?: boolean;
  other_slots_at_same_request?: ProviderAvailability[];
  unavailable_dates?: string[];
}

export const checkUserAvailability = async (user_id: number, payload: Payload): Promise<ICase[]> => {
  if (payload.other_slots_at_same_request) {
    payload.other_slots_at_same_request = payload.other_slots_at_same_request.map((slot) => {
      slot.created_at = slot.created_at || null;
      return slot;
    });
  }

  const { data } = await client.post<ICase[]>(`/users/${user_id}/check_user_availability`, payload);

  return data;
};
