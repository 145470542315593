export const defaultFormValues = {
  profile_img: '',
  isCompleted: false,
  patientId: 0,
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  iPhoneNumber: '',
  zip_code: '',
  state: '',
  date_of_birth: '',
  languages: [
    {
      id: '',
      name: '',
      locale: '',
    },
  ],
  language: [],
  pronouns: '',
  gender: '',
  gender_identity: '',
  address1: '',
  address2: '',
  member_id: '',
  group_number: '',
  insurance_through: '',
  phone_number: '',
  iFirstName: '',
  iMiddleName: '',
  iDateOfBirth: '',
  iGenderIdentity: '',
  iLastName: '',
  iAddress1: '',
  iAddress2: '',
  iZipCode: '',
  iState: '',
  iCity: '',
  secondary_member_id: '',
  secondary_group_number: '',
  secondary_insurance_through: '',
  secondary_insurance_carrier_id: 0,
  secondary_iPhoneNumber: '',
  secondary_iFirstName: '',
  secondary_iMiddleName: '',
  secondary_iDateOfBirth: '',
  secondary_iGenderIdentity: '',
  secondary_iLastName: '',
  secondary_iAddress1: '',
  secondary_iAddress2: '',
  secondary_iZipCode: '',
  secondary_iState: '',
  secondary_iCity: '',
  city: '',
  country: '',
  profileAddress: '',
  profileCity: '',
  basic_info: {
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    pronouns: 'string',
    languages: [],
  },
  dependent_profile_img: '',
  dependent_first_name: '',
  dependent_middle_name: '',
  dependent_last_name: '',
  dependent_email: undefined,
  dependent_iPhone_number: '',
  dependent_zip_code: '',
  dependent_state: '',
  dependent_date_of_birth: '',
  dependent_languages: [
    {
      id: '',
      name: '',
      locale: '',
    },
  ],
  dependent_language: [],
  dependent_pronouns: '',
  dependent_genderIdentity: '',
  dependent_gender: '',
  dependent_address1: '',
  dependent_address2: '',
};

export const defaultPaymentProfile = {
  buye: null,
  token: null,
};
