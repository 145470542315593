import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { isValidAddress, isValidEmail, isValidName, isValidZipCode } from '../../../../Utils/validation';

export const validationSchema = (isAdmin: boolean | undefined): any => {
  return Yup.object().shape({
    profile_img: Yup.string().required('Profile image is required'),
    first_name: Yup.string()
      .required('First name is required')
      .test('test-name', 'Enter Valid First Name', (value: any) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    middle_name: Yup.string().test('test-name', 'Enter Valid Last Name', (value: any) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    last_name: Yup.string()
      .required('Last name is required')
      .test('test-name', 'Enter Valid Last Name', (value: any) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      }),
    gender: Yup.string()
      .required('Gender is required')
      .test('test-name', 'Gender is required', (value: any) => {
        if (value === '') {
          return false;
        }
        return true;
      }),
    gender_identity: !isAdmin
      ? Yup.string()
          .required('Gender identity is required')
          .test('test-name', 'Gender identity is required', (value) => {
            if (value === '') {
              return false;
            }
            return true;
          })
      : Yup.string(),
    language: Yup.array().min(1, 'Language is required').required('Language is required'),
    state: Yup.string().required('State is required'),
    address1: Yup.string()
      .required('Address is required')
      .test('test-name', 'Enter Valid Address', (value) => {
        if (value && !isValidAddress(value)) {
          return false;
        }
        return true;
      }),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    zip_code: Yup.string()
      .required('Zip Code is required')
      .test('test-name', 'Enter Valid Zip Code', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    email: Yup.string()
      .required('Email is required')
      .test('test-name', 'Enter Valid Email', (value: any) => {
        if (value && !isValidEmail(value)) {
          return false;
        }
        return true;
      }),
    phone_number: Yup.string()
      .required('Phone number is required')
      .test('test-name', 'Enter Valid Phone Number', (value: any) => {
        if (value && !isValidPhoneNumber(value)) {
          return false;
        }
        return true;
      }),
    biography: Yup.string().trim().required('Biography is required'),
  });
};
