import { useEffect, useState } from 'react';

interface WindowSizeReturn {
  width: number;
  height: number;
}

const useWindowSize = (): WindowSizeReturn => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const handleResize = (): void => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
export default useWindowSize;
