import { selectAuth } from '@brands/store/selectors/auth';
import { AuthState } from '@brands/store/slices/authSlice';
import React from 'react';

import { useAppSelector } from '../../hooks/useReduxHook';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import Solutions from './Solutions';

const SolutionsValidation: React.FC = () => {
  let component: JSX.Element;
  const user: AuthState = useAppSelector(selectAuth);

  switch (user.userInfo?.role.name) {
    case UserRoleName.CareAdvisor:
      component = <Solutions />;
      break;
    case UserRoleName.Provider:
      component = <Solutions />;
      break;
    default:
      // call 404 page
      component = <NotFoundPage />;
      break;
  }

  return <div>{component}</div>;
};

export default SolutionsValidation;
