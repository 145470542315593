import qs from 'qs';

import { client } from '../client';
import { IListCasesParams } from './listAllCases';
import { ICase } from './types/ICase';

export const getOneCase = async (case_id: number, params?: IListCasesParams): Promise<ICase> => {
  if (!case_id) {
    throw new Error('Case ID is required');
  }

  const { data } = await client.get(
    `/cases/${case_id}${params ? '?' : ''}${qs.stringify(params, { skipNulls: true })}`,
  );

  return data;
};
