import { client } from '../client';
import { UserProfile } from './types/UserProfile';

type UpdateClientTagPayload = {
  client_ids: number[];
};

export const updateUserClientTag = async (user_id: number, payload: UpdateClientTagPayload): Promise<UserProfile> => {
  const { data } = await client.patch(`/admin/users/${user_id}/clients`, payload);

  return data;
};
