/* eslint-disable prefer-destructuring */
import { multiSelectCommonCustomStyles } from '@brands/Utils/customStyles';
import React, { SetStateAction, useEffect, useState } from 'react';
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import MultiselectInput from '../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import { CaseType, ICase } from '../../../../services/cases/types/ICase';
import { listCDTOptions } from '../../../../services/identity/listCDTOptions';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OptionCDT } from '../../../../Utils/selectOptions';
import { OptionCdts, OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type AssessmentSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  getValues: UseFormGetValues<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const AssessmentSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  getValues,
  trigger,
  errors,
}: AssessmentSectionProps): JSX.Element => {
  const [cdtOptions, setCdtOptions] = useState<OptionCdts[]>([]);
  const [cdtOptionsToDisplay, setCdtOptionsToDisplay] = useState<OptionCDT[]>([]);

  const saveCaseAssessment = async (caseAssessment: string): Promise<void> => {
    const { assessments: res } = await updateOneReport(thisCase?.one_report.id || '', {
      assessments: caseAssessment,
    });
    setCase({
      ...thisCase,
      one_report: {
        ...thisCase.one_report,
        assessments: res,
      },
    });
  };

  const saveCaseCDT = async (caseCDT: string[]): Promise<void> => {
    const { cdts: res } = await updateOneReport(thisCase?.one_report.id || '', {
      cdts: caseCDT.length > 0 ? caseCDT : [''],
    });
    setCase({
      ...thisCase,
      one_report: {
        ...thisCase.one_report,
        cdts: res,
      },
    });
    setConsultationForm({
      ...consultationForm,
      cdt: res.map((currentCDT) => currentCDT.code),
    });
  };

  useEffect(() => {
    if (thisCase) {
      setCdtOptionsToDisplay(thisCase?.one_report?.cdts || []);
    }
  }, [thisCase]);

  const setDefaultCDTOptions = (options: OptionCdts[]): void => {
    let defaultCDTOption: OptionCdts;
    if (
      thisCase?.type === 'video_call_scheduled' ||
      thisCase?.type === 'video_call_instant' ||
      thisCase?.type === CaseType.phone_call_scheduled
    ) {
      [defaultCDTOption] = options.filter((item) => item.value === 'D9995');
    } else {
      [defaultCDTOption] = options.filter((item) => item.value === 'D9996');
    }

    const isDefaultCDTExist = thisCase?.one_report?.cdts?.find((item) => item.code === defaultCDTOption.value);

    if (defaultCDTOption && !isDefaultCDTExist) {
      setConsultationForm({
        ...consultationForm,
        cdt: [defaultCDTOption.value],
      });
      setValue('cdt', [defaultCDTOption.value]);
      setCase({
        ...thisCase,
        cdts: [{ code: defaultCDTOption.value, nomenclature: defaultCDTOption.label.split(': ')[1] }],
        one_report: {
          ...thisCase.one_report,
          assessments: getValues('assessment') || '',
        },
      });
      saveCaseCDT([defaultCDTOption.value]);
    }
  };

  const fetchCDTOptions = async (): Promise<void> => {
    try {
      const data = await listCDTOptions();
      const sortedDataByCode = data.sort((a, b) => a.code.localeCompare(b.code));
      const transformedData = sortedDataByCode.map((cdts) => ({
        value: cdts.code,
        label: `${cdts.code}: ${cdts.nomenclature}`,
        disabled: cdts.code === 'D9995' || cdts.code === 'D9996',
      }));
      setCdtOptions(transformedData);
      setDefaultCDTOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    fetchCDTOptions();
  }, []);
  return (
    <div className={styles.assessmentDiv}>
      <div className={styles.assessment}>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={styles.assessmentInput}
            style={{ color: errors.assessment ? '#BA271A' : '' }}
            {...register('assessment')}
            value={consultationForm.assessment || ''}
            onChange={(e) => {
              setConsultationForm({ ...consultationForm, assessment: e.target.value });
              setValue('assessment', e.target.value);
              if (errors?.assessment) {
                trigger('assessment');
              }
            }}
            onBlur={() => {
              saveCaseAssessment(consultationForm.assessment);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.assessments || ''}
            className={styles.assessmentInput}
            style={{ color: errors.assessment ? '#BA271A' : '' }}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(AssessmentSection);
